import { FC } from "react";
import { useParams } from "react-router-dom";
import { useUserDetailsQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useRoleList } from "../../common/hooks/globals";
import { useMainContext } from "../../layout/components/MainProvider";
import { SellerAccounts } from "./SellerAccounts";
import { UserDetails } from "./UserDetails";

export const EditUser: FC = (): ReturnType<FC> => {
  const { usershipId } = useParams<{ usershipId: string }>();
  const [queried] = useUserDetailsQuery({ variables: { usershipId } });
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users`;
  const userRoute = baseRoute + "/" + usershipId;
  useRoleList();

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "EditUser", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />;
  }

  const userData = queried.data.vw_active_user.find((user) => user)!;
  const tabs = [
    {
      tabName: "User Details",
      route: userRoute,

      component: <UserDetails userDetailInfo={userData} />,
    },
  ];
  if (userData?.seller_id) {
    tabs.push({
      tabName: "Sellers",
      route: `${userRoute}/stores`,

      component: <SellerAccounts userDetailInfo={userData} />,
    });
  }
  /*
  tabs.push({
    tabName: "User Activity",
    route: `${userRoute}/activities`,
    
    component: <UserActivity userDetailInfo={userData} />,
  });
  */

  return (
    <div className="row">
      <div className="col-lg-12 col-xl-8 mx-auto">
        <ReturnHeader title="Edit User" url={baseRoute} />
        <div className="wrapper-inner">
          <LinkTabsComponent tabs={tabs} />
        </div>
      </div>
    </div>
  );
};
