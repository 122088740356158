import { useAuth0 } from "@auth0/auth0-react";
import { SubscribePayload, createClient as createWSClient } from 'graphql-ws';
import { useEffect } from "react";
import { Provider, cacheExchange, createClient, fetchExchange, subscriptionExchange } from 'urql';
import { env } from "../../../env";
import { Loader } from "../../common/components/Loader";
import { useAccessToken } from "../../common/hooks/useAccessToken";
import { IBasicTenantSettings, defaultTenantSettings, getTenantSetting } from "../../common/miscellaneous/organizations";
import { isDebug } from "../../common/miscellaneous/utility";
import { LoginError } from "../../layout/components/LoginError";
import { MainProvider } from "../../layout/components/MainProvider";
import { MainRoute } from "./MainRoute";

if (env.REACT_APP_HASURA_WS_URI === undefined) {
  throw new Error("REACT_APP_HASURA_WS_URI missing");
} else if (env.REACT_APP_HASURA_API_URI === undefined) {
  throw new Error("REACT_APP_HASURA_API_URI missing");
}

const getUrqlClient = (token: string) => {
  // eslint-disable-next-line no-console
  isDebug() && console.log({ token: token });

  const wsClient = createWSClient({
    url: env.REACT_APP_HASURA_WS_URI!,
    connectionParams: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });
  return createClient({
    url: env.REACT_APP_HASURA_API_URI!,
    fetchOptions: () => {
      return {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
    exchanges: [
      cacheExchange,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(
                operation as SubscribePayload,
                sink,
              );
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
    ],
    requestPolicy: 'cache-and-network'
  });
};

const getOperatorTag = () => {
  const url = new URL(window.location.toString().toLowerCase());
  const tag = url.pathname.replace("/", "").split("/")[0];
  return tag;
}


export const loginSetting = async (fromLogin: boolean): Promise<IBasicTenantSettings> => {
  const searchParams = new URLSearchParams(window.location.search);
  const tenantUrl = Object.fromEntries(searchParams)?.tenantUrlTag; // if returning from the logout - get the tenantUrl from the url parameter
  const tag = tenantUrl ?? getOperatorTag();
  let opSetting: IBasicTenantSettings = defaultTenantSettings;

  return new Promise(async (resolve, reject) => {
    const result = await getTenantSetting(tag)
      .then(value => {
        opSetting = value;
        if (!fromLogin)
          return { tenantName: opSetting.tenantName };

        return Object.fromEntries(searchParams)?.signup ? { ...opSetting, screen_hint: "signup" } : opSetting;
      })


    if (result instanceof Error) {
      reject(result);

    } else {
      resolve(result);
    }
  });
}

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const accessToken = useAccessToken();
  const searchParams = new URLSearchParams(window.location.search);
  const loginError = Object.fromEntries(searchParams)?.error_description ?? "";

  useEffect(() => {
    const oldProdUrl = "fillitnow.marketpush.net";
    if (window.location.hostname.includes(oldProdUrl)) {
      window.location.href = window.location.href.replace(oldProdUrl, "app.marketpush.net/fillitnow");
      return;
    }

    if (isLoading || isAuthenticated) {
      return;
    }

    const login = async () => {
      await loginSetting(true)
        .then(opSetting => {
          window.localStorage.setItem("tenantName", opSetting?.tenantName ?? defaultTenantSettings.tenantName);
          const redirectParams = Object.fromEntries(searchParams)?.signup ? { ...opSetting, screen_hint: "signup" } : opSetting;
          loginWithRedirect({ authorizationParams: redirectParams });
        })

      window.localStorage.setItem("user-login", "true");
    };
    if (!loginError) {
      login();
    }
  }, [isLoading, isAuthenticated, loginError]);

  if (!isAuthenticated && loginError !== "") {
    return <LoginError message={loginError} />;
  }

  if (isLoading || accessToken.tag !== "token") {
    return <Loader />;
  }

  return (
    <Provider value={getUrqlClient(accessToken.token)}>
      <MainProvider>
        <div className="App">
          <MainRoute />
        </div>
      </MainProvider>
    </Provider>
  );
}

export default App;
