import parse from 'html-react-parser';
import { FC, useEffect, useRef, useState } from "react";
import {
  useDeleteNotificationMutation,
  useDeleteSelectedNotificationsMutation,
  useMyNotificationCountQuery,
  useMyNotificationQuery,
  useSetNotificationStatusMutation,
  useSetSelectedNotificationStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems, { ActionItemType } from "../../common/components/DropdownItems";
import { PageError } from '../../common/components/Errors';
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from '../../common/miscellaneous/utility';
import { ELoadingType, IDateRange, MutationAction } from "../../common/types/types";
import { alertsRef } from '../../layout/components/Main';
import { useMainContext } from '../../layout/components/MainProvider';
interface IProps {
  refreshCount: () => void;
}

interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}
const columnList: string[] = ["date", "notification", /* "by", */ "Action"];
const options = ["Restore", "Delete"];

export const MyArchivedNotification: FC<IProps> = (props) => {
  const [context] = useMainContext();
  const formatDate = useDateFormat();
  const { refreshCount } = props;
  const [loading, setLoading] = useState<number>(-1);
  const [notificationId, setNotificationId] = useState<string>("");
  const [selectedValues] = useState<{ id: string }[]>([]);
  const [selectedOption] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);

  let userId = useUserInfo().user_id!;
  let operatorId = useUserInfo().operator_id!;
  const { number_items_per_page } = context.operatorSettings.preset;
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  let staticFilterString = `,"status_cd": { "_eq": "Archive"},
                        "receiver_id": { "_eq": "${userId}"},
                        "operator_id": { "_eq": "${operatorId}"}`;
  const customRule = staticFilterString;
  const tableColumns = ["notification", "email"/* , "created_by" */];

  const addFilterOptions = [
    { label: "Notification", value: "notification_lower" },
    //{ label: "By", value: "email" },
  ];

  const [useNotificationLive, executeNotificationQuery] = useMyNotificationQuery({
    requestPolicy: "cache-and-network",
    variables: {
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
      offset: offset,
      limit: number_items_per_page,
    },
  });
  const [totalCount, executeTotalCountQuery] = useMyNotificationCountQuery({
    requestPolicy: "cache-and-network",
    variables: {
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
    },
  });

  const refreshData = () => {
    executeNotificationQuery();
    executeTotalCountQuery();
    refreshCount();
  };
  const [showConfirmRestoreSelectedDialog, setShowConfirmRestoreSelectedDialog] = useState<boolean>(
    false
  );
  const [showConfirmRestoreDialog, setShowConfirmRestoreDialog] = useState<boolean>(false);
  const [showConfirmDeleteAllDialog, setShowConfirmDeleteAllDialog] = useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);

  const multiSelectRef = useRef<IRef | null>(null);

  const [, SetNotificationStatus] = useSetNotificationStatusMutation();
  const [, SetSelectedNotificationStatus] = useSetSelectedNotificationStatusMutation();
  const [, DeleteSelectedNotification] = useDeleteNotificationMutation();
  const [, DeleteAllSelectedNotification] = useDeleteSelectedNotificationsMutation();

  const addActions = (id: string, allowUnarchive: boolean) => {
    let actions: ActionItemType[] = [
      {
        actionType: "Restore",
        id: id,
        icon: "bi-arrow-counterclockwise",
        actionFunc: (newId: string) => {
          setNotificationId(newId);
          setShowConfirmRestoreDialog(true);
        },
        disabled: !allowUnarchive,
      },
      {
        actionType: "Delete",
        id: id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: (newId: string) => {
          setNotificationId(newId);
          setShowConfirmDeleteDialog(true);
        },
      },
    ];
    return actions;
  };

  const continueRestoreSelected = (proceed: boolean) => {
    if (proceed) {
      MarkSelectedNotificationActive("Active");
    }
    setShowConfirmRestoreSelectedDialog(false);
  };

  const continueRestore = (proceed: boolean) => {
    if (proceed) {
      SetStatus(notificationId, "Active");
    }
    setShowConfirmRestoreDialog(false);
  };

  const continueDelete = (proceed: boolean) => {
    if (proceed) {
      DeleteNotification(notificationId);
    }
    setShowConfirmDeleteDialog(false);
  };

  const continueDeleteAll = (proceed: boolean) => {
    if (proceed) {
      DeleteAllNotification();
    }
    setShowConfirmDeleteAllDialog(false);
  };

  const MarkSelectedNotificationActive = async (status: string) => {
    const elements = multiSelectRef.current?.selectedData.map((element) => `"${element}"`).join();

    const whereString = `{ "id": { "_in": [${elements}] } }`;

    const res = await SetSelectedNotificationStatus({
      whereValue: JSON.parse(whereString),
      status: status,
    });
    if (!res.error) {
      refreshData();
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("notification", MutationAction.Archive, res));
  };

  const SetStatus = async (newNotificationId: string, status: string) => {
    const res = await SetNotificationStatus({
      id: newNotificationId,
      status: status,
    });
    if (!res.error) {
      refreshData();
    }
    alertsRef.current?.generate(mutationInfo("notification", MutationAction.Update, res));
  };

  const DeleteAllNotification = async () => {
    const elements = multiSelectRef.current?.selectedData.map((element) => `"${element}"`).join();
    const whereString = `{ "id": { "_in": [${elements}] } }`;
    const res = await DeleteAllSelectedNotification(
      {
        whereValue: JSON.parse(whereString),
      },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    if (!res.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("the notifications", MutationAction.Delete, res));
  };

  const DeleteNotification = async (id: string) => {
    const res = await DeleteSelectedNotification(
      { id: id },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    alertsRef.current?.generate(mutationInfo("notification", MutationAction.Delete, res));
  };

  useEffect(() => {
    if (selectedValues.length === 0) {
      return;
    }
    if (selectedOption === "Restore") {
      setShowConfirmRestoreSelectedDialog(true);
    }
    if (selectedOption === "Delete") {
      setShowConfirmDeleteAllDialog(true);
    }
  }, [selectedValues, selectedOption]);

  useEffect(() => {
    if (useNotificationLive.data) {
      setLoading(ELoadingType.None);
    }
  }, [useNotificationLive.data]);

  const error = useNotificationLive.error || totalCount.error;
  if (error) {
    return <PageError error={{ source: "MyArchivedNotification", errMsg: error.message }} />;
  }

  if (!useNotificationLive.data || !totalCount.data) {
    return (
      <Card>
        <TablePlaceHolder columnNames={columnList} numberOfRows={number_items_per_page} />
      </Card>
    );
  }

  const totalNotificationCount = totalCount.data.vw_notification_user_aggregate?.aggregate?.count;
  const notificationData = useNotificationLive.data;

  const userNotification = notificationData?.vw_notification_user;
  let tableData = userNotification
    ? userNotification.map((item) => {
      const actions = addActions(item.id ? item.id : "", true);
      return {
        id: item.id ? item.id : "",
        date: < div className="date-created" >
          <p className="mb-0">{formatDate(item.created_date)}</p>
          <span className="small text-muted">{formatDate(item.created_date, "time")}</span>
        </div >,
        notification: (
          <p className={item.read_date ? "mb-0" : "mb-0 font-weight-semi-bold"}>
            {parse(item.notification ?? "")}
          </p>
        ),
        /* by: (
          <p className={item.read_date ? "mb-0" : "mb-0 font-weight-semi-bold"}>
            {item.email ?? item.created_by}
          </p>
        ), */
        Action: <DropdownItems items={actions} />,
      };
    })
    : [];

  const applyChanges = () => {
    if (multiSelectRef.current?.selectedOption === "Restore") {
      setShowConfirmRestoreSelectedDialog(true);
    }
    if (multiSelectRef.current?.selectedOption == "Delete") {
      setShowConfirmDeleteAllDialog(true);
    }
  };

  return (
    <Card>
      <Dialog
        show={showConfirmRestoreSelectedDialog}
        title="Restore notifications"
        continueText="Restore"
        contineBtnCss={"btn-success"}
        continue={continueRestoreSelected}
      >
        <div className="pt-2">Are you sure you want to restore the selected notifications?</div>
      </Dialog>
      <Dialog
        show={showConfirmRestoreDialog}
        title="Restore notifications"
        continueText="Restore"
        contineBtnCss={"btn-success"}
        continue={continueRestore}
      >
        <div className="pt-2">Are you sure you want to restore this notification?</div>
      </Dialog>
      <Dialog
        show={showConfirmDeleteDialog}
        title="Delete Selected"
        continueText="Ok"
        continue={continueDelete}
      >
        <div className="pt-2">Are you sure you want to delete this notification?</div>
      </Dialog>
      <Dialog
        show={showConfirmDeleteAllDialog}
        title="Delete"
        continueText="Ok"
        continue={continueDeleteAll}
      >
        <div className="pt-2">Are you sure you want to delete the selected notification(s)?</div>
      </Dialog>
      <Table
        setSearchValue={setSearchValue}
        columnNames={columnList}
        data={tableData}
        options={options}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalNotificationCount || 0}
        ref={multiSelectRef}
        multiSelectTable
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Notificat..."
        searchHint="Search by Notification"
      />
    </Card>
  );
};
