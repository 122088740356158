import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { OrderItemPartFragment, Reason_Category_Enum, useCreateIncidentsMutation } from "../../../generated/urql-graphql";
import { useReasonList } from "../../common/hooks/globals";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";
import { OrderItemBriefDescription } from "./OrderItems";

interface IncidentProps {
  orderItems: OrderItemPartFragment[];
  setFetching: (processing: boolean) => void;
  setClose: () => void;
}
interface IncidentItem {
  order_item: OrderItemPartFragment;
  selected: boolean;
  reason?: string | null;
}

interface IncidentState {
  apply_same_reason: boolean;
  incident_items: IncidentItem[];
}

export const OpenIncident: FC<IncidentProps> = ({ orderItems, setFetching, setClose }) => {
  const reasonList = useReasonList();
  const [hasError, setHasError] = useState<boolean>(false);
  const [, addMutation] = useCreateIncidentsMutation();

  const openIncidentReasons = reasonList
    .filter((reason) => reason.category === Reason_Category_Enum.OpenIncident)
    .map((reason) => {
      return { value: reason.value, label: reason.label };
    });

  const defState: IncidentState = {
    apply_same_reason: true,
    incident_items: orderItems.map((item) => {
      return { order_item: item, selected: false, reason: null };
    }),
  };

  const methods = useForm<IncidentState>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: defState,
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = methods;

  const applyAllReason = watch("apply_same_reason");
  const incidentItems = watch("incident_items");
  useEffect(() => {
    if (incidentItems.find((item) => item.selected) && hasError) setHasError(false);
  }, [incidentItems.find((item) => item.selected)]);

  const onSubmit = async (formData: FieldValues) => {
    if (!incidentItems.find((item) => item.selected)) {
      setHasError(true);
      return;
    }
    setFetching(true);
    const incidents = incidentItems
      .filter((item) => item.selected)
      .map((item) => {
        const open_reason = applyAllReason ? formData.same_reason : item.reason;
        return { order_item_id: item.order_item.id, open_reason };
      });
    const res = await addMutation({ incidents });
    setFetching(false);
    if (!res.error) {
      reset();
      setClose();
    }
    alertsRef.current?.generate(mutationInfo("order incident", MutationAction.Create, res));
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form
          data-testid="open-incident-form"
          id="open-incident-form"
          noValidate
          className={
            errors && Object.keys(errors).length !== 0
              ? "was-validated form-group px-2"
              : "form-group px-2"
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            name="apply_same_reason"
            label="Apply same reason to all items"
            type="checkbox"
            css="mb-3 d-flex"
            isswitch="true"
          />
          {applyAllReason && (
            <FormSelect
              name="same_reason"
              label="Select reason for Incident"
              reg_options={{ required: true }}
              options={openIncidentReasons}
              css="w-50 mb-3"
            />
          )}
          <div className="table-responsive mb-3">
            <table className="table table-sm align-middle">
              <thead>
                <tr className="w-100">
                  <th scope="col">
                    <div className="form-check m-0">
                      <Input
                        data-testid=""
                        ref={null}
                        type="checkbox"
                        className="form-check-input"
                        onChange={(event) => {
                          setValue(
                            "incident_items",
                            incidentItems.map((item) => {
                              return { ...item, selected: event.target.checked };
                            })
                          );
                        }}
                        checked={
                          incidentItems.filter((item) => item.selected).length ===
                          incidentItems.length
                        }
                      />
                    </div>
                  </th>
                  <th scope="col">Items</th>
                </tr>
              </thead>
              <tbody>
                {incidentItems.map((incItem, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <FormInput
                          type="checkbox"
                          name={`incident_items.${index}.selected`}
                          label=""
                        />
                      </td>
                      <td>
                        <OrderItemBriefDescription orderItem={incItem.order_item} />
                        {!applyAllReason && incidentItems[index].selected && (
                          <FormSelect
                            name={`incident_items.${index}.reason`}
                            label="Select reason for incident"
                            reg_options={{ required: true }}
                            options={openIncidentReasons}
                            css="w-50 my-3"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {hasError && (
              <small className="has-error text-danger">
                <i className="bi bi-exclamation-circle-fill me-1" />
                Please select at least one item.
              </small>
            )}
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
