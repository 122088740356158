import { FC, useEffect, useRef, useState } from "react";
import { Loader } from "../../common/components/Loader";
import { MultipleSteps, StepsRef } from "../../common/components/MultipleSteps";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import { ProfileFirstStep, } from "./ProfileFirstStep";
import { ProfileReviewSubmit, } from "./ProfileReviewSubmit";

export const CompleteUserProfile: FC = () => {
  const userInfo = useUserInfo();
  const userProfile = useUserProfile();
  const stepsRef = useRef<StepsRef | null>(null);
  const [goNext, setGoNext] = useState<boolean>(false)
  const [context] = useMainContext();
  const { site_info } = context.operatorInfo;
  const [logoImage, setLogoImage] = useState<string>("");
  const steps = [
    { label: "1. Display Name", formId: "profile-first-step" },
    { label: "2. Finalize Profile", formId: "profile-review-submit-step" }
  ];

  const [userProfileData, setUserProfileData] = useState<{
    [key: string]: string | null | undefined | boolean | Array<Object>
  }>({ user_id: '00000000-0000-0000-0000-000000000000', email: '' });

  useEffect(() => {
    if (site_info.logo) {
      setLogoImage(site_info.logo);
    }
  }, [site_info]);

  useEffect(() => {
    if (userInfo.user_id !== '00000000-0000-0000-0000-000000000000'
      && userProfile.email !== '') {
      setUserProfileData({
        user_id: userInfo.user_id,
        operator_id: userInfo.operator_id,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        email: userProfile.email,
        contact: userProfile.phone,
        profile_image: userProfile.profile_image,
        preset_notification: true,
        notification_type_list: userInfo.seller_id
          ? [
            { name: 'New order' },
            { name: 'Product rejected' },
            { name: 'Offer Stock Quantity Threshold Alert' },
            { name: 'New Message' },
            { name: 'New order - email' }
          ]
          : [
            { name: 'New order' },
            { name: 'New offer' },
            { name: 'Product rejected' }
          ],
        enable_notification: false,
        enable_email_notification: true
      });
    }
  }, [userInfo, userProfile]);


  if (userProfileData.user_id === '00000000-0000-0000-0000-000000000000') {
    return <Loader />;
  }
  return (
    <div className="p-4 col-lg-12 col-xl-8 mx-auto">

      <div className="site-logo-container p-4 d-flex justify-content-center align-items-center mb-3">
        <img src={logoImage} alt="site logo" className="img-fluid" />
      </div>

      <MultipleSteps ref={stepsRef} steps={steps} prevBtn={{ label: 'Previous' }} goNext={goNext} showFinish={true}>
        <ProfileFirstStep next={() => stepsRef.current?.navNext()} data={userProfileData} setGoNext={setGoNext} />
        <ProfileReviewSubmit data={userProfileData} />
      </MultipleSteps>

    </div>
  );
};
