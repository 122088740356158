import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigurationsDefaultRoute } from "./ConfigurationsDefaultRoute";

export const ConfigurationsRoute: FC = () => {
  const baseRoute = "/configurators";
  return (
    <div>
      <Routes>
        <Route path={`${baseRoute}`}>
          <ConfigurationsDefaultRoute />
        </Route>
      </Routes>
    </div>
  );
};
