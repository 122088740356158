import { FC } from "react";

export const Error500: FC = () => {
  return (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <h1 className="error-number">500</h1>
      <p className="mini-text">Server Error</p>
      <p className="error-text mb-4 mt-1">Ooops, something went wrong!</p>
    </div>
  );
};
