import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useState } from "react";
//import { organizations } from "../../common/miscellaneous/organizations";
import { getTenantSettings, IBasicTenantSettings } from "../../common/miscellaneous/organizations";
import { IHash } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
interface Props {
  message: string;
}



export const LoginError: FC<Props> = ({ message }) => {
  const { logout } = useAuth0();
  const msgParts = message.split("-param-");
  const errCode = msgParts[0];
  const param = msgParts.length > 1 ? msgParts[1] : "";
  const supportEmail = errCode === "expire_invite" ? param : "";
  const extraOps = errCode === "other_org" && param;

  const [orgs, setOrgs] = useState<IBasicTenantSettings[]>([]);

  useEffect(() => {
    getTenantSettings()
      .then((data) => {
        setOrgs(data);
      })
  }, []);

  const errObj: IHash = {
    expire_invite: <>Unfortunately your invitation has expired. <br />Please reach out the support at {supportEmail} for further assistance.</>,
    no_org: <>You do not have authorization to log into this organization.</>,
    other_org: <><b className="welcome-back-header">Welcome back!</b><div className="text-center welcome-back-description mt-2">Please select the organization you'd like to log into</div></>,
    inactive_org: <>The organization you're trying to log into has been archived or suspended. <br /> Please reach out to support at support@marketpush.com if you need further assistance.</>
  };

  return (
    <div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="text-center mt-2 welcome-back-body">
        <div className="logo-container">
          <img src={"/marketpush-logo.png"} alt="Marketpush logo" className="w-100 p-3" />
        </div>
        <div className="welcome-back">
          {errObj[errCode]}
        </div>
        {extraOps && extraOps.split(',').map(opr => {
          const setting = orgs.find(ops => ops.tenantName === opr);
          const host = window.location.host;
          let url = `https://${host}/${setting?.tag}`;
          url = host.includes("localhost") ? url.replace("https", "http") : url;
          return (
            <a key={opr} href={url} className="administration-card-link">
              <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded-3 mb-3">
                <div className="w-50  py-2 pe-2">
                  <img src={setting?.logoUrl || ""} alt="organization logo" className="w-100" style={{ maxHeight: "50px", maxWidth: '100px', objectFit: "contain" }} />
                </div>
                <div className="tenant-name mb-0">{setting?.legalName}</div>
              </div>
            </a>
          )
        })}
        <Button data-testid="btn-back-login" className="btn btn-primary mt-5 px-3" onClick={() => logout()}>
          Logout
        </Button>
      </div>
    </div>
  );
};
