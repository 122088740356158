import { FC, useState } from "react";

interface ITabs {
  tabName: string;
  component: JSX.Element;
}

type Props = {
  tabs: ITabs[];
  defaultTab?: string;
};

export const TabsComponent: FC<Props> = (props): ReturnType<FC> => {
  const { tabs } = props;
  const [selectedTab, setSelectedTab] = useState(props.defaultTab ?? tabs[0].tabName);
  return (
    <div className="tabs-component">
      <ul className="nav nav-tabs">
        {tabs.map((tab) => (
          <li
            className="nav-item rounded-top"
            key={tab.tabName}
            onClick={() => setSelectedTab(tab.tabName)}
          >
            <span
              className={`nav-link ${
                selectedTab === tab.tabName ? "active text-primary" : "text-dark"
              }`}
            >
              {tab.tabName}
            </span>
          </li>
        ))}
      </ul>
      <div>{tabs.find((tab) => tab.tabName === selectedTab)?.component}</div>
    </div>
  );
};
