import { FC, useContext, useEffect, useState } from "react";
import { Card } from "../../common/components/Card";
import { UploadSpreadsheet } from "../../common/components/UploadSpreadsheet";
import { ISheetData, MappingContext, getDefMapResults } from "../utils/types";
import { RequiredMapping } from "./RequiredMapping";

interface IProps {
  next: () => void;
  setGoNext: (val: boolean) => void | undefined;
}

export const ProcessFile: FC<IProps> = ({ next, setGoNext }: IProps) => {
  const mapContext = useContext(MappingContext);
  const [hasRows, setHasRows] = useState<boolean>(false);

  useEffect(()=>{
    setHasRows(!!mapContext.resultsRef!.current.sheet.rowData);
  }, []);

  const dataChanged = (fileData: ISheetData | null) => {
    if (fileData) 
    mapContext.resultsRef!.current.sheet = { ...fileData };
    else {
      mapContext.resultsRef!.current = getDefMapResults();
      setGoNext(false);
    }
    setHasRows(!!mapContext.resultsRef!.current.sheet.rowData);
  }

  return (
    <>
      <div className="product-import-wizard">
        <Card className="p-4 my-4">
          <h4 className="card-title mb-0">Import your file to MarketPush</h4>
          <UploadSpreadsheet 
            sheetData={mapContext.resultsRef!.current.sheet}
            setSheetData={(fileData: ISheetData | null) => dataChanged(fileData)}
            stopParsing={hasRows} 
          />
          {hasRows && (
            <>
              <TableComponent />
              <RequiredMapping next={next} setGoNext={setGoNext} />
            </>
          )}
        </Card>
      </div>
    </>
  );
};

const TableComponent: FC = () => {
  const mapContext = useContext(MappingContext);
  const rowData = mapContext.resultsRef?.current.sheet.rowData!;
  const {header, bodyRows} = rowData;
  return (
    <div className="preview-wrapper">
      <Card className="py-4 border-0 shadow-none">
        <h4 className="card-title">Preview</h4>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                {header ? (
                  header.map((rows: any, index) => {
                    return (
                      <th className="table-headings" key={`header_${index}`}>
                        {rows}
                      </th>
                    );
                  })
                ) : (
                  <th className="text-center">Cannot Find Table Header Content</th>
                )}
              </tr>
            </thead>
            <tbody>
              {bodyRows.length > 0 ? (
                bodyRows.slice(0, 5).map((row: any, index) => {
                  return (
                    <tr key={index}>
                      {Object.values(row).map((val: any, valIndex: number) => {
                        return (
                          <td className="table-cell" key={`row${index}_val${valIndex}`}>
                            {val}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center">
                    No Records. It's an invalid file, please import a valid file.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};
