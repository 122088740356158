import _ from "lodash";
import { useEffect, useState } from "react";
import { useAttributeOptionsQuery, useCtgAttrOptionsQuery, useMfrOptionsQuery, useProductCategoriesQuery } from "../../../generated/urql-graphql";
import { emptyUuid } from "../miscellaneous/utility";
import { IOptions, ISearchParam } from "../types/types";

export const useSearch= (searchType: string, initPause: boolean, searchParam?: ISearchParam) => {
  const defResult = {options: [], total: 0};
  const [result, setResult] = useState<IOptions>(defResult);
  const defSearch = 
  {
    searchTerm: "%",
    limit: 15,
    offset: 0,
    defaultValue: emptyUuid,
    total: 0
  };
  const [searchInputs, setSearchInputs] = useState<ISearchParam>(defSearch);
  const [ctgQueried] = useProductCategoriesQuery({
    variables: _.omit(searchInputs, "total"),
    pause: searchType === "category" ? initPause : true
  });
  const [attrQueried] = useAttributeOptionsQuery({
    variables: _.omit(searchInputs, "total"),
    pause: searchType === "attribute" ? initPause : true
  });
  const [ctgAttrQueried] = useCtgAttrOptionsQuery({
    variables: _.omit(searchInputs, "total", "defaultValue"),
    pause: searchType === "ctgattr" ? initPause : true
  });
  const [mfrQueried] = useMfrOptionsQuery({
    variables: _.omit(searchInputs, "total", "defaultValue"),
    pause: searchType === "mfr" ? initPause : true
  });

  useEffect(()=>{
    if (searchParam)
      setSearchInputs(searchParam);
  }, [searchParam]);

  useEffect(() => {
    if (attrQueried.data || ctgQueried.data || ctgAttrQueried.data || mfrQueried.data) {
      const options = ctgQueried.data?.item.map(itm=>({value:itm.value || "", label:itm.label || ""}))
        || attrQueried.data?.item.map(itm=>({value:itm.value || "", label:itm.label})) 
        || ctgAttrQueried.data?.item.map(itm=>({value:itm.value || "", label:itm.label}))
        || mfrQueried.data?.item.map(itm=>({value:itm.value || "", label:itm.label}))
        || [];  
      const total = ctgQueried.data?.items.aggregate?.count 
        || attrQueried.data?.items.aggregate?.count
        || ctgAttrQueried.data?.items.aggregate?.count 
        || mfrQueried.data?.items.aggregate?.count 
        ||  0;
      setResult({options, total});
    }
  }, [attrQueried.data, ctgQueried.data, ctgAttrQueried.data, mfrQueried.data]);
  return result;
};
