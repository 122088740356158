import { Route, Routes } from "react-router-dom";
import { useGetHubIndustryQuery } from "../../../generated/urql-graphql";
import { AppSettingsRoute } from "../../app-settings-route/components/AppSettingsRoute";
import { AttributesRoute } from "../../attributes-route/components/AttributesRoute";
import { CategoriesRoute } from "../../categories-route/components/CategoriesRoute";
import { CategoryAttributesRoute } from "../../category-attributes-route/components/CategoryAttributesRoute";
import { Error404 } from "../../common/components/Errors/Error404";
import { EmailSettingDetails } from "../../email-settings-route/components/EmailSettingDetails";
import { EmailSettingsRoute } from "../../email-settings-route/components/EmailSettingsRoute";
import { SMTPSettings } from "../../email-settings-route/components/SMTPSettings";
import { useMainContext } from "../../layout/components/MainProvider";
import MembersRoute from "../../members-route/components/MembersRoute";
import { OperatorShipping } from "../../operator-shipping-route/components/OperatorShipping";
import { ShippingRoute } from "../../operator-shipping-route/components/ShippingRoute";
import { AuditLog } from "../../settings-route/components/AuditLog";
import { ApiService } from "./ApiService";
import Appearance from "./AppearanceView";
import BillingPeriodsView from "./BillingPeriodsView";
import GeneralSettingsView from "./GeneralSettingsView";
import { ManufacturerView } from "./ManufacturerView";
import PimDomainsView from "./PimDomainsView";
import TermsAndConditionsView from "./TermsandConditionsView";
import WebhooksView from "./WebhooksView";
import { useUserInfo } from "../../common/hooks/useUserInfo";

export function SettingsRoute() {
  const [context] = useMainContext();
  const [pimTenantQueried] = useGetHubIndustryQuery()
  const isPimTenant = (pimTenantQueried.data?.pim_hub_industry.length || 0) > 0;
  return (
    <Routes>
      <Route index element={<GeneralSettingsView />} />
      <Route path="terms-and-conditions" element={<TermsAndConditionsView />} />
      <Route path="billing" element={<BillingPeriodsView />} />
      <Route path="product-attributes" element={<AttributesRoute />} />
      <Route path="categories"  >
        <Route index element={<CategoriesRoute />} />
        <Route path=":categoryId/attributes" element={<CategoryAttributesRoute />} />
      </Route>
      <Route path="category-attributes" element={<CategoryAttributesRoute />} />
      <Route path="logs" element={<AuditLog />} />
      <Route path="appearance" element={<Appearance />} />
      <Route path="shipping" >
        <Route index element={<OperatorShipping />} />
        <Route path="*" element={<ShippingRoute />} />
      </Route>
      <Route path="email-settings">
        <Route index element={<EmailSettingsRoute />} />
        <Route path="smtp" element={<SMTPSettings />} />
        <Route path=":messageId" element={<EmailSettingDetails />} />
      </Route>
      <Route path="api-service" element={<ApiService />} />
      <Route path="app-settings">
        <Route index element={<AppSettingsRoute />} />
        <Route path="*" element={<AppSettingsRoute />} />
      </Route>
      <Route path="members">
        <Route index element={<MembersRoute />} />
        <Route path="*" element={<MembersRoute />} />
      </Route>
      <Route path="webhooks" element={<WebhooksView />} />
      <Route path="webhook-history" element={<WebhooksView />} />
      <Route path="manage-manufacturer-names" element={<ManufacturerView />} />
      {isPimTenant && <Route path="manage-pim-domains" element={<PimDomainsView />} />}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
