import { FC, } from "react";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { AuditLogOffset } from "./AuditLogOffset";
import { useMainContext } from "../../layout/components/MainProvider";
export const AuditLog: FC = () => {
    const [context] = useMainContext();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/settings`;
    return (
        <>
            <div className="d-flex justify-content-between return-header">
                <div className="d-flex align-items-start">
                    <ReturnHeader
                        title="Logs"
                        url={baseRoute}
                        description="View log information about events and triggers that occurred on MarketPush."
                    />
                </div>
            </div>
            <AuditLogOffset HandleCallBack={() => { }} />
        </>
    );
};
