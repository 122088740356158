import { FC } from "react";

export const Loader: FC = (): ReturnType<FC> => {
  return (
    <div className="loader-container">
      <div className="bouncer">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
