import { FC } from "react";

type Props = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & { "data-testid": string }; // force inclusion of id for testing

export const Label: FC<Props> = (props) => {
  // eslint-disable-next-line react/forbid-elements
  return <label {...props} />;
};
