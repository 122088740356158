import { useWebhookActionCountSubscription, useWebhookCountSubscription } from "../../../generated/urql-graphql";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useMainContext } from "../../layout/components/MainProvider";
import WebhookHistory from "./WebhookHistory";
import Webhooks from "./Webhooks";

const WebhooksView = () => {
    const [context] = useMainContext();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/settings`;
    const [webhookCountSubscribed] = useWebhookCountSubscription()
    const [webhookActionCountSubscribed] = useWebhookActionCountSubscription()

    if (
        !webhookCountSubscribed.data ||
        webhookCountSubscribed.fetching ||
        !webhookActionCountSubscribed.data ||
        webhookActionCountSubscribed.fetching
    ) {
        return <Loader />;
    }
    const totalWebhookCount = webhookCountSubscribed.data?.configs_webhook_settings_aggregate.aggregate?.count
    const totalWebhookActionCount = webhookActionCountSubscribed.data.audit_logged_webhook_actions_aggregate.aggregate?.count
    const tabs = [
        {
            tabName: "Webhooks",
            route: `${baseRoute}/webhooks`,
            totalNumberOfElements: totalWebhookCount,
            component: <Webhooks totalCount={totalWebhookCount || 0} />,
        },
        {
            tabName: "Webhook History",
            route: `${baseRoute}/webhook-history`,
            totalNumberOfElements: totalWebhookActionCount,
            component: <WebhookHistory />
        },
    ];

    return <div className="inner-wrapper">
        <div className="row">
            <div className="col-lg-12 col-xl-12 mx-auto">
                <ReturnHeader
                    url={baseRoute}
                    title="Webhooks"
                    description="View your webhooks and callback history"
                />
            </div>
        </div>
        <div>
            <LinkTabsComponent tabs={tabs} />
        </div>
    </div>
}

export default WebhooksView