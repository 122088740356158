import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useExportQueryMutation,
  useImportFileMutation
} from "../../../generated/urql-graphql";
import { uploadImportFile } from "../../common/miscellaneous/storage";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
const { v1: uuidv1 } = require("uuid");

type Props = {
  tablelist: Array<string>;
  setAlert?: (message: string, messageType: "error" | "success") => void;
};

type QueryProps = {
  queryString: string;
  inputJson: string;
  caption: string;
  setAlert?: (message: string, messageType: "error" | "success") => void
  btnWidth?: string;
  btnType?: string;
  redirectLink?: string;
};

export const ExportData: FC<QueryProps> = ({ setAlert, queryString, inputJson, caption, btnWidth, btnType, redirectLink }: QueryProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()
  const callExportQueryMutation = useExportQueryMutation();

  const callHasuraAction = async (tablename: string, input_json: string) => {
    const actionInput = {
      variables: {
        queryString: tablename,
        filterString: input_json
      },
    };

    const [, executeExportQueryMutation] = callExportQueryMutation;
    executeExportQueryMutation(actionInput.variables)
      .then((result) => {
        console.log(result)
        if (result.error) {
          setAlert && setAlert(result.error?.toString(), "error");
          setLoading(false);
        } else {
          setAlert && setAlert("Export submitted. You are redirected to my notifications page!", "success");
          if (redirectLink) {
            setTimeout(() => {
              navigate(redirectLink);
            }, 1500)
          }
          setLoading(false);
        }
      })
      .catch((error) => {

        setAlert && setAlert(error?.message ?? "Unknown error", "error");
        setLoading(false);
      })
      .finally(() => {

      });
  };
  return (
    <Button
      className={btnType === 'link' ? `btn btn-link p-0` : `btn btn-light`}
      style={{ width: btnWidth }}
      ref={null}
      data-testid="ExportTableButton"
      onClick={() => {
        setLoading(true);
        callHasuraAction(queryString, inputJson);
      }}
    >
      {loading ?
        <div data-testid=""
          className="spinner-border spinner-border-sm" role="status">
        </div>
        :
        caption ?? 'Export'}
    </Button>
  );
};

export const ImportFile: FC<Props> = ({ tablelist, setAlert }: Props) => {
  const formFields = {
    file_upload: useRef<HTMLInputElement>(null),
    button_upload: useRef<HTMLButtonElement>(null),
    form_admin: useRef<HTMLFormElement>(null),
  };

  const callImportFileMutation = useImportFileMutation();
  const [loading, setLoading] = useState<boolean>(false);
  let [tablename, setTablename] = useState<string>("");
  const handleBrowseClick = () => {
    formFields.file_upload?.current?.click();
  };
  const buttomItems = tablelist.map((tableName: string) => (
    <Button
      key={tableName}
      className="btn btn-light mx-3"
      ref={null}
      data-testid="UserImportButton"
      onClick={() => {
        setTablename(tableName);
        handleBrowseClick();
      }}
    >
      Import {tableName.includes("QUERY") ? "" : tablename}
    </Button>
  ));

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setLoading(true);
      let file = event.target.files[0];
      const filename = uuidv1() + ".csv";
      await uploadImportFile(file, filename);

      const [, executeImportFileMutation] = callImportFileMutation;
      executeImportFileMutation({ filename, tablename })
        .then((result) => {
          if (result.error) {
            setAlert && setAlert(result.error?.toString(), "error");
          } else {
            setAlert && setAlert(result?.data?.importFile?.message ?? "", "success");
          }
        })
        .catch((error) => {
          setAlert && setAlert(error?.message ?? "Unknown error", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }

  return (
    <div>
      {loading ?
        <div data-testid=""
          className="spinner-border spinner-border-sm" role="status">
        </div>
        :
        <><Input
          ref={formFields.file_upload}
          data-testid="user-file-upload"
          type="file"
          style={{ display: "none" }}
          accept=".csv"
          onChange={(event) => onFileChange(event)}
        />
          {buttomItems}
        </>
      }
    </div>
  );
};

