import { ImageType } from "react-images-uploading";

export type StringKeyObject = {
  [key: string]: string | undefined;
};

export interface UrlFileDt {
  url: string;
  fileName: string;
  size: number;
  file?: File;
}

export interface IProdImage {
  url?: string;
  uploadImg?: ImageType;
  remove?: (index: number) => void; 
}

export const getProdImgUrls = (urls: string | null | undefined) => {
  return urls?.split("||")
    .map(url=>url.trim())
    .filter(url=>url !== "" && !!url.match(/https:*/)) || [];
} 

