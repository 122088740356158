import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Vw_Active_User_Bool_Exp,
  useArchiveOpUserMutation,
  useChangeEmulationMutation, useUserListInitialQuery, useUserListSubscription
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useRoleList } from "../../common/hooks/globals";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IActionState, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface UserProps {
  whereString: Vw_Active_User_Bool_Exp;
  logsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

let columnNames = ["company", "email", "full name", "role", "last login", "action"];

export const AllUsers: FC<UserProps> = (props) => {

  const { logsPerPage } = props;
  const [loading1, setLoading] = useState<number>(-1);
  const multiSelectRef = useRef<IRef | null>(null);
  const formatDate = useDateFormat();
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "last_login_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const userInfo = useUserInfo()!;
  const emulating = !isNullOrEmpty(userInfo.emulater);

  const navigate = useNavigate();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users`;
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    company: "company",
    email: "email",
    full_name: "full_name",
    role: "role_name",
    last_login: "last_login_date",
  };

  const tableColumns = ["company", "email", "full_name", "role_name"];
  const getCustomRule = () => {
    const level = userInfo.user_level!;
    if (level < 20) return "";
    if (level === 35)
      return `,"operator_id": {"_eq": "${userInfo.operator_id}"}`;
    return `,"usr_order": {"_eq": "1"}`;
  };
  const inputs = {
    variables: {
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule: getCustomRule(),
        })
      ),
      offset,
      limit: logsPerPage,
      order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined
    },
  };

  const [totalUsers] = useUserListInitialQuery(inputs);
  const [userdData] = useUserListSubscription(inputs);

  const [{ fetching: loading }, archiveMutation] = useArchiveOpUserMutation();
  const [, changeEmulationMutation] = useChangeEmulationMutation();

  const defState = {
    action: "",
    ids: [],
  };
  const [usersState, setUsersState] = useState<IActionState>(defState);
  const roleList = useRoleList();
  const actionEnabled = (roleId: string, action: string) => {
    const curRole = roleList?.find((role) => role.id === roleId);
    const manageUser = userInfo.user_level! >= curRole?.user_level!;
    let actionPerm = 0;
    switch (action) {
      case "ArchiveUser":
        actionPerm = context.permissions.archive_user;
        break;
      case "EditUser":
        actionPerm = context.permissions.edit_user;
        break;
      case "EmulateUser":
        actionPerm = context.permissions.emulate_user;
        return manageUser && ((curRole?.user_level || 0) <= 20) && userInfo.permissions! & actionPerm;
    }
    return manageUser && userInfo.permissions! & actionPerm;
  };

  const continueArchive = async (isContinue: boolean) => {
    if (isContinue) {
      const obj = {
        archived: {
          at: new Date().toISOString(),
          by: userInfo.user_id,
        },
      };
      archiveMutation({
        userIds: selectedValues.length ? selectedValues : usersState.ids,
        operatorId: userInfo.operator_id,
        infoObj: obj,
      })
        .then((res) => {
          if (res.data && !res.error) {
            multiSelectRef.current?.clearSelectedData();
            setUsersState(defState);
            setSelectedValues([]);
          }
          alertsRef.current?.generate(mutationInfo("user", MutationAction.Archive, res));
        })
        .catch((error) => alertsRef.current?.generate(mutationInfo("user", MutationAction.Archive, error)));
    } else {
      setUsersState(defState);
      multiSelectRef.current?.clearSelectedData();
      setSelectedValues([]);
    }
  };

  const continueEmulateUser = (continueEmulate: boolean) => {
    const topLevelUserId = emulating ? userInfo.emulater : userInfo.user_id;
    const ushipId = usersState.ids?.find(usid => usid);
    const emulatee = usersState.item.user_level >= 20 ? { master_operator_uship_id: ushipId } : { seller_uship_id: ushipId }
    if (continueEmulate) {
      changeEmulationMutation({
        userId: topLevelUserId,
        emulatee
      })
        .then((res) => {
          alertsRef.current?.generate(mutationInfo("as the user", MutationAction.Login, res));
          if (res.data && !res.error) {
            setUsersState(defState);
            window.location.href = `/${tenantUrlTag}`;
          }
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("as the user", MutationAction.Login, error));
        });
    } else setUsersState(defState);
  };
  useEffect(() => {
    if (userdData.data) {
      setLoading(ELoadingType.None);
    }
  }, [userdData.data]);

  const error = userdData.error || totalUsers.error;
  if (error) {
    return <PageError error={{ source: "AllUsers", errMsg: error.message }} />;
  }

  if (!userdData.data || !totalUsers.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={columnNames} numberOfRows={logsPerPage} />
      </Card>
    );
  }
  const users = userdData.data?.vw_active_user;
  const totalRecords = totalUsers.data?.total_users.aggregate?.count;

  const data = users.map((user) => {
    const actions = [];
    if (actionEnabled(user.role_id, "EditUser")) {
      actions.push({
        actionType: "edit",
        id: user.usership_id,
        icon: "bi bi-pencil-square",
        actionFunc: (id: string) => navigate(`${baseRoute}/${id}`),
      });
    }
    if (user.user_id !== userInfo.user_id && actionEnabled(user.role_id, "ArchiveUser")) {
      actions.push({
        actionType: "archive",
        id: user.user_id,
        icon: "bi bi-archive",
        actionFunc: (id: string) =>
          setUsersState({
            action: "archive",
            ids: [id]
          }),
      });
    }
    if (actionEnabled(user.role_id, "EmulateUser")) {
      actions.push({
        actionType: "Log In As User",
        id: user.usership_id,
        icon: "bi bi-box-arrow-in-right",
        actionFunc: (id: string) =>
          setUsersState({
            action: "emulate",
            ids: [id],
            item: user
          }),
      });
    }

    return {
      id: user.user_id,
      company: user.company,
      email: user.email,
      full_name: user.full_name,
      role: user.role_name,
      last_login: formatDate(user.last_login_date, "datetime", false),
      action: actions.length > 0 && <DropdownItems items={actions} />,
    };
  });

  const addFilterOptions = [
    { label: "Company", value: "company" },
    { label: "Email", value: "email" },
    { label: "Full name", value: "full_name" },
    { label: "Role", value: "role_name" },
  ];

  const applyChanges = () => {
    setSelectedValues(multiSelectRef.current?.selectedData || []);
    return null;
  };

  const options = ["Status: Archive"];

  return (
    <Card>
      <Table
        columnNames={columnNames}
        data={data}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalRecords || 0}
        setSearchValue={setSearchValue}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        ref={multiSelectRef}
        multiSelectTable
        options={options}
        applyChanges={applyChanges}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading1}
        setLoading={setLoading}
        searchPlaceHolder="Search by Company,..."
        searchHint="Search by Company, Email, Full Name, Role"
      />
      <Dialog
        show={usersState.action === "archive"}
        title="Archive this user?"
        continueText="Archive"
        continue={continueArchive}
        contineBtnCss="btn-danger"
      >
        <p className="py-2">Are you sure you want to archive this user account?</p>
      </Dialog>
      <Dialog
        show={usersState.action === "emulate"}
        title="Log In As User"
        continueText="Continue"
        continue={continueEmulateUser}
      >
        <p className="my-2">
          Logging in as another user does not allow you to make updates to that user's account
          profile. Continue logging in as this user?
        </p>
      </Dialog>
      <Dialog
        show={selectedValues.length > 0}
        title="Archived Users"
        continueText="Archive"
        continue={continueArchive}
        fetching={loading}
      >
        <p>Are you sure you want to archive these users?</p>
      </Dialog>
    </Card>
  );
};

export default AllUsers;
