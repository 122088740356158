/* eslint-disable no-shadow */
export enum SellerActions {
  open,
  archive,
  delete,
  block_payment,
}

export enum SellerStatus {
  open = "Open",
  pending = "Pending",
  archived = "Archived",
  suspended = "Suspended",
  closed = "Closed",
}

export interface CloseStatus {
  close_from: string;
  close_to?: string | null;
}

export interface StatusObj {
  archived?: {};
  pending?: string;
  suspended?: string;
  block_payment?: string;
}

export interface ActionData {
  action: string;
  display?: string;
  icon?: string;
  dlgMsg?: string;
  dlgTitle?: string;
}

export interface SellersState {
  action: string;
  showActionDialog: boolean;
  actionSellerId: string;
}

export const sellerActionData = [
  {
    action: "add_archived",
    display: "Archive",
    icon: "bi-archive-fill",
    css: "btn-danger",
    dlgMsg:
      "If you archive this seller, all of its offers will be deactivated and its assigned users will be unable to access it. Would you like to archive this seller?",
  },
  {
    action: "add_block_payment",
    display: "Block Payment",
    icon: "bi-pause-btn",
    dlgTitle: "Block Payment For Seller",
    css: "btn-danger",
    dlgMsg:
      "This will prevent the automatic generation of an invoice for this seller at the end of its billing period. Are you sure you want to block the payment?",
  },
  {
    action: "add_bulk_archived",
    display: "Archive",
    icon: "",
    dlgTitle: "Archive Sellers",
    dlgMsg: `If you archive the sellers, all of its offers will be deactivated and its assigned users will be unable to access them. 
      Would you like to archive the sellers?`,
  },
  {
    action: "add_suspended",
    display: "Suspend",
    icon: "bi-pause-btn",
    css: "btn-danger",
    dlgMsg:
      "A suspended seller will remain accessible to all assigned users and will only deactivate its offers. Do you want to suspend this seller?",
  },
  { action: "edit", display: "Edit", icon: "bi-pencil" },
  { action: "delete", display: "Delete", icon: "bi-trash", css: "btn-danger" },
  { action: "remove_archived", display: "Restore", icon: "bi-shop", css: "btn-success" },
  {
    action: "remove_block_payment",
    display: "Resume Payment",
    icon: "bi-shop",
    dlgTitle: "Resume Payment For Seller",
    dlgMsg: "Are you sure you want to resume payment for this seller?",
    css: "btn-success",
  },
  {
    action: "add_bulk_archived",
    display: "Archive",
    icon: "",
    dlgTitle: "Archive Sellers",
    dlgMsg: `If you archive the sellers, all of its offers will 
      be deactivated and its assigned users will be unable to access them. 
      Would you like to archive the sellers?`,
  },
  {
    action: "remove_bulk_pending",
    display: "Open",
    icon: "",
    dlgTitle: "Open Multiple Sellers",
    dlgMsg: "Open selected sellers?",
  },
  { action: "remove_closed", display: "Open", icon: "bi-shop" },
  { action: "remove_pending", display: "Open", icon: "bi-shop" },
  {
    action: "remove_suspended",
    display: "Resume",
    icon: "bi-shop",
    dlMsg: "Are you sure you want to resume this seller?",
    css: "btn-success",
  },
  { action: "set_closed", display: "Close", icon: "bi-x-circle" },
  { action: "set_unclosed", display: "Open", icon: "bi-shop" },
];

export const getActionProp = (actionName: string, propName: string) => {
  const action = sellerActionData.find((act) => act.action === actionName);
  let prop;
  switch (propName) {
    case "display":
      prop = action?.display;
      break;
    case "dlgTitle":
      prop = action?.dlgTitle ?? `${action?.display} Seller`;
      break;
    case "dlgMsg":
      prop =
        action?.dlgMsg ?? `Are you sure you want to ${action?.display.toLowerCase()} this seller?`;
      break;
    case "css":
      prop = action?.css;
      break;
  }
  return prop ?? "";
};
