import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useArchivedTenantAdminUsersCountQuery, useArchivedTenantsInfoQuery, useUpdateTenantStatusMutation } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";


let columnNames = ["created", "Tenant Name", "Environment Type", "sellers", "users", "Date Archived", "archived_by", "action"];
const ArchivedTenantsList = () => {
  const [context] = useMainContext();
  const formatDate = useDateFormat();
  const [loading1, setLoading] = useState<number>(-1);
  const multiSelectRef = useRef<IRef | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [tenantId, setTenantId] = useState<string>();
  const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
  const userInfo = useUserInfo()!;
  const emulating = !isNullOrEmpty(userInfo.emulater);

  const { number_items_per_page } = context.operatorSettings.preset;
  const navigate = useNavigate();
  const baseRoute = "/tenants";

  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Created",
    column_name: "created_date",
    orderBy: "desc",
  });


  const customRule = ',"operator_status": { "_in": "Archived" }'
  const orderByOptions: { [key: string]: string } = {
    created: "created_date",
    Tenant_Name: "operator_name",
    sellers: "seller_count",
    Environment_Type: "environment_type",
    users: "user_count",
    Date_Archived: "archived_at",
    archived_by: "archived_by",
    status: "operator_status"
  };

  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }]
    : undefined

  const input = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(['operator_status', 'operator_name'], '%%'),
          dateRange,
          customRule,
          selectedFilters,
        })
      ),
    }
  }
  const [adminsData] = useArchivedTenantsInfoQuery(input)

  const [archivedAdminsCountData] = useArchivedTenantAdminUsersCountQuery({
    variables: {
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(['operator_status', 'operator_name'], '%%'),
          dateRange,
          customRule,
          selectedFilters,
        })
      ),
    },
  });

  const [, updateTenantStatusMutation] = useUpdateTenantStatusMutation();

  const continueUpdateStatusTenant = (isContinue: boolean) => {
    if (isContinue) {
      updateTenantStatusMutation({ tenantId: tenantId, status: "Active", archived: null }, { additionalTypenames: ["vw_tenant_info"] })
        .then((res) => {
          alertsRef.current?.generate(mutationInfo("the operator status", MutationAction.Update, res));
          if (res.data && !res.error) {
            setShowActionDialog(false)
          }
        }).catch((error) => {
          alertsRef.current?.generate(mutationInfo("the operator status", MutationAction.Update, error));
        })
    } else {
      setShowActionDialog(false)
    }
  }
  useEffect(() => {
    if (adminsData.data) {
      setLoading(ELoadingType.None);
    }
  }, [adminsData.data]);

  const error = adminsData.error;
  if (error) {
    return <PageError error={{ source: "ArchivedTenantList", errMsg: error.message }} />;
  }

  if (!adminsData.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
      </Card>
    );
  }

  const tenants = adminsData.data?.vw_tenant_info;
  const totalRecords = archivedAdminsCountData.data?.total_admins.aggregate?.count;

  const data = tenants.map((tenant) => {
    const actions = [];
    actions.push({
      actionType: "edit",
      id: tenant.operator_id,
      icon: "bi bi-pencil",
      actionFunc: (id: string) => {
        navigate(`${baseRoute}/${id}`)
      }
    });
    actions.push({
      actionType: "restore",
      id: tenant.operator_id,
      icon: "bi bi-arrow-counterclockwise",
      actionFunc: (id: string) => {
        setTenantId(tenant.operator_id)
        setShowActionDialog(true)
      }
    })

    return {
      id: tenant.user_id,
      operator_user: tenant.email,
      role: tenant.role_name,
      Tenant_Name: tenant.operator_name,
      sellers: tenant.seller_count,
      users: tenant.user_count,
      Date_Archived: <div className="date-created">
        <p className="mb-0">{formatDate(tenant.archived_at)}</p>
        <span className="small text-muted">{formatDate(tenant.archived_at, "time")}</span>
      </div>,
      archived_by: tenant.archived_by,
      created: <div className="date-created">
        <p className="mb-0">{formatDate(tenant.created_date)}</p>
        <span className="small text-muted">{formatDate(tenant.created_date, "time")}</span>
      </div>,
      action: actions.length > 0 && <DropdownItems items={actions} />,
      Environment_Type: tenant.environment_type
    };
  });

  const applyChanges = () => {
    setSelectedValues(multiSelectRef.current?.selectedData || []);
    return null;
  };

  const options = ["Status: Restore"];
  return (
    <>
      <Card>
        <Table
          columnNames={columnNames}
          data={data}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalRecords || 0}
          setSearchValue={setSearchValue}
          filters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          ref={multiSelectRef}
          options={options}
          applyChanges={applyChanges}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading1}
          setLoading={setLoading}
          dateRange={dateRange}
          setDateRange={setDateRange}
          searchPlaceHolder="Search by Tenant..."
          searchHint="Search by Tenant" />
      </Card>
      <Dialog
        show={showActionDialog}
        title="Restore Tenant"
        continueText="Restore"
        continue={continueUpdateStatusTenant}
        contineBtnCss="btn-success">
        <p className="my-2">
          Are you sure you want to restore this tenant?
        </p>
      </Dialog>
    </>
  )
}

export default ArchivedTenantsList