import { FC, useEffect, useState } from "react";
import {
  OrderItemPartFragment,
  TrackingItemPartFragment,
  useDeleteTrackingItemMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { Table } from "../../common/components/Table/index";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction, SelectOption } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { OrderItemBriefDescription } from "./OrderItems";
import { useMainContext } from "../../layout/components/MainProvider";
import TrackingDetails from "./TrackingDetails";
interface IProp {
  status: string;
  orderItems: OrderItemPartFragment[];
  shippedItems: React.MutableRefObject<{
    [key: string]: number;
  }>;
  availableFunc: () => void;
  availableQuantity: { [key: string]: number };
  adjust: boolean;
  exceedItems: string[];
  trackingItems: TrackingItemPartFragment[] | undefined;
  carriers: SelectOption[];
}
export type ShippingItem = { quantity: string; order_item_id: string };
const TrackingInfoTable: FC<IProp> = ({
  status,
  orderItems,
  shippedItems,
  availableFunc,
  availableQuantity,
  adjust,
  exceedItems,
  trackingItems,
  carriers
}) => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const isSeller = !!userInfo.seller_id;
  const defState = {
    action: "",
    ids: null,
    item: null
  }
  const [actionState, setActionState] = useState<IActionState>(defState);
  const deleteTrackingItemMutation = useDeleteTrackingItemMutation()[1];
  const [offset, setOffset] = useState<number>(0);
  const shipped: { [key: string]: number } = {};
  const columnNames = ["tracking number", "items", "carrier"];

  useEffect(() => {
    availableFunc();
  }, [trackingItems]);

  trackingItems?.forEach((item) => {
    const { shipping_items } = item;
    shipping_items.forEach((shipping: ShippingItem) => {
      shipped[shipping.order_item_id] =
        (shipped[shipping.order_item_id] ? shipped[shipping.order_item_id] : 0) +
        Number(shipping.quantity);
    });
  });
  shippedItems.current = shipped;
  const data = trackingItems?.map((item, index) => {
    const actions = [
      {
        enabled: isSeller && userInfo.permissions! & context.permissions.manage_offer,
        actionType: "edit",
        id: item.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: () => {
          availableFunc();
          setActionState({...actionState, action: "Edit", item});
        },
      },
      {
        enabled: isSeller && userInfo.permissions! & context.permissions.manage_offer,
        actionType: "delete",
        id: item.id,
        display: "Delete",
        icon: "bi bi-pencil",
        actionFunc: () => {
          setActionState({...actionState, action: "Delete", item});
        },
      },
    ];
    const showWarning = (str: string) =>
      status === "AwaitingFulfillment" && adjust && exceedItems.find((value) => value === str);
    return {
      id: item.id + index,
      tracking_number: item.tracking_number,
      items: (
        <div className="d-flex flex-column my-2">
          {item.shipping_items.map((shipping_item: ShippingItem, key: number) => {
            const orderItem = orderItems.find(
              oitem => oitem.id === shipping_item.order_item_id
            );

            return (
              orderItem ? <div key={key} className="mb-2">
                <OrderItemBriefDescription orderItem={orderItem} />
                <p className="mb-1 text-muted">
                  <strong
                    className={`${showWarning(shipping_item.order_item_id) ? "text-danger" : ""}`}
                  >
                    Quantity: {shipping_item.quantity}
                    {showWarning(shipping_item.order_item_id) && (
                      <span className="ms-2 tooltip-custom top">
                        <span className="tooltip-text">Shipping Information Quantity exceeds the Order Item Quantity entered. Please
                          adjust the existing tracking information.
                        </span>
                        <i className="bi bi-info-circle-fill"></i>
                      </span>
                    )}
                  </strong>
                </p> 
              </div> : null
            );
          })}
        </div>
      ),
      carrier: item.carrier_key,
      action: <DropdownItems items={actions} />,
    };
  });

  const continueAction = async (isContinue: boolean) => {
    if(!isContinue)
      setActionState(defState);
    else {
      const res = await deleteTrackingItemMutation({ id: actionState.item?.id });
      alertsRef.current?.generate(mutationInfo("tracking info", MutationAction.Delete, res));
      setActionState(defState);
    }
  }

  return (
    <>
      <Table
        columnNames={status === "AwaitingFulfillment" ? columnNames.concat("action") : columnNames}
        data={data || []}
        offset={offset}
        setOffset={setOffset}
        totalRecords={0}
      />
      {actionState.action === "Edit" &&
        <TrackingDetails 
          orderItems={orderItems} 
          availableQuantity={availableQuantity}
          trackingItem={actionState.item!} 
          close={()=> setActionState(defState)}   
          carriers={carriers}               
        />}
      <Dialog
        show={actionState.action === "Delete"}
        title="Delete Tracking Information"
        continueText="Delete"
        continue={continueAction}
        contineBtnCss="btn-danger"
      >
        <p className="mb-2">
          Are you sure you want to permanently delete this tracking information?
        </p>
      </Dialog>
    </>
  );
};
export default TrackingInfoTable;
