import { useEffect, useState } from "react";

export function useMatchMedia(mediaQuery: string) {
  const [matchesMediaQuery, setMatchesMediaQuery] = useState(() => {
    return window.matchMedia(mediaQuery).matches;
  });
  useEffect(() => {
    let mql = window.matchMedia(mediaQuery);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mediaQueryChanged = (e: any) => {
      setMatchesMediaQuery(e.matches);
    };
    mql.addEventListener("change", mediaQueryChanged);
    return () => {
      mql.removeEventListener("change", mediaQueryChanged);
    };
  }, [mediaQuery]);
  return matchesMediaQuery;
}
