import { FC, useState } from "react";
import { useProductGroupPageQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { Table } from "../../common/components/Table/index";
import { SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { AddProductGroup } from "./AddProductGroup";

export const ProductGroup: FC = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const continueSave = (isContinue: boolean) => {
    if (!isContinue) {
      setShowDialog(false);
    }
  };
  const tableColumns = ["Name", "Categories", "Sellers", "Actions"];
  const [queried] = useProductGroupPageQuery();

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ProductGroup", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />;
  }

  const subctgOptions = queried.data.category.map((item) => item as SelectOption);
  const tableData = queried.data.configs_product_group.map((data) => {
    const number = data.product_group_store_jncs_aggregate.aggregate?.count;
    const ctgs = data.categories
      .map((ctg: string) => subctgOptions.find((option) => option.value === ctg)?.label)
      .join(", ");
    return {
      id: data.id,
      Name: <p className="py-2 mb-0">{data.name}</p>,
      Categories: ctgs,
      Sellers: number,
      Actions: <i className="bi bi-three-dots"></i>,
    };
  });
  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          data={tableData}
          offset={0}
          setOffset={() => { }}
          multiSelectTable
          totalRecords={0}
        />
        <div className="d-flex flex-row align-items-start">
          <Button data-testid="" className="btn btn-primary" onClick={() => setShowDialog(true)}>
            Add Product Group
          </Button>
        </div>
      </Card>
      <Dialog
        show={showDialog}
        subFormId="product-group-form"
        title={`Add Product Group`}
        continueText="Save"
        staticModal={true}
        continue={continueSave}
        size="lg"
      >
        <AddProductGroup closeDialog={() => setShowDialog(false)} subctgOptions={subctgOptions} />
      </Dialog>
    </>
  );
};
