import { FC } from "react";

export const ErrorNetwork: FC = () => {
  return (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <h1 className="error-number"><i className="bi bi-wifi-off text-muted"></i></h1>
      <p className="mini-text">Connection Issue</p>
      <p className="error-text mb-4 mt-1">
      You encountered a temporary network issue.
      Please check network connection and try again 
      or contact your administrator if you continue to have problem.</p>
      <a className="btn btn-primary" href={window.location.href}>Try Again</a>
    </div>
  );
};
