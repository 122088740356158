import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Message_Insert_Input,
  useCreateOrderMessageMutation,
  useOrderMessagesByOrderIdSubscription
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import FileLink from "../../common/components/FileLink";
import Separator from "../../common/components/Separator";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadOrderFile } from "../../common/miscellaneous/storage";
import { getFileExt, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { TextArea } from "../../forms/components/Textarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";


interface IOrderMessage {
  order_id: string;
  message_text: string | null | undefined;
  created_date: string;
  user_info?: any;
  customer_info?: any;
  file_name?: string | null | undefined;
  file_path?: string | null | undefined;
  message_from: string;
  user: any;
}

interface IFileInfo {
  file_name: string;
  file_path: string;
  file_size: number;
}

interface ICustomer {
  fullName: string | null | undefined;
  email: string | null | undefined;
}

const MessagesList = (props: { orderMessages: IOrderMessage[] | undefined | null }) => {
  const orderMessages = props.orderMessages;
  const isDateSame = (date1: string, date2: string) => {
    if (date1 == "" || date1 == null) return false;

    const dateOne = new Date(date1);
    const dateTwo = new Date(date2);
    dateOne.setHours(dateOne.getHours() - 8);
    dateTwo.setHours(dateTwo.getHours() - 8);

    if (
      dateOne.getDate() == dateTwo.getDate() &&
      dateOne.getMonth() == dateTwo.getMonth() &&
      dateOne.getFullYear() == dateTwo.getFullYear()
    )
      return true;

    return false;
  };

  let lastDate: string = "";

  const formatTime = (date: string) => {
    const datetimeDate = new Date(date);
    // datetimeDate.setHours(datetimeDate.getHours() - 8);
    let displayTime = datetimeDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return displayTime;
  };
  const messagesDiv = useRef<HTMLDivElement>(null);
  const scrollDown = () => {
    messagesDiv.current?.scrollTo(0, messagesDiv.current?.scrollHeight);
  };

  useEffect(() => {
    scrollDown();
  }, [props]);

  const noMessages = orderMessages == null || orderMessages?.length === 0;

  if (noMessages) return <div className="text-center mb-3">This order has no messages.</div>;
  else
    return (
      <>
        <div
          style={{ overflowY: "scroll", maxHeight: "350px" }}
          className="border mb-3"
          ref={messagesDiv}
        >
          {orderMessages?.map((note, index) => {
            console.log(note, "dwww")
            if (note.message_from == "Seller")
              return (
                <div key={index}>
                  <Separator
                    display={!isDateSame(lastDate, note.created_date)}
                    date={note.created_date}
                  />

                  <div className="d-none">{(lastDate = note.created_date)}</div>
                  <div className="message-wrapper d-flex justify-content-start">
                    <div className="message-avatar">
                      {note.user && (
                        <img
                          className="rounded-circle"
                          src={note.user?.profile_image}
                          alt={note.user?.profile_image}
                        />
                      )}
                    </div>
                    <div className="message-content col-md-5 mt-2">
                      <span className="font-weight-medium me-2">
                        {note.user_info?.full_name ?? note.user_info?.email ?? ""}
                      </span>
                      <span className="text-muted small">{formatTime(note.created_date)}</span>
                      <div className="rounded bg-light mt-2 p-3">
                        <div className="mb-1">{note.message_text ?? ""}</div>
                        <FileLink fileName={note.file_name} filePath={note.file_path} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            if (note.message_from == "Customer")
              return (
                <div key={index}>
                  <Separator
                    display={!isDateSame(lastDate, note.created_date)}
                    date={note.created_date}
                  />
                  <div className="d-none">{(lastDate = note.created_date)}</div>
                  <div className="m-3 pb-3 d-flex justify-content-end" key={index}>
                    <div className="mt-2 col-md-5 text-right">
                      <b>{note.customer_info?.full_name ?? ""}</b> - {formatTime(note.created_date)}
                      <div className="rounded bg-light mt-2 p-3">
                        <div className="mb-1">{note.message_text ?? ""}</div>
                        <FileLink fileName={note.file_name} filePath={note.file_path} />
                      </div>
                    </div>
                    <div className="message-avatar">
                      {note.user && (
                        <img
                          className="rounded-circle"
                          src={note.user?.profile_image}
                          alt={note.user?.profile_image}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </>
    );
};

const OrderNotesComponent = (props: { orderId: string, sellerId: string, customerDetails: ICustomer }) => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const { preset } = context.operatorSettings;
  const manageOrderMessages = (userInfo.permissions! & context.permissions.manage_order_messages) > 0;
  const accessOrder = (userInfo.permissions! & context.permissions.access_order) > 0;
  const accessMessageAppSetting = context.operatorSettings.seller.allow_sellers_to_manage_messages

  const { orderId, customerDetails } = { ...props };

  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [noteLength, setNoteLength] = useState<number>(0)
  const messageInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBtnEnabled(e.target.value.length > 0 || fileSelected != null);
    setNoteLength(e.target.value.length)
  };
  const currentNote = useRef<HTMLTextAreaElement>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const [fileSelected, setFileSelected] = useState<IFileInfo | null>(null);

  //   const todaysDate: string = new Date().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

  const inputs = { variables: { orderId: orderId } };
  const [queried] = useOrderMessagesByOrderIdSubscription(inputs);
  const orderMessages = queried.data?.message as IOrderMessage[];
  const [loader, setLoader] = useState<boolean>(false);

  const insertMessage = useCreateOrderMessageMutation()[1];

  const handleUploadClick = () => {
    uploadRef.current?.click();
  };

  const allowedFileTypesSetting = preset.document_formats ?? "";
  const maxFileSizeKB = preset.document_size ?? 2048;
  const maxFileSizeMB = Math.round(maxFileSizeKB / 1024);
  const maxFileSizeBytes = maxFileSizeKB * 1024;
  const allowedFileFormats = allowedFileTypesSetting
    .split(",")
    .map((item: string) => {
      let fixedItem = item.trim().toLowerCase();
      if (!fixedItem.startsWith(".")) fixedItem = `.${fixedItem}`;
      return fixedItem;
    })
    .join(",");

  const onFileBlobUpload = async (file: File) => { return await uploadOrderFile(file); }

  const setupFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selected = event.target.files[0];
      // checking for just the extension or MIME type may not be secure enough?
      const extension = getFileExt(selected.name);
      const allowedTypes = allowedFileFormats.split(",");
      const typeAllowed = allowedTypes.indexOf(extension) > -1;
      if (!typeAllowed) {
        alertsRef.current?.add("This file type is not allowed", "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else if (selected.size > maxFileSizeBytes) {
        alertsRef.current?.add(`Maximum allowed file size is ${maxFileSizeMB}MB`, "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else {
        const uploadedFile: IFileInfo = {
          file_name: selected.name,
          file_path: "",
          file_size: selected.size,
        };
        setFileSelected(uploadedFile);
        setBtnEnabled(true);
      }
    }
  };

  const postNote = async (note: Message_Insert_Input) => {
    const res = await insertMessage({ data: note });

    if (res && res.error) {
      alertsRef.current?.generate(mutationInfo("order note", MutationAction.Add, res));
    }
    setLoader(false);
    if (currentNote.current != null) currentNote.current.value = "";
    if (uploadRef.current !== null) uploadRef.current.value = "";
    setBtnEnabled(false);
    setFileSelected(null);
  };

  const addNote = async (e: any) => {
    e.preventDefault();
    const user = {
      full_name: userInfo.full_name,
      email: userInfo.email,
    };

    let messageObject: Message_Insert_Input = {
      order_id: orderId,
      user_id: userInfo.user_id,
      message_text: currentNote.current?.value,
      user_info: user,
      file_name: null,
      file_path: null,
      seller_id: userInfo.seller_id || props.sellerId,
      message_type: "Order",
      operator_id: userInfo.operator_id,
      customer_email: customerDetails.email,
      message_from: "Seller"
    };

    if (uploadRef.current?.files && uploadRef.current.files.length > 0) {
      setLoader(true);
      const selected = uploadRef.current.files[0];
      const uploadResult = onFileBlobUpload(selected);
      uploadResult.then((url) => {
        messageObject.file_name = selected.name;
        messageObject.file_path = url;
        postNote(messageObject);
      });

      uploadResult.catch((err) => {
        alertsRef.current?.add(`File upload failed: ${err}`, "error");
        removeSelectedFile();
        setLoader(false);
      });
    } else if (messageObject.message_text != null && messageObject.message_text != "") {
      setLoader(true);
      postNote(messageObject);
    }
  };

  const removeSelectedFile = () => {
    setFileSelected(null);
    if (uploadRef.current !== null) uploadRef.current.value = "";

    const curMessage = currentNote?.current?.value;
    if (curMessage != null && curMessage.length == 0) setBtnEnabled(false);
  };

  return (
    <>
      {accessOrder && accessMessageAppSetting && (
        <div className="col-12">
          <Card className="mb-3">
            <div className="row">
              <div className="col-md-12">
                <div className="border-bottom card-header">
                  <h5 className="d-flex justify-content-between card-title mb-0 align-items-center">
                    <span>
                      <i className="bi bi-chat-left-text me-2"></i>
                      <span>Order Messages</span>
                    </span>
                  </h5>
                </div>
                <div className="card-body">
                  <MessagesList orderMessages={orderMessages} />
                  {manageOrderMessages && (
                    <form onSubmit={(e) => addNote(e)}>
                      <div className="d-flex input-group">
                        <TextArea
                          data-testid=""
                          ref={currentNote}
                          className="form-control p-2"
                          placeholder="Write a message"
                          onChange={(e) => messageInputChange(e)}
                          rows={1}
                          maxLength={3000}
                        />
                        {!loader && (
                          <>
                            <Button
                              data-testid=""
                              role="button"
                              className="btn btn-light border"
                              type="button"
                              title="Attach file"
                              onClick={handleUploadClick}
                            >
                              <i
                                role="button"
                                className="bi bi-paperclip fa-lg"
                                title="paperclip icon"
                              ></i>
                            </Button>
                            <Input
                              id="file"
                              type="file"
                              ref={uploadRef}
                              style={{ display: "none" }}
                              data-testid="message-file-upload"
                              accept={allowedFileFormats}
                              onChange={setupFileUpload}
                            />
                          </>
                        )}
                        {loader ? (
                          <Button data-testid="" className="btn btn-primary" disabled>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </Button>
                        ) : (

                          <Button
                            data-testid=""
                            className="btn btn-primary"
                            disabled={!btnEnabled}
                            type="submit"
                          >Send
                          </Button>
                        )}
                      </div>
                      <div className="d-flex justify-content-end">
                        <small className={`${noteLength >= 3000 ? "text-danger" : "text-muted"}`}>{noteLength}/3000</small>
                      </div>
                    </form>
                  )}
                  {fileSelected && (
                    <div className="d-flex font-weight-light align-items-center justify-content-between px-3 py-1 mt-2 border">
                      <div className="file-name-wrapper me-3">
                        <p className="small file-name font-weight-semi-bold mb-0">
                          {fileSelected?.file_name}
                          <span className="small text-muted ms-2 font-weight-light">
                            {Math.round(fileSelected?.file_size / 1000)} KB
                          </span>
                        </p>
                        <small className="text-muted">Attached</small>
                      </div>
                      <Button
                        data-testid=""
                        className="btn btn-sm btn-danger"
                        onClick={removeSelectedFile}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default OrderNotesComponent;
