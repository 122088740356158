import { FC, useEffect, useRef, useState } from "react";
import { UserProfilePartFragment, useLandingPageQuery, useUpdateUserLandingPageMutation, useUserApiKeyQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Option } from "../../forms/components/Option";
import { Select } from "../../forms/components/Select";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import APIKeyForm from "./APIKeyForm";

interface PropDT {
  data: UserProfilePartFragment;
}

const UserProfileAdvancedSettings: FC<PropDT> = (props) => {
  const { data } = props;
  const [landingPageQueried] = useLandingPageQuery();
  const landingPageSelectRef = useRef<HTMLSelectElement>(null);
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const accessApi = userInfo.permissions! & context.permissions.access_api ? true : false;
  const [landingPageOptions, setLandingPageOptions] = useState<any[]>([])
  const isSeller = !!userInfo.seller_id;

  const apikeyInput = {
    variables: {
      userId: userInfo.user_id,
      sellerId: userInfo.seller_id ?? emptyUuid, // tenant id is handled by hasura permissions
    },
  };

  const [queried] = useUserApiKeyQuery(apikeyInput);
  const [, updateUserLandingPageMutation] = useUpdateUserLandingPageMutation();

  useEffect(() => {
    let allowedLandingPageOptions: any[] = landingPageQueried.data?.landing_page || []

    if (!(userInfo.permissions! & context.permissions.access_seller)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Sellers')
    } else {

      const optionForStoresPage = allowedLandingPageOptions.find(page => page?.page_name === 'Sellers')
      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Sellers')
      allowedLandingPageOptions.splice(1, 0, optionForStoresPage)
    }
    if (!(userInfo.user_level! < 20)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'My Seller')
      // myStore
    }
    if (!(userInfo.permissions! & context.permissions.access_product)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Products')
      //Product
    }
    if (!(userInfo.permissions! & context.permissions.access_offer)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Offers')
      //offers
    }
    if (!(userInfo.permissions! & context.permissions.access_order)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Orders')
    }
    if (!(userInfo.permissions! & context.permissions.access_report)) {

      allowedLandingPageOptions = allowedLandingPageOptions.filter(page => page?.page_name !== 'Reports')
    }

    setLandingPageOptions(allowedLandingPageOptions)

  }, [landingPageQueried])

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "UserProfile-AdvancedSettings", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />;
  }
  const apiKey = queried.data?.api_key.find((akey) => akey)?.key;

  const updateLandingPage = async (e: any) => {
    const res = await updateUserLandingPageMutation({ userId: data.id, landing_page_id: e.target.value })
    alertsRef.current?.generate(mutationInfo("the default landing page", MutationAction.Update, res));
  }

  return (
    <>
      <Card className="pt-4 px-4 pb-5 no-top-border">
        <div>
          <div className="font-weight-medium mb-2">Default Landing Page</div>
          <Select data-testid="" ref={landingPageSelectRef}
            onChange={updateLandingPage}
            {...(data.landing_page_id ?
              { defaultValue: data.landing_page_id } :
              { defaultValue: landingPageQueried.data?.landing_page[0].id })}
            className="form-select w-50">
            {landingPageOptions.map((page, i) => {
              if (page.id === data.landing_page_id) {
                return (<Option value={page.id} data-testid="" key={i} selected
                >{page.page_name ? page.page_name : 'Dashboard'}</Option>)
              }
              else {
                return (<Option value={page.id} data-testid="" key={i}>{page.page_name ? page.page_name : 'Dashboard'}</Option>)
              }
            })
            }
          </Select>
        </div>
        {accessApi && <APIKeyForm api_key={apiKey} />}
      </Card>
    </>
  )
}

export default UserProfileAdvancedSettings


