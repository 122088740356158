import { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../common/components/Card";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { GlobalMessage } from "../../layout/components/GlobalMessage";

export function SupportRoute(): JSX.Element {
  const [borderCss, setBorderCss] = useState<string>("border-success")
  const userInfo = useUserInfo();
  const sellerId = userInfo.seller_id
  return (
    <div className="support-container-inner">
      <ReturnHeader title="Support" />
      <div className="d-flex flex-column flex-xl-row row">
        <div className="col-lg-12 col-xl-6">
          <Card>
            <h5 className="font-weight-semi-bold">Quick support</h5>
            <Link
              to="https://marketpush.freshdesk.com/support/home"
              target="_blank"
            >
              <p className="text-decoration-underline mb-0">Check out our support community</p>
            </Link>
          </Card>
          <Card>
            <h5 className="font-weight-semi-bold">Documentation</h5>
            <Link
              to="https://marketpush.freshdesk.com/support/solutions"
              target="_blank"
            >
              <p className="text-decoration-underline mb-0">Online Knowledge Base</p>
            </Link>
            {/*
            <Link
              to="https://marketpush.freshdesk.com/support/solutions/articles/150000037721-overview"
              target="_blank"
            >
              <p className="text-decoration-underline mb-0">Supported web browsers</p>
            </Link>
  */}
            {userInfo.seller_id ?
              <Link
                to="https://marketpush.freshdesk.com/support/solutions/folders/150000539510"
                target="_blank"
              >
                <p className="text-decoration-underline mb-0">FAQ & Troubleshooting</p>
              </Link>
              :
              <Link
                to="https://marketpush.freshdesk.com/support/solutions/folders/150000539506"
                target="_blank"
              >
                <p className="text-decoration-underline mb-0">FAQ & Troubleshooting</p>
              </Link>
            }
            {/*
            <Link
              to="https://marketpush.freshdesk.com/support/solutions/articles/150000038827-release-notes"
              target="_blank"
            >
              <p className="text-decoration-underline mb-0">MarketPush release notes</p>
            </Link>
          */}
          </Card>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className={`bg-white shadow-sm p-0 mb-4 bg-white rounded-bottom border-top ${borderCss} border-4 p-0`}>
            <div className="p-3">
              <h5 className="font-weight-semi-bold">MarketPush Cloud Platform Status</h5>
              <GlobalMessage setBorder={setBorderCss} />
            </div>
            <Link to="/" data-testid="" className="btn btn-light w-100 bg-ghost-white text-start">
              <i className="bi bi-arrow-right pe-2" />
              Return to {sellerId ? "My Seller" : "Dashboard"}
            </Link>
          </div>
        </div>
      </div>
    </div >
  );
}