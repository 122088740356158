import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  AppSettingPartFragment,
  App_Setting_Category_Enum,
  useAppSettingByCtgQuery,
  useUpdateAppSettingsMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { daysOfWeek, timezones } from "../../common/miscellaneous/data";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { WeeklyOnElements } from "../../settings-route/components/WeeklyOnElements";

interface IProps {
  category: App_Setting_Category_Enum;
}

export const SettingsByCategory: FC<IProps> = ({ category }: IProps): ReturnType<FC> => {
  const [context] = useMainContext();
  const opSettings = context.operatorSettings;
  const userInfo = useUserInfo();
  const inputs = { variables: { category } };
  const [queried] = useAppSettingByCtgQuery(inputs);
  const [{ fetching }, updateMutation] = useUpdateAppSettingsMutation();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const emulating = !isNullOrEmpty(userInfo.emulater);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields },
    setValue,
    getValues
  } = methods;

  useEffect(() => {
    let settings = {};
    switch (category) {
      case App_Setting_Category_Enum.Offer:
        settings = opSettings.offer;
        break;
      case App_Setting_Category_Enum.Order:
        settings = opSettings.order;
        break;
      case App_Setting_Category_Enum.Preset:
        settings = opSettings.preset;
        break;
      case App_Setting_Category_Enum.Seller:
        settings = opSettings.seller;
        break;
      case App_Setting_Category_Enum.Product:
        settings = opSettings.product;
        break;
      case App_Setting_Category_Enum.System:
        settings = opSettings.system;
        break;
    };
    reset(settings);
  }, [opSettings]);

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  if (queried.error)
    return <p>{queried.error.message}</p>;
  if (!queried.data)
    return <Loader />;

  const optionsHash: IHash = {};
  optionsHash["timezones"] = timezones.map(tz => ({ label: tz.name, value: tz.name }));
  const watches: IHash = {};
  const chdParent: IHash = {};
  queried.data?.app_setting.filter(setting => setting.properties.children)
    .forEach(setting => {
      watches[setting.name] = watch(setting.name);
      setting.properties.children.forEach((chd: string) => chdParent[chd] = setting.name);
    });

  const onSubmit = async (data: FieldValues) => {
    const chdDefVals: IHash = {};
    queried.data?.app_setting.filter(setting => setting.properties.is_child).forEach(setting => {
      const pfe = setting.properties.parent_false_enabled || false;
      const pName = chdParent[setting.name];
      if (watches[pName] === pfe)
        chdDefVals[setting.name] = setting.default_value;
    });

    const defKeys = Object.keys(chdDefVals);
    const settings = Object.keys(data).map((key) => {
      const value = defKeys.includes(key) ? chdDefVals[key] : data[key]?.toString();
      const sysSetting = {
        _set: { value, updated_date: "now()" },
        where: {
          app_setting: { name: { _eq: key }, category: { _eq: category } }
        }
      };
      const opSetting = { ...sysSetting, where: { ...sysSetting.where, operator_id: { _eq: userInfo.operator_id } } };
      return category === App_Setting_Category_Enum.System ? sysSetting : opSetting;
    }) as any;

    const res = await updateMutation({ settings });
    if (!res.error) {
      setFormChanged(false);
      appRef.current?.updateStatus(false)
    }
    alertsRef.current?.generate(mutationInfo("product setting", MutationAction.Update, res));
  };

  const getInputComponent = (setting: AppSettingPartFragment, addLabel: boolean, required: boolean = false) => {
    const constraints = {} as IHash;
    Object.keys(setting.properties).filter(key => ["max", "min", "maxLength", "minLength"].includes(key))
      .forEach(key => constraints[key] = setting.properties[key]);

    return <div key={category.toString() + "_" + setting.id}>
      {(setting.properties.options || setting.properties.alter_options) ?
        <FormSelect
          name={setting.name}
          label={addLabel ? setting.description! : ""}
          options={setting.properties.options || optionsHash[setting.properties.alter_options]}
          css="mb-0"
          disabled={setting.readonly ? setting.readonly : false}
        />
        :
        <>
          {setting.data_type === "number" &&
            <FormInput
              name={setting.name}
              label={addLabel ? setting.description! : ""}
              type="number"
              reg_options={{ ...constraints, valueAsNumber: true, required }}
              css="mb-0"
              measurelabel={setting.properties.unit}
            />}
          {setting.data_type === "boolean" &&
            <FormInput
              type="checkbox"
              name={setting.name}
              label={addLabel ? setting.description! : ""}
              css="mb-0"
              isswitch={true}
            />
          }
          {setting.data_type === "text" &&
            (setting.properties.ui_component ?
              <>
                {setting.properties.ui_component === "textarea" &&
                  <FormTextarea
                    name={setting.name}
                    label={addLabel ? setting.description! : ""}
                    css="mb-0"
                    reg_options={{ required }}
                  />}
                {setting.properties.ui_component === "weekdays" &&
                  <WeeklyOnElements
                    elements={daysOfWeek}
                    day={watch(setting.name)}
                    ifEdit={true}
                    hideLabel={true}
                    setDay={(day: string) => {
                      const prevValues = getValues(setting.name);
                      let values = prevValues ? prevValues.split(',') : [];
                      values = values.includes(day) ? values.filter((val: string) => val !== day) : [...values, day];
                      setValue(setting.name, values.join(","), { shouldDirty: true });
                    }}
                  />}
              </>
              : <FormInput
                name={setting.name}
                label={addLabel ? setting.description! : ""}
                css="mb-0"
                reg_options={{ required }}
              />)}
        </>}
    </div>;
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Form
          data-testid="setting-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <Card>
            <table className="table">
              <tbody>
                {queried.data?.app_setting.filter(setting => (!setting.admin_only || emulating || userInfo.user_level! >= 30) && !setting.properties.is_child)
                  .map(setting => (
                    <tr key={setting.id}>
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          <label>
                            {setting.admin_only && <span title="Admin Only" className="text-success me-1"><i className="bi bi-person-lock"></i></span>}
                            {setting.description}
                            {setting.properties.tooltip &&
                              <span className="tooltip-custom top ms-2">
                                <span className="tooltip-text">{setting.properties.tooltip}
                                </span>
                                <i className="bi bi-info-circle-fill text-muted"></i>
                              </span>}
                          </label>
                          <div className="">
                            {getInputComponent(setting, false)}
                          </div>
                        </div>
                        {Object.keys(watches).includes(setting.name) &&
                          <div className="px-2 mt-2">
                            {setting.properties.children.map((chdKey: string) => {
                              const chdSetting = queried.data?.app_setting.find(chd => chd.name === chdKey);
                              const pfe = chdSetting?.properties.parent_false_enabled || false;
                              return chdSetting && pfe !== watches[setting.name] ?
                                (getInputComponent(chdSetting!, true, true)) : null;
                            })}
                          </div>}
                      </td>
                    </tr>)
                  )}
              </tbody>
            </table>
          </Card>
          <div className="d-flex justify-content-end mt-3">
            {fetching ? (
              <Button data-testid="" className="btn btn-primary" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
            ) : (
              <Button
                data-testid=""
                className="btn btn-primary"
                type="submit"
                disabled={!formChanged}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
