import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { EditUser } from "./EditUser";
import { MyNotificationTab } from "./MyNotificationTabs";
import { UserList } from "./UserList";
import { UserProfileTabs } from "./UserProfile-Tabs";

export const UsersRoute: FC = () => {
  return (
    <Routes>
      <Route index element={<UserList />} />
      {["archived", "pending"].map((path, index) =>
        <Route key={`userlist_${index}`} path={path} element={<UserList />} />
      )}
      <Route path=":usershipId" >
        <Route index element={<EditUser />} />
        <Route path="stores" element={<EditUser />} />
      </Route>
      <Route path="notification" >
        <Route index element={<MyNotificationTab />} />
        <Route path="archived" element={<MyNotificationTab />} />
      </Route>
      <Route path="profile">
        <Route index element={<UserProfileTabs />} />
        {["notification", "advanced-settings", "email-notifications"].map((path, index) =>
          <Route key={`profile_${index}`} path={path} element={<UserProfileTabs />} />
        )}
      </Route>
    </Routes>
  );
};
