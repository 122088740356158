import { FC, useEffect, useRef, useState } from "react";
import { Vw_Report_Orders_Over_Time_Order_By, useReportOrdersOverTimeQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { A } from "../../forms/components/A";
import { useMainContext } from "../../layout/components/MainProvider";
import { ColumnAlignLeft } from "./ColumnAlignEnd";
import { ZeroedOutMoney } from "./ZeroedOutMoney";
interface IProp {
  handleCallBack: (where: string,
    orderBy: Vw_Report_Orders_Over_Time_Order_By | Vw_Report_Orders_Over_Time_Order_By[]) => void;
};

export const OrdersOverTimeData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const formatDate = useDateFormat();
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;
  const userInfo = useUserInfo();

  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date_tz",
  });
  const [offset, setOffset] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Date",
    column_name: "created_date_tz",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Date: "created_date_tz",
    Orders: "orders",
    Refunds: "refunds",
    Shipping: "shipping",
    Commission: "commission",
    Total_Sales: "total_sales",
    Gross_Sales: "gross_sales",
  };
  const tableColumnsToSearch: string[] = ["date_text"];

  const tableColumns = [
    "Date",
    "Orders",
    "Gross Sales",
    "Refunds",
    "Shipping",
    "Commission",
    "Total Sales",
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.Date]: "desc" };

  const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    dateRange,
    selectedFilters,
    customRule
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useReportOrdersOverTimeQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "OrdersOverTimeData", errMsg: error.message }} />;
  }

  const rows = queried.data?.vw_report_orders_over_time;
  const totalRows = queried.data?.items?.aggregate?.count ?? 0;
  handleCallBack(handleWhereStr, orderByString);
  const tableData = rows?.map((row) => {
    return {
      id: formatDate(row.created_date_tz),
      Orders: <ColumnAlignLeft className="order-total">{row.orders}</ColumnAlignLeft>,
      Date: (
        <div className="date-created">
          <p className="mb-0">{row.date_text}</p>
        </div>
      ),

      Total_Sales: (
        <A data-testid="" title={`Avg: ${row.avg_total_sales ?? 0}`} >
          <ColumnAlignLeft className="total-sales">
            <ZeroedOutMoney amount={row.total_sales ?? 0} />
          </ColumnAlignLeft>

        </A>
      ),
      Gross_Sales: (
        <A data-testid="" title={`Avg: ${row.avg_gross_sales ?? 0}`}>
          <ColumnAlignLeft className="gross-sales">
            <ZeroedOutMoney amount={row.gross_sales ?? 0} />
          </ColumnAlignLeft>
        </A>
      ),
      Refunds: (
        <A data-testid="" title={`Avg: ${row.avg_refunds ?? 0}`}>
          <ColumnAlignLeft className="refunds">
            <ZeroedOutMoney amount={(row.refunds ?? 0)} />
          </ColumnAlignLeft>
        </A>
      ),
      Shipping: (
        <A data-testid="" title={`Avg: ${row.avg_shipping ?? 0}`}>
          <ColumnAlignLeft className="shipping">
            <ZeroedOutMoney amount={row.shipping ?? 0} />
          </ColumnAlignLeft>
        </A>
      ),
      Commission: (
        <A data-testid="" title={`Avg: ${row.avg_commission ?? 0}`}>
          <ColumnAlignLeft className="commission">
            <ZeroedOutMoney amount={row.commission ?? 0} />
          </ColumnAlignLeft>
        </A>
      ),
    };
  });

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Orders", value: "orders", type: "numeric" },
    { label: "Gross Sales", value: "gross_sales", type: "numeric" },
    { label: "Refunds", value: "refunds", type: "numeric" },
    { label: "Shipping", value: "shipping", type: "numeric" },
    { label: "Commission", value: "commission", type: "numeric" },
    { label: "Total Sales", value: "total_sales", type: "numeric" },
  ];

  return (
    <Card>
      {tableData === undefined ? (
        <>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </>
      ) : (
        <>
          <Table
            setSearchValue={setSearchValue}
            columnNames={tableColumns}
            data={tableData}
            applyChanges={applyChanges}
            offset={offset}
            setOffset={setOffset}
            totalRecords={totalRows}
            ref={multiSelectRef}
            multiSelectTable={false}
            filters
            dateRange={dateRange}
            setDateRange={setdateRange}
            addFilterOptions={addFilterOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setOrderBy={setOrderBy}
            orderByOptions={orderByOptions}
            orderBy={orderBy}
            loading={loading}
            setLoading={setLoading}
            searchPlaceHolder="Search by Date"
            searchHint="Search by Date"
          />
        </>
      )}
    </Card>
  );
};
