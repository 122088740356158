import { FC, useEffect, useMemo, useState } from "react";
import RGL, { Layout, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../common/components/Loader";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";
import { B2BKpi } from "./B2BKpi";
import { BarChartOperator } from "./BarChartOperator";
import { BarChartSeller } from "./BarChartSeller";
import { LineChartComponent } from "./LineChartComponent";
import { OfferStat } from "./OfferStats";
import { OrderStat } from "./OrderStats";
import { PieChartComponent } from "./PieChart";
import { StoreStat } from "./StoreStats";
import { TopSeller } from "./TopSeller";
import { useMatchMedia } from "./useMatchMedia";

const useIsTabletPortrait = () => useMatchMedia("(max-width: 600px)");

const ReactGridLayout = WidthProvider(RGL);

const isTileEqual = (tileA: Layout, tileB: Layout) =>
  !(["x", "y", "i", "w", "h"] as const).some(
    (layoutKey) => tileA[layoutKey as keyof Layout] !== tileB[layoutKey as keyof Layout]
  );

const isLayoutEqual = (layoutA: Layout[], layoutB: Layout[]) => {
  if (layoutA === layoutB) return true;
  if (layoutA.length !== layoutB.length) return false;
  return !layoutA.some((tileA, index) => {
    const tileB = layoutB[index];
    return !isTileEqual(tileA, tileB);
  });
};

const getSortedLayout = (layout: Layout[]) =>
  [...layout].sort((tileA, tileB) => {
    if (tileA.y > tileB.y) {
      return 1;
    }
    if (tileA.y < tileB.y) {
      return -1;
    }
    if (tileA.x > tileB.x) {
      return 1;
    }
    if (tileA.x < tileB.x) {
      return -1;
    }
    return 0;
  });

const generateLayoutByOrder = (layout: Layout[], columnCount: number) => {
  const newLayout: Layout[] = [];
  let currentX = 0;
  let currentY = 0;
  let lineHeight = 0;
  layout.forEach((tile) => {
    if (currentX + tile.w > columnCount) {
      currentX = 0;
      currentY += lineHeight;
      lineHeight = 0;
    }
    newLayout.push({
      i: tile.i,
      w: tile.w,
      h: tile.h,
      x: currentX,
      y: currentY,
    });
    currentX += tile.w;
    lineHeight = Math.max(lineHeight, tile.h);
  });
  return isLayoutEqual(newLayout, layout) ? layout : newLayout;
};

const calculateNewLayout = (currentLayout: Layout[], newLayout: Layout[], baseLayout: Layout[]) => {
  const changedElements = currentLayout.filter(
    (tile, index) => !isTileEqual(tile, newLayout[index])
  );
  return changedElements.length ? newLayout : baseLayout;
};

interface Props {
  userLevel: number;
  sellerId: string;
}
const Grid: FC<Props> = ({ userLevel, sellerId }) => {
  const isTabletPortrait = useIsTabletPortrait();
  const userInfo = useUserInfo();

  let arrayLayout = [];
  if (userLevel < 20) {
    arrayLayout = [
      { i: "a", x: 0, y: 0, w: 4, h: 12 },
      { i: "b", x: 4, y: 0, w: 2, h: 12 },
      { i: "c", x: 0, y: 10, w: 2, h: 10 },
      { i: "d", x: 2, y: 10, w: 2, h: 10 },
    ];
  } else {
    arrayLayout = [
      { i: "a", x: 0, y: 0, w: 4, h: 12 },
      { i: "b", x: 0, y: 12, w: 4, h: 12 },
      { i: "c", x: 4, y: 0, w: 2, h: 12 },
      { i: "d", x: 4, y: 22, w: 2, h: 11 },
      { i: "e", x: 4, y: 12, w: 2, h: 10 },
      { i: "f", x: 0, y: 24, w: 2, h: 10 },
      { i: "g", x: 2, y: 24, w: 2, h: 10 },
    ];
  }

  const [layout, setNewLayout] = useState(arrayLayout);

  useEffect(() => {
    setNewLayout(layout);
  }, [arrayLayout]);

  const columnCount = isTabletPortrait ? 2 : 6;
  // Caclulate the layout based on viewport
  const computedLayout = useMemo(() => {
    const sortedLayout = getSortedLayout(layout);
    if (!isTabletPortrait) {
      return sortedLayout;
    }
    return generateLayoutByOrder(sortedLayout, 2);
  }, [layout, isTabletPortrait]);

  return (
    <>
      {userLevel < 20 ? (
        <ReactGridLayout
          isDraggable={false}
          isDroppable={false}
          isResizable={false}
          className="layout"
          layout={computedLayout}
          cols={columnCount}
          rowHeight={30}
          onLayoutChange={(newLayout) => {
            if (!isLayoutEqual(newLayout, computedLayout)) {
              setNewLayout(calculateNewLayout(computedLayout, newLayout, layout));
            }
          }}
        >
          {userInfo.seller_id ?
            <div className="gridItem p-3 shadow-sm" key="a">
              <BarChartSeller />
            </div>
            : <div className="gridItem p-3 shadow-sm" key="a">
              <BarChartOperator />
            </div>
          }

          <div className="gridItem p-3 shadow-sm" key="b">
            <OrderStat />
          </div>
          <div className="gridItem p-3 shadow-sm" key="c">
            <PieChartComponent />
          </div>
          <div className="p-3 shadow-sm" key="d" style={{ height: 290 }}>
            <OfferStat />
          </div>
        </ReactGridLayout>
      ) : (
        <ReactGridLayout
          isDraggable={false}
          isDroppable={false}
          isResizable={false}
          className="layout"
          layout={computedLayout}
          cols={columnCount}
          rowHeight={30}
          onLayoutChange={(newLayout) => {
            if (!isLayoutEqual(newLayout, computedLayout)) {
              setNewLayout(calculateNewLayout(computedLayout, newLayout, layout));
            }
          }}
        >
          {userInfo.seller_id! ?
            <div className="gridItem p-3 shadow-sm" key="a">
              <BarChartSeller />
            </div>
            : <div className="gridItem p-3 shadow-sm" key="a">
              <BarChartOperator />
            </div>
          }
          <div className="gridItem p-3 shadow-sm" key="b">
            <LineChartComponent />
          </div>
          <div className="gridItem p-3 shadow-sm" key="c">
            <OrderStat />
          </div>
          <div className="gridItem p-3 shadow-sm" key="d">
            <PieChartComponent />
          </div>
          <div className="gridItem p-3 shadow-sm" key="e">
            <StoreStat />
          </div>
          <div className="gridItem p-3 shadow-sm" key="f">
            <TopSeller />
          </div>
          <div className="p-3 shadow-sm" key="g" style={{ height: 200 }}>
            <B2BKpi />
          </div>
        </ReactGridLayout>
      )}
    </>
  );
};

export function DashboardRoute() {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  if (isNullOrEmpty(userInfo.user_id)) {
    return <Loader />;
  }

  let userLevel = userInfo.user_level!;
  if (userLevel >= 30)
    navigate("/tenants");
  let sellerId = userInfo.seller_id;
  // determine time of day
  const timeOfDay = new Date().getHours();

  return (
    <section>
      <h3 className="page-title font-weight-semi-bold mb-0">
        {timeOfDay < 12 ? "Good Morning" : timeOfDay < 18 ? "Good Afternoon" : "Good Evening"}
        {" " + (userProfile.first_name || "") + "!"}
      </h3>
      <p className="text-muted">Here's a look at what's going on in the marketplace.</p>
      <div className="row">
        <div className="col px-0">
          <Grid userLevel={userLevel} sellerId={sellerId} />
        </div>
      </div>
    </section>
  );
}
