/* eslint-disable id-length */

interface IProps {
  numberOfRows?: number;
  columnNames: string[];
}

export function TablePlaceHolder(props: IProps): JSX.Element {
  const { numberOfRows, columnNames } = props;

  const generateRandomCol = (): string => {
    const randomNumber = Math.floor(Math.random() * (9 - 3 + 1)) + 3;
    return `col-${randomNumber}`;
  };

  return (
    <table className="table table-placeholder">
      <thead>
        <tr>
          {columnNames.map((columnName, index) => (
            <th key={`${columnName}${index}`} className={`text-capitalize`}>
              {columnName.toLowerCase() === "seller_account"
                ? "seller"
                : columnName.replace(/[_-]/g, " ")}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="placeholder-glow">
        {Array.from(Array(numberOfRows).keys()).map((fakeRow, index) => (
          <tr key={fakeRow + index}>
            {Array.from(Array(columnNames.length).keys()).map((_, cellIndex) => (
              <td key={cellIndex}>
                <span className={`placeholder ${generateRandomCol()}`} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TablePlaceHolder.defaultProps = {
  numberOfRows: 15,
};
