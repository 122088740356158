import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTenantQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { emptyUuid } from "../../common/miscellaneous/utility";
import TenantForm, { ITenantData } from "./TenantForm";

type Props = {
  isAddForm: boolean,
}

const TenantDetails: FC<Props> = (props): ReturnType<FC> => {
  const { isAddForm } = props
  const { tenantParam } = useParams<{ tenantParam: string }>();
  const tenantId = tenantParam?.length === 36 ? tenantParam : emptyUuid;
  const pageTitle = isAddForm ? "Add Tenant" : "Edit Tenant"
  const navigate = useNavigate();
  const inputs = {
    variables: {
      tenantId: tenantId,
    },
  };
  let tenantData: ITenantData = {
    id: emptyUuid,
    tenant_name: "",
    legal_name: "",
    tenant_url_tag: "",
    website: "",
    city: "",
    line1: "",
    line2: "",
    state: "",
    country: "",
    zipcode: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    alter_phone: "",
    fax: "",
    environment_type: "Production",
    industry_name: "None"
  }


  if (!isAddForm) {
    const [tenantQuery] = useGetTenantQuery(inputs);

    if (tenantQuery.fetching) {
      return <Loader />
    }

    const error = tenantQuery.error;
    if (error) {
      return <PageError error={{ source: "TenantDetails", errMsg: error.message }} />;
    }

    const data = tenantQuery.data?.operator_by_pk;
    if (data) {
      const { id, name, legal_name, tenant_url_tag, website, environment_type, address, contact, industry_name } = data
      tenantData = {
        id: id || emptyUuid,
        tenant_name: name || "",
        legal_name: legal_name || "",
        tenant_url_tag: tenant_url_tag || "",
        website: website || "",
        city: address.city || "",
        line1: address.line1 || "",
        line2: address.line2 || "",
        state: address.state || "",
        country: address.country || "",
        zipcode: address.zipcode || "",
        first_name: contact?.first_name || "",
        last_name: contact?.last_name || "",
        email: contact?.email || "",
        phone: contact?.phone || "",
        alter_phone: contact?.alter_phone || "",
        fax: contact?.fax || "",
        environment_type: environment_type || "Production",
        industry_name: industry_name || "None"
      }
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="col-sm-12 col-xl-8">
          <ReturnHeader title={pageTitle} url="/tenants" />
          <TenantForm updateTenantId={tenantId} tenantData={tenantData} />
        </div>
      </div>
    </>
  )
}

export default TenantDetails