import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OrderInfoPartFragment, OrderItemPartFragment, useMarkAsPaidMutation, useUpdateItemsStatusMutation } from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { OpenIncident } from "./OpenIncident";
import { ReasonDialog } from "./ReasonDialog";
interface IProps {
  pendingItems: string[];
  orderItems: OrderItemPartFragment[];
  orderData: OrderInfoPartFragment
}
const OrderdetailActionButtons: FC<IProps> = ({
  pendingItems,
  orderItems,
  orderData
}) => {
  const { orderId } = useParams<{ orderId: string }>();
  const [openIncident, setOpenIncident] = useState<boolean>(false);
  const [creatingIncident, setCreatingIncident] = useState<boolean>(false);
  const [markAsPaidDialog, setMarkAsPaidDialog] = useState<boolean>(false);
  const userInfo = useUserInfo()!;
  const defState = { ids: null, action: "none" };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const updateStatusMutation = useUpdateItemsStatusMutation()[1];

  const [, markAsPaidMutation] = useMarkAsPaidMutation()

  const navigate = useNavigate();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo?.tenant_url_tag;

  const { id, cp_total_price, debited_date, payment_method, trg_status: order_status, transaction_number } = orderData
  const route = `/${tenantUrlTag}/orders/${id}/refunds`;
  const activeItems: string[] =
    orderItems
      .filter((item) => ["Pending", "Accepted"].includes(item.trg_status || ""))
      .map((item) => item?.id) ?? [];

  const rejectButton = (
    <li className="nav-items">
      <Button
        data-testid="order-reject"
        className="btn btn-danger me-3"
        onClick={() => setActionState({ ids: activeItems, action: "reject_order" })}
      >
        Reject Order
      </Button>
    </li>
  );
  const cancelButton = (
    <li className="nav-items">
      <Button
        data-testid="order-cancel"
        className="btn btn-danger"
        onClick={() => setActionState({ ids: activeItems, action: "cancel_order" })}
      >
        Cancel Order
      </Button>
    </li>
  );
  const acceptButton = (
    <li className="nav-items">
      <Button data-testid="order-accept" className="btn btn-primary" onClick={() => updateStatus()}>
        Accept Order
      </Button>
    </li>
  );

  const openIncidentButton = (
    <li className="nav-items">
      <Button
        data-testid="open-incident"
        className={`me-3 btn btn-outline-danger`}
        onClick={() => setOpenIncident(true)}
      >
        Open Incident
      </Button>
    </li>
  );
  const issueRefundButton = (
    <li className="nav-items">
      <Button
        data-testid="order-refund"
        className={`btn ${cp_total_price && cp_total_price > 0 ? "btn-secondary" : "btn-secondary"}`}
        onClick={() => {
          navigate(route);
        }}
        disabled={cp_total_price && cp_total_price > 0 ? false : true}
      >
        Issue Refund
      </Button>
    </li>
  );

  const markAsPaidButton = (
    <li className="nav-items">
      <Button
        data-testid="order-refund"
        className="ms-3 btn btn-outline-warning"
        onClick={() => { setMarkAsPaidDialog(true) }}>
        Mark as Paid
      </Button>
    </li>
  )

  const canOpenIncident =
    userInfo?.user_level! >= 20 &&
    userInfo.permissions! & context.permissions.manage_order &&
    debited_date &&
    orderItems.find((item) => ["Accepted", "PartialRefund"].includes(item.trg_status || ""));

  const showMarkAsPaidButton = (userInfo.permissions! & context.permissions.manage_order && !debited_date && payment_method == "OnAccount") ||
    (payment_method === "CreditCard" && !transaction_number && !debited_date && order_status === "Shipped")

  const displayButton = (orderStatus: string) => {
    switch (orderStatus) {
      case "AwaitingFulfillment":
        return (
          <>
            {/* {holdButton} */}
            {cancelButton}
          </>
        );
      case "Pending":
        return (
          <>
            {/* {holdButton} */}
            {rejectButton}
            {pendingItems?.length > 0 && acceptButton}
          </>
        );
      case "Shipped":
        return (
          <>
            {canOpenIncident && openIncidentButton}
            {debited_date && issueRefundButton}
            {showMarkAsPaidButton && markAsPaidButton}
          </>
        );
      case "Received":
        return (
          <>
            {canOpenIncident && openIncidentButton}
            {issueRefundButton}
            {showMarkAsPaidButton && markAsPaidButton}
          </>
        );
      case "Rejected":
      case "Closed":
      case "Cancelled":
      default:
        return <></>;
    }
  };

  const continueOpen = (isContinue: boolean) => {
    if (!isContinue) {
      setOpenIncident(false);
      setCreatingIncident(false);
    }
  };

  const updateStatus = async () => {
    const res = await updateStatusMutation({
      updateIds: activeItems,
      itemStatus: "Accepted",
      reason: null,
    }, { additionalTypenames: ["order_aggregate", "order", "search_counts", "vw_order", "search_order"] });
    alertsRef.current?.generate(mutationInfo("order", MutationAction.Update, res));
  };

  const continueMarkAsPaid = async () => {
    const res = await markAsPaidMutation({ orderId })
    alertsRef.current?.generate(mutationInfo("order", MutationAction.Update, res));
    setMarkAsPaidDialog(false)
  }
  return (
    <>
      {!["Cancelled", "Rejected", "Closed", "Refunded"].includes(order_status || "") ? (
        <div className="d-flex flex-xs-column flex-sm-row justify-content-end align-items-start navbar-expand-xl">
          <Button
            data-testid="toggle-button"
            className="btn btn-light navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbarOrderBtns"
            aria-label="Toggle buttons"
          >
            <i className="bi bi-three-dots-vertical"></i>
          </Button>
          <div className="navbarOrderBtns align-items-center navbar-collapse collapse">
            <ul className="navbar-nav ms-xl-auto">{displayButton(order_status || "")}</ul>
          </div>
        </div>
      ) : null}

      <ReasonDialog
        show={!!actionState.ids}
        action={actionState.action}
        itemIds={actionState.ids}
        setClose={() => setActionState(defState)}
      />
      <Dialog
        show={openIncident}
        subFormId="open-incident-form"
        title="Open Incident"
        continueText="Open Incident"
        contineBtnCss="btn-danger"
        staticModal={true}
        continue={continueOpen}
        size="lg"
        fetching={creatingIncident}
      >
        <OpenIncident
          orderItems={orderItems.filter((item) =>
            ["Accepted", "PartialRefund"].includes(item.trg_status || "")
          )}
          setFetching={(processing: boolean) => setCreatingIncident(processing)}
          setClose={() => setOpenIncident(false)}
        />
      </Dialog>
      <Dialog
        show={markAsPaidDialog}
        title="Mark as Paid"
        continueText={"Mark as Paid"}
        continue={continueMarkAsPaid}
        contineBtnCss={"btn-primary"}
      >
        Are you sure you want to mark this order as paid?
      </Dialog>
    </>
  );
};

export default OrderdetailActionButtons;
