import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import {
  BillingListPartFragment,
  GlbAttributeListQuery,
  GroupListQuery,
  ReasonListQuery,
  Reason_Category_Enum,
  RoleListSubscription,
  UserProfilePartFragment,
  useBillingListSubscription,
  useGlbAttributeListQuery,
  useGroupListQuery,
  useReasonListQuery,
  useRoleListSubscription,
  useUserProfileSubscription,
} from "../../../generated/urql-graphql";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, isNullOrEmpty } from "../miscellaneous/utility";

const roleListValue = atom<RoleListSubscription["role"]>({
  key: "roleList",
  default: [
    {
      id: emptyUuid,
      name: "none",
      user_level: 10,
      description: "",
      role_permission_jncs_aggregate: { aggregate: { count: 0 } },
    },
  ],
});

export function useRoleList() {
  const [roleList, setRoleList] = useRecoilState<RoleListSubscription["role"]>(roleListValue);
  const userInfo = useUserInfo()!;
  const inputs = {
    variables: {
      roleId: userInfo.role_id,
    },
    pause: !isNullOrEmpty(roleList.find((role) => role)?.id) || userInfo.role_id === emptyUuid,
  };

  const [subscribed] = useRoleListSubscription(inputs);
  useEffect(() => {
    if (subscribed.data?.role) {
      setRoleList(subscribed.data.role);
    }
  }, [subscribed.data]);

  return roleList;
}

const groupListValue = atom<GroupListQuery["user_group"]>({
  key: "groupList",
  default: [
    {
      id: emptyUuid,
      name: "",
      base_level: 10,
    },
  ],
});

export function useGroupList() {
  const [groupList, setGroupList] = useRecoilState<GroupListQuery["user_group"]>(groupListValue);
  const inputs = {
    pause: !isNullOrEmpty(groupList.find((group) => group)?.id),
  };

  const [queried] = useGroupListQuery(inputs);
  useEffect(() => {
    if (queried.data?.user_group) {
      setGroupList(queried.data.user_group);
    }
  }, [queried.data]);

  return groupList;
}

const reasonListValue = atom<ReasonListQuery["reason"]>({
  key: "reasonList",
  default: [
    {
      value: "",
      label: "",
      category: Reason_Category_Enum.CancelOrder,
    },
  ],
});

export function useReasonList() {
  const [reasonList, setReasonList] = useRecoilState<ReasonListQuery["reason"]>(reasonListValue);
  const [queried] = useReasonListQuery();
  useEffect(() => {
    if (queried.data?.reason) {
      setReasonList(queried.data.reason);
    }
  }, [queried.data]);

  return reasonList;
}

const billingListValue = atom<BillingListPartFragment[]>({
  key: "billingList",
  default: [
    {
      id: emptyUuid,
      billing_period_name: "",
      default: false,
      cp_billing_sellers: 0,
    },
  ],
});

export function useBillingList() {
  const [billingList, setBillingList] = useRecoilState<BillingListPartFragment[]>(
    billingListValue
  );
  const userInfo = useUserInfo();
  const inputs = {
    variables: {
      operatorId: userInfo?.operator_id || emptyUuid,
  },
};
  const [subscribed] = useBillingListSubscription(inputs);
  useEffect(() => {
    if (subscribed.data?.billing_period) {
      setBillingList(subscribed.data.billing_period);
    }
  }, [subscribed.data]);

  return billingList;
}

export interface IAlert {
  id: number;
  message: string;
  autoClose: boolean;
  type: "success" | "error";
}

export const alertsValue = atom<IAlert[]>({
  key: "alerts",
  default: [],
});

export const sidebarState = atom({
  key: "sidebarState",
  default: true,
});

export interface IAppStatus {
  changed: boolean;
}

export const appStatusValue = atom<IAppStatus>({
  key: "appStatus",
  default: {
    changed: false,
  },
});

const glbAttrListValue = atom<GlbAttributeListQuery["attribute"]>({
  key: "glbAttrList",
  default: [
    {
      id: emptyUuid,
      display: "",
      name: "",
      required: false,
      operator_id: emptyUuid,
    },
  ],
});

export function useGblAttrList() {
  const [glbAttrList, setGlbAttrList] = useRecoilState<GlbAttributeListQuery["attribute"]>(glbAttrListValue);
  const userInfo = useUserInfo()!;
  const inputs = {
    variables: {operatorId: userInfo.operator_id},
  };

  const [queried] = useGlbAttributeListQuery(inputs);
  useEffect(() => {
    if (queried.data?.attribute) {
      setGlbAttrList(queried.data.attribute);
    }
  }, [queried.data]);

  return glbAttrList;
}

const userProfileValue = atom<UserProfilePartFragment>({
  key: "userProfile",
  default: {
      id: emptyUuid,
      user_id: emptyUuid,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      landing_page_id: emptyUuid,
      get_notification: false,
      get_email_notification: true,
      profile_image: "",
      info_obj: {},
      page_name: "",
      full_name: ""
    },
});

export function useUserProfile() {
  const [userProfile, setUserProfile] = useRecoilState<UserProfilePartFragment>(
    userProfileValue
  );
  const userInfo = useUserInfo();
  const inputs = {
    variables: {
      userId: userInfo?.user_id || emptyUuid,
      operatorId: userInfo?.operator_id || emptyUuid,
  },
};
  const [subscribed] = useUserProfileSubscription(inputs);
  useEffect(() => {
    if (subscribed.data?.vw_operator_user?.length) {
      setUserProfile(subscribed.data.vw_operator_user.find(profile=>profile)!);
    }
  }, [subscribed.data]);

  return userProfile;
}
