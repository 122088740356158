import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useAllSellersCountQuery,
  useRemoveSellerStatusMutation,
  useVwSellersInitialQuery,
  useVwSellersQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { SellersState } from "../miscellaneous/types";
interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}
interface ArchivedSellersProps {
  refreshCount: () => void;
}
export const ArchivedSellers: FC<ArchivedSellersProps> = (props): ReturnType<FC> => {
  const { refreshCount } = props;
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;
  const [loading, setLoading] = useState<number>(-1);
  const formatDate = useDateFormat();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/sellers`;
  const navigate = useNavigate();
  const [, removeStatusMutation] = useRemoveSellerStatusMutation();
  const userInfo = useUserInfo()!;
  const manageSeller = userInfo.permissions! & context.permissions.manage_seller;

  const [, setSelectedOption] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [, setSelectedValues] = useState<string[]>([]);
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Seller",
    column_name: "company_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Created: "created_date",
    Date_Archived: "archived_at",
    Seller: "company_name",
    Users: "users",
    Archived_By: "archived_by",
    Id: "id"
  };
  const customRule = `,"status_cd":{"_eq":"Archived"}`;

  const tableColumnsToSearch = ["company_name", "archived_by"];

  const [queried] = useVwSellersInitialQuery(
    {
      requestPolicy: 'cache-and-network',
      variables: {
        itemsPerPage: number_items_per_page,
        offset: offset,
        whereValue: JSON.parse(
          handleWhere({
            columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
            dateRange,
            selectedFilters,
            customRule,
          })
        ),
        order_by: orderBy.column_name
          ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.Id]: "asc" }]
          : [{ [orderByOptions.Seller]: "asc" }, { [orderByOptions.Id]: "asc" }]
      },
    });
  const [totalSellers, executeTotalSellersQuery] = useAllSellersCountQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
    },
  });
  const [subscribed, executeVwSellersQuery] = useVwSellersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      itemsPerPage: number_items_per_page,
      offset: offset,
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
      order_by: orderBy.column_name
        ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.Id]: "asc" }]
        : [{ [orderByOptions.Seller]: "asc" }, { [orderByOptions.Id]: "asc" }]
    },
  });
  const multiSelectRef = useRef<IRef | null>(null);

  useEffect(() => {
    if (subscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [subscribed.data]);

  const refreshData = () => {
    executeVwSellersQuery();
    executeTotalSellersQuery();
    refreshCount();
  };


  const checkSelectSellersCount = () => {
    return multiSelectRef.current?.selectedData && multiSelectRef.current?.selectedData.length > 1;
  };

  const defState = {
    action: "",
    showActionDialog: false,
    actionSellerId: "",
  };
  const [sellersState, setSellersState] = useState<SellersState>(defState);

  const actionData = [
    {
      action: "remove_archived",
      display: "Restore",
      dlgTitle: "Restore Seller",
      dlgMsg: "Are you sure you want to restore this seller?",
    },
    {
      action: "remove_bulk_archived",
      display: "Restore",
      dlgTitle: `Restore ${checkSelectSellersCount() ? "Sellers" : "Seller"}`,
      dlgMsg: `Are you sure you want to restore ${checkSelectSellersCount() ? "the selected sellers" : "this seller"
        }?`,
    },
  ];

  const getActionProp = (actionName: string, propName: string) => {
    const action = actionData.find((element) => element.action === actionName);

    let prop;
    switch (propName) {
      case "display":
        prop = action?.display;
        break;
      case "dlgTitle":
        prop = action?.dlgTitle ?? `${action?.display} Seller`;
        break;
      case "dlgMsg":
        prop = action?.dlgMsg ?? `Are you sure to ${action?.display.toLowerCase()} seller?`;
        break;
    }
    return prop ?? "";
  };

  const tableColumns = ["Created", "Seller", "Users", "Date Archived", "Archived By"];
  const placeholderColumnNames = [
    "created",
    "seller",
    "users",
    "date archived",
    "archived by",
    "action",
  ];

  if (manageSeller) tableColumns.push("Action");

  const tableBulkActions = ["Status: Restore"];

  const error = totalSellers.error || subscribed.error || queried.error;
  if (error) {
    return <PageError error={{ source: "ArchivedSellers", errMsg: error.message }} />;
  }

  if (!queried.data || !subscribed.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={placeholderColumnNames} numberOfRows={number_items_per_page} />
      </Card>
    );
  }
  const totalSellersCount = totalSellers.data?.vw_seller_aggregate.aggregate?.count;
  const sellers = subscribed.data?.vw_seller ?? queried.data?.vw_seller;

  const tableData = sellers.map((seller) => {
    const actions = [
      {
        actionType: "edit",
        id: seller.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: (id: string) => navigate(`${baseRoute}/${id}`),
      },
      {
        actionType: "restore",
        id: seller.id,
        display: "Restore",
        icon: "bi bi-arrow-counterclockwise",
        actionFunc: (id: string) =>
          setSellersState({
            action: "remove_archived",
            actionSellerId: id,
            showActionDialog: true,
          }),
      },
    ];

    return {
      id: seller.id,
      Created: (
        <div className="date-created">
          <p className="mb-0">{formatDate(seller.created_date)}</p>
          <span className="small text-muted">{formatDate(seller.created_date, "time")}</span>
        </div>
      ),
      //formatDate(seller.archived_at || ""),
      Seller: manageSeller ? (
        <NavLink
          data-testid="nav-message"
          to={`${baseRoute}/${seller.id}`}
          className="nav-link text-decoration-underline"
        >
          {seller.company_name}
        </NavLink>
      ) : (
        seller.company_name
      ),
      Users: <p className="text-left">{seller.users}</p>,
      Date_Archived: (
        <div className="date-archived">
          <p className="mb-0">{formatDate(seller.archived_at)}</p>
          <span className="small text-muted">
            {formatDate(seller.archived_at, "time")}
          </span>
        </div>
      ),
      Archived_By: seller.archived_by,
      Action: <DropdownItems items={actions} />,
    };
  });

  const currentSellers = tableData;

  const continueAction = async (newContinueAction: boolean) => {
    if (newContinueAction) {
      const key = sellersState.action.replace(/remove_bulk_|remove_/, "");
      let ids = [sellersState.actionSellerId];
      if (sellersState.action.indexOf("bulk") !== -1) {
        ids = multiSelectRef.current?.selectedData || [];
      }
      await removeStatusMutation(
        { sellerIds: ids, statusKey: key },
        { additionalTypenames: ["vw_seller", "vvw_seller_aggregate"] }
      )
        .then((res) => {
          if (res.data && !res.error) {
            setSellersState(defState);
            multiSelectRef.current?.clearSelectedData();
          }
          alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, res));
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, error));
        });
    } else {
      setSellersState(defState);
      setSelectedValues([]);
      setSelectedOption("");
    }
  };

  const applyChanges = () => {
    setSellersState({
      action: "remove_bulk_archived",
      actionSellerId: multiSelectRef.current?.selectedData[0] || "",
      showActionDialog: true,
    });
  };

  const addFilterOptions = [
    { label: "Seller", value: "company_name_lower" },
    { label: "Archived by", value: "archived_by_lower" },
  ];

  return (
    <>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={currentSellers}
          options={tableBulkActions}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalSellersCount || 0}
          ref={multiSelectRef}
          multiSelectTable
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchHint="Search by Seller, Archived By"
          searchPlaceHolder="Search by Seller, ..."
        />
      </Card>
      <Dialog
        show={sellersState.showActionDialog}
        title={getActionProp(sellersState.action, "dlgTitle")}
        continueText={getActionProp(sellersState.action, "display")}
        continue={continueAction}
        contineBtnCss="btn-success"
      >
        <div className="pt-2">{getActionProp(sellersState.action, "dlgMsg")}</div>
      </Dialog>
    </>
  );
};
