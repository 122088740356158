import { FC } from "react";
import { FormInput } from "../../forms/components/FormInput";

type IProps = {
  disableForm?: boolean;
  parentPrefix?: string;
  ignores?: string[];
};

export const Contact: FC<IProps> = (props: IProps): ReturnType<FC> => {
  const { disableForm, parentPrefix, ignores } = props;

  return (
    <>
      <FormInput
        name={`${parentPrefix || ""}first_name`}
        label="First Name"
        reg_options={{ required: true, maxLength: 50 }}
        disabled={disableForm}
      />
      <FormInput
        name={`${parentPrefix || ""}last_name`}
        label="Last Name"
        reg_options={{ required: true, maxLength: 50 }}
        disabled={disableForm}
      />
      {!ignores?.includes("email") && <FormInput
        name={`${parentPrefix || ""}email`}
        label="Email"
        type="email"
        reg_options={{
          required: true,
          maxLength: 100,
          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        }}
        disabled={disableForm}
      />}
      {!ignores?.includes("phone") && <FormInput
        name={`${parentPrefix || ""}phone`}
        label="Phone"
        type="tel"
        reg_options={{
          maxLength: 15,
          pattern: /^[+\s\-]?(\(?\d{3}\)?[\s.\-]?)?[\d\s\-]{0,12}$/i
        }}
        disabled={disableForm}
      />}
      {!ignores?.includes("alter_phone") && <FormInput
        name={`${parentPrefix || ""}alter_phone`}
        label="Secondary Phone"
        type="tel"
        reg_options={{
          maxLength: 15,
          pattern: /^[+\s\-]?(\(?\d{3}\)?[\s.\-]?)?[\d\s\-]{0,12}$/i
        }}
        disabled={disableForm}
      />}
      {!ignores?.includes("fax") && <FormInput
        name={`${parentPrefix || ""}fax`}
        label="Fax"
        reg_options={{ maxLength: 15 }}
        disabled={disableForm}
      />}
    </>
  );
};
