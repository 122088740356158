import React, { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormInput } from "../../forms/components/FormInput";

import { Input } from "../../forms/components/Input";
import { getCssVar, setCssVar } from "../../settings-route/handlers/getCssVar";
import { useFieldValidationNew } from "../hooks/useFieldValidationNew";

type Props = {
  defaultValue: number | string;
  isChecked: boolean;
  value?: string;
  type?: string;
  cssVariable?: string;
  updateAppRefStatus?: boolean;
  index?: number;
};
type InputProps = Props & {
  currentValue: number | string;
  forceUpdate: React.Dispatch<React.SetStateAction<number>>;
};

export const InputCheckbox: FC<InputProps> = (props): ReturnType<FC> => {
  const { defaultValue, isChecked, currentValue, forceUpdate } = props;
  const [checked, setChecked] = useState<boolean>(isChecked);
  const inputRef = useFieldValidationNew<HTMLInputElement>();

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between">
        <Input
          data-testid="input-checkbox"
          ref={inputRef}
          className="form-control me-4"
          style={{ width: "100px" }}
          type="number"
          required
          defaultValue={Number(currentValue)}
          onChange={() => {
            forceUpdate((val) => val + 1);
          }}
          disabled={checked}
          min={1}
          max={365}
          pattern="[0-9]"
          step={1}
          onKeyDown={(event) => event.key === "e" && event.preventDefault()}
        />
        <Input
          data-testid=""
          ref={null}
          className="form-check-input "
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            if (event.target.checked && inputRef.current) {
              inputRef.current.value = String(defaultValue);
              forceUpdate((val) => val + 1);
            }
          }}
        />
      </div>

      {inputRef.current?.validationMessage && (
        <p className="display-7 text-danger text-left show-error mb-0 mt-2">
          <i className="bi bi-exclamation-circle-fill"></i> {inputRef.current?.validationMessage}
        </p>
      )}
    </div>
  );
};

export const InputCheckColor = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(
    getCssVar(props.cssVariable ?? "") === props.defaultValue
  );

  const methods = useFormContext();
  const {
    watch,
    setValue
  } = methods;
  const inputName = `custom_style.${props.index}.value`;
  const colorInput = watch(inputName);

  useEffect(()=>{
    if (colorInput) {
      if (props.cssVariable) {
        setCssVar(props.cssVariable, colorInput);
      }
    }
  }, [colorInput]);

  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <FormInput
          name={inputName}
          style={{ width: "150px" }}
          disabled={checked ? true : false}
          type="color"
          css="mb-0"
        />
        <Input
          data-testid=""
          ref={null}
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            if (event.target.checked) {
              setValue(inputName, props.defaultValue as string);
            }
            setChecked(event.target.checked);
          }}
        />
      </div>
    </div>
  );
};

