export const handleTitle = (titleName: string, extention: number): string => {
  return titleName.length > extention ? titleName.slice(0, extention) + " ..." : titleName;
};

export const handleStatus = (statusValue: string): string => {
  switch (statusValue.toLowerCase()) {
    case "open":
      return "bg-success";
    case "closed":
      return "bg-grey";
    case "pending":
      return "bg-warning text-dark";
    case "suspended":
      return "bg-danger";
    default:
      return "bg-light";
  }
};
