import { FC, useEffect, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Vw_Report_Take_Rate,
  useDashboardTakeRateQuery
} from "../../../generated/urql-graphql";
import { ChartPlaceHolder } from "../../common/components/ChartPlaceHolder";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
const dataInit = [
  {
    key: "January",
    name: "January",
    take_rate: 0,
  },
  {
    key: "February",
    name: "February",
    take_rate: 0,
  },
  {
    key: "March",
    name: "March",
    take_rate: 0,
  },
  {
    key: "April",
    name: "April",
    take_rate: 0,
  },
  {
    key: "May",
    name: "May",
    take_rate: 0,
  },
  {
    key: "June",
    name: "June",
    take_rate: 0,
  },
  {
    key: "July",
    name: "July",
    take_rate: 0,
  },
  {
    key: "August",
    name: "August",
    take_rate: 0,
  },
  {
    key: "September",
    name: "September",
    take_rate: 0,
  },
  {
    key: "October",
    name: "October",
    take_rate: 0,
  },
  {
    key: "November",
    name: "November",
    take_rate: 0,
  },
  {
    key: "December",
    name: "December",
    take_rate: 0,
  },
];

interface ReportDataType {
  key: string;
  name: string;
  take_rate: number;
}

export const B2BKpi: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const userInfo = useUserInfo()!;

  const where1 = `
    {
      "operator_id": { "_eq": "${userInfo.operator_id}" } ,
      "month_tz" : { "_like": "${currentYear}%" }
    }`;
  const where2 = `
    {
      "operator_id": { "_eq": "${userInfo.operator_id}" }
    }`;
  const inputs = {
    variables: {
      where1: JSON.parse(where1),
      where2: JSON.parse(where2),
    },
  };
  const [lineChartSubscribed] = useDashboardTakeRateQuery(inputs);

  useEffect(() => {
    if (lineChartSubscribed.data) {
      setLoading(false);
    }
  }, [lineChartSubscribed.data]);

  const error = lineChartSubscribed.error;
  if (error) {
    return <PageError error={{ source: "B2BKpi", errMsg: error.message }} />;
  }
  if (
    lineChartSubscribed.fetching ||
    !lineChartSubscribed.data
  ) {
    return (
      <ChartPlaceHolder
        icon="graph-up-arrow"
        title="Take Rate"
        showMetrics={true}
        loadingText="Loading..."
        numberOfBars={10}
      />
    );
  }
  const sumCommission =
    lineChartSubscribed.data?.vw_sales_by_seller_day_aggregate.aggregate?.sum?.commission;
  const sumTotalSales =
    lineChartSubscribed.data?.vw_sales_by_seller_day_aggregate.aggregate?.sum?.total_sales;
  const takeRate = (sumCommission / sumTotalSales) * 100;
  const dataChart: ReportDataType[] | undefined = dataInit;
  lineChartSubscribed.data.vw_report_take_rate.forEach((item: Vw_Report_Take_Rate) => {
    if (item.month_tz) {
      let monthIndex: number = parseInt(item.month_tz?.substring(5) || "");

      if (dataChart) {
        dataChart[monthIndex - 1]["take_rate"] = item.take_rate;
      }
    }
  });

  return (
    <>
      <div className="d-flex flex-end justify-content-between">
        <div className="btn-group">
          <h4 className="card-title me-2">
            <i className="bi bi-graph-up-arrow me-2"></i>Take Rate
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center my-2">
            <p className="text-muted small mb-0 me-2">Take Rate:</p>
            <p
              className={`text-right font-weight-semi-bold mb-0 ${takeRate > 0 ? "text-success" : "text-danger"
                }`}
            >{`${takeRate ? takeRate.toFixed(2) + '%' : 'N/A'}`}</p>
          </div>
        </div>
      </div>
      <>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={dataChart}
            margin={{
              top: 20,
              right: 40,
              bottom: 100,
              left: 0,
            }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              //name={`Take Rate YTD`}
              dataKey="take_rate"
              stroke="#00a3c7"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </>
    </>
  );
};
