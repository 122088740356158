import { FC, useState } from "react";
import { useRecoilState } from "recoil";
import {
  useActiveNotificationCountQuery,
  useArchivedNotificationCountQuery,
  useDeleteSelectedNotificationsMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { IAlert, alertsValue } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from '../../common/miscellaneous/utility';
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { MyArchivedNotification } from "../../users-route/components/MyArchivedNotification";
import { MyNotification } from "../../users-route/components/MyNotification";

export const MyNotificationTab: FC = () => {
  const [alerts, setAlerts] = useRecoilState<IAlert[]>(alertsValue);
  const [context] = useMainContext();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users/notification`;

  let userId = useUserInfo().user_id!;
  let operatorId = useUserInfo().operator_id!;
  let staticFiter = `{"status_cd": { "_eq": "Active"},
                      "receiver_id": { "_eq": "${userId}"},
                      "operator_id": { "_eq": "${operatorId}"}}`;
  let staticArchivedFiter = `{"status_cd": { "_eq": "Archive"},
                      "receiver_id": { "_eq": "${userId}"},
                      "operator_id": { "_eq": "${operatorId}"}}`;
  const [activeNotificationCount, executeActiveNotificationCountQuery] = useActiveNotificationCountQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      where: JSON.parse(staticFiter),
    },
  });
  const [archivedNotificationCount, executeArchivedNotificationCountQuery] = useArchivedNotificationCountQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      where: JSON.parse(staticArchivedFiter),
    },
  });

  const [, DeleteAll] = useDeleteSelectedNotificationsMutation();
  const refreshCount = () => {
    executeActiveNotificationCountQuery();
    executeArchivedNotificationCountQuery();
  };

  const error = activeNotificationCount.error || archivedNotificationCount.error;
  if (error) {
    return <PageError error={{ source: "MyNotificationTabs", errMsg: error.message }} />;
  }

  if (!activeNotificationCount.data || !archivedNotificationCount.data) {
    return <Loader />;
  }

  const tabs = [
    {
      tabName: "All Notifications",
      route: `${baseRoute}`,

      component: <MyNotification refreshCount={refreshCount} />,
      totalNumberOfElements:
        activeNotificationCount.data.vw_notification_user_aggregate.aggregate?.count,
    },
    {
      tabName: "Archived Notifications",
      route: `${baseRoute}/archived`,
      component: <MyArchivedNotification refreshCount={refreshCount}></MyArchivedNotification>,
      totalNumberOfElements:
        archivedNotificationCount.data.vw_notification_user_aggregate.aggregate?.count,
    },
  ];

  const DeleteAllNotification = async () => {
    const whereString = `{ "receiver_id": { "_eq": "${userId}"} }`;
    const res = await DeleteAll(
      {
        whereValue: JSON.parse(whereString),
      },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );

    alertsRef.current?.generate(mutationInfo("the notifications", MutationAction.Delete, res));
  };

  const continueDeleteAll = (proceed: boolean) => {
    if (proceed) {
      DeleteAllNotification();
    }
    setShowConfirmDialog(false);
  };

  return (
    <div>
      <div>
        <ReturnHeader title="My Notifications">
          <div className="d-flex justify-content-end">
            <Button data-testid="" className="btn btn-primary mt-3"
              onClick={() => setShowConfirmDialog(true)}
            >
              Delete All
            </Button>
          </div>
        </ReturnHeader>
      </div>
      <div className="mt-4">
        <LinkTabsComponent tabs={tabs} />
      </div>
      <Dialog
        show={showConfirmDialog}
        title="Delete All"
        continueText="Ok"
        continue={continueDeleteAll}
      >
        <div className="pt-2">Are you sure you want to delete all notifications?</div>
      </Dialog>
    </div>
  );
};
