import _ from "lodash";
import { useEffect, useState } from "react";
import {
  useCategoryResultsQuery,
  useFullpathCategoriesQuery,
  useRootCategoriesQuery,
} from "../../../generated/urql-graphql";
import { HierarchyCategories } from "../../categories-route/components/HierarchyCategories";
import { Subcategories } from "../../categories-route/components/Subcategories";
import { ISetProdCtg } from "../../categories-route/types/types";
import { Card } from "../../common/components/Card";
import { CustomSelect } from "../../common/components/CustomSelect";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { ISearchParam, SelectOption } from "../../common/types/types";

interface IProps {
  setProdCtg: ISetProdCtg;
}

export function Categories({ setProdCtg }: IProps): JSX.Element {
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const defParam = {
    searchTerm: setProdCtg.prodCtgId ? "" : "%",
    limit: 15,
    offset: 0,
    total: 0,
    defaultValue: setProdCtg.prodCtgId || emptyUuid,
    reset: () => setSearchParam({ ...defParam, searchTerm: "%", defaultValue: emptyUuid })
  };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [ctgLimit, setCtgLimit] = useState<number>(15);
  const [categoryPath, setCategoryPath] = useState<string>("");
  const [queried] = useFullpathCategoriesQuery({
    variables: _.omit(searchParam, ["total", "reset"]),
  });
  useEffect(() => {
    if (queried.data) {
      const categories =
        queried.data.category.map(
          (ctg) =>
          ({
            label: ctg.fullname,
            value: ctg.path,
          } as SelectOption)
        ) || [];
      setCategoryOptions(categories);
      setSearchParam({ ...searchParam, total: queried.data.categories.aggregate?.count || 0 });
    }
  }, [queried]);

  const [searchedQueried] = useCategoryResultsQuery({
    variables: { ids: categoryPath.split(",") },
  });

  const [ctgsQueried] = useRootCategoriesQuery({
    variables: { limit: ctgLimit, offset: 0 },
  });

  const searchedCtgs = searchedQueried.data?.category;
  const ctgs = ctgsQueried.data?.category || [];
  const total = ctgsQueried.data?.categories?.aggregate?.count || 0;
  return (
    <div className="">
      <CustomSelect
        options={categoryOptions}
        placeholder="Search by category name"
        search={{ searchParam, setSearchParam }}
        onSelectionChanged={(options: SelectOption[]) => {
          const selected = options.find(opt => opt)?.value || "";
          setCategoryPath(Array.isArray(selected) ? selected.join(",") : selected)
        }}
        disabled={!isNullOrEmpty(searchParam.defaultValue)}
      />
      <Card className="category-tree mt-3">
        {categoryPath && searchedCtgs ? (
          <HierarchyCategories
            level={0}
            categories={searchedCtgs}
            setProdCtg={setProdCtg}
          />
        ) : (
          <Subcategories
            level={0}
            categories={ctgs}
            totalCtgs={total}
            subOpen={true}
            handleShowMore={() => setCtgLimit(ctgLimit + 15)}
            fetching={ctgsQueried.fetching}
            setProdCtg={setProdCtg}
          />
        )}
      </Card>
    </div>
  );
}
