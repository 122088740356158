/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  RoleDetailsQuery,
  Role_Permission_Jnc_Insert_Input,
} from "../../../generated/urql-graphql";
import { useRoleList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty, isSimilar } from "../../common/miscellaneous/utility";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { useMainContext } from "../../layout/components/MainProvider";

interface PermissionsByRoleProps {
  permissions: RoleDetailsQuery["permission"];
  groupLevel: number | null;
  roleId: string;
  apiOnly: boolean | null;
  updatedPerms: string[];
  permChanged: (changed: boolean) => void;
}

interface PermsByRole {
  curPerms: string[];
  availPerms: string[];
  existPerms: string[];
  apiOnly: boolean;
}

export type RolePerms = {
  get: () => Role_Permission_Jnc_Insert_Input[];
};

export const PermissionsByRole = forwardRef<RolePerms, PermissionsByRoleProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    get() {
      const rolePermJnc: Role_Permission_Jnc_Insert_Input[] = [];
      if (isNullOrEmpty(roleId))
        permsByRole.curPerms.forEach((pid) => rolePermJnc.push({ permission_id: pid }));
      else
        permsByRole.curPerms.forEach((pid) =>
          rolePermJnc.push({
            permission_id: pid,
            role_id: roleId,
          })
        );
      return rolePermJnc;
    },
  }));

  const { permissions, groupLevel, apiOnly, roleId, updatedPerms, permChanged } = props;
  const roleList = useRoleList();
  const defPerms: PermsByRole = {
    curPerms: [],
    availPerms: [],
    existPerms: [],
    apiOnly: false,
  };
  const [btnAll, setBtnAll] = useState<string>("Select All");
  const [permsByRole, setPermsByRole] = useState<PermsByRole>(defPerms);
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const curRole = roleList.find((role) => role.id === roleId);
  const manageRolePermissions =
    userInfo.permissions! & context.permissions.manage_role_permission && curRole?.id !== userInfo.role_id;

  useEffect(() => {
    const updated = defPerms;
    permissions
      .filter((perm) => perm.group_level! <= groupLevel!)
      .forEach((perm) => {
        updated.availPerms.push(perm.id);
        if ((!apiOnly && perm.role_permission_jncs_aggregate?.aggregate?.count === 1)
          || (apiOnly && perm.name === "access_api"))
          updated.curPerms.push(perm.id);
      });
    if (permsByRole.existPerms.length === 0)
      updated.existPerms = [...updated.curPerms];
    else
      updated.existPerms = [...permsByRole.existPerms];
    updated.apiOnly = apiOnly || false;
    setPermsByRole(updated);
  }, [groupLevel, apiOnly]);

  useEffect(() => {
    setBtnAll(
      permsByRole.availPerms.length !== 0 && permsByRole.curPerms.length === permsByRole.availPerms.length
        ? "Unselect All"
        : "Select All"
    );
  }, [permsByRole]);

  useEffect(() => {
    setPermsByRole({ ...permsByRole, existPerms: updatedPerms });
  }, [updatedPerms]);

  const findChdPids = (pids: string[], permid: string) => {
    const chdPids = permissions
      .filter((perm) => perm.parent_id === permid)
      .map((perm) => perm.id);
    if (chdPids) {
      chdPids.forEach((pid) => {
        pids.push(pid);
        findChdPids(pids, pid);
      });
    }
  };

  const permissionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permissionId = event.target.value;
    let changed = [...permsByRole.curPerms];
    if (event.target.checked) {
      changed = [...changed, permissionId];
    } else {
      let rmPids: string[] = [permissionId];
      findChdPids(rmPids, permissionId);
      changed = changed.filter((pid) => !rmPids.includes(pid));
    }
    setPermsByRole({
      ...permsByRole,
      curPerms: changed,
    });
    permChanged(!isSimilar(permsByRole.existPerms, changed));
  };

  const toggleAll = () => {
    const changed = btnAll === "Select All" ? permsByRole.availPerms.map((pid) => pid) : [];
    permChanged(!isSimilar(permsByRole.existPerms, changed));
    setPermsByRole({ ...permsByRole, curPerms: changed });
  };

  const permCkBox = (perm: RoleDetailsQuery["permission"][0]) => {
    return (
      <div key={"perm_" + perm.id ?? "none_perm_id"}>
        {groupLevel! >= perm?.group_level! && (
          <>
            <Label className="form-check" data-testid="label_permission">
              <Input
                ref={null}
                data-testid={"perm_" + perm.id}
                type="checkbox"
                checked={permsByRole.curPerms.includes(perm.id)}
                disabled={
                  !permsByRole.apiOnly &&
                    manageRolePermissions &&
                    (perm.parent_id === null || permsByRole.curPerms.includes(perm.parent_id))
                    ? false
                    : true
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => permissionChanged(event)}
                name={`perm_${perm.id}`}
                value={perm.id}
                className="form-check-input"
              />
              {perm.description}
            </Label>
            <div className="ms-3">
              {permissions
                .filter((pm) => pm.parent_id === perm.id)
                ?.map((pm) => {
                  return permCkBox(pm);
                })}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {permissions.length !== 0 ? (
        <div className="permssions-wrapper">
          <div className="d-flex align-items-center">
            <p className="font-weight-semi-bold mb-0">Permissions</p>
            {manageRolePermissions && !permsByRole.apiOnly ? (
              <Button
                data-testid="btn-select-all"
                className="btn btn-link"
                onClick={() => toggleAll()}
              >
                {btnAll}
              </Button>
            ) : null}
          </div>
          <div className="row">
            {permissions.filter((perm) => perm.group_level === 10).length > 0 && (
              <div className="col-md-6">
                <p className="font-weight-semi-bold mb-2">General</p>
                <div className="ms-2">
                  {permissions
                    .filter((perm) => perm.group_level === 10 && perm.parent_id === null)
                    .sort((p1, p2) => p1.description!.localeCompare(p2.description!))
                    .map((perm) => {
                      return permCkBox(perm);
                    })}
                </div>
              </div>
            )}
            {groupLevel !== 10 &&
              permissions.filter((perm) => perm.group_level !== 10).length > 0 && (
                <div className="col-md-6">
                  <p className="font-weight-semi-bold mb-2">Administration</p>
                  <div className="ms-2">
                    {permissions
                      .filter((perm) => perm.group_level !== 10 && perm.parent_id === null)
                      .sort((p1, p2) => p1.description!.localeCompare(p2.description!))
                      .map((perm) => {
                        return permCkBox(perm);
                      })}
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div>There is no permission available.</div>
      )}
    </>
  );
});
