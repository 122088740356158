import { handleDateRange } from "../../common/handlers/handleDateRange";
import { IDateRange } from "../../common/types/types";

export function handleWhere({
  columnSearch = "",
  dateRange = { startDate: null, endDay: null, column_name: "" },
  selectedFilters = "",
  customRule = "",
}: {
  columnSearch?: string;
  dateRange?: IDateRange;
  selectedFilters?: string;
  customRule?: string;
}): string {
  return `{"_or": [${columnSearch}]${handleDateRange(
    dateRange
  )}, "_and": [${selectedFilters}]${customRule}}`;
}
