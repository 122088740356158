import { FC, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { AttributesContainer } from "./AttributesContainer";
import { Categories } from "./Categories";

export const CategoryAttributesRoute: FC = (): ReturnType<FC> => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseUrl = `/${tenantUrlTag}/settings`;
  const [prodCtgId, setProdCtgId] = useState<string>(categoryId || "");

  if (isNullOrEmpty(userInfo.user_id)) return <Loader />;
  if (userInfo.user_level! < 20) {
    if (userInfo.seller_id) {
      navigate(`/sellers/${userInfo.seller_id}`);
    }
    navigate("/dashboard");
  }

  return (
    <div className="manage-product-attributes">
      <div>
        <ReturnHeader
          title="Manage Category Attributes"
          url={baseUrl}
          description="Category attributes are the specific characteristics or features of a product or service that distinguish it from other products or services."
        >
          <NavLink data-testid="" to={`${baseUrl}/categories`}>
            <Button data-testid="" className="btn btn-primary">
              Manage Categories
            </Button>
          </NavLink>
        </ReturnHeader>
      </div>
      <div>
        <div className="row flex-xl-row">
          <div className="col-sm-12 col-lg-4 categories-area">
            <Categories setProdCtg={{ prodCtgId, setProdCtgId: (ctgId: string) => ctgId && setProdCtgId(ctgId) }} />
          </div>
          <div className="col-sm-12 col-lg-8 attributes-area">
            {prodCtgId ? (
              <Card className="position-relative">
                <AttributesContainer categoryId={prodCtgId} />
              </Card>
            ) : (
              <Card className="position-relative attributes-container d-flex justify-content-center align-items-center">
                <p className="">Please select a category</p>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
