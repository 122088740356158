import { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../forms/components/Button";

type Props = {
  children?: React.ReactChild | null;
  title: string;
  url?: string;
  description?: string;
};

export const ReturnHeader: FC<Props> = (props): ReturnType<FC> => {
  const { children, title, url, description } = props;

  return (
    <div className="d-flex flex-column">
      <div
        className={`d-flex justify-content-between return-header ${description ? "mb-3" : "mb-4"}`}
      >
        <div className="d-flex align-items-start">
          {url && (
            <Link to={url} replace>
              <Button data-testid="" className="btn btn-sm bg-light-gray me-3">
                <i className="bi bi-caret-left-fill text-primary"></i>
              </Button>
            </Link>
          )}
          <h1 className={`page-title mb-0 ${url && "me-3"}`}>{title}</h1>
        </div>
        {children ? <div className="d-flex align-items-center">{children}</div> : null}
      </div>
      {description ? <p className="text-muted">{description}</p> : null}
    </div>
  );
};

ReturnHeader.defaultProps = {
  children: null,
};
