import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateUserRoleMutation } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";
import { UserProps } from "../miscellaneous/types";
import { Avatar } from "./Avatar";
import RoleSelector from "./RoleSelector";

export const UserDetails: FC<UserProps> = ({ userDetailInfo }) => {
  const [, updateMutation] = useUpdateUserRoleMutation();
  const fullName = userDetailInfo?.full_name ?? "";
  const phone = userDetailInfo?.user?.phone ?? "";
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { full_name: fullName, mobile: phone },
  });

  const changeRole = async (roleId: string) => {
    const result = await updateMutation(
      { user_id: userDetailInfo?.user_id!, role_id: roleId },
      { additionalTypenames: ["vw_active_user", "usership"] }
    )
    alertsRef.current?.generate(mutationInfo("user role", MutationAction.Change, result));
  };

  return (
    <FormProvider {...methods}>
      <Form data-testid="user-details-form">
        <Card className="border-top-0 p-4">
          <div className="">
            <Avatar userDetailInfo={userDetailInfo} />
            <FormInput
              name="full_name"
              label="Full Name"
              className="mb-3 form-control"
              disabled={true}
            />
            <FormInput name="mobile" label="Contact Phone Number" disabled={true} />
            {userDetailInfo?.seller_id === emptyUuid || userDetailInfo.user_level == 20 && (
              <div>
                <RoleSelector
                  curRoleId={userDetailInfo?.role_id}
                  confirmNeeded={true}
                  onChange={(roleId: string) => changeRole(roleId)}
                  label="Role"
                  required={true}
                />
              </div>
            )}
          </div>
        </Card>
      </Form>
    </FormProvider>
  );
};
