import { FC, useEffect, useState } from "react";
import {
  Vw_Report_Offer_By_Seller,
  Vw_Report_Offer_By_Seller_Order_By,
  useReportOfferBySellerQuery,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { dateRangeToStr } from "../../common/miscellaneous/utility";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { OffersBySellerData } from "./OffersBySellerData";

export const OffersBySeller: FC = () => {
  const userInfo = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [context] = useMainContext();
  const operatorId = context.operatorInfo.id;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/reports`;
  let whereStr: string = `{}`;
  let orderByStr: Vw_Report_Offer_By_Seller_Order_By | Vw_Report_Offer_By_Seller_Order_By[] = [];
  let tableData: {}[][] | undefined = [];
  const [exportClicked, setExportClicked] = useState<boolean>(false);

  let currentDate = new Date();
  let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [input, setInput] = useState<{}>({
    variables: {
      operatorId: operatorId,
      searchText: dateRangeToStr(last14Days, currentDate),
      limit: 0,
      offset: 0,
      order_by: orderByStr,
      where: JSON.parse(whereStr),
    },
  });
  let input2 = {};
  const [queried] = useReportOfferBySellerQuery(input);

  useEffect(() => {
    let headers = ["Company Name,New Offer Count,Total Offer Count,Active Offer Count,Inactive Offer Count"];

    if (queried.data && exportClicked) {
      downloadFile({
        data: [...headers, tableData?.join("\n")].join("\n"),
        fileName: "OffersBySeller.csv",
        fileType: "text/csv",
      });
      setExportClicked(false);
      setLoading(false);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "OffersBySeller", errMsg: error.message }} />;
  }
  const downloadFile = (value: { [key: string]: string }) => {
    const blob = new Blob([value.data], { type: value.fileType });

    const aLink = document.createElement("a");
    aLink.download = value.fileName;
    aLink.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    aLink.dispatchEvent(clickEvt);
    aLink.remove();
  };

  const exportToCsv = async () => {
    setExportClicked(true);
    if (JSON.stringify(input) !== JSON.stringify(input2)) {
      setInput(input2);
      setLoading(true);
    }
  };

  const HandleCallBack = (
    searchText: string,
    where: string,
    orderBy: Vw_Report_Offer_By_Seller_Order_By | Vw_Report_Offer_By_Seller_Order_By[]
  ) => {
    input2 = {
      variables: {
        operatorId: userInfo.operator_id,
        searchText: searchText,
        limit: 999999,
        offset: 0,
        order_by: orderBy,
        where: JSON.parse(where),
      },
    };
  };

  tableData = queried.data?.item.map((row: Vw_Report_Offer_By_Seller) => {
    return [
      `"${row.seller_name}","${row.new_offer_count}","${row.total_offer_count}","${row.active_offer_count}","${row.inactive_offer_count}"`,
    ];
  });
  return (
    <>
      <div className="d-flex justify-content-between return-header">
        <div className="d-flex align-items-start">
          <ReturnHeader
            title="Offers by Seller"
            url={baseRoute}
            description="This report provides counts of Offers by Seller."
          />
        </div>
        <div className="d-flex align-items-center">
          {loading ? (
            <div data-testid=""
              className="spinner-border spinner-border-sm" role="status">
            </div>
          )
            :
            <Button
              data-testid=""
              className="btn"
              id="exportButton"
              onClick={() => {
                exportToCsv();
              }}
            >
              Export
            </Button>
          }
        </div>
      </div>
      <OffersBySellerData handleCallBack={HandleCallBack} />
    </>
  );
};
