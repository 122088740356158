import { FC, useEffect, useState } from "react";
import {
  useRemoveSellerAccountMutation,
  useSellerUsersIntQuery,
  useSellerUsersSubscription,
  useUpdateUserSellerRoleMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems, { ActionItemType } from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { Table } from "../../common/components/Table";
import { useRoleList } from "../../common/hooks/globals";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import RoleSelector from "../../users-route/components/RoleSelector";
import { ActionData } from "../miscellaneous/types";

interface Props {
  sellerId: string;
}

export const UserRoles: FC<Props> = ({ sellerId }: Props): ReturnType<FC> => {
  const userInputs = { variables: { seller_id: sellerId } };
  const formatDate = useDateFormat();
  const [usrsQueried] = useSellerUsersIntQuery(userInputs);
  const [usrsSubscribed] = useSellerUsersSubscription(userInputs);
  const [, updateMutation] = useUpdateUserSellerRoleMutation();
  const [, removeMutation] = useRemoveSellerAccountMutation();
  const [dataId, setDataId] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  useRoleList();
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const [manage, setManage] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo && userInfo.permissions && context && context.permissions.update_seller
      && context.permissions.manage_seller
    ) {
      setManage(BigInt(userInfo.permissions!) &
        (BigInt(context.permissions.update_seller) || BigInt(context.permissions.manage_seller))
        ? true : false);
    }
    else {
      setManage(false);
    }
  }, [userInfo, context]);
  const error = usrsQueried.error;
  if (error) {
    return <PageError error={{ source: "UserRoles", errMsg: error.message || "" }} />;
  }

  if (!usrsQueried.data) {
    return <Loader />;
  }

  const usrsData =
    usrsSubscribed.data?.usership! ?? usrsQueried.data?.usership!;
  const admins = usrsData.find((usrs) => usrs)?.seller?.admins;
  const tableColumns = ["User", "Role", "Last Login"];
  if (manage) tableColumns.push("Actions");
  const actionData: ActionData[] = [
    {
      action: "remove",
      display: "Remove",
      icon: "bi-person-x",
    },
  ];

  const addActions = (itemId: string, actionNames: string[]) => {
    const actions: ActionItemType[] = [];
    actionNames.forEach((name) => {
      const actionProp = actionData.find((data) => data.action === name);
      actions.push({
        actionType: name,
        display: actionProp?.display! ?? name,
        id: itemId,
        icon: `bi ${actionProp?.icon}`,
        actionFunc: (id: string) => setDataId(id),
      });
    });
    return actions;
  };

  // const isLastAdmin = (itemId: string) => {
  //   const curItem = usrsData.find((usr) => usr.id === itemId);
  //   if (
  //     admins &&
  //     admins.aggregate?.count === 1 &&
  //     admins.nodes.find((node) => node.role_id === curItem?.role_id)
  //   ) {
  //     setErrMsg(
  //       "You cannot change or remove this Seller Administrator, as this is the only one for this seller."
  //     );
  //     return true;
  //   }
  //   return false;
  // };

  const handleRoleChange = (roleId: string, id?: string) => {
    // if (isLastAdmin(id!)) return;

    updateMutation(
      { id: id!, role_id: roleId },
      {
        additionalTypenames: [
          "vw_active_user",
          "usership_aggregate",
          "usership",
        ],
      }
    )
      .then((res) => {
        alertsRef.current?.generate(mutationInfo("user role", MutationAction.Update, res));
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("user role", MutationAction.Update, error));
      });
  };

  const continueRemove = (isContinue: boolean) => {
    if (!isContinue) {
      setDataId("");
      setErrMsg("");
      return;
    }
    // if (isLastAdmin(dataId)) return;
    removeMutation(
      { id: dataId },
      {
        additionalTypenames: [
          "vw_active_user",
          "usership",
          "usership_aggregate",
        ],
      }
    )
      .then((res) => {
        if (res.data && !res.error) {
          setDataId("");
          setErrMsg("");
        }
        alertsRef.current?.generate(mutationInfo("user", MutationAction.Remove, res));
        return;
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("user", MutationAction.Remove, error));
        return;
      });
  };

  const tableData = usrsData?.map((usr) => {
    const actions = addActions(usr.id, ["remove"]);
    return {
      id: usr?.id,
      User: (usr.user.first_name ?? "") + " " + (usr.user.last_name ?? ""),
      Role: (
        <RoleSelector
          curRoleId={usr?.role_id}
          id={usr.id}
          confirmNeeded={true}
          onChange={handleRoleChange}
          disabled={!manage}
        />
      ),
      Last_Login: formatDate(usr.user.last_login_date, "datetime"),
      Actions: <DropdownItems items={actions} />,
    };
  });

  return (
    <>
      <Table columnNames={tableColumns} data={tableData} />
      <Dialog
        show={!!dataId}
        title="Remove User"
        continueText="Remove"
        continue={continueRemove}
        errorMsg={errMsg}
        contineBtnCss='bg-danger text-white'
      >
        <div className="pt-2">Are you sure you want to remove this user?</div>
      </Dialog>
    </>
  );
};
