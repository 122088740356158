import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Usership_Insert_Input, useAddSellerAccountMutation, useSellerNamesQuery } from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { alertsRef } from "../../layout/components/Main";
import RoleSelector from "./RoleSelector";

interface Props {
  userId: string;
  onClose: () => void;
  userStores: String[];
}

export const AddUserToSeller: FC<Props> = ({ userId, onClose, userStores }) => {
  const [, addMutation] = useAddSellerAccountMutation();
  const userInfo = useUserInfo()!;

  const [roleId, setRoleId] = useState<string>("");

  const [sellerOptions, setSellerOptions] = useState<SelectOption[]>([]);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const selectRef = useRef<SelectRef | null>(null);
  const [queried] = useSellerNamesQuery({
    variables: _.omit(searchParam, "total"),
  });

  useEffect(() => {
    if (queried.data) {
      const sellers = queried.data.seller
        .filter((seller) => !userStores.includes(seller.value))
        .map((seller) => {
          return { label: seller.label, value: seller.value };
        }) || [];
      setSellerOptions(sellers);
      setSearchParam({ ...searchParam, total: queried.data.sellers.aggregate?.count || 0 });
    }
  }, [queried]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "AddUserToSeller", errMsg: error.message }} />;
  }

  const onSubmit = () => {
    const sellerId = selectRef.current?.value;
    if (!sellerId) {
      //To do: show error
      return;
    } else {
      const usership = {
        user_id: userId,
        seller_id: sellerId,
        role_id: roleId,
        operator_id: userInfo.operator_id,
        current: false
      } as Usership_Insert_Input;
      addMutation({ usership }, { additionalTypenames: ["usership"] })
        .then((res) => {
          if (res.data && !res.error) {
            onClose();
          }
          alertsRef.current?.generate(mutationInfo("seller", MutationAction.Add, res));
        })
        .catch((error) => alertsRef.current?.generate(mutationInfo("seller", MutationAction.Add, error)));
    }
  };

  const addAccount = (continueAdd: boolean) => {
    if (!continueAdd) {
      onClose();
    }
  };

  return (
    <Dialog
      title="Add Seller"
      continueText="Add Seller"
      subFormId="add-user-seller-form"
      continue={addAccount}
    >
      <FormProvider {...methods}>
        <Form
          data-testid="add-user-seller-form"
          id="add-user-seller-form"
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="mb-3">
            <CustomSelect
              ref={selectRef}
              label="Select Seller"
              required={true}
              options={sellerOptions}
              placeholder="Start typing to search for sellers"
              search={{ searchParam, setSearchParam, fetching: queried.fetching }}
            />
          </div>
          <div>
            <RoleSelector
              curRoleId={userInfo.role_id}
              groupBase={10}
              label="Select Role Assignment"
              required={true}
              onChange={(roleIdValue: string) => setRoleId(roleIdValue)}
            />
          </div>
        </Form>
      </FormProvider>
    </Dialog>
  );
};
