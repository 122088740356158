import { useEffect, useRef, useState } from "react";
import { IInputState } from ".";
import { Spinner } from "../Spinner";

interface IProps {
  status: IInputState;
  searchFunc: (search: string) => void;
  fetching?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const AutosizeInput = ({ status, searchFunc, fetching, disabled, readOnly }: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputholder, setInputholder] = useState<string>("");

  useEffect(() => {
    if (inputRef.current) {
      setInputholder(!status.showHolder || status.focus ? "" : status.placeholder || "");
      if (status.focus) inputRef.current.focus();
      if (status.clear) setInputValue("");
    }
  }, [inputRef.current, status]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      searchFunc(inputValue);
    }, 300);

    return () => clearTimeout(debounce);
  }, [inputValue]);

  return (
    <div className="d-flex select__input-container flex-grow-1 overflow-hidden">
      <input
        ref={inputRef}
        value={inputValue}
        className="select__input flex-grow-1 border-0"
        onChange={(event) => setInputValue(event.target.value)}
        placeholder={inputholder}
        disabled={disabled}
        readOnly={readOnly}
      />
      <div>{fetching && <Spinner size={20} />}</div>
    </div>
  );
};
