import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useCreateTenantMutation,
  useEditTenantMutation, useGetHubIndustryQuery
} from "../../../generated/urql-graphql";
import { Address } from "../../common/components/Address";
import { Card } from "../../common/components/Card";
import { Contact } from "../../common/components/Contact";
import { appRef } from "../../common/components/appStatus";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";

type Props = {
  updateTenantId: string
  tenantData?: ITenantData
}

export interface ITenantData {
  id: string
  tenant_name: string
  legal_name: string
  tenant_url_tag: string
  website: string
  city: string
  line1: string
  line2: string
  state: string
  country: string
  zipcode: string
  first_name: string
  last_name: string
  email: string
  phone: string
  alter_phone: string
  fax: string
  environment_type: string,
  industry_name: string
}

const environmentTypes = [{ label: "Production", value: "Production" },
{ label: "Test", value: "Test" },
{ label: "Development", value: "Development" }];


const TenantForm: FC<Props> = (props): ReturnType<FC> => {
  const { tenantData, updateTenantId } = props;
  const isAddForm = isNullOrEmpty(updateTenantId);
  const navigate = useNavigate();
  const baseRoute = "/tenants";
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [industryTemplates, setIndustryTemplates] = useState<{ label: string, value: string }[]>([
    { label: "None", value: "" },
  ]);

  const [hubIndustryQueried] = useGetHubIndustryQuery();
  const [{ fetching }, createTenantMutation] = useCreateTenantMutation();

  const [{ fetching: fetchingEdit }, editTenantMutation] = useEditTenantMutation();

  const methods = useForm<ITenantData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { ...tenantData },
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (hubIndustryQueried.data) {
      let itemTemplates: { label: string; value: string; }[] = [{ label: "None", value: "" }];
      hubIndustryQueried.data.pim_hub_industry.forEach((item) => {
        const itemTemplate: { label: string; value: string; } = { label: item.name, value: item.id };

        itemTemplates.push(itemTemplate);
      });
      setIndustryTemplates(itemTemplates);
    }

  }, [hubIndustryQueried.data]);

  const onSubmit = async (data: FieldValues) => {
    let res;
    const { id, industry_name, ...dataAdd } = data;
    if (isAddForm) {
      dataAdd.industry_id = data.industry_id || null;
      res = await createTenantMutation({ ...dataAdd });
    } else {
      const updatedData = {
        ...dataAdd,
        id: updateTenantId
      };
      res = await editTenantMutation(updatedData);
    }
    const alertAction = isAddForm ? MutationAction.Create : MutationAction.Update;
    alertsRef.current?.generate(mutationInfo("the operator", alertAction, res));

    if (!res?.error) {
      if (!isAddForm) {
        //add new tenant
        appRef.current?.updateStatus(false);
        setTimeout(() => navigate(baseRoute), 300);
      } else {
        //update tenant
        reset(data as ITenantData);
      }
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form
          data-testid="tenant-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
          <Card>
            <div className="card-body">
              <div className="my-3">
                <h4 className="text-uppercase mt-3 mb-0">Tenant Information</h4>
              </div>
              <FormInput
                name="tenant_name"
                label="Tenant Name"
                reg_options={{
                  required: true,
                  pattern: {
                    value: /^[a-z][a-z0-9_]+$/,
                    message:
                      "Name starts with letter and includes lower case letter, number and underscore only",
                  }
                }}
                disabled={!isAddForm}
              />
              <FormInput
                name="tenant_url_tag"
                label="Tenant URL Tag"
                reg_options={{
                  required: true,
                  pattern: {
                    value: /^[a-z][a-z0-9_]+$/,
                    message:
                      "Tenant URL Tag starts with letter and includes lower case letter, number and underscore only",
                  }
                }}
                disabled={!isAddForm}
              />
              <FormInput
                name="legal_name"
                label="Tenant Legal Name"
                reg_options={{
                  required: true,
                }}
              />
              {isAddForm ? <FormSelect
                name="industry_id"
                label="Industry Template"
                options={industryTemplates}
              /> :
                <FormInput
                  name="industry_name"
                  label="Industry Template"
                  disabled={true}
                />}
              <FormSelect
                name="environment_type"
                label="Environment Type"
                options={environmentTypes}
              />
              <div className="border-top my-3">
                <h4 className="text-uppercase mt-3 mb-0">contact details</h4>
              </div>
              <Contact ignores={["alter_phone"]} />
              <Address />
              <FormInput
                name="website"
                label="Website"
                type="url"
                placeholder="http://yourwebsite.com"
                reg_options={{ maxLength: 65 }}
              />
            </div>
          </Card>
          <div className="mt-4 d-flex justify-content-end">
            <Button
              data-testid=""
              disabled={!formChanged}
              type="submit"
              className="btn btn-primary"
              id="tenant-save-btn">
              Save
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  )
}

export default TenantForm