/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import {
  Attribute_Bool_Exp,
  Order_Bool_Exp,
  Product_Bool_Exp,
  Product_Order_By,
  Transactions_Transaction,
  Vw_Archived_User_Bool_Exp,
} from "../../../generated/urql-graphql";

export interface IconElementType {
  className?: string;
  to?: string;
  testId?: string;
  children: ReactNode;
}

export interface IFormInputProps {
  //input name
  name: string;
  //input label
  label?: string;
  //extra info for label
  label_extra?: ReactNode | string;
  //extra info for input
  input_extra?: ReactNode | string;
  //extra info for tooltip
  tool_tip?: string;
  tooltip_position?: string;
  //input div css
  css?: string;
  //input constraints
  reg_options?: RegisterOptions;

  form_methods?: UseFormReturn<any, object>;
}

export interface Filter {
  currentPage: number;
  where: Order_Bool_Exp | Product_Bool_Exp | Attribute_Bool_Exp | Vw_Archived_User_Bool_Exp;
  search: string;
}

export interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

export interface IDateRange {
  startDate: Date | null;
  endDay: Date | null;
  column_name: string;
}

export interface ILocalSingleFilter {
  id: string;
  filter_name: string;
  filter_op: string;
  filter_value: string;
}

export interface SelectOption {
  label: string;
  value: any;
}

export interface SelectOptionExt extends SelectOption {
  description?: string | ReactNode;
  disabled?: boolean;
}

export interface IFilterOption extends SelectOption {
  op?: string;
  filterValue?: any;
  type?: string;
  valueOptions?: SelectOption[];
}

interface AttributeBase {
  name: string;
  display: string;
  required: boolean;
  type: string;
  global: boolean;
}

interface AttributeProperty {
  tip?: string;
  ui_component?: string;
  max_length?: string;
  options?: SelectOption[];
  min_value?: number;
  max_value?: number;
  on_text?: string;
  off_test?: string;
  date_range?: boolean;
  input_type?: string;
}

export interface AttributeProps extends AttributeBase {
  id?: string;
  built_in: boolean;
  def_properties: AttributeProperty;
}

export interface SubAttributeProps extends AttributeBase, AttributeProperty {
  key_attribute?: boolean;
}

export interface IProductList {
  handleCallBack?: (where: string, orderBy: Product_Order_By | Product_Order_By[]) => void;
}

export interface OrderByType {
  display: string;
  column_name: string;
  orderBy: string;
}

export interface IActionState {
  action: string;
  ids: string[] | null;
  errorMsg?: string;
  item?: any;
}
export interface TransactionByType {
  display: string;
  column_name: string;
  orderBy: string;
}
export interface ITransactionUI extends Transactions_Transaction {
  debit: number | null | undefined;
  credit: number | null | undefined;
}

export interface ISearchParam {
  searchTerm: string | null;
  limit: number;
  offset: number;
  total: number;
  defaultValue?: string | null;
}

export interface ISearch {
  searchParam: ISearchParam;
  setSearchParam: (param: ISearchParam) => void;
  reset?: () => void;
  fetching?: boolean;
}

export enum ELoadingType {
  None = -1,
  ColumnSorting = 0,
  IncrementalSearch = 100,
  DateRange = 150,
  AddFilter = 200,
  Pagination = 300,
}

export interface IHash {
  [key: string]: any;
}

export interface IImportStatus {
  color: string;
  icon: string;
  text: string;
}

export const DashBoard_Monitor_On: boolean = false;
export const DashBoard_Monitor_Interval: number = 10000;

export interface IAlertInfo {
  message: string;
  type: "success" | "error";
  errMsg?: string | undefined;
}

export enum MutationAction {
  Add,
  Archive,
  Create,
  Update,
  Delete,
  Remove,
  Restore,
  Change,
  Login,
  Send,
  Enable,
  Disable,
}

//export interface ISettings {
//  preset: Scalars['jsonb'];
//  product: Scalars['jsonb'];
//  order: Scalars['jsonb'];
//  offer: Scalars['jsonb'];
//  seller: Scalars['jsonb'];
//  system: Scalars['jsonb'];
//  smtp: Scalars['jsonb'];
//}

export interface ISettings {
  preset: any;
  product: any;
  order: any;
  offer: any;
  seller: any;
  system: any;
  smtp: any;
}

export interface IOptions {
  options: SelectOption [];
  total: number;
}

export type ColumnDesc = {
  column_name: String;
  column_type?: String;
  display_name?: String;
};