import { FC } from "react";
import { addCommaFunc } from "../../orders-route/handler/handler";

type MoneyProps = { amount: number };

export const Money: FC<MoneyProps> = (props) => {
  return (
    <span>
      {/* TODO: fix currency symbol for negative numbers */}
      <span className="currency-symbol me-1">$</span>
      <span>{addCommaFunc(props.amount)}</span>
    </span>
  );
};
