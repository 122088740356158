import { FC, useState } from "react";
import { useExitEmulationMutation } from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface Props {
  cssClass?: string;
}

export const EmulationPrompt: FC<Props> = (props): ReturnType<FC> => {
  const userInfo = useUserInfo()!;
  const [, exitEmulationMutation] = useExitEmulationMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = (userInfo.user_level! > 20) ? "/" : `/${tenantUrlTag}`;
  const top = { top: "71px" };

  const continueExit = (isContinue: boolean) => {
    if (isContinue) {
      const emulateeKey = userInfo.seller_id ? "seller_uship_id" : userInfo.user_level == 20 ? "master_operator_uship_id" : "operator_uship_id";
      exitEmulationMutation({ userId: userInfo.emulater, emulateeKey })
        .then((res) => {
          alertsRef.current?.generate(mutationInfo("as the user", MutationAction.Login, res));
          if (res.data && !res.error) {
            window.location.href = baseRoute;
          }
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("as the user", MutationAction.Login, error));
        });
    } else setShowDialog(false);
  };

  return (
    <>
      {!isNullOrEmpty(userInfo.emulater) && (
        <div
          className={`${props.cssClass || ""} d-flex position-absolute justify-content-between align-items-center bg-warning px-4 pt-1 w-100`}
          style={top}
        >
          <p className="m-0">
            You are currently logged in as <strong>{userInfo.user_level! > 20 ? context.operatorInfo.name : userInfo.email}</strong>.
          </p>
          <Button data-testid="" className="btn-clean" onClick={() => setShowDialog(true)}>
            <i onClick={() => setShowDialog(true)} className="bi bi-box-arrow-right me-2"></i>
            Exit
          </Button>
        </div>
      )}
      <Dialog
        show={showDialog}
        title={userInfo.user_level! > 20 ? "Log Out of Tenant" : "Exit User Emulation"}
        continueText="Exit"
        continue={continueExit}
      >
        <div className="pt-2">
          {
            userInfo.user_level! > 20 ?
              "This will end your session for this tenant. Exit?" :
              "This will end your emulation session for this user account. Exit?"
          }
        </div>
      </Dialog>
    </>
  );
};
