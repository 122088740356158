import { handleDateRange } from "../../common/handlers/handleDateRange";
import { IDateRange } from "../../common/types/types";

const formatSearchPart = (searchPart: string, position:number) => {
    if (searchPart === "")
        return "";
    else {
        const commaBefore = position > 0 ? ',' : '';
        const commaAfter = position === 0 ? ',' : '';
        return `${commaBefore}${searchPart}${commaAfter}`

    }
}

// applies when the search needs to look up a value in one column only - such as in products or offers
export function handleWhereSingleColumnSearch({
    columnSearch = "",
    dateRange = { startDate: null, endDay: null, column_name: "" },
    selectedFilters = "",
    sellerFilter = "",
    statusFilter = ""
}: {
    columnSearch?: string;
    dateRange?: IDateRange;
    selectedFilters?: string;
    sellerFilter?: string;
    statusFilter?: string
}): string {
    return `{${formatSearchPart(columnSearch, 0)} "_and": [${selectedFilters}]${formatSearchPart(sellerFilter, 1)}${formatSearchPart(statusFilter,1)}${handleDateRange(dateRange)}}`;
    //return `{${columnSearch}${searchComma}${customRule}${handleDateRange(
    //        dateRange
    //    )} ${selectedFilters}}`;
}