import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CategoryPartFragment,
  useMoveCategoriesQuery,
  useMoveCategoryMutation,
  useRemoveCategoryMutation,
  useUpsertCategoryMutation
} from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { useGblAttrList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ISetProdCtg } from "../types/types";
interface IProps {
  category: CategoryPartFragment;
  isOpen?: boolean;
  fetching?: boolean;
  loadSubs?: (id: string) => void;
  setOpenStatus?: (open: boolean) => void;
  setProdCtg?: ISetProdCtg;
}

export const CategoryItem: FC<IProps> = ({
  category,
  isOpen,
  fetching,
  loadSubs,
  setOpenStatus,
  setProdCtg,
}: IProps): ReturnType<FC> => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const [context] = useMainContext();
  const { tenant_profit_model } = context.operatorSettings.preset;
  const {mv_del_ctg_n_mng_attr} = context.operatorSettings.product;
  const {tenant_url_tag: tenantUrlTag, cp_is_pim, industry_id} = context.operatorInfo;
  const mpMgn = userInfo.user_level! > 20;
  const canAddCtg = mpMgn || !industry_id; //not consuming tenant
  const canMvCtg = mpMgn || (!industry_id && mv_del_ctg_n_mng_attr);
  const canRvCtg = !industry_id && mv_del_ctg_n_mng_attr && !cp_is_pim;
  const attrPage = `/${tenantUrlTag}/settings/categories/:categoryId/attributes`;
  const glbAttrs = useGblAttrList();
  const [subOpen, setSubOpen] = useState<boolean>(isOpen || false);
  const defState = {
    ids: null,
    action: "",
    item: null,
  };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const [{ fetching: removing }, removeMutation] = useRemoveCategoryMutation();
  const [{ fetching: upserting }, upsertMutation] = useUpsertCategoryMutation();
  const [{ fetching: moving }, moveMutation] = useMoveCategoryMutation();

  const selectRef = useRef<SelectRef | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [moveId, setMoveId] = useState<string>(emptyUuid);
  const [errMsg, setErrMsg] = useState<string>("");
  useEffect(() => {
    if (actionState.action === "Move" && actionState.item?.id) {
      setMoveId(actionState.item?.id);
    }
  }, [actionState]);

  const [queried] = useMoveCategoriesQuery({
    variables: { ..._.omit(searchParam, "total"), moveId },
    pause: isNullOrEmpty(moveId),
  });

  useEffect(() => {
    if (queried.data) {
      const categories =
        queried.data.category.map(
          (ctg) =>
          ({
            label: ctg.fullname,
            value: ctg.path,
          } as SelectOption)
        ) || [];
      setCategoryOptions(categories);
      setSearchParam({ 
        ...searchParam, 
        total: queried.data.categories.aggregate?.count || 0 
      });
    }
  }, [queried.data]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(actionState.item);
  }, [actionState.item]);

  const nonPimEmptySubctg = !cp_is_pim && category.cp_subs === 0 
    && category.parent_id !== '11111111-1111-1111-1111-111111111111';
  const manageAttrs = (mpMgn || mv_del_ctg_n_mng_attr) && (setProdCtg
    ? nonPimEmptySubctg && !category.trg_product_category
    : (nonPimEmptySubctg || category.trg_product_category));

  const actions = [
    {
      enabled: !setProdCtg,
      actionType: "edit",
      id: category?.id,
      display: "Edit",
      icon: "bi bi-pencil",
      actionFunc: () =>
        setActionState({
          ...actionState,
          action: "Edit",
          item: category,
        }),
    },
    {
      enabled: canAddCtg && !setProdCtg && !category?.trg_product_category,
      actionType: "add",
      id: category?.parent_id,
      display: "Add",
      icon: "bi bi-folder-plus",
      actionFunc: () =>
        setActionState({
          ...actionState,
          action: "Add",
          item: { parent_id: category?.id, name: "", cp_subs: 0 },
        }),
    },
    {
      enabled: canMvCtg && !setProdCtg 
        && category.parent_id !== '11111111-1111-1111-1111-111111111111',
      actionType: "move",
      id: category?.parent_id,
      display: "Move",
      icon: "bi bi-arrows-move",
      actionFunc: () =>
        setActionState({
          ...actionState,
          action: "Move",
          item: category,
        }),
    },
    {
      enabled: manageAttrs,
      actionType: "manage_attr",
      id: category?.id,
      display: "Manage Attributes",
      icon: "bi bi-list-check",
      actionFunc: () => {
        if (setProdCtg) {
          setProdCtg.setProdCtgId(category.id);
        } else {
          navigate(attrPage.replace(":categoryId", category.id));
        }
      },
    },
    {
      enabled: canRvCtg && !setProdCtg && category?.cp_products === 0 && category?.cp_subs === 0,
      actionType: "delete",
      id: category?.id,
      display: "Delete",
      icon: "bi bi-trash",
      actionFunc: () => setActionState({ ...actionState, ids: [category?.id], action: "Delete" }),
    },
  ].filter((action) => action.enabled);

  const toggleSub = () => {
    if (!isOpen) {
      setSubOpen(!subOpen);
      setOpenStatus && setOpenStatus(!subOpen);
    }
    if (!subOpen && category.cp_subs !== 0) {
      loadSubs && loadSubs(category.id);
    }
    if (setProdCtg && category.trg_product_category) {
      setProdCtg.setProdCtgId(category.id);
    }
  };

  const continueDelete = async (isContinue: boolean) => {
    if (isContinue) {
      const categoryId = actionState.ids?.find((id) => id);
      const res = await removeMutation(
        { categoryId },
        { additionalTypenames: ["category", "category_aggregate"] }
      );
      if (!res.error) {
        setActionState(defState);
      }
      alertsRef.current?.generate(mutationInfo("category", MutationAction.Delete, res));
    } else setActionState(defState);
  };

  const continueSave = async (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    }
  };

  const onSubmit = async (formData: FieldValues) => {
    let ctgObj = _.omit(formData, ["__typename", "cp_subs", "cp_products", "add_global_attrs"]);
    ctgObj = { ...ctgObj, operator_id: userInfo.operator_id };
    if (formData.add_global_attrs) {
      const attrs = glbAttrs.map((attr) => ({ attribute_id: attr.id }));
      const attrsOrder = glbAttrs.map((attr) => attr.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ctgObj = {
        ...ctgObj,
        operator_id: userInfo.operator_id,
        category_attributes: { data: attrs },
      } as any;
    }
    const res = await upsertMutation(
      { category: ctgObj },
      { additionalTypenames: ["category", "category_aggregate", "category_attribute"] }
    );
    if (!res.error) {
      reset();
      setActionState(defState);
    }
    alertsRef.current?.generate(mutationInfo("category", MutationAction.Update, res));
  };

  const continueMove = async (isContinue: boolean) => {
    if (isContinue) {
      const parentIds = selectRef.current?.value;
      if (!parentIds.length) {
        setErrMsg("Move to category is required");
        return;
      }
      const categoryId = actionState.item?.id;
      const res = await moveMutation(
        { categoryId, parentId: parentIds[parentIds.length - 1] },
        { additionalTypenames: ["category", "category_aggregate"] }
      );
      const info = mutationInfo("category", MutationAction.Update, res)
      if (!res.error) {
        alertsRef.current?.generate(info);
        setActionState(defState);
        setErrMsg("");
      } else {
        setErrMsg(info.message);
      }
    } else setActionState(defState);
  };

  const RemoveUrl = async () => {
    const updatedItem = { ...actionState.item, image_url: null };
    setActionState({
      ...actionState,
      action: "Edit",
      item: updatedItem
      ,
    })

  };

  return (
    <>
      <div key={category.id}>
        <div
          className={`d-flex align-items-center justify-content-between category position-relative ${setProdCtg?.prodCtgId === category.id ? "active" : ""
            }`}
        >
          <div
            className="d-flex flex-grow-1 align-items-center justify-content-between"
            onClick={toggleSub}
          >
            <div className="ps-3 d-flex category-name-wrapper">
              {category?.cp_subs !== 0 ? (
                <>
                  <div className="">
                    <i
                      className={`me-1 bi ${fetching
                        ? "spinner-border spinner-border-sm"
                        : subOpen
                          ? "bi-folder2-open"
                          : "bi-folder"
                        }`}
                    ></i>
                    <label className="category-name font-weight-normal ms-1">{category.name}</label>
                  </div>
                </>
              ) : (
                <p className="mb-0 d-flex align-items-center">
                  {category.trg_product_category ? (
                    <i className="bi bi-bag-check"></i>
                  ) : (
                    <i className="bi bi-folder"></i>
                  )}
                  <span className="category-name mx-2">{category.name}</span>
                  {category.trg_product_category && (
                    <span className="product-count small">({category.cp_products})</span>
                  )}
                </p>
              )}
              {!setProdCtg && category.parent_id === '11111111-1111-1111-1111-111111111111' && (
                <div className="commission-rate d-flex align-items-center mx-2">
                  <small className="text-muted mb-0">
                    {tenant_profit_model === "Commission"
                      &&
                      <span className="rate-wrapper" data-title="Commission Rate">
                        {category.commission_rate}%
                      </span>
                    }
                  </small>
                </div>
              )}
            </div>
            <div className={`d-flex position-relative small ${actions.length === 0 && "me-3"}`}>
              {category.trg_product_category ? null : setProdCtg ? (
                <span>({category.cp_subs})</span>
              ) : (
                <span>({category.cp_subs})</span>
              )}
            </div>
          </div>
          {actions.length > 0 && (
            <div>
              <DropdownItems items={actions} />
            </div>
          )}
        </div>
      </div>
      <Dialog
        show={actionState.action === "Delete"}
        title="Delete Category"
        continue={continueDelete}
        continueText="Delete"
        contineBtnCss={"btn-danger"}
        fetching={removing}
      >
        Are you sure you want to delete this category?
      </Dialog>
      <Dialog
        title={`${actionState.action} Category`}
        show={["Edit", "Add"].includes(actionState.action)}
        subFormId="manage-category-form"
        continueText="Save"
        staticModal={true}
        continue={continueSave}
        fetching={upserting}
      >
        <FormProvider {...methods}>
          <Form
            data-testid="manage-category-form"
            id="manage-category-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          >
            <div className="form-group">
              <FormInput
                name="name"
                label="Category Name"
                reg_options={{ required: true, maxLength: 80 }}
              />
              {actionState.item?.parent_id === '11111111-1111-1111-1111-111111111111'
                ? (
                  tenant_profit_model === "Markup" ?
                    <>
                      {/*
                      <FormInput
                        type="number"
                        name="markup_rate"
                        label="Markup Rate"
                        reg_options={{
                          min: 0,
                          max: 100,
                          pattern: /^\d+(\.\d{0,2})?$/,
                        }}
                        measurelabel="%"
                      />
                      */}
                    </>
                    :
                    <FormInput
                      type="number"
                      name="commission_rate"
                      label="Commission Rate"
                      reg_options={{
                        min: 0,
                        max: 100,
                        pattern: /^\d+(\.\d{0,2})?$/,
                      }}
                      measurelabel="%"
                    />
                ) : (
                  !actionState.item?.trg_product_category &&
                  actionState.item?.cp_subs === 0 && (
                    <FormInput
                      name="add_global_attrs"
                      label="Add global attributes"
                      css="d-flex flex-row align-items-start"
                      label_extra={
                        <span
                          className="font-weight-normal"
                          data-title="Subcategories can no longer be added."
                        >
                          <i className="ms-2 bi bi-info-circle-fill text-muted"></i>
                        </span>
                      }
                      type="checkbox"
                      isswitch="true"
                    />
                  )
                )}
              <FormInput
                name="image_url"
                label="Image URL"
                reg_options={{
                  pattern: {
                    value: /https:*/,
                    message:
                      "Invalid image url",
                  }
                }}
              />
              {actionState.item?.image_url && (
                <div className="product-image-thumbnails" style={{ width: 200, margin: "auto" }} >
                  <div className="thumbnails d-flex justify-content-between align-items-center p-2" >
                    <div className="d-flex align-items-center">
                      <img style={{ width: 150, margin: "auto", display: "block" }}
                        src={actionState.item?.image_url}
                        alt="new"
                      />
                    </div>
                    <Button
                      data-testid=""
                      className="btn btn-link"
                      type="button"
                      onClick={async () => RemoveUrl()}
                    >
                      <i className="bi bi-x-circle-fill text-muted" />
                    </Button>
                  </div>
                </div>
              )}
              <FormTextarea
                name="description"
                label="Category Description"
              />
              <FormTextarea
                name="meta_description"
                label="Category Meta Description"
                reg_options={{ maxLength: 160 }}
              />
            </div>
          </Form>
        </FormProvider>
      </Dialog>
      <Dialog
        title={`${actionState.action} Category`}
        show={["Move"].includes(actionState.action)}
        continueText={actionState.action}
        continue={continueMove}
        fetching={moving}
        size="lg"
      >
        <div className="mb-3">
          <span className="font-weight-semi-bold">Moving:</span>
          <code className="ms-2">{actionState.item?.name}</code>
        </div>
        <div className="mb-3">
          <CustomSelect
            label="Select category to move to"
            required={true}
            ref={selectRef}
            options={categoryOptions}
            placeholder="Please select or search for a category"
            search={{ searchParam, setSearchParam }}
          />
          {errMsg && (
            <small className="has-error text-danger">
              <i className="bi bi-exclamation-circle-fill me-1"></i>
              {errMsg}
            </small>
          )}
        </div>
      </Dialog>
    </>
  );
};
