import { useState, forwardRef, useImperativeHandle } from "react";
import { Input } from "../../forms/components/Input";

interface ChipsProps {
  contentFormat: string;
}

interface ChipsState {
  chipList: string[];
  chip: string;
  selectedChip: string;
  errorMsg: string;
}

export type ChipsRef = {
  validate: () => boolean;
  get: () => string[];
};

export const Chips = forwardRef<ChipsRef, ChipsProps>((props, ref) => {
  const [chipsState, setChipsState] = useState<ChipsState>({
    chipList: [],
    chip: "",
    selectedChip: "",
    errorMsg: "",
  });

  useImperativeHandle(ref, () => ({
    get: () => chipsState.chipList,
    validate: () => {
      const item = addChip();
      const list = chipsState.chipList;
      if (item !== "") {
        list.push(item);
      }
      const isValid = list.length > 0;
      if (!isValid)
        setChipsState({ ...chipsState, errorMsg: "Please specify at least one recipient." });
      else setChipsState({ ...chipsState, chipList: list, chip: "" });
      return isValid;
    },
  }));

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const chip = chipsState.chip;
    if ([","].includes(event.key)) {
      event.preventDefault();
      addChip();
    } else if (["Backspace"].includes(event.key) && chip?.length === 0) {
      if (chipsState.selectedChip) {
        const list = chipsState.chipList.filter((i) => i !== chipsState.selectedChip);
        setChipsState({ ...chipsState, chipList: list, selectedChip: "" });
      } else if (chipsState.chipList.length > 0) {
        setChipsState({
          ...chipsState,
          selectedChip: chipsState.chipList[chipsState.chipList.length - 1],
        });
      }
    }
  };

  const addChip = () => {
    const item = chipsState.chip;
    const format = new RegExp(props.contentFormat);
    if (!chipsState.chipList.includes(item) && format.test(item)) {
      const list = [...chipsState.chipList, item];
      setChipsState({ ...chipsState, chipList: list, chip: "" });
      return item;
    }
    return "";
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text");
    const format = new RegExp(props.contentFormat, "g");
    const chips = paste.match(format);
    if (chips) {
      const uniques = chips
        .filter((item, pos) => chips?.indexOf(item) === pos)
        .filter((item) => !chipsState.chipList.includes(item));
      setChipsState({ ...chipsState, chipList: [...chipsState.chipList, ...uniques] });
    }
  };

  const handleFocus = () => {
    if (chipsState.errorMsg) setChipsState({ ...chipsState, errorMsg: "" });
  };

  return (
    <>
      <div className="chips d-flex flex-row p-1 flex-wrap">
        {chipsState.chipList.map((item) => (
          <div
            className={
              "chip d-flex ps-2 pe-2 me-2 mb-1 text-break align-items-center " +
              (chipsState.selectedChip === item ? "selected" : "")
            }
            key={item}
          >
            {item}
            <div
              className="ms-2"
              onClick={() =>
                setChipsState({
                  ...chipsState,
                  chipList: chipsState.chipList.filter((i) => i !== item),
                })
              }
            >
              &times;
            </div>
          </div>
        ))}
        <div className="input-wrapper">
          <Input
            ref={null}
            value={chipsState.chip}
            data-testid="chip component"
            className="form-control border-0"
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChipsState({ ...chipsState, chip: event.target.value })
            }
            onPaste={handlePaste}
            onFocus={handleFocus}
          />
        </div>
      </div>
      {chipsState.errorMsg && (
        <p className="small text-danger m-0 mt-1">
          <i className="bi bi-exclamation-circle-fill me-1"></i>
          {chipsState.errorMsg}
        </p>
      )}
    </>
  );
});
