import classNames from "classnames";
import { FC, ReactNode } from "react";

type ColumnAlignEndProps = { className: string; children: ReactNode };

export const ColumnAlignEnd: FC<ColumnAlignEndProps> = (props) => {
  return <div className={classNames(props.className, "text-right")}>{props.children}</div>;
};

export const ColumnAlignLeft: FC<ColumnAlignEndProps> = (props) => {
  return <div className={classNames(props.className, "text-left")}>{props.children}</div>;
};

export const ColumnAlignMiddle: FC<ColumnAlignEndProps> = (props) => {
  return <div className={classNames(props.className, "text-center")}>{props.children}</div>;
};