import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useInsertAdjustmentMutation, useSellerNamesQuery } from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
import { Dialog } from "../../common/components/Dialog";

type adjustmentType = "ManualDebit" | "ManualCredit" | undefined;

interface IFormData {
  seller_id: string;
  adj_type: adjustmentType;
  amount: number | undefined;
  description: string;
}

export const AdjustmentForm = (props: { close: () => void }) => {
  const [sellerOptions, setSellerOptions] = useState<SelectOption[]>([]);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const selectRef = useRef<SelectRef | null>(null);
  const [queried] = useSellerNamesQuery({
    variables: _.omit(searchParam, "total"),
  });
  useEffect(() => {
    if (queried.data) {
      const sellers =
        queried.data.seller.map((seller) => {
          return { label: seller.label, value: seller.value };
        }) || [];
      setSellerOptions(sellers);
      setSearchParam({ ...searchParam, total: queried.data.sellers.aggregate?.count || 0 });
    }
  }, [queried]);

  const def_data = {
    seller_id: emptyUuid,
    adj_type: undefined,
    amount: undefined,
    description: "",
  };

  const methods = useForm<IFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: def_data,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  const [sellerErrorMsg, setSellerErrorMsg] = useState<string>("");
  const [{ fetching }, insertAdjustmentMutation] = useInsertAdjustmentMutation();

  const checkSeller = () => {
    const sellerId = selectRef.current?.value;
    if (!!!sellerId) {
      setSellerErrorMsg("Valid seller is required");
      return null;
    }

    setSellerErrorMsg("");
    return sellerId;
  }

  const onSubmit = async (data: FieldValues) => {
    const { adj_type, description, amount } = data;
    const sellerId = checkSeller();
    if (!sellerId)
      return;
    const tr_amount = adj_type === "ManualCredit" ? amount : amount * -1;
    const res = await insertAdjustmentMutation({
      seller_id: sellerId,
      transaction_type: adj_type,
      amount: tr_amount,
      description: description || null,
    }, { additionalTypenames: ["transactions_transaction_aggregate", "transactions_transaction", "search_counts", "transactions_vw_transaction"] });

    if (!res?.error) {
      reset();
      props.close();
    }
    alertsRef.current?.generate(mutationInfo("transaction adjustment", MutationAction.Create, res));
  };

  const continueAdjust = (isContinue: boolean) => {
    if (!isContinue)
      props.close();
  }

  return (
    <Dialog
      title="Add Adjustment"
      continue={continueAdjust}
      fetching={fetching}
    >
      <FormProvider {...methods}>
        <Form
          data-testid="seller-form"
          noValidate
          className={
            errors && Object.keys(errors).length !== 0 ? "was-validated form-group" : "form-group"
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="no-top-border">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <CustomSelect
                    ref={selectRef}
                    required={true}
                    label="Select Seller"
                    options={sellerOptions}
                    placeholder="Search for seller..."
                    search={{ searchParam, setSearchParam, fetching: queried.fetching }}
                    errorMsg={sellerErrorMsg}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <FormSelect
                    name="adj_type"
                    label="Adjustment Type"
                    options={[
                      { label: "Credit", value: "ManualCredit" },
                      { label: "Debit", value: "ManualDebit" },
                    ]}
                    reg_options={{ required: true }}
                  />
                </div>
                <div className="col-6">
                  <FormInput
                    name="amount"
                    label="Amount"
                    type="number"
                    reg_options={{
                      required: true,
                      min: 0.01,
                      max: 9999999,
                    }}
                    inputgrouplabel="$"
                  />
                </div>
              </div>
              <FormTextarea name="description" label="Description" className="mb-3 form-control" />
            </div>
          </div>
        </Form>
      </FormProvider>
    </Dialog>
  );
};
