import { Route, Routes } from "react-router-dom";
import { useMainContext } from "../../layout/components/MainProvider";
import { AddProduct } from "./AddProduct";
import { EditProduct } from "./EditProduct";
import { ProductTabs } from "./ProductTabs";
import { SellerProduct } from "./SellerProduct";
import { ProductImportRoute } from "../../products-import-route/components/ProductsImportRoute";
import { IndustryProduct } from "./IndustryProduct";

export function ProductsRoute() {
  const [context] = useMainContext();
  const { products_require_operator_approval } = context.operatorSettings.product;
  const manageProductApproval = products_require_operator_approval;

  return (
    <Routes>
      <Route index element={<ProductTabs />} />
      {["archived", "import-history", "pim"].map((path, index) =>
        <Route key={index} path={path} element={<ProductTabs />} />
      )}
      {manageProductApproval &&
        <>
          <Route path="pending" element={<ProductTabs />} />
          <Route path="pending/:productId" element={<SellerProduct />} />
        </>
      }
      <Route path="import" element={<ProductImportRoute />} />
      <Route path="add" element={<AddProduct />} />
      <Route path="updates" element={<ProductImportRoute />} />
      <Route path="pim/:productId" element={<IndustryProduct />} />
      <Route path=":productId" element={<EditProduct />} />
    </Routes>
  );
}
