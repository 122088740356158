import { FC } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useProductDetailQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss } from "../../common/miscellaneous/utility";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";
import { ProductDetails } from "./ProductDetails";
import { ProductImageCarousel } from "./ProductImageCarousel";
import { ProductMetric } from "./ProductMetrics";

export const EditProduct: FC = (): ReturnType<FC> => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseUrl = `/${tenantUrlTag}/products`;
  const userInfo = useUserInfo()!;
  const { products_require_operator_approval: prodAppr } = context.operatorSettings.product;
  const formatDate = useDateFormat();

  const { productId } = useParams<{ productId: string }>();
  const [productQueried] = useProductDetailQuery({
    requestPolicy: 'network-only',
    variables: { productId },
  });

  const error = productQueried.error;
  if (error) {
    return <PageError error={{ source: "EditProduct", errMsg: error.message }} />;
  }

  if (!productQueried.data || productQueried.fetching) {
    return <Loader />;
  }

  const prodData = productQueried.data.data_vw_product?.find(prod => prod)!;
  const isSellerProd = userInfo.seller_id === prodData?.seller_id;

  const productImgUrls = getProdImgUrls(prodData.data.product_image_urls);
  const SellerInfo = (): JSX.Element => {
    if (prodData.info.ds_prod_id) {
      return <span>External data source</span>;
    }
    if (prodData.pim_prod_id) {
      return <span>PIM Catalog</span>;
    }
    else if (!prodData.seller_id) {
      return <span>Created by operator</span>;
    }
    else if (isSellerProd || !userInfo.seller_id) {
      return (
        <NavLink
          data-testid="nav-seller"
          to={`/${tenantUrlTag}/sellers/${prodData.seller_id}`}
          className="nav-link text-truncate text-decoration-underline pb-0 pt-0 ms-1"
        >
          {prodData.info.seller_name || ""}
        </NavLink>
      );
    }
    else
      return <span>Seller</span>;
  };

  const pendingSellerProd = !userInfo.seller_id && productQueried.data?.seller_product?.status === "Pending";
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader
            url={baseUrl}
            title="Product Detail"
          >
            <div>
              {prodAppr && (isSellerProd || pendingSellerProd) &&
                (
                  <NavLink
                    data-testid="nav-message"
                    to={{ pathname: `${baseUrl}/pending/${productId}` }}
                    className="nav-link text-truncate pb-0 pt-0"
                  >
                    {pendingSellerProd ? "Pending " : "My "} Product
                    <i className="small bi bi-caret-right-fill"></i>
                  </NavLink>
                )}
            </div>
          </ReturnHeader>
          <Card>
            <div className="row p-3">
              <div className="col-sm-12 col-md-3 d-flex justify-content-center">
                {productImgUrls?.length ? (
                  <ProductImageCarousel images={productImgUrls} />
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-none d-md-block img-placeholder">
                      <i className="bi bi-card-image ps-2"></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-12 col-md-9">
                <h3 className="sub-title font-weight-semi-bold">
                  {prodData.data.product_title || "NAME"}
                </h3>
                <div className="mb-0">
                  <span className="font-weight-semi-bold">Manufacturer: </span>
                  {prodData.data.manufacturer}
                </div>
                <div className="mb-0">
                  <span className="font-weight-semi-bold">Manufacturer Part Number: </span>
                  {prodData.data.mfr_part_number}
                </div>
                <div className="mb-0">
                  <span className="font-weight-semi-bold">Status: </span>
                  <span className={`badge rounded-pill ${badgeCss(prodData.status)}`}>{prodData.status}</span>
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <span className="font-weight-semi-bold me-1">Source:</span>
                  {SellerInfo()}
                </div>
                <div className="mb-1">
                  <span className="font-weight-semi-bold">Published: </span>
                  <span className="text-muted">{formatDate(prodData.created_date)}</span>
                </div>
              </div>
            </div>

            <div className="row p-3 pt-0">
              <ProductMetric
                mpin={prodData.mpin || ""}
                offer={prodData.offer_count || 0}
                metricData={[
                  { icon: "bi-cart", label: "Orders", name: "order_count" },
                  { icon: "bi-cash", label: "Total Revenue", name: "total_sale" },
                  { icon: "bi-tag", label: "Offers", name: "offer" },
                ]}
              />
            </div>
          </Card>
          <ProductDetails
            categoryId={prodData.category_id}
            productData={prodData}
          />
        </div>
      </div >
    </>
  );
};
