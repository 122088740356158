import { FC, createRef, useImperativeHandle } from "react";
import { useRecoilState } from "recoil";
import { IAppStatus, appStatusValue } from "../hooks/globals";
import { Dialog } from "./Dialog";
import { useBlocker } from "react-router-dom";

export const appRef = createRef<{
  updateStatus: (changed: boolean) => void;
  getStatus: () => IAppStatus;
}>();

export const RouterPrompt: FC = () => {
  const [appStatus, setAppStatus] = useRecoilState<IAppStatus>(appStatusValue);
  const blocker = useBlocker(appStatus.changed);

  const confirm = (continueLeave: boolean) => {
    if (continueLeave) {
      appRef.current?.updateStatus(false);
      blocker.proceed?.();
    }
    blocker.reset?.()
  };

  useImperativeHandle(appRef, () => {
    return {
      updateStatus: (changed: boolean) => setAppStatus({ changed: changed }),
      getStatus: () => appStatus,
    };
  });

  return (
    <Dialog
      show={blocker.state==="blocked"}
      title="You have unsaved changes"
      continueText="Continue without saving"
      closeText="Stay"
      continue={confirm}
    >
      <div className="py-2">Your changes will be lost if you continue.</div>
    </Dialog>);
};