import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useReportCategoryComparisonQuery } from "../../../generated/urql-graphql";
import { ExportData } from "../../azure/components/AzureBlob";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { ELoadingType, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

const PriceComparisonReport = () => {
    const [context] = useMainContext();
    const { sellerId } = useParams<{ sellerId: string }>();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/sellers/${sellerId}`;
    const notificationUrl = `/${tenantUrlTag}/users/notification`;
    const { number_items_per_page } = context.operatorSettings.preset;
    const [loading, setLoading] = useState<number>(-1);
    const [offset, setOffset] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<OrderByType>({
        display: "Category",
        column_name: "fullname",
        orderBy: "asc",
    });
    const orderByOptions: { [key: string]: string } = {
        Category: "fullname",
        My_Offer_Count: "my_offers_count",
        Price_Comparison: "difference_percentage"
    }
    const tableColumns = ["Category", "My Offer Count", "Price Comparison"]

    const [queried] = useReportCategoryComparisonQuery({
        variables: {
            sellerId,
            offset: offset || 0,
            limit: number_items_per_page,
            order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined
        }
    });
    const reportData = queried.data?.vw_report_category_comparison
    const totalCount = queried.data?.vw_report_category_comparison_aggregate.aggregate?.count
    useEffect(() => {
        if (reportData) {
            setLoading(ELoadingType.None);
        }
    }, [reportData]);

    const error = queried.error;
    if (error) {
        return <PageError error={{ source: "PriceComparisonReport", errMsg: error.message }} />;
    }

    if (!reportData) {
        return (
            <Card>
                <PlaceholderTableSearchItem />
                <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
            </Card>
        );
    }

    const setAlert = (message: string, messageType: "success" | "error") => {
        alertsRef.current?.add(message, messageType);
    };
    const tableData = reportData.map(item => {
        return {
            id: item.category_id,
            Category: <div className="category-fullname">
                {item.fullname}
            </div>,
            My_Offer_Count: item.my_offers_count,
            Price_Comparison: <div>
                <div className="d-flex text-muted">
                    <div className="text-left me-2">
                        Your Price: ${item.my_offers_avg_price}
                    </div>
                    <div className="text-left">
                        Avg Price: ${item.offers_avg_price}
                    </div>
                    <div className="ms-auto">
                        {item.difference_percentage < 0 ?
                            `${Math.abs(item.difference_percentage)}% below average`
                            : item.difference_percentage == 0 ? `${item.difference_percentage}%` : `${item.difference_percentage}% above average`}
                    </div>
                </div>
                <div className="progress-container py-1 mb-2">
                    <div
                        className="progress"
                        role="progressbar"
                        arial-aria-valuenow={item.difference_percentage}
                        arial-aria-valuemin={0}
                        ariel-aria-valuemax={200}>
                        <div
                            className={"progress-bar progress-bar-striped bg-info"}
                            style={{ width: `${((item.difference_percentage || 0) + 100) / 2}%` }}
                        ></div>
                    </div>
                </div>
            </div>
        }
    })
    return (
        <div className="inner-wrapper">
            <div className="row ">
                <div className="col-lg-12 mx-auto">
                    <ReturnHeader
                        url={baseRoute}
                        title="Price Comparison Report"
                    >
                        <div className="d-flex flex-xs-column flex-sm-row justify-content-end align-items-start navbar-expand-xl">
                            <div className="navbarProductBtns align-items-end navbar-collapse collapse">
                                <ul className="navbar-nav ms-xl-auto">
                                    <li className="nav-items ms-1">
                                        <ExportData queryString={`QUERY_EXPORT_PRICE_COMPARISON_REPORT`}
                                            inputJson={`{"variables":{"where":{"seller_id":{"_eq":"${sellerId}"}}}}`}
                                            caption={`Export`}
                                            setAlert={setAlert}
                                            redirectLink={notificationUrl} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ReturnHeader>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 mx-auto">
                    <Card className="p-4">
                        <Table
                            columnNames={tableColumns}
                            data={tableData}
                            offset={offset}
                            setOffset={setOffset}
                            totalRecords={totalCount || 0}
                            setOrderBy={setOrderBy}
                            orderByOptions={orderByOptions}
                            orderBy={orderBy}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default PriceComparisonReport