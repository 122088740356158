import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import { AddOffer } from "./AddOffer";
import { EditOffer } from "./EditOffer";
import { OfferList } from "./OfferList";

export const OffersRoute: FC = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const manageOffer = userInfo.permissions! & context.permissions.manage_offer;
  const {
    sellers_can_define_decrement
  } = context.operatorSettings.offer;

  return (
    <Routes>
      <Route index element={<OfferList />} />
      {["archived", "inactive", "import-history"].map((path, index) =>
        <Route key={index} path={path} element={<OfferList />} />
      )}
      <Route path=":offerId" element={<EditOffer />} />
      {manageOffer && userInfo.seller_id &&
        <>
          <Route path="add" element={<AddOffer />} >
            <Route path=":prodId" element={<AddOffer />} />
          </Route>
          {sellers_can_define_decrement &&
            <Route path="advanced-settings" element={<OfferList />} />}
        </>}
    </Routes>
  );
};

