import { useEffect, useState } from "react";
import {
  useLocationAndRatesQuery,
  useLocationRatesSubscription,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { LocationDetails } from "./LocationDetails";
import { LocationRate } from "./LocationRate";

export function SellerShipping() {
  const [context] = useMainContext();
  const [queried] = useLocationAndRatesQuery();
  const [subscribed] = useLocationRatesSubscription({});
  const [addAction, setAddAction] = useState<string>("");
  const userInfo = useUserInfo()!;
  const [manage, setManage] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo && userInfo.permissions && context && context.permissions.update_seller) {
      setManage(BigInt(userInfo.permissions!) & BigInt(context.permissions.update_seller) ? true : false);
    }
    else {
      setManage(false);
    }
  }, [userInfo, context]);

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "SellerShipping", errMsg: error.message }} />;
  }

  if (!queried.data || !subscribed.data) {
    return <Loader />;
  }
  const locations = subscribed.data?.address ?? queried.data?.address;
  const zone_list: SelectOption[] = queried.data.shipping_zone.map((zone) => {
    return { label: zone.zone_name, value: zone.id };
  });
  const deliveryMethods: SelectOption[] = queried.data.delivery_method.map((dmd) => {
    return { label: dmd.delivery_method_name, value: dmd.id };
  });
  const shippingCarriers: SelectOption[] = queried.data.shipping_carrier.map((crr) => {
    return { label: crr.shipping_carrier, value: crr.id };
  });

  return (
    <div>
      <ReturnHeader
        title="Manage Locations and Rates"
        description="Manage the locations you stock inventory and the set of rules that define the shipping rates and conditions that will be displayed to your customers during checkout."
      >
        {manage ? (
          <div className="d-flex flex-row align-items-start">
            <Button
              data-testid=""
              className="btn btn-primary"
              onClick={() => setAddAction("add_location")}
            >
              Add Location
            </Button>
          </div>
        ) : null}
      </ReturnHeader>
      <div>
        <LocationRate
          location={locations.find((loc) => loc.is_primary)!}
          zone_list={zone_list}
          delivery_methods={deliveryMethods}
          shipping_carriers={shippingCarriers}
        />
        {locations
          .filter((loc) => !loc.is_primary)
          .map((loc) => (
            <LocationRate
              location={loc}
              key={loc.id}
              zone_list={zone_list}
              delivery_methods={deliveryMethods}
              shipping_carriers={shippingCarriers}
            />
          ))}
      </div>
      <LocationDetails action={addAction} location_item={null} setClose={() => setAddAction("")} />
    </div>
  );
}
