import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import React, { FC, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import {
  useUpdateUserProfileMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { appRef } from "../../common/components/appStatus";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadAppData } from "../../common/miscellaneous/storage";
import { getFileExt, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

const DEFAULT_USER_AVATAR = "/default-user-avatar.jpeg";

export const UserProfile: FC = () => {
  const { user } = useAuth0();
  const userInfo = useUserInfo()!;
  const userProfile = useUserProfile();
  const [context] = useMainContext();

  const imageHeight = 320;
  const imageWidth = 320;
  const imageQuality = 100;
  const imageOutputType = "base64";
  const avatar = (!userInfo.emulater && user?.picture) || DEFAULT_USER_AVATAR;
  const [profileImage, setProfileImage] = useState<string>(avatar);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const maxImageSize = context.operatorSettings.preset.max_image_size;

  const imgRef = useRef<HTMLInputElement>(null);
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const [{ fetching }, updateUserProfileMutation] = useUpdateUserProfileMutation();

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset
  } = methods;

  useEffect(() => {
    let changed = Object.keys(dirtyFields).length !== 0;
    changed = changed || (profileImage !== DEFAULT_USER_AVATAR && profileImage !== userProfile.profile_image);
    setFormChanged(changed);

    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length, profileImage]);

  useEffect(() => {
    reset(userProfile);
    if (userProfile.profile_image) {
      setProfileImage(userProfile.profile_image);
    }
  }, [userProfile]);

  const resizeFile = (file: File) =>
    new Promise<string>(async (resolve) => {
      Resizer.imageFileResizer(
        file,
        imageWidth,
        imageHeight,
        "JPEG",
        imageQuality,
        0,
        (uri: string | Blob | ProgressEvent<FileReader> | File) => {
          resolve(uri.toString());
        },
        imageOutputType
      );
    });
  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];

      const imgStr = await resizeFile(file);
      setProfileImage(imgStr);
    }
  };
  const handleImportPictureClick = () => {
    imgRef?.current?.click();
  };
  const onSubmit = async (formData: FieldValues) => {
    const validForm = Object.keys(errors).length === 0;
    if (!validForm) {
      return;
    }
    const imgFiles = imgRef.current?.files;
    if (imgFiles && imgFiles.length > 0) {
      const imageFile = imgFiles[0];
      if (imageFile.size >= maxImageSize * 1024) {
        alertsRef.current?.add(`Image size exceeds the ${maxImageSize}KB maximum file size`, "error");
        return;
      }

      // *** UPLOAD TO AZURE STORAGE ***
      const uniqueFileName = `profile_image_${userInfo.user_id}${getFileExt(imageFile.name)}`;
      const url = await uploadAppData(imageFile, uniqueFileName);
      setFormChanged(false);
      formData.profile_image = url;
    }
    const profile = _.pick(formData, ["first_name", "last_name", "phone", "profile_image"]);
    const res = await updateUserProfileMutation({ userId: userInfo.user_id, profile: { ...profile, updated_date: "now()" } });
    alertsRef.current?.generate(mutationInfo("the user profile", MutationAction.Update, res));
    if (!res.error) {
      reset(formData);
    }
  };
  const handleKeyUp = (event: React.KeyboardEvent) => {
    let contactRef = document.querySelector<HTMLInputElement>('#profile-contact');
    if (contactRef) {
      if (event.key !== 'Backspace' &&
        contactRef &&
        (contactRef.value.length === 3 ||
          contactRef.value.length === 7)) {
        contactRef.value += '-';
      }
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <Form
          data-testid="user-profile-form"
          id="user-profile-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="d-flex flex-column">
            <Card className="p-4 no-top-border">
              <div className="p-0">
                <div className="row">
                  <div className="col-sm">
                    <FormInput
                      name="first_name"
                      label="First Name"
                      type="text"
                      placeholder="Please enter your first name"
                      reg_options={{
                        required: true,
                        maxLength: 100,
                        pattern: /^[a-z ,.'-]+$/i
                      }}
                    />
                  </div>
                  <div className="col-sm">
                    <FormInput
                      name="last_name"
                      label="Last Name"
                      type="text"
                      placeholder="Please enter your last name"
                      reg_options={{
                        required: true,
                        maxLength: 100,
                        pattern: /^[a-z ,.'-]+$/i
                      }}
                    />
                  </div>
                </div>
              </div>

              <Label className="font-weight-medium d-flex align-items-center mt-3" data-testid="">
                Profile Image
              </Label>
              <p className="py-1">
                A profile image helps personalize your account and lets you know if you're signed
                into your account.
              </p>
              <div className="container-overlay">
                <img
                  src={profileImage}
                  className="border rounded-circle"
                  alt=""
                  width="100px"
                  height="100px"
                  onError={(event) => {
                    event.currentTarget.src = "/default-user-avatar.jpeg";
                  }}
                />

                <div className="overlay">
                  <i className="bi bi-camera display-1" onClick={handleImportPictureClick}></i>
                </div>
              </div>
              <Input
                ref={imgRef}
                type="file"
                data-testid="user-profile-upload"
                style={{ display: "none" }}
                onChange={onImageChange}
                accept=".jpg, .png, .jpeg"
              />
              <FormInput
                name="email"
                label="MarketPush Account Email"
                data-testid=""
                type="text"
                placeholder="Please enter email"
                disabled={true}
                css="mt-3"
              />
              <FormInput
                id="profile-contact"
                name="phone"
                label="Contact Phone Number"
                data-testid=""
                type="tel"
                placeholder="Please enter contact phone number: 123-456-7890"
                reg_options={{
                  required: false,
                  maxLength: 12,
                  pattern: /^[+\s\-]?(\(?\d{3}\)?[\s.\-]?)?[\d\s\-]{0,12}$/i
                }}
                css="mt-3"
                onKeyUp={handleKeyUp}
              />
            </Card>
            <div className="mt-4 d-flex justify-content-end">
              {fetching ? (
                <Button
                  data-testid=""
                  type="submit"
                  className="btn btn-primary"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              )
                : (
                  <Button
                    data-testid=""
                    type="submit"
                    className="btn btn-primary"
                    disabled={!formChanged}
                  >
                    Save
                  </Button>
                )
              }
            </div>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
