import { FC, useEffect, useState } from "react";
import { CategoryPartFragment, useCategoryOptionsQuery } from "../../../generated/urql-graphql";
import { ISearchParam } from "../../common/types/types";
import { ISetProdCtg } from "../types/types";
import { CategoryItem } from "./CategoryItem";
import { Subcategories } from "./Subcategories";

interface IProps {
  category: CategoryPartFragment;
  level: number;
  setProdCtg?: ISetProdCtg
}

export const Category: FC<IProps> = ({ category, level, setProdCtg }: IProps): ReturnType<FC> => {
  const incLimit = 15;
  const defParam = { searchTerm: null, limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [subOpen, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryPartFragment[]>([]);
  const inputs = {
    variables: {
      parentId: searchParam.searchTerm,
      limit: searchParam.limit,
      offset: searchParam.offset,
    },
    pause: !searchParam.searchTerm,
  };

  const [queried] = useCategoryOptionsQuery(inputs);
  useEffect(() => {
    if (queried.data) {
      setCategories(queried.data.category);
      setSearchParam({ ...searchParam, total: queried.data.categories.aggregate?.count || 0 });
    }
  }, [queried.data]);

  const handleShowMore = () => {
    setSearchParam({ ...searchParam, limit: searchParam.limit + incLimit });
  };
  return (
    <div key={category.id}>
      <CategoryItem
        category={category}
        loadSubs={(value) => setSearchParam({ ...searchParam, searchTerm: value })}
        setOpenStatus={(open: boolean) => setOpen(open)}
        fetching={queried.fetching}
        setProdCtg={setProdCtg}
      />
      <Subcategories
        level={level}
        categories={categories}
        totalCtgs={searchParam.total}
        subOpen={subOpen}
        handleShowMore={handleShowMore}
        fetching={!!searchParam.searchTerm && queried.fetching}
        setProdCtg={setProdCtg}
      />
    </div>
  );
};
