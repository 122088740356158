import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useArchiveOpUserMutation,
  useRemoveSellerAccountMutation,
  useUpdateUserSellerRoleMutation,
  useUserSellersIntQuery,
  useUserSellersSubscription
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { UserSellerAccountsLoader } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { UserProps } from "../miscellaneous/types";
import { AddUserToSeller } from "./AddUserToSeller";
import { Avatar } from "./Avatar";
import RoleSelector from "./RoleSelector";

export const SellerAccounts: FC<UserProps> = ({ userDetailInfo }) => {
  const userDetailUserId = userDetailInfo.user_id
  const userInput = {
    variables: { user_id: userDetailUserId },
  };

  const userInfo = useUserInfo()!;
  const navigate = useNavigate()

  const [queried] = useUserSellersIntQuery(userInput);
  const [subscribed] = useUserSellersSubscription(userInput);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [, updateMutation] = useUpdateUserSellerRoleMutation();
  const [, removeMutation] = useRemoveSellerAccountMutation();
  const [, archiveMutation] = useArchiveOpUserMutation();
  const [dataId, setDataId] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");

  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users`;
  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "SellerAccounts", errMsg: error.message || "" }} />;
  }

  if (!queried.data || !subscribed.data) {
    return <UserSellerAccountsLoader />;
  }

  const userData = subscribed.data.usership ?? queried.data?.usership;

  const userStores = userData.map((store) => store.seller_id);

  const tableColumns = ["Seller Account", "Role", "Current Logged In", "Action"];

  const tableData = userData.map((usr) => {
    const actions = [
      {
        actionType: "remove",
        id: usr.id,
        display: "Remove",
        icon: "bi bi-person-x",
        actionFunc: (id: string) => setDataId(id),
      },
    ];

    return {
      id: usr.id,
      Seller_Account: usr?.seller?.company_name,
      Role: (
        <RoleSelector
          id={usr.id}
          curRoleId={usr?.role_id}
          confirmNeeded={true}
          onChange={(roleId: string, id?: string) => changeRole(roleId, id)}
        />
      ),
      Current_Logged_In: usr?.current ? (
        <div className="ps-4">
          <i className="bi bi-check"></i>
        </div>
      ) : null,
      Action: <DropdownItems items={actions} />,
    };
  });

  // const isLastAdmin = (itemId: string) => {
  //   const curItem = userData.find((usr) => usr.id === itemId);
  //   const admins = curItem?.seller?.admins;
  //   if (
  //     admins &&
  //     admins.aggregate?.count === 1 &&
  //     admins.nodes.find((node) => node.role_id === curItem?.role_id)
  //   ) {
  //     setErrMsg("Unable to remove! Sellers require at least one administrator.");
  //     return true;
  //   }
  //   return false;
  // };

  const changeRole = async (roleId: string, id?: string) => {
    // if (isLastAdmin(id!)) return;

    const result = await updateMutation(
      { id: id, role_id: roleId },
      {
        additionalTypenames: [
          "vw_active_user",
          "usership",
          "usership_aggregate",
        ],
      }
    )
    alertsRef.current?.generate(mutationInfo("seller role", MutationAction.Change, result));
  };

  const continueRemove = async (isContinue: boolean) => {
    if (!isContinue) {
      setDataId("");
      setErrMsg("");
      return;
    }
    // if (isLastAdmin(dataId)) return;

    const isLastSellerAccount = userData.filter(userItem => userItem.id != dataId).length == 0
    if (isLastSellerAccount) {
      const obj = {
        archived: {
          at: new Date().toISOString(),
          by: userInfo.user_id,
        },
      };
      const res = await archiveMutation({
        userIds: [userDetailInfo.user_id],
        operatorId: userInfo.operator_id,
        infoObj: obj,
      })
      if (res.data && !res.error) {
        setDataId("");
        setErrMsg("");
      }
      alertsRef.current?.generate(mutationInfo("user", MutationAction.Archive, res));
      navigate(baseRoute)
      return
    }
    const res = await removeMutation(
      { id: dataId },
      {
        additionalTypenames: [
          "vw_active_user",
          "usership",
          "usership_aggregate",
        ],
      }
    );
    if (res.data && !res.error) {
      setDataId("");
      setErrMsg("");
    }
    alertsRef.current?.generate(mutationInfo("user role", MutationAction.Remove, res));
  };

  return (
    <>
      <Card className="no-top-border p-4">
        <Avatar userDetailInfo={userDetailInfo} />
        <Table columnNames={tableColumns} data={tableData} css="border-top mt-2" />
        {userInfo?.user_level! >= 20 ?
          <div className="actions-wrapper">
            <Button data-testid="" className="btn btn-clean" onClick={() => setShowDialog(true)}>
              <i className="bi bi-plus-square-fill me-2"></i>Add Another Seller
            </Button>
          </div> : <></>
        }
      </Card>
      {showDialog && (
        <AddUserToSeller
          userId={userDetailInfo.user_id}
          onClose={() => setShowDialog(false)}
          userStores={userStores}
        />
      )}
      {!!dataId && (
        <Dialog
          title="Remove from seller?"
          continueText="Remove"
          continue={continueRemove}
          errorMsg={errMsg}
          contineBtnCss="btn-danger"
        >
          <div className="pt-2">Are you sure you want to remove the user from this seller?</div>
        </Dialog>
      )}
    </>
  );
};
