import { FC, forwardRef, Ref } from "react";

type Props = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "ref"
> & {
  /**
   * force inclusion for testing
   */
  "data-testid": string;
  /**
   * force inclusion for browser constraint api, pass null to bypass
   */
  ref: Ref<HTMLTextAreaElement>;
};

export const TextArea: FC<Props> = forwardRef<HTMLTextAreaElement>(
  (props, ref) => {
    // eslint-disable-next-line react/forbid-elements
    return <textarea ref={ref} {...props} />;
  }
);
