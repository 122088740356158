import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useAddStoreProductGroupMutation } from "../../../generated/urql-graphql";
import { appRef } from "../../common/components/appStatus";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";

interface Props {
  closeDialog: () => void;
  productGroups: SelectOption[];
  stores: SelectOption[];
}
export const AddStoreProductGroup: FC<Props> = ({ closeDialog, productGroups, stores }) => {
  const [, addMutation] = useAddStoreProductGroupMutation();

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (formData: FieldValues) => {
    let res;
    const storePgrp = { ...formData };
    res = await addMutation({ storePgrp });
    if (!res.error) {
      closeDialog();
      appRef.current?.updateStatus(false);
    }
    alertsRef.current?.generate(mutationInfo("vendor product group", MutationAction.Create, res));
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="vendor-product-group-form"
        id="vendor-product-group-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <div className="px-2">
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-6">
              <FormSelect name="seller_id" label="Seller Name" options={stores} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <FormSelect
                name="product_group_id"
                label="Product Group Number"
                options={productGroups}
              />
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
