import { FC, useRef, useState } from "react";
import { Card } from "../../common/components/Card";
import { Input } from "../../forms/components/Input";
import { Option } from "../../forms/components/Option";
import { Select } from "../../forms/components/Select";

export const AdvancedSettings: FC = () => {
  const lazyLoadImages = useRef<HTMLSelectElement>(null);
  const showProductsPrice = useRef<HTMLInputElement>(null);
  const closeConfig = useRef<HTMLInputElement>(null);
  const allowAddProduct = useRef<HTMLInputElement>(null);
  const skuSetup = useRef<HTMLSelectElement>(null);
  const charUsed = useRef<HTMLInputElement>(null);
  const showResetButton = useRef<HTMLInputElement>(null);
  const VariableSellingPrice = useRef<HTMLInputElement>(null);
  const allowNoninventory = useRef<HTMLInputElement>(null);
  const allowPartnerPrice = useRef<HTMLInputElement>(null);
  const [dividerDisabled, setDividerDisabled] = useState<boolean>(false);
  const information = [
    {
      id: 1,
      text: "Lazy load images",
      component: (
        <Select
          data-testid=""
          ref={lazyLoadImages}
          className="form-select w-50"
        //  defaultValue={number_items_per_page}
        >
          <Option data-testid="" value="yes">
            Yes (Default) - use as empty placeholder until the user selects a choice
          </Option>
          <Option data-testid="" value="no">
            No - load everything from the beginning
          </Option>
        </Select>
      ),
    },
    {
      id: 2,
      text: "Show the product's price in the configurator",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={showProductsPrice}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={true}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 3,
      text: 'Close the configurator when "Add to Cart" button is clicked',
      component: (
        <span className="form-check form-switch">
          <Input
            ref={closeConfig}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={false}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 4,
      text: "Allow adding the product to the cart without configuring",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={allowAddProduct}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={false}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 5,
      text: "SKU setup",
      component: (
        <Select
          data-testid=""
          ref={skuSetup}
          className="form-select w-50"
          onChange={(event) => {
            if (event.currentTarget.value === "single") setDividerDisabled(true);
            else setDividerDisabled(false);
          }}
        //  defaultValue={number_items_per_page}
        >
          <Option data-testid="" value="own">
            Each choice can have its own SKU
          </Option>
          <Option data-testid="" value="single">
            Use a single static SKU
          </Option>
        </Select>
      ),
    },
    {
      id: 6,
      text: "Character used in-between parameter value codes added to the product part number",
      component: (
        <Input
          data-testid=""
          type="text"
          className="form-control w-50"
          ref={charUsed}
          defaultValue="-"
          disabled={dividerDisabled}
        />
      ),
    },
    {
      id: 7,
      text: "Show a reset button in the configurator",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={showResetButton}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={true}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 8,
      text: "Variable selling prices",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={VariableSellingPrice}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={true}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 9,
      text: "Allow non-inventory items",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={allowNoninventory}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={false}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
    {
      id: 10,
      text: "Allow partner tiered pricing",
      component: (
        <span className="form-check form-switch">
          <Input
            ref={allowPartnerPrice}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            defaultChecked={false}
          // onChange={() => setDisabled(false)}
          />
        </span>
      ),
    },
  ];
  return (
    <>
      <Card>
        <table className="table presets-table">
          <tbody>
            {information.map((row) => (
              <tr key={row.id} className="py-2">
                <td>{row.text}</td>
                <td className="d-flex justify-content-end">{row.component}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </>
  );
};
