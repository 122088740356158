import { FC, forwardRef, Ref } from "react";

type Props = Omit<
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >,
  "ref"
> & { "data-testid": string; ref?: Ref<HTMLFormElement> };

export const Form: FC<Props> = forwardRef<HTMLFormElement>((props, ref) => {
  // eslint-disable-next-line react/forbid-elements
  return <form ref={ref} {...props} />;
});
