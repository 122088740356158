import { FC, ReactNode, useEffect, useRef } from "react";
import { Button } from "../../forms/components/Button";

type DialogProps = {
  children: ReactNode;
  title: string;
  continueText?: string;
  closeText?: string;
  subFormId?: string;
  disableAction?: boolean;
  contineBtnCss?: string | null;
  staticModal?: boolean;
  errorMsg?: string;
  size?: "sm" | "lg" | "xl";
  show?: boolean;
  footerText?: string | JSX.Element;
  continue: (process: boolean) => void;
  fetching?: boolean;
  infoOnly?: boolean;
};

export const Dialog: FC<DialogProps> = (props): ReturnType<FC> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.staticModal) {
      const validateClickOutsideTheModal = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          props.continue(false);
        }
      };
      document.addEventListener("mousedown", validateClickOutsideTheModal);
      return () => {
        document.removeEventListener("mousedown", validateClickOutsideTheModal);
      };
    }
  }, []);

  return (
    <>
      {(props.show === undefined || props.show) && (
        <div className="bs-modal modal dialog show">
          <div
            className={`modal-dialog ${
              props.size ? `modal-${props.size}` : ""
            } modal-dialog-centered`}
            ref={ref}
          >
            <div className="modal-content">
              <div className="modal-header bg-blue">
                <h5 className="modal-title font-weight-semi-bold text-white text-capitalize">{props.title}</h5>
                <Button
                  data-testid=""
                  type="button"
                  className="btn-close btn-close-white"
                  aria-label="Close"
                  onClick={() => props.continue(false)}
                ></Button>
              </div>
              <div className="model-body">
                <div className="p-3">{props.children}</div>
                {props.errorMsg && (
                  <p className="px-3 small text-danger display-7">
                    <i className="bi bi-exclamation-circle-fill mt-1 me-1"></i>
                    {props.errorMsg}
                  </p>
                )}
              </div>
              <div
                className={`modal-footer d-flex ${
                  props.footerText
                    ? "justify-content-between align-items-center"
                    : "justify-content-end"
                }`}
              >
                {props.footerText}
                <div>
                  <Button
                    data-testid="dialog-discard"
                    onClick={() => props.continue(false)}
                    className="btn btn-outline-primary me-2"
                  >
                    {props.closeText ?? "Discard"}
                  </Button>
                  {!props.infoOnly && (props.fetching ? (
                    <Button data-testid="" className="btn btn-primary" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </Button>
                  ) : (
                    <Button
                      data-testid="dialog-confirm"
                      className={"btn " + (props.contineBtnCss || "btn-primary")}
                      form={props.subFormId}
                      type={props.subFormId ? "submit" : "button"}
                      disabled={props.disableAction}
                      onClick={() => props.continue(true)}
                    >
                      {props.continueText || "Continue"}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
