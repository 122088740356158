import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useCreatePimUrlDomainMutation, useDeletePimUrlDomailMutation, useEditPimUrlDomainMutation, usePimUrlDomainsQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IActionState, MutationAction, OrderByType } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

const PimDomainsView = () => {
    const [context] = useMainContext();
    const formatDate = useDateFormat()
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseUrl = `/${tenantUrlTag}/settings`;
    const { number_items_per_page } = context.operatorSettings.preset;
    const [loading, setLoading] = useState<number>(-1);
    const [searchValue, setSearchValue] = useState<string>("");
    const [offset, setOffset] = useState<number>(0);
    const columnNames = ["Created Date", "Domain Name", "Company Name", "Actions"];
    const tableColumnsToSearch = ["name", "company_name"];
    const [orderBy, setOrderBy] = useState<OrderByType>({
        display: "Domain Name",
        column_name: "name",
        orderBy: "asc",
    });
    const orderByOptions: { [key: string]: string } = {
        Domain_Name: "name",
        Created_Date: "created_date",
        Company_Name: "company_name"
    };
    const defState = {
        ids: null,
        action: "",
        item: null,
    };
    const [actionState, setActionState] = useState<IActionState>(defState);
    const orderByString = orderBy.column_name
        ? [{ [orderBy.column_name]: orderBy.orderBy }]
        : undefined

    const defInput = {
        variables: {
            limit: number_items_per_page,
            offset: offset,
            order_by: orderByString,
            whereValue: JSON.parse(
                handleWhere({
                    columnSearch: handleColumnFilter(
                        tableColumnsToSearch,
                        searchValue
                    )
                })
            )
        }
    }

    const [queried] = usePimUrlDomainsQuery(defInput)
    const [{ fetching: creating }, createMutation] = useCreatePimUrlDomainMutation()
    const [{ fetching: updating }, updateMutation] = useEditPimUrlDomainMutation()
    const [{ fetching: fetchingDelete }, deleteMutation] = useDeletePimUrlDomailMutation()

    const methods = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const {
        formState: { errors },
        handleSubmit,
        reset,
        setValue
    } = methods;

    const onSubmit = async (formData: FieldValues) => {
        let res;
        if (formData.id) {
            res = await updateMutation({ id: formData.id, name: formData.name, company_name: formData.company_name })
        } else {
            res = await createMutation({ pimUrlDomain: formData })
        }
        if (!res.error) {
            setActionState(defState);
        }
        alertsRef.current?.generate(mutationInfo("PIM resource domain", MutationAction.Update, res));
    }

    const continueDelete = async (isContinue: boolean) => {
        if (!isContinue) {
            setActionState(defState);
        }
        else {
            const res = await deleteMutation({ id: actionState.item.id });
            setActionState(defState);
            alertsRef.current?.generate(mutationInfo("PIM resource domain", MutationAction.Delete, res));
        }
    }

    const continueAction = (isContinue: boolean) => {
        if (!isContinue) {
            setActionState(defState);
        }
    };

    useEffect(() => {
        if (queried.data) {
            setLoading(ELoadingType.None);
        }
    }, [queried.data]);

    useEffect(() => {
        if (actionState.item) {
            setValue("id", actionState.item.id)
            setValue("name", actionState.item.name)
            setValue("company_name", actionState.item.company_name)
        }
        else
            reset()
    }, [actionState.item]);

    const error = queried.error;
    if (error) {
        return <PageError error={{ source: "PimDomainsView", errMsg: error.message }} />;
    }

    if (!queried.data) {
        return (
            <Card>
                <PlaceholderTableSearchItem />
                <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
            </Card>
        );
    }
    const tableData = queried.data?.pim_url_domain.map((item) => {
        const actions = [
            {
                actionType: "edit",
                id: item.id,
                icon: "bi bi-pencil",
                actionFunc: () => setActionState({ ...actionState, action: "Edit", item })
            },
            {
                actionType: "delete",
                id: item.id,
                icon: "bi bi-trash",
                actionFunc: () => setActionState({ ...actionState, action: "Delete", item }),
            },
        ];
        return {
            id: item.id,
            Created_Date: (
                <div className="date-created">
                    <p className="mb-0">{formatDate(item.created_date)}</p>
                    <span className="small text-muted">{formatDate(item.created_date, "time")}</span>
                </div>
            ),
            Domain_Name: item.name,
            Company_Name: item.company_name,
            Actions: <DropdownItems items={actions} />
        };
    });
    const totalRecords = queried.data.pim_url_domain_aggregate.aggregate?.count;
    return (
        <div className="col-12">
            <div>
                <ReturnHeader
                    title="Manage PIM Resource Domains"
                    url={baseUrl}
                    description="Manage domains allowed in products data links">
                    <>
                        <div className="navbar-expand-xl d-flex flex-column align-items-end">
                            <Button
                                data-testid=""
                                className="btn btn-primary"
                                onClick={() => setActionState({ ...defState, action: "Add" })}>
                                Add PIM Resource Domain
                            </Button>
                        </div>
                    </>
                </ReturnHeader>
            </div>
            <Card>
                <Table
                    setSearchValue={setSearchValue}
                    columnNames={columnNames}
                    data={tableData}
                    filters
                    offset={offset}
                    setOffset={setOffset}
                    totalRecords={totalRecords}
                    setOrderBy={setOrderBy}
                    orderByOptions={orderByOptions}
                    orderBy={orderBy}
                    loading={loading}
                    setLoading={setLoading}
                    searchPlaceHolder="Search by Domain..."
                    searchHint={`Search by Domain Name, Company Name`}
                />
            </Card>
            <Dialog
                show={actionState.action === "Delete"}
                title="Delete PIM Resource Domains"
                continueText="Delete"
                continue={continueDelete}
                contineBtnCss={"btn-danger"}
                fetching={fetchingDelete}
            >
                Delete selected PIM resource domain?
            </Dialog>
            <Dialog
                title={`${actionState.action} PIM Resource Domain`}
                show={["Add", "Edit"].includes(actionState.action)}
                subFormId="manage-pim-domain-form"
                continueText="Save"
                staticModal={true}
                continue={continueAction}
                fetching={creating || updating}
            >
                <FormProvider {...methods}>
                    <Form
                        data-testid="manage-pim-domain-form"
                        id="manage-pim-domain-form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
                    >
                        <div className="form-group">
                            <FormInput
                                name="name"
                                label="Domain Name"
                                type="url"
                                placeholder="http://website.com"
                                reg_options={{
                                    required: true,
                                    maxLength: 80,
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?.*$/,
                                        message: "Invalid domain name",
                                    }
                                }}
                            />
                            <FormInput
                                name="company_name"
                                label="Company Name"
                            />
                        </div>
                    </Form>
                </FormProvider>
            </Dialog>
        </div >
    )
}

export default PimDomainsView