/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dialog } from "../../common/components/Dialog";
import { useRoleList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";
import { Label } from "../../forms/components/Label";
import { Option } from "../../forms/components/Option";
import { Select } from "../../forms/components/Select";
import { useMainContext } from "../../layout/components/MainProvider";

interface RoleSelectorProps {
  curRoleId: string;
  groupBase?: number | null;
  id?: string;
  confirmNeeded?: boolean;
  onChange: (roleId: string, id?: string) => void;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  removeMpAdmin?: boolean;
}

const RoleSelector = (props: RoleSelectorProps) => {
  const { id, curRoleId, groupBase, confirmNeeded, onChange, required, label, disabled, removeMpAdmin } = props;

  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const [userRoleId, setUserRoleId] = useState<string>(curRoleId);
  const [changedRoleId, setChangedRoleId] = useState<string>("");

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    update(event.target.value);

  const roleList = useRoleList();

  useEffect(() => {
    if (groupBase && !isNullOrEmpty(userInfo.role_id)) {
      const role = roleList.find(
        (singleRole) => singleRole.user_level! <= Math.min((groupBase * 1 + 9), userInfo.user_level!)
      );
      roleIdChanged(role?.id);
    }
  }, [groupBase, userInfo.role_id]);

  useEffect(() => {
    if (curRoleId && confirmNeeded) setUserRoleId(curRoleId);
  }, [curRoleId]);

  const update = (roleId: string) => {
    if (confirmNeeded) {
      setChangedRoleId(roleId);
    } else {
      roleIdChanged(roleId);
    }
  };

  const continueAction = async (newContinueAction: boolean) => {
    if (newContinueAction) {
      roleIdChanged(changedRoleId);
    }
    setChangedRoleId("");
  };

  const roleIdChanged = (roleId: string) => {
    onChange(roleId, id);
    if (!confirmNeeded) setUserRoleId(roleId);
  };

  if (!roleList) return <p>role list</p>;

  const assignorRole = roleList
    .filter((role) => role.id === userInfo.role_id)
    .find((element) => element);

  const curRole = roleList.filter((role) => role.id === userRoleId).find((element) => element);

  const assignRole =
    userInfo.permissions! & context.permissions.invite_user &&
    userInfo.user_level! >= curRole?.user_level! &&
    assignorRole?.role_permission_jncs_aggregate.aggregate?.count !== 0;

  const minUserLevel = groupBase ? groupBase * 1 : Math.floor(curRole?.user_level! / 10) * 10;;
  const maxUserLevel = Math.min(userInfo.user_level!, minUserLevel + 9);
  const roles = roleList.filter(
    (role) =>
      (role.user_level !== 30 && assignRole && minUserLevel <= role.user_level! && maxUserLevel >= role.user_level! && (!removeMpAdmin || role.user_level !== 25))
  );

  return (
    <>
      {label && (
        <div className="mb-2">
          <Label
            data-testid="label_role_selector"
            className={`font-weight-medium ${required && roles.length > 1 ? "required" : ""}`}
          >
            {label}
          </Label>
        </div>
      )}
      {roles.length === 1 ? (
        roles.find((role) => role)!.name
      ) : (
        <Select
          required
          ref={null}
          value={userRoleId}
          data-testid="UserDetail-userRoleLevel"
          className="form-select"
          aria-label="TestUsers"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleRoleChange(event)}
          disabled={disabled}
        >
          {roles.map((role) => {
            return (
              <Option value={role.id} key={role.id} data-testid={"role_" + role.id}>
                {role.name}
              </Option>
            );
          })}
        </Select>
      )}

      <Dialog
        show={!!changedRoleId}
        title="Change Role"
        continueText="Save"
        continue={continueAction}
      >
        <div className="pt-2">Are you sure to change role?</div>
      </Dialog>
    </>
  );
};

export default RoleSelector;
