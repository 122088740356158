import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useOfferImportsCountSubscription,
  useOfferImportsSubSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import {
  ELoadingType, IDateRange, OrderByType
} from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";

const formatStatus = (st: string) => {
  if (st === "CompleteWithErrors")
    return "Complete with errors";
  else
    return st;
}

const statusColor = (st: string) => {
  let retValue = "text-primary";

  switch (st) {
    case "Complete":
      retValue = "text-success";
      break;
    case "Error":
    case "CompleteWithErrors":
      retValue = "text-danger";
      break;
  }
  return retValue;
}

const statusIcon = (st: string) => {
  let retValue = "bi-hourglass";

  switch (st) {
    case "Complete":
      retValue = "bi-check-circle";
      break;
    case "Error":
    case "CompleteWithErrors":
      retValue = "bi-exclamation-triangle";
      break;
  }
  return retValue;
}
export const ImportHistory: FC = () => {
  const userInfo = useUserInfo();
  const sellerId = userInfo.seller_id;
  const formatDate = useDateFormat();
  const [loading, setLoading] = useState<number>(-1);
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const sellerRoute = `/${tenantUrlTag}/sellers`;
  const { number_items_per_page } = context.operatorSettings.preset;
  const [searchValue, setSearchValue] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const listBySeller = sellerId ? `"seller_id":{"_eq": ${JSON.stringify(sellerId)}}` : "";
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });

  const tableColumnToSearch = "user_email_lower";
  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, `%${searchValue.toLowerCase()}%`),
    selectedFilters,
    sellerFilter: listBySeller,
    dateRange
  });

  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "created date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    created_date: "created_date",
    seller: "company_name_lower",
    status: "status_lower"
  };
  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }]
    : [{ [orderByOptions.Created_Date]: "desc" }];
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };
  const countInputs = {
    variables: {
      where: JSON.parse(handleWhereStr),
    },
  };
  const [sellersImportsSubscribed] = useOfferImportsSubSubscription(inputs);
  const [countSubscribed] = useOfferImportsCountSubscription(countInputs);
  const addFilterOptions = [
    { label: "Status", value: "status_lower", type: "string" }
  ];
  if (!sellerId)
    addFilterOptions.push({ label: "Seller", value: "company_name_lower", type: "string" })

  const tableColumns = sellerId ? ["created date", "imported by", "file name", "status", "error report"] :
    ["created date", "imported by", "file name", "seller", "status", "error report"];

  useEffect(() => {
    if (sellersImportsSubscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [sellersImportsSubscribed.data]);

  const error = sellersImportsSubscribed.error || countSubscribed.error;
  if (error) {
    return <PageError error={{ source: "ImportHistory", errMsg: error.message }} />;
  }

  if (!sellersImportsSubscribed.data || !countSubscribed.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }
  const imports = sellersImportsSubscribed.data?.imports_vw_seller_offer_imports;

  const tableData = imports
    ? imports?.map((imp, i) => {
      return {
        id: imp.id,

        file_name: (
          <div>
            <p className="mb-0">
              <a href={imp.filepath_storage!}>{imp.filename_original}</a>
            </p>
          </div>
        ),
        imported_by: (imp.user_email),
        seller: (
          <NavLink
            data-testid=""
            to={`${sellerRoute}/${imp.seller_id}`}
            className="text-decoration-underline"
          >
            {imp.company_name}
          </NavLink>
        ),
        created_date: (
          <div>
            <p className="mb-0">{formatDate(imp.created_date)}</p>
            <span className="small text-muted">{formatDate(imp.created_date, "time")}</span>
          </div>
        ),
        status: (
          <div style={{ maxWidth: "180px" }} className="text-truncate">
            <div className={`text-bold my-1 ${statusColor(imp.status!)}`}>
              <i className={`small me-1 bi ${statusIcon(imp.status!)}`}></i>
              {formatStatus(imp.status ?? "")}
            </div>
            {imp.status != "Pending" && (
              <div>
                <span className="small text-muted">Lines total: {imp.lines_total}</span><br />
                <span className="small text-muted">Lines processed: {imp.lines_processed}</span><br />
                <span className="small text-muted">Lines imported: {imp.lines_successful}</span><br />
                <span className="small text-muted">Lines with errors: {imp.lines_with_errors}</span><br />
              </div>
            )}
          </div>
        ),
        error_report: (
          <div className="text-left my-2">
            <p className="mb-0">
              {imp.filepath_errors && (<a href={imp.filepath_errors}>Download</a>)}
            </p>
          </div>
        ),
      };
    })
    : [];




  return (
    <>
      <div className="col">
        <Card>
          <Table
            columnNames={tableColumns}
            data={tableData}
            offset={offset}
            setOffset={setOffset}
            totalRecords={countSubscribed.data?.imports_vw_seller_offer_imports?.aggregate?.count ?? 0}
            filters
            setSelectedFilters={setSelectedFilters}
            addFilterOptions={addFilterOptions}
            setOrderBy={setOrderBy}
            orderByOptions={orderByOptions}
            orderBy={orderBy}
            loading={loading}
            setLoading={setLoading}
            dateRange={dateRange}
            setDateRange={setdateRange}
            setSearchValue={setSearchValue}
            searchPlaceHolder="Search Imported By"
          />

        </Card>
      </div>
      <div></div>
    </>
  );
}


