import { FC } from "react";
import { useParams } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  Vw_Order_History_User,
  useOrderHistorySubscription,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { Button } from "../../forms/components/Button";
import { Label } from "../../forms/components/Label";
const { v1: uuidv1 } = require("uuid");

const StatusIcon = (statusCd: string, trackingOperation: string): ReturnType<FC> => {
  if (trackingOperation === 'INSERT order_refund') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cash-coin"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
        />
        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
      </svg>
    );
  }
  if (statusCd === "Accepted") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-check-circle"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
      </svg>
    );
  } else if (statusCd === "Pending") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-stopwatch"
        viewBox="0 0 16 16"
      >
        <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
        <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
      </svg>
    );
  } else if (statusCd === "Shipped") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-truck"
        viewBox="0 0 16 16"
      >
        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
      </svg>
    );
  } else if (statusCd === "Received") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-receipt"
        viewBox="0 0 16 16"
      >
        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
      </svg>
    );
  } else if (statusCd === "Cancelled") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-x-circle"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    );
  } else if (statusCd === "AwaitingFulfillment") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-hourglass-split"
        viewBox="0 0 16 16"
      >
        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
      </svg>
    );
  } else if (statusCd === "New") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cart-check"
        viewBox="0 0 16 16"
      >
        <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
      </svg>
    );
  } else if (statusCd === "Refunded") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cash-coin"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
        />
        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
      </svg>
    );
  } else if (statusCd === "Rejected") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
      </svg>
    );
  } else if (
    statusCd === "New Tracking" ||
    statusCd === "Update Tracking" ||
    statusCd === "Delete Tracking"
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-globe2"
        viewBox="0 0 16 16"
      >
        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
      </svg>
    );
  }
  return <></>;
};

export const OrderHistory: FC = (): ReturnType<FC> => {
  let prevItem: Vw_Order_History_User;
  const { orderId } = useParams<{ orderId: string }>();
  const formatDate = useDateFormat();
  const orderHistoryInput = {
    variables: {
      orderId: orderId,
    },
  };
  const [orderHistorySubscription] = useOrderHistorySubscription(orderHistoryInput);

  const error = orderHistorySubscription.error;
  if (error) {
    return <PageError error={{ source: "OrderHistory", errMsg: error.message }} />;
  }

  if (!orderHistorySubscription.data) {
    return <Loader />;
  }
  const TrackOperationMessage = (item: Vw_Order_History_User): string => {
    if (item.tracking_operation?.includes("INSERT")) {
      return `created`;
    } else if (item.tracking_operation?.includes("UPDATE")) {
      return `updated`;
    } else if (item.tracking_operation?.includes("DELETE")) {
      return `deleted`;
    } else {
      return "";
    }
  };

  const TrackingInfoStatusMessage = (item: string): string => {
    switch (item) {
      case "Accepted":
        return 'accepted';
      case "Pending":
        return 'pending';
      case "Cancelled":
        return 'cancelled';
      case "Rejected":
        return 'rejected';
      case "Refunded":
        return 'refunded';
      default:
        return item;
    }
  };

  const TrackInfoChange = (item: Vw_Order_History_User): JSX.Element => {
    const newObj = item.tracking_info; // JSON.parse(item.tracking_info);
    const oldObj = item.old_tracking_info; // JSON.parse(item.old_tracking_info ?? '{}');
    let listChanges: string[] = [];
    Object.keys(newObj).forEach((key: string) => {
      if (newObj && oldObj && key === 'quantity' && (newObj[key] !== oldObj[key])) {
        listChanges.push(`${key} changed from ${oldObj[key]} to ${newObj[key]}`);
      }
    });

    if (listChanges.length === 0) {
      return <></>
    }
    else {
      return (
        <div>
          {listChanges.map(key => <small className="text-muted me-2">{key}</small>)}
        </div>);
    };
  }

  const OrderTrackingInfo = (item: Vw_Order_History_User): ReturnType<FC> => {
    return (
      <>
        {item.tracking_operation?.includes("tracking_item") && (
          <div>
            <b>Tracking info {TrackOperationMessage(item)}</b>
            <div>{`${item.tracking_info.carrier_key} Tracking Number #${item.tracking_info.tracking_number}`}</div>
          </div>
        )}
        {item.tracking_operation?.includes("order_refund") && (
          <div>
            <b>Refund {TrackOperationMessage(item)}: </b>
            <span>{`Reference Number #${item.tracking_info.transaction_number} by ${item.status_by}`}</span>
          </div>
        )}
        {item.tracking_operation?.includes("order_item") && (
          <div className="flex-1">
            <b>{`Line item #${item.tracking_info.index}:`}</b>
            <p className="mt-0 mb-0 lh-sm">
              <small>{item.tracking_info.product_info.title}</small>
            </p>
            <p className="mt-0 mb-0 lh-sm">
              <small className="text-muted me-2">Mfr Part Number:</small>
              <small>{item.mfr_part_number}</small>
            </p>
            <p className="mt-0 mb-0 lh-sm">
              <small className="text-muted me-2">Seller Item Sku:</small>
              <small>{item.offer_sku}</small>
            </p>
            <p className="mt-0 mb-0 lh-sm">
              {item.tracking_operation === 'INSERT order_item'
                ?
                ''
                :
                (
                  <>
                    {TrackInfoChange(item)}

                    <div className="mt-0 mb-0 lh-sm">
                      <small className="text-muted me-2">
                        {`${TrackingInfoStatusMessage(item.tracking_info.trg_status)} by ${item.status_by}`}
                      </small>
                    </div>
                  </>
                )
              }
            </p>
          </div>
        )}
      </>
    );
  };

  const OrderStatusMessage = (
    status: string,
    item: Vw_Order_History_User,
    index: number
  ): ReturnType<FC> => {
    let prevOHItem: Vw_Order_History_User;
    let prevOrderStatus: string = "";

    if (index > 0) {
      prevOHItem = data[index - 1];
      prevOrderStatus = prevOHItem.status_cd || "";
    } else {
    }
    //console.log(`${index} prevOrderStatus=${prevOrderStatus} status_cd=${item.status_cd}`)
    if (prevOrderStatus === item.status_cd) {
      if (
        item.tracking_operation?.includes("order_item") ||
        item.tracking_operation?.includes("tracking_item") ||
        item.tracking_operation?.includes("order_refund")
      ) {
        return <div>{OrderTrackingInfo(item)}</div>;
      }
    }
    return (
      <div>
        {status === 'created' ?
          <><b>{`Order ${status}`}</b>
            {OrderTrackingInfo(item)}
          </>
          :
          (
            <>{(item.tracking_operation === 'INSERT order_item' ||
              item.tracking_operation === 'INSERT tracking_item' ||
              item.tracking_operation === 'INSERT order_refund') ?
              (
                OrderTrackingInfo(item)
              )
              :
              <>
                {status !== 'Pending' ? <b>{`Order status changed to ${status}`}</b> : null}
              </>
            }

            </>)
        }
      </div>
    );
  };

  const StatusMessage = (item: Vw_Order_History_User, index: number): ReturnType<FC> => {
    if (item.status_cd === "Accepted") {
      return OrderStatusMessage("Accepted", item, index);
    } else if (item.status_cd === "Pending") {
      return OrderStatusMessage("Pending", item, index);
    } else if (item.status_cd === "Shipped") {
      return OrderStatusMessage("Shipped", item, index);
    } else if (item.status_cd === "Received") {
      return OrderStatusMessage("Received", item, index);
    } else if (item.status_cd === "Cancelled") {
      return OrderStatusMessage("Cancelled", item, index);
    } else if (item.status_cd === "AwaitingFulfillment") {
      return OrderStatusMessage("Awaiting Fulfillment", item, index);
    } else if (item.status_cd === "New") {
      //return <p>Order create</p>; 
      return OrderStatusMessage('created', item, index);
    } else if (item.status_cd === "Rejected") {
      return OrderStatusMessage("Rejected", item, index);
    } else if (item.status_cd === "Refunded") {
      return OrderStatusMessage("Refunded", item, index);
    }

    return <div></div>;
  };
  const data = orderHistorySubscription.data.vw_order_history_user;

  let dataVerticalElement: JSX.Element[] = [];
  data.map((item: Vw_Order_History_User, index: number) => {
    if (index > 0) {
      prevItem = data[index - 1];
    }

    if (item.tracking_operation === 'UPDATE order' && item.status_cd === 'Pending') {
      //
    }
    else if (prevItem && item.tracking_operation === 'UPDATE order'
      && (prevItem.status_cd === item.status_cd)) {
      //
    } else {

      if (prevItem && item.tracking_operation === 'INSERT order_refund'
        && (prevItem.status_cd !== item.status_cd)) {
        dataVerticalElement.push(
          <VerticalTimelineElement
            key={uuidv1()}
            className="vertical-timeline-element--work"
            date={formatDate(item.created_date, "datetime_long")}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={StatusIcon(item.status_cd || "", item.tracking_operation ?? "")}
          >
            <b>{`Order status changed to ${item.status_cd}`}</b>
          </VerticalTimelineElement>
        );
      }
      dataVerticalElement.push(
        <VerticalTimelineElement
          key={uuidv1()}
          className="vertical-timeline-element--work"
          date={formatDate(item.created_date, "datetime_long")}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={StatusIcon(item.status_cd || "", item.tracking_operation ?? "")}
        >
          {StatusMessage(item, index)}
        </VerticalTimelineElement>
      );
    }
  });

  return (
    <div
      className="offcanvas offcanvas-end"
      style={{ minWidth: "500px" }}
      tabIndex={-1}
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="modal-header bg-blue">
        <h5 className="modal-title text-white text-capitalize" id="staticBackdropLabel">
          History
        </h5>
        <Button
          data-testid=""
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          data-bs-dismiss="offcanvas"
        ></Button>
      </div>
      <div className="offcanvas-header">
        <Label data-testid="">
          <i className="bi bi-clock-history me-2" />
          Order History
        </Label>
      </div>
      <div className="offcanvas-body">
        {data.length === 0 ? (
          <p>
            <b>No History Data</b>
          </p>
        ) : (
          <VerticalTimeline animate={true} layout="1-column" lineColor="blue">
            {dataVerticalElement.reverse().map(item => {
              return item
            })}
          </VerticalTimeline>
        )}
      </div>
    </div>
  );
};
