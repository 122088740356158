
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { Option } from "../../forms/components/Option";
import { Select } from "../../forms/components/Select";
import { ELoadingType, OrderByType } from "../types/types";

interface IProps {
  columnNames: string[];
  tableData: { id: string }[];
  options: string[];
  applyChanges: (() => void) | undefined;
  noShadow?: boolean;
  multiSelect?: boolean;
  setOrderBy?: React.Dispatch<React.SetStateAction<OrderByType>>;
  orderBy?: OrderByType;
  orderByOptions?: {
    [key: string]: string;
  };
  loading?: number;
  setLoading?: (val: number) => void;
}

interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

export const MultiSelectTable = forwardRef((props: IProps, ref: ForwardedRef<IRef>) => {
  const {
    columnNames,
    tableData,
    options,
    applyChanges,
    noShadow,
    multiSelect,
    setOrderBy,
    orderBy,
    orderByOptions,
    loading,
    setLoading
  } = props;
  const [filter, setFilter] = useState<boolean>(false);
  const [numberOfRowsChecked, setNumberOfRowsChecked] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>(options[0]);

  const allIdsOfTheCurrentPage = tableData.map((row) => row.id);

  const handleColumnName = (column: string): string => column.replace(/[_-]/g, " ");
  const handleColumnNameForSorting = (column: string): string => column.replace(/\s/g, "_");

  const rowChecked = (id: string): boolean => selectedRows.some((value) => value === id);

  const allIdsOfTheCurrentPageAreSelected: boolean = allIdsOfTheCurrentPage.every((id) =>
    selectedRows.includes(id)
  );

  const clearData = () => {
    setSelectedRows([]);
    setNumberOfRowsChecked(0);
    setFilter(false);
  };

  const handleAllRows = (): void => {
    // of all currently selected rows will be removed from the current page
    if (allIdsOfTheCurrentPageAreSelected) {
      const newselectedRows: string[] = selectedRows.filter(
        (value) => !allIdsOfTheCurrentPage.includes(value)
      );
      setSelectedRows(newselectedRows);
      setFilter(true);
      setNumberOfRowsChecked(newselectedRows.length);
      // if there are no longer selected rows, the filter is removed
      if (!newselectedRows.length) {
        setFilter(false);
      }
    } else {
      // the previously selected rows are joined together with the new ones
      const allRows: string[] = [...selectedRows, ...allIdsOfTheCurrentPage];
      const newselectedRows: string[] = allRows.filter(
        (element, index) => allRows.indexOf(element) === index
      );

      setSelectedRows(newselectedRows);
      setFilter(true);
      setNumberOfRowsChecked(newselectedRows.length);
    }
  };

  const handleSingleRow = (id: string): void => {
    if (rowChecked(id)) {
      // if the row is checked it will be removed from the list
      const newselectedRows: string[] = selectedRows.filter((value) => value !== id);
      setSelectedRows(newselectedRows);
      setNumberOfRowsChecked(newselectedRows.length);
      // if there are no longer selected rows, the filter is removed
      if (!newselectedRows.length) {
        setFilter(false);
        setNumberOfRowsChecked(0);
      }
    } else {
      // if the row is not checked it will be added from the list.
      const newselectedRows: string[] = [...selectedRows, id];
      setSelectedRows(newselectedRows);
      setFilter(true);
      setNumberOfRowsChecked(newselectedRows.length);
    }
  };

  useImperativeHandle(ref, () => ({
    clearSelectedData: () => clearData(),
    selectedData: selectedRows,
    selectedOption: selectedOption,
  }));

  const allIsChecked: boolean = tableData.every((value) => selectedRows.includes(value.id));
  const showSortableIcons = (name: string) => {
    if (orderByOptions)
      return (
        Object.keys(orderByOptions)
          .map((value) => handleColumnName(value))
          .indexOf(name) > -1
      );
    else return false;
  };

  const displayColumnName = (column: string) => {
    return handleColumnName(column);
  };

  const getOrderByString = (column: string) =>
    orderByRef.current.display === column
      ? orderByRef.current.orderBy === "asc"
        ? "desc"
        : "asc"
      : "asc";

  const getSortableClassName = (column: string, orderByParameter: OrderByType) =>
    column === orderByParameter.display
      ? orderByParameter.orderBy === "desc"
        ? "bi-sort-down-alt"
        : "bi-sort-up"
      : "bi-filter-left";

  //orderByRef for tracking the previous sorting item
  const orderByRef = useRef<OrderByType>(
    orderBy ?? {
      display: "",
      column_name: "",
      orderBy: "",
    }
  );

  useEffect(() => {
    if (orderBy) orderByRef.current = orderBy;
  }, [orderBy]);

  return (
    // <div className={`${!noShadow && "bg-white p-4 shadow-sm"}`}>
    <div className="table-wrapper">
      {filter && (
        <div className="d-flex my-2">
          <div className="d-flex align-items-center border rounded p-1">
            <Input
              data-testid=""
              ref={null}
              type="checkbox"
              className="form-check-input mt-0"
              onChange={() => handleAllRows()}
              checked={allIsChecked}
            />
            <p className="m-0 mx-2 text-primary">{numberOfRowsChecked} selected</p>
            <span
              className="clear-selections text-primary"
              onClick={() => {
                clearData();
              }}
            >
              <i className="bi bi-x me-1"></i>
            </span>
          </div>
          <div className="mx-3">
            <Select
              data-testid=""
              ref={null}
              className="form-select"
              aria-label="Default select"
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {options.map((option) => (
                <Option data-testid="" key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            data-testid=""
            className="btn btn-primary"
            disabled={!applyChanges}
            onClick={() => applyChanges && applyChanges()}
          >
            Apply
          </Button>
        </div>
      )}
      {/* className="table-responsive" */}
      <div className="table-responsive-sm">
        <table className={`table ${multiSelect ? "table-hover" : "table-sm table-hover"}`}>
          {!filter && (
            <thead>
              <tr>
                {multiSelect && (
                  <th className="width-40px">
                    <Input
                      data-testid=""
                      ref={null}
                      onChange={() => handleAllRows()}
                      type="checkbox"
                      className="form-check-input"
                      disabled={!tableData.length}
                    />
                  </th>
                )}
                {columnNames.map((column, index) => (
                  <th
                    className={`text-capitalize ${column.toLowerCase().search(/action/) > -1 ? "text-center" : "text-left"
                      } ${showSortableIcons(column) && orderBy ? "sortable" : ""}`}
                    key={column}
                    onClick={() => {
                      if (showSortableIcons(column) && setOrderBy && orderByOptions) {
                        setLoading && setLoading(ELoadingType.ColumnSorting + index);
                        setOrderBy({
                          display: column,
                          column_name: orderByOptions[handleColumnNameForSorting(column)],
                          orderBy: getOrderByString(column),
                        });
                      }
                    }}
                  >
                    {displayColumnName(column)}
                    {showSortableIcons(column) && orderBy && (
                      ((loading !== undefined) && (loading >= 0) && (loading < 100) && (loading === index)) ?
                        (<div className="spinner-border spinner-border-sm ms-1" role="status">
                        </div>) :
                        (<i className={`ms-1 bi ${getSortableClassName(column, orderBy)}`}></i>)
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {tableData.map((row) => (
              <tr key={row.id} className={`${rowChecked(row.id) ? "selected-row" : ""}`}>
                {multiSelect && (
                  <td>
                    <Input
                      data-testid=""
                      ref={null}
                      type="checkbox"
                      className="form-check-input"
                      onChange={() => handleSingleRow(row.id)}
                      checked={rowChecked(row.id)}
                    />
                  </td>
                )}
                {columnNames.map((column) => (
                  <td className="row-element" key={column}>
                    {(row as any)[column.replace(/[,.\/():]/g, "").replace(/\s/g, "_")]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!tableData.length && (
          <div className="d-flex justify-content-center">
            <p className="font-weight-semi-bold">No records found.</p>
          </div>
        )}
      </div>
    </div>
  );
});

MultiSelectTable.defaultProps = {
  noShadow: false,
  multiSelect: true,
  applyChanges: undefined,
  loading: -1
};
