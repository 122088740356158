import { FC, useState } from "react";

import {
  useDeleteDeliveryMethodMutation,
  useDeliveryMethodsInitialQuery,
  useDeliveryMethodsSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { DeliveryMethodForm } from "./DeliveryMethodForm";

export const ManageDeliveryMethods: FC = (): ReturnType<FC> => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings/shipping`;

  const [deliveryMethodQuery] = useDeliveryMethodsInitialQuery();
  const [deliveryMethodSuscription] = useDeliveryMethodsSubscription({});
  const [{ fetching }, deleteMutation] = useDeleteDeliveryMethodMutation();

  const defState = {
    ids: null,
    action: "",
    item: null,
  };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const tableColumns = ["Method", "Delivery Time", "Description", "Enabled", "Actions"];

  const error = deliveryMethodQuery.error || deliveryMethodSuscription.error;
  if (error) {
    return <PageError error={{ source: "ManageDeliveryMethods", errMsg: error.message }} />;
  }

  const methodsData = deliveryMethodSuscription.data ?? deliveryMethodQuery.data;
  if (!methodsData)
    return <Loader />;

  const tableData = methodsData.delivery_method.map((mthd) => {
    let actions = [
      {
        actionType: "edit",
        id: mthd.id,
        icon: "bi bi-pencil",
        actionFunc: () => setActionState({ ...actionState, action: "Edit", item: mthd })
      },
      {
        actionType: "delete",
        id: mthd.id,
        icon: "bi bi-trash",
        actionFunc: () => setActionState({ ...actionState, action: "Delete", item: mthd }),
      },
    ];

    return {
      id: mthd.id,
      Method: mthd.delivery_method_name,
      Delivery_Time: mthd.delivery_time,
      Description: mthd.description,
      Enabled: mthd.status && <span className="bi bi-check2 text-success"></span>,
      Actions: <DropdownItems items={actions} />
    };
  });

  const continueDelete = async (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    }
    else {
      const res = await deleteMutation({ id: actionState.item.id });
      setActionState(defState);
      alertsRef.current?.generate(mutationInfo("delivery method", MutationAction.Delete, res));
    }
  }

  return (
    <section>
      <ReturnHeader
        title="Manage Delivery Methods"
        url={baseRoute}
        description="A delivery method is the delivery speed that customers can choose from
          at checkout. Delivery methods determine how quickly a customer may receive their
          products."
      >
        <Button
          data-testid=""
          className="btn btn-primary"
          onClick={() => setActionState({ ...actionState, action: "Add" })}
        >
          Add Delivery Method
        </Button>
      </ReturnHeader>
      <Card className="p-4">
        <Table
          columnNames={tableColumns}
          data={tableData}
        />
      </Card>
      {["Add", "Edit"].includes(actionState.action) &&
        <DeliveryMethodForm
          onClose={() => setActionState(defState)}
          deliveryMethod={actionState.item}
        />
      }
      <Dialog
        title="Delete Delivery Method"
        show={actionState.action === "Delete"}
        continue={continueDelete}
        continueText={actionState.action}
        contineBtnCss="btn btn-danger"
        fetching={fetching}
      >
        Are you sure you want to delete this delivery method?
      </Dialog>
    </section>
  );
};
