import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useMainContext } from "../../layout/components/MainProvider";

dayjs.extend(utc);
dayjs.extend(timezone);
export function useDateFormat() {  
/*   const preset = {
    time_zone: "America/Chicago",
    date_format: "MMM DD, YYYY",
    time_format: "h:mm A",
    unit_system: "Imperial System",
    weight_unit: "Pound (lb)",
    document_size: 15430,
    image_formats: "gif, jpg, jpeg, png, svg",
    max_image_size: 2000,
    max_image_width: 10000,
    min_image_width: 1,
    document_formats: "doc, docx, pdf, ppt, pptx, xls, xlsx, csv, txt",
    max_image_height: 10000,
    min_image_height: 1,
    calendar_week_starts: "Monday",
    number_items_per_page: 25,
    delete_confirmation_dialog: false,
    days_before_logs_are_removed: 0,
    number_api_requests_per_second: 150,
    display_unsaved_changes_warning_prompt: true,
  };
  const { time_zone, time_format, date_format } = preset; */
  const [context] = useMainContext();
  const { time_zone, time_format, date_format } = context.operatorSettings.preset;

  const formatDate = (
    date: string | Date | null | undefined,
    typeOrFormat: "date" | "time" | "datetime" | "datetime_long" | string = "date",
    bNeedConversion: boolean = true
  ) => {
    let convertedDate: string | Date | null | undefined;
    if (!date) return "";
    if (
      !bNeedConversion ||
      date.toString().includes("+00") ||
      date.toString().includes("GMT") ||
      date.toString().includes("Z") ||
      !date.toString().includes("T")
    ) {
      convertedDate = date;
      bNeedConversion = false;
    } else {
      convertedDate = new Date(date.toString() + "+0000");
    }

    let curformat = typeOrFormat || "YYYY-MM-DD";
    switch (typeOrFormat) {
      case "date":
        curformat = date_format;
        break;
      case "time":
        curformat = time_format;
        break;
      case "datetime":
        curformat = date_format + ", " + time_format;
        break;
      case "datetime_long":
        curformat = date_format + ", hh:mm:ss A";
        break;
    }
    return bNeedConversion
      ? dayjs(convertedDate).tz(time_zone).format(curformat)
      : dayjs(convertedDate).format(curformat);
  };
  return formatDate;
}
