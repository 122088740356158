import { TENANT_SETTING_URL } from "./storage";

const defaultTenantName = "marketpush";
export const defaultTenantSettings = { tenantName: defaultTenantName };
export interface IBasicTenantSettings {
    tag?: string | undefined,
    logoUrl?: string | undefined,
    tenantName?: string | undefined,
    legalName?: string | undefined
}

export const getTenantSettings = async (): Promise<IBasicTenantSettings[]> => {
    return new Promise(async (resolve, reject) => {
        const result = await fetch(`${TENANT_SETTING_URL}?v=${new Date().getTime()}`).then(response => response.json())
            .then((data) => {
                return data;
            })
            .catch((e) => {
                return [];
            });
        if (result instanceof Error) {
            reject(result);

        } else {
            resolve(result);
        }
    });
}
export const getTenantSetting = async (tag: string): Promise<IBasicTenantSettings> => {
    if (tag == null || tag === "")
        return defaultTenantSettings;

    return new Promise(async (resolve, reject) => {
        const result = await getTenantSettings()
            .then((data) => {
                const curSettings = data.find((x: IBasicTenantSettings) => x.tag === tag.toLowerCase());
                if (curSettings == null)
                    return defaultTenantSettings;
                else return curSettings;
            })
            .catch((e) => {
                return defaultTenantSettings;
            });
        if (result instanceof Error) {
            reject(result);

        } else {
            resolve(result);
        }

    });
}

