import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { loginSetting } from "../../layout/components/App";

export function useAuth0User() {
  const { user } = useAuth0();
  return user;
}

export function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<
    { tag: "error" } | { tag: "token"; token: string } | { tag: "loading" }
  >({ tag: "loading" });

  useEffect(() => {
    (async () => {
      try {
          let setting:any = {};
        const tenantName = window.localStorage.getItem("tenantName");
          if (tenantName)
              setting = { tenantName };
          else {
              try {
                  await loginSetting(false)
                      .then(value => {
                          setting = value;
                      });
              }
              catch (error) { ; }
          }
        const accessToken = await getAccessTokenSilently({ authorizationParams: setting });
        setToken({ tag: "token", token: accessToken });
       // localStorage.removeItem("tenantName");
      } catch (e) {
        setToken({ tag: "error" });
      }
    })();
  }, [getAccessTokenSilently]);

  return token;
}
