import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLiveSalesByProductQuery, useReportSaleQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import { Money } from "./Money";
export function ReportsRoute(): JSX.Element {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/reports`;
  const { member_based_marketpush } = context.operatorSettings.seller;
  let currentDate = new Date();
  let last30Days = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);

  const [operatorId, setOperatorId] = useState<string>(userInfo.operator_id);

  const inputReports = {
    variables: {
      where: JSON.parse(`{ 
        "operator_id": { "_eq": "${operatorId}" },
        "created_date_tz": { "_gte": "${last30Days.getFullYear()}-${last30Days.getMonth() + 1
        }-${last30Days.getDate()}"}
      }
        `),
    },
  };
  const [reportOrder] = useReportSaleQuery(inputReports);
  const inputs = {
    variables: {
      where: JSON.parse(`{ "operator_id": { "_eq": "${operatorId}" }}`),
    },
  };
  const [salesByProduct] = useLiveSalesByProductQuery(inputs);

  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setOperatorId(userInfo.operator_id);
    }
  }, [userInfo]);

  const error = reportOrder.error || salesByProduct.error;
  if (error) {
    return <PageError error={{ source: "ReportsRoute", errMsg: error.message }} />;
  }

  if (
    reportOrder.fetching ||
    !reportOrder.data ||
    salesByProduct.fetching ||
    !salesByProduct.data
  ) {
    return <Loader />;
  }

  return (
    <div className="reports-container-inner">
      <ReturnHeader title="Reports" />
      <div className="row">
        <div className="col-lg-12 col-xl-6">
          <Card>
            <h5 className="font-weight-semi-bold">Sales</h5>
            <p className="text-muted">
              Make business decisions by comparing sales on your marketplace.
            </p>
            <span className="font-weight-medium text-uppercase text-muted">
              Total Sales last 30 Days
            </span>
            <p className="fs-5">
              <Money amount={reportOrder.data?.sales_earnings.aggregate?.sum?.earnings} />
            </p>
            <p className="font-weight-semi-bold text-uppercase mb-0">Reports</p>
            <Link to={`${baseRoute}/sales-over-time`}>
              <p className="text-decoration-underline mb-0">Comprehensive Sales</p>
            </Link>
            <Link to={`${baseRoute}/sales-by-store`}>
              <p className="text-decoration-underline mb-0">Sales by seller</p>
            </Link>
          </Card>
          <Card>
            <h5 className="font-weight-semi-bold">Orders</h5>
            <p className="text-muted">
              Get an overview of your marketplace's order volume and flow, as well as the overall
              fulfillment performance of your sellers.
            </p>
            <span className="font-weight-medium text-uppercase text-muted">
              ORDERS LAST 30 DAYS
            </span>
            <p className="fs-5">{reportOrder.data?.sales_earnings.aggregate?.sum?.count}</p>
            <p className="font-weight-semi-bold text-uppercase mb-0">Reports</p>
            <Link to={`${baseRoute}/fulfillment-rate-by-store`}>
              <p className="text-decoration-underline mb-0">Fulfillment rate by seller</p>
            </Link>
            <Link to={`${baseRoute}/orders-over-time`}>
              <p className="text-decoration-underline mb-0">Orders over time</p>
            </Link>
          </Card>
        </div>
        <div className="col-lg-12 col-xl-6">
          <Card>
            <h5 className="font-weight-semi-bold">Products</h5>
            <p className="text-muted">
              Understand the movement of your products for more efficient inventory management.
            </p>
            <span className="font-weight-medium text-uppercase text-muted">
              TOP SELLING PRODUCT
            </span>
            <p className="fs-5">{salesByProduct.data.vw_report_sales_by_product[0]?.product_title}</p>
            <p className="font-weight-semi-bold text-uppercase mb-0">Reports</p>
            <Link to={`${baseRoute}/sales-by-product`}>
              <p className="text-decoration-underline mb-0">Sales by product</p>
            </Link>
          </Card>
          {member_based_marketpush
            &&
            <Card>
              <h5 className="font-weight-semi-bold">Member Engagement</h5>
              <p className="text-muted">
                Measure your member participation with the platform.
              </p>

              <p className="font-weight-semi-bold text-uppercase mb-0">Reports</p>
              <Link to={`${baseRoute}/offers-by-seller`}>
                <p className="text-decoration-underline mb-0">Offers by seller</p>
              </Link>
              <Link to={`${baseRoute}/orders-by-member`}>
                <p className="text-decoration-underline mb-0">Orders by member</p>
              </Link>
              <Link to={`${baseRoute}/active-seller`}>
                <p className="text-decoration-underline mb-0">Active sellers</p>
              </Link>
            </Card>
          }
        </div>

      </div>
    </div>
  );
}