import { FC } from "react";
import { addCommaFunc } from "../../orders-route/handler/handler";

type StrikedOutMoneyProps = {
  amount: number;
};

export const StrikedOutMoney: FC<StrikedOutMoneyProps> = (props) => {
  return (
    <span className="text-muted line-through">
      {/* TODO: fix currency symbol for negative numbers */}${" "}
      <span>{addCommaFunc(props.amount)}</span>
    </span>
  );
};
