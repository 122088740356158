import { FC } from "react";

import { Route, Routes } from "react-router-dom";
import { ManageDeliveryMethods } from "./ManageDeliveryMethods";
import { ManageShippingCarriers } from "./ManageShippingCarriers";
import { ManageShippingZones } from "./ManageShippingZones";

export const ShippingRoute: FC = (): ReturnType<FC> => {

  return (
    <Routes>
      <Route path="manage-delivery-methods" element={<ManageDeliveryMethods />} />
      <Route path="manage-shipping-zones" element={<ManageShippingZones />} />
      <Route path="manage-shipping-carriers" element={<ManageShippingCarriers />} />
    </Routes>
  );
};
