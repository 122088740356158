import { FC, useEffect, useState } from "react";
import {
  useRemoveApiKeyMutation,
  useUpsertApiKeyMutation,
  useUserApiKeyQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

export const ApiService: FC = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const accessApi = userInfo.permissions! & context.permissions.access_api ? true : false;
  const [apiKey, setApiKey] = useState<string>("");

  const apikeyInput = {
    variables: {
      userId: userInfo.user_id,
      sellerId: userInfo.seller_id ?? emptyUuid,
    },
  };
  const [queried] = useUserApiKeyQuery(apikeyInput);
  const [, upsertMutation] = useUpsertApiKeyMutation();
  const [, removeMutation] = useRemoveApiKeyMutation();

  useEffect(() => {
    const key = queried.data?.api_key.find((akey) => akey)?.key;
    if (key) setApiKey(key);
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ApiService", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />;
  }

  const usership_id = queried.data.usership.find((usr) => usr)?.id;
  const generateApiKey = async () => {
    let key = await window.crypto.subtle.generateKey({ name: "AES-GCM", length: 256 }, true, [
      "encrypt",
      "decrypt",
    ]);
    const jwk = await crypto.subtle.exportKey("jwk", key);
    return jwk.k;
  };
  const upsertApikey = async () => {
    let key = await generateApiKey();
    const res = await upsertMutation({ usership_id, key });
    if (!res.error) {
      setApiKey(key!);
    }
    alertsRef.current?.generate(mutationInfo("API key", MutationAction.Update, res));
  };
  const removeApikey = async () => {
    const res = await removeMutation({ usership_id });
    if (!res.error) {
      setApiKey("");
    }
    alertsRef.current?.generate(mutationInfo("API key", MutationAction.Remove, res));
  };

  return (
    <>
      <div className="wrapper">
        <div className="row">
          <div className="col-lg-12 col-xl-8 mx-auto">
            <ReturnHeader
              url="/settings"
              title="API Keys and Service Accounts"
              description="Your API key is associated with your user identity and has all of the access you're entitled to across MarketPush that you are a member of. Be careful with how you use or share your API key."
            />
          </div>
        </div>
        {accessApi ? (
          <div className="row">
            <div className="col-lg-12 col-xl-8 mx-auto">
              <Card className="p-4">
                <h4 className="sub-title font-weight-semi-bold mb-3">API Key</h4>
                <div className="d-flex">
                  <Input
                    data-testid="api-key"
                    readOnly
                    type="text"
                    className="form-control"
                    {...{ value: `${apiKey ?? ""}` }}
                    ref={null}
                  />
                </div>
              </Card>
              <div className="mt-3 d-flex justify-content-end">
                <Button data-testid="" className="btn btn-outline-primary" onClick={upsertApikey}>
                  Generate
                </Button>
                {accessApi && (
                  <Button
                    data-testid=""
                    className="btn btn-outline-danger ms-2"
                    disabled={!apiKey}
                    onClick={removeApikey}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-xl-8 mx-auto">
              <Card className="p-4">You don't have priviledge to access API key</Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
