import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { env } from "../../../env";
import { Vw_Auth_User } from "../../../generated/urql-graphql";
import { emptyUuid } from "../miscellaneous/utility";

export function useUserInfo() {
  const { getIdTokenClaims } = useAuth0();
  const defUserInfo = {
    user_level: 10,
    permissions: 0,
    role_id: emptyUuid,
    seller_id: null,
    operator_id: emptyUuid,
    user_id: emptyUuid,
    emulater: emptyUuid,  
    current: true,
    full_name: "",
    email: "",
    info_obj: {},
  };
  const [userInfo, setUserInfo] = useState<Vw_Auth_User>(defUserInfo);
  useEffect(() => {
    (async () => {
        const idToken = await getIdTokenClaims();
        idToken && setUserInfo(idToken[env.REACT_APP_IDTOKEN_NAMESPACE!]);
    })();
  }, [getIdTokenClaims]);

  return userInfo;
}
