import { FC, useEffect, useState } from "react";
import { useWebhooksSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { badgeCss } from "../../common/miscellaneous/utility";
import { ELoadingType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
interface IProps {
    totalCount: number
}
const Webhooks: FC<IProps> = (props): ReturnType<FC> => {
    const formatDate = useDateFormat();
    const [loading, setLoading] = useState<number>(-1);
    const [offset, setOffset] = useState<number>(0);
    const [context] = useMainContext();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/settings`;

    const totalCount = props.totalCount
    const { number_items_per_page } = context.operatorSettings.preset;
    const inputs = {
        variables: {
            limit: number_items_per_page,
            offset: offset
        }
    }
    const [queried] = useWebhooksSubscription(inputs);

    const tableColumns = ['Created', 'Scope', 'Destination', 'Error Rate', 'Status']
    useEffect(() => {
        if (queried.data) {
            setLoading(ELoadingType.None);
        }
    }, [queried.data]);
    const error = queried.error;
    if (error) {
        return <PageError error={{ source: "ManufacturerView", errMsg: error.message }} />;
    }

    if (!queried.data) {
        return (
            <>
                <Card>
                    <PlaceholderTableSearchItem />
                    <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
                </Card>
            </>
        );
    }
    const tableData = queried.data.configs_webhook_settings.map(webhookData => {
        return {
            id: webhookData.id,
            Created: (
                <div className="date-created">
                    <p className="mb-0">{formatDate(webhookData.created_at)}</p>
                    <span className="small text-muted">{formatDate(webhookData.created_at, "time")}</span>
                </div>
            ),
            Scope: webhookData.scope,
            Destination: webhookData.destination,
            Error_Rate: webhookData.error_rate,
            Status: <span className={badgeCss(webhookData.status)}>
                {webhookData.status}
            </span>,
        }
    })

    return (
        <>
            <Card>
                <Table
                    columnNames={tableColumns}
                    data={tableData}
                    offset={offset}
                    setOffset={setOffset}
                    loading={loading}
                    setLoading={setLoading}
                    totalRecords={totalCount || 0}
                />
            </Card>

        </>
    )
}

export default Webhooks