import React, { FC, useRef, useState } from "react";
import ImageUploading, { ErrorsType, ImageListType } from "react-images-uploading";
import { Dialog } from "../../common/components/Dialog";
import { IHash } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { IProdImage } from "../types/product";
import { useMainContext } from "../../layout/components/MainProvider";
import { Input } from "../../forms/components/Input";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";

interface IProps {
  prodImages: IHash;
  setProdImages: (images: IHash) => void;
}

export const ProductImage: FC<IProps> = ({ prodImages, setProdImages }) => {
  const [context] = useMainContext();
  const { max_image_size, max_image_width, max_image_height, image_formats, maximum_number_product_images } = context.operatorSettings.preset;
  const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
  const imgRef = useRef<HTMLInputElement>(null);
  const [errMsg, setErrMsg] = useState<string>("");

  const allowedFileFormats = image_formats?.split(",")
    .map((item: string) => {
      return item.trim();
    });
  const [images, setImages] = React.useState<ImageListType>([]);
  const maxNumberImage = maximum_number_product_images || 5;
  const maxFileSize = max_image_size * 1024;
  const maxNumberPreviewImage = 10;
  const onChange = (imageList: ImageListType) => {
    if (images.length < imageList.length) {
      const uploadImg = imageList.slice(-1)[0];
      if (images.find((img) => img.file?.name === uploadImg.file?.name)) {
        alertsRef.current?.add(
          `Image file '${uploadImg.file?.name}' already exists`,
          "error"
        );
        return;
      }
      setProdImages({ ...prodImages, "added": [...prodImages["added"], { uploadImg }] });
    }
    setImages(imageList);
  };
  const onImagesError = (errorImages: ErrorsType, files?: ImageListType) => {
    const fileNames = files?.map((item) => `"${item.file?.name}"`).join(", ");
    let errDetails = "";
    if (errorImages?.maxNumber) {
      errDetails = `number of files exceed max ${maxNumberPreviewImage} files`;
    }
    if (errorImages?.acceptType) {
      errDetails = `file type is not allow`;
    }
    if (errorImages?.maxFileSize) {
      errDetails = `file size exceed ${maxFileSize}`;
    }
    if (errorImages?.resolution) {
      errDetails = `file resolution is not matching your desired resolution`;
    }
    alertsRef.current?.add(
      `On selected file(s) ${fileNames}: ${errDetails}`,
      "error"
    );
  };

  const RemoveUrl = async (source: string, index: number, curImg: IProdImage) => {
    const prodImgs = { ...prodImages };
    if (curImg.remove && curImg.uploadImg) {
      const uploadedIndex = images.findIndex(img => img.file?.name === curImg.uploadImg?.file?.name)
      curImg.remove(uploadedIndex);
    }
    prodImgs[source].splice(index, 1);
    setProdImages(prodImgs);
  };

  const continueAction = (isContinue: boolean) => {
    if (isContinue) {
      const imgUrl = imgRef?.current?.value || "";
      if (isNullOrEmpty(imgUrl) || !(/^https*/.test(imgUrl))) {
        setErrMsg("Invalid image url");
        return;
      }
      const imgs = prodImages["added"] as IProdImage[];
      if (imgs?.find((img: IProdImage) => img.url === imgUrl)) {
        setErrMsg("The image URL already exists");
        return;
      }
      setProdImages({ ...prodImages, added: [...imgs, { url: imgUrl }] });
    }
    setShowActionDialog(false);
    setErrMsg("");
  }

  const thumbnailImage = (source: string, index: number, img: IProdImage) => {
    const url = (img.url || (img.uploadImg && img.uploadImg["data_url"])) || "";
    const filename = (url?.substring(url?.lastIndexOf("/") + 1) || img?.uploadImg?.file?.name) || "";
    return (
      <div
        key={`${source}-${index}`}
        className="thumbnails d-flex justify-content-between align-items-center p-2"
      >
        <div className="d-flex align-items-center">
          <img
            className="img-thumbnails"
            src={url}
            alt={filename}
          />
        </div>
        <Button
          key={`button-${index}`}
          data-testid=""
          className="btn btn-link"
          type="button"
          onClick={async () => RemoveUrl(source, index, img)}
        >
          <i className="bi bi-x-circle-fill text-muted" />
        </Button>
      </div>
    );
  }

  const totalImgs = prodImages["origins"].length + prodImages["added"].length;
  return (
    <>
      <Dialog
        show={showActionDialog}
        title="Add URL"
        continueText={`Add`}
        continue={continueAction}
        size="lg"    
        errorMsg={errMsg}    
      >
        <Input
          data-testid={""} 
          ref={imgRef}
          type="text"
          pattern="https:*"
          className="form-control my-2"
          placeholder="Please paste or enter URL..."
        />
      </Dialog>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
        maxNumber={maxNumberPreviewImage}
        maxFileSize={maxFileSize}
        resolutionType="less"
        resolutionHeight={
          max_image_height || 800
        }
        resolutionWidth={
          max_image_width || 800
        }
        acceptType={allowedFileFormats}
        onError={onImagesError}
      >
        {({ onImageUpload, onImageRemove, dragProps }) => (
          <>
            <div {...dragProps} className="img-dropzone" data-testid="img-dropzone">
              <div className="box" data-testid="">
                <div className="d-flex flex-column">
                  <p className="text-muted mb-1">
                    Drop and drop your images here. <small>(Limit: {maxNumberPreviewImage})</small>
                  </p>
                  <p className="text-muted mb-2 line">
                    <span className="or-text">Or</span>
                  </p>
                  {totalImgs <= maxNumberImage && <div className="d-flex justify-content-center my-2">
                    <Button
                      data-testid=""
                      className="btn btn-primary"
                      type="button"
                      onClick={onImageUpload}
                    >
                      Add files
                    </Button>
                    <Button
                      data-testid=""
                      className="btn btn-link mx-2"
                      type="button"
                      onClick={() => setShowActionDialog(true)}
                    >
                      Add url
                    </Button>
                  </div>}
                  <div className="d-flex justify-content-center">
                    <p className="text-muted">
                      <small>Suported image files: {allowedFileFormats.join(", ")} </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 product-image-thumbnails">
              <div className="d-flex flex-row gap-3">
                {!!prodImages["origins"].length && prodImages["origins"].map((img: IProdImage, index: number) => (thumbnailImage("origins", index, img)))}
                {!!prodImages["added"].length && prodImages["added"].map((img: IProdImage, index: number) => (
                  thumbnailImage("added", index, { ...img, remove: onImageRemove })
                ))}
              </div>
            </div>
          </>
        )}
      </ImageUploading>
    </>
  );
};
