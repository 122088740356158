import { FC } from "react";

type RateProps = { value: number };

export const RateNumber: FC<RateProps> = (props) => {
    return (
        <span>
            <span>{props.value === 0 ? 0 : props.value.toFixed(2)}</span>
            <span className="me-1">%</span>
        </span>
    );
};
