import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useUpdateCloseStatusMutation } from "../../../generated/urql-graphql";
import { currentDate, isoFormatDate, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";

interface Props {
  sellerId: string;
  resetState: () => void;
}

export const CloseStore: FC<Props> = ({ sellerId, resetState }): ReturnType<FC> => {
  const [, closeMutation] = useUpdateCloseStatusMutation();
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = methods;
  const closeFrom = watch("close_from") || currentDate();

  const onSubmit = (fields: FieldValues) => {
    closeMutation({
      sellerId: sellerId,
      closeStatus: {
        close_from: fields.close_from,
        close_to: fields.close_to ? fields.close_to : null,
      },
    }, { additionalTypenames: ["vw_seller", "vvw_seller_aggregate"] })
      .then((res) => {
        if (res.data && !res.error) {
          reset();
          resetState();
        }
        alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, res));
        return;
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, error));
        return;
      });
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="store-close-form"
        id="store-close-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <p className="pb-0">
          Closing a seller will deactivate all its offers. Please select the date range to close this
          seller.
        </p>
        <div className="row mb-2">
          <div className="col">
            <FormInput
              name="close_from"
              label="Close from"
              reg_options={{
                required: true,
                min: Date.parse(currentDate()) > Date.parse(closeFrom) ? closeFrom : currentDate(),
              }}
              type="date"
              css="mt-2"
            />
          </div>
          <div className="col">
            <FormInput
              name="close_to"
              label="Close to"
              reg_options={{
                validate: (value) => !value || Date.parse(closeFrom) < Date.parse(value),
                min: isoFormatDate(closeFrom),
              }}
              type="date"
              css="mt-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <small className="text-muted mb-0">
              Close this seller indefinitely by indicating a <strong>Closed from</strong> date with
              no <strong>Closed to</strong> date.
            </small>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
