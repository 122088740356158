import parse from 'html-react-parser';
import { FC, useEffect, useRef, useState } from "react";
import {
  useDeleteNotificationMutation,
  useDeleteSelectedNotificationsMutation,
  useMarkAllAsReadMutation,
  useMarkAsReadMutation,
  useMyNotificationCountQuery,
  useMyNotificationQuery,
  useSetNotificationStatusMutation,
  useSetSelectedNotificationStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems, { ActionItemType } from "../../common/components/DropdownItems";
import { PageError } from '../../common/components/Errors';
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from '../../common/miscellaneous/utility';
import { ELoadingType, IDateRange, MutationAction } from "../../common/types/types";
import { alertsRef } from '../../layout/components/Main';
import { useMainContext } from '../../layout/components/MainProvider';
interface IProps {
  refreshCount: () => void;
}

interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

const columnList: string[] = ["date", "notification", /* "by", */ "Action"];

const options = ["Mark as read", "Archive", "Delete"];

export const MyNotification: FC<IProps> = (props) => {
  const [context] = useMainContext();
  const formatDate = useDateFormat();
  const { refreshCount } = props;
  const [loading, setLoading] = useState<number>(-1);

  const { number_items_per_page } = context.operatorSettings.preset;
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [notificationId, setNotificationId] = useState<string>("");
  const [selectedOption] = useState<string>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  let userId = useUserInfo().user_id!;
  let operatorId = useUserInfo().operator_id!;

  let staticFilterString = `,"status_cd": { "_eq": "Active"},
                        "receiver_id": { "_eq": "${userId}"},
                        "operator_id": { "_eq": "${operatorId}"}`;

  const customRule = staticFilterString;
  const tableColumns = ["notification", "email"/* , "created_by" */];

  const [useNotificationLive, executeNotificationQuery] = useMyNotificationQuery({
    requestPolicy: "cache-and-network",
    variables: {
      whereValue: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
      offset: offset,
      limit: number_items_per_page,
    },
  });
  const [totalCount, executeTotalCountQuery] = useMyNotificationCountQuery({
    requestPolicy: "cache-and-network",
    variables: {
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule,
        })
      ),
    },
  });
  const refreshData = (detail: string) => {
    if (!totalCount.fetching && !useNotificationLive.fetching) {
      executeNotificationQuery();
      executeTotalCountQuery();
      refreshCount();
    }
  };

  document.addEventListener('Refresh_Notification',
    (ev: Event) => {
      refreshData((ev as CustomEvent).detail);
    });

  const multiSelectRef = useRef<IRef | null>(null);
  const addFilterOptions = [
    { label: "Notification", value: "notification_lower" }//,
    //{ label: "By", value: "email" },
  ];

  const [selectedValues] = useState<string[]>([]);

  const [showConfirmMarkAllReadDialog, setShowConfirmMarkAllReadDialog] = useState<boolean>(false);
  const [showConfirmMarkAllArchivedDialog, setShowConfirmMarkAllArchivedDialog] = useState<boolean>(
    false
  );

  const [showConfirmMarkAsReadDialog, setShowConfirmMarkAsReadDialog] = useState<boolean>(false);
  const [showConfirmMarkAsArchivedDialog, setShowConfirmMarkAsArchivedDialog] = useState<boolean>(
    false
  );

  const [showConfirmDeleteAllDialog, setShowConfirmDeleteAllDialog] = useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);

  const [, MarkAsReadMutation] = useMarkAsReadMutation();
  const [, SetNotificationStatus] = useSetNotificationStatusMutation();
  const [, MarkAllAsRead] = useMarkAllAsReadMutation();
  const [, MarkAllAsArchive] = useSetSelectedNotificationStatusMutation();
  const [, DeleteSelectedNotification] = useDeleteNotificationMutation();
  const [, DeleteAllSelectedNotification] = useDeleteSelectedNotificationsMutation();

  const addActions = (dataId: string, readBool: boolean) => {
    let actions: ActionItemType[] = [
      {
        actionType: "Mark as read",
        id: dataId,
        icon: "bi-envelope-open",
        actionFunc: (newId: string) => {
          setNotificationId(newId);
          //setShowConfirmMarkAsReadDialog(true);
          MarkAsRead(newId);
        },
        disabled: readBool,
      },
      {
        actionType: "Archive",
        id: dataId,
        icon: "bi-archive",
        actionFunc: (newId: string) => {
          setNotificationId(newId);
          setShowConfirmMarkAsArchivedDialog(true);
        },
      },
      {
        actionType: "Delete",
        id: dataId,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: (newId: string) => {
          setNotificationId(newId);
          setShowConfirmDeleteDialog(true);
        },
      },
    ];

    return actions;
  };

  const MarkAsRead = async (id: string) => {
    const res = await MarkAsReadMutation(
      { id: id },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    alertsRef.current?.generate(mutationInfo("notification status", MutationAction.Update, res));
  };

  const MarkAllRead = async () => {
    const elements = multiSelectRef.current?.selectedData.map((element) => `"${element}"`).join();

    const whereString = `{ "id": { "_in": [${elements}] } }`;

    const res = await MarkAllAsRead(
      { whereValue: JSON.parse(whereString) },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    if (!res.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("all the notification status", MutationAction.Update, res));
  };

  const SetAllArchive = async () => {
    const elements = multiSelectRef.current?.selectedData.map((element) => `"${element}"`).join();

    const whereString = `{ "id": { "_in": [${elements}] } }`;

    const res = await MarkAllAsArchive(
      {
        whereValue: JSON.parse(whereString),
        status: "Archive",
      },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    if (!res.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("the notifications", MutationAction.Archive, res));
  };

  const DeleteAllNotification = async () => {
    const elements = multiSelectRef.current?.selectedData.map((element) => `"${element}"`).join();
    const whereString = `{ "id": { "_in": [${elements}] } }`;
    const res = await DeleteAllSelectedNotification(
      {
        whereValue: JSON.parse(whereString),
      },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    if (!res.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("the notifications", MutationAction.Delete, res));
  };

  const DeleteNotification = async (id: string) => {
    const res = await DeleteSelectedNotification(
      { id: id },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    alertsRef.current?.generate(mutationInfo("notification", MutationAction.Delete, res));
  };

  const continueMarkAllRead = (proceed: boolean) => {
    if (proceed) {
      MarkAllRead();
    }
    setShowConfirmMarkAllReadDialog(false);
  };

  const continueMarkAllArchived = (proceed: boolean) => {
    if (proceed) {
      SetAllArchive();
    }
    setShowConfirmMarkAllArchivedDialog(false);
  };

  const continueMarkAsRead = (proceed: boolean) => {
    if (proceed) {
      MarkAsRead(notificationId);
    }
    setShowConfirmMarkAsReadDialog(false);
  };

  const continueMarkAsArchived = (proceed: boolean) => {
    if (proceed) {
      SetStatus(notificationId, "Archive");
    }
    setShowConfirmMarkAsArchivedDialog(false);
  };

  const continueDelete = (proceed: boolean) => {
    if (proceed) {
      DeleteNotification(notificationId);
    }
    setShowConfirmDeleteDialog(false);
  };

  const continueDeleteAll = (proceed: boolean) => {
    if (proceed) {
      DeleteAllNotification();
    }
    setShowConfirmDeleteAllDialog(false);
  };

  const SetStatus = async (newNotificationId: string, status: string) => {
    const res = await SetNotificationStatus(
      {
        id: newNotificationId,
        status: status,
      },
      { additionalTypenames: ["vw_notification_user", "vw_notification_user_aggregate"] }
    );
    alertsRef.current?.generate(mutationInfo("notification", MutationAction.Update, res));
  };

  useEffect(() => {
    if (selectedValues.length === 0) {
      return;
    }
    if (selectedOption === "Mark as read") {
      setShowConfirmMarkAllReadDialog(true);
    } else if (selectedOption === "Archive") {
      setShowConfirmMarkAllArchivedDialog(true);
    } else if (selectedOption === "Delete") {
      setShowConfirmDeleteAllDialog(true);
    }
  }, [selectedValues, selectedOption]);

  useEffect(() => {
    if (useNotificationLive.data) {
      setLoading(ELoadingType.None);
    }
  }, [useNotificationLive.data]);

  const error = useNotificationLive.error || totalCount.error;
  if (error) {
    return <PageError error={{ source: "MyNotification", errMsg: error.message }} />;
  }

  if (!useNotificationLive.data || !totalCount.data) {
    return (
      <Card>
        <TablePlaceHolder columnNames={columnList} numberOfRows={number_items_per_page} />
      </Card>
    );
  }

  const totalNotificationCount = totalCount.data.vw_notification_user_aggregate?.aggregate?.count;

  const tableData = useNotificationLive.data.vw_notification_user.map((item) => {
    const actions = addActions(item.id ? item.id : "", item.read_date ? true : false);
    return {
      id: item.id ? item.id : "",
      date: <div className="date-created">
        <p className="mb-0">{formatDate(item.created_date)}</p>
        <span className="small text-muted">{formatDate(item.created_date, "time")}</span>
      </div>,
      notification: (
        <p className={item.read_date ? "mb-0" : "mb-0 font-weight-semi-bold"}>
          {parse(item.notification ?? "")}
        </p>
      ),
      /* by: (
        <p className={item.read_date ? "mb-0" : "mb-0 font-weight-semi-bold"}>
          {item.email ?? item.created_by}
        </p>
      ), */
      Action: <DropdownItems items={actions} />,
    };
  });

  const applyChanges = () => {
    if (multiSelectRef.current?.selectedOption === "Mark as read") {
      continueMarkAllRead(true);
    }

    if (multiSelectRef.current?.selectedOption === "Archive") {
      setShowConfirmMarkAllArchivedDialog(true);
    }
    if (multiSelectRef.current?.selectedOption == "Delete") {
      setShowConfirmDeleteAllDialog(true);
    }
  };

  return (
    <Card>
      <Dialog
        show={showConfirmMarkAllArchivedDialog}
        title="Archive"
        continueText="Archive"
        contineBtnCss={"btn-danger"}
        continue={continueMarkAllArchived}
      >
        <div className="pt-2">Are you sure you want to archive the selected notifications?</div>
      </Dialog>
      <Dialog
        show={showConfirmMarkAsArchivedDialog}
        title="Mark as read"
        continueText="Ok"
        continue={continueMarkAsArchived}
      >
        <div className="pt-2">Are you sure you want to archive this notification?</div>
      </Dialog>
      <Dialog
        show={showConfirmDeleteDialog}
        title="Delete notification?"
        continueText="Delete"
        contineBtnCss={"btn-danger"}
        continue={continueDelete}
      >
        <div className="pt-2">Are you sure you want to delete this notification?</div>
      </Dialog>
      <Dialog
        show={showConfirmDeleteAllDialog}
        title="Delete selected notifications?"
        continueText="Delete"
        contineBtnCss={"btn-danger"}
        continue={continueDeleteAll}
      >
        <div className="pt-2">Are you sure you want to delete the selected notifications?</div>
      </Dialog>
      <Table
        setSearchValue={setSearchValue}
        columnNames={columnList}
        data={tableData}
        options={options}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalNotificationCount || 0}
        ref={multiSelectRef}
        multiSelectTable
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Notificat..."
        searchHint="Search by Notification" //, By
      />
    </Card>
  );
};
