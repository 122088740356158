import { FC } from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../../layout/components/MainProvider";
import { useUserInfo } from "../../hooks/useUserInfo";

export const Error404: FC = () => {
  const [context] = useMainContext();
  const userInfo = useUserInfo()
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const sellerId = userInfo.seller_id
  return (
    <>
      <div className="error-page d-flex flex-column justify-content-center align-items-center">
        <h1 className="error-number">404</h1>
        <p className="mini-text">Ooops!</p>
        <p className="error-text mb-4 mt-1">The page you requested was not found!</p>
        <Link className="btn btn-primary" to={sellerId ? `/${tenantUrlTag}/sellers/${userInfo.seller_id}` : `/${tenantUrlTag}/dashboard`}>
          Go to {sellerId ? "My Seller" : "Dashboard"}
        </Link>
      </div>
    </>
  );
};
