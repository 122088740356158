import { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CategoryAttributePartFragment } from "../../../generated/urql-graphql";
import { Button } from "../../forms/components/Button";

type Props = {
  index: number;
  categoryAttr: CategoryAttributePartFragment;
  remove?: () => void;
  edit: () => void;
};

export const DragableItem: FC<Props> = (props) => {
  const { index, categoryAttr, remove, edit } = props;
  const { display, type, required, global} = categoryAttr?.cp_properties;
  return (
    <div className="list-group px-3">
      <Draggable draggableId={categoryAttr.attribute_id || ""} index={index}>
        {(provided) => (
          <div
            className="list-group-item product-attribute-wrapper mb-2"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div {...provided.dragHandleProps} className={`d-flex justify-content-between`}>
              <div className="d-flex align-items-center left-container">
                <i className="bi bi-arrows-move"></i>
                <p className="attribute-title my-2 ms-3" title={display || ""}>
                  <span className="font-weight-medium">{display}</span>
                  <small className="text-muted ms-2 text-capitalize">{type}</small>
                </p>
              </div>
              <div className="d-flex align-items-center right-container ">
                {required && <span className="text-danger me-2" data-title="Required">(*)</span>}
                <Button className="btn btn-clean btn-sm" data-testid="edit" onClick={edit}>
                  <i className="bi bi-pencil"></i>
                </Button>
                {!global && remove && (
                  <Button className="btn btn-clean btn-sm" data-testid="delete" onClick={remove}>
                    <i className="bi bi-trash"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};
