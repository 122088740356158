import { FC, useEffect, useRef, useState } from "react";
import {
  Vw_Report_Offer_By_Seller_Order_By,
  useReportOfferBySellerQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Table } from "../../common/components/Table/index";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { dateRangeToStr } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";

interface IProp {
  handleCallBack: (
    searchText: string,
    where: string,
    orderBy: Vw_Report_Offer_By_Seller_Order_By | Vw_Report_Offer_By_Seller_Order_By[]
  ) => void;
};

export const OffersBySellerData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;

  let tableData: { id: string }[] | undefined = [];
  const operatorId = context.operatorInfo.id;

  let currentDate = new Date();

  let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);

  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: last14Days,
    endDay: currentDate,
    column_name: "created_date_tz",
  });
  const [searchText, setSearchText] = useState<string>(dateRangeToStr(last14Days, currentDate));
  const [offset, setOffset] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Seller",
    column_name: "seller_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Seller: "seller_name",
    New_Offer_Count: "new_offer_count",
    Total_Offer_Count: "total_offer_count",
    Active_Offer_Count: "active_offer_count",
    Inactive_Offer_Count: "inactive_offer_count",
  };
  const tableColumnsToSearch: string[] = ["seller_name"];

  const tableColumns = [
    "Seller",
    "New Offer Count",
    "Total Offer Count",
    "Active Offer Count",
    "Inactive Offer Count"
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.Date]: "desc" };

  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
  });

  const inputs = {
    variables: {
      operatorId: operatorId,
      searchText: searchText,
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useReportOfferBySellerQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  useEffect(() => {
    if (dateRange) {
      let startDate = dateRange.startDate ?? new Date(1980, 1, 1);
      let endDate = dateRange.endDay ?? new Date(9999, 1, 1)
      setSearchText(dateRangeToStr(startDate, endDate));
    }
  }, [dateRange]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "OffersBySellerData", errMsg: error.message }} />;
  }

  const rowsItem = queried.data?.item;
  const totalRows = queried.data?.items.aggregate?.count ?? 0;
  handleCallBack(searchText, handleWhereStr, orderByString);

  if (rowsItem) {
    tableData = rowsItem.map((row) => {
      return {
        id: row.seller_name ?? "",
        Seller: row.seller_name,
        New_Offer_Count: row.new_offer_count,
        Total_Offer_Count: row.total_offer_count,
        Active_Offer_Count: row.active_offer_count,
        Inactive_Offer_Count: row.inactive_offer_count
      };
    });
  }

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Seller", value: "seller_name_lower" },
    { label: "New Offer Count", value: "new_offer_count", type: "numeric" },
    { label: "Total Offer Count", value: "total_offer_count", type: "numeric" },
    { label: "Active Offer Count", value: "active_offer_count", type: "numeric" },
    { label: "Inactive Offer Count", value: "inactive_offer_count", type: "numeric" },
  ];

  return (
    <Card>
      <Table
        setSearchValue={setSearchValue}
        columnNames={tableColumns}
        data={tableData}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalRows}
        ref={multiSelectRef}
        multiSelectTable={false}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        predefinedDateRange={true}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Seller..."
        searchHint="Search by Seller"
      />
    </Card>
  );
}