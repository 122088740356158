import { Button } from "../../forms/components/Button";
import { Label } from "../../forms/components/Label";

type WeeklyOnElementsProps = {
  elements: {
    label: string;
    value: string;
  }[];
  day: string | null;
  ifEdit: boolean | null;
  hideLabel?: boolean;
  setDay?: (value: string) => void;
};
const getClassName = (value: string, day: string) => {
  return day.includes(value) ? "weekly-on-element" : "";
};
export const WeeklyOnElements = ({
  elements,
  day,
  ifEdit,
  hideLabel,
  setDay,
}: WeeklyOnElementsProps) => {
  return (
    <>
      <div className="col d-flex flex-column">
        {!hideLabel && (
          <Label className="font-weight-semi-bold required mb-2" data-testid="">
            On
          </Label>
        )}
        <div className="btn-group" role="group" aria-label="Days of the week">
          {elements.map((element, index) => (
            <Button
              key={index}
              type="button"
              disabled={ifEdit === null ? true : false}
              onClick={(event) => {
                event.preventDefault();
                ifEdit && setDay && setDay(element.value);
              }}
              className={`btn btn-primary weekly-elements ${getClassName(
                element.value,
                day ?? ""
              )}`}
              data-testid={`weekly-on-${element.value}`}
            >
              {element.label.substring(0,2)}
            </Button>
          ))}
        </div>
        {hideLabel ? null : (
          <p className="small hidden show-error mb-0">
            {day ? (
              "\u00A0"
            ) : (
              <>
                <i className="bi bi-exclamation-circle-fill"></i> Please select a day
              </>
            )}
          </p>
        )}
      </div>
    </>
  );
};