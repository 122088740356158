/* eslint-disable react/forbid-elements */
import { forwardRef, Ref, useState } from "react";
import { Text } from "../Text";

type Props = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "ref"
> & {
  /**
   * test id for automation testing
   */
  "data-testid"?: string;
  /**
   * type to be assigned to input
   */
  type?: "text" | "url" | "email" | "password" | "number" | "date";
  /**
   * label to be assigned to input
   */
  label?: string;
  /**
   * error to be assigned to input
   */
  error?: string;
  /**
   * icon to be assigned to input
   */
  icon?: string;

  // copy value function 
  copyClipboardFunc?: () => void;
  /**
   * measure label to be assigned to input
   */
  measurelabel?: string | null;
  /**
   * help text or short description for the input
   */
  hint?: string;
  /**
   * read-only input grouped with the normal input
   */
  inputgrouplabel?: string;
  divclassname?: string;
  tool_tip?: string;
  tooltip_position?: string;
  loading?: number;
};

export const Input = forwardRef(function InputRef(
  props: Props,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  const {
    label,
    error,
    icon,
    copyClipboardFunc,
    measurelabel,
    required,
    hint,
    inputgrouplabel,
    divclassname,
    tool_tip,
    tooltip_position,
    loading,
    type,
    ...rest
  } = props;

  const [inputMasked, setIsInputMasked] = useState<boolean>(type === "password")

  return (
    <div className={`${divclassname ? divclassname : "mb-3"}`}>
      {label && (
        <Text variant="label" className="mb-1" weight="semi-bold" required={required}>
          {label}{" "}
          {tool_tip && (
            <>
              <span className={`ms-1 tooltip-custom ${tooltip_position || "right"}`}>
                <span className="tooltip-text">{tool_tip}
                </span>
                <i className="bi bi-question-circle"></i>
              </span>
            </>
          )}
        </Text>
      )
      }
      < div className="input-wrapper" >
        <div
          className={
            inputgrouplabel
              ? "input-group d-flex flex-nowrap"
              : measurelabel
                ? "input-group"
                : icon
                  ? "position-relative"
                  : ""
          }
        >
          {inputgrouplabel && <span className="input-group-text">{inputgrouplabel}</span>}
          {loading && loading === 100 ? (
            <i
              className={`spinner-border spinner-border-sm spinner-border-thin search-input-loader-icon input-icon`}
              role="status"
            ></i>
          ) : (
            <>{icon && <i className={`bi bi-${icon} me-3 input-icon`} />}
              {copyClipboardFunc && <i onClick={copyClipboardFunc} className={`bi bi-clipboard me-1 ${type == "password" ? "input-icon-end2" : "input-icon-end"}`} />}
              {type == "password" && <i onClick={() => { setIsInputMasked(!inputMasked); }} className={`bi ${inputMasked ? 'bi-eye-slash-fill' : 'bi-eye-fill'} me-1 input-icon-end`} />}
            </>
          )}
          <input
            className={`form-control ${icon ? "with-icon" : ""} ${measurelabel ? "with-measure" : ""
              }`}
            ref={ref}
            required={required}
            {...rest}
            type={type == 'password' && !inputMasked && 'text' || type}
          />
          {measurelabel ? <span className="input-group-text">{measurelabel}</span> : ""}
        </div>
        {hint && <small className="helper-text text-muted">{hint}</small>}
      </div >
      {error && (
        <small className="has-error text-danger">
          <i className="bi bi-exclamation-circle-fill me-1" />
          {error}
        </small>
      )}
    </div >
  );
});

Input.defaultProps = {
  type: "text",
  hint: "",
  inputgrouplabel: "",
  loading: -1,
};
