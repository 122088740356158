import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  BillingDetailsPartFragment,
  useCreateBillingPeriodMutation,
  useUpdateBillingPeriodMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import {
  billingPeriodOnOption,
  billingPeriodRepeats,
  daysOfWeek,
} from "../../common/miscellaneous/data";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { WeeklyOnElements } from "./WeeklyOnElements";
import { UseQueryExecute } from "urql";

type IProps = {
  onClose: () => void;
  billing: BillingDetailsPartFragment;
  viewOnly: boolean;
  reexecuteQuery: UseQueryExecute;
  initBilling: boolean;
};
export const BillingPeriodForm = ({
  onClose,
  billing,
  viewOnly,
  reexecuteQuery,
  initBilling
}: IProps) => {
  const userInfo = useUserInfo()!;
  const [{ fetching: creating }, createMutation] = useCreateBillingPeriodMutation();
  const [{ fetching: editing }, editMutation] = useUpdateBillingPeriodMutation();
  const [curDaysOfWeek, setCurDaysOfWeek] = useState<SelectOption[]>(daysOfWeek);
  const [selectedDay, setSelectedDay] = useState<string>(billing?.weekly_day_of_week || "");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    if (billing) {
      reset(billing);
    }
  }, [billing]);

  const repeats = watch("repeats") || "Weekly";
  const runsOnOption = watch("runs_on_option") || "Day";
  const excludeWkns = watch("exclude_weekends");

  useEffect(() => {
    if (excludeWkns)
      setCurDaysOfWeek(daysOfWeek.filter(day => !["Saturday", "Sunday"].includes(day.value)));
    else
      setCurDaysOfWeek(daysOfWeek)
  }, [excludeWkns]);

  useEffect(() => {
    if (repeats && repeats === "Quarterly") {
      setValue("repeat_interval", 1);
    }
    setErrorMsg("");
  }, [repeats]);

  const getName = (formData: FieldValues) => {
    let day = "";
    if (repeats === "Weekly")
      day = selectedDay;
    else {
      day = `${formData.runs_on_option} ${formData.runs_on_option === "Day"
        ? formData.runs_on_day : formData.runs_on_value}`
    }
    return `Every ${formData.repeat_interval} ${getMeasure()} on ${day}${excludeWkns ? " Excluding Weekends" : ""}`;
  }

  const onSubmit = async (formData: FieldValues) => {
    if (repeats === "Weekly" && !selectedDay) {
      setErrorMsg("A day of the week is required");
      return;
    }

    let res;
    const billing_period_name = getName(formData);
    const weekly = repeats === "Weekly";
    const onDay = runsOnOption === "Day";
    let billingPeriod: IHash = {
      ..._.pick(formData, ["repeats", "repeat_interval", "exclude_weekends"]),
      billing_period_name
    };
    billingPeriod = {
      ...billingPeriod,
      weekly_day_of_week: weekly ? selectedDay : null,
      runs_on_option: !weekly ? formData.runs_on_option : null,
      runs_on_day: !weekly && onDay ? formData.runs_on_day : null,
      runs_on_value: !weekly && !onDay ? formData.runs_on_value : null,
    };
    if (billing) {//update
      res = await editMutation({
        id: billing.id,
        billingPeriod
      });
    }
    else { //create
      billingPeriod = {
        ...billingPeriod,
        operator_id: userInfo.operator_id,
      };
      if (initBilling)
        billingPeriod.default = true;
      res = await createMutation({ billingPeriod }, 
        {additionalTypenames: ["billing_period"]});
    }
    if (!res.error) {
      onClose();
      setErrorMsg("");
      mutationInfo("the billing period",
        billing ? MutationAction.Update : MutationAction.Create, res);
      
      if (initBilling)
        reexecuteQuery({ requestPolicy: 'network-only' });
    }
    else
      setErrorMsg(res.error.message);
  }

  const getMeasure = () => repeats.replace("ly", (repeats === "Quarterly" ? "" : "(s)")).toLowerCase();

  const continueAction = (isContinue: boolean) => {
    if (!isContinue)
      onClose();
  }

  return (
    <Dialog
      title={`${viewOnly ? "View" : billing ? "Edit" : "Add"} Billing Period`}
      continue={continueAction}
      continueText="Save"
      errorMsg={errorMsg}
      fetching={creating || editing}
      size="lg"
      subFormId="manage_billing_period_form"
      footerText={<p className="m-1 mt-2 text-muted small">
        {billing?.cp_next_run_date &&
          `Next scheduled run: ${billing.cp_next_run_date}`
        }
      </p>}
      disableAction={viewOnly}
    >
      <FormProvider {...methods}>
        <Form
          data-testid=""
          noValidate
          id="manage_billing_period_form"
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <FormSelect
            name="repeats"
            label="Repeats"
            options={billingPeriodRepeats}
            reg_options={{ required: true }}
            disabled={viewOnly}
          />
          <div className="d-flex align-items-start gap-3 mb-3">
            {repeats === "Weekly" ?
              <>
                <FormInput
                  name="repeat_interval"
                  label="Every"
                  reg_options={{
                    required: true,
                    max: 4
                  }}
                  disabled={viewOnly}
                  type="number"
                  measurelabel={getMeasure()}
                  css="col-3 mb-0"
                />
                <WeeklyOnElements
                  elements={curDaysOfWeek}
                  day={selectedDay}
                  setDay={(day: string | null) => day && setSelectedDay(day)}
                  ifEdit={!viewOnly}
                />
              </>
              :
              <>
                <FormInput
                  name="repeat_interval"
                  label="Every"
                  reg_options={{
                    required: true,
                    max: repeats === "Monthly" ? 12 : 1
                  }}
                  disabled={viewOnly}
                  readOnly={repeats === "Quarterly"}
                  type="number"
                  measurelabel={getMeasure()}
                  css="col mb-0"
                />
                <FormSelect
                  name="runs_on_option"
                  label="On"
                  reg_options={{ required: true }}
                  options={billingPeriodOnOption}
                  disabled={viewOnly}
                  css="col mb-0"
                />
                <div className="col">
                  {runsOnOption === "Day" ?
                    <FormInput
                      name="runs_on_day"
                      type="number"
                      reg_options={{ required: true, min: 1, max: 28 }}
                      disabled={viewOnly}
                      label="LABEL_FOR_POS_HIDDEN"
                    />
                    :
                    <FormSelect
                      name="runs_on_value"
                      options={[{ label: "Day", value: "Day" }].concat(curDaysOfWeek)}
                      reg_options={{ required: true }}
                      disabled={viewOnly}
                      label="LABEL_FOR_POS_HIDDEN"
                    />}
                </div>
              </>}
          </div>
          <FormInput
            name="exclude_weekends"
            label="Exclude Saturdays and Sundays"
            type="checkbox"
            css="small text-muted"
            disabled={viewOnly}
          />
        </Form>
      </FormProvider>
    </Dialog>
  );
};


