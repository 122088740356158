import {
  useAttrMappingSubscription,
  useAttributeMappingQuery,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";

import { useLocation } from "react-router-dom";
import { UpdateProducts } from "../../products-route/components/UpdateProducts";
import { ImportProducts } from "./ImportProducts";

export const ProductImportRoute = () => {
  const { pathname } = useLocation();

  const [queried] = useAttributeMappingQuery();
  const [attrSubed] = useAttrMappingSubscription();

  const error = queried?.error || attrSubed?.error;
  if (error) {
    return <PageError error={{ source: "ProductImportRoute", errMsg: error.message }} />;
  }

  const attrData = attrSubed?.data?.attribute_mapping || queried?.data?.attribute_mapping;
  return (
    <>
      {pathname.includes("updates") ? 
        <UpdateProducts mappedAttrs={attrData || []} /> 
        : <ImportProducts mappedAttrs={attrData || []} />}
    </>
  );
};
