import { FC, useEffect, useState } from "react";

import {
  ShippingZonePartFragment,
  useCreateAddShippingZoneMutation,
  useEditShippingZoneMutation,
} from "../../../generated/urql-graphql";
import NestedSelect from "../../common/components/NestedSelect";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { shippingZonesOptions } from "../../common/miscellaneous/data";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { Dialog } from "../../common/components/Dialog";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { FormInput } from "../../forms/components/FormInput";
import { FormTextarea } from "../../forms/components/FormTextarea";

type Props = {
  onClose: () => void;
  zone: ShippingZonePartFragment | null;
  usedRegions: string[];
};

export const ShippingZoneForm: FC<Props> = (props): ReturnType<FC> => {
  const {
    onClose,
    zone,
    usedRegions
  } = props;
  const userInfo = useUserInfo();
  const [{ fetching: creating }, createMutation] = useCreateAddShippingZoneMutation();
  const [{ fetching: editing }, editMutation] = useEditShippingZoneMutation();
  const action = zone ? "Edit" : "Add";
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue
  } = methods;

  const status = watch("status") || false;
  const zoneName = watch("zone_name");

  useEffect(()=>{
    if (zone) {
      reset(zone);
      setSelectedRegions(zone.regions);
    }
  }, [zone]);

  useEffect(()=>{
    if (!zone && zoneName)
      setValue("zone_key",zoneName.replace(/ /g, "-").toLocaleLowerCase());
  }, [zoneName]);

  const onSubmit = async (formData: FieldValues) => {
    let res;
    if(zone) {//update
      res = await editMutation({
        id: zone.id, 
        shpZone: {...formData, updated_date: 'now()'} 
      });
    }
    else { //create
      const shpZone = {
        ...formData,
        operator_id: userInfo.operator_id,
      } as any;
      res = await createMutation({shpZone});
    }
    if (!res.error) {
      onClose();
      setErrorMsg("");
      mutationInfo("the shipping zone", 
        zone ? MutationAction.Update : MutationAction.Create, res);
    }
    else {
      setErrorMsg(res.error.message);
    }
  }

  const continueAction = (isContinue: boolean) => {
    if(!isContinue)
      onClose();
  }
  
  return (
    <Dialog
      title={`${action} Shipping Zone`}
      continue={continueAction}
      continueText="Save"
      errorMsg={errorMsg}
      fetching={creating || editing}
      subFormId="manage_shipping_zone_form"
      footerText={
        <FormInput
          form_methods={methods}
          label={`Status: ${status ? "Enabled" : "Disabled"}`}
          name="status"
          type="checkbox"
          isswitch={true}
        />
      }
    >
      <FormProvider {...methods}>
        <Form
          data-testid=""
          id="manage_shipping_zone_form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="form-group row mb-3">
            <div className="col-6">
              <FormInput
                name="zone_name"
                label="Label"
                reg_options={{required: true, maxLength: 80}}
                placeholder="Please enter shipping zone"
                css="mb-0"
              />
              <span className="small text-muted">Your sellers will see this</span>
            </div>
            <div className="col-6">
              <FormInput
                name="zone_key"
                label="Name"
                readOnly={!!zone}
              />
            </div>
          </div>
          <FormTextarea
            name="description"
            label="Description"
            reg_options={{ maxLength:300 }}                             
          />
{/*           <div className="form-group row mb-3">
            <NestedSelect 
              label='Select Regions' 
              options={shippingZonesOptions} 
              selectedItems={selectedRegions}
              setSelectedItems={setSelectedRegions} 
              disabledText="In another zone" 
              disabledValues={usedRegions} 
            />
          </div> */}
        </Form>
      </FormProvider>
    </Dialog>
  );
};
