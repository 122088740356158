import { Route, Routes } from "react-router-dom";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import MessagesByCustomer from "./MessagesByCustomer";
import SellerMessageList from "./SellerMessageList";

const SellerMessagesRoute = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const accessMessageAppSetting = context.operatorSettings.seller.allow_sellers_to_manage_messages
  const accessMessages = (userInfo.permissions! & context.permissions.access_order) > 0 && accessMessageAppSetting;
  return (
    <Routes>
      {accessMessages && <Route index element={<SellerMessageList />} />}
      {userInfo.seller_id && accessMessages && <Route index path=":messageId" element={<MessagesByCustomer />} />}
    </Routes>
  )
}

export default SellerMessagesRoute