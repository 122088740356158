import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  RatePartFragment,
  useCreateShippingRateMutation,
  useUpdateShippingRateMutation,
} from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { CollisionParam, CollisionResult } from "../miscellanceous/types";
interface IProps {
  action: string;
  location_id: string;
  rate_item: RatePartFragment | null;
  zone_list: SelectOption[];
  delivery_methods: SelectOption[];
  checkCollision: (cparam: CollisionParam) => CollisionResult;
  setClose: () => void;
  shipping_carriers: SelectOption[];
}

interface IError {
  zoneError: boolean;
  collision: boolean;
  message: string;
}

export function RateDetails({
  action,
  location_id,
  rate_item,
  zone_list,
  delivery_methods,
  setClose,
  checkCollision,
  shipping_carriers
}: IProps): JSX.Element {
  const [{ fetching: creating }, createMutation] = useCreateShippingRateMutation();
  const [{ fetching: updating }, updateMutation] = useUpdateShippingRateMutation();
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const selectRef = useRef<SelectRef | null>(null);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [rateHash, setRateHash] = useState<IHash>({});

  const disableForm =
    userInfo.seller_id && userInfo.permissions! & context.permissions.update_seller ? false : true;
  const errDef = {
    zoneError: false,
    collision: false,
    message: "",
  };
  const [rateError, setRateError] = useState<IError>(errDef);

  const shippingOptions: SelectOption[] = [
    { label: "Free Shipping", value: "FreeShipping" },
    { label: "Flat Rate", value: "FlatRate" },
    { label: "Price-Based Rate", value: "PriceBasedRate" },
    { label: "Weight-Based Rate", value: "WeightBasedRate" }
  ];

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    unregister,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    let rItem: IHash = {};
    if (rate_item) {
      rItem = { ...rate_item };
      if (zone_list.length === rate_item.rate_zones.length) {
        rItem = { ...rate_item, apply_all_zones: true };
      } else {
        selectRef.current?.set(rate_item.rate_zones.map(zone => zone.zone_id));
      }
    } else {
      rItem = {
        delivery_method_id: delivery_methods.find(mtd => mtd)?.value,
        carrier_id: shipping_carriers.find(crr => crr)?.value,
        shipping_option: { option: "FreeShipping" }
      };
    }
    reset(rItem);
    setRateHash(rItem);
  }, [rate_item]);

  useEffect(() => {
    if (rateHash) {
      const changed = Object.keys(dirtyFields).length !== 0
        || rateHash.shipping_option?.rates?.length !== rate_item?.shipping_option?.rates?.length;
      setFormChanged(changed);
      appRef.current?.updateStatus(changed);
    }
  }, [rateHash, Object.keys(dirtyFields).length]);

  const applyAllZones = watch("apply_all_zones");
  const shpOption = watch("shipping_option.option");
  const cdtRates = watch("shipping_option.rates");
  useEffect(() => {
    let rItem = { ...rateHash };
    if (shpOption === rate_item?.shipping_option.option) {
      rItem = { ...rItem, shipping_option: rate_item?.shipping_option };
    }
    else {
      rItem = { ...rItem, shipping_option: getOptionItem() };
    }
    setRateHash(rItem);
  }, [shpOption]);

  const getOptionItem = (option: IHash = {}) => {
    let shippingOption: IHash = {};
    switch (shpOption) {
      case "FreeShipping":
        shippingOption = { option: shpOption };
        break;
      case "FlatRate":
        shippingOption = { option: shpOption, rate: option.rate || "" };
        break;
      case "PriceBasedRate":
        shippingOption = {
          option: shpOption,
          rate_by: "value",
          rates: option.rates || [{ min: "", rate: "" }]
        };
        break;
      case "WeightBasedRate":
        shippingOption = {
          option: shpOption,
          rate_by: "weight",
          rates: option.rates || [{ min: "", rate: "", per_weight_unit: "" }]
        };
        break;
    }
    return shippingOption;
  }

  const onSubmit = async (formData: FieldValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let res: any = null;
    const shipping_option = getOptionItem(formData.shipping_option)
    if (rate_item) {
      //edit rate
      res = await updateMutation({
        id: rate_item?.id,
        shipping_option,
      });
    } else {
      //create rate
      if (checkZoneError()) return;
      const zones = applyAllZones ? zone_list.map(zone => zone.value) : selectRef.current?.value;
      const collision = checkCollision({ zones, deliveryMethod: formData.delivery_method_id, carrier: formData.carrier_id });
      if (!!collision.message && !rateError.collision) {
        setRateError((prev) => ({ ...prev, collision: true, message: collision.message }));
        return;
      }
      let shippingRate = _.omit(formData, ["apply_all_zones"]);
      shippingRate = {
        ...shippingRate,
        shipping_option,
        seller_id: userInfo.seller_id,
        location_id,
        shipping_rate_zone_jncs: {
          data: zones.map((zone: string) => ({ zone_id: zone })),
        },
      };
      res = await createMutation({
        rateZoneIds: collision.rateZoneIds,
        rateIds: collision.rateIds,
        shippingRate,
      });
    }

    const info = mutationInfo("shipping rate", MutationAction.Update, res);
    if (!res?.error) {
      alertsRef.current?.generate(info);
      resetForm();
    } else {
      setRateError((prev) => ({
        ...prev,
        message: info.errMsg!,
      }));
    }
  };

  const continueAction = async (isContinue: boolean) => {
    if (!isContinue) {
      resetForm();
    }
  };

  const resetForm = () => {
    reset();
    setClose();
    appRef.current?.updateStatus(false);
  };

  const removeMinBreak = (index: number) => {
    unregister(`shipping_option.rates.${index}.min`);
    unregister(`shipping_option.rates.${index}.rate`);
    cdtRates.splice(index, 1);
    setValue("shipping_option.rates", cdtRates);
    setRateHash({ ...rateHash, shipping_option: { ...rateHash.shipping_option, rates: cdtRates } });
  };

  const checkZoneError = () => {
    if (rate_item || applyAllZones) return false;
    const hasError = selectRef.current?.value.length <= 0;
    setRateError((prev) => ({ ...prev, zoneError: hasError }));
    return hasError;
  };

  const priceBasedSection = () => {
    return (
      <div className="p-3 border rounded">
        {/*         <FormInput
          name="shipping_option.rate_by"
          type="radio"
          radio_options={[
            {
              label: "Based on order value",
              value: "value",
            },
            {
              label: "Based on order weight",
              value: "weight",
            },
          ]}
          disabled={disableForm || rateError.collision}
        /> */}
        {rateHash.shipping_option?.rates?.length && Array.from({ length: rateHash.shipping_option.rates.length }, (elm, index) => {
          const minBreak = parseInt(rateHash.shipping_option?.rates[index - 1]?.min);
          return (
            <div className="row" key={`condition_rates_${index}`}>
              <div className="col">
                <FormInput
                  name={`shipping_option.rates.${index}.min`}
                  type="number"
                  label={`Minimum order ${rateHash.shipping_option.rate_by}`}
                  reg_options={{
                    required: true,
                    min: minBreak ? minBreak + 1 : undefined,
                    max: 9999999,
                    valueAsNumber: true,
                  }}
                  disabled={disableForm || rateError.collision}
                />
              </div>
              <div className="col">
                <div className="d-flex">
                  <div className="col">
                    <FormInput
                      name={`shipping_option.rates.${index}.rate`}
                      type="number"
                      label="Rate"
                      reg_options={{
                        required: true,
                        min: 0,
                        max: 9999999,
                        valueAsNumber: true,
                      }}
                      inputgrouplabel="$"
                      css="mt-0"
                      disabled={disableForm || rateError.collision}
                    />
                  </div>
                  {!disableForm && index > 0 && (
                    <div className="col-auto p-0 d-flex flex-column justify-content-end ms-2">
                      <Button
                        data-testid=""
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeMinBreak(index)}
                        disabled={rateError.collision}
                      >
                        <i className="bi bi-trash" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {!disableForm && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Button
              data-testid=""
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => setRateHash({
                ...rateHash,
                shipping_option: {
                  ...rateHash.shipping_option,
                  rates: [
                    ...rateHash.shipping_option.rates, { min: "", rate: "" }
                  ]
                }
              })}
              disabled={rateError.collision}
            >
              + Add
            </Button>
          </div>
        )}
      </div>
    );
  };

  const weightBasedSection = () => {
    return (
      <div className="p-3 border rounded">
        {rateHash.shipping_option?.rates?.length && Array.from({ length: rateHash.shipping_option.rates.length }, (elm, index) => {
          const minBreak = parseInt(rateHash.shipping_option?.rates[index - 1]?.min);
          return (
            <div className="row" key={`weight_rates_${index}`}>
              <div className="col">
                <FormInput
                  name={`shipping_option.rates.${index}.min`}
                  type="number"
                  label={`Minimum order ${rateHash.shipping_option.rate_by === 'weight' ? 'weight (lb)' : rateHash.shipping_option.rate_by}`}
                  reg_options={{
                    required: true,
                    min: minBreak ? minBreak + 1 : undefined,
                    max: 9999999,
                    valueAsNumber: true,
                  }}
                  disabled={disableForm || rateError.collision}
                />
              </div>
              <div className="col">
                <FormInput
                  name={`shipping_option.rates.${index}.rate`}
                  type="number"
                  label="Rate"
                  reg_options={{
                    required: true,
                    min: 0,
                    max: 9999999,
                    valueAsNumber: true,
                  }}
                  inputgrouplabel="$"
                  css="mt-0 mr-1"
                  disabled={disableForm || rateError.collision}
                />
              </div>
              <div className="col">
                <div className="d-flex">
                  <div className="col">
                    <FormInput
                      name={`shipping_option.rates.${index}.per_weight_unit`}
                      type="number"
                      label="Per lb"
                      reg_options={{
                        required: true,
                        min: 0,
                        max: 9999999,
                        valueAsNumber: true,
                      }}
                      inputgrouplabel="$"
                      css="mt-0"
                      disabled={disableForm || rateError.collision}
                    />
                  </div>
                  {!disableForm && index > 0 && (
                    <div className="col-auto p-0 d-flex flex-column justify-content-end ms-2">
                      <Button
                        data-testid=""
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeMinBreak(index)}
                        disabled={rateError.collision}
                      >
                        <i className="bi bi-trash" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {!disableForm && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Button
              data-testid=""
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => setRateHash({
                ...rateHash,
                shipping_option: {
                  ...rateHash.shipping_option,
                  rates: [
                    ...rateHash.shipping_option.rates, { min: "", rate: "" }
                  ]
                }
              })}
              disabled={rateError.collision}
            >
              + Add
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <Dialog
      show={!!action}
      title={`${action.replace("_", " ")}`}
      subFormId="shipping-rate-form"
      continueText={`${rateError.collision ? "Confirm" : "Save"}`}
      continue={continueAction}
      size="lg"
      staticModal={true}
      fetching={creating || updating}
      disableAction={disableForm || (!!rate_item && !formChanged)}
    >
      <FormProvider {...methods}>
        <Form
          data-testid="shipping-rate-form"
          id="shipping-rate-form"
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          onSubmit={handleSubmit(onSubmit, checkZoneError)}
        >
          {!!rateError.message && (
            <div className="alert alert-danger" role="alert">
              <i className="bi bi-exclamation-triangle"></i>
              <span className="ms-2">{rateError.message}</span>
            </div>
          )}
          <FormInput
            name="apply_all_zones"
            label="Applies to all zones"
            type="checkbox"
            disabled={disableForm || !!rate_item || rateError.collision}
          />
          {applyAllZones ? (
            <FormInput name="temp" value="All zones" disabled />
          ) : (
            <CustomSelect
              ref={selectRef}
              options={zone_list}
              placeholder="Select shipping zones"
              disabled={disableForm || !!rate_item || rateError.collision}
              multiple={true}
              errorMsg={rateError.zoneError ? "Please select at least one option" : ""}
              search={{ searchParam, setSearchParam }}
            />
          )}
          <div className="mt-3">
            <FormSelect
              name="delivery_method_id"
              label="Delivery Method"
              options={delivery_methods}
              reg_options={{ required: true }}
              disabled={disableForm || !!rate_item || rateError.collision}
            />
          </div>
          <div className="mt-3">
            <FormSelect
              name="carrier_id"
              label="Shipping Carrier"
              options={shipping_carriers}
              reg_options={{ required: true }}
              disabled={disableForm || !!rate_item || rateError.collision}
            />
          </div>
          <div className="wrapper mt-3">
            <div className="row">
              <div className="col">
                <FormSelect
                  name="shipping_option.option"
                  label="Choose the shipping option you wish to use"
                  options={shippingOptions}
                  reg_options={{ required: true }}
                  disabled={disableForm || rateError.collision}
                />
              </div>
              <div className="col">
                {shpOption === "FlatRate" && <FormInput
                  name="shipping_option.rate"
                  type="number"
                  label="Rate"
                  disabled={disableForm || rateError.collision}
                  reg_options={{
                    required: true,
                    min: 0.01,
                    pattern: /^\d+(\.\d{0,2})?$/,
                  }}
                  step="0.01"
                  inputgrouplabel="$"
                />}
              </div>
            </div>
          </div>
          {shpOption === "PriceBasedRate" && (priceBasedSection())}
          {shpOption === "WeightBasedRate" && (weightBasedSection())}
        </Form>
      </FormProvider>
    </Dialog>
  );
}
