import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Members_Member_Company_Set_Input, useCreateMemberMutation, useEditMemberMutation } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { appRef } from "../../common/components/appStatus";
import { statesOfUnitedStates } from "../../common/miscellaneous/data";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { Address } from "../../common/components/Address";

interface IProps {
  isAddForm: boolean
  memberId?: string
  memberData: IMemberDetails
}

export interface IMemberDetails {
  id?: string;
  name: string;
  company_id: string;
  org: string;
  joined_date?: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zipcode: string;
}
const MemberForm: FC<IProps> = (props): ReturnType<FC> => {
  const { isAddForm, memberData, memberId } = props
  const navigate = useNavigate();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings/members`
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [{ fetching }, createMemberMutation] = useCreateMemberMutation();

  const [{ fetching: fetchingEdit }, editMemberMutation] = useEditMemberMutation();

  const organizations = [{ label: "BLUE HAWK", value: "BLUE HAWK" }, { label: "IMARK", value: "IMARK" }]
  const methods = useForm<IMemberDetails>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { ...memberData },
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = async (data: FieldValues) => {
    let res;
    if (isAddForm) {
      const { id, ...newMemberData } = data;
      res = await createMemberMutation({ memberObj: newMemberData });
    } else {
      const updateMemberData: Members_Member_Company_Set_Input = {
        ...data
      }
      res = await editMemberMutation({ id: memberId, memberObj: updateMemberData })
    }
    const alertAction = isAddForm ? MutationAction.Create : MutationAction.Update;
    alertsRef.current?.generate(mutationInfo("member", alertAction, res));

    if (!res?.error) {
      appRef.current?.updateStatus(false);
      if (isAddForm) {
        //add new member
        appRef.current?.updateStatus(false);
        setTimeout(() => navigate(baseRoute), 500);
      } else {
        //update member
        reset(data as IMemberDetails);
      }
    }
  }
  return (
    <FormProvider {...methods}>
      <Form data-testid="tenant-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
        <Card>
          <div className="card-body">
            <FormInput
              name="name"
              label="Company Name"
              reg_options={{
                required: true
              }}
            />
            <FormInput
              name="company_id"
              label="Member Id"
              reg_options={{
                required: true
              }}
            />
            <FormSelect
              name="org"
              label="Organization"
              options={organizations}
            />
            <FormInput
              name="joined_date"
              label="Joined Date"
              type="date"
            />
            <Address />
          </div>
        </Card>
        <div className="mt-4 d-flex justify-content-end">
          <Button
            data-testid=""
            disabled={!formChanged}
            type="submit"
            className="btn btn-primary"
            id="tenant-save-btn">
            Save
          </Button>
        </div>
      </Form>
    </FormProvider>
  )
}

export default MemberForm