import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRemoveApiKeyMutation, useUpsertApiKeyMutation } from "../../../generated/urql-graphql";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";

interface IAPIKeyForm {
    api_key?: string
}
const APIKeyForm: FC<IAPIKeyForm> = (props) => {
    const { api_key } = props
    const userInfo = useUserInfo()!;
    const usership_id = userInfo.info_obj?.uship_id;
    const [{ fetching: fetchingUpdateApiKey }, upsertMutation] = useUpsertApiKeyMutation();
    const [{ fetching: fetchingRemoveApiKey }, removeMutation] = useRemoveApiKeyMutation();
    const methods = useForm<IAPIKeyForm>({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: { api_key: api_key }
    });

    const {
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        setValue
    } = methods;

    const apiKeyValue = watch('api_key')
    const generateApiKey = async () => {
        let key = await window.crypto.subtle.generateKey({ name: "AES-GCM", length: 256 }, true, [
            "encrypt",
            "decrypt",
        ]);
        const jwk = await crypto.subtle.exportKey("jwk", key);
        return jwk.k;
    };
    const upsertApikey = async () => {
        let key = await generateApiKey();
        const res = await upsertMutation({ usership_id, key });
        if (!res.error) {
            setValue("api_key", key)
        }
        alertsRef.current?.generate(mutationInfo("API key", MutationAction.Update, res));
    };
    const removeApikey = async () => {
        const res = await removeMutation({ usership_id });
        if (!res.error) {
            setValue("api_key", "")
        }
        alertsRef.current?.generate(mutationInfo("API key", MutationAction.Delete, res));
    };

    const copyApiKeyToClipboard = () => {
        navigator.clipboard.writeText(apiKeyValue || "")
        alertsRef.current?.generate({ message: "API Key copied to clipboard!", type: "success" });
    }
    const onSubmit = () => {

    }
    return (
        <FormProvider {...methods}>
            <Form data-testid=""
                id="generate-api-key"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
                <div className="mt-4">
                    <div className="dropdown-divider"></div>
                    <div className="font-weight-medium my-3">API Keys and Service Accounts</div>
                    <p className="text-muted">Your API key is associated with your user identity and has all of the access you're entitled to across MarketPush that you are a member of.
                        Be careful with how you use or share your API key.</p>
                    <div className="d-flex">
                        <FormInput
                            name="api_key"
                            data-testid="api-key"
                            readOnly
                            type="text"
                            css="form-input w-100"
                            disabled
                            copyClipboardFunc={copyApiKeyToClipboard}
                        />
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-end mb-3">
                    {
                        fetchingUpdateApiKey &&
                        <Button data-testid="" type="button" disabled className="btn btn-primary">
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </Button> ||
                        <Button data-testid="btn-generate" onClick={upsertApikey} className="btn btn-outline-primary">
                            Generate
                        </Button>
                    }
                    <div style={{ width: fetchingRemoveApiKey ? "6rem" : "unset", textAlign: 'center' }}>
                        {
                            fetchingRemoveApiKey &&
                            <Button data-testid="" type="button" disabled className="btn btn-primary">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </Button>
                            || <Button
                                data-testid="btn-remove"
                                className="btn btn-outline-danger ms-2"
                                disabled={!apiKeyValue} onClick={removeApikey}>
                                Remove
                            </Button>
                        }
                    </div>
                </div>
            </Form>
        </FormProvider>
    )
}

export default APIKeyForm