import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useArchiveProductsMutation,
  useProductsListQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IProductList, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";

const offerTemplateHeader = "MPN,Manufacturer,Seller Item SKU,Description,Location,Condition,Allow Returns,Stock Quantity,Lead Time to Ship,Threshold alert,Minimum order quantity,Maximum order quantity,Start Date,End Date,Pricing";
const offerTemplateColumns = `,,,,,,,,,,,,`;
const headers = [
  offerTemplateHeader
];

const ActiveProductList: FC<IProductList> = ({ handleCallBack }) => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/products`;
  const sellerRoute = `/${tenantUrlTag}/sellers`;
  const [loading, setLoading] = useState<number>(-1);
  const navigate = useNavigate();
  const captionDownloadTemplate = 'Download Offer Template';
  const formatDate = useDateFormat();
  const [offset, setOffset] = useState<number>(0);
  const [archiveDialog, setArchiveDialog] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const userInfo = useUserInfo();
  const { products_require_operator_approval: prodAppr } = context.operatorSettings.product;
  const { number_items_per_page } = context.operatorSettings.preset;
  const managePerm = userInfo.permissions! & context.permissions.manage_product;
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [selectedFilters, setSelectedFilters] = useState<any>("");

  const selectedProductsRef = useRef<string[]>([]);
  const [prodIdList, setProdIdList] = useState<string>("");
  const [exportClick, setExportClick] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Created Date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Product: "product_title",
    Manufacturer: "manufacturer",
    Created_Date: "created_date",
    Updated_Date: "updated_date",
    Offers: "offer_count",
    MPN: "mfr_part_number",
    Id: "id"
  };
  const tableColumns = [
    "Created Date",
    "MPN",
    "Product",
    "Manufacturer",
    "Updated Date",
    "Offers",
    "Actions",
  ];

  const tableColumnToSearch = "mfr_part_number_lower";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    statusFilter: `"status":{"_eq": "Active"}`
  });

  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.Id]: "asc" }]
    : [{ [orderByOptions.Created_Date]: "desc" }, { [orderByOptions.Id]: "asc" }];

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr)
      ,
    },
  };
  const fixSearchStr = () => {
    if (searchValue === "")
      return searchValue;

    return `${searchValue.toLowerCase().trim()}%`
  }

  let whereStr = JSON.parse(`{"id" : {"_in": [${prodIdList}]} }`);
  const [exportDataQueried] = useProductsListQuery({
    requestPolicy: 'network-only',
    variables: {
      where: whereStr,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Created_Date]: "desc" },
    },
  });

  const [queried] = useProductsListQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  const [{ fetching }, archiveProducts] = useArchiveProductsMutation();
  let tableBulkActions = managePerm ? ["Archive"] : [];

  if (userInfo.seller_id) {
    tableBulkActions.push(captionDownloadTemplate);
  }
  const archiveProductsFunc = async (productIds: string[]) => {
    const obj = {
      at: new Date().toISOString(),
      by: userInfo.user_id,
    };
    const results = await archiveProducts({ ids: productIds, archived: obj }, { additionalTypenames: ["data_vw_product_aggregate", "data_vw_product"] });
    if (results && !results.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("product(s)", MutationAction.Archive, results));
    setArchiveDialog(false);
    selectedProductsRef.current = [];
  };

  const exportData = async () => {
    let exportTableData: {}[][] = [];

    exportDataQueried.data?.data_vw_product.forEach((product) => {
      if (searchStringInArray(product.id, selectedProductsRef.current)) {
        exportTableData.push([
          `${product.mfr_part_number},${product.manufacturer},${offerTemplateColumns}`,
        ]);
      }
    });
    downloadFile({
      data: [...headers, exportTableData?.join("\n")].join("\n"),
      fileName: "ProductOffer.csv",
      fileType: "text/csv",
    });
  };

  useEffect(() => {
    if (prodIdList !== "" && !exportDataQueried.fetching && exportClick) {
      exportData();
      setExportClick(false);
    }
  }, [exportDataQueried.fetching]);

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
      handleCallBack && handleCallBack(handleWhereStr, orderByString);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ActiveProductList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }

  const products = queried.data?.data_vw_product;
  const totalProducts = queried.data?.total_products.aggregate?.count || 0;

  const tableData = (products == null) ? [] : products?.map((product) => {
    const sellerProd = userInfo.seller_id === product.seller_id;
    const manageProd = !!managePerm && !product.info.ds_prod_id
      && (!userInfo.seller_id || (sellerProd && !prodAppr));

    const editable = manageProd && !product?.pim_prod_id
    const bUnverfiedProduct = product.unverified;

    const actions = [
      {
        enabled: true,
        actionType: (editable) ? "edit" : "view",
        id: product.id,
        display: editable ? "Edit" : "View",
        icon: `bi ${editable ? "bi-pencil" : "bi-eye"}`,
        actionFunc: () => navigate(`${baseRoute}/${product.id}`),
      },
      {
        enabled: manageProd,
        actionType: "archive",
        id: product.id,
        display: "Archive",
        icon: "bi bi-archive",
        actionFunc: () => {
          setArchiveDialog(true);
          selectedProductsRef.current = [product.id];
        },
      },
      {
        enabled: userInfo.seller_id && !bUnverfiedProduct,
        actionType: "add-offer",
        id: product.id,
        display: "Add Offer",
        icon: "bi bi-tag",
        actionFunc: () => navigate(`/${tenantUrlTag}/offers/add/${product.id}`),
      },
    ];

    const productImgUrls = getProdImgUrls(product.product_image_urls);
    return {
      id: product.id,
      Created_Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.created_date)}</p>
          <span className="small text-muted">{formatDate(product.created_date, "time")}</span>
        </div>
      ),
      Updated_Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.updated_date)}</p>
          <span className="small text-muted">{formatDate(product.updated_date, "time")}</span>
        </div>
      ),
      MPN:
        <NavLink
          data-testid="nav-message"
          to={`${baseRoute}/${product.id}`}
          className="nav-link text-truncate text-decoration-underline product-title pb-0 pt-0"
        >
          {product.mfr_part_number}
        </NavLink>,
      Product: (
        <div className="d-inline-block text-truncate product-info">
          <div className="product-wrapper">
            <div className="d-flex align-items-center">
              {productImgUrls?.length ? (
                <div className="d-none d-md-block product-image me-2">
                  <img
                    src={productImgUrls[0]}
                    alt={""}
                    height="60"
                    width="60"
                    className="img-fluid"
                  />
                </div>
              ) : (
                <div className="d-none d-md-block img-placeholder d-flex justify-content-center align-items-center me-2">
                  <i className="bi bi-card-image"></i>
                </div>
              )}
              <div className="product-details">
                <div className="product-header">
                  <p className="product-title mb-0 text-truncate">
                    {product?.product_title || "No Title"}
                  </p>
                  {product?.seller_id === userInfo.seller_id &&
                    <small className="ms-2 tooltip-custom right">
                      <span className="tooltip-text">My Product
                      </span>
                      <i className="bi bi-info-circle"></i>
                    </small>}
                  {bUnverfiedProduct &&
                    <small className="ms-2 tooltip-custom right">
                      <span className="tooltip-text">Unverified
                      </span>
                      <i className="bi bi-exclamation-circle"></i>
                    </small>}
                </div>
                {product?.product_description && (
                  <p className="product-description small text-muted text-truncate mb-0">
                    {product.product_description}
                  </p>
                )}
                <div className="product-category d-flex align-items-center text-muted mb-0">
                  <span className="category-wrapper font-weight-semi-bold small me-1">
                    Category:
                  </span>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <span className="small">{product.product_category}</span>
                      </li>
                    </ol>
                  </nav>
                </div>
                {product?.pim_prod_id ?
                  <small className="d-flex text-muted">
                    <span className="font-weight-semi-bold me-1">Source:</span>
                    <span>PIM Catalog</span>
                  </small>
                  :
                  (
                    (userInfo.user_level! > 10) &&
                    <small className="d-flex text-muted">
                      <span className="font-weight-semi-bold me-1">Source:</span>
                      {product?.info.ds_prod_id ? <span>External data source</span>
                        : <div className="d-flex">Created by
                          {!product?.seller_id ? <span className="ms-1">operator</span> :
                            <NavLink
                              data-testid="nav-seller"
                              to={`${sellerRoute}/${product.seller_id}`}
                              className="nav-link text-truncate text-decoration-underline pb-0 pt-0 ms-1"
                            >
                              {product?.info.seller_name}
                            </NavLink>}
                        </div>}
                    </small>)}
                {/*                 {managePerm && userInfo.user_level! > 10 && (
                  <small className="text-muted">
                    <span className="font-weight-semi-bold me-1">MPIN:</span>
                    {product.mpin}
                  </small>
                )} */}
              </div>
            </div>
          </div>
        </div>
      ),
      Manufacturer: product.manufacturer,
      Offers: product.offer_count,
      Actions: actions.filter((item) => item.enabled).length > 0 ?
        <DropdownItems items={actions.filter((item) => item.enabled)} />
        : <></>,
    };
  });
  const downloadFile = (value: { [key: string]: string }) => {
    const blob = new Blob([value.data], { type: value.fileType });

    const aLink = document.createElement("a");
    aLink.download = value.fileName;
    aLink.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    aLink.dispatchEvent(clickEvt);
    aLink.remove();
  };

  const searchStringInArray = (str: string, strArray: string[]): boolean => {
    for (let index = 0; index < strArray.length; index++) {
      if (strArray[index].match(str)) return true;
    }
    return false;
  };
  const exportProductData = async () => {
    let exportTableData: {}[][] = [];

    products?.forEach((product) => {
      if (searchStringInArray(product.id, selectedProductsRef.current)) {
        exportTableData.push([
          `${product.mfr_part_number}, ${offerTemplateColumns}`,
        ]);
      }
    });

    downloadFile({
      data: [...headers, exportTableData?.join("\n")].join("\n"),
      fileName: "ProductOffer.csv",
      fileType: "text/csv",
    });
  };

  const applyChanges = () => {
    if (multiSelectRef.current?.selectedOption === "Archive") {
      selectedProductsRef.current = [...multiSelectRef.current?.selectedData];
      setArchiveDialog(true);
    } else if (multiSelectRef.current?.selectedOption === captionDownloadTemplate) {
      selectedProductsRef.current = [...multiSelectRef.current?.selectedData];
      setProdIdList(
        selectedProductsRef.current.length > 0
          ? `"${selectedProductsRef.current?.join('", "')}"`
          : ""
      );
      setExportClick(true);
    }
  };

  const addFilterOptions = [
    { label: "Product", value: "product_title_lower" },
    { label: "MPN", value: "mfr_part_number_lower" },
    { label: "Manufacturer", value: "manufacturer_lower" },
    { label: "Offer Count", value: "offer_count", type: "numeric" },
    {
      label: "Show My Products",
      value: "seller_id",
      op: "equal",
      filterValue: userInfo.seller_id
    },
    {
      label: "Show Uncategorized Products",
      value: "product_category",
      op: "contains",
      filterValue: "Unassigned"
    }
  ];

  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          options={tableBulkActions}
          applyChanges={applyChanges}
          data={tableData}
          ref={multiSelectRef}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalProducts}
          multiSelectTable
          filters
          setSearchValue={setSearchValue}
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={
            managePerm && userInfo.user_level! > 10
              ? addFilterOptions.filter(
                (options) => options.value !== "seller_id"
              )
              : addFilterOptions.filter(
                (options) => options.label !== "MPIN"
              )
          }
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Mfr Part #"
          searchHint="Search by Mfr Part #"
        />
      </Card>
      <Dialog
        show={archiveDialog}
        title={`Archive ${selectedProductsRef.current.length <= 1 ? "Product" : "Products"}`}
        continueText="Archive"
        continue={(process) => {
          if (process) {
            if (selectedProductsRef.current.length)
              archiveProductsFunc(selectedProductsRef.current);
          } else {
            setArchiveDialog(false);
            selectedProductsRef.current = [];
          }
        }}
        contineBtnCss="btn-danger"
        fetching={fetching}
      >
        <p className="mb-0">
          Archiving{" "}
          {`${selectedProductsRef.current.length <= 1 ? "this product" : "these products"}`} will
          deactivate all associated offers. Are you sure you want to archive{" "}
          {`${selectedProductsRef.current.length <= 1 ? "this product?" : "these products?"}`}
        </p>
      </Dialog>
    </>
  );
};

export default ActiveProductList;

