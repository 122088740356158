import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { appRef } from "../../common/components/appStatus";
import { Card } from "../../common/components/Card";
import { useGblAttrList } from "../../common/hooks/globals";
import { checkDuplicate } from "../../common/miscellaneous/utility";
import { IHash } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormSelect } from "../../forms/components/FormSelect";
import { MappingContext } from "../utils/types";
import { FormInput } from "../../forms/components/FormInput";
import { Dialog } from "../../common/components/Dialog";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";

interface IProps {
    next: () => void;
    setGoNext: (go: boolean) => void;
}

export const RequiredMapping = ({ next, setGoNext }: IProps) => {
    const userInfo = useUserInfo();
    const mapContext = useContext(MappingContext);
    const results = mapContext.resultsRef!.current;
    const glbAttrsList = useGblAttrList();
    const rowData = mapContext.resultsRef?.current.sheet.rowData!;
    const { header, bodyRows } = rowData;
    const ctgAttrName = "product_category";
    const [showMapping, setShowMapping] = useState<boolean>(false);

    const methods = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const {
        handleSubmit,
        reset,
        setError,
        watch
    } = methods;

    useEffect(() => {
        if (glbAttrsList[0]?.name === "") return;

        const existings = new Map();
        //init attrMapping
        header.filter(col => !["mp_category_id", "customer_mpin"].includes(col)).forEach(col => {
            let mapping = results.attrMapping[col];
            if (mapping)
                existings.set(mapping.attribute_id, col);
            else {
                const mapped = mapContext.attrMapped.find((attr) => attr.sheet_column === col);
                if (mapped) {
                    mapping = { ...mapped, orig_attr_id: mapped.attribute_id };
                    existings.set(mapping.attribute_id, col);
                }
                else
                    mapping = { sheet_column: col };
                results.attrMapping[col] = mapping;
            }
        });

        const requires: IHash = {};
        glbAttrsList.filter(attr => attr.required).forEach((attr) => {
            let colname = "";
            if (existings.has(attr.id))
                colname = existings.get(attr.id);
            else {
                let mapping = results.attrMapping[attr.display];
                if (mapping) {
                    mapping = {
                        ...mapping,
                        attribute_id: attr.id,
                        display: attr.display,
                        attribute_name: attr.name,
                        required: attr.required
                    };
                    results.attrMapping[attr.display] = mapping;
                    colname = mapping.sheet_column;
                }
            }
            requires[attr.name] = colname;
        });
        requires["import_type"] = results.importing.type;
        reset(requires);
        setGoNext(true);
    }, [mapContext.attrMapped, glbAttrsList, header]);

    const initCtgMapping = (ctgCol: string) => {
        if (bodyRows.length) {
            const ctgMapping: IHash = {};
            bodyRows.forEach((row) => {
                const ctgVal = row[ctgCol]?.trim();
                const mpCtgId = row["mp_category_id"];
                if (ctgVal) {
                    ctgMapping[ctgVal] = { sheet_category_value: ctgVal, category_id: mpCtgId };
                }
            });

            const ctgMapped: IHash = {};
            Object.values(ctgMapping).forEach(ctg => {
                let temp = { ...ctg };
                const mapped = mapContext.ctgMapped
                    .find((mctg) => mctg.sheet_category_value === ctg.sheet_category_value);
                if (mapped) {
                    temp = { ...mapped, orig_ctg_id: mapped.category_id }
                    if (ctg.category_id && mapped.category_id !== ctg.category_id)
                        temp.category_id = ctg.category_id;
                }
                ctgMapped[ctg.sheet_category_value] = temp;
            });
            mapContext.resultsRef!.current.ctgMapping = ctgMapped;
        }
    };

    const getCol = (attrName: string) => {
        return mapContext.attrMapped.find((mapping) => mapping.attribute_name === attrName)?.sheet_column;
    }

    const onSubmit = async (fieldValues: IHash) => {
        const dups = checkDuplicate(fieldValues);
        if (dups.length) {
            dups.forEach((dup) => {
                setError(dup, { type: "custom", message: "Duplicate value" });
            });
            return;
        }

        let ctgChanged = false;
        let ctgCol = getCol(ctgAttrName) || "";
        if (importType !== "update") {
            ctgCol = fieldValues[ctgAttrName];
            glbAttrsList.filter(attr => attr.required).forEach((attr) => {
                const sheet_column = fieldValues[attr.name];
                let mapping = results.attrMapping[sheet_column];
                if (mapping.attribute_id !== attr.id) {
                    if (attr.name === ctgAttrName) {
                        ctgChanged = true;
                    }
                    const dup = Object.values(results.attrMapping).find(mattr => mattr.attribute_id === attr.id);
                    if (dup) {
                        results.attrMapping[dup.sheet_column] = { ...dup, attribute_id: "", required: false };
                    }
                    mapping = {
                        ...mapping,
                        attribute_id: attr.id,
                        sheet_column,
                        attribute_name: attr.name,
                        required: attr.required,
                        display: attr.display
                    };
                }
                results.attrMapping[sheet_column] = mapping;
            });
            reset();
            appRef.current?.updateStatus(false);
        }

        if (ctgChanged || !Object.keys(results.ctgMapping).length) {
            initCtgMapping(ctgCol);
        }
        results.importing.type = importType;
        next();
    };

    const keyAttrs = Object.values(results.attrMapping).filter(mapping =>
        ["manufacturer", "mfr_part_number"].includes(mapping.attribute_name));
    const disableImpType = !results.importing.changeType
        || keyAttrs.length !== 2 || !keyAttrs.every(mapping => mapping.orig_attr_id);
    const importType = watch("import_type");
    const options = [
        { value: "", label: "-" },
        ...header.filter(opt => !["mp_category_id", "customer_mpin"].includes(opt)).map((opt) => ({ value: opt, label: opt })),
    ];

    const tableColumn = ["Use these column names in your import file", "Product fields"];
    const reqMapped = mapContext.attrMapped.filter(attr => attr.required).map(attr => {
        return {
            id: "existing_" + attr.id,
            Use_these_column_names_in_your_import_file:
                <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                        {attr.sheet_column}
                    </div>
                    <div className="display-3 me-2 text-muted">
                        <i className="bi bi-arrow-right"></i>
                    </div>
                </div>,
            Product_fields: attr.display
        };
    });
    return (
        <div className="product-import-wizard map-fields-wrapper">
            <FormProvider {...methods}>
                <Form data-testid="" id="global_mapping_form" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card className="pt-4 border-0 px-0 shadow-none">
                        {glbAttrsList.find(attr => attr)?.name === "" ? null : (
                            <>
                                <h5>Map Required Fields</h5>
                                <div>
                                <FormInput
                                    name="import_type"
                                    label="Import Type"
                                    type="radio"
                                    css="mb-0"
                                    radio_options={[
                                        {
                                            value: "import",
                                            label: "Full Product Import (Overwrite Data for Imported Products)",
                                            description:
                                                <div className="px-3 py-2 mb-2 w-75 display-6">
                                                    This option will import new products or overwrite the data
                                                    for existing products included in your file. Please make sure to
                                                    include all attribute columns, as any missing information for
                                                    a product will be cleared from the system.
                                                </div>
                                        },
                                        {
                                            value: "update",
                                            label: "Partial Product Import (Update Specific Fields)",
                                            description:
                                                <div className="px-3 py-1 w-75 display-6">
                                                    Use this option to update a subset of product fields.
                                                    Only the columns you include in your file will be updated,
                                                    and any missing columns will retain their current values.
                                                    {disableImpType && <div className="mt-2"><i className="bi bi-exclamation-circle-fill me-1 text-warning"></i>
                                                        This option is unavailable because some column names in your import file could not be mapped. Click the instruction link below for more details.</div>}
                                                </div>
                                        }
                                    ]}
                                    disabled={disableImpType}
                                    /> 
                                </div>
                                <div>
                                    <small className="d-flex align-items-center">
                                        <a // use a instead of button to not set the state
                                            onClick={() => setShowMapping(true)}
                                            className="btn btn-link text-info"
                                        >
                                            Show Import/Update Instructions
                                        </a>
                                    </small>  
                                </div>
                                <table className="table table-hover align-middle mt-4 my-2">
                                    <thead>
                                        <tr>
                                            <th className="d-flex">
                                                <span className="flex-fill">MarketPush Fields</span>
                                                <span className="flex-fill">Your Fields</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {glbAttrsList.filter(attr => attr.required).map((attr) => {
                                            const col = getCol(attr.name);
                                            return (
                                                <tr key={attr.id}>
                                                    <td className="d-flex justify-content-between align-items-center">
                                                        <div className="w-50 d-flex justify-content-between align-items-center">
                                                            <div className="required">
                                                                {attr.display}
                                                            </div>
                                                            <div className="display-3 font-weight-bold">
                                                                <strong>
                                                                    <i className="bi bi-arrow-right"></i>
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className="w-50 ms-5">
                                                            {importType === "import" ?
                                                                <FormSelect
                                                                    name={attr.name}
                                                                    options={options}
                                                                    reg_options={{ required: attr.required }}
                                                                /> :
                                                                <div>
                                                                    {col}
                                                                    {!header.includes(col!) &&
                                                                        <small className="ms-1 text-muted display-7"> (* not found in import file, will not be updated)</small>}
                                                                </div>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </Card>
                </Form>
            </FormProvider>
            <Dialog
                show={showMapping}
                title="Import/Update Instructions"
                infoOnly={true}
                closeText="Ok"
                size="lg"
                continue={() => setShowMapping(false)}
            >
                <>
                    <h6>Full Product Import (Overwrite Data for Imported Products)</h6>
                    {!userInfo.seller_id ? (<div className="mt-3">
                        <div className="px-2">To set the MPIN product field, include the <span className="font-weight-semi-bold text-primary">customer_mpin</span> column in your import file.</div>
                        <div className="px-2">All other columns can be mapped to the corresponding product fields using the mapping wizard in the next step of the import process.</div>
                    </div>) :
                        <div className="px-2"> Map your import file columns to the corresponding product fields using the mapping wizard on the next step of the import process.</div>
                    }
                    <h6 className="mt-4">Partial Product Import (Update Specific Fields)</h6>
                    {!!reqMapped.length && <div className="mt-3">
                        <div className="px-2">
                            <ul>                               
                                <li>Include columns containing manufacturer part number and manufacturer in your import file to identify products for updating.
                                Use the column names listed below.
                                </li>
                                <li>If you're updating product category or product title, include those columns in your import file, using the column names below.</li>
                                <li>Include any additional columns for fields you want to update (e.g., image URLs or datasheet URLs) in your import file,
                                    and you can map them to the product fields in the next step of the import process.</li>
                                {!userInfo.seller_id && <li>To set the MPIN product field, include the <span className="font-weight-semi-bold text-primary">customer_mpin</span> column in your import file.</li>}
                            </ul>                           
                            <div className="product-attrs-scroll mt-2">
                                <Table columnNames={tableColumn} data={reqMapped} />
                            </div>
                        </div>                        
                    </div>}
                </>
            </Dialog>
        </div>
    );
};
