import { FC, useEffect, useState } from "react";
import {
  useUserNotificationTypeQuery,
  useUserNotificationTypeSubSubscription
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";
import { useMainContext } from "../../layout/components/MainProvider";
import { UserProfile } from "./UserProfile";
import UserProfileAdvancedSettings from "./UserProfile-AdvancedSettings";
import UserProfileEmailNotifications from "./UserProfile-EmailNotifications";
import { UserProfileNotification } from "./UserProfile-Notification";

export const UserProfileTabs: FC = () => {
  const userInfo = useUserInfo();
  const userProfile = useUserProfile();
  const [userIdInput, setUserIdInput] = useState({
    variables: {
      userId: '00000000-0000-0000-0000-000000000000',
      operatorId: '00000000-0000-0000-0000-000000000000',
    },
  });
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users/profile`;

  const [userNotificationQueried] = useUserNotificationTypeQuery(userIdInput);
  const [useUserNotificationLive] = useUserNotificationTypeSubSubscription(userIdInput);

  useEffect(() => {
    if (userInfo.user_id !== '00000000-0000-0000-0000-000000000000') {
      setUserIdInput({
        variables: {
          userId: userInfo.user_id!,
          operatorId: userInfo.operator_id!
        },
      });
    }
  }, [userInfo, userProfile]);

  if (
    isNullOrEmpty(userInfo.user_id) ||
    !userNotificationQueried.data ||
    userNotificationQueried.fetching ||
    !useUserNotificationLive.data ||
    isNullOrEmpty(userProfile.user_id)
  ) {
    return <Loader />;
  }

  const error = userNotificationQueried.error || useUserNotificationLive.error;
  if (error) {
    return <PageError error={{ source: "UserProfile-Tabs", errMsg: error.message }} />;
  }

  const tabs = [
    {
      tabName: "Profile",
      route: `${baseRoute}`,

      component: <UserProfile />,
    }
  ];
  if (userInfo.user_level! <= 20) {
    tabs.push({
      tabName: "In-App Notifications",
      route: `${baseRoute}/notification`,

      component: (
        <UserProfileNotification
          data={userProfile}
          dataUserNotification={useUserNotificationLive.data}
        />
      ),
    })
    tabs.push({
        tabName: "Email Notifications",
        route: `${baseRoute}/email-notifications`,
        component: <UserProfileEmailNotifications data={userProfile} dataUserNotification={useUserNotificationLive.data} />,
    })
    tabs.push({
      tabName: "Advanced Settings",
      route: `${baseRoute}/advanced-settings`,

      component: <UserProfileAdvancedSettings data={userProfile} />,
    })
   
  }

  return (
    <div>
      <div className="col-lg-12 col-xl-8 mx-auto">
        <div className="d-flex">
          <h2 className="page-title">User Profile</h2>
          <p className="mt-4 mx-5"></p>
        </div>
      </div>
      <div className="col-lg-12 col-xl-8 mt-4 mx-auto">
        <LinkTabsComponent tabs={tabs} />
      </div>
    </div>
  );
};
