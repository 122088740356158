import { FC, useEffect, useRef, useState } from "react";
import { Money } from "../../../features/reports-route/components/Money";
import {
  Vw_Report_Order_By_Member_Order_By,
  useReportOrderByMemberQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Table } from "../../common/components/Table/index";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { dateRangeToStr } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
interface IProp {
  handleCallBack: (
    searchText: string,
    where: string,
    orderBy: Vw_Report_Order_By_Member_Order_By | Vw_Report_Order_By_Member_Order_By[]
  ) => void;
};

export const OrdersByMemberData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const [context] = useMainContext();
  const operatorId = context.operatorInfo.id;
  const { handleCallBack } = props;
  const { number_items_per_page } = context.operatorSettings.preset;

  let tableData: { id: string }[] | undefined = [];
  let currentDate = new Date();

  let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);

  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: last14Days,
    endDay: currentDate,
    column_name: "created_date_tz",
  });
  const [searchText, setSearchText] = useState<string>(dateRangeToStr(last14Days, currentDate));
  const [offset, setOffset] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>(
    {
      display: "Member Name",
      column_name: "name",
      orderBy: "asc",
    }
  );
  const orderByOptions: { [key: string]: string } = {
    Member_Name: "name",
    Seller: "seller_name",
    Order_Count: "order_count",
    Order_Value: "order_value",
    YTD_Order_Count: "ytd_order_count",
    YTD_Order_Value: "ytd_order_value",
  };
  const tableColumnsToSearch: string[] = ["seller_name", "name"];

  const tableColumns = [
    "Member Name",
    "Seller",
    "Order Count",
    "Order Value",
    "YTD Order Count",
    "YTD Order Value"
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.Date]: "desc" };

  //const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"}`;

  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
    //customRule: customRule,
  });

  const inputs = {
    variables: {
      operatorId: operatorId,
      searchText: searchText,
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useReportOrderByMemberQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  useEffect(() => {
    if (dateRange) {
      let startDate = dateRange.startDate ?? new Date(1980, 1, 1);
      let endDate = dateRange.endDay ?? new Date(9999, 1, 1)
      setSearchText(dateRangeToStr(startDate, endDate));
    }
  }, [dateRange]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "OrderByMemberData", errMsg: error.message }} />;
  }
  const rows = queried.data?.search_order_by_member;
  const totalRows = queried.data?.items.aggregate?.count ?? 0;

  if (rows) {
    tableData = rows?.map((row) => {
      return {
        id: ((row.name ?? "") + (row.seller_name) ?? "") ?? "",
        Member_Name: row.name ?? "",
        Seller: row.seller_name,
        Order_Count: row.order_count,
        Order_Value: <Money amount={row.order_value ?? "N/A"} />,
        YTD_Order_Count: row.ytd_order_count,
        YTD_Order_Value: <Money amount={row.ytd_order_value ?? "N/A"} />,
      };
    });
  }

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Member Name", value: "name_lower" },
    { label: "Seller", value: "seller_name_lower" },
    { label: "Order Count", value: "order_count", type: "numeric" },
    { label: "Order Value", value: "order_value", type: "numeric" },
    { label: "YTD Order Count", value: "ytd_order_count", type: "numeric" },
    { label: "YTD Order Value", value: "ytd_order_value", type: "numeric" },
  ];
  handleCallBack(searchText, handleWhereStr, orderByString);
  return (
    <Card>
      <Table
        setSearchValue={setSearchValue}
        columnNames={tableColumns}
        data={tableData}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalRows}
        ref={multiSelectRef}
        multiSelectTable={false}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        predefinedDateRange={true}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Member Name..."
        searchHint="Search by Member Name, Seller"
      />
    </Card>
  );
}