import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetMemberDetailsQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { emptyUuid } from "../../common/miscellaneous/utility";
import { useMainContext } from "../../layout/components/MainProvider";
import MemberForm, { IMemberDetails } from "./MemberForm";

interface IProps {
    isAddForm: boolean;
}


const MemberDetails: FC<IProps> = (props): ReturnType<FC> => {
    const [context] = useMainContext();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/settings/members`;
    const { isAddForm } = props
    const { memberIdParam } = useParams<{ memberIdParam: string }>();
    const memberId = memberIdParam ? memberIdParam.toLowerCase() === "add-member" ? emptyUuid : memberIdParam : emptyUuid;
    const pageTitle = isAddForm ? "Add Member" : "Edit Member"
    const navigate = useNavigate();
    const inputs = {
        variables: {
            id: memberId,
        },
    };

    let memberFormData: IMemberDetails = {
        id: emptyUuid,
        name: "",
        company_id: "",
        org: "BLUE HAWK",
        joined_date: new Date().toISOString().slice(0, 10),
        line1: "",
        line2: "",
        city: "",
        state: "",
        zipcode: "",
    }

    if (!isAddForm) {
        const [memberQuery] = useGetMemberDetailsQuery(inputs);

        if (memberQuery.fetching) {
            return <Loader />
        }

        const error = memberQuery.error;
        if (error) {
            return <PageError error={{ source: "MemberDetails", errMsg: error.message }} />;
        }
        const memberData = memberQuery.data?.members_member_company_by_pk
        if (memberData) {
            memberFormData = {
                id: memberData.id,
                name: memberData.name,
                company_id: memberData.company_id,
                org: memberData.org || "",
                joined_date: new Date(memberData.joined_date).toISOString().slice(0, 10),
                line1: memberData.line1,
                line2: memberData.line2 || "",
                city: memberData.city,
                state: memberData.state,
                zipcode: memberData.zipcode || "",
            }
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="col-sm-12 col-xl-8">
                    <ReturnHeader title={pageTitle} url={baseRoute} />
                    <MemberForm isAddForm={isAddForm} memberId={memberId} memberData={memberFormData} />
                </div>
            </div>
        </>
    )
}

export default MemberDetails