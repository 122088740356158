import dayjs from "dayjs";

import { IDateRange } from "../types/types";

export function handleDateRangeForSQL(dateRange?: IDateRange): string {
    //console.log(dayjs(dateRange?.startDate),new Date(2022,0,1))
    if (dateRange) {
        if (dateRange.startDate && dateRange.endDay) {
            let start_utc = Date.UTC(
                dateRange.startDate.getUTCFullYear(),
                dateRange.startDate.getUTCMonth(),
                dateRange.startDate.getUTCDate(),
                dateRange.startDate.getUTCHours(),
                dateRange.startDate.getUTCMinutes(),
                dateRange.startDate.getUTCSeconds()
            );
            let endDt = new Date(
                dateRange.endDay.getFullYear(),
                dateRange.endDay.getUTCMonth(),
                dateRange.endDay.getUTCDate(),
                23,
                59,
                59
            );
            let end_utc = Date.UTC(
                endDt.getUTCFullYear(),
                endDt.getUTCMonth(),
                endDt.getUTCDate(),
                endDt.getUTCHours(),
                endDt.getUTCMinutes(),
                endDt.getUTCSeconds()
            );        

            return `"${dateRange.column_name} >= '${new Date(
                start_utc
            ).toISOString()}' and ${dateRange.column_name} <= '${new Date(end_utc).toISOString()}'"`;
        } else if (dateRange.startDate && !dateRange.endDay) {
            let start_utc = Date.UTC(
                dateRange.startDate.getUTCFullYear(),
                dateRange.startDate.getUTCMonth(),
                dateRange.startDate.getUTCDate(),
                dateRange.startDate.getUTCHours(),
                dateRange.startDate.getUTCMinutes(),
                dateRange.startDate.getUTCSeconds()
            ); 
          return `"${dateRange.column_name} >= '${new Date(start_utc).toISOString()}'"`;
        } else if (dateRange.endDay && !dateRange.startDate) {
            let endDt = new Date(
                dateRange.endDay.getFullYear(),
                dateRange.endDay.getUTCMonth(),
                dateRange.endDay.getUTCDate(),
                23,
                59,
                59
            );
            let end_utc = Date.UTC(
                endDt.getUTCFullYear(),
                endDt.getUTCMonth(),
                endDt.getUTCDate(),
                endDt.getUTCHours(),
                endDt.getUTCMinutes(),
                endDt.getUTCSeconds()
            );  
            return `"${dateRange.column_name} <= '${new Date(end_utc).toISOString()}'"`;
        } else {
            return "";
        }
    } else {
        return "";
    }
}
