import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useCreateSellerMerchantAccountMutation, useDecryptTextMutation, useEncryptTextMutation, useGetMerchantIdsQuery, useGetSecretKeyByNameQuery, useUpdateSellerMerchantAccountMutation } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";

interface Props {
  sellerMerchantAccountData: ISellerMerchantAccountData
}
export interface ISellerMerchantAccountData {
  id: string;
  seller_id: string;
  merchant_id: string;
  api_username: string;
  api_password: string;
  is_enabled: boolean;
  is_verified: boolean;
  merchant_provider: string;
}
const MerchantAccountSettings: FC<Props> = ({ sellerMerchantAccountData }): ReturnType<FC> => {
  const [merchantIDsQueried] = useGetMerchantIdsQuery({
    variables: {
      merchantProvider: sellerMerchantAccountData.merchant_provider || "CardConnect"
    }
  })
  const [merhanctAccountSecretKeyQueried] = useGetSecretKeyByNameQuery({
    variables: {
      secretKeyName: "MERCHANT_ACCOUNT_SETTINGS_ENCRYPTION_KEY"
    }
  })
  const [{ fetching: fetchingCreate }, createMutation] = useCreateSellerMerchantAccountMutation()
  const [{ fetching: fetchingUpdate }, updateMutation] = useUpdateSellerMerchantAccountMutation()
  const [, encryptTextMutation] = useEncryptTextMutation()
  const [, decryptTextMutation] = useDecryptTextMutation()

  const userInfo = useUserInfo()!;
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState<boolean>(false)
  const [isMerchantIdUnique, setIsMerchantIdUnique] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const methods = useForm<ISellerMerchantAccountData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { ...sellerMerchantAccountData }
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isValid },
    reset,
  } = methods;

  const decryptCredentials = async (data: ISellerMerchantAccountData) => {
    const decryptedPassword = await decryptTextMutation({ encryptedText: data.api_password || "", secretKey: merhanctAccountSecretKeyQueried.data?.configs_secret_keys_by_pk?.key || "" })
    const decryptedUsername = await decryptTextMutation({ encryptedText: data.api_username || "", secretKey: merhanctAccountSecretKeyQueried.data?.configs_secret_keys_by_pk?.key || "" })
    setValue("api_username", decryptedUsername.data?.decrypt?.plainText || "")
    setValue("api_password", decryptedPassword.data?.decrypt?.plainText || "")
    setIsLoading(false)
  }
  useEffect(() => {
    decryptCredentials(sellerMerchantAccountData)
  }, [merhanctAccountSecretKeyQueried.data])

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    const values = getValues()
    if (values.api_password && values.api_username && values.merchant_id && isMerchantIdUnique && changed) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = async (data: FieldValues) => {
    const secretKey = merhanctAccountSecretKeyQueried.data?.configs_secret_keys_by_pk?.key
    if (!secretKey) {
      appRef.current?.updateStatus(false);
      alertsRef.current?.add("MERCHANT_ACCOUNT_SETTINGS_ENCRYPTION_KEY couldn't be queried!", "error");
      reset({ ...data, id: sellerMerchantAccountData.id } as ISellerMerchantAccountData);
      return
    }
    const encryptedUsernameData = await encryptTextMutation({ plainText: data.api_username, secretKey })
    const encryptedPasswordData = await encryptTextMutation({ plainText: data.api_password, secretKey })
    const encryptedUsername = encryptedUsernameData.data?.encrypt?.secretValue
    const encryptedPassword = encryptedPasswordData.data?.encrypt?.secretValue
    if (encryptedUsername && encryptedPassword) {
      if (data.id) {
        const updatedData = {
          merchant_provider: "CardConnect",
          api_credentials: {
            api_username: encryptedUsername,
            api_password: encryptedPassword,
            merchant_id: data.merchant_id
          },
          is_enabled: data.is_enabled
        }
        const res = await updateMutation({ id: data.id, sellerMerchantAccountObj: updatedData })
        alertsRef.current?.generate(mutationInfo("merchant account settings", MutationAction.Update, res));
        if (!res.error) {
          console.log(res.error)
          appRef.current?.updateStatus(false);
        } else {
          reset(data as ISellerMerchantAccountData);
          decryptCredentials(data as ISellerMerchantAccountData)
        }
      } else {
        const createdMerchantAccount: any = {
          seller_id: data.seller_id,
          operator_id: userInfo.operator_id,
          merchant_provider: "CardConnect",
          api_credentials: {
            api_username: encryptedUsername,
            api_password: encryptedPassword,
            merchant_id: data.merchant_id
          },
          is_enabled: data.is_enabled
        }
        const res = await createMutation({ sellerMerchantAccountObj: createdMerchantAccount })
        if (!res.error && res.data) {
          appRef.current?.updateStatus(false);
          reset({ ...data, id: res.data.insert_configs_seller_merchant_account_one?.id } as ISellerMerchantAccountData);
          decryptCredentials({ ...data, id: res.data.insert_configs_seller_merchant_account_one?.id } as ISellerMerchantAccountData)
        }
        alertsRef.current?.generate(mutationInfo("merchant_account_settings", MutationAction.Create, res));
      }
    } else {
      console.log(encryptedUsernameData.error)
      console.log(encryptedPasswordData.error)
      appRef.current?.updateStatus(false);
    }
  }

  if (merchantIDsQueried.fetching || isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h5>CardConnect API Credentials</h5>
      <FormProvider {...methods}>
        <Form
          data-testid="seller-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
            <div className="card-body">
              <FormInput
                name="merchant_id"
                label="Merchant ID"
                reg_options={{
                  required: true,
                  onChange: (e) => {
                    const isMerchantIdAlreadyExist = merchantIDsQueried.data?.configs_seller_merchant_account.find(i => i.api_credentials.merchant_id === e.target.value)
                    if (isMerchantIdAlreadyExist && e.target.value !== sellerMerchantAccountData.merchant_id) {
                      setIsMerchantIdUnique(false)
                      return
                    }
                    setIsMerchantIdUnique(true)
                  }
                }}
              />
              {isMerchantIdUnique ? <></> :
                (
                  <small className="has-error text-danger w-100 col-12">
                    <i className="bi bi-exclamation-circle-fill me-1" />
                    This merchant ID already exists
                  </small>
                )
              }
              <FormInput
                name="api_username"
                label="API Username"
                reg_options={{ required: true }}
                type="password"
              />
              <FormInput
                name="api_password"
                label="API Password"
                reg_options={{ required: true }}
                type="password"
              />
              <FormInput
                name="is_enabled"
                label="Enabled"
                type="checkbox"
                isswitch="true" />
              <div className="d-flex align-items-center">
                <span>Verified: </span>
                {
                  sellerMerchantAccountData.is_verified ?
                    <span className="bi bi-check px-1 text-success text-large"></span>
                    : <span className="bi bi-x px-1 text-danger text-large"></span>
                }
              </div>
            </div>
          <div className="mt-4 d-flex justify-content-end">
            {fetchingUpdate || fetchingCreate ? (
              <Button data-testid="" type="button" disabled className="btn btn-primary">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
            ) : (
              <Button
                data-testid=""
                disabled={formDisabled}
                type="submit"
                className="btn btn-primary"
                id="seller-save-btn"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  )
}

export default MerchantAccountSettings