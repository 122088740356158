import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  OrderItemPartFragment,
  Reason_Category_Enum,
  useCreateOrderRefundMutation,
  useFetchOrderDetailsSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderHeadingItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { appRef } from "../../common/components/appStatus";
import { useReasonList } from "../../common/hooks/globals";
import { currentDate, keydownfunc, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";
import { OrderItemBriefDescription } from "./OrderItems";

interface IRefund {
  reason: string;
  quantity: number;
  order_item_id: string;
}

const OrderIssueRefunds: FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const reasons = useReasonList();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo?.tenant_url_tag;
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const route = `/${tenantUrlTag}/orders/${orderId}`;
  const [totalRefunds, setTotalRefunds] = useState<number>(0);
  const createOrderRefund = useCreateOrderRefundMutation()[1];

  const [refundableItems, setRefundableItems] = useState<OrderItemPartFragment[]>([]);
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
  } = methods;

  const applySameReason = watch("apply_same_reason");
  const refunds = watch("refunds");
  const refundShipping = watch("refund_shipping");
  const shpAmount = watch("refund_shipping_amount");

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);


  const onSubmit = async (formData: FieldValues) => {
    const rItems: IRefund[] = [];
    refunds.forEach((refund: IRefund, index: number) => {
      if (refund.quantity)
        rItems.push({
          ...refund,
          reason: applySameReason ? formData.same_reason : refund.reason,
          order_item_id: refundableItems[index].id
        });
    });

    const insertObj = {
      refund_items: rItems,
      shipping_amount: refundShipping ? Number(formData.refund_shipping_amount || 0) : 0,
      order_id: orderId,
      transaction_number: formData.transaction_number,
      transaction_date: formData.transaction_date,
    };

    const res = await createOrderRefund({ data: insertObj });
    if (res && !res.error) {
      reset();
      setTimeout(() => {
        navigate(route);
      }, 2000);
    }
    alertsRef.current?.generate(mutationInfo("refund item", MutationAction.Create, res));
    appRef.current?.updateStatus(false);
  };

  useEffect(() => {
    if (refunds?.length || shpAmount) {
      let totalPrice: number = 0;
      refunds?.forEach((refund: IRefund, index: number) => {
        if (refund.quantity)
          totalPrice += refund.quantity * refundableItems[index].unit_price;
      });
      if (shpAmount) totalPrice += shpAmount;
      setTotalRefunds(totalPrice);
    }
  }, [JSON.stringify(refunds), shpAmount]);

  const [subscribed] = useFetchOrderDetailsSubscription({ variables: { orderId: orderId } });
  const finalRefundAmount = totalRefunds ? addCommaFunc(totalRefunds) : 0;

  useEffect(() => {
    if (subscribed.data) {
      const refundedQties: IHash = {};
      const order = subscribed.data?.order_by_pk;
      order?.refunds.forEach((refund) => {
        refund.refund_items.forEach((item: IRefund) => {
          if (refundedQties[item.order_item_id])
            refundedQties[item.order_item_id] += item.quantity;
          else refundedQties[item.order_item_id] = item.quantity;
        });
      });
      const tempItems: OrderItemPartFragment[] = [];
      order?.order_items
        .filter(
          (item) =>
            item.trg_status === "Accepted" ||
            item.trg_status === "PartialRefund" ||
            item.trg_status === "IncidentOpen"
        )
        .sort((item1, item2) => item1.index - item2.index)
        .forEach((item) => {
          const { id, quantity } = item;
          const diffQty = quantity - (refundedQties[id] || 0);
          if (diffQty > 0) {
            tempItems.push({ ...item, quantity: diffQty });
          }
        }
        );
      setRefundableItems(tempItems);
    }
  }, [subscribed.data]);

  const error = subscribed.error;
  if (error) {
    return <PageError error={{ source: "OrderIssueRefunds", errMsg: error.message }} />;
  }

  if (!subscribed.data) {
    return (
      <div className="col-12">
        <PlaceholderHeadingItem />
        <Card>
          <PlaceholderHeadingItem />
          <div className="row">
            <div className="col-sm-12 col-xl-9">
              <Card className=" mb-4">
                <TablePlaceHolder
                  columnNames={["Items", "Quantity", "Unit Price"]}
                  numberOfRows={3}
                />
                <PlaceholderHeadingItem />
              </Card>
            </div>
            <div className="col-sm-12 col-xl-3">
              <Card className=" mb-4">
                <TablePlaceHolder columnNames={["Summary"]} numberOfRows={1} />
              </Card>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  const orderData = subscribed.data.order_by_pk;
  const refundableShipping = orderData?.shipping_price - orderData?.cp_shipping_refund;

  const refundReasons: SelectOption[] = [
    { label: "Please select a reason...", value: "" },
    ...reasons
      .filter((reason) => reason.category === Reason_Category_Enum.RefundOrder)
      .map((reason) => ({ value: reason.value, label: reason.label }))
  ];

  return (
    <div className="col-12">
      <ReturnHeader url={`/${tenantUrlTag}/orders/${orderId}`} title="Issue Refunds" />
      <FormProvider {...methods}>
        <Form
          data-testid="refund-form"
          noValidate
          className={
            errors && Object.keys(errors).length !== 0 ? "was-validated form-group" : "form-group"
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row">
            <div className="col-sm-12">
              <Card className="mb-4">
                <div className="border-bottom card-header bg-light-gray">
                  <h4 className="d-flex card-title mb-0 justify-content-between align-items-center">
                    <span className="order-number">
                      <i className="bi bi-cart me-2"></i>
                      {`Order #${orderData?.order_number}`}
                    </span>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="mb-3 col-xl-6">
                      <FormInput
                        name="transaction_number"
                        label="Reference Number"
                        reg_options={{ required: true }}
                        className="form-control mt-0"
                      />
                    </div>
                    <div className="mb-3 col-xl-6">
                      <div className="row">
                        <FormInput
                          name="transaction_date"
                          label="Date"
                          type="date"
                          reg_options={{ required: true, max: currentDate() }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mt-2 mb-4" />
                  <div className="row">
                    <div className="col-12" >
                      <Card className="mb-4">
                        <div className="border-bottom card-header">
                          <h5 className="d-flex card-title mb-0 align-items-center">
                            <i className="bi bi-bag me-2" />
                            <span>Order Items to Refund</span>
                          </h5>
                        </div>
                        <div className="table-responsive card-body">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <FormInput
                                name="apply_same_reason"
                                label="Apply same reason to all refund items"
                                type="checkbox"
                                isswitch={true}
                              />
                            </div>
                            {applySameReason && (
                              <div>
                                <FormSelect
                                  name="same_reason"
                                  options={refundReasons}
                                  reg_options={{ required: true }}
                                />
                              </div>)}
                          </div>
                          {!applySameReason &&
                            <p>* For individual refund reason, input refund quantity first and select a reason.</p>}
                          <table className="table table-sm align-middle">
                            <thead>
                              <tr className="w-100">
                                <th scope="col" style={{ maxWidth: "40%" }}>Items</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Refund Reason</th>
                                <th scope="col">Unit Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!refundableItems.length ? (
                                <tr>
                                  <td className="py-3 text-center" colSpan={4}>
                                    No products are available to refund.
                                  </td>
                                </tr>
                              ) : (
                                refundableItems.map((item, index) => {
                                  const { quantity, unit_price } = item!;
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <OrderItemBriefDescription orderItem={item} />
                                      </td>
                                      <td>
                                        <FormInput
                                          name={`refunds.${index}.quantity`}
                                          type="number"
                                          placeholder="0"
                                          reg_options={{
                                            max: quantity,
                                            min: 0,
                                            valueAsNumber: true
                                          }}
                                          onKeyDown={keydownfunc}
                                          measurelabel={`of ${quantity}`}
                                          css="mb-0"
                                        />
                                      </td>
                                      <td>
                                        <FormSelect
                                          name={`refunds.${index}.reason`}
                                          options={refundReasons}
                                          reg_options={{ required: !applySameReason && refunds && !!refunds[index].quantity }}
                                          disabled={applySameReason || !refunds || !refunds[index].quantity}
                                          css="mb-0"
                                        />
                                      </td>
                                      <td>
                                        <p className="my-2">$ {addCommaFunc(unit_price, max_decimals_in_unit_price)}</p>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-xl-7">
                      <div className="d-flex align-items-center">
                        <FormInput
                          name="refund_shipping"
                          label='Refund Shipping'
                          type="checkbox"
                          disabled={!refundableShipping}
                          isswitch={true}
                          css="mb-3 me-3"
                        />
                        {refundShipping && (
                          <FormInput
                            name="refund_shipping_amount"
                            type="number"
                            placeholder="0"
                            step="0.01"
                            reg_options={{
                              required: true,
                              max: refundableShipping,
                              min: 0,
                              valueAsNumber: true
                            }}
                            measurelabel={`of $${refundableShipping}`}
                          />
                        )}
                      </div>
                      <FormInput
                        name="notification"
                        label="Send an email notification to the customer"
                        type="checkbox"
                        isswitch={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xl-5">
                      <Card className="mb-4">
                        <div className="border-bottom card-header">
                          <h5 className="d-flex card-title mb-0 align-items-center">
                            <i className="bi bi-card-text me-2" />
                            <span>Summary</span>
                          </h5>
                        </div>
                        <div className="card-body">
                          <p className="mb-2 d-flex flex-row justify-content-between">
                            <span className="font-weight-semi-bold">Items Subtotal</span>
                            <span>$ {addCommaFunc(totalRefunds)}</span>
                          </p>
                          <p className="mb-2 d-flex flex-row justify-content-between">
                            <span className="font-weight-semi-bold">Shipping</span>
                            <span>
                              ${" "}
                              {addCommaFunc(shpAmount || 0)}
                            </span>
                          </p>
                          <hr className="dropdown-divider mb-3 bg-light-gray" />
                          <p className="mb-2 d-flex flex-row justify-content-between">
                            <strong>Refund Amount</strong>
                            <strong>$ {finalRefundAmount}</strong>
                          </p>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="d-flex justify-content-end pb-1">
            <Button
              data-testid=""
              type="submit"
              className="btn btn-primary ms-2"
              disabled={totalRefunds === 0}
            >
              Refund ${finalRefundAmount}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default OrderIssueRefunds;
