import { useEffect, useState } from "react";
import {
  LocationRatePartFragment,
  RatePartFragment,
  useDeleteAddressMutation,
  useDeleteShippingRateMutation,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { Table } from "../../common/components/Table/index";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { statesOfUnitedStates } from "../../common/miscellaneous/data";
import { mutationInfo, separateWithSpace } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { CollisionParam, CollisionResult } from "../miscellanceous/types";
import { LocationDetails } from "./LocationDetails";
import { RateDetails } from "./RateDetails";

interface IProps {
  location: LocationRatePartFragment;
  zone_list: SelectOption[];
  delivery_methods: SelectOption[];
  shipping_carriers: SelectOption[];
}

interface IPriceBasedRate {
  min: number;
  rate: number;
}

export const LocationRate = ({ location, zone_list, delivery_methods, shipping_carriers }: IProps) => {
  const [{ fetching: locDeleting }, deleteLocation] = useDeleteAddressMutation();
  const [{ fetching: rateDeleting }, deleteRate] = useDeleteShippingRateMutation();
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const defState = { item: null, action: "", ids: null };
  const [locRateState, setLocRateState] = useState<IActionState>(defState);
  const { shipping_rates, cp_offers, cp_orders } = location;
  const [manage, setManage] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo && userInfo.permissions && context && context.permissions.update_seller) {
      setManage(BigInt(userInfo.permissions!) & BigInt(context.permissions.update_seller) ? true : false);
    }
    else {
      setManage(false);
    }
  }, [userInfo, context]);

  const handleRate = (shpOption: any) => {
    if (shpOption.option === "PriceBasedRate") {
      const rateBy = shpOption.rate_by;
      return (
        <>
          {shpOption.rates.map((robj: IPriceBasedRate) => (
            <div key={`condition_${robj.min}_${robj.rate}`}>
              Minimum order {rateBy} of {rateBy === "value" && "$"}
              {robj.min}
              {rateBy === "weight" && " lb (s)"}, rate ${robj.rate}
            </div>
          ))}
        </>
      );
    } else if (shpOption.option === "WeightBasedRate") {
      const rateBy = shpOption.rate_by;
      return (

        shpOption.rates.map((robj: any) => (
          <div key={`weight_${robj.min}_${robj.rate}`}>
            Minimum order {rateBy} of {rateBy === "value" && "$"}
            {robj.min}
            {rateBy === "weight" && " lb (s)"}, rate ${robj.rate}, per lb ${robj.per_weight_unit}
          </div>
        ))
      )
    }
    else {
      return (<>{parseFloat(shpOption.rate || 0).toFixed(2)}</>);
    }
  }

  let tableColumns = ["Zones", "Delivery Method", "Option", "Rate", "Shipping Carrier"];
  if (manage) tableColumns = [...tableColumns, "Actions"];
  const tableData = shipping_rates.map((rate: RatePartFragment) => {
    const { id: rateId, delivery_method_id, shipping_option, rate_zones, carrier_id } = rate;
    const actions = [
      {
        actionType: "edit",
        id: rateId,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: () => setLocRateState((prev) => ({ ...prev, item: rate, action: "edit_rate" })),
      },
      {
        actionType: "delete",
        id: rateId,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: () =>
          setLocRateState((prev) => ({ ...prev, ids: [rateId], action: "delete_rate" })),
      },
    ];
    return {
      id: rateId,
      Zones:
        rate_zones.length === zone_list.length
          ? "All zones"
          : rate_zones.map((rzone) => (
            <div key={`${rateId}_${rzone.id}`}>
              {zone_list.find((zone) => zone.value === rzone.zone_id)?.label}
            </div>
          )),
      Delivery_Method: delivery_methods.find((dmd) => dmd.value === delivery_method_id)?.label,
      Shipping_Carrier: shipping_carriers.find((crr) => crr.value === carrier_id)?.label,
      Option: separateWithSpace(shipping_option.option),
      Rate: handleRate(shipping_option),
      Actions: <DropdownItems items={actions} />,
    };
  });

  const continueDelete = async (isContinue: boolean) => {
    if (isContinue) {
      let res;
      if (locRateState.action === "delete_location") {
        const deleteId = locRateState.ids?.find((id) => id);
        const rateIds = shipping_rates.map((rate) => rate.id);
        res = await deleteLocation({ deleteId, rateIds }, { additionalTypenames: ["address"] });
      } else {
        res = await deleteRate({ rateIds: locRateState.ids });
      }
      alertsRef.current?.generate(mutationInfo("location", MutationAction.Delete, res));
    }
    setLocRateState(defState);
  };

  const checkCollision = (cparam: CollisionParam): CollisionResult => {
    const zoneIds = cparam.zones;
    let rateZoneIds: string[] = [];
    const rateIds: string[] = [];
    let message = "";
    shipping_rates
      .filter((rate) => rate.delivery_method_id === cparam.deliveryMethod)
      .filter((rate) => rate.carrier_id === cparam.carrier)
      .filter((rate) => rate.rate_zones.some((zone) => zoneIds.includes(zone.zone_id)))
      .forEach((rate) => {
        const deleted = rate.rate_zones.filter((zone) => zoneIds.includes(zone.zone_id));
        rateZoneIds = [...rateZoneIds, ...deleted.map((jnc) => jnc.id)];
        if (deleted.length === rate.rate_zones.length) rateIds.push(rate.id);
      });
    if (rateZoneIds.length !== 0) {
      const curMethod = delivery_methods.find((dmd) => dmd.value === cparam.deliveryMethod)?.label;
      const curCarrier = shipping_carriers.find((crr) => crr.value === cparam.carrier)?.label;
      message = `This zone already has a rate for ${curMethod} and ${curCarrier}. Do you want to update the existing rate?`;
    }
    return { rateZoneIds, rateIds, message };
  };

  return (
    <>
      <Card className="p-4 mb-3" key={location.id}>
        <div className="d-flex justify-content-between">
          <h4 className="font-weight-semi-bold mb-2">Shipping Location</h4>
          <div className="d-flex ">
            <div className="p-2 tooltip-custom top">
              <i className="bi bi-cart"></i>
              <span className="tooltip-text">{`Orders: ${cp_orders}`}</span>
              {cp_orders > 0 && (
                <span className="position-absolute translate-middle badge rounded-pill bg-primary notification-badge">
                  {cp_orders > 99 ? "99+" : cp_orders}
                </span>
              )}
            </div>
            <div className="p-2 mx-2 tooltip-custom top">
              <i className="bi bi-tags"></i>
              <span className="tooltip-text">{`Offers: ${cp_offers}`}</span>
              {cp_offers > 0 && (
                <span className="position-absolute translate-middle badge bg-primary rounded-pill">
                  {cp_offers > 99 ? "99+" : cp_offers}
                </span>
              )}
            </div>
            {manage && (
              <>
                <Button
                  data-testid=""
                  className="btn-clean position-relative"
                  onClick={() =>
                    setLocRateState((prev) => ({
                      ...prev,
                      item: location,
                      action: "edit_location",
                    }))
                  }
                >
                  <i className="bi bi-pencil"></i>
                </Button>
                {!location.is_primary && cp_offers === 0 && cp_orders === 0 && (
                  <Button
                    data-testid=""
                    className="btn-clean position-relative"
                    onClick={() =>
                      setLocRateState((prev) => ({
                        ...prev,
                        ids: [location.id],
                        action: "delete_location",
                      }))
                    }
                    data-title="Delete"
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="locationIcon">
            <i className="bi bi-geo-alt-fill" />
          </div>
          <div className="d-flex flex-column ms-3">
            <div className="d-flex flex-row">
              <span>{location.name}</span>
              {location.is_primary && (
                <span className="badge rounded-pill bg-light-gray text-dark ms-2">
                  Primary Location
                </span>
              )}
            </div>
            <span className="text-muted">
              {`${location.line1}${location.line2 ? `, ${location.line2}` : ""}, 
                ${location.city}, 
                ${statesOfUnitedStates.find((state) => state.value === location.state)?.label || location.state}, 
                ${location.zipcode}, 
                ${location.country}`}
            </span>
          </div>
        </div>
        <div className="mt-3 mb-1 border-bottom border-muted" />
        <p className="mb-2 py-1 px-2 font-weight-semi-bold bg-ghost-white">Shipping to</p>
        <Table columnNames={tableColumns} data={tableData} />
        {!zone_list.length ?
          <small className="has-error text-danger">
            <i className="bi bi-exclamation-circle-fill me-1"></i>
            No Zone Exists
          </small> :
          <>
            {manage ?
              <div className="d-flex">
                <Button
                  data-testid=""
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => setLocRateState((prev) => ({ ...prev, action: "add_rate" }))}
                >
                  Add Rate
                </Button>
              </div>
              : null}
          </>}
      </Card>
      <Dialog
        show={["delete_rate", "delete_location"].includes(locRateState.action)}
        title={`Delete ${locRateState.action.includes("rate") ? "Rate" : "Location"}`}
        continueText="Delete"
        continue={continueDelete}
        contineBtnCss={"btn-danger"}
        fetching={locDeleting || rateDeleting}
      >
        Are you sure you want to delete this {locRateState.action.replace("delete_", "")}?
      </Dialog>
      {["add_rate", "edit_rate"].includes(locRateState.action) && (
        <RateDetails
          action={locRateState.action}
          location_id={location.id}
          rate_item={locRateState.item}
          zone_list={zone_list}
          delivery_methods={delivery_methods}
          shipping_carriers={shipping_carriers}
          checkCollision={(cparam: CollisionParam) => checkCollision(cparam)}
          setClose={() => setLocRateState(defState)}
        />
      )}
      {["edit_location"].includes(locRateState.action) && (
        <LocationDetails
          action={locRateState.action}
          location_item={locRateState.item}
          setClose={() => setLocRateState(defState)}
        />
      )}
    </>
  );
};
