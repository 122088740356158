import { useContext } from "react";
import { AttributeMappingPartFragment } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { MappingContext } from "../utils/types";

export const Review = () => {
  const mapContext = useContext(MappingContext);
  const { attrMapping, ctgMapping } = mapContext.resultsRef!.current;
  const ctgMaps = Object.values(ctgMapping);
  return (
    <>
      <div className="product-import-wizard review-mappings">
        <Card className="my-4 p-4">
          <div className="mb-4">
            <div>
              <h5 className="sub-title small text-uppercase">Attribute Mapping</h5>
              <div className="product-wizard">
                {Object.values(attrMapping).filter(attr=>attr.attribute_id).map((attr) => (
                  <AttributeItem key={attr.display} attr={attr} />
                ))}
              </div>
            </div>
            <div className="mt-4">
              <h5 className="sub-title small text-uppercase">Category Mapping</h5>
              {!ctgMaps.length ? 
                <div className="border-top p-2 text-center">No mapped category</div> : 
                <>
                  {ctgMaps.filter(ctg=>ctg.category_id).map((ctg) => {
                    const ctgval = ctg.sheet_category_value;
                    return (
                      <div className="product-wizard" key={ctgval}>
                        <div className="d-flex my-1">
                          <span className="category-mapping mapping-from">
                            {ctgval}
                          </span>
                          <span className="mapping-to">{ctg?.ctg_fullname}</span>
                        </div>
                      </div>
                    );})}
                </>}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

const AttributeItem = ({ attr }: { attr: AttributeMappingPartFragment }) => {
  return (
    <div className="d-flex mb-1">
      <div className="mapping-from">{attr.sheet_column}</div>
      <div
        className={`mapping-to ${attr.sheet_column ? "" : "no-mapping-fields "
          }d-flex flex-row justify-content-between`}
      >
        <span>{attr.display}</span>
        {attr.required && (
          <small className="text-danger" data-title="required">
            *
          </small>
        )}
      </div>
    </div>
  );
};
