import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";

// Default styles
import "./features/common/scss/bootstrap.scss";
import "./features/common/scss/default.scss";
import App from "./features/layout/components/App";
import reportWebVitals from "./reportWebVitals";
import { env } from "./env";

if (env.REACT_APP_AUTH_DOMAIN === undefined) {
  throw new Error("REACT_APP_AUTH_DOMAIN missing");
} else if (env.REACT_APP_AUTH_CLIENT_ID === undefined) {
  throw new Error("REACT_APP_AUTH_CLIENT_ID missing");
} else if (env.REACT_APP_AUTH_AUDIENCE === undefined) {
  throw new Error("REACT_APP_AUTH_AUDIENCE missing");
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Auth0Provider
        domain={env.REACT_APP_AUTH_DOMAIN}
        clientId={env.REACT_APP_AUTH_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin, 
          audience: env.REACT_APP_AUTH_AUDIENCE
        }}
      >
        <App />
      </Auth0Provider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
