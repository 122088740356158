declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_AUTH_DOMAIN: string;
  REACT_APP_AUTH_CLIENT_ID: string;
  REACT_APP_AUTH_AUDIENCE: string;
  REACT_APP_HASURA_API_URI: string;
  REACT_APP_HASURA_WS_URI: string;
  REACT_APP_IDTOKEN_NAMESPACE: string;
  REACT_APP_STORAGESASTOKEN: string;
  REACT_APP_STORAGERESOURCENAME: string;
};
export const env: EnvType = { ...process.env, ...window.env };
