import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import { Card } from "../../common/components/Card";
import { appRef } from "../../common/components/appStatus";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";

interface PropDT {
    data: {
        [key: string]: any
    };
    next: () => void;
    setGoNext: (val: boolean) => void | undefined
}

export const ProfileFirstStep: FC<PropDT> = (props) => {
    const { data, next, setGoNext } = props;

    const { user } = useAuth0();
    const imageHeight = 320;
    const imageWidth = 320;
    const imageQuality = 100;
    const imageOutputType = "base64";
    const [image, setImage] = useState<string>(data.profile_image || user?.picture);
    const [formChanged, setFormChanged] = useState<boolean>(false);

    const imgRef = useRef<HTMLInputElement>(null);
    const methods = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        reset
    } = methods;

    useEffect(() => {
        if (data.email) {
            reset({ email: data.email });
        }
    }, [data]);

    useEffect(() => {
        let changed = Object.keys(dirtyFields).length !== 0;
        changed = changed || image !== data.profile_image;
        setFormChanged(changed);
        appRef.current?.updateStatus(changed);
        if (dirtyFields['firstname'] && dirtyFields['lastname']) {
            setGoNext(true);
        }
        else {
            setGoNext(false);
        }
    }, [Object.keys(dirtyFields).length, image]);

    const resizeFile = (file: File) =>
        new Promise<string>(async (resolve) => {
            Resizer.imageFileResizer(
                file,
                imageWidth,
                imageHeight,
                "JPEG",
                imageQuality,
                0,
                (uri: string | Blob | ProgressEvent<FileReader> | File) => {
                    resolve(uri.toString());
                },
                imageOutputType
            );
        });
    const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];

            data.file = file;
            const imgStr = await resizeFile(file);
            setImage(imgStr);
        }
    };
    const handleImportPictureClick = () => {
        imgRef?.current?.click();
    };

    const onSubmit = async (formData: FieldValues) => {

        const validForm = Object.keys(errors).length === 0;
        if (!validForm) {
            return;
        }
        data.first_name = formData.firstname;
        data.last_name = formData.lastname;
        data.email = formData.email;
        data.contact = formData.contact;
        data.profile_image = image;

        reset(formData)
        next();
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
        let contactRef = document.querySelector<HTMLInputElement>('#profile-contact');
        if (contactRef) {
            if (event.key !== 'Backspace' &&
                contactRef &&
                (contactRef.value.length === 3 ||
                    contactRef.value.length === 7)) {
                contactRef.value += '-';
            }
        }
    };

    return (
        <div className="mb-2 mx-auto">
            <FormProvider {...methods}>
                <Form
                    id="profile-first-step"
                    data-testid="user-profile-form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
                >
                    <div className="d-flex flex-column">
                        <Card className="p-4 no-top-border">
                            <h3 className="mb-3">Complete your profile</h3>

                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-sm">
                                        <FormInput
                                            name="firstname"
                                            label="First Name"
                                            type="text"
                                            placeholder="Please enter your first name"
                                            reg_options={{
                                                required: true,
                                                maxLength: 100,
                                                pattern: /^[a-z ,.'-]+$/i
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <FormInput
                                            name="lastname"
                                            label="Last Name"
                                            type="text"
                                            placeholder="Please enter your last name"
                                            reg_options={{
                                                required: true,
                                                maxLength: 100,
                                                pattern: /^[a-z ,.'-]+$/i
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                            <Label className="font-weight-medium d-flex align-items-center mt-3" data-testid="">
                                Profile Image
                            </Label>
                            <p className="py-1">
                                A profile image helps personalize your account and lets you know if you're signed
                                into your account.

                            </p>
                            <div className="container-overlay">
                                <img
                                    src={image}
                                    className="border rounded-circle"
                                    alt=""
                                    width="100px"
                                    height="100px"
                                    onError={(event) => {
                                        event.currentTarget.src = "/default-user-avatar.jpeg";
                                    }}
                                />

                                <div className="overlay">
                                    <i className="bi bi-camera display-1"
                                        onClick={handleImportPictureClick}></i>
                                </div>
                            </div>
                            <Input
                                ref={imgRef}
                                type="file"
                                data-testid="user-profile-upload"
                                style={{ display: "none" }}
                                onChange={onImageChange}
                                accept=".jpg, .png, .jpeg"
                            />
                            <FormInput
                                name="profile_image"
                                style={{ display: "none" }}
                            />
                            <FormInput
                                name="email"
                                label="MarketPush Account Email"
                                data-testid=""
                                type="text"
                                placeholder="Please enter your email"
                                disabled={true}
                                css="mt-3"
                            />
                            <FormInput
                                id="profile-contact"
                                name="contact"
                                label="Contact Phone Number"
                                ref={null}
                                data-testid=""
                                type="tel"
                                placeholder="Please enter your phone number: 123-456-7890"
                                reg_options={{
                                    required: false,
                                    maxLength: 15,
                                    pattern: /^[+\s\-]?(\(?\d{3}\)?[\s.\-]?)?[\d\s\-]{0,12}$/i
                                }}
                                css="mt-3"
                                onKeyUp={handleKeyUp}
                            />
                        </Card>

                    </div>
                </Form>
            </FormProvider>
        </div>
    );
};
