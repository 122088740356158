import { FC, useEffect, useState } from "react";

import {
  useDeleteShippingZoneMutation,
  useShippingZonesInitialQuery,
  useShippingZonesSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { shippingZonesOptions } from "../../common/miscellaneous/data";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ShippingZoneForm } from "./ShippingZoneForm";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";

export const ManageShippingZones: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings/shipping`;

  const [queried] = useShippingZonesInitialQuery();
  const [subscribed] = useShippingZonesSubscription({});
  const [{ fetching }, deleteMutation] = useDeleteShippingZoneMutation();

  const defState = {
    ids: null,
    action: "",
    item: null,
  };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const [usedRegions, setUsedRegions] = useState<string[]>([])
  const tableColumns = ["Shipping Zone", "Name", "Description", "Regions", "Enabled", "Actions"];

  useEffect(() => {
    if (zoneData) {
      const existing = zoneData.shipping_zone.reduce((regionArray: string[], zone) => {
        zone.regions.forEach((i: string) => regionArray.push(i))
        return regionArray
      }, [])
      setUsedRegions(existing)
    }
  }, [queried.data, subscribed.data]);

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "ManageShippingZones", errMsg: error.message }} />;
  }

  const zoneData = subscribed.data ?? queried.data;
  if (!zoneData)
    return <Loader />;

  const tableData = zoneData.shipping_zone.map((zone) => {
    let actions = [
      {
        actionType: "edit",
        id: zone.id,
        icon: "bi bi-pencil",
        actionFunc: () => setActionState({ ...actionState, action: "Edit", item: zone })
      },
      {
        actionType: "delete",
        id: zone.id,
        icon: "bi bi-trash",
        actionFunc: () => setActionState({ ...actionState, action: "Delete", item: zone }),
      },
    ];

    return {
      id: zone.id,
      Shipping_Zone: zone.zone_name,
      Name: zone.zone_key,
      Description: <div className="text-truncated">{zone.description}</div>,
      Regions: <div className="zone-name-wrapper">
        {zone.regions.map((regionValue: string) => {
          let regionData: any;
          shippingZonesOptions.forEach(parentRegion => {
            const regionOption = parentRegion.options.find(i => i.value === regionValue)
            if (regionOption) {
              regionData = regionOption;
            }
          })
          return (
            <span key={regionValue} className="badge bg-light-gray text-dark">
              {regionData?.label}
            </span>
          )
        })}
      </div>,
      Enabled: zone.status && <span className="bi bi-check2 text-success"></span>,
      Actions: <DropdownItems items={actions} />
    };
  });

  const continueDelete = async (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    }
    else {
      const res = await deleteMutation({ id: actionState.item.id });
      setActionState(defState);
      alertsRef.current?.generate(mutationInfo("shipping zone", MutationAction.Delete, res));
    }
  }

  return (
    <div className="manage-shipping-zone">
      <ReturnHeader
        title="Manage Shipping Zones"
        url={baseRoute}
        description="A shipping zone is a geographic region where a certain set of delivery
          methods are offered. Customers will get matched to a shipping zone using
          their shipping address and will be presented with the delivery methods available within that
          zone."
      >
        {userInfo.user_level && userInfo.user_level! > 20 ? 
          <Button
            data-testid=""
            className="btn btn-primary"
            onClick={() => setActionState({...actionState, action: "Add"})}
          >
            Add Shipping Zone
          </Button> : <></>}
      </ReturnHeader>
      <Card className="p-4">
        <Table
          columnNames={tableColumns}
          data={tableData}
        />
      </Card>
      {["Add", "Edit"].includes(actionState.action) &&
        <ShippingZoneForm
          onClose={() => setActionState(defState)}
          usedRegions={usedRegions}
          zone={actionState.item}
        />
      }
      <Dialog
        title="Delete Shipping Zone"
        show={actionState.action === "Delete"}
        continue={continueDelete}
        continueText={actionState.action}
        contineBtnCss="btn btn-danger"
        fetching={fetching}
      >
        Are you sure you want to delete this shipping zone?
      </Dialog>
    </div>
  );
};
