import { escapeControlCharacters } from "./handleEscapeControlChar";

export function handleSingleColumnFilter(filter: string, searchValue: string): string {
  if (searchValue === "") return "";
  let value = "";

  const escapedControlChars = escapeControlCharacters(searchValue);
  let escapedSearchValue = escapedControlChars.trim();

  value = `"${filter}":{"_ilike": ${JSON.stringify(
    escapedSearchValue.replaceAll("\\", "\\\\") + "%"
  )}}`;

  return value;
}
