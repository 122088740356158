import { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IndustryProductPartFragment, ProductDetailPartFragment, SellerProductPartFragment } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { SelectRef } from "../../common/components/CustomSelect";
import { SearchSelect } from "../../common/components/SearchSelect";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid } from "../../common/miscellaneous/utility";
import { SelectOption } from "../../common/types/types";
import { FormInput } from "../../forms/components/FormInput";
import { useMainContext } from "../../layout/components/MainProvider";
import { CategoryFamilies } from "./CategoryFamilies";

interface IProps {
  productData?: ProductDetailPartFragment | SellerProductPartFragment | IndustryProductPartFragment;
  editable: boolean;
  dsOpEdit?: boolean;
  setCategory: (ctgId: string) => void;
}

interface ICategoryStatus {
  ctgId: string;
  ctgname: string;
  editType: "" | "regular" | "dsUnctged" | "dsOpEdit";
}

export const DetailsOpSection: FC<IProps> = (props): ReturnType<FC> => {
  const { productData, editable, dsOpEdit, setCategory } = props;

  const [context] = useMainContext();
  const userInfo = useUserInfo()!;
  const selectRef = useRef<SelectRef | null>(null);
  const { allow_customer_mpin } = context.operatorSettings.product;
  const methods = useFormContext();

  const productship = productData?.__typename?.includes("data_vw_product") ? 
   (productData as ProductDetailPartFragment) : null;
  const indProduct = productData?.__typename?.includes("data_mvw_industry_product") ? 
   (productData as IndustryProductPartFragment) : null;
  const defCtgStatus: ICategoryStatus = {
    ctgId: productData?.category_id || emptyUuid,
    ctgname: productship?.product_category || indProduct?.product_category ||"",
    editType: ""
  };
  const [ctgStatus, setCtgStatus] = useState<ICategoryStatus>(defCtgStatus);

  useEffect(() => {
    if (productship) {  
      methods.setValue("featured", productship.featured);
      methods.setValue("mpin", productship.mpin);
    }
  }, [productship]);
  
  const manageProduct = indProduct ? false : !!(userInfo.permissions! & context.permissions.manage_product);
  const dsUnctged = manageProduct && userInfo.seller_id
    && productship && productship.product_category?.includes("Unassigned");
  const opMgt = manageProduct && !userInfo.seller_id;

  const toggleCtgSelect = () => {
    const editType = ctgStatus.editType ? "" :
      dsUnctged ? "dsUnctged" : (dsOpEdit ? "dsOpEdit" : "regular");
    setCtgStatus({ ...ctgStatus, editType });
  }

  const categoryChanged = (ctg: SelectOption | null = null) => {
    let updated = { ...ctgStatus, editType: "" };
    let selected = ctg || selectRef.current?.selected.find(cur => cur);
    if (selected) {
      updated = { ...updated, ctgId: selected.value, ctgname: selected.label };
      setCategory(selected.value);
    }
    setCtgStatus(updated as ICategoryStatus);
  }

  return (
    <>
      <Card className="p-4 mb-4 mt-3">
        {productData &&
          <div className="mb-3 w-100">
            <div className="font-weight-semi-bold mb-2">Category</div>
            <div className="d-flex w-100 align-items-center">
              {["dsOpEdit", "regular"].includes(ctgStatus.editType) ?
                <SearchSelect
                  ref={selectRef}
                  placeholder={ctgStatus.ctgname || ""}
                  defVal={ctgStatus.ctgId}
                  noClear={true}
                  itemType="category"
                />
                : <div className="text-muted w-100 bg-ligth-gray px-2 py-1 border rounded">
                  {ctgStatus.ctgname || (productData as IndustryProductPartFragment)?.product_category}
                </div>}
              {(editable || dsOpEdit || dsUnctged) &&
                <>
                  <div>
                    <i className={`ms-2 bi bi-${!ctgStatus.editType ? "pencil" : "x"}`}
                      onClick={toggleCtgSelect} />
                  </div>
                  {!!ctgStatus.editType &&
                    <div>
                      <i className="ms-2 bi bi-check-lg"
                        onClick={() => categoryChanged()} />
                    </div>}
                </>}
            </div>
          </div>
        }
        {allow_customer_mpin &&
          <FormInput
            name="mpin"
            label="MPIN"
            disabled={!opMgt}
          />}
        {productship && <FormInput
          name="featured"
          label="Featured"
          type="checkbox"
          isswitch={true}
          disabled={!opMgt}
        />}
      </Card>
      {
        ctgStatus.editType === "dsUnctged" && productship && 
        <CategoryFamilies
          productData={productship}
          setCtg={(ctg: SelectOption | null) => categoryChanged(ctg)}
        />
      }
    </>
  );
};
