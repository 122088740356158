import { Document, Page, Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from "dayjs";
import * as React from "react";
import { FC } from "react";
import {
    Transactions_Transaction
} from "../../../../generated/urql-graphql";
import { ITransactionUI } from "../../../common/types/types";
import { addCommaFunc } from "../../../orders-route/handler/handler";
import { IBillingStatement, IImageInfo, PDFProps } from "../../misc/types";



const styles = StyleSheet.create({
    page: {
        margin: 20,
        fontSize: "10pt",
        color: "#444",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    heading: {
        fontSize: "14pt",
        marginBottom: 10,
        color: "#222",
        fontFamily: "Helvetica-Bold"
    },
    headingSm: {
        fontSize: "12pt",
        marginBottom: 10,
        marginTop: 10,
        color: "#222",
        fontFamily: "Helvetica-Bold"
    },
    textStyle: { paddingBottom: 2 },
    textStyleToDo: { backgroundColor: "yellow" },

    tranContainer: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 6,
        paddingTop: 4,
        textAlign: "right",
        fontSize: "9pt",
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    tranDetails: {           
        marginLeft: 5,       
        fontSize: "9pt",
        textAlign: "left",
        width:73
    },
    tranDetailsLast: {        
        marginLeft: 5,
        border: "solid 1px green",
        fontSize: "9pt",
        textAlign: "right",
        width: 73
    },

    tranDetailsRed: {       
        marginLeft: 5,       
        fontSize: "9pt",
        color: "red",
        textAlign: "left",
        width: 73
    },

    tranDetails2: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 5,
        fontSize: "9pt",
        textAlign: "left",       
        width: 150,
        paddingRight:10
    },

    tranHeader: {
        display: "flex",
        flexDirection: "row",
        padding: 5,
        paddingTop: 10,
        paddingBottom:10,
        fontFamily: "Helvetica-Bold",
        fontSize: "9pt",
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        borderTopWidth: 1,
        borderTopColor: '#ccc',
    },
    tranDetailsHeader: {        
        marginLeft: 5,
        width: 73,
        fontSize: "9pt",
        textAlign: "left",
        fontFamily: "Helvetica-Bold"
    },
    tranDetailsHeaderLast: {          
        marginLeft: 5,
        width: 73,
        fontSize: "9pt",
        textAlign: "right",
        fontFamily: "Helvetica-Bold"
    },
    tranDetailsHeader2: {       
        marginLeft: 5,
        width: 150,
        fontSize: "9pt",
        textAlign: "left",
        fontFamily: "Helvetica-Bold"
    },
});


export const PdfHeader = (props: {
    billing_statement: IBillingStatement,
    timeZone: string,
    pageNumber: number,
    logoImage: IImageInfo
}): ReturnType<FC> => {
    const b_stmt = props.billing_statement;
    const seller = props.billing_statement.stmtSeller;
    const imageInfo = props.logoImage;
    const time_zone = props.timeZone;
   // console.log("generating header");

    return (<>
        <View style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
            <View style={{ flex: 2 }}>
                <View style={{ height: 50, textAlign: 'left', width: '50%', marginBottom:5}}>
                    <PdfImage src={imageInfo.imageURL}
                        style={{ width: imageInfo.imageWidth, height: imageInfo.imageHeight }}
                    />
                </View>
            <Text style={{marginBottom:14}}>{seller.company_name}</Text>
            <Text style={styles.headingSm}>To:</Text>
            <Text style={styles.textStyle}>{`${seller.first_name} ${seller.last_name}`}</Text>
            <Text style={styles.textStyle}>{seller.company_name}</Text>
            <Text style={styles.textStyle}>{seller.line1}</Text>
            <Text style={styles.textStyle}>{seller.line2}</Text>
            <Text style={styles.textStyle}>{seller.city}, {seller.state} { seller.zipcode}</Text>
            <Text style={styles.textStyle}>{ seller.phone}</Text>
        </View>
        <View style={{ flex: 1, marginTop: 26 }}>
            <Text style={styles.textStyle}>Date:</Text>
            <Text style={styles.textStyle}>Billing Period:</Text>
            <Text style={styles.textStyle}>Seller ID</Text>
            <Text style={styles.textStyle}>Page</Text>

            <Text style={styles.headingSm}>Account Summary</Text>
            <Text style={styles.textStyle}>Previous Balance</Text>
            <Text style={styles.textStyle}>Debits</Text>
            <Text style={styles.textStyle}>Credits</Text>
            <Text style={styles.textStyle}>Ending Balance</Text>
        </View>
        <View style={{ flex: 1, textAlign: "right", marginRight: 50 }}>
            <Text style={styles.heading}>Statement</Text>
            <Text style={styles.textStyle}>{dayjs(b_stmt.created_date).format("MMMM DD, YYYY")}</Text>
            <Text style={styles.textStyle}>
                    {dayjs(b_stmt.billing_period_from_date, time_zone).format("MM/DD/YYYY")} -{" "}
                    {dayjs(b_stmt.billing_period_to_date, time_zone).format("MM/DD/YYYY")}</Text>
            <Text style={styles.textStyle}>{seller.shop_id}</Text>
            <Text style={styles.textStyle}>{props.pageNumber}</Text>
            <Text style={{ ...styles.textStyle, marginTop: 35, justifyContent: "flex-end", paddingBottom: 2 }}>$ {addCommaFunc(b_stmt.previous_invoiced_balance ?? 0)}</Text>
            <Text style={styles.textStyle}>(${addCommaFunc(b_stmt.cp_debit_amount * (-1))})</Text>
            <Text style={styles.textStyle}>$ {addCommaFunc(b_stmt.cp_credit_amount)}</Text>
            <Text style={styles.textStyle}>$ {addCommaFunc(b_stmt.current_invoiced_balance ?? 0)}</Text>
        </View>
       
    </View>
      <hr />
        <View  style={styles.tranHeader}>
            <Text style={styles.tranDetailsHeader}>Date</Text>
            <Text style={styles.tranDetailsHeader}>Order No.</Text>
            <Text style={styles.tranDetailsHeader2}>Description</Text>
            <Text style={styles.tranDetailsHeader}>Debit</Text>
            <Text style={styles.tranDetailsHeader}>Credit</Text>
            <Text style={styles.tranDetailsHeaderLast}>Balance</Text>
        </View>
        </>
    )
};

const PdfTransactions = (props: { transactions: ITransactionUI[], timeZone: string }) => {
  const time_zone = props.timeZone;
  const formatDescription = (_desc: string | null | undefined, _transType: string) => {
        if (_desc == null) return <>{_transType}</>;

        const lines = _desc.split(" | ");
        return lines.map((line, index) => {
            return <Text key={index}>{line}</Text>;
        });
    };

    return (
        <View style={{ display: "flex", flexDirection: "column" }}>
            {props.transactions
                ? props.transactions.map((tr, index) => {
                    return (
                        <View key={index} style={styles.tranContainer}>
                            <Text style={styles.tranDetails}>{dayjs(tr.created_date, time_zone).format("MM/DD/YYYY")}</Text>
                            <Text style={styles.tranDetails}>{tr.cp_order_number_text}</Text>
                            <View style={styles.tranDetails2}>{formatDescription(tr.description, tr.transaction_type)}</View>
                            <Text style={styles.tranDetailsRed}>{(tr.debit != null) ? `(\$${addCommaFunc(tr.debit!*(-1))})` : ""}</Text>
                            <Text style={styles.tranDetails}>{(tr.credit != null) ? `\$${addCommaFunc(tr.credit!)}` : ""}</Text>
                            <Text style={styles.tranDetailsLast}>
                                $ {addCommaFunc(tr.transaction_type == "Payment"
                                    ? tr.seller_invoiced_balance
                                    : tr.seller_rolling_balance
                            )}</Text>
                        </View>
                    );
                })
                : ""}
        </View>
        )
};

const getValidImage = (img: IImageInfo) => {
    const urlLower = img.imageURL.toLowerCase();
    const validPdfImg = /\.(jpg|jpeg|png|gif)$/.test(urlLower);
    if (!validPdfImg) {
        const returnImg: IImageInfo = {
            imageURL: `${location.protocol}//${location.hostname}/blank.JPG`,
            imageWidth: 80,
            imageHeight:50
        }
        return returnImg; // return a blank image
    }
    return img;
    
}

const PdfPage = (props: {
    transactions: ITransactionUI[],
    billing_statement: IBillingStatement,
    timeZone: string,
    pageNumber: number,
    logoImage: IImageInfo
}) => {
    return (
        <Page size="A4" style={styles.page} key={props.pageNumber }>
            <PdfHeader billing_statement={props.billing_statement} pageNumber={props.pageNumber}
                logoImage={getValidImage(props.logoImage)} timeZone={props.timeZone}
            />
            <PdfTransactions transactions={props.transactions} timeZone={props.timeZone} />
        </Page>
        )
};

const PdfDoc = (props: PDFProps) => {
    const b_stmt = props.billing_statement;
    const transactions = b_stmt.statement_transactions;
    const pageSize = 15;
    const pages: React.ReactElement[] = [];
    let pageCounter = 1;
    const adjustTransactions = (transactions: Transactions_Transaction[]) => {
        return transactions.map((tr) => {
            return {
                ...tr,
                debit: tr.amount < 0 ? tr.amount : null,
                credit: tr.amount >= 0 ? tr.amount : null,
            };
        });
    };


    if (transactions == null || transactions.length === 0) {
        pages.push(PdfPage({
            transactions: [], billing_statement: b_stmt,
            timeZone: props.timeZone,
            pageNumber: pageCounter, logoImage: props.logoImage
        }))
    }
    else {
        const transactionsUI: ITransactionUI[] = adjustTransactions(b_stmt.statement_transactions!);

        for (let i = 0; i < transactionsUI.length; i += pageSize) {
            let pageTransactions = transactionsUI.slice(i, (i + pageSize));
            pages.push(PdfPage({
                transactions: pageTransactions, billing_statement: b_stmt,
                timeZone: props.timeZone,
                pageNumber: pageCounter, logoImage: props.logoImage
            }))
            pageCounter++;
        }
    }
    return (  
        <Document>
            {pages}       
       </Document>
   );
}


export const PDFStatement = (props: PDFProps) => {
     return (
         <PdfDoc billing_statement={props.billing_statement}
             timeZone={props.timeZone}
             logoImage={props.logoImage}/>
    );

}


export const BlankPDF = () => {
    return (
        <Document>        
        </Document>
        )
}




