import { FC } from "react";

export const Error403: FC = () => {
  return (
    <>
      <div className="error-page d-flex flex-column justify-content-center align-items-center">
        <h1 className="error-number">403</h1>
        <p className="mini-text">Access denied</p>
        <p className="error-text mb-4 mt-1">
          Sorry, but you do not have permissions to acces this page!
        </p>
      </div>
    </>
  );
};
