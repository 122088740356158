import { FC, useState } from "react";
import { Card } from "../../common/components/Card";
import { ChartPlaceHolder } from "../../common/components/ChartPlaceHolder";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { TextArea } from "../../forms/components/Textarea";
import "../scss/design.scss";

export const DesignRoute: FC = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [radio, setRadio] = useState<boolean>(false);
  const columns = ["Column A", "Column B", "Column C"];

  const generateRandomCol = (): string => {
    const randomNumber = Math.floor(Math.random() * (12 - 3 + 1)) + 3;
    return `height-${randomNumber}`;
  };

  return (
    <>
      <div>
        <h1 className="mt-3 mb-4">Headings</h1>
        <hr />
        <section className="headings">
          <h1>H1 Heading</h1>
          <p className="display-7">2rem - 32px</p>
          <h2>H2 Heading</h2>
          <p className="display-7">1.75rem - 28px</p>
          <h3>H3 Heading</h3>
          <p className="display-7">1.51rem - 24px</p>
          <h4>H4 Heading</h4>
          <p className="display-7">1.32rem - 21px</p>
          <h5>H5 Heading</h5>
          <p className="display-7">1.125rem - 20px</p>
        </section>
        <h1 className="mt-3 mb-4">Displays</h1>
        <hr />
        <section className="displays-container">
          <p className="display-1">Display 1</p>
          <p className="display-7">2rem - 32px</p>
          <p className="display-2">Display 2</p>
          <p className="display-7">1.75rem - 28px</p>
          <p className="display-3">Display 3</p>
          <p className="display-7">1.5rem - 24px</p>
          <p className="display-4">Display 4</p>
          <p className="display-7">1.3125rem - 21px</p>
          <p className="display-5">Display 5</p>
          <p className="display-7">1.125rem - 18px</p>
          <p className="">Normal</p>
          <p className="display-7">1rem - 16px</p>
          <p className="display-6">Display 6</p>
          <p className="display-7">0.875rem - 14px</p>
          <p className="display-7">Display 7</p>
          <p className="display-7">0.75rem - 12px</p>
        </section>
        <h1 className="mt-3 mb-4">Weights</h1>
        <hr />
        <section>
          <div className="displays-weights">
            <p className="display-1 font-weight-light">Display 1 light</p>
            <p className="display-1 ">Display 1 normal</p>
            <p className="display-1 font-weight-medium">Display 1 medium</p>
            <p className="display-1 font-weight-semi-bold">Display 1 semi</p>
            <p className="display-1 font-weight-bold">Display 1 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-2 font-weight-light">Display 2 light</p>
            <p className="display-2 ">Display 2 normal</p>
            <p className="display-2 font-weight-medium">Display 2 medium</p>
            <p className="display-2 font-weight-semi-bold">Display 2 semi</p>
            <p className="display-2 font-weight-bold">Display 2 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-3 font-weight-light">Display 3 light</p>
            <p className="display-3 ">Display 3 normal</p>
            <p className="display-3 font-weight-medium">Display 3 medium</p>
            <p className="display-3 font-weight-semi-bold">Display 3 semi</p>
            <p className="display-3 font-weight-bold">Display 3 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-4 font-weight-light">Display 4 light</p>
            <p className="display-4 ">Display 4 normal</p>
            <p className="display-4 font-weight-medium">Display 4 medium</p>
            <p className="display-4 font-weight-semi-bold">Display 4 semi</p>
            <p className="display-4 font-weight-bold">Display 4 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-5 font-weight-light">Display 5 light</p>
            <p className="display-5 ">Display 5 normal</p>
            <p className="display-5 font-weight-medium">Display 5 medium</p>
            <p className="display-5 font-weight-semi-bold">Display 5 semi</p>
            <p className="display-5 font-weight-bold">Display 5 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-6 font-weight-light">Display 6 light</p>
            <p className="display-6 ">Display 6 normal</p>
            <p className="display-6 font-weight-medium">Display 6 medium</p>
            <p className="display-6 font-weight-semi-bold">Display 6 semi</p>
            <p className="display-6 font-weight-bold">Display 6 bold</p>
          </div>
          <div className="displays-weights">
            <p className="display-7 font-weight-light">Display 7 light</p>
            <p className="display-7 ">Display 7 normal</p>
            <p className="display-7 font-weight-medium">Display 7 medium</p>
            <p className="display-7 font-weight-semi-bold">Display 7 semi</p>
            <p className="display-7 font-weight-bold">Display 7 bold</p>
          </div>
        </section>
        <h1 className="mt-3 mb-4">Text Align</h1>
        <hr />
        <section>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-1">Display 1 left</p>
            </div>
            <div className="text-container">
              <p className="display-1 text-center">Display 1 center</p>
            </div>
            <div className="text-container">
              <p className="display-1 text-right">Display 1 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-2">Display 2 left</p>
            </div>
            <div className="text-container">
              <p className="display-2 text-center">Display 2 center</p>
            </div>
            <div className="text-container">
              <p className="display-2 text-right">Display 2 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-3">Display 3 left</p>
            </div>
            <div className="text-container">
              <p className="display-3 text-center">Display 3 center</p>
            </div>
            <div className="text-container">
              <p className="display-3 text-right">Display 3 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-4">Display 4 left</p>
            </div>
            <div className="text-container">
              <p className="display-4 text-center">Display 4 center</p>
            </div>
            <div className="text-container">
              <p className="display-4 text-right">Display 4 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-5">Display 5 left</p>
            </div>
            <div className="text-container">
              <p className="display-5 text-center">Display 5 center</p>
            </div>
            <div className="text-container">
              <p className="display-5 text-right">Display 5 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-6">Display 6 left</p>
            </div>
            <div className="text-container">
              <p className="display-6 text-center">Display 6 center</p>
            </div>
            <div className="text-container">
              <p className="display-6 text-right">Display 6 right</p>
            </div>
          </div>
          <div className="text-alignment">
            <div className="text-container">
              <p className="display-7">Display 7 left</p>
            </div>
            <div className="text-container">
              <p className="display-7 text-center">Display 7 center</p>
            </div>
            <div className="text-container">
              <p className="display-7 text-right">Display 7 right</p>
            </div>
          </div>
        </section>
        <h1 className="mb-3">Buttons</h1>
        <div className="row">
          <div className="col">
            <Card>
              <h5>Default Buttons</h5>
              <p className="text-muted">
                Use the button classes on <code>&lt;Link&gt;</code> or <code>&lt;Button&gt;</code>{" "}
                components.
              </p>
              <div className="button-wrapper row">
                <div className="col-12">
                  <div className="d-flex gap-2">
                    <Button data-testid="" type="button" className="btn btn-primary">
                      Primary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-secondary">
                      Secondary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-primary" disabled>
                      Disabled
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-success">
                      Success
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-info">
                      Info
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-warning">
                      Warning
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-danger">
                      Danger
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-light">
                      Light
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <h5>Outline Buttons</h5>
              <p className="text-muted">
                Use the class <code>btn-outline-*</code> to quickly create outline buttons.
              </p>
              <div className="button-wrapper row">
                <div className="col-12">
                  <div className="d-flex gap-2">
                    <Button data-testid="" type="button" className="btn btn-outline-primary">
                      Primary Alt
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-outline-secondary">
                      Secondary Alt
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col">
            <Card>
              <h5>Buttons with icon</h5>
              <p className="text-muted">Place an icon on the left side of any button.</p>
              <div className="button-wrapper row">
                <div className="col-12">
                  <div className="d-flex gap-2">
                    <Button data-testid="" type="button" className="btn btn-primary">
                      <i className="bi bi-clipboard-check me-1"></i>
                      Primary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-secondary">
                      <i className="bi bi-clipboard-check me-1"></i>
                      Secondary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-outline-primary">
                      <i className="bi bi-clipboard-check me-1"></i>
                      Primary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-outline-secondary">
                      <i className="bi bi-clipboard-check me-1"></i>
                      Secondary
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-primary" disabled>
                      <i className="bi bi-clipboard-check me-1"></i>
                      Disabled
                    </Button>
                    <Button data-testid="" type="button" className="btn btn-danger">
                      <i className="bi bi-trash-fill me-1"></i>
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <h5>Icon Button</h5>
              <p className="text-muted">Icon only buttons.</p>
              <section className="buttons">
                <Button data-testid="" type="button" className="btn btn-primary">
                  <i className="bi bi-clipboard-check"></i>
                </Button>
                <Button data-testid="" type="button" className="btn btn-secondary">
                  <i className="bi bi-clipboard-check me-1"></i>
                </Button>
                <Button data-testid="" type="button" className="btn btn-outline-primary">
                  <i className="bi bi-clipboard-check"></i>
                </Button>
                <Button data-testid="" type="button" className="btn btn-outline-secondary">
                  <i className="bi bi-clipboard-check"></i>
                </Button>
                <Button data-testid="" type="button" className="btn btn-primary" disabled>
                  <i className="bi bi-clipboard-check"></i>
                </Button>
              </section>
            </Card>
            <Card>
              <h5>Buttons Sizes</h5>
              <p className="text-muted">
                Add <code>btn-lg</code> or <code>btn-sm</code> to create different button sizes.
              </p>
              <section className="buttons">
                <Button data-testid="" type="button" className="btn btn-primary btn-lg">
                  Large
                </Button>
                <Button data-testid="" type="button" className="btn btn-secondary btn-md">
                  Default
                </Button>
                <Button data-testid="" type="button" className="btn btn-outline-primary btn-sm">
                  Small
                </Button>
              </section>
            </Card>
          </div>
        </div>
      </div>
      <hr />
      <h1 className="mt-3 mb-4">Form Elements</h1>
      <div className="row">
        <div className="col-6">
          <Card>
            <h5>Input Types</h5>
            <section className="">
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2" data-testid="">
                  Text
                </Label>
                <Input data-testid="" ref={null} className="form-control" />
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold required mb-2" data-testid="">
                  Label Required
                </Label>
                <Input data-testid="" ref={null} className="form-control" />
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2" data-testid="">
                  Disabled
                </Label>
                <Input data-testid="" ref={null} className="form-control" disabled />
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2" data-testid="">
                  With placeholder
                </Label>
                <Input
                  data-testid=""
                  ref={null}
                  className="form-control"
                  placeholder="placeholder"
                />
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2" data-testid="">
                  Input with label
                </Label>
                <div className="input-group">
                  <span className="input-group-text">category.</span>
                  <Input data-testid="" ref={null} className="form-control" />
                </div>
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2 required" data-testid="">
                  Required
                </Label>
                <Input data-testid="" ref={null} className="form-control is-invalid" />
                <p className="small text-danger">
                  {" "}
                  <i className="bi bi-exclamation-circle-fill"></i> Please fill out this field
                </p>
              </div>
              <div className="mb-3">
                <Label className="font-weight-semi-bold mb-2" data-testid="">
                  Description
                </Label>
                <TextArea data-testid="" ref={null} className="form-control" />
                <p className="small text-muted">a block of help text.</p>
              </div>
            </section>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <h5>Checks &amp; radios</h5>
            <section className="mb-4">
              <div className="row">
                <div className="col">
                  <p>Checkboxes</p>
                  <div>
                    <Input data-testid="" ref={null} className="form-check-input" type="checkbox" />
                    <Label data-testid="" className="form-check-label ms-2">
                      Default Checkbox
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked
                    />
                    <Label data-testid="" className="form-check-label ms-2">
                      Checked checkbox
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className={`form-check-input ${!checked && "border-danger"}`}
                      type="checkbox"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <Label
                      data-testid=""
                      className={`form-check-label ms-2 ${!checked && "text-danger"}`}
                    >
                      {checked ? "Good" : "Error Checkbox"}
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className="form-check-input"
                      type="checkbox"
                      disabled
                    />
                    <Label data-testid="" className="form-check-label ms-2">
                      Disable Checkbox
                    </Label>
                  </div>
                </div>
                <div className="col">
                  <p>Radios</p>
                  <div>
                    <Input data-testid="" ref={null} className="form-check-input" type="radio" />
                    <Label data-testid="" className="form-check-label ms-2">
                      Default radio
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className="form-check-input"
                      type="radio"
                      defaultChecked
                    />
                    <Label data-testid="" className="form-check-label ms-2">
                      Checked radio
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className={`form-check-input ${!radio && "border-danger"}`}
                      type="radio"
                      checked={radio}
                      onChange={(e) => setRadio(e.target.checked)}
                    />
                    <Label
                      data-testid=""
                      className={`form-check-label ms-2 ${!radio && "text-danger"}`}
                    >
                      {radio ? "Good" : "Error radio"}
                    </Label>
                  </div>
                  <div>
                    <Input
                      data-testid=""
                      ref={null}
                      className="form-check-input"
                      type="radio"
                      disabled
                    />
                    <Label data-testid="" className="form-check-label ms-2">
                      Disable radio
                    </Label>
                  </div>
                </div>
              </div>
            </section>
            <h5>Switches</h5>
            <p className="text-muted">
              A switch has the markup of a custom checkbox but uses the <code>.form-switch</code>{" "}
              class to render a toggle switch.
            </p>
            <div className="form-switch">
              <div className="">
                <Input
                  data-testid=""
                  ref={null}
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked
                />
                <Label data-testid="" className="form-check-label ms-2">
                  Enabled switch checkbox input
                </Label>
              </div>
              <div className="">
                <Input
                  data-testid=""
                  ref={null}
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked
                  disabled
                />
                <Label data-testid="" className="form-check-label ms-2">
                  Enabled switch checkbox input
                </Label>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <hr />
      <section className="mb-4">
        <h1>Placeholders</h1>
        <p className="text-muted">
          Use loading placeholders to indicate something may still be loading.
        </p>
        <div className="row">
          <div className="col">
            <Card>
              <h3>Table Placeholders</h3>
              <hr />
              <TablePlaceHolder columnNames={columns} numberOfRows={5} />
            </Card>
          </div>
          <div className="col">
            <Card>
              <h3>Chart Placeholders</h3>
              <hr />
              <ChartPlaceHolder numberOfBars={12} title="Revenue" showMetrics={true} />
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};
