import { FC, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  OrderItemPartFragment,
  TrackingItemPartFragment,
  useInsertTrackingItemMutation,
  useUpdateTrackingItemMutation
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { OrderItemBriefDescription, footerText } from "./OrderItems";
import { ShippingItem } from "./TrackingInfoTabe";

interface IProp {
  orderItems: OrderItemPartFragment[];
  availableQuantity: { [key: string]: number };
  close: () => void;
  trackingItem?: TrackingItemPartFragment;
  orderId?: string;
  carriers: SelectOption[];
}

interface IAvailItem {
  orderItem: OrderItemPartFragment;
  maxQty: number;
}

const TrackingDetails: FC<IProp> = ({
  orderItems,
  availableQuantity,
  trackingItem,
  close,
  orderId,
  carriers
}) => {
  const updateMutation = useUpdateTrackingItemMutation()[1];
  const insertMutation = useInsertTrackingItemMutation()[1];
  const [errMsg, setErrMsg] = useState<string>("");
  const availItems: IAvailItem[] = trackingItem ?
    trackingItem.shipping_items.map((shpitem: ShippingItem) => {
      const orderItem = orderItems.find(oitem => oitem?.id === shpitem.order_item_id);
      return {
        orderItem,
        maxQty: shpitem.quantity + (availableQuantity[orderItem?.id as string] || 0)
      }
    })
    :
    orderItems.filter(item => item.trg_status === "Accepted" && availableQuantity[item?.id])
      .map(orderItem => ({ orderItem, maxQty: availableQuantity[orderItem?.id as string] || 0 }));

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: trackingItem
  });
  const {
    handleSubmit,
    formState: { errors },
    getValues
  } = methods;

  const onError = (errs: any) => {
    getShippingItems();
  }

  const getShippingItems = () => {
    const qties = getValues("shipping_items");
    let shpItems = qties.filter(
      (item: ShippingItem) => Number(item.quantity) > 0);
    if (!shpItems.length) {
      setErrMsg("At least one of order item quantities must be greater than 0");
    }
    else if (!trackingItem) {
      const temps = availItems.map((item: IAvailItem, index: number) => (
        { order_item_id: item.orderItem.id, quantity: qties[index].quantity }));
      shpItems = temps.filter(
        (item: ShippingItem) => Number(item.quantity) > 0);
    }
    return shpItems;
  }

  const onSubmit = async (data: FieldValues) => {
    const shipping_items = getShippingItems();
    if (!shipping_items.length) {
      return;
    }
    const trkItem = { ...data, shipping_items };
    let res;
    if (trackingItem) { //update
      res = await updateMutation({
        id: trackingItem?.id,
        trackingItem: { ...trkItem }
      });
    }
    else {//create
      res = await insertMutation({
        trackingItem: { ...trkItem, order_id: orderId }
      });
    }

    if (!res?.error) {
      close();
      alertsRef.current?.generate(mutationInfo(
        "tracking item",
        trackingItem ? MutationAction.Update : MutationAction.Create,
        res));
    }
    else {
      setErrMsg(res.error.message);
    }
  };

  const keydownfunc = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    evt.key === "." && evt.preventDefault();
    evt.key === "e" && evt.preventDefault();
    evt.key === "-" && evt.preventDefault();
  };

  const continueAction = async (isContinue: boolean) => {
    if (!isContinue)
      closeDialog();
  }

  const closeDialog = () => {
    errMsg && setErrMsg("");
    close();
  }

  return (
    <Dialog
      title={`${trackingItem ? "Edit" : "Add"} Tracking Information`}
      continueText="Confirm"
      continue={continueAction}
      subFormId="manage-tracking-information"
      size="xl"
      footerText={footerText}
      errorMsg={errMsg}
    >
      <FormProvider {...methods}>
        <Form
          data-testid="manage-tracking-information"
          id="manage-tracking-information"
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="modal-body p-3">
            <div className="table-responsive mb-3">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Items</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {availItems.length && availItems.map((aItem: IAvailItem, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <OrderItemBriefDescription orderItem={aItem.orderItem} />
                        </td>
                        <td>
                          <div className="flex-fill">
                            <FormInput
                              name={`shipping_items.${index}.quantity`}
                              type="number"
                              onKeyDown={keydownfunc}
                              reg_options={{
                                min: 0,
                                max: aItem.maxQty,
                                valueAsNumber: true,
                              }}
                              measurelabel={`of ${aItem.maxQty}`}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <div className="w-50 me-2">
                <FormInput
                  name="tracking_number"
                  label="Tracking Number"
                  reg_options={{ required: true }}
                />
              </div>
              <div className="w-50 ms-2">
                <FormSelect
                  options={carriers}
                  label="Select Carrier"
                  name="carrier_key"
                  reg_options={{ required: true }}
                />
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
    </Dialog>
  );
};

export default TrackingDetails;
