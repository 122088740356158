import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { useUpdateUserJustLoginMutation } from "../../../generated/urql-graphql";
import { Error404 } from "../../common/components/Errors/Error404";
import { Loader } from "../../common/components/Loader";
import { RouterPrompt } from "../../common/components/appStatus";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { LandingPageUrl, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { DashboardRoute } from "../../dashboard-route/components/DashboardRoute";
import { DesignRoute } from "../../design-route/components/DesignRoute";
import TenantDetails from "../../tenants-route/components/TenantDetails";
import { TenantsRoute } from "../../tenants-route/components/TenantsRoute";
import { CompleteUserProfile } from "../../users-route/components/CompleteProfile";
import { GlobalMessage } from "./GlobalMessage";
import Main from "./Main";
import { useMainContext } from "./MainProvider";
import Nav from "./Nav";
import { PagesRoute } from "./PagesRoute";
import ScrollToTop from "./ScrollToTop";
import { TermsConditionsPage } from "./TermsConditionsPage";

export const MainRoute: FC = () => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo?.tenant_url_tag ?? "";
  const rootRedirect = userInfo.user_level! >= 30 ? "/tenants" : `/${tenantUrlTag}`;
  const [, updateUserJustLogin] = useUpdateUserJustLoginMutation();
  const justLogin = !isNullOrEmpty(userInfo?.user_id)
    && window.localStorage.getItem("user-login") === "true";
  const customerLogin = justLogin && userInfo.user_level! <= 20;
  const profileIncomplete = customerLogin && isNullOrEmpty(userInfo.full_name);
  //const termsIncomplete = customerLogin && userInfo.info_obj.accepted_tos !== "true";
  const termsIncomplete = customerLogin && userInfo.seller_id && userInfo.info_obj.accepted_tos !== "true";

  const sellerId = userInfo.seller_id
  const userProfilePageName = useUserProfile().page_name!;
  const [landingPage, setLandingPage] = useState<string>("");

  useEffect(() => {
    const doUpdateUserJustLogin = async () => {
      await updateUserJustLogin({ userId: userInfo?.user_id });
    };

    if (justLogin || userProfilePageName) {
      justLogin && doUpdateUserJustLogin();
      let landing = "";
      if (termsIncomplete)
        landing = `${rootRedirect}/agreement`;
      else if (profileIncomplete)
        landing = `${rootRedirect}/complete-profile`;
      else if (userProfilePageName)
        landing = LandingPageUrl(userProfilePageName, tenantUrlTag, userInfo);

      landing && setLandingPage(landing);
    }

  }, [justLogin, userProfilePageName]);

  if (isNullOrEmpty(userInfo.user_id))
    return <Loader />;

  const Layout = () => {
    return (
      (profileIncomplete || termsIncomplete) ?
        <Outlet />
        :
        <ScrollToTop>
          <Nav />
          <Main />
          <RouterPrompt />
        </ScrollToTop>);
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/"
          element={<Navigate to={rootRedirect} replace />}
          errorElement={<Error404 />}
        >
        </Route>
        <Route path="/tenants" element={<Layout />}>
          <Route index element={<TenantsRoute />} />
          <Route path="archived" element={<TenantsRoute />} />
          <Route path="add-tenant" element={<TenantDetails isAddForm={true} />} />
          <Route path=":tenantParam" element={<TenantDetails isAddForm={false} />} />
          <Route path="*" element={<Error404 />} />
        </Route>
          <Route path={`/${tenantUrlTag}`} element={<Layout />}>
            {landingPage ?
                <>
                    <Route index element={<Navigate to={landingPage} replace />} />
                    {!sellerId && <Route path={`dashboard`} element={<DashboardRoute />} />}
                </>
                :
                sellerId ? <Route index element={<Navigate to={`sellers/${sellerId}`} replace />} />
                    : <Route index element={<Navigate to={`dashboard`} replace />} />
            }
          {termsIncomplete && <Route path={`agreement`} element={<TermsConditionsPage />} />}
          {profileIncomplete && <Route path={`complete-profile`} element={<CompleteUserProfile />} />}
          <Route path="*" element={<PagesRoute />} />
        </Route>
        <Route path="design" element={<DesignRoute />} />
      </Route>
    )
  );

  return (
    <>
      <GlobalMessage />
      <RouterProvider router={router} />
    </>);
}