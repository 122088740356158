import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePimProcessLogCountSubscription, usePimProcessLogSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { ELoadingType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";

const PIMHistoryList = () => {
    const [context] = useMainContext()
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const { number_items_per_page } = context.operatorSettings.preset;
    const [loading, setLoading] = useState<number>(-1);
    const navigate = useNavigate();
    const formatDate = useDateFormat();
    const [offset, setOffset] = useState<number>(0);
    const inputs = {
        variables: {
            limit: number_items_per_page,
            offset: offset
        }
    }

    const [queried] = usePimProcessLogSubscription(inputs)
    const [countQueried] = usePimProcessLogCountSubscription()
    const tableColumns = [
        "Created Date",
        "Consuming Tenant",
        "Details"
    ]
    useEffect(() => {
        if (queried.data) {
            setLoading(ELoadingType.None);
        }
    }, [queried.data]);

    const error = queried.error || countQueried.error;
    if (error) {
        return <PageError error={{ source: "ManufacturerView", errMsg: error.message }} />;
    }
    if (!queried.data) {
        return (
            <>
                <Card>
                    <PlaceholderTableSearchItem />
                    <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
                </Card>
            </>
        );
    }
    const totalCount = countQueried.data?.pim_hub_pim_process_log_aggregate.aggregate?.count
    const tableData = queried.data.pim_hub_pim_process_log.map(pimProcessLog => {
        return {
            id: pimProcessLog.id,
            Created_Date: (
                <div className="date-created">
                    <p className="mb-0">{formatDate(pimProcessLog.created_date)}</p>
                    <span className="small text-muted">{formatDate(pimProcessLog.created_date, "h:mm:ss A")}</span>
                </div>
            ),
            Consuming_Tenant: pimProcessLog.cp_operator_name,
            Details: <p className="me-3 mb-0">
                {pimProcessLog.message_type == 'error' && <small className="me-2 tooltip-custom top">
                    <span className="tooltip-text">This step in the sync failed for some reason
                    </span>
                    <i className="bi bi-exclamation-circle text-danger"></i>
                </small>}
                {pimProcessLog.log_message}
            </p>
        }
    })
    return (
        <>
            <ReturnHeader
                title="PIM Sync History"
                description="View the history of syncing data from this PIM to tenants."
            />
            <Card>
                <Table
                    columnNames={tableColumns}
                    data={tableData}
                    offset={offset}
                    setOffset={setOffset}
                    loading={loading}
                    setLoading={setLoading}
                    totalRecords={totalCount || 0}
                />
            </Card>
        </>
    )
}

export default PIMHistoryList