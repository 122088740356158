import { FC } from "react";
import { A } from "../../forms/components/A";
import { Button } from "../../forms/components/Button";

export interface ActionItemType {
  actionType: string;
  id: string;
  display?: string;
  name?: string;
  path?: string;
  actionFunc?: (id: string) => void;
  icon?: string; //For show icons
  disabled?: boolean;
}
interface PropsType {
  items: ActionItemType[];
}
const DropdownItems: FC<PropsType> = (props) => {
  //const [display, setDisplay] = useState<boolean>(false);
  // const threeDotButton = useRef<HTMLElement>(null);
  // const dropdown = useRef<HTMLUListElement>(null);
  // const handler = (event: MouseEvent) => {
  //   if (!threeDotButton.current || threeDotButton.current !== event.target) {
  //     setDisplay(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("click", handler);
  //   return () => {
  //     window.removeEventListener("click", handler);
  //   };
  // });

  const downloadActionItem = (item: ActionItemType, index: number) => {
    return (
      <li
        data-testid={`download-action-${item.id}`}
        key={index}
        className="dropdown-item py-2 no-decoration"
      >
        {!item.disabled ? (
          <A
            data-testid={`download-action-${item.id}`}
            download={item.name || ""}
            href={item.path || ""}
          >
            <i className="bi bi-cloud-arrow-down me-2"></i>Download
          </A>
        ) : (
          <span>
            <i className="bi bi-cloud-arrow-down me-2"></i>Download
          </span>
        )}
      </li>
    );
  };
  const deleteActionItem = (item: ActionItemType, index: number) => {
    return (
      <li
        key={index}
        // data-testid={`delete-action-${item.id}`}
        onClick={() => {
          if (!item.disabled && item.actionFunc) item.actionFunc(item.id);
        }}
        //  className={`${item.disabled ? "delete-disabled " : ""}`}
      >
        <Button
          key={index}
          data-testid={`delete-action-${item.id}`}
          className="dropdown-item"
          disabled={item.disabled}
          // className={`dropdown-item ${
          //   item.disabled ? "disabled" : ""
          // }`}
          type="button"
        >
          <i className={`bi bi-trash me-2 ${item.disabled ? "disabled" : ""}`}></i>Delete
        </Button>
      </li>
    );
  };
  const regularActionItem = (item: ActionItemType, index: number) => {
    return (
      <li
        data-testid={`${item.actionType}-action-${item.id}`}
        key={index}
        onClick={() => {
          !item.disabled && item.actionFunc && item.actionFunc(item.id);
        }}
      >
        <Button
          data-testid={`${item.actionType}-button-${item.id}`}
          className="dropdown-item"
          type="button"
          disabled={item.disabled}
        >
          {item.icon ? <i className={`${item.icon} me-2`}></i> : null}
          <span>{item.display ?? item.actionType}</span>
        </Button>
      </li>
    );
  };
  const generateContent = (item: ActionItemType, index: number) => {
    switch (item.actionType) {
      case "delete":
        return deleteActionItem(item, index);
      case "download":
        return downloadActionItem(item, index);
      default:
        return regularActionItem(item, index);
    }
  };
  return (
    <div className={`dropdown-wrapper text-center`}>
      <Button
        type="button"
        data-testid="Action_Button"
        className="btn btn-clean"
        title="Action button"
        aria-expanded="false" // for accessibility
        data-bs-toggle="dropdown" // use BS data-bs-toggle to trigger dropdown-menu
      >
        <i className="bi bi-three-dots"></i>
      </Button>
      <ul className="dropdown-menu dropdown-menu-end">
        {props.items.map((value, index) => generateContent(value, index))}
      </ul>
    </div>
  );
};

export default DropdownItems;
