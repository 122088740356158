import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  SellerDataPartFragment,
  Seller_Update_Column,
  useCreateSellerMutation,
  useUpdateSellerDetailMutation
} from "../../../generated/urql-graphql";
import { Address } from "../../common/components/Address";
import { Card } from "../../common/components/Card";
import { Contact } from "../../common/components/Contact";
import { Dialog } from "../../common/components/Dialog";
import { appRef } from "../../common/components/appStatus";
import { useBillingList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadAppData } from "../../common/miscellaneous/storage";
import { getFileExt, isoFormatDate, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { SellerStatus } from "../miscellaneous/types";

type Props = {
  sellerDetail?: SellerDataPartFragment;
};

interface ILogoImg {
  file: File | null;
  src: string | undefined;
}

export const SellerDetails: FC<Props> = (props): ReturnType<FC> => {
  const { sellerDetail } = props;
  const addSeller = !sellerDetail;
  const navigate = useNavigate();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [{ fetching }, createMutation] = useCreateSellerMutation();
  const [{ fetching: fetchingUpdate }, updateMutation] = useUpdateSellerDetailMutation();
  const [resetLogoDialogOpen, setResetLogoDialogOpen] = useState<boolean>(false)
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const { tenant_profit_model } = context.operatorSettings.preset;

  const baseRoute = `/${tenantUrlTag}/sellers`;
  const billingData = useBillingList();
  let defLogoImg: ILogoImg = { file: null, src: sellerDetail?.logo_image_url || undefined }
  const [logoImg, setLogoImg] = useState<ILogoImg>(defLogoImg)
  const editSeller =
    userInfo.permissions & context.permissions.manage_seller ||
      userInfo.permissions & context.permissions.update_seller
      ? true
      : false;
  const disableForm =
    (userInfo.user_level! < 20 && sellerDetail?.cp_status === SellerStatus.suspended) ||
    !editSeller ||
    sellerDetail?.cp_status === SellerStatus.archived;

  const methods = useForm<SellerDataPartFragment>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { ...sellerDetail },
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    let changed = Object.keys(dirtyFields).length !== 0;

    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  const getFileUrl = async (file: File, imgType: string) => {
    const randomNumber = Math.floor(Math.random() * 100000)
    const uniqueFileName = `${imgType}_${sellerDetail?.id || userInfo.operator_id + "_seller"}_${randomNumber}${getFileExt(file.name)}`;
    return await uploadAppData(file, uniqueFileName);
  }

  //const closeFrom = watch("close_from") || currentDate();
  const closeFrom = watch("close_status.close_from");
  useEffect(() => {
    if (!sellerDetail) {
      const defBillingId = billingData.find(billing => billing.default)?.id;
      if (defBillingId) setValue("billing_period_id", defBillingId);
    }
  }, [billingData]);

  const handleImportClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let selected = event.target.files[0];
      if (selected.size > 2097152) {
        alertsRef.current?.add("Maximum allowed file size is 2MB.", "error");
        return;
      }
      if (selected.type !== "image/svg+xml" && selected.type !== "image/png") {
        alertsRef.current?.add("Only .svg, .png, .jpg are currently supported.", "error");
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(selected);
      reader.onload = function () {
        const img = { file: selected, src: reader.result as string };
        setLogoImg(img)
        setFormChanged(true)
        appRef.current?.updateStatus(true)
      }
    }
  }

  const checkValue = async (data: IHash | FieldValues, extra: IHash) => {
    let obj = _.omit(data, ["id", "created_date", "payment_method", "status_obj"]);
    if (userInfo.seller_id) {
      obj = _.omit(obj, ["absolute_rate", "markup_rate", "commission", "billing_period_id"])
    }
    else {
      obj = {
        ...obj,
        shop_id: data.shop_id || null,
        absolute_rate: data.absolute_rate || false,
        min_order_price: Number(data.min_order_price || 0),
        markup_rate: Number(data.markup_rate || 0),
        commission: Number(data.commission || 0),
      };
    }
    let logoImgInfo = { ...logoImg };
    if (logoImg.src !== defLogoImg.src) {
      const src = logoImg.file ? await getFileUrl(logoImg.file!, "logo") : logoImg.src;
      logoImgInfo = { ...logoImg, src };
      setLogoImg(logoImgInfo);
    }
    const close_status = isNaN(Date.parse(data.close_status?.close_from))
      ? null
      : {
        close_from: data.close_status.close_from,
        close_to: isNaN(Date.parse(data.close_status?.close_to)) ? null : data.close_status.close_to,
      };
    return {
      ...obj,
      logo_image_url: logoImgInfo.src,
      close_status,
      ...extra
    };
  };

  const onSubmit = async (data: FieldValues) => {
    let res;
    let action: MutationAction;
    if (addSeller) {//create seller
      action = MutationAction.Create;
      const newSeller: IHash = await checkValue(data, { "operator_id": userInfo.operator_id });
      res = await createMutation({ newSeller }, {
        additionalTypenames: ["vw_seller", "seller"],
      });
    } else { //update seller
      action = MutationAction.Update;
      let updateCols = Object.values(Seller_Update_Column);
      const objKeys = Object.keys(data);
      let sellerObj = {} as IHash;
      updateCols.filter(col => objKeys.includes(col)).forEach(col => sellerObj[col] = data[col]);
      sellerObj = await checkValue(sellerObj, { "updated_date": "now()" });

      res = await updateMutation({
        sellerId: data.id,
        sellerObj,
        contact: _.omit(data.contact, ["__typename", 'id']),
        address: _.omit(data.addresses[0], ["__typename", 'id'])
      },
        { additionalTypenames: ["vw_seller", "seller"] });
    }

    const alertAction = addSeller ? MutationAction.Create : MutationAction.Update;
    alertsRef.current?.generate(mutationInfo("seller", alertAction, res));

    if (!res?.error) {
      if (addSeller) {
        //add new seller
        appRef.current?.updateStatus(false);
        setTimeout(() => navigate(baseRoute), 300);
      } else {
        //update seller
        reset(data);
        setFormChanged(false)
        appRef.current?.updateStatus(false)
      }
    }
  };

  const continueResetLogo = () => {
    setLogoImg({ file: null, src: undefined });
    if (defLogoImg.src) {
      setFormChanged(true)
      appRef.current?.updateStatus(true)
    }
    setResetLogoDialogOpen(false)
  }

  const getCurrentDate = () => {
    return new Date().toISOString().slice(0, 10)
  }

  function MarkupRateInfo(): JSX.Element {
    return (<>
      <div className="row d-flex">
        <div className="col-sm-12 col-md-6">
          <FormInput
            name="markup_rate"
            label="Markup Rate"
            type="number"
            step="0.01"
            reg_options={{
              required: true,
              min: 0,
              max: 100,
              pattern: /^\d+(\.\d{0,2})?$/,
            }}
            disabled={disableForm || userInfo.seller_id}
            measurelabel="%"
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <FormSelect
            name="billing_period_id"
            label="Billing Period"
            options={billingData?.map((billing) => {
              return { label: billing.billing_period_name, value: billing.id };
            })}
            reg_options={{
              required: "Please select an item in the list.",
            }}
            disabled={disableForm || userInfo.seller_id}
          />
        </div>
      </div>
    </>);
  }
  function BillingInfo(): JSX.Element {
    return (<>
      <div className="row d-flex">
        <div className="col-sm-12 col-md-6">
          <FormInput
            name="commission"
            label="Commission Rate"
            type="number"
            step="0.01"
            reg_options={{
              required: true,
              min: 0,
              max: 100,
              pattern: /^\d+(\.\d{0,2})?$/,
            }}
            disabled={disableForm || userInfo.seller_id}
            measurelabel="%"
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <FormSelect
            name="billing_period_id"
            label="Billing Period"
            options={billingData?.map((billing) => {
              return { label: billing.billing_period_name, value: billing.id };
            })}
            reg_options={{
              required: "Please select an item in the list.",
            }}
            disabled={disableForm || userInfo.seller_id}
          />
        </div>
      </div>
      <FormInput
        name="absolute_rate"
        label="Absolute rate"
        type="checkbox"
        css="d-flex flex-row align-items-start"
        input_extra={
          <span className="tooltip-custom right">
            <span className="tooltip-text">Absolute commission rate will override category commission rates.
            </span>
            <i className="bi bi-info-circle-fill text-muted"></i>
          </span>
        }
        disabled={disableForm || userInfo.seller_id}
        isswitch="true"
      />
    </>);
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form
          data-testid="seller-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <Card className={addSeller ? "no-top-border" : ""}>
            <div className="card-body">
              <FormInput
                name="company_name"
                label="Name"
                reg_options={{ required: true, maxLength: 150 }}
                disabled={disableForm}
              />
              {userInfo.user_level! >= 20 && (
                <FormInput
                  name="shop_id"
                  label="Seller ID"
                  reg_options={{ maxLength: 30 }}
                //disabled={userInfo.user_level! < 20}
                />
              )}
              <FormTextarea
                name="description"
                label="Description"
                reg_options={{ maxLength: 3000 }}
                disabled={disableForm}
              />
              {(tenant_profit_model === "Markup") ?
                <MarkupRateInfo />
                :
                <BillingInfo />}
              {!addSeller &&
                <div className="my-3">
                  <div className="row d-flex">
                    <div className="col-xs-12 col-sm-6">
                      <FormInput
                        name="close_status.close_from"
                        label="Closed from"
                        type="date"
                        disabled={disableForm}
                        reg_options={{
                          min:
                            Date.parse(getCurrentDate()) > Date.parse(closeFrom || getCurrentDate())
                              ? (closeFrom || getCurrentDate())
                              : getCurrentDate(),
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <FormInput
                        name="close_status.close_to"
                        label="Closed to"
                        type="date"
                        disabled={disableForm || !closeFrom ? true : false}
                        reg_options={{ min: isoFormatDate(closeFrom, -2) }}
                      />
                    </div>
                  </div>
                </div>
              }
              <FormInput
                name="min_order_price"
                label="Minimum Order Amount"
                type="number"
                disabled={disableForm}
              />
              <div className="border-top my-3">
                <h4 className="text-uppercase mt-3 mb-0">contact details</h4>
                <small className="text-muted helper-text">
                  This information will display on the seller invoices.
                </small>
              </div>
              <Contact disableForm={disableForm} parentPrefix={`${addSeller ? "contacts.data." : "contact."}`} />
              <Address disableForm={disableForm} parentPrefix={`addresses.${addSeller ? "data." : "0."}`} />
              <FormInput
                name="website"
                label="Website"
                type="url"
                placeholder="http://yourwebsite.com"
                reg_options={{ maxLength: 65 }}
                disabled={disableForm}
              />
              <FormInput
                name="customer_service_email_address"
                label="Customer Service Email Address"
                type="email"
                reg_options={{
                  required: true,
                  maxLength: 100,
                  pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                }}
                disabled={disableForm}
                css="mb-0"
              />
              <div className="small text-muted mb-2">The reply-to address for email communication sent to your customers.</div>
              <div data-testid="" className="form-check-label" style={{ cursor: "auto" }}>
                <h4 className="subheading mb-0">Logo</h4>
                <p className="text-muted mb-0">Upload your seller logo.</p>
              </div>
              <Input
                ref={null}
                onChange={handleImportClick}
                type="file"
                data-testid="upload-logo"
                id="logo-img"
                name="logo-img"
                disabled={disableForm}
                accept=".png, .jpg, .jpeg,.svg"
                style={{ display: "none" }}
              />
              <div className="my-3 d-flex flex-column">
                <div className="seller-logo-container d-flex align-items-center mb-3">
                  {logoImg.src ? <img src={logoImg.src} alt="seller_logo_img" className="img-fluid seller-logo" /> : <></>}
                </div>
                <div className="site-logo-button-wrapper">
                  <Button
                    data-testid=""
                    className="btn btn-outline-primary me-2"
                    type="button"
                    disabled={!logoImg.src || disableForm}
                    onClick={() => setResetLogoDialogOpen(true)}
                  >
                    Reset
                  </Button>
                  <Label data-testid="" className={`btn btn-primary ${disableForm ? "disabled" : ""}`} htmlFor="logo-img">
                    Replace Logo
                  </Label>
                </div>
              </div>
            </div>
          </Card >
          <div className="mt-4 d-flex justify-content-end">
            {/* {userInfo.user_level! > 20 && (
            <NavLink data-testid="" to="/sellers" id="seller-discard-btn">
              <Button data-testid="" role="button" className="btn btn-outline-primary me-3">
                Discard
              </Button>
            </NavLink>
          )} */}

            {fetching || fetchingUpdate ? (
              <Button data-testid="" type="button" disabled className="btn btn-primary">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
            ) : (
              <Button
                data-testid=""
                disabled={!formChanged || disableForm}
                type="submit"
                className="btn btn-primary "
                id="seller-save-btn"
              >
                Save
              </Button>
            )}
          </div>
        </Form >
      </FormProvider >
      <Dialog
        show={resetLogoDialogOpen}
        title="Reset Seller Logo"
        continueText="Reset"
        continue={continueResetLogo}
      >
        <p>Are you sure you want to remove this logo?</p>
      </Dialog>
    </>
  );
};
