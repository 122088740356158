import { FC, useRef, useState } from "react";
import { PDFObject } from "react-pdfobject";
import {
  useGetDefaultTermQuery,
  useUpdateOperatorUserTosMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Checkbox } from "../../common/components/Checkbox";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { LandingPageUrl, isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { A } from "../../forms/components/A";
import { Button } from "../../forms/components/Button";
import { CompleteUserProfile } from "../../users-route/components/CompleteProfile";
import { alertsRef } from "./Main";
import { useMainContext } from "./MainProvider";

export const TermsConditionsPage: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo();
  const ref = useRef<HTMLDivElement>(null);
  const refCheckBoxTermCondition = useRef<HTMLInputElement>(null);
  const [disabledAcceptTermCondition, DisableAcceptTermCondition] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [, updateUserTOS] = useUpdateOperatorUserTosMutation();
  const [getDefaultTermQueried] = useGetDefaultTermQuery();
  const userProfile = useUserProfile();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo?.tenant_url_tag ?? "";

  if (!userInfo.user_id) {
    return <Loader />;
  }

  if (!getDefaultTermQueried.data || getDefaultTermQueried.fetching) {
    return <Loader />;
  }

  const error = getDefaultTermQueried.error;
  if (error) {
    return <PageError error={{ source: "TermsConditionsPage", errMsg: error.message }} />;
  }
  const terms = getDefaultTermQueried.data?.terms_and_conditions.find(tcs => tcs);
  if (!terms) {
    return <div>No default terms and conditions available. Please contact customer service.</div>;
  }

  const UpdateUserProfileTOSAcceptance = async (userId: string, tosObj: object) => {
    const res = await updateUserTOS({
      userId,
      operatorId: userInfo.operator_id,
      tosObj
    });
    if (!res.error) {
      const profileIncomplete = (isNullOrEmpty(userProfile.first_name) || isNullOrEmpty(userProfile.last_name));
      if (profileIncomplete)
        setShowProfile(true);
      else {
        const url = LandingPageUrl(userProfile.page_name, tenantUrlTag, userInfo);
        window.location.href = url;
      }
    } else {
      alertsRef.current?.generate(mutationInfo("terms and conditions", MutationAction.Update, res));
    }
  };
  const continueAction = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const tosObj = {
      accepted_tos: true,
      file_tos_id: terms.id
    }

    await UpdateUserProfileTOSAcceptance(userInfo.user_id, tosObj);
    window.localStorage.setItem("user-login", "false");
  };

  return (
    <>
      {!showProfile ?
        <div className="row terms-wrapper mt-4 w-100" ref={ref}>
          <div className="col-12 col-md-7 mx-auto">
            <Card className="p-4">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                  <h3 className="card-title">Terms and Conditions</h3>
                  <A
                    className="py-2 text-secondary"
                    data-testid=""
                    download={terms.terms_name}
                    href={terms.file_path ?? ""}
                  >
                    Download Terms and Conditions
                  </A>
                </div>

                <div className="col-12 mb-3">
                  <PDFObject
                    containerProps={{ className: "d-flex w-100 h-100 " }}
                    pdfOpenParams={{ zoom: "scale", view: "FitV", pagemode: "thumbs" }}
                    height="100%"
                    url={
                      terms.file_path
                        ? `${terms.file_path}#toolbar=0&zoom=100`
                        : ""
                    }
                  />
                </div>
                <div className="col-12">
                  <div className="d-flex flex-row justify-content-center mb-3">
                    <Checkbox
                      ref={refCheckBoxTermCondition}
                      data-testid=""
                      id=""
                      label="I have read and agree to the Terms and Conditions"
                      value="ok"
                      onChange={(event) => {
                        if (event.target.checked) {
                          DisableAcceptTermCondition(false);
                        } else {
                          DisableAcceptTermCondition(true);
                        }
                      }}
                    ></Checkbox>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    {" "}
                    <Button
                      data-testid=""
                      type="button"
                      className="btn btn-primary"
                      disabled={
                        disabledAcceptTermCondition || !refCheckBoxTermCondition.current?.checked
                      }
                      onClick={continueAction}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div> :
        <CompleteUserProfile />}
    </>
  );
};
