import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Vw_Report_Store_Order_By_Date,
  useReportSellerOrderByDateQuery,
  useReportSellerOrderByMonthQuery,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { Button } from "../../forms/components/Button";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { PageError } from "../../common/components/Errors";

const reportDataByMonth = [
  { key: "January", name: "January", count: 0 },
  { key: "February", name: "February", count: 0 },
  { key: "March", name: "March", count: 0 },
  { key: "April", name: "April", count: 0 },
  { key: "May", name: "May", count: 0 },
  { key: "June", name: "June", count: 0 },
  { key: "July", name: "July", count: 0 },
  { key: "August", name: "August", count: 0 },
  { key: "September", name: "September", count: 0 },
  { key: "October", name: "October", count: 0 },
  { key: "November", name: "November", count: 0 },
  { key: "December", name: "December", count: 0 },
];

interface ReportDataType {
  key: string;
  name: string;
  count: number;
}

const findByMatchingProperties = (
  reportData: Array<ReportDataType>,
  matchingReportData: ReportDataType
): ReportDataType | undefined => {
  return reportData.find((reportItem) => reportItem.key === matchingReportData["key"]);
};
const LastNDays = (nDays: number): Array<ReportDataType> => {
  const dates = [...Array(nDays)].map((_, index) => {
    const today = new Date();
    today.setDate(today.getDate() - index);
    return {
      key:
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2),
      name: ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2),
      count: 0,
    };
  });
  return dates.reverse();
};

export const SellerChart: FC = (): ReturnType<FC> => {
  const { sellerId } = useParams<{ sellerId: string }>();

  let reportData1: ReportDataType[] | undefined = [];
  let currentDate = new Date();
  //let currentDate = new Date('November 29,2022');
  let currentYear = currentDate.getFullYear();
  let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let filterLast7Condition = `{ "_gte": "${last7Days.getFullYear()}-${last7Days.getMonth() + 1
    }-${last7Days.getDate()}"}`;
  let last30Days = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  let last30DaysCondition = `{ "_gte": "${last30Days.getFullYear()}-${last30Days.getMonth() + 1
    }-${last30Days.getDate()}" }`;

  const [viewState, setViewState] = useState<string>("MTD");
  const [sellerDataId, setSellerDataId] = useState<string>(sellerId!);
  let filterSellerId = `"id": { "_eq": "${sellerDataId}" }`;

  const [reportSellerOrderByMonth] = useReportSellerOrderByMonthQuery({
    variables: {
      where: JSON.parse(
        `{
                    ${filterSellerId}, 
                    "month_tz" : { "_ilike": "%${currentYear}%"}
                }`
      ),
    },
  });
  const [reportSellerOrderMonthlyByDate] = useReportSellerOrderByDateQuery({
    variables: {
      where: JSON.parse(
        `{
                    ${filterSellerId},
                    "created_date_tz" :  ${last30DaysCondition}
                }`
      ),
    },
  });
  const [reportSellerOrderWeeklyByDate] = useReportSellerOrderByDateQuery({
    variables: {
      where: JSON.parse(
        `{
                    ${filterSellerId}, 
                    "created_date_tz" : ${filterLast7Condition}
                }`
      ),
    },
  });

  useEffect(() => {
    setSellerDataId(sellerId!);
  }, [sellerId]);



  const error = reportSellerOrderByMonth.error ||
    reportSellerOrderMonthlyByDate.error ||
    reportSellerOrderWeeklyByDate.error;
  if (error) {
    return <PageError error={{ source: "SellerChart", errMsg: error.message }} />;
  }

  if (
    !reportSellerOrderByMonth.data &&
    !reportSellerOrderMonthlyByDate.data &&
    !reportSellerOrderWeeklyByDate.data
  ) {
    return <Loader />;
  }

  // YYYY-MM-DD
  if (viewState === "YTD") {
    reportData1 = reportDataByMonth;

    reportSellerOrderByMonth.data?.vw_report_store_order_by_month.forEach((item) => {
      if (item.month_tz) {
        let monthIndex: number = parseInt(item.month_tz?.substring(5) || "");

        if (reportData1) {
          reportData1[monthIndex - 1]["count"] = item.count;
        }
      }
    });
  } else if (viewState === "MTD") {
    reportData1 = LastNDays(30);
    reportSellerOrderMonthlyByDate.data?.vw_report_store_order_by_date.forEach(
      (item: Vw_Report_Store_Order_By_Date) => {
        if (reportData1 && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData1, {
            key: item.created_date_tz,
            name: "",
            count: 0,
          });
          if (findItem) {
            findItem["name"] = item.created_date_tz.substring(5);
            findItem["count"] = item.count;
          }
        }
      }
    );
  } else if (viewState === "WTD") {
    reportData1 = LastNDays(7);
    reportSellerOrderWeeklyByDate.data?.vw_report_store_order_by_date.forEach(
      (item: Vw_Report_Store_Order_By_Date) => {
        if (reportData1 && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData1, {
            key: item.created_date_tz,
            name: "",
            count: 0,
          });
          if (findItem) {
            findItem["name"] = item.created_date_tz.substring(5);
            findItem["count"] = item.count;
          }
        }
      }
    );
  }

  const handleReportButtonClick = (event: React.MouseEvent<HTMLButtonElement>, rptType: string) => {
    if (rptType === "YTD") {
      setViewState("YTD");
    } else if (rptType === "MTD") {
      setViewState("MTD");
    } else if (rptType === "WTD") {
      setViewState("WTD");
    }

    removeActive();
    event.currentTarget.classList.add("active");
  };
  const removeActive = () => {
    let elems = document.getElementsByClassName("btn-sm");

    [].forEach.call(elems, function (el: HTMLElement) {
      el.classList.remove("active");
    });
  };

  return (
    <Card>
      <div className="total-orders-wrapper d-flex align-items-center justify-content-between mb-2">
        <h4 className="card-title">Total Orders</h4>
        <div className="btn-group" role="group">
          <Button
            data-testid=""
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "WTD");
            }}
          >
            Last 7 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className="btn btn-outline-primary btn-sm active"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "MTD");
            }}
          >
            Last 30 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "YTD");
            }}
          >
            YTD
          </Button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={500}
          height={200}
          data={reportData1}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="count" stroke="#00a3c7" fill="#00a3c7" />
          {/* <Brush /> */}
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};
