/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import {
  useDeleteShippingCarrierMutation,
  useShippingCarriersInitialQuery,
  useShippingCarriersSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ShippingCarrierForm } from "./ShippingCarrierForm";

export function ManageShippingCarriers(): JSX.Element {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings/shipping`;

  const [queried] = useShippingCarriersInitialQuery();
  const [subscribed] = useShippingCarriersSubscription({});
  const [{ fetching: deleting }, deleteMutation] = useDeleteShippingCarrierMutation();

  const defActionState = { ids: null, action: "", item: null };
  const [actionState, setActionState] = useState<IActionState>(defActionState);
  const tableColumns = ["Shipping Carrier", "Key/Code", "Enabled", "Actions"];

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "ManageShippingCarriers", errMsg: error.message }} />;
  }

  const carrierData = subscribed?.data || queried.data; 
  if (!carrierData) {
    return <Loader />;
  }

  const tableData = carrierData.shipping_carrier.map((carrier) => {
    let actions = [
      {
        actionType: "edit",
        id: carrier.id,
        icon: "bi bi-pencil",
        actionFunc: () => setActionState({ ...actionState, action: "Edit", item: carrier })
      },
      {
        actionType: "delete",
        id: carrier.id,
        icon: "bi bi-trash",
        actionFunc: () => setActionState({ ...actionState, action: "Delete", item: carrier }),
      },
    ];

    return {
      id: carrier.id,
      Shipping_Carrier: carrier.shipping_carrier,
      KeyCode: carrier.key,
      Enabled: carrier.status && <span className="bi bi-check2 text-success"></span>,
      Actions: <DropdownItems items={actions} />
    };
  });

  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await deleteMutation({
        id: actionState.item.id,
      });
      alertsRef.current?.generate(mutationInfo("shipping rate", MutationAction.Delete, res));
    }
    setActionState(defActionState);
  };

  return (
    <section>
      <ReturnHeader
        title="Manage Shipping Carriers"
        url={baseRoute}
        description="Shipping carriers are the services that deliver the customers' orders."
      >
        <Button
          data-testid=""
          className="btn btn-primary"
          onClick={() => setActionState({ ...actionState, action: "Add" })}
        >
          Add Shipping Carrier
        </Button>
      </ReturnHeader>
      <Card className="p-4">
        <Table
          columnNames={tableColumns}
          data={tableData}
        />
      </Card>
      {["Add", "Edit"].includes(actionState.action) &&
        <ShippingCarrierForm
          onClose={() => setActionState(defActionState)}
          carrier={actionState.item}
        />
      }
      <Dialog
        show={actionState.action === "Delete"}
        title="Delete Shipping Carrier"
        fetching={deleting}
        continue={continueAction}
        contineBtnCss="btn btn-danger"
        continueText="Delete"
      >
        Are you sure you want to delete this shipping carrier?
      </Dialog>
    </section>
  );
}
