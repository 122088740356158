import { FC } from "react";
import { A } from "../../forms/components/A";
import { UserProps } from "../miscellaneous/types";

export const Avatar: FC<UserProps> = ({ userDetailInfo }) => {
  const avatar = userDetailInfo.user?.profile_image || "";

  return (
    <div className="mb-3 d-flex">
      <div className="me-2">
        <div className="d-flex">
          <img
            src={avatar || "/default-user-avatar.jpeg"}
            className="rounded"
            alt="user profile"
            width="100px"
            height="100px"
            onError={(event) => {
              event.currentTarget.src = "/default-user-avatar.jpeg";
            }}
          />
        </div>
      </div>

      <div>
        <div className="font-weight-semi-bold">{userDetailInfo.full_name || "Unknown"}</div>
        <A data-testid="user-email" href={`mailto:${userDetailInfo.email}`}>
          {userDetailInfo.email}
        </A>
        <div className="font-weight-semi-bold">Role: {userDetailInfo?.role_name}</div>
      </div>
    </div>
  );
};
