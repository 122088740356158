import { FC, useState } from "react";
import { Card } from "../../common/components/Card";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { SearchSelect } from "../../common/components/SearchSelect";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { SelectOption } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { ProductDetails } from "./ProductDetails";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Error403 } from "../../common/components/Errors/Error403";

export const AddProduct: FC = () => {
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, cp_is_pim} = context.operatorInfo;
  const baseRoute = `/${tenantUrlTag}/products`;
  const [categoryId, setCategoryId] = useState<string>(emptyUuid);
  const userInfo = useUserInfo()!;

  if (cp_is_pim && !userInfo.seller_id)
    return <Error403 />;

  return (
    <div className="row">
      <div className="col-lg-12 col-xl-8 mx-auto">
        <ReturnHeader url={baseRoute} title="Add Product" />
        <Card className="p-4 mb-3">
          <SearchSelect
            label="Please select a product category"
            disabled={!isNullOrEmpty(categoryId)}
            itemChanged={(item: SelectOption | null) => item && setCategoryId(item?.value)}
            itemType="category"
          />
        </Card>
        {!isNullOrEmpty(categoryId) && <ProductDetails categoryId={categoryId} />}
      </div>
    </div>
  );
};
