import _ from "lodash";
import { FC, useEffect, useState } from "react";
import {
  useCategoriesByParentIdQuery,
  useCategoryByIdQuery,
  useCategorySettingsBySellerIdSubscription,
  useFullpathCategoriesQuery,
  useRootCategorieswithAtrrOrderQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { CustomSelect } from "../../common/components/CustomSelect";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { useFieldValidationNew } from "../../common/hooks/useFieldValidationNew";
import { emptyUuid } from "../../common/miscellaneous/utility";
import { ISearchParam, SelectOption } from "../../common/types/types";
import { Input } from "../../forms/components/Input";

interface IProps {
  sellerId: string,
  defaultPercent: number,
  updateCats(items: ICatUILine[]): void
}
export interface ICatUILine {
  name: string,
  id: string,
  isSelected: boolean,
  percentage: number,
  isUpdated: boolean,
  error?: boolean
}

export interface ICategory {
  trg_product_category?: boolean | null;
  id: string;
  parent_id?: string;
  name: string;
}

export interface ICategoryBySellerId {
  id: string;
  category_id: string;
  seller_id: string;
  reduce_quantity_by_percentage: number;
}

export const OfferSettingsCategories: FC<IProps> = (props: IProps): ReturnType<FC> => {
  const seller_id = props.sellerId;
  const defaultPercent = props.defaultPercent;
  const inputs = { variables: { sellerId: seller_id } };
  const [rootCategories, setRootCategories] = useState<any[]>([])
  const [ctgLimit, setCtgLimit] = useState<number>(15)
  const [sellerCatSettings, setSellerCatSettings] = useState<ICategoryBySellerId[]>([])
  const [updatedItems, setUpdatedItems] = useState<ICatUILine[]>([])
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0, defaultValue: emptyUuid };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const [categoryPath, setCategoryPath] = useState<string>("");
  const [searchedCategory, setSearchedCategory] = useState<ICategory>({ id: "", name: "" });

  const [sellersCatSettingQueried] = useCategorySettingsBySellerIdSubscription(inputs);
  const [rootCategoriesQuery] = useRootCategorieswithAtrrOrderQuery({ variables: { limit: ctgLimit } })
  const [queriedFullPath] = useFullpathCategoriesQuery({
    variables: _.omit(searchParam, "total"),
  });
  const [searchedCategoryQuery] = useCategoryByIdQuery({ variables: { id: categoryPath } })
  useEffect(() => {
    if (queriedFullPath.data) {
      const categories =
        queriedFullPath.data.category.map(
          (ctg) =>
          ({
            label: ctg.fullname,
            value: ctg.id,
          } as SelectOption)
        ) || [];
      setCategoryOptions(categories);
      setSearchParam({ ...searchParam, total: queriedFullPath.data.categories.aggregate?.count || 0 });
    }
  }, [queriedFullPath]);

  useEffect(() => {
    if (searchedCategoryQuery.data?.category_by_pk) {
      setSearchedCategory(
        {
          id: searchedCategoryQuery.data?.category_by_pk.id,
          name: searchedCategoryQuery.data?.category_by_pk.name,
          trg_product_category: searchedCategoryQuery.data?.category_by_pk?.trg_product_category,
          parent_id: searchedCategoryQuery.data.category_by_pk.parent_id
        }
      )
    } else {
      setSearchedCategory({ id: "", name: "" })
    }
  }, [searchedCategoryQuery])
  const totalCtgs = rootCategoriesQuery.data?.categories.aggregate?.count

  const handleShowMore = () => setCtgLimit(ctgLimit + 15)

  useEffect(() => {
    setRootCategories(rootCategoriesQuery.data?.category || [])
  }, [rootCategoriesQuery.fetching])
  useEffect(() => {
    setSellerCatSettings(sellersCatSettingQueried.data?.seller_category_stock_percent || [])
  }, [sellersCatSettingQueried.fetching])

  const updateCat = (item: ICatUILine) => {
    const curUpdatedItem = updatedItems.find(i => i.id == item.id)
    if (curUpdatedItem) {
      const newUpdatedItems = updatedItems.map(i => i.id != item.id ? i : item)
      setUpdatedItems(newUpdatedItems)
      props.updateCats(newUpdatedItems)
    } else {
      const newUpdatedItems = updatedItems
      newUpdatedItems.push(item)
      setUpdatedItems(newUpdatedItems)
      props.updateCats(newUpdatedItems)
    }
  }

  const searchedCategoryOfferLine = () => {
    const selectedCategory = sellerCatSettings?.find(i => i.category_id == searchedCategory.id)
    const updatedItem = updatedItems.find(i => i.id == searchedCategory.id)
    const item: ICatUILine = {
      name: searchedCategory.name,
      id: searchedCategory.id,
      isSelected: updatedItem ? updatedItem.isSelected : selectedCategory ? true : false,
      percentage: updatedItem?.isSelected ? updatedItem.percentage : selectedCategory ? selectedCategory.reduce_quantity_by_percentage : defaultPercent,
      isUpdated: updatedItem?.isUpdated || false
    }
    return (
      <div className="pe-5" style={{ paddingLeft: "1.8rem" }}>
        <OfferSettingCategoryLine item={item} updateCat={updateCat} defaultPercent={defaultPercent} />
      </div>
    )
  }
  if (!rootCategoriesQuery.data || !sellersCatSettingQueried.data)
    return (<Card className=" pt-4">
      <PlaceholderLists />
    </Card>);
  else
    return (
      <>
        <div className="px-0 pt-4">
          <div className="search-category-wrapper">
            <CustomSelect
              options={categoryOptions}
              placeholder="Search by category name"
              search={{ searchParam, setSearchParam }}
              onSelectionChanged={(options: SelectOption[]) => setCategoryPath(options.find(opt => opt)?.value || "")}
            />
          </div>
        </div>
        <tr className="border-white">
          <td className="d-flex flex-row justify-content-between align-items-center form-switch pt-3 px-0">
            <div className="col-8 font-weight-semi-bold" style={{ marginLeft: "2rem" }}>
              Categories
            </div>
            <div className="col-4 justify-content-end d-flex font-weight-semi-bold" style={{ paddingRight: "4.8rem" }}>
              <span style={{ width: "130px" }}>Percentage</span>
            </div>
          </td>
        </tr>
        {
          searchedCategory.id != "" ? searchedCategory.trg_product_category
            ? searchedCategoryOfferLine()
            : <RootOfferSettingsCategory updatedItems={updatedItems} key={searchedCategory.id} defaultPercent={defaultPercent} categoriesBySellerId={sellerCatSettings}
              updateCat={updateCat} item={searchedCategory} fetching={sellersCatSettingQueried.fetching || rootCategoriesQuery.fetching} isOpen /> :
            rootCategories.map((rootCategory: ICategory, index) => {
              return (
                <RootOfferSettingsCategory updatedItems={updatedItems} defaultPercent={defaultPercent} categoriesBySellerId={sellerCatSettings}
                  updateCat={updateCat} item={rootCategory} key={index} fetching={sellersCatSettingQueried.fetching || rootCategoriesQuery.fetching} />
              )
            })

        }
        <div className="px-0">
          {totalCtgs && totalCtgs > rootCategories.length && !searchedCategory.id && (
            <div className="show-more-wrapper d-flex align-items-center m-2 ps-2">
              <span className="small">
                Showing {rootCategories.length} out of {totalCtgs} categories
              </span>
              <button className="btn btn-sm btn-link" onClick={handleShowMore}>
                {sellersCatSettingQueried.fetching || rootCategoriesQuery.fetching ? (
                  <div className="spinner-border spinner-border-sm" />
                ) : (
                  <span>Show more</span>
                )}
              </button>
            </div>
          )}
        </div>
      </>
    );
}

interface IRootCategoryProps {
  fetching?: boolean
  isOpen?: boolean
  item: ICategory
  updateCat(item: ICatUILine): void
  defaultPercent: number
  categoriesBySellerId?: ICategoryBySellerId[]
  updatedItems: ICatUILine[]
}

// NEW
const RootOfferSettingsCategory: FC<IRootCategoryProps> = (props: IRootCategoryProps): ReturnType<FC> => {
  const fetching = props.fetching
  const [subOpen, setSubOpen] = useState<boolean>(props.isOpen || false)
  const [finalCatogories, setFinalCategories] = useState<ICategory[]>([])
  const [rootCategories, setRootCategories] = useState<ICategory[]>([])
  const [ctgLimit, setCtgLimit] = useState<number>(30)
  const [totalCtgs, setTotalCtgs] = useState<number>(0)

  props.item
  const defaultPercent = props.defaultPercent
  const categoriesBySellerId = props.categoriesBySellerId
  const updatedItems = props.updatedItems
  const updateCat = props.updateCat

  const [categoryQuery] = useCategoriesByParentIdQuery({ variables: { limit: ctgLimit, offset: 0, parentId: props.item.id } })

  const handleShowMore = () => setCtgLimit(ctgLimit + 15)
  useEffect(() => {
    const newFinalCategories = categoryQuery.data?.category.filter(cat => cat.trg_product_category)
    if (newFinalCategories) {
      setFinalCategories(newFinalCategories)
    }
    const newRootCategories = categoryQuery.data?.category.filter(cat => !cat.trg_product_category)
    if (newRootCategories) {
      setRootCategories(newRootCategories)
    }
    setTotalCtgs(categoryQuery.data?.categories.aggregate?.count || 0)
  }, [categoryQuery.fetching])
  return (
    <div className="category-group ps-2" style={{ paddingRight: props.item.parent_id ? "unset" : "3rem" }}>
      <div className="category-group__body" style={{ borderBottom: props.item.parent_id ? "" : "1px solid rgb(212, 212, 212)" }}>
        <div className="category-group__root" onClick={() => { setSubOpen(!subOpen) }}>
          <i
            className={`me-1 bi ${subOpen ? "bi-folder2-open"
              : "bi-folder"}`}
          ></i>
          <span>{props.item.name}</span>
        </div>
        <div>
          {subOpen ?
            <>
              {finalCatogories.map((categoryItem: ICategory, index: number) => {
                const selectedCategory = categoriesBySellerId?.find(i => i.category_id == categoryItem.id)
                const updatedItem = updatedItems.find(i => i.id == categoryItem.id)
                const item: ICatUILine = {
                  name: categoryItem.name,
                  id: categoryItem.id,
                  isSelected: updatedItem ? updatedItem.isSelected : selectedCategory ? true : false,
                  percentage: updatedItem?.isSelected ? updatedItem.percentage : selectedCategory ? selectedCategory.reduce_quantity_by_percentage : defaultPercent,
                  isUpdated: updatedItem?.isUpdated || false
                }
                return (<OfferSettingCategoryLine item={item} updateCat={updateCat} key={index} defaultPercent={defaultPercent} />)
              })}
              {rootCategories.map((categoryItem: ICategory, index) => {
                return (
                  <RootOfferSettingsCategory updatedItems={updatedItems} defaultPercent={defaultPercent} categoriesBySellerId={categoriesBySellerId} updateCat={updateCat}
                    item={categoryItem} key={index} fetching={fetching} />
                )
              })}
              <div className="px-0">
                {totalCtgs && totalCtgs > (categoryQuery.data?.category.length || 0) ? (
                  <div className="show-more-wrapper d-flex align-items-center m-2 ps-2">
                    <span className="small">
                      Showing {rootCategories.length} out of {totalCtgs} categories
                    </span>
                    <button className="btn btn-sm btn-link" onClick={handleShowMore}>
                      {categoryQuery.fetching || fetching ? (
                        <div className="spinner-border spinner-border-sm" />
                      ) : (
                        <span>Show more</span>
                      )}
                    </button>
                  </div>
                )
                  : <></>
                }
              </div>
            </> : <></>}
        </div>
      </div>
    </div>
  )
}

interface ILineProps {
  item: ICatUILine,
  updateCat(item: ICatUILine): void;
  defaultPercent: number
}

export const OfferSettingCategoryLine: FC<ILineProps> = (props: ILineProps): ReturnType<FC> => {
  const item = props.item;

  const [selected, setSelected] = useState<boolean>(item.isSelected ?? false);

  const percentageRef = useFieldValidationNew<HTMLInputElement>();
  const checkedRef = useFieldValidationNew<HTMLInputElement>();

  const updateCatSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemToUpdate: ICatUILine = {
      ...item,
      error: (percentageRef.current?.validationMessage !== ""),
      isUpdated: true,
      isSelected: e.target.checked,
      percentage: percentageRef.current ? Number(percentageRef.current.value) : item.percentage
    };
    props.updateCat(itemToUpdate);
    setSelected(e.target.checked);
  }

  const updateCatPercentage = () => {
    const itemToUpdate: ICatUILine = {
      ...item,
      error: (percentageRef.current?.validationMessage !== ""),
      isUpdated: true,
      isSelected: selected,
      percentage: percentageRef.current ? Number(percentageRef.current.value) : item.percentage
    };
    props.updateCat(itemToUpdate);
  }



  useEffect(() => {
    if (checkedRef.current) checkedRef.current!.checked = props.item.isSelected;
    setSelected(props.item.isSelected);
  }, [props.item]);

  useEffect(() => {
    if (!selected)
      percentageRef.current!.value = props.defaultPercent.toString();
  }, [selected]);


  return (
    <div className="border-white">
      <div className="d-flex flex-row justify-content-between align-items-center form-switch py-2">
        <div className="col-8">
          <Input
            ref={checkedRef}
            data-testid=""
            className="form-check-input ms-1 me-3"
            type="checkbox"
            onChange={(e) => updateCatSelected(e)}
          />
          <span>
            {item.name}
          </span>
        </div>
        <div className="col-4 justify-content-end d-flex">
          <div className="input-group me-5" style={{ width: "120px" }}>
            <Input
              data-testid="reduce-percentage"
              type="number"
              step="0.01"
              min="0"
              max="100"
              name="reduce_percentage"
              required
              ref={percentageRef}
              className={`form-control input-sm to-validate ${percentageRef.current?.validationMessage && "is-invalid"
                }`}
              value={selected ? item.percentage : props.defaultPercent}
              disabled={!selected}
              onChange={updateCatPercentage}
            />
            <span className="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
  );
}