import { useContext, useEffect, useState } from "react";
import {
  AttributeMappingPartFragment, useCustomerAttrsQuery,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { SearchSelect } from "../../common/components/SearchSelect";
import { useSearch } from "../../common/hooks/useSearch";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { IHash, IOptions, SelectOption } from "../../common/types/types";
import { MappingContext } from "../utils/types";

export const MapAttribute = () => {
  const initAttrData = useSearch("attribute", false);
  const mapContext = useContext(MappingContext);
  const results = mapContext.resultsRef!.current;
  const cols = Object.keys(results.attrMapping);
  const [queried] = useCustomerAttrsQuery({ variables: { cols } });

  useEffect(() => {
    if (queried.data) {
      const mappings : IHash = {};
      Object.values(results.attrMapping).forEach(mapping => {
        let updated = {...mapping};
        if (!mapping.required && !mapping.attribute_id) {
          const found = queried?.data?.attribute?.find(attr => attr.display === mapping.sheet_column);
          if (found)
            updated = { ...mapping, attribute_id: found.id, display: found.display };
        }
        mappings[updated.sheet_column] = updated;
      });
      results.attrMapping = mappings;
    }
  }, [queried.data]);

  if (queried.error)
    return <p>{queried.error.message}</p>;
  if (!initAttrData.total || !queried.data)
    return <PlaceholderLists />;

  return (
    <>
      <div className="product-import-wizard map-categories">
        <Card className="my-4 p-4">
          <h4 className="card-title">Map Attributes</h4>
          <table className="table my-2">
            <thead>
              <tr>
                <th className="d-flex col-12">
                  <span className="col-4">Your Fields</span>
                  <span className="">Map to MarketPush Fields</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(results.attrMapping).map((mapping, index) => {
                return (
                  <tr key={`${mapping.sheet_column}_${index}`}>
                    <td className="d-flex col-12 align-items-center">
                      <div className="col-4 d-flex justify-content-between align-items-center">
                        <div>{mapping.sheet_column}</div>
                      </div>
                      <div className="col-8">
                        {mapping.required ? 
                          <div className="required">
                            {mapping.display}
                          </div> 
                          : 
                          <AttributeMapping attribute={mapping} initData={initAttrData} />}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

const AttributeMapping = ({ attribute, initData }: { attribute: AttributeMappingPartFragment, initData: IOptions }) => {
  const mapContext = useContext(MappingContext);
  const results = mapContext.resultsRef!.current;
  const defId = attribute.attribute_id || emptyUuid;

  let initOptions = [...initData.options];
  if (!isNullOrEmpty(defId) && initOptions.some(opt => opt.value !== defId)) {
    initOptions = [...initOptions, { label: attribute.display || "", value: defId }];
  }
  const [warnMsg, setWarnMsg] = useState<string>("");
  const ctgChanged = (item: SelectOption | null) => {
    if (item?.value !== defId && mapContext.attrMapped.find((map) => map.attribute_id === item?.value))
      setWarnMsg("Existing attribute mapping.");
    else
      setWarnMsg("");
    results.attrMapping[attribute.sheet_column!] = {
      ...attribute,
      attribute_id: item?.value,
      display: item?.label,
    };
  };
  return (<>
    <div className="">
      <SearchSelect
        initOptions={initOptions}
        itemChanged={(item: SelectOption | null) => ctgChanged(item)}
        defVal={defId}
        totalItems={initData.total}
        placeholder="Please select or search for the attribute"
        itemType="attribute"
      />
      {warnMsg && (
        <span className="small text-warning">
          <i className="bi bi-exclamation-circle-fill me-1" />
          {warnMsg}
        </span>
      )}
    </div>
  </>);
};

