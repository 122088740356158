import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDashboardOfferCountQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";

export const OfferStat: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const [where1, setWhere1] = useState<string>(`{
                    "operator_id": { "_eq": "${userInfo.operator_id}"},
                    "offer_status": { "_in": ["Active", "Inactive"] }
           }`);
  const [where2, setWhere2] = useState<string>(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     "offer_status": { "_in": "Archived" }
                }`);
  let inputs = {
    variables: {
      where1: JSON.parse(where1),
      where2: JSON.parse(where2)
    },
  };

  const [reportOfferStat] = useDashboardOfferCountQuery(inputs);

  useEffect(() => {
    if (reportOfferStat.data) {
      setLoading(false);
    }
  }, [reportOfferStat.data]);

  useEffect(() => {
    if (userInfo.seller_id) {
      let sellerId = userInfo.seller_id;
      setWhere1(`{
        "seller_id": { "_eq": "${sellerId}"},
        "offer_status": { "_in": ["Active", "Inactive"] }
                }`);
      setWhere2(`{
        "seller_id": { "_eq": "${sellerId}"},
        "offer_status": { "_in": ["Archived"] }
                }`);
    } else {
      setWhere1(`{
          "operator_id": { "_eq": "${userInfo.operator_id}"},
          "offer_status": { "_in": ["Active", "Inactive"] }
                }`);
      setWhere2(`{
          "operator_id": {"_eq": "${userInfo.operator_id}"},
          "offer_status": { "_in": ["Archived"] }
                }`);
    }
  }, [userInfo]);

  const error = reportOfferStat.error;
  if (error) {
    return <PageError error={{ source: "OfferStats", errMsg: error.message }} />;
  }
  if (!reportOfferStat.data) {
    return (
      <>
        <h4 className="card-title mb-3">
          <i className="bi bi-tags me-2"></i>Offers
        </h4>
        <p className="card-text placeholder-glow gap-3">
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2 height-4 mt-4"></span>
        </p>
      </>
    );
  }
  return (
    <div className="">
      <div className="btn-group">
        <h4 className="card-title me-2">
          <i className="bi bi-tags me-2"></i>Offers
        </h4>

      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">All Offers</p>
                </td>
                <td className="text-right">{reportOfferStat.data?.active_count.aggregate?.count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Archived Offers</p>
                </td>
                <td className="text-right">
                  {reportOfferStat.data?.archived_count.aggregate?.count}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <NavLink
            data-testid="view-orders-button"
            className="btn btn-primary btn-block"
            to={`/${tenantUrlTag}/offers`}
          >
            View Offers
          </NavLink>
        </div>
      </div>
    </div>
  );
};
