import { useRef, useState } from "react";
import {
  useAttributesInitQuery,
  useAttributesSubscription,
  useDeleteAttributeMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { appRef } from "../../common/components/appStatus";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { AttributeProps, IRef, MutationAction, OrderByType, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { AttributeDetails } from "./AttributeDetails";

export function AttributesRoute() {
  const [context] = useMainContext();
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [upsertId, setUpsertId] = useState<string | null>(null);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const userInfo = useUserInfo()!;
  const manage = userInfo.permissions! & context.permissions.manage_ctg_n_attr;
  const { number_items_per_page } = context.operatorSettings.preset;

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings`;

  const filterList: SelectOption[] = [
    { value: "name", label: "Name" },
    { value: "display", label: "Display" },
  ];
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    Name: "name",
    Display: "display",
    Built_In: "built_in",
    Global_Attribute: "global",
    Type: "type",
    Num_of_Categories: "cp_ctg_attrs",
  };
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(
            filterList.map((col) => col.value),
            searchValue
          ),
          selectedFilters,
        })
      ),
      order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined,
    },
  };
  const [queried] = useAttributesInitQuery(inputs);
  const [subscribed] = useAttributesSubscription(inputs);
  const [, deleteMutation] = useDeleteAttributeMutation();

  const defAttrProps: AttributeProps = {
    built_in: false,
    type: "text",
    global: false,
    required: false,
    def_properties: {
      max_length: "300",
      ui_component: "input",
      input_type: "date",
    },
    name: "",
    display: "",
  };

  const multiSelectRef = useRef<IRef | null>(null);

  const tableColumns = [
    "Name",
    "Display",
    "Built In",
    "Global Attribute",
    "Type",
    "Num of Categories",
  ];
  if (manage) tableColumns.push("Actions");

  const error = subscribed?.error || queried?.error;
  if (error) {
    return <PageError error={{ source: "AttributesRoute", errMsg: error.message }} />;
  }

  if (!queried.data || !subscribed.data) {
    return <Loader />;
  }
  const attributes = subscribed.data?.attribute ?? queried.data?.attribute;
  const totalAttributes = queried.data?.total_attributes?.aggregate?.count ?? 0;

  const tableData = attributes.map((attribute) => {
    const actions = [
      {
        enabled: true,
        actionType: "edit",
        id: attribute.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: (id: string) => setUpsertId(id),
      },
      {
        enabled: !attribute.built_in && attribute.cp_ctg_attrs === 0,
        actionType: "delete",
        id: attribute.id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: (id: string) => setDeleteId(id),
      },
    ];

    return {
      id: attribute.id,
      Name: attribute.name,
      Display: <span>{attribute.display}</span>,
      Built_In: attribute.built_in && <span className="bi bi-check2 text-success"></span>,
      Global_Attribute: attribute.global && <span className="bi bi-check2 text-success"></span>,
      Type: attribute.type,
      Num_of_Categories: attribute.cp_ctg_attrs,
      Actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  const applyChanges = () => { };
  const continueSave = (isContinue: boolean) => {
    if (!isContinue) {
      setUpsertId(null);
      appRef.current?.updateStatus(false);
    }
  };

  const continueDelete = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await deleteMutation({ deleteId });
      if (!res.error) {
        setDeleteId(null);
      }
      alertsRef.current?.generate(mutationInfo("attribute", MutationAction.Delete, res));
    }
    else {
      setDeleteId(null);
    }
  };

  return (
    <div>
      <ReturnHeader
        url={baseRoute}
        title="Manage Attributes"
        description="Customize global and custom field attributes to tailor to your own unique
        requirements."
      >
        {manage ? (
          <div className="d-flex flex-row align-items-start">
            <Button
              data-testid=""
              className="btn btn-primary"
              onClick={() => setUpsertId(emptyUuid)}
            >
              Add Attribute
            </Button>
          </div>
        ) : null}
      </ReturnHeader>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalAttributes || 0}
          ref={multiSelectRef}
          multiSelectTable={false}
          filters
          addFilterOptions={filterList}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
        />
      </Card>
      <Dialog
        show={!!upsertId}
        subFormId="attribute-details-form"
        title={`${upsertId !== emptyUuid ? "Edit" : "Add"} Attribute`}
        continueText="Save"
        staticModal={true}
        continue={continueSave}
        size="xl"
        disableAction={upsertId !== emptyUuid && !formChanged}
      >
        <AttributeDetails
          attrProps={
            !upsertId || upsertId === emptyUuid
              ? defAttrProps
              : (attributes.find((attr) => attr.id === upsertId) as AttributeProps)
          }
          resetAttribute={() => setUpsertId(null)}
          setChanged={setFormChanged}
        />
      </Dialog>
      <Dialog
        show={!!deleteId}
        title={`Delete Attribute`}
        continueText="Delete"
        continue={continueDelete}
        contineBtnCss="btn-danger"
      >
        Are you sure you want to delete this attribute?
      </Dialog>
    </div>
  );
}
