import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  OrderItemPartFragment,
  Reason_Category_Enum,
  useCreateOrderRefundMutation,
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { useReasonList } from "../../common/hooks/globals";
import { currentDate, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";
import { OrderItemBriefDescription, footerText } from "./OrderItems";

interface IProps {
  setClose: () => void;
  itemData: OrderItemPartFragment;
}

export const RefundDialog: FC<IProps> = ({ setClose, itemData }) => {
  const [{ fetching }, createOrderRefund] = useCreateOrderRefundMutation();
  const reasons = useReasonList();
  const [context] = useMainContext();
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  const { orderId } = useParams<{ orderId: string }>();
  const reasonOptions = reasons
    .filter((reason) => reason.category === Reason_Category_Enum.RefundOrder)
    .map((reason) => {
      return { value: reason.value, label: reason.label };
    });

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const refundQty = watch("refund_items.0.quantity") || 0;
  const onSubmit = async (formData: FieldValues) => {
    const refund = {
      ...formData,
      shipping_amount: 0,
      order_id: orderId,
      refund_items: [
        {
          ...formData.refund_items[0],
          order_item_id: itemData.id,
          quantity: Number(formData.refund_items[0].quantity),
        },
      ],
    };
    const res = await createOrderRefund({ data: refund });
    if (res && !res.error) {
      reset();
      setClose();
    }
    alertsRef.current?.generate(mutationInfo("refund item", MutationAction.Create, res));
  };

  const continueConfirm = async (isContinue: boolean) => {
    if (!isContinue) {
      setClose();
      reset();
    }
  };

  const { quantity, cp_refund_qty, unit_price } = itemData!;
  const maxRefundQty = quantity - cp_refund_qty;
  return (
    <>
      <Dialog
        subFormId="order-action-form-refund_item"
        title="Refund Item"
        continueText="Refund"
        staticModal={true}
        continue={continueConfirm}
        size="lg"
        footerText={footerText}
        disableAction={refundQty === 0 || maxRefundQty < refundQty}
        fetching={fetching}
      >
        <FormProvider {...methods}>
          <Form
            data-testid="order-action-form-refund_item"
            id="order-action-form-refund_item"
            noValidate
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="table-responsive">
              <div className="row">
                <div className="mb-3 col-xl-6">
                  <FormInput
                    name="transaction_number"
                    label="Reference Number"
                    reg_options={{
                      required: true,
                    }}
                  />
                </div>
                <div className="mb-3 col-xl-6">
                  <FormInput
                    name="transaction_date"
                    label="Date"
                    type="date"
                    reg_options={{
                      required: true,
                      max: currentDate(),
                    }}
                  />
                </div>
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <OrderItemBriefDescription orderItem={itemData} />
                    </td>
                    <td>
                      <div className="d-flex">
                        <FormInput
                          name="refund_items.0.quantity"
                          type="number"
                          reg_options={{
                            min: 1,
                            max: maxRefundQty,
                            required: true,
                            pattern: /^[0-9]+$/,
                          }}
                          step="1"
                          css="w-50"
                        />
                        <div className="ms-xl-2 flex-fill py-2">of {maxRefundQty}</div>
                      </div>
                    </td>
                    <td>$ {addCommaFunc(unit_price, max_decimals_in_unit_price)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex align-items-center">
                <div className="w-50 mb-2">
                  <FormSelect
                    name="refund_items.0.reason"
                    options={reasonOptions}
                    label="Select Reason"
                    reg_options={{
                      required: true,
                    }}
                  />
                </div>
                <div className="w-50 d-flex justify-content-center">
                  <h6>Refund: ${addCommaFunc(unit_price * refundQty, max_decimals_in_unit_price)}</h6>
                </div>
              </div>
            </div>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};
