import { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  MessageSettingsInitialQuery,
  MessageSettingsSubscription,
  useEnableMessageSettingMutation
} from "../../../generated/urql-graphql";
import { Table } from "../../common/components/Table";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";

interface EmailSettingListProps {
  messages:
  | MessageSettingsInitialQuery["message_setting"]
  | MessageSettingsSubscription["message_setting"];
}

export const EmailSettingList: FC<EmailSettingListProps> = (props): ReturnType<FC> => {
  const baseRoute = "/settings/email-settings";
  const [, updateMutation] = useEnableMessageSettingMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const msid = event.currentTarget.dataset.msid;
    const enabled = event.target.checked;
    updateMutation({
      msid: msid,
      enabled: enabled,
    })
      .then((res) => {
        alertsRef.current?.generate(mutationInfo("email setting", MutationAction.Update, res));
      })
      .catch((error) => alertsRef.current?.generate(mutationInfo("email setting", MutationAction.Update, error)));
  };
  const columnNames = ["Name", "Description", "Action"];
  const tableData = props.messages.map((noti) => {
    return {
      id: noti.id,
      Name: (
        <NavLink
          data-testid="nav-message"
          to={`${baseRoute}/${noti.id}`}
          className="nav-link ps-0"
        >
          {noti.name}
        </NavLink>
      ),
      Description: noti.description,
      Action: (
        <div className="d-flex align-items-center justify-content-center form-check form-switch ms-2">
          <Input
            ref={null}
            data-testid=""
            className="form-check-input"
            type="checkbox"
            checked={noti.enabled}
            data-msid={noti.id}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
          />
        </div>
      ),
    };
  });

  return <Table columnNames={columnNames} data={tableData} />;
};
