import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  useCreateAttributeMutation,
  useUpdateAttributeMutation
} from "../../../generated/urql-graphql";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import {
  appDataTypeOptions,
  boolUiComponents,
  dateInputTypes,
  emptyUuid,
  getAttributeObj,
  mutationInfo,
  textUiComponents
} from "../../common/miscellaneous/utility";
import { AttributeProps, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
interface Props {
  attrProps: AttributeProps;
  resetAttribute: () => void;
  setChanged: (changed: boolean) => void;
}
export const AttributeDetails: FC<Props> = ({ attrProps, resetAttribute, setChanged }) => {
  const [optionLength, setOptionLength] = useState<number>(
    attrProps.def_properties?.options?.length ?? 1
  );
  const userInfo = useUserInfo();
  const [, addMutation] = useCreateAttributeMutation();
  const [, updateMutation] = useUpdateAttributeMutation();

  const methods = useForm<AttributeProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    getValues,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    appRef.current?.updateStatus(changed);
    if (attrProps.id) setChanged(changed);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (attrProps) {
      if (attrProps.type === "text" && !attrProps.def_properties.ui_component) {
        reset({
          ...attrProps,
          def_properties: { ...attrProps.def_properties, ui_component: "input" },
        });
      } else {
        reset(attrProps);
      }
      setOptionLength(attrProps.def_properties.options?.length ?? 1);
    }
  }, [attrProps]);

  const dataType = watch("type");
  const onSubmit = async (formData: FieldValues) => {
    let res;
    const defProps = getAttributeObj(["tip"], formData.type, formData.def_properties);
    const attrObj = { ...formData, operator_id: userInfo.operator_id, def_properties: defProps, cp_ctg_attrs: void 0 };

    if (attrProps.id) {
      const updateAttr = { ...attrObj, id: void 0 };
      res = await updateMutation({ updateId: attrProps.id!, updateAttr });
    } else res = await addMutation({ attrObj });
    if (!res.error) {
      resetAttribute();
      appRef.current?.updateStatus(false);
    }
    alertsRef.current?.generate(mutationInfo("attribute", attrProps.id ? MutationAction.Update : MutationAction.Create, res));
  };

  const removeOption = (index: number) => {
    const temp = getValues("def_properties.options");
    temp?.splice(index, 1);
    setValue("def_properties.options", temp);
    setOptionLength(temp?.length ?? 1);
  };

  const optionsRequirement = () => {
    return (
      <>
        <p className="small text-muted">
          For a set of options where only one option can apply at a time, i.e a color or size.
        </p>
        {Array.from({ length: optionLength }, (elm, index) => {
          return (
            <div className="mb-3" key={`options_${index}`}>
              <div
                className={index > 0 ? "input-group justify-content-between align-items-end" : ""}
              >
                <FormInput
                  name={`def_properties.options.${index}`}
                  label={`Option ${index + 1}`}
                  reg_options={{ required: true }}
                  css="form-input"
                  input_extra={
                    index > 0 && (
                      <Button
                        data-testid=""
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeOption(index)}
                      >
                        <i className="bi bi-trash" />
                      </Button>
                    )
                  }
                />
              </div>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-between">
          <Button
            data-testid=""
            type="button"
            className="btn btn-secondary btn-sm mb-2"
            onClick={() => setOptionLength(optionLength + 1)}
          >
            Add option
          </Button>
        </div>
      </>
    );
  };

  const textRequirement = () => {
    return (
      <>
        <p className="small text-muted">
          This attribute can be used for short values of text or longer pieces of plain text, eg.
          description, instructions and warranty content.
        </p>
        <FormInput
          name="def_properties.max_length"
          label="Maximum number of characters allowed"
          reg_options={{ required: true, maxLength: 5 }}
        />
        <FormInput
          name="def_properties.ui_component"
          label="Format"
          label_extra={
            <p className="small text-muted mb-1">
              Values can contain letters, numbers, and special characters
            </p>
          }
          type="radio"
          radio_options={textUiComponents}
        />
      </>
    );
  };

  const booleanRequirement = () => {
    return (
      <>
        <p className="small text-muted">Typically used for all 'yes or no' questions</p>
        <FormSelect
          name="def_properties.ui_component"
          label="Choice Type"
          options={boolUiComponents}
          reg_options={{ required: true }}
        />
        <FormInput
          name="def_properties.on_text"
          reg_options={{ required: true }}
          label="On Text"
          tool_tip="Value shown when enabled"
        />
        <FormInput
          name="def_properties.off_text"
          label="Off Text"
          reg_options={{ required: true }}
          tool_tip="Value shown when disabled"
        />
      </>
    );
  };
  const datetimeRequirement = () => {
    return (
      <>
        <p className="small text-muted">
          When you need a uniform way to tell the date. Some examples are launch dates, end of life
          dates, expiration date, etc.
        </p>
        <FormInput
          name="def_properties.input_type"
          label="Format"
          type="radio"
          radio_options={dateInputTypes}
        />
        <FormInput
          name="def_properties.show_date_range"
          label="Show as date range?"
          type="checkbox"
          css="d-flex align-items-center"
        />
      </>
    );
  };
  const numberRequirement = () => {
    return (
      <>
        <p className="small text-muted">
          A numerical input that only allows for whole numbers. For example, quantities or numerical
          identifiers.
        </p>
        <FormInput name="def_properties.min_value" type="number" label="Minimum Value" />
        <FormInput name="def_properties.max_value" label="Maximum Value" type="number" />
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="attribute-details-form"
        id="attribute-details-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <div className="px-2">
          <div className={`row d-flex ${!attrProps?.built_in ? "mb-3" : ""}`}>
            <div className="col-6">
              <FormInput
                name="display"
                label="Label"
                reg_options={{ required: true, maxLength: 50 }}
                disabled={attrProps?.built_in}
              />
              <FormInput
                name="name"
                label="Name"
                reg_options={{
                  required: true,
                  maxLength: 50,
                  pattern: {
                    value: /^[a-z][a-z0-9_]+$/,
                    message:
                      "Name is case insensitive and starts with letter and only includes letter, number and underscore",
                  },
                }}
                disabled={
                  (!attrProps.id || attrProps.id === emptyUuid ? false : true) ||
                  attrProps?.built_in
                }
                inputgrouplabel="attribute."
              />
              <FormTextarea
                name="def_properties.tip"
                label="Description"
                reg_options={{ maxLength: 300 }}
                input_extra={
                  <span className="small text-muted">
                    Your description will appear as a tooptip
                  </span>
                }
              />
            </div>
            <div className="col-6">
              <FormSelect
                name="type"
                label="Attribute Type"
                options={appDataTypeOptions}
                reg_options={{ required: true }}
                disabled={
                  !attrProps.id || attrProps.id === emptyUuid ? false : true || attrProps?.built_in
                }
              />
              {dataType === "text" && textRequirement()}
              {dataType === "options" && optionsRequirement()}
              {dataType === "boolean" && booleanRequirement()}
              {dataType === "datetime" && datetimeRequirement()}
              {dataType === "number" && numberRequirement()}
            </div>
          </div>
          {!attrProps?.built_in && (
            <div className="row">
              <div className="col">
                <h5 className="sub-title border-bottom pb-2">Advanced Settings</h5>
                <FormInput
                  label="Set as a global attribute"
                  name="global"
                  type="checkbox"
                  css="d-flex align-items-start"
                  input_extra={
                    <span className="tooltip-custom right">
                      <span className="tooltip-text">Global attributes are attributes available for all products in MarketPush.
                      </span>
                      <i className="bi bi-info-circle-fill text-muted"></i>
                    </span>
                  }
                  disabled={attrProps?.built_in}
                  isswitch="true"
                />
              </div>
            </div>
          )}
        </div>
      </Form>
    </FormProvider>
  );
};
