import { Document, Font, Link, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { Reason_Category_Enum } from '../../../generated/urql-graphql';
import { separateWithSpace } from '../../common/miscellaneous/utility';
import { SelectOption } from '../../common/types/types';
import { addCommaFunc } from '../handler/handler';

const CalendarIcon = () => {
    return (
        <Svg style={{ width: '9%', color: '#6c757d' }} width="16" height="16" viewBox="0 0 16 16">
            <Path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" fill="#6c757d" />
        </Svg>
    )
}

const CartIcon = () => {
    return (
        <Svg style={{ width: '3%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path
                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                fill="#212529"
            />
        </Svg>)
}

const ShopIcon = () => {
    return (
        <Svg style={{ color: '#6c757d', width: '10%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#6c757d" d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
        </Svg>)
}

const PersonIcon = () => {
    return (
        <Svg style={{ color: '#6c757d', width: '11%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#6c757d" d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
        </Svg>
    )
}
const CartCheckIcon = () => {
    return (
        <Svg style={{ color: '#6c757d', width: '11%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#6c757d" d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
            <Path fill="#6c757d" d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </Svg>
    )
}
const BagIcon = () => {
    return (
        <Svg style={{ width: '6%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#212529" d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
        </Svg>
    )
}
const CreditCartIcon = () => {
    return (
        <Svg style={{ width: '8%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#212529" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
            <Path fill="#212529" d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
        </Svg>
    )
}
const TruckIcon = () => {
    return (
        <Svg style={{ width: '8%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#212529" d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </Svg>
    )
}

const CardTextIcon = () => {
    return (
        <Svg style={{ width: '8%' }} width="16" height="16" viewBox="0 0 16 16">
            <Path fill="#212529" d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
            <Path fill="#212529" d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
        </Svg>
    )
}

interface IProps {
    order: {
        order_items: any
        status: string
        payment_method?: string
        shipping_carrier: any
        shipping_method_code?: string
        order_number?: number
        refunds: any
        cp_commission: any
        cp_total_price: any
        cp_base_total_price: any
        cp_shipping_refund: any
        shipping_price: any
        cp_items_refund: any
        cp_sub_total_price: any
        cp_base_sub_total_price: any
        seller_id: any
        shipping_deadline_time: string
        trg_shipping_deadline: any
        po_number: any
        shipped_date: string
        seller: any
        tax_info?: { tax_amount: number, tax_rate: number }
    }
    customer: {
        first_name: string;
        last_name: string;
        email: string;
        phone?: string | null;
        shipping_address: any;
        customer_id: string;
        billing_address: any;
        tax_id?: string | null;
    } | undefined
    shippingAddr: any
    locationOptions: SelectOption[]
    trackingItems: {
        id: any
        tracking_number: string
        shipping_items: any
        carrier_key: string
        shipped_date: any
    }[]
    reasons: {
        label: string
        category: Reason_Category_Enum
        value: string
    }[]
    maxDecimalsInUnitPrice: number

}

const OrderDetailsPdf: FC<IProps> = ({ order, customer, shippingAddr, locationOptions, trackingItems, reasons, maxDecimalsInUnitPrice }) => {
    const baseRoute = window.location;
    const orderItems = order?.order_items ?? []

    const createStatusBadge = (statusForBadge: string) => {
        let badgeStatusBg = ""
        let badgeStatusColor = "#fff"
        let orderStatusWidth = '12%'
        switch (statusForBadge) {
            case "Pending":
            case "Hold":
            case "Inactive":
                badgeStatusBg = "#f7b84b";
                badgeStatusColor = "#333"
                break;
            case "IncidentOpen":
                badgeStatusBg = "#fd7e14";
                orderStatusWidth = '17%'
                break;
            case "AwaitingFulfillment":
                badgeStatusBg = "#00a3c7";
                orderStatusWidth = '22%'
                break;
            case "Active":
            case "Accepted":
            case "Approved":
            case "Shipped":
            case "Open":
                badgeStatusBg = "#198754";
                break;
            case "Cancelled":
            case "Rejected":
            case "Suspended":
            case "Disputed":
            case "OutOfStock":
                badgeStatusBg = "#f1556c";
                orderStatusWidth = '15%'
                break;
            case "Received":
                badgeStatusBg = "#085873";
                break;
            case "Closed":
                badgeStatusBg = "#212529";
                break;
            case "PartialRefund":
            case "Refunded":
            case "Archived":
                badgeStatusBg = "#6c757d";
                orderStatusWidth = '17%'
                break;
            default:
                badgeStatusBg = "#0DCAF0";
                break;
        }
        return [badgeStatusColor, badgeStatusBg, orderStatusWidth]

    }

    const orderStatusWith = createStatusBadge(order?.status)[2]

    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
    });

    const styleOrderInfo = StyleSheet.create({
        styleOrderTotal: {
            color: '#212529',
            fontSize: '9px',
            fontFamily: 'Roboto',
            marginLeft: 'auto'
        }
    })

    const subTotalPrice = order?.cp_sub_total_price
        ? addCommaFunc(order?.cp_sub_total_price)
        : addCommaFunc(order.cp_base_sub_total_price);
    const totalPrice = order?.cp_sub_total_price
        ? (order?.cp_total_price
            ? addCommaFunc(order?.cp_total_price)
            : addCommaFunc(0))
        : addCommaFunc(order.cp_base_total_price);
    return (
        <Document>
            <Page size="A4" style={{ padding: '60px 20px' }}>
                <View style={{ border: '1px solid #dee2e6', borderRadius: '3px' }}>
                    <View style={{
                        backgroundColor: '#dee2e6',
                        padding: '10px'
                    }}>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            <CartIcon />
                            <Text style={{ flexBasis: '75%', fontSize: '17px' }}> Order #{order?.order_number}</Text>
                            <View style={{ width: orderStatusWith, marginLeft: 'auto' }}>
                                <Text style={{
                                    color: createStatusBadge(order?.status)[0], backgroundColor: createStatusBadge(order?.status)[1], textAlign: 'center', borderRadius: '8px', fontSize: '11px',
                                    padding: '4px 5px 5px 3px',
                                }}>{separateWithSpace(order?.status)}</Text>
                            </View>
                        </View>
                    </View>
                    <View wrap={false} style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        padding: '13px'
                    }}>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            width: '24%',
                            paddingRight: '5px'
                        }}>
                            <CalendarIcon />
                            <View style={{ width: '89%', paddingLeft: '5px', fontSize: '9px' }}>
                                <Text style={{ color: '#6c757d', paddingBottom: '3px' }}>Date:</Text>
                                <Text style={{ color: '#212529', paddingBottom: '6px' }}>{order?.shipped_date}</Text>
                                <Text style={{ color: '#6c757d', paddingBottom: '3px' }}>Purchase Order:</Text>
                                <Text style={{ color: '#212529', paddingBottom: '6px' }} >{order?.po_number}</Text>
                                <Text style={{ color: '#6c757d', paddingBottom: '3px' }}>Shipping Deadline:</Text>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap"
                                }}>
                                    <Text style={{ color: '#212529', fontSize: '9px', paddingRight: '2px' }}>{order?.trg_shipping_deadline}</Text>
                                    <Text style={{ color: '#6c757d', fontSize: '8px', paddingTop: '1px' }}>{order?.shipping_deadline_time}</Text>
                                </View>
                            </View>
                        </View>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap", width: '24%',
                            paddingRight: '5px'
                        }}>
                            <ShopIcon />
                            <View style={{ width: '85%', paddingLeft: '5px' }}>
                                <Text style={{ color: '#6c757d', fontSize: '9px', paddingBottom: '3px' }}>Seller:</Text>
                                <Text style={{ color: '#212529', fontSize: '10px', paddingBottom: '6px' }}><Link src={`${baseRoute}/sellers/${order?.seller_id}`}>{order?.seller.company_name}</Link></Text>
                                <Text style={{ color: '#6c757d', fontSize: '9px', paddingBottom: '3px' }}>Payment Terms:</Text>
                                <Text style={{ color: '#212529', fontSize: '10px', paddingBottom: '6px' }} >Payment Terms</Text>
                            </View>
                        </View>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap", width: '25%',
                            paddingRight: '5px'
                        }}>
                            <PersonIcon />
                            <View style={{ width: '89%', paddingLeft: '5px' }}>
                                <Text style={{ color: '#6c757d', fontSize: '9px', paddingBottom: '3px' }}>Customer:</Text>
                                <Text style={{ color: '#212529', fontSize: '10px' }}>{customer?.first_name} {customer?.last_name}</Text>
                                <Text style={{ color: '#6c757d', fontSize: '6px', paddingBottom: '2px' }}>{customer?.customer_id}</Text>
                                <Text style={{ color: '#6c757d', fontSize: '7px', paddingBottom: '2px' }}><Link src={`mailto:${customer?.email}`}>{customer?.email}</Link></Text>
                                <Text style={{ color: '#212529', fontSize: '7px', paddingBottom: '2px' }}>{customer?.phone}</Text>
                                <Text style={{ color: '#6c757d', fontSize: '8px', paddingBottom: '2px' }}>{shippingAddr?.country}</Text>
                                {customer?.tax_id ? <Text style={{ color: '#212529', fontSize: '7px', paddingBottom: '2px' }}>Tax Id: {customer.tax_id}</Text> : <></>}
                            </View>
                        </View>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap", width: '24%'
                        }}>
                            <CartCheckIcon />
                            <View style={{ width: '85%', paddingLeft: '5px' }}>
                                <Text style={{ color: '#6c757d', fontSize: '9px' }}>Order Total:</Text>
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '3px'
                                }}>
                                    <Text style={{ color: '#212529', fontSize: '10px', width: '70%' }}>Subtotal:</Text>
                                    <Text style={styleOrderInfo.styleOrderTotal}>$ {subTotalPrice}</Text>
                                </View>
                                {order?.cp_items_refund !== 0 && (
                                    <View wrap={false} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        paddingBottom: '3px'
                                    }}>
                                        <Text style={{ color: '#212529', fontSize: '10px', width: '70%' }}>Adjustment:</Text>
                                        <Text style={styleOrderInfo.styleOrderTotal}>$ -{addCommaFunc(order?.cp_items_refund)} </Text>
                                    </View>
                                )}
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '3px'
                                }}>
                                    <Text style={{ color: '#212529', fontSize: '10px', width: '70%' }} >Shipping:</Text>
                                    <Text style={styleOrderInfo.styleOrderTotal}>$ {order?.shipping_price ? addCommaFunc(order?.shipping_price) : "0.00"}</Text>
                                </View>
                                {order?.cp_shipping_refund !== 0 && (
                                    <View wrap={false} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        paddingBottom: '3px'
                                    }}>
                                        <Text style={{ color: '#212529', fontSize: '10px', width: '70%' }}>Adjustment:</Text>
                                        <Text style={styleOrderInfo.styleOrderTotal}>$ -{addCommaFunc(order?.cp_shipping_refund)} </Text>
                                    </View>
                                )}
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '3px'
                                }}>
                                    <Text style={{ color: '#212529', fontSize: '10px', width: '70%' }}>Tax:</Text>
                                    <Text style={styleOrderInfo.styleOrderTotal}>$ {order?.tax_info
                                        ? addCommaFunc(order?.tax_info?.tax_amount)
                                        : addCommaFunc(0)}</Text>
                                </View>
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '3px', borderBottom: '1px solid #6c757d', borderRadius: '3px'
                                }}>
                                    <Text style={{ color: '#212529', fontSize: '10px', paddingBottom: '3px', width: '70%' }}>Total:</Text>
                                    <Text style={styleOrderInfo.styleOrderTotal}>$ {totalPrice}</Text>
                                </View>
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '3px',
                                }}>
                                    <Text style={{ color: '#6c757d', fontSize: '9px', width: '70%', paddingTop: '2px' }}>Commission:</Text>
                                    <Text style={styleOrderInfo.styleOrderTotal}>$ {order?.cp_commission
                                        ? addCommaFunc(order?.cp_commission) :
                                        addCommaFunc(0)}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {order?.refunds?.length ? <View style={{ margin: '9px 5px 13px 5px', border: '1px solid #f7bb55', backgroundColor: '#fef8ed', paddingBottom: '5px', borderRadius: '3px' }}>
                        <View wrap={false} style={{
                            backgroundColor: '#f7bb55', display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            padding: '5px',
                        }}>
                            <CardTextIcon />
                            <Text style={{ width: '88%', fontSize: '15px' }}>Refund Summary</Text>
                        </View>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            borderBottom: '1px solid black',
                            margin: '20px 10px 2px 10px',
                            paddingBottom: '2px',
                        }}>
                            <Text style={{ width: '9%', fontSize: '9px' }}>Date</Text>
                            <Text style={{ width: '25%', fontSize: '9px' }}>Product</Text>
                            <Text style={{ width: '16%', fontSize: '9px' }}>Reference Number</Text>
                            <Text style={{ width: '17%', fontSize: '9px' }}>Reason</Text>
                            <Text style={{ width: '7%', fontSize: '9px' }}>Quantity</Text>
                            <Text style={{ width: '11%', fontSize: '9px' }}>Unit Price</Text>
                            <Text style={{ width: '9%', fontSize: '9px' }}>Subtotal</Text>
                        </View>
                        <View style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            paddingBottom: '10px'
                        }}>
                            {order.refunds.map((refund: any, index: number) => {
                                return (
                                    refund.refund_items?.map((refundItem: any, index2: number) => {
                                        const date = new Date(refund.transaction_date).toLocaleDateString()
                                        const orderItem = orderItems.find((i: any) => i.id === refundItem.order_item_id)
                                        const images = orderItem.cp_product_image && orderItem.cp_product_image.split("||");
                                        const productImage = images?.find((img: string) => img).replace(/\"/g, "");

                                        return (
                                            <>
                                                <View wrap={false} key={index + index2} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: 'center',
                                                    flexWrap: "wrap",
                                                    paddingBottom: '4px',
                                                    paddingTop: '2px',
                                                    borderBottom: '1px solid #dee2e6'
                                                }}>
                                                    <View style={{ width: '9%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}>
                                                        <Text>{date}</Text>
                                                    </View>
                                                    <View wrap={false} style={{
                                                        width: '25%', fontSize: '8px', display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "wrap"
                                                    }}>
                                                        {/*<View style={{ width: '20%' }}>*/}
                                                        {/*    <Image src={productImage}  */}
                                                        {/*        style={{ width: 100,  height: 100 }} />*/}
                                                        {/*</View>*/}
                                                        <View style={{ width: '78%', marginLeft: '2px', fontSize: '6px' }}>
                                                            <Text>{orderItem?.product_info.title}</Text>
                                                            <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                                <Text style={{ color: '#6c757d' }}>Mfr Part#: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.mpn}</Text>
                                                            </View>
                                                            <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                                <Text style={{ color: '#6c757d' }}>Seller Item Sku: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.offer_sku}</Text>
                                                            </View>
                                                            <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                                <Text style={{ color: '#6c757d' }}>MPIN: </Text><Text style={{ color: '#212529', fontSize: '4px', paddingTop: '2px' }}>{orderItem?.product_info.mpin}</Text>
                                                            </View>
                                                            <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                                <Text style={{ color: '#6c757d' }}>Condition: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.condition}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '16%' }}>
                                                        <Text>{refund.transaction_number}</Text>
                                                    </View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '17%' }}>
                                                        <Text>{reasons.find((reason: any) => reason.value === refundItem.reason)?.label}</Text>
                                                    </View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '7%' }}><Text>{orderItem.quantity}</Text></View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '11%' }}><Text>$ {addCommaFunc(orderItem.unit_price, maxDecimalsInUnitPrice)}</Text></View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '4%' }}></View>
                                                    <View style={{ fontSize: '8px', fontFamily: 'Roboto', width: '9%' }}><Text>$ {addCommaFunc(orderItem.cp_price, maxDecimalsInUnitPrice)}</Text></View>
                                                </View>
                                            </>
                                        )
                                    }))
                            })}
                        </View>
                    </View> : <></>}

                    <View style={{ margin: '15px 5px 5px 5px', border: '1px solid #dee2e6', paddingBottom: '5px', borderRadius: '3px' }}>
                        <View wrap={false} style={{
                            backgroundColor: '#f7f7f7', display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            padding: '5px'
                        }}>
                            <BagIcon />
                            <Text style={{ width: '88%', fontSize: '15px' }}>Product Details for Order #{order?.order_number}</Text>
                        </View>
                        <View wrap={false} style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            borderBottom: '1px solid black',
                            margin: '10px',
                            paddingBottom: '2px'
                        }}>
                            <Text style={{ width: '11%', fontSize: '10px' }}>Item</Text>
                            <Text style={{ width: '26%', fontSize: '10px' }}>Product</Text>
                            <Text style={{ width: '17%', fontSize: '10px' }}>Shipping From</Text>
                            <Text style={{ width: '11%', fontSize: '10px', marginRight: '3px' }}>Status</Text>
                            <Text style={{ width: '8%', fontSize: '10px' }}>Quantity</Text>
                            <Text style={{ width: '11%', fontSize: '10px' }}>Unit Price</Text>
                            <Text style={{ width: '10%', fontSize: '10px' }}>Subtotal</Text>
                        </View>
                        <View wrap={false} style={{
                            marginLeft: '10px',
                            marginRight: '10px'
                        }}>
                            {
                                orderItems.map((orderItem: any, index: number) => {
                                    const shipping_from = locationOptions.find(
                                        (loc: any) => loc.value === orderItem.shipping_location_id
                                    )
                                    const images = orderItem.cp_product_image && orderItem.cp_product_image.split("||");
                                    const productImage = images?.find((img: string) => img).replace(/\"/g, "");
                                    return (
                                        <View wrap={false} key={index} style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: 'center',
                                            flexWrap: "wrap",
                                            paddingBottom: '4px',
                                            paddingTop: '2px',
                                            borderBottom: '1px solid #dee2e6'
                                        }}>
                                            <View style={{
                                                width: '11%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto',
                                            }}>
                                                <Text>{`#${order.order_number + "_" + orderItem?.index}`}</Text>
                                            </View>
                                            <View wrap={false} style={{
                                                width: '26%', fontSize: '8px', display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap"
                                            }}>
                                                {/*<View style={{ width: '20%' }}>*/}
                                                {/*    <Image src={productImage} style={{ width: 100, height: 'auto' }} />*/}
                                                {/*</View>*/}
                                                <View style={{ width: '78%', marginLeft: '2px', fontSize: '6px' }}>
                                                    <Text>{orderItem?.product_info.title}</Text>
                                                    <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                        <Text style={{ color: '#6c757d' }}>Mfr Part#: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.mpn}</Text>
                                                    </View>
                                                    <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                        <Text style={{ color: '#6c757d' }}>Seller Item Sku: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.offer_sku}</Text>
                                                    </View>
                                                    <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                        <Text style={{ color: '#6c757d' }}>MPIN: </Text><Text style={{ color: '#212529', fontSize: '4px', paddingTop: '2px' }}>{orderItem?.product_info.mpin}</Text>
                                                    </View>
                                                    <View wrap={false} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontFamily: 'Roboto' }}>
                                                        <Text style={{ color: '#6c757d' }}>Condition: </Text><Text style={{ color: '#212529' }}>{orderItem?.product_info.condition}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ width: '17%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}>
                                                <Text>{shipping_from?.label}</Text>
                                            </View>
                                            <View style={{
                                                width: '11%', fontSize: '8px',
                                                fontFamily: 'Roboto', marginRight: '3px'
                                            }}>
                                                <Text style={{
                                                    color: createStatusBadge(orderItem.cp_status)[0], backgroundColor: createStatusBadge(orderItem.cp_status)[1], textAlign: 'center', borderRadius: '7px',
                                                    marginRight: '2px',
                                                    padding: '2px 1px 1px 1px',
                                                }}>{separateWithSpace(orderItem.cp_status)}</Text>
                                            </View>
                                            <View style={{ width: '8%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}>
                                                <Text>{orderItem.quantity}</Text>
                                            </View>
                                            <View style={{ width: '11%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}>
                                                <Text>$ {addCommaFunc(orderItem.unit_price, maxDecimalsInUnitPrice)}</Text>
                                            </View>
                                            <View style={{ width: '5%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}></View>
                                            <View style={{ width: '10%', fontSize: '8px', color: '#212529', fontFamily: 'Roboto' }}>
                                                <Text>$ {addCommaFunc(orderItem.cp_price, maxDecimalsInUnitPrice)}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                </View>
                {!(order?.status === "Pending" || order?.status === "Rejected") && (<>
                    <View wrap={false} style={{ width: '100%', border: '1px solid #dee2e6', marginBottom: '5px', borderRadius: '3px', marginTop: '8px' }}>
                        <View wrap={false} style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: '15px',
                            backgroundColor: '#f7f7f7',
                            padding: '5px'
                        }}>
                            <TruckIcon />
                            <Text style={{ width: '90%', fontSize: '14px' }}>Shipping Information</Text>
                        </View>
                        <View wrap={false} style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", padding: '9px 6px 6px 6px'
                        }}>
                            <View style={{ width: '26%', fontSize: '9px' }}>
                                <Text style={{ paddingBottom: '7px', color: '#212529' }}>SHIPPING TO:</Text>
                                <Text style={{ paddingBottom: '4px', fontFamily: 'Roboto' }}>{customer?.first_name && customer?.last_name ? customer?.first_name + " " + customer?.last_name : "Unknown"}</Text>
                                <Text style={{ paddingBottom: '3px', color: '#313539' }}>{customer?.shipping_address?.company}</Text>
                                <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.shipping_address?.line1}</Text>
                                <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.shipping_address?.line2}</Text>
                                <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.shipping_address?.city} {customer?.shipping_address?.state}, {customer?.shipping_address?.zipcode}</Text>
                                <Text style={{ paddingBottom: '8px', fontFamily: 'Roboto', color: '#6c757d' }}>{customer?.shipping_address?.country}</Text>
                                <Text style={{ paddingBottom: '4px', color: '#212529' }}>Shipping Method</Text>
                                <Text style={{ textTransform: 'capitalize' }}>{order?.shipping_method_code}</Text>
                                <Text style={{ paddingBottom: '4px', color: '#212529' }}>Preferred Shipping Method</Text>
                                <Text style={{ paddingBottom: '5px', fontFamily: 'Roboto', color: '#6c757d', textTransform: 'capitalize' }}>{order?.shipping_carrier?.name}</Text>
                                {order?.shipping_carrier?.number && <Text style={{ paddingBottom: '5px', fontFamily: 'Roboto', color: '#6c757d' }}>Account No. {order?.shipping_carrier?.number}</Text>}
                                <Text style={{ paddingBottom: '4px', color: '#212529' }}>Recipient Name</Text>
                                {customer?.shipping_address?.recipient_name && <Text style={{ paddingBottom: '7px', fontFamily: 'Roboto', color: '#6c757d' }}>{customer.shipping_address.recipient_name}</Text>}
                            </View>
                            <View style={{ width: '73%', fontSize: '9px' }}>
                                <View wrap={false} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    paddingBottom: '2px',
                                    borderBottom: '1px solid #212529'
                                }}>
                                    <Text style={{ width: '33%', marginRight: '2px' }}>Tracking Number</Text>
                                    <Text style={{ width: '47%', marginRight: '2px' }}>Items</Text>
                                    <Text style={{ width: '18%' }}>Carrier</Text>
                                </View>
                                <View>
                                    {trackingItems?.length ? trackingItems.map((item: any, index: number) => {
                                        const trackingItemsInfo = item.shipping_items.map((shippingItem: any) => {
                                            const orderItem = orderItems.find((x: any) => x.id == shippingItem.order_item_id)
                                            return {
                                                // mpin: orderItem?.product_info.mpin,
                                                seller_item_sku: orderItem?.product_info.offer_sku,
                                                mfr_part_number: orderItem?.product_info.mpn,
                                                quantity: shippingItem.quantity
                                            }
                                        })
                                        return (
                                            <View wrap={false} key={index} style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                borderBottom: '1px solid #dee2e6'
                                            }}>
                                                <View wrap={false} style={{
                                                    width: '27%', marginRight: '2px', fontFamily: 'Roboto', height: '100%', display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: 'center',
                                                    flexWrap: "wrap"
                                                }}><Text>{item?.tracking_number}</Text></View>
                                                <View style={{ width: '54%', marginRight: '2px', padding: '6px 0' }}>
                                                    {
                                                        trackingItemsInfo.map((trackingItem: any, index2: number) => {
                                                            return (
                                                                <View key={index2}>
                                                                    <Text style={{ fontFamily: 'Roboto' }}>Mfr Part#: {trackingItem.mfr_part_number}</Text>
                                                                    <Text style={{ fontFamily: 'Roboto' }}>Seller Item Sku: {trackingItem.seller_item_sku}</Text>
                                                                    <Text style={{ fontFamily: 'Roboto', paddingBottom: '2px' }}>MPIN: {trackingItem.mpin}</Text>
                                                                    <Text style={{ color: '#5c656d', paddingBottom: '7px', fontSize: '11px' }}>Quantity: {trackingItem.quantity}</Text>
                                                                </View>
                                                            )
                                                        })
                                                    }
                                                </View>
                                                <View style={{
                                                    width: '17%', fontFamily: 'Roboto', height: '100%', display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: 'center',
                                                    flexWrap: "wrap"
                                                }}>
                                                    <Text>{item?.carrier_key}</Text>
                                                </View>
                                            </View>
                                        )
                                    }) : <Text style={{ fontSize: '10px', paddingTop: '5px', textAlign: 'center' }}> No Records Found.</Text>}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View wrap={false} style={{ border: '1px solid #dee2e6', borderRadius: '3px', marginTop: '5px' }}>
                        <View wrap={false} style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: '15px',
                            backgroundColor: '#f7f7f7',
                            padding: '5px'
                        }}>
                            <CreditCartIcon />
                            <Text style={{ width: '90%', fontSize: '14px' }}>Billing Information</Text>
                        </View>
                        <View style={{ padding: '9px 6px 6px 6px', fontSize: '9px' }}>
                            <Text style={{ color: '#6c757d', paddingBottom: '3px' }}>Payment Method</Text>
                            <Text style={{ paddingBottom: '7px' }}>{separateWithSpace(order?.payment_method)}</Text>
                            <Text style={{ paddingBottom: '4px' }}>{customer?.first_name && customer?.last_name ? customer?.first_name + " " + customer?.last_name : "Unknown"}</Text>
                            <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.billing_address?.company}</Text>
                            <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.billing_address?.line1}</Text>
                            <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.billing_address?.line2}</Text>
                            <Text style={{ fontFamily: 'Roboto', paddingBottom: '3px' }}>{customer?.billing_address?.city} {customer?.billing_address?.state}, {customer?.billing_address?.zipcode}</Text>
                            <Text style={{ paddingBottom: '3px', fontFamily: 'Roboto', color: '#6c757d' }}>{customer?.billing_address?.country}</Text>
                        </View>
                    </View></>)}
            </Page>
        </Document >
    )
}

export default OrderDetailsPdf