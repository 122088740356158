import { FC, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  useSellerProductsQuery,
  useUpdateSellerProductStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, mutationInfo } from "../../common/miscellaneous/utility";
import {
  IActionState,
  IDateRange,
  IRef,
  MutationAction,
  OrderByType
} from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";

export const reasonOptions = [
  { label: "Please select a reason", value: "" },
  { label: "Duplicate Product", value: "DuplicateProduct" },
  { label: "Content Policy Violation", value: "ContentPolicyViolation" },
  { label: "Poor Quality Images", value: "PoorQualityImages" },
];

export const PendingProductList: FC = () => {
  const userInfo = useUserInfo();
  const formatDate = useDateFormat();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const { number_items_per_page } = context.operatorSettings.preset;
  const { check_mfr } = context.operatorSettings.product;
  const [actionState, setActionState] = useState<IActionState>({
    ids: null,
    action: "",
    errorMsg: "",
  });
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [{ fetching }, updateMutation] = useUpdateSellerProductStatusMutation();
  const baseRoute = `/${tenantUrlTag}`;
  const productRoute = `${baseRoute}/products`;
  const mfrRoute = `${baseRoute}/settings/manage-manufacturer-names`;
  const navigate = useNavigate();

  const managePerm = userInfo.permissions! & context.permissions.manage_product;
  const operatorManage = managePerm && userInfo && userInfo.user_level! > 10;
  const sellerEdit = managePerm && userInfo && userInfo.user_level! === 10;

  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Created Date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Product: "product_title",
    MPN: "mpn",
    Manufacturer: "mfr",
    Submitted_By: "seller_name",
    Created_Date: "created_date",
    Status: "status",
  };
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const tableColumns = operatorManage
    ? ["Created Date", "MPN", "Product", "Manufacturer", "Submitted By", "Status", "Actions"]
    : ["Created Date", "MPN", "Product", "Manufacturer", "Status", "Actions"];
  const tableColumnsToSearch = ["product_title", "seller_name", "mpn", "mfr"];
  const addFilterOptions = [
    { label: "Product", value: "prodcut_title" },
    { label: "Submitted by", value: "seller_name" },
    { label: "MPN", value: "mpn" },
    { label: "Manufacturer", value: "mfr" },
    { label: "Status", value: "status" },
  ];

  const inputs = {
    variables: {
      args: { check_mfr, op_id: userInfo.operator_id! },
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Created_Date]: "desc" },
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
        })
      ),
    },
  };

  const tableBulkActions = managePerm ? ["Approve", "Reject"] : [];
  const [queried] = useSellerProductsQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "PendingProductList", errMsg: error.message }} />;
  }
  if (!queried.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
      </Card>
    );
  }

  const products = queried.data.imports_mfr_seller_product;
  const totalProducts = queried.data?.total_products?.aggregate?.count ?? 0;
  const tableData = products.map((product) => {
    const actions = [
      {
        enabled: true,
        actionType: managePerm ? "edit" : "view",
        id: product.id,
        display: managePerm ? "Edit" : "View",
        icon: "bi bi-pencil",
        actionFunc: () => navigate(`${productRoute}/pending/${product.id}`),
      },
      {
        enabled: operatorManage && !product.check_mfr,
        actionType: "approve",
        id: product.id,
        display: "Approve",
        icon: "bi bi-check-circle",
        actionFunc: () => setActionState({ ...actionState, ids: [product.id], action: "Approve" }),
      },
      {
        enabled: operatorManage && product.status !== "Rejected",
        actionType: "reject",
        id: product.id,
        display: "Reject",
        icon: "bi bi-slash-circle",
        actionFunc: () => setActionState({ ...actionState, ids: [product.id], action: "Reject" }),
      },
    ];
    const productImages = getProdImgUrls(product.data?.product_image_urls);

    return {
      id: product.id,
      Product: (
        <div className="product-wrapper d-flex flex-row align-items-center">
          {productImages?.length ? (
            <div className="product-image me-2 mt-0" style={{ overflow: "hidden" }}>
              <img
                src={productImages[0]}
                data-url={productImages[0]}
                alt="no_image"
                height="60"
                width="60"
                className="img-fluid"
              />
            </div>
          ) : (
            <div className="img-placeholder d-flex justify-content-center align-items-center mt-1 me-2">
              <i className="bi bi-card-image"></i>
            </div>
          )}
          <div className="product-details">
            {product.product_title ? (
              <NavLink
                data-testid="nav-message"
                to={{ pathname: `${productRoute}/pending/${product.id}` }}
                state={{ tab: "pending" }}
                className="nav-link text-truncate text-decoration-underline product-title pb-0 pt-0"
              >
                {product.product_title}
              </NavLink>
            ) : (
              "No Title"
            )}

            <div className="product-category d-flex align-items-center text-muted mb-0">
              <span className="category-wrapper font-weight-semi-bold small me-1">Category:</span>
              <span>{product.product_category}</span>
            </div>
          </div>
        </div>
      ),
      Manufacturer: product.check_mfr ?
        <div>
          {managePerm ?
            <NavLink
              data-testid="manage mfr"
              to={mfrRoute}
              className="nav-link text-decoration-underline"
              title={product.mfr || ""}
            >
              {product.mfr}
            </NavLink>
            : product.mfr}
          <span className="ms-2 tooltip-custom right">
            <span className="tooltip-text">Verify mfr needed
            </span>
            <i className="bi bi-exclamation-circle text-danger"></i>
          </span>
        </div>
        : product.mfr,
      Submitted_By: (
        <NavLink
          data-testid="submitted_by_seller"
          to={`/${tenantUrlTag}/sellers/${product.seller_id}`}
          className="nav-link text-decoration-underline"
          title={product.seller_name || ""}
        >
          {product.seller_name}
        </NavLink>
      ),
      MPN: (
        <div className="d-flex flex-row align-items-center">
          <p className="mb-0">{product.mpn}</p>
        </div>
      ),
      Created_Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.created_date)}</p>
          <span className="small text-muted">
            {formatDate(product.created_date, "time")}
          </span>
        </div>
      ),
      Status: (
        <>
          <div className={`badge rounded-pill ${badgeCss(product.status)}`}>{product.status}</div>
          {product.reason && (
            <div className="mt-2 display-7 text-muted text-left">
              {reasonOptions.find((option) => option.value === product.reason)?.label}
            </div>
          )}
        </>
      ),
      Actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    const action = multiSelectRef.current?.selectedOption;
    if (ids && ids.length !== 0) setActionState({ ...actionState, ids, action: action! });
    return true;
  };

  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      if (actionState.action === "Approve") await updateDatabase();
    } else {
      resetData();
    }
  };

  const resetData = () => {
    multiSelectRef.current?.clearSelectedData();
    reset();
    setActionState({ ids: null, action: "", errorMsg: "" });
  };

  const onSubmit = async (data: FieldValues) => {
    await updateDatabase(data.reason);
  };

  const updateDatabase = async (reason: string | null = null) => {
    const status = actionState.action === "Approve" ? "Approved" : "Rejected";
    if (
      products.filter((prod) => actionState.ids?.includes(prod.id) && prod.status === status)
        .length === actionState.ids?.length
    ) {
      setActionState({
        ...actionState,
        errorMsg: `Project status can't be ${status.toLowerCase()}`,
      });
      return;
    }
    const transId = v4();
    const transObj = {
      status_by: userInfo.user_id,
      status,
      reason,
      id: transId,
    };
    const res = await updateMutation(
      { ids: actionState.ids, status, transId, reason: reason, transObj },
      { additionalTypenames: ["imports_vw_seller_product", "imports.seller_product"] }
    );

    alertsRef.current?.generate(mutationInfo("product status", MutationAction.Update, res));
    if (res.error) {
      return;
    }
    resetData();
  };

  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          options={tableBulkActions}
          data={tableData}
          ref={multiSelectRef}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalProducts}
          multiSelectTable={!sellerEdit ? true : false}
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          setSearchValue={setSearchValue}
          addFilterOptions={addFilterOptions}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          applyChanges={applyChanges}
          searchHint="Search by Title,MPN,Manufacturer,Seller Name"
          searchPlaceHolder="Search by Title,MPNz... "
        />
      </Card>
      <Dialog
        show={actionState.action === "Approve"}
        title="Approve Product"
        continueText="Approve"
        continue={continueAction}
        contineBtnCss={"btn-success"}
        size="lg"
        fetching={fetching}
        errorMsg={actionState.errorMsg}
      >
        Approved products are visible to customers on your marketplace. Approve this product?
      </Dialog>
      <Dialog
        show={actionState.action === "Reject"}
        title="Reject Product"
        continueText="Reject"
        subFormId="reject-product-form"
        continue={continueAction}
        contineBtnCss={"btn-danger"}
        size="lg"
        fetching={fetching}
        errorMsg={actionState.errorMsg}
      >
        <FormProvider {...methods}>
          <Form
            data-testid="reject-product-form"
            id="reject-product-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          >
            <p className="mb-2">
              Rejecting the submission of this product will send an email notification to the seller
              informing them of the reason the product was rejected. Please select the reason for
              rejecting this product below.
            </p>
            <FormSelect
              reg_options={{ required: true }}
              options={reasonOptions}
              label="Reason"
              name="reason"
            />
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default PendingProductList;
