import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  ShippingCarrierPartFragment,
  Shipping_Carrier_Insert_Input,
  useCreateShippingCarrierMutation,
  useEditShippingCarrierMutation,
} from "../../../generated/urql-graphql";

import _ from "lodash";
import { Dialog } from "../../common/components/Dialog";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";

type IProps = {
  onClose: () => void;
  carrier: ShippingCarrierPartFragment | null
};

export const ShippingCarrierForm: FC<IProps> = (props): ReturnType<FC> => {
  const {
    onClose,
    carrier,
  } = props;
  const userInfo = useUserInfo();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const action = carrier ? "Edit" : "Add";

  const [{ fetching: creating }, createMutation] = useCreateShippingCarrierMutation();
  const [{ fetching: editing }, editMutation] = useEditShippingCarrierMutation();

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue
  } = methods;

  const status = watch("status") || false;
  const shpCarrierVal = watch("shipping_carrier");

  useEffect(() => {
    if (carrier) {
      reset(carrier);
    }
  }, [carrier]);

  useEffect(() => {
    if (!carrier && shpCarrierVal)
      setValue("key", shpCarrierVal.replace(/ /g, "-").toLocaleLowerCase());
  }, [shpCarrierVal]);

  const onSubmit = async (formData: FieldValues) => {
    let res;
    if (carrier) {//update
      res = await editMutation({
        id: carrier.id,
        shpCarrier: { ..._.omit(formData, "__typename"), updated_date: 'now()' },
      });
    }
    else { //create
      const shpCarrier = {
        ...formData,
        operator_id: userInfo.operator_id,
      } as Shipping_Carrier_Insert_Input;
      res = await createMutation({ shpCarrier });
    }
    if (!res.error) {
      onClose();
      setErrorMsg("");
      mutationInfo("the shipping carrier",
        carrier ? MutationAction.Update : MutationAction.Create, res);
    }
    else {
      setErrorMsg(res.error.message);
    }
  }

  const continueAction = (isContinue: boolean) => {
    if (!isContinue)
      onClose();
  }

  return (
    <Dialog
      title={`${action} Shipping carrier`}
      continue={continueAction}
      continueText="Save"
      size="lg"
      errorMsg={errorMsg}
      fetching={creating || editing}
      subFormId="manage_shipping_carrier_form"
      footerText={
        <FormInput
          form_methods={methods}
          label={`Status: ${status ? "Enabled" : "Disabled"}`}
          name="status"
          type="checkbox"
          isswitch={true}
        />
      }
    >
      <FormProvider {...methods}>
        <Form
          data-testid=""
          id="manage_shipping_carrier_form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="form-group row mb-3">
            <div className="col-6">
              <FormInput
                name="shipping_carrier"
                label="Shipping Carrier"
                reg_options={{ required: true, maxLength: 80 }}
                css="mb-0"
              />
              <span className="small text-muted">Your sellers will see this</span>
            </div>
            <div className="col-6">
              <FormInput
                name="key"
                label="Key/Code"
                readOnly={!!carrier}
                reg_options={{
                  pattern: {
                    value: /^\S*$/,
                    message: "There cannot be whitespaces in Key/Code!"
                  }
                }}
              />
            </div>
          </div>
        </Form>
      </FormProvider>
    </Dialog>
  );
};
