import { FC, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid, Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Vw_Report_Operator_Offer_Count_Group_By_Day,
  Vw_Report_Operator_Offer_Count_Group_By_Month,
  Vw_Report_Operator_Product_Count_Group_By_Day,
  Vw_Report_Operator_Product_Count_Group_By_Month,
  Vw_Report_Operator_Store_Count_Group_By_Day,
  Vw_Report_Operator_Store_Count_Group_By_Month,
  useDashboardOverviewCountByDayQuery,
  useDashboardOverviewCountByMonthQuery
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { addCommaFunc } from "../../orders-route/handler/handler";


const dataInit = [
  {
    key: "January",
    name: "January",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "February",
    name: "February",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "March",
    name: "March",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "April",
    name: "April",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "May",
    name: "May",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "June",
    name: "June",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "July",
    name: "July",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "August",
    name: "August",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "September",
    name: "September",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "October",
    name: "October",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "November",
    name: "November",
    stores: 0,
    products: 0,
    offers: 0,
  },
  {
    key: "December",
    name: "December",
    stores: 0,
    products: 0,
    offers: 0,
  },
];
interface ReportDataType {
  key: string;
  name: string;
  stores: number;
  products: number;
  offers: number;
}

const findByMatchingProperties = (
  reportData: Array<ReportDataType>,
  matchingReportData: ReportDataType
): ReportDataType | undefined => {
  return reportData.find((reportItem) => reportItem.key === matchingReportData["key"]);
};
const LastNDays = (nDays: number): Array<ReportDataType> => {
  const dates = [...Array(nDays)].map((_, index) => {
    const today = new Date();
    today.setDate(today.getDate() - index);
    return {
      key:
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2),
      name: ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2),
      stores: 0,
      products: 0,
      offers: 0,
    };
  });
  return dates.reverse();
};
export const LineChartComponent: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const [operatorId, setOperatorId] = useState<string>(userInfo.operator_id!);
  let storeCount = 0;
  let productCount = 0;
  let offersCount = 0;
  let reportData: ReportDataType[] | undefined = [];
  let currentDate = new Date();
  let last30Days = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  let last30DaysUTC = Date.UTC(
    last30Days.getUTCFullYear(),
    last30Days.getUTCMonth(),
    last30Days.getUTCDate(),
    23,
    59,
    59
  );
  let last60Days = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
  let last60DaysUTC = Date.UTC(
    last60Days.getUTCFullYear(),
    last60Days.getUTCMonth(),
    last60Days.getUTCDate(),
    23,
    59,
    59
  );
  let filter30Days = `{ "_gt": "${new Date(last30DaysUTC).toISOString()}"}`;
  let filter60Days = `{ "_gt": "${new Date(last60DaysUTC).toISOString()}"}`;
  const [where, setWhere] = useState<string>(`{
              "operator_id": { "_eq": "${operatorId}"},
              "created_date_tz": ${filter30Days}
           }`);
  const [whereMonth, setWhereMonth] = useState<string>(`{
              "operator_id": { "_eq": "${operatorId}" },
               "month_tz" : { "_like": "${9999}%"}
            }`);
  let inputs = {
    variables: {
      where1: JSON.parse(where),
      where2: JSON.parse(where),
      where3: JSON.parse(where),
    },
  };
  let inputMonths = {
    variables: {
      where1: JSON.parse(whereMonth),
      where2: JSON.parse(whereMonth),
      where3: JSON.parse(whereMonth),
    },
  };
  const [reportOperatorCountOverview] = useDashboardOverviewCountByDayQuery(inputs);
  const [reportOperatorCountByMonthOverview] = useDashboardOverviewCountByMonthQuery(inputMonths);

  const [viewState, setViewState] = useState<string>("Last30");
  useEffect(() => {
    if (reportOperatorCountOverview.data) {
      setLoading(false);
    }

  }, [reportOperatorCountOverview.data]);

  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setWhere(`{
        "operator_id": { "_eq": "${userInfo.operator_id}" },
        "created_date_tz": ${filter30Days}
      }`);
      setOperatorId(userInfo.operator_id);
    }
  }, [userInfo]);

  const handleReportButtonClick = (event: React.MouseEvent<HTMLButtonElement>, rptType: string) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    if (rptType === "Last30") {
      setViewState("Last30");
      setWhere(`{
              "operator_id": { "_eq": "${operatorId}" },
              "created_date_tz": ${filter30Days}
           }`);
    } else
      if (rptType === "Last60") {
        setWhere(`{
              "operator_id": { "_eq": "${operatorId}" },
              "created_date_tz": ${filter60Days}
            }`);
        setViewState("Last60");
      } else
        if (rptType === "YTD") {
          setWhereMonth(`{
              "operator_id": { "_eq": "${operatorId}" },
               "month_tz" : { "_like": "${currentYear}%"}
            }`);
          setViewState("YTD");

        }
  };

  const error = reportOperatorCountOverview.error;
  if (error) {
    return <PageError error={{ source: "LineChartComponent", errMsg: error.message }} />;
  }

  if (viewState === "Last30") {
    reportData = LastNDays(30);
    storeCount = 0;
    productCount = 0;
    offersCount = 0;
    const dataStore = reportOperatorCountOverview.data?.vw_report_operator_store_count_group_by_day;
    /*reportStoreOverview.data
      ? reportStoreOverview.data.vw_report_operator_store_count_group_by_day
      : reportOperatorCountOverview.data?.vw_report_operator_store_count_group_by_day;
      */

    dataStore?.forEach(
      (item: Vw_Report_Operator_Store_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            storeCount = storeCount + item.store_count;
            findItem["stores"] = item.store_count;
            findItem["products"] = 0;
            findItem["offers"] = 0;
          }
        }
      }
    );
    const dataProduct = reportOperatorCountOverview.data?.vw_report_operator_product_count_group_by_day;
    /*reportProductOverview.data
      ? reportProductOverview.data?.vw_report_operator_product_count_group_by_day
      : reportOperatorCountOverview.data?.vw_report_operator_product_count_group_by_day;
      */
    dataProduct?.forEach(
      (item: Vw_Report_Operator_Product_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            productCount = productCount + item.product_count;
            findItem["products"] = item.product_count;
          }
        }
      }
    );
    const dataOffer = reportOperatorCountOverview.data?.vw_report_operator_offer_count_group_by_day;
    /*reportOfferOverview.data
      ? reportOfferOverview.data?.vw_report_operator_offer_count_group_by_day
      : reportOperatorCountOverview.data?.vw_report_operator_offer_count_group_by_day;
      */

    dataOffer?.forEach(
      (item: Vw_Report_Operator_Offer_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            offersCount = offersCount + item.offer_count;
            findItem["offers"] = item.offer_count;
          }
        }
      }
    );
  } else if (viewState === "Last60") {
    storeCount = 0;
    productCount = 0;
    offersCount = 0;
    reportData = LastNDays(60);

    const dataStore = reportOperatorCountOverview.data?.vw_report_operator_store_count_group_by_day;
    /*reportStoreOverview.data
      ? reportStoreOverview.data.vw_report_operator_store_count_group_by_day
      : reportOperatorCountOverview.data?.vw_report_operator_store_count_group_by_day;
      */

    dataStore?.forEach(
      (item: Vw_Report_Operator_Store_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            storeCount = storeCount + item.store_count;
            findItem["stores"] = item.store_count;
            findItem["products"] = 0;
            findItem["offers"] = 0;
          }
        }
      }
    );

    const dataProduct = reportOperatorCountOverview.data?.vw_report_operator_product_count_group_by_day;
    /*reportProductOverview.data
    ? reportProductOverview.data?.vw_report_operator_product_count_group_by_day
    : reportOperatorCountOverview.data?.vw_report_operator_product_count_group_by_day;
    */

    dataProduct?.forEach(
      (item: Vw_Report_Operator_Product_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            productCount = productCount + item.product_count;
            findItem["products"] = item.product_count;
          }
        }
      }
    );

    const dataOffer = reportOperatorCountOverview.data?.vw_report_operator_offer_count_group_by_day;
    /*
      reportOfferOverview.data
    ? reportOfferOverview.data?.vw_report_operator_offer_count_group_by_day
    : reportOperatorCountOverview.data?.vw_report_operator_offer_count_group_by_day;
    */

    dataOffer?.forEach(
      (item: Vw_Report_Operator_Offer_Count_Group_By_Day) => {
        if (reportData && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData, {
            key: item.created_date_tz,
            name: "",
            stores: 0,
            products: 0,
            offers: 0,
          });
          if (findItem) {
            offersCount = offersCount + item.offer_count;
            findItem["offers"] = item.offer_count;
          }
        }
      }
    );
  } else
    if (viewState === "YTD") {
      storeCount = 0;
      productCount = 0;
      offersCount = 0;
      reportData = dataInit;
      const dataStore = reportOperatorCountByMonthOverview.data?.vw_report_operator_store_count_group_by_month;
      /*
        reportStoreOverview.data
      ? reportStoreOverview.data.vw_report_operator_store_count_group_by_month
      : reportOperatorCountOverview.data?.vw_report_operator_store_count_group_by_month;
      */

      dataStore?.forEach(
        (item: Vw_Report_Operator_Store_Count_Group_By_Month) => {
          if (item.month_tz) {
            let monthIndex: number = parseInt(item.month_tz?.substring(5) || "-1");

            if (reportData && monthIndex > -1) {
              reportData[monthIndex - 1]["stores"] = item.store_count;
              storeCount = storeCount + item.store_count;
            }
          }
        }
      );

      const dataProduct = reportOperatorCountByMonthOverview.data?.vw_report_operator_product_count_group_by_month;
      /*
        reportProductOverview.data
      ? reportProductOverview.data?.vw_report_operator_product_count_group_by_month
      : reportOperatorCountOverview.data?.vw_report_operator_product_count_group_by_month;
      */

      dataProduct?.forEach(
        (item: Vw_Report_Operator_Product_Count_Group_By_Month) => {
          if (reportData && item.month_tz) {
            let monthIndex: number = parseInt(item.month_tz?.substring(5) || "-1");
            if (monthIndex > -1) {
              reportData[monthIndex - 1]["products"] = item.product_count;
              productCount = productCount + item.product_count;
            }
          }
        }
      );

      const dataOffer = reportOperatorCountByMonthOverview.data?.vw_report_operator_offer_count_group_by_month;
      /*
        reportOfferOverview.data
      ? reportOfferOverview.data?.vw_report_operator_offer_count_group_by_month
      : reportOperatorCountOverview.data?.vw_report_operator_offer_count_group_by_month;
      */

      dataOffer?.forEach(
        (item: Vw_Report_Operator_Offer_Count_Group_By_Month) => {
          if (reportData && item.month_tz) {
            let monthIndex: number = parseInt(item.month_tz?.substring(5) || "-1");
            if (monthIndex > -1) {
              reportData[monthIndex - 1]["offers"] = item.offer_count;
              offersCount = offersCount + item.offer_count;
            }
          }
        }
      );
    }

  return (
    <>
      <div className="d-flex flex-xs-column flex-sm-column flex-md-row justify-content-between mb-3">
        <div className="btn-group">
          <h4 className="card-title me-2">
            <i className="bi bi-graph-up me-2"></i>Overview
          </h4>
          {/*
          {loading ? (
            <div data-testid=""
              className="spinner-border spinner-border-sm mt-2" role="status">
            </div>
          )
            :
            <Button
              data-testid=""
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                refreshData();
              }}
            >
              Refresh
            </Button>
          }
        */}
        </div>
        <div className="btn-group" role="group" ref={null}>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "Last30" ? "active" : ""
              }`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "Last30");
            }}
          >
            Last 30 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "Last60" ? "active" : ""
              }`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "Last60");
            }}
          >
            Last 60 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "YTD" ? "active" : ""}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "YTD");
            }}
          >
            YTD
          </Button>
        </div>
      </div>

      {(reportOperatorCountOverview.fetching || !reportOperatorCountOverview.data)
        ?
        <>
          <div className="d-flex justify-content-center align-items-center height-100 w-100 pb-5">
            <div className="ripple" role="status"></div>
          </div>
        </>
        :
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={reportData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" >
            </XAxis>
            <YAxis label={{ angle: -90, position: "insideLeft" }} />
            <Tooltip />
            <Legend />
            <Area
              name={`Sellers (${addCommaFunc(storeCount, 0)})`}
              type="monotone"
              dataKey="stores"
              stroke="#085873"
              fill="#085873"
              legendType="square"
            />
            <Area
              name={`Products (${addCommaFunc(productCount, 0)})`}
              type="monotone"
              dataKey="products"
              stroke="#00a3c7"
              fill="#00a3c7"
              legendType="square"
            />
            <Area
              name={`Offers (${addCommaFunc(offersCount, 0)})`}
              type="monotone"
              dataKey="offers"
              stroke="#9dd0e1"
              fill="#9dd0e1"
              legendType="square"
            />
          </AreaChart>
        </ResponsiveContainer>
      }
    </>
  );
};
