/* eslint-disable react/forbid-elements */
import { forwardRef, Ref } from "react";

type Props = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "ref"
> & {
  /**
   * test id for automation testing
   */
  "data-testid"?: string;
  /**
   * value of the input
   */
  value?: string;
  /**
   * id of the input to assign label
   */
  id?: string;
  /**
   * label to be assigned to input
   */
  label?: string;
  /**
   * boolean that determines if the checkbox is a switch control
   */
  isswitch?: boolean | string | null;
};

export const Checkbox = forwardRef(function CheckboxRef(
  props: Props,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  const { label, id, isswitch, ...aProp } = props;
  //const { isswitch, ...aProp } = props;
  return (
    <div className="d-flex flex-column mb-1">
      <div className={`form-check ${isswitch ? "form-switch" : ""}`}>
        <input className="form-check-input" type="checkbox" ref={ref} {...aProp} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
});

Checkbox.defaultProps = {
  isswitch: false,
};
