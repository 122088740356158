import { FC, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { Button } from "../../../forms/components/Button";
import { Form } from "../../../forms/components/Form";
import { FormInput } from "../../../forms/components/FormInput";
import { FormSelect } from "../../../forms/components/FormSelect";
import { ELoadingType, IFilterOption, ILocalSingleFilter } from "../../types/types";

interface IProps {
  addFilterOptions?: IFilterOption[];
  setOffset: (value: number) => void;
  setCurrentPage: (value: number) => void;
  localSingleFilters: ILocalSingleFilter[];
  setLocalSingleFilters: (value: ILocalSingleFilter[]) => void;
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  setLoading?: (value: number) => void;
  extraFilterOps?: string[];
}

interface IFilterOperator {
  display: string;
  op: string;
  type: string;
  def: boolean;
}

const filterOps: IFilterOperator[] = [
  { display: "starts with", op: "starts with", type: "string", def: true },
  { display: "contains", op: "contains", type: "string", def: false },
  { display: "does not contain", op: "does not contain", type: "string", def: false },
  { display: "equal", op: "equal", type: "string|numeric", def: true },
  { display: "greater than", op: "greater than", type: "numeric", def: true },
  { display: "greater than or equal", op: "greater than or equal", type: "numeric", def: true },
  { display: "less than", op: "less than", type: "numeric", def: true },
  { display: "less than or equal", op: "less than or equal", type: "numeric", def: true },
  { display: "is empty", op: "is empty", type: "string|numeric", def: true },
];

export const FilterDialog: FC<IProps> = (props: IProps) => {
  const {
    addFilterOptions,
    setOffset,
    setCurrentPage,
    showFilter,
    setShowFilter,
    localSingleFilters,
    setLocalSingleFilters,
    setLoading,
    extraFilterOps
  } = props;
  const [filterOp, setFilterOp] = useState<string>('');
  const [filter, setFilter] = useState<IFilterOption | null>(null);
  const defOps = filterOps.filter(fop => fop.def || extraFilterOps?.includes(fop.op));
  const [oprOptions, setOprOptions] = useState<IFilterOperator[]>(defOps);

  const filtersRef = useRef<HTMLDivElement>(null);
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods;

  const fltName = watch("filter_name");
  const fltOp = watch("filter_op");
  const fltValue = watch("filter_value");

  useEffect(() => {
    const nfilter = addFilterOptions?.find(opt => fltName ? opt.value === fltName : opt);
    if (nfilter) {
      setFilter(nfilter);
      const oprs = defOps.filter((item) =>
        nfilter.valueOptions ? item.op === "equal" :
          item.type.includes(nfilter.type || "string"));
      setOprOptions(oprs);
      setValue("filter_op", oprs.find(opr => opr)?.op);
    }
  }, [fltName]);

  useEffect(() => {
    setFilterOp(fltOp);
  }, [fltOp]);

  useEffect(() => {
    const validateClickOutsideTheModal = (event: MouseEvent) => {
      if (showFilter && filtersRef.current && !filtersRef.current.contains(event.target as Node)) {
        setShowFilter(false);
        reset();
      }
    };

    document.addEventListener("mousedown", validateClickOutsideTheModal);

    return () => {
      document.removeEventListener("mousedown", validateClickOutsideTheModal);
    };
  }, [showFilter, setShowFilter]);

  const onSubmit = (formData: FieldValues) => {
    setShowFilter(false);
    const adding = {
      id: v4(),
      filter_name: formData.filter_name,
      filter_op: filter?.op ? filter?.op : formData.filter_op,
      filter_value: filter?.filterValue ? filter?.filterValue :
        (filter?.value.includes("lower") ? formData.filter_value.toLowerCase() : formData.filter_value),
    };
    setLocalSingleFilters([...localSingleFilters, adding]);
    setOffset(0);
    setCurrentPage(1);
    setLoading && setLoading(ELoadingType.AddFilter);
  };

  return (
    <div
      className="filter-floating-card d-flex flex-column align-items-start modal-dialog modal-lg shadow my-0"
      ref={filtersRef}
    >
      <div className="modal-content">
        <div className="caret"></div>
        <FormProvider {...methods}>
          <Form
            data-testid=""
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          >
            <div className="modal-body pb-0">
              <div className="d-flex flex-xs-column flex-md-row gap-2">
                <div className="col">
                  <FormSelect
                    name="filter_name"
                    options={addFilterOptions}
                    required
                    reg_options={{ required: true }}
                  />
                </div>
                {!filter?.op &&
                  <>
                    <div className="col">
                      <FormSelect
                        name="filter_op"
                        options={oprOptions?.map(opt => ({ label: opt.display, value: opt.op }))}
                        reg_options={{ required: true }}
                      />
                    </div>
                    <div className="col">
                      {filter?.valueOptions ?
                        <div className="mb-3">
                          <FormSelect
                            name="filter_value"
                            options={filter?.valueOptions}
                            reg_options={{ required: true }}
                          />
                        </div>
                        :
                        (filterOp === 'is empty'
                          ?
                          <FormInput
                            name="filter_value"
                            reg_options={{
                              required: false
                            }}
                            type={filter?.type === "numeric" ? "number" : "text"
                            }
                            placeholder={"type a value"}
                            disabled={!!filter?.op || fltOp === 'is empty'}
                          />
                          :
                          <FormInput
                            name="filter_value"
                            reg_options={{
                              required: true
                            }}
                            type={filter?.type === "numeric" ? "number" : "text"
                            }
                            placeholder={"type a value"}
                            disabled={!!filter?.op || fltOp === 'is empty'}
                          />
                        )}
                    </div>
                  </>}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                data-testid=""
                className="btn btn-primary"
                disabled={fltOp !== 'is empty' && (filter?.op ? false : !fltValue)}
                type="submit"
              >
                Apply Filter
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </div >
  );
};
