import { FC, useEffect, useState } from "react";
import { useWebhookActionCountSubscription, useWebhookActionsSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { ELoadingType } from "../../common/types/types";
import { Label } from "../../forms/components/Label";
import { TextArea } from "../../forms/components/Textarea";
import { useMainContext } from "../../layout/components/MainProvider";

const WebhookHistory = (): ReturnType<FC> => {
    const formatDate = useDateFormat();
    const [loading, setLoading] = useState<number>(-1);
    const [offset, setOffset] = useState<number>(0);
    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false)
    const [paylaodDetail, setPayloadDetail] = useState<string>("")
    const [responseDetail, setResponseDetail] = useState<string>("")
    const [headersDetail, setHeadersDetail] = useState<string>("")
    const [context] = useMainContext();
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const baseRoute = `/${tenantUrlTag}/settings`;

    const { number_items_per_page } = context.operatorSettings.preset;
    const inputs = {
        variables: {
            limit: number_items_per_page,
            offset: offset
        }
    }
    const [queried] = useWebhookActionsSubscription(inputs);
    const [countQueried] = useWebhookActionCountSubscription();

    const tableColumns = ['Created', 'Scope', 'Method', 'Retry Count', 'Response Status Code', 'Destination', 'Actions']
    useEffect(() => {
        if (queried.data) {
            setLoading(ELoadingType.None);
        }
    }, [queried.data]);

    useEffect(() => {
        if (!showDetailsDialog) {
            setPayloadDetail("")
            setResponseDetail("")
            setHeadersDetail("")
        }
    }, [showDetailsDialog])
    const error = queried.error || countQueried.error;
    if (error) {
        return <PageError error={{ source: "ManufacturerView", errMsg: error.message }} />;
    }

    if (!queried.data) {
        return (
            <>
                <Card>
                    <PlaceholderTableSearchItem />
                    <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
                </Card>
            </>
        );
    }

    const viewDetailsOfWebhookAction = (paylaod: any, response: string, headers: any) => {
        const payloadText = JSON.stringify(paylaod)
        const headersText = JSON.stringify(headers)
        setPayloadDetail(payloadText || "")
        setResponseDetail(response || "")
        setHeadersDetail(headersText || "")
        setShowDetailsDialog(true)
    }
    const tableData = queried.data.audit_logged_webhook_actions.map(webhookAction => {
        const actions = [
            {
                enabled: true,
                actionType: "view",
                id: webhookAction.message_id,
                display: "View",
                icon: "bi bi-eye",
                actionFunc: () => viewDetailsOfWebhookAction(webhookAction.payload, webhookAction.response, webhookAction.headers),
            }
        ]
        return {
            Actions: <DropdownItems items={actions} />,
            id: webhookAction.message_id,
            Created: (
                <div className="date-created">
                    <p className="mb-0">{formatDate(webhookAction.created_date)}</p>
                    <span className="small text-muted">{formatDate(webhookAction.created_date, "time")}</span>
                </div>
            ),
            Retry_Count: webhookAction.retry_count,
            Method: webhookAction.method,
            Scope: webhookAction.scope,
            Response_Status_Code: webhookAction.response_status_code,
            Destination: webhookAction.url,
        }
    })

    const totalCount = countQueried.data?.audit_logged_webhook_actions_aggregate.aggregate?.count

    return (
        <>
            <Dialog title={"Webhook Actions Details"} closeText="Ok"
                contineBtnCss="btn-secondary"
                infoOnly
                continue={() => setShowDetailsDialog(false)}
                show={showDetailsDialog}
                staticModal={true}>
                <div className="d-flex flex-column">
                    <Label className="font-weight-semi-bold my-1" data-testid="">
                        Payload
                    </Label>
                    <TextArea className="mb-2" data-testid={""} ref={null} disabled rows={6} value={paylaodDetail} />
                    <Label className="font-weight-semi-bold my-1" data-testid="">
                        Response
                    </Label>
                    <TextArea className="mb-2" data-testid={""} ref={null} disabled rows={6} value={responseDetail} />
                    <Label className="font-weight-semi-bold my-1" data-testid="">
                        Headers
                    </Label>
                    <TextArea className="mb-2" data-testid={""} ref={null} disabled rows={6} value={headersDetail} />
                </div>
            </Dialog>
            <Card>
                <Table
                    columnNames={tableColumns}
                    data={tableData}
                    offset={offset}
                    setOffset={setOffset}
                    loading={loading}
                    setLoading={setLoading}
                    totalRecords={totalCount || 0}
                />
            </Card>
        </>
    )
}

export default WebhookHistory