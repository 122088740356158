import { FC } from "react";
import { OrderItemPartFragment } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { useReasonList } from "../../common/hooks/globals";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";
import { OrderItemBriefDescription } from "./OrderItems";

interface Refund_Items {
  reason: string;
  quantity: number;
  order_item_id: string;
}
interface Refund {
  id: string;
  transaction_date: string;
  transaction_number: string;
  shipping_amount?: number;
}
interface Order_Refund extends Refund {
  refund_items?: Refund_Items[];
}
type RefundsArray = Refund & Refund_Items & { data: OrderItemPartFragment };
interface IProps {
  refunds: Order_Refund[];
  orderItems: OrderItemPartFragment[];
}
const RefundSummaryComponent: FC<IProps> = ({ refunds, orderItems }) => {
  const refundsArray: RefundsArray[] = [];
  const reasons = useReasonList();
  const [context] = useMainContext();
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;
  refunds.forEach((refund) => {
    refund.refund_items?.forEach((rfitem: Refund_Items) => {
      const temp = orderItems.find((oitem) => oitem.id === rfitem.order_item_id);
      temp &&
        refundsArray.push({
          ...rfitem,
          data: temp,
          transaction_date: refund?.transaction_date,
          id: refund?.id,
          transaction_number: refund?.transaction_number,
        });
    });
  });

  return (
    <div className="col-12 mb-3">
      <Card className="card-warning">
        <div className="border-bottom card-header">
          <h5 className="d-flex card-title mb-0 align-items-center">
            <i className="bi bi-card-text me-2" />
            <span>Refund Summary</span>
          </h5>
        </div>
        <div className="card-body">
          <div className="table-responsive my-2">
            <table className="table table-sm align-middle">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Product</th>
                  <th scope="col">Reference Number</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Quantity</th>
                  <th scope="col" className="text-right">
                    Unit Price
                  </th>
                  <th scope="col" className="text-right">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                {refundsArray.map((refund, index) => {
                  const date = new Date(refund.transaction_date).toLocaleDateString();
                  return (
                    <tr key={index}>
                      <td>{date}</td>
                      <td>
                        <OrderItemBriefDescription orderItem={refund.data} />
                      </td>
                      <td>{refund.transaction_number}</td>
                      <td>{reasons.find(reason => reason.value === refund.reason)?.label}</td>
                      <td>{refund.quantity}</td>
                      <td className="text-right">$ {addCommaFunc(refund.data.unit_price)}</td>
                      <td></td>
                      <td className="text-right">
                        $ {addCommaFunc(refund.data.unit_price * refund.quantity, max_decimals_in_unit_price)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RefundSummaryComponent;
