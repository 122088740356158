import { FC } from "react"
import { Link, useParams } from "react-router-dom"
import { LiveFulfillmentRateQuery, SellerDataPartFragment } from "../../../generated/urql-graphql"
import { Card } from "../../common/components/Card"
import { useMainContext } from "../../layout/components/MainProvider"
import { addCommaFunc } from "../../orders-route/handler/handler"
import { SellerChart } from "./SellerChart"

interface Props {
    sellerData: SellerDataPartFragment;
    fulFillmentRateData: LiveFulfillmentRateQuery
}
const SellerDashboard: FC<Props> = (props): ReturnType<FC> => {
    const { sellerId } = useParams<{ sellerId: string }>();
    const sellerData = props.sellerData;
    const fulFillmentRateData = props.fulFillmentRateData.vw_report_fulfillment_rate[0]
    const [context] = useMainContext();
    const { enable_price_comparison_report } = context.operatorSettings.seller
    const tenantUrlTag = context.operatorInfo.tenant_url_tag;
    const sellerRoute = `/${tenantUrlTag}/sellers/${sellerId}`;

    const FormatNumber = (value: number, label: string): string => {
        return (value > 0 ? `${value} ${label}${value > 1 ? 's' : ''}` : '');
    }
    const displayInterval = (jsonInterval: string): string => {
        const jsonObj = JSON.parse(JSON.stringify(jsonInterval));
        const yy = FormatNumber(jsonObj.year, 'yr');
        const mth = FormatNumber(jsonObj.month, 'mth');
        const day = FormatNumber(jsonObj.day, 'day');
        const hr = FormatNumber(jsonObj.hour, 'hr');
        const min = FormatNumber(jsonObj.minute, 'min');
        const sec = FormatNumber(jsonObj.second, 'sec');

        return (
            yy
            + (yy === "" ? mth : ", " + mth)
            + (mth === "" ? day : ", " + day)
            + (day === "" ? hr : ", " + hr)
            + (hr === "" ? min : ", " + min)
            + (min === "" ? sec : ", " + sec)
        );
    };
    return (
        <>
            <SellerChart />
            <div className="row">
                <div className="col-lg-6 col-xl-4">
                    <Card>
                        <div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">All Offers</p>
                                <p className="mb-0 text-right">{sellerData?.cp_offers}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Total Orders</p>
                                <p className="mb-0 text-right">{sellerData?.cp_orders}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="font-weight-medium">Total Amount</p>
                                <p className="text-right">$ {addCommaFunc(sellerData?.cp_order_amount || 0.0)}</p>
                            </div>
                        </div>
                        <div className="border-top pt-3">
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Balance Pending</p>
                                <p className="mb-0 text-right">$ {addCommaFunc(sellerData?.cp_balance_pending || 0.0)}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Balance Payable</p>
                                <p className="mb-0 text-right">$ {addCommaFunc(sellerData?.cp_balance_payable || 0.0)}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="font-weight-medium mb-0">Balance Invoiced</p>
                                <p className="text-right mb-0">$ {addCommaFunc(sellerData?.cp_balance_invoiced || 0.0)}</p>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-lg-6 col-xl-4">
                    <Card>
                        <h4 className="card-title text-uppercase">Fulfillment Rates</h4>
                        <div className="fulfillment-card-wrapper">
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Acceptance Rate</p>
                                <p className="mb-0 text-right text-muted">
                                    {fulFillmentRateData.rate_accepted ?? "N/A"}%
                                </p>
                            </div>
                            <div
                                className="progress mb-2"
                                role="progressbar"
                                arial-aria-valuenow={fulFillmentRateData.rate_accepted}
                                arial-aria-valuemin={0}
                                ariel-aria-valuemax={100}
                            >
                                <div
                                    className={`progress-bar progress-bar-striped ${fulFillmentRateData.rate_accepted < 45
                                        ? "bg-danger"
                                        : fulFillmentRateData.rate_accepted < 70
                                            ? "bg-orange"
                                            : "bg-success"
                                        }`}
                                    style={{ width: `${fulFillmentRateData.rate_accepted}%` }}
                                ></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Cancelled Rate</p>
                                <p className="mb-0 text-right text-muted">
                                    {fulFillmentRateData.rate_cancelled ?? "N/A"}%
                                </p>
                            </div>
                            <div
                                className="progress mb-2"
                                role="progressbar"
                                arial-aria-valuenow={fulFillmentRateData.rate_cancelled}
                                arial-aria-valuemin={0}
                                ariel-aria-valuemax={100}
                            >
                                <div
                                    className={`progress-bar progress-bar-striped ${fulFillmentRateData.rate_cancelled < 20
                                        ? "bg-success"
                                        : fulFillmentRateData.rate_cancelled < 60
                                            ? "bg-orange"
                                            : "bg-danger"
                                        }`}
                                    style={{ width: `${fulFillmentRateData.rate_cancelled}%` }}
                                ></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Incident Rate</p>
                                <p className="mb-0 text-right text-muted">
                                    {fulFillmentRateData.rate_incident ?? "N/A"}%
                                </p>
                            </div>
                            <div
                                className="progress mb-2"
                                role="progressbar"
                                arial-aria-valuenow={fulFillmentRateData.rate_incident}
                                arial-aria-valuemin={0}
                                ariel-aria-valuemax={100}
                            >
                                <div
                                    className={`progress-bar progress-bar-striped ${fulFillmentRateData.rate_incident < 20
                                        ? "bg-success"
                                        : fulFillmentRateData.rate_incident < 45
                                            ? "bg-orange"
                                            : "bg-danger"
                                        }`}
                                    style={{ width: `${fulFillmentRateData.rate_incident}%` }}
                                ></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Rejected Rate</p>
                                <p className="mb-0 text-right text-muted">
                                    {fulFillmentRateData.rate_rejected ?? "N/A"}%
                                </p>
                            </div>
                            <div
                                className="progress mb-2"
                                role="progressbar"
                                arial-aria-valuenow={fulFillmentRateData.rate_rejected}
                                arial-aria-valuemin={0}
                                ariel-aria-valuemax={100}>
                                <div
                                    className={`progress-bar progress-bar-striped ${fulFillmentRateData.rate_rejected < 20
                                        ? "bg-success"
                                        : fulFillmentRateData.rate_rejected < 70
                                            ? "bg-orange"
                                            : "bg-danger"
                                        }`}
                                    style={{ width: `${fulFillmentRateData.rate_rejected}%` }}></div>
                            </div>
                            <hr className="bg-light-grey mt-4 mb-3" />
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Avg Acceptance Time</p>
                                <p className="mb-0 text-right">
                                    {fulFillmentRateData.avg_acceptance_time ? displayInterval(fulFillmentRateData.avg_acceptance_time_json) : "N/A"}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 font-weight-medium">Avg Time to ship</p>
                                <p className="mb-0 text-right">
                                    {fulFillmentRateData.avg_time_to_shipped ? displayInterval(fulFillmentRateData.avg_time_to_shipped_json) : "N/A"}
                                </p>
                            </div>
                        </div>
                    </Card>
                </div> {
                    enable_price_comparison_report && <div className="col-lg-6 col-xl-4">
                        <Card>
                            <h4 className="card-title text-uppercase">Price Comparison Report</h4>
                            <div>
                                <Link to={`${sellerRoute}/price-comparison-report`}>
                                    <p className="text-decoration-underline mb-0">See how your offer prices compare with other sellers</p>
                                </Link>
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </>
    )
}

export default SellerDashboard