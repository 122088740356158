import { FC, useState } from "react";
import { useStoreProductGroupPageQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { Table } from "../../common/components/Table/index";
import { SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { AddCost } from "./AddCost";
import { AddStoreProductGroup } from "./AddStoreProductGroup";

interface StorePgroup {
  id: string | null;
  store: string;
  pgroup: string;
  categories: SelectOption[];
}

export const Relationships: FC = () => {
  const [addStorePgrp, setAddStorePgrp] = useState<boolean>(false);
  const defSpgrp = {
    id: null,
    store: "",
    pgroup: "",
    categories: [],
  };
  const [storePgroup, setStorePgroup] = useState<StorePgroup>(defSpgrp);
  const [queried] = useStoreProductGroupPageQuery();

  const continueSave = (isContinue: boolean) => {
    if (!isContinue) {
      setAddStorePgrp(false);
    }
  };

  const continueAdd = (isContinue: boolean) => {
    if (!isContinue) {
      setStorePgroup(defSpgrp);
    }
  };

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "Relationships", errMsg: error.message }} />;
  }
  if (!queried.data) {
    return <Loader />;
  }

  const tableColumns = ["Seller", "Product Group", "Actions"];
  const tableData = queried.data.sotrePgrpList.map((data) => {
    const actions = [
      {
        enabled: true,
        actionType: "add",
        id: data.id,
        display: "Add Cost",
        icon: "bi bi-pencil",
        actionFunc: (id: string) =>
          setStorePgroup({
            id: id,
            store: data.seller.company_name,
            pgroup: data.product_group.name,
            categories: data.product_group.cp_categories,
          }),
      },
      {
        enabled: true,
        actionType: "delete",
        id: data.id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: () => { },
      },
    ];

    return {
      id: data.id,
      Seller: data.seller.company_name,
      Product_Group: data.product_group.name,
      Actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  const stores = queried.data.seller.map((seller) => {
    return { value: seller.id, label: seller.company_name };
  });

  const productGroups = queried.data.configs_product_group.map((grp) => {
    return { value: grp.id, label: grp.name };
  });

  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          data={tableData}
          offset={0}
          setOffset={() => { }}
          multiSelectTable
          totalRecords={0}
        />
        <div className="d-flex flex-row align-items-start">
          <Button
            data-testid=""
            className="btn btn-primary me-2"
            onClick={() => setAddStorePgrp(true)}
          >
            Add Relationship
          </Button>
        </div>
      </Card>
      <Dialog
        show={addStorePgrp}
        subFormId="vendor-product-group-form"
        title={`Add Seller/Product Group Relationship`}
        continueText="Save"
        staticModal={true}
        continue={continueSave}
        size="lg"
      >
        <AddStoreProductGroup
          closeDialog={() => setAddStorePgrp(false)}
          stores={stores}
          productGroups={productGroups}
        />
      </Dialog>
      <Dialog
        show={!!storePgroup.id}
        subFormId="store-pgroup-cost-form"
        title={`Add Cost`}
        continueText="Save"
        staticModal={true}
        continue={continueAdd}
        size="lg"
      >
        <AddCost closeDialog={() => setStorePgroup(defSpgrp)} storePgroup={storePgroup} />
      </Dialog>
    </>
  );
};
