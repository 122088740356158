import { FC } from "react";

type IntervalProps = { value: string };

export const IntervalFormat: FC<IntervalProps> = (props) => {
    return (
        <span>
            <span>{props.value ? props.value : 'N/A'}</span>
        </span>
    );
};
