import { FC, useEffect, useState } from "react";
import {
  Vw_Report_Sales_By_Product,
  Vw_Report_Sales_By_Product_Order_By,
  useSalesByProductQuery,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { SalesByProductData } from "./SalesByProductData";
export const SalesByProduct: FC = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/reports`;
  const [loading, setLoading] = useState<boolean>(false);
  let whereStr: string = `{"id": { "_eq": "00000000-0000-0000-0000-000000000000" }}`;
  let orderByStr: Vw_Report_Sales_By_Product_Order_By | Vw_Report_Sales_By_Product_Order_By[] = [];
  let tableData: {}[][] | undefined = [];
  const [exportClicked, setExportClicked] = useState<boolean>(false);
  const [input, setInput] = useState<{}>({
    variables: {
      limit: 0,
      offset: 0,
      order_by: orderByStr,
      where: JSON.parse(whereStr),
    },
  });
  let input2: {} = {};
  const [queried] = useSalesByProductQuery(input);

  useEffect(() => {
    let headers = [
      "Product MPIN,Category,SubCategory,Manufacturer,Orders,Gross Sales,Refund Sales,Total Sales",
    ];

    if (queried.data && exportClicked) {
      downloadFile({
        data: [...headers, tableData?.join("\n")].join("\n"),
        fileName: "SalesByProduct.csv",
        fileType: "text/csv",
      });
      setExportClicked(false);
      setLoading(false);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SalesByProduct", errMsg: error.message }} />;
  }

  const downloadFile = (value: { [key: string]: string }) => {
    const blob = new Blob([value.data], { type: value.fileType });

    const aLink = document.createElement("a");
    aLink.download = value.fileName;
    aLink.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    aLink.dispatchEvent(clickEvt);
    aLink.remove();
  };

  const exportToCsv = async () => {
    setExportClicked(true);
    if (JSON.stringify(input) !== JSON.stringify(input2)) {
      setInput(input2);
      setLoading(true);
    }
  };

  const HandleCallBack = (
    where: string,
    orderBy: Vw_Report_Sales_By_Product_Order_By | Vw_Report_Sales_By_Product_Order_By[]
  ) => {

    input2 = {
      variables: {
        limit: 999999,
        offset: 0,
        order_by: orderBy,
        where: JSON.parse(where),
      },
    };

  };
  tableData = queried.data?.vw_report_sales_by_product.map((row: Vw_Report_Sales_By_Product) => {
    return [
      `"${row.product_mpn}","${row.product_category}","${row.manufacturer}","${row.order_count}","${row.gross_sale}","${row.gross_adjust}","${row.total_sale}"`,
    ];
  });
  return (
    <>
      <div className="d-flex justify-content-between return-header">
        <div className="d-flex align-items-start">
          <ReturnHeader
            title="Sales by product"
            url={baseRoute}
            description="This report provides a breakdown of sales revenue by individual product (does not include shipping)."
          />
        </div>
        <div className="d-flex align-items-center">
          {loading ? (
            <div data-testid=""
              className="spinner-border spinner-border-sm" role="status">
            </div>
          )
            :
            <Button
              data-testid=""
              className="btn"
              id="export-report"
              onClick={() => {
                exportToCsv();
              }}
            >
              Export
            </Button>
          }
        </div>
      </div>
      <SalesByProductData handleCallBack={HandleCallBack} />
    </>
  );
};
