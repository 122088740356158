import { FC, useEffect, useRef, useState } from "react";
import {
  Vw_Report_Active_Seller_Order_By,
  useReportActiveSellerQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Table } from "../../common/components/Table/index";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { dateRangeToStr } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";

interface IProp {
  handleCallBack: (
    searchText: string,
    where: string,
    orderBy: Vw_Report_Active_Seller_Order_By | Vw_Report_Active_Seller_Order_By[]
  ) => void;
};

export const ActiveSellerData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;

  let tableData: { id: string }[] | undefined = [];
  const operatorId = context.operatorInfo.id;
  const formatDate = useDateFormat();
  let currentDate = new Date();

  let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);

  const [dateRange, setdateRange] = useState<IDateRange | undefined>({
    startDate: last14Days,
    endDay: currentDate,
    column_name: "last_login_date_tz",
  });
  const [searchText, setSearchText] = useState<string>(dateRangeToStr(last14Days, currentDate));
  const [offset, setOffset] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Seller",
    column_name: "company_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Seller: "company_name",
    Email: "email",
    Full_Name: "full_name",
    Last_Login: "last_login_date",
    Pending_Invite: "pending_invite_count"
  };
  const tableColumnsToSearch: string[] = ["company_name", "email", "full_name"];

  const tableColumns = [
    "Seller",
    "Email",
    "Full Name",
    "Last Login",
    "Pending Invite"
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.Date]: "desc" };

  const customRule = `,"operator_id": {"_eq":"${operatorId}"}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
    dateRange,
    customRule
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useReportActiveSellerQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  useEffect(() => {
    if (dateRange) {
      let startDate = dateRange.startDate ?? new Date(1980, 1, 1);
      let endDate = dateRange.endDay ?? new Date(9999, 1, 1)
      setSearchText(dateRangeToStr(startDate, endDate));
    }
  }, [dateRange]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ActiveSellerData", errMsg: error.message }} />;
  }

  const rowsItem = queried.data?.vw_report_active_seller;
  const totalRows = queried.data?.items.aggregate?.count ?? 0;
  handleCallBack(searchText, handleWhereStr, orderByString);

  if (rowsItem) {
    tableData = rowsItem.map((row) => {
      return {
        id: row.company_name ?? "",
        Seller: row.company_name,
        Email: row.email,
        Full_Name: row.full_name,
        Last_Login:
          <div className="d-flex justify-content-start">
            <p className="mb-0">{formatDate(row.last_login_date, "datetime")}</p>
          </div>,
        Pending_Invite:
          <div className="d-flex justify-content-center">
            {row.pending_invite_count}
          </div>
      };
    });
  }

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Seller", value: "company_name_lower" },
    { label: "Email", value: "email_lower" },
    { label: "Full Name", value: "full_name_lower" },
    { label: "Pending Invite", value: "pending_invite_count", type: "numeric" },
  ];

  return (
    <Card>
      <Table
        setSearchValue={setSearchValue}
        columnNames={tableColumns}
        data={tableData}
        applyChanges={applyChanges}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalRows}
        ref={multiSelectRef}
        multiSelectTable={false}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        predefinedDateRange={true}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Seller..."
        searchHint="Search by Seller, Email, Full Name"
      />
    </Card>
  );
}