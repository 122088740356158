import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { CategoryAttributePartFragment, useUpdateCtgAttributeMutation } from "../../../generated/urql-graphql";
import { appRef } from "../../common/components/appStatus";
import {
  boolUiComponents,
  dateInputTypes,
  getAttributeObj,
  mutationInfo
} from "../../common/miscellaneous/utility";
import { MutationAction, SubAttributeProps } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";

interface Props {
  categoryAttribute: CategoryAttributePartFragment;
  resetAttribute: () => void;
  setFormChanged: (changed: boolean) => void;
}
export const EditAttribute: FC<Props> = ({ categoryAttribute, resetAttribute, setFormChanged }) => {
  const { type, options, global } = categoryAttribute.cp_properties
  const [optionLength, setOptionLength] = useState<number>(1);
  const [, updateMutation] = useUpdateCtgAttributeMutation();
  const methods = useForm<SubAttributeProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    appRef.current?.updateStatus(changed);
    setFormChanged(changed);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (categoryAttribute.cp_properties) {
      reset(categoryAttribute.cp_properties);
      setOptionLength(options?.length ?? 1);
    }
  }, [categoryAttribute]);

  const onSubmit = async (formData: FieldValues) => {
    const commKeys: string[] = ["display", "required", "tip"];
    const properties = getAttributeObj(commKeys, type, formData);
    const res = await updateMutation({ ctgAttrId: categoryAttribute.id, properties });
    if (!res.error) {
      resetAttribute();
      appRef.current?.updateStatus(false);
    }
    alertsRef.current?.generate(mutationInfo("attribute", MutationAction.Update, res));
  };

  const removeOption = (index: number) => {
    const temp = getValues("options");
    temp?.splice(index, 1);
    setValue("options", temp);
    setOptionLength(temp?.length ?? 1);
  };

  const optionsRequirement = () => {
    return (
      <div>
        <p className="small text-muted mb-3">
          For a set of options where only one option can apply at a time, i.e a color or size.
        </p>
        {Array.from({ length: optionLength }, (elm, index) => {
          return (
            <div className="mb-3" key={`options_${index}`}>
              <div
                className={index > 0 ? "input-group justify-content-between align-items-end" : ""}
              >
                <FormInput
                  name={`options.${index}`}
                  label={`Option ${index + 1}`}
                  reg_options={{ required: true }}
                  css="form-input"
                />
                {index > 0 && (
                  <Button
                    data-testid=""
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeOption(index)}
                  >
                    <i className="bi bi-trash" />
                  </Button>
                )}
              </div>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-between">
          <Button
            data-testid=""
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => setOptionLength(optionLength + 1)}
          >
            Add option
          </Button>
        </div>
      </div>
    );
  };

  const textRequirement = () => {
    return (
      <>
        <FormInput
          name="max_length"
          label="Maximum number of characters allowed"
          reg_options={{ required: true, maxLength: 50 }}
        />
        <FormInput
          name="ui_component"
          label="Format"
          label_extra={
            <p className="small text-muted font-weight-normal">
              Values can contain letters, numbers, and special characters
            </p>
          }
          type="radio"
          radio_options={[
            {
              value: "single-line text",
              label: "single-line text",
            },
            { value: "multi-line text", label: "multi-line text" },
          ]}
        />
      </>
    );
  };

  const booleanRequirement = () => {
    return (
      <>
        <FormSelect
          name="ui_component"
          label="Choice Type"
          options={boolUiComponents}
          reg_options={{ required: true }}
        />
        <div className="row d-flex">
          <div className="col-xs-12">
            <FormInput
              name="on_text"
              reg_options={{ required: true }}
              label="On Text"
              tool_tip="Value shown when enabled"
            />
          </div>
          <div className="col-xs-12">
            <FormInput
              name="off_text"
              label="Off Text"
              reg_options={{ required: true }}
              tool_tip="Value shown when disabled"
            />
          </div>
        </div>
      </>
    );
  };
  const datetimeRequirement = () => {
    return (
      <>
        <Label className="form-label font-weight-medium" data-testid="">
          Format
        </Label>
        <FormInput
          name="input_type"
          label=""
          type="radio"
          radio_options={dateInputTypes}
          css="d-flex justify-content-between align-items-center"
        />
        <FormInput
          name="show_date_range"
          label="Show as date range?"
          type="checkbox"
          css="d-flex align-items-center"
        />
      </>
    );
  };
  const numberRequirement = () => {
    return (
      <>
        <Label className="font-weight-medium" data-testid="">
          Validation
        </Label>
        <div className="row d-flex">
          <div className="col-xs-12 col-sm-6">
            <FormInput name="min_value" type="number" label="Minimum Value" css="mb-3 me-3" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <FormInput name="max_value" label="Maximum Value" type="number" css="mb-3" />
          </div>
        </div>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="edit-atrribute-form"
        id="edit-atrribute-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <div className="">
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-6">
              <FormInput
                name="display"
                label="Display"
                reg_options={{ required: true, maxLength: 50 }}
              />
              <FormInput name="name" label="Name" disabled={true} inputgrouplabel="attribute." />
              <FormTextarea
                name="tip"
                label="Description"
                reg_options={{ maxLength: 300 }}
                input_extra={
                  <small className="text-muted">Your description will appear as a tooptip</small>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              {type === "text" && textRequirement()}
              {type === "options" && optionsRequirement()}
              {type === "boolean" && booleanRequirement()}
              {type === "datetime" && datetimeRequirement()}
              {type === "number" && numberRequirement()}
            </div>
          </div>
        </div>   
      </Form>
    </FormProvider>
  );
};
