/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import { CustomerPartFragment, OrderInfoPartFragment, OrderItemPartFragment } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { separateWithSpace } from "../../common/miscellaneous/utility";
import { A } from "../../forms/components/A";
import { addCommaFunc } from "../handler/handler";

interface Customer {
  id: any;
  firstname?: string;
  lastname?: string;
  phone?: string | null;
  email?: string;
  taxid?: string;
  shippingAddress?: AddressType;
  billingAddress?: AddressType;
}
interface AddressType {
  company: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
export interface Shipping {
  method?: string;
  customer: {
    firstname?: string;
    lastname?: string;
    address: AddressType;
  };
}

export interface IPreferredCarrier {
  name: string;
  number: number;
}
interface OrderInfoType {
  id: string;
  order_date: any;
  order_number: any;
  po_number: any;
  order_status: string;
  shipping_deadline: any;
  shipped_date: any;
  created_date: any;
  payment_method: any;
  shipping_carrier: any;
  transaction_number: any;
  account_number: any;
  shipping_method_code: any;
}
export interface OrderDetailsType {
  customer: Customer;
  order_detail: OrderInfoType;
}

export const BillingInfo = ({
  firstName = "",
  lastName = "",
  billingAddress,
  paymentMethod,
  debitDate
}: {
  firstName?: string;
  lastName?: string;
  billingAddress?: AddressType;
  paymentMethod?: string;
  debitDate?: any;
}) => {
  const formatDate = useDateFormat();
  return (
    <div className="col-lg-12 col-xl-3">
      <Card className="no-default-css">
        <div className="border-bottom card-header">
          <h5 className="d-flex card-title mb-0 align-items-center">
            <i className="bi bi-credit-card me-2" />
            <span>Billing Information</span>
          </h5>
        </div>
        <div className="card-body">
          <small className="text-muted me-2">Payment Method</small>
          <h6 className="order-date mb-4">{separateWithSpace(paymentMethod)}</h6>

          <p className="font-weight-semi-bold mb-1">
            {firstName && lastName ? firstName + " " + lastName : "Unknown"}
          </p>
          <p className="mb-0">{billingAddress?.company}</p>
          <p className="mb-0">{billingAddress?.line1}</p>
          <p className="mb-0">{billingAddress?.line2}</p>
          <p className="mb-0">
            {billingAddress?.city} {billingAddress?.state}, {billingAddress?.zipcode}
          </p>
          <p className="mb-0">
            <small className="text-muted text-uppercase">
              {billingAddress?.country}
            </small>
          </p>

          {debitDate ?
            (
              <p className="mb-0">
                Debit Date: {formatDate(debitDate)}
                <span className="small text-muted">
                  {` ${formatDate(debitDate, "time")}`}
                </span>
              </p>)
            :
            <></>
          }
        </div>
      </Card>
    </div>
  );
};

const compareDates = (shippingDeadline: string | number, date: string | number) =>
  dayjs(shippingDeadline).valueOf() - dayjs(date).valueOf() <= 0;

const checkShippedDate = (
  status: string,
  shippingDeadline: string | null,
  date: string | null
) => {
  switch (status) {
    case "Pending":
    case "AwaitingFulfillment":
      return shippingDeadline ? compareDates(shippingDeadline, new Date().toJSON()) : false;
    default:
      return shippingDeadline && date ? compareDates(shippingDeadline, date) : false;
  }
};

export const OrderInfo = (props: { orderInfo: OrderInfoPartFragment, formatDate: any }) => {
  const { orderInfo, formatDate } = props;
  const comparedDate: string | null =
    (orderInfo.trg_status === "Cancelled" || orderInfo.trg_status === "Rejected")
      ? orderInfo.created_date
      : orderInfo.shipped_date;

  return (
    <div className="d-flex mb-2">
      <div className="me-3 align-self-top">
        <i className="bi bi-calendar text-muted text-large"></i>
      </div>
      <div className="flex-1">
        <small className="text-muted me-2">Date:</small>
        <h6 className="order-date mb-2">{formatDate(orderInfo.created_date)}</h6>
        <small className="text-muted me-2">Purchase Order:</small>
        <h6 className="po-number mb-1">{orderInfo.po_number}</h6>
        <small className="text-muted me-2">Shipping Deadline:</small>
        <div className={`shipping-due-date mb-1`}>
          {formatDate(orderInfo.trg_shipping_deadline)}
          <span className="small text-muted ms-1">
            {formatDate(orderInfo.trg_shipping_deadline, "time")}
          </span>{" "}
          {orderInfo.trg_status &&
            checkShippedDate(orderInfo.trg_status, orderInfo.trg_shipping_deadline ?? null, comparedDate) ? (
            <span className="ms-2 tooltip-custom right">
              <span className="tooltip-text">Behind Schedule
              </span>
              <i className="bi bi-exclamation-circle text-danger"></i>
            </span>
          ) : null}
        </div>
        <small className="text-muted me-2">Payment Method:</small>
        <h6 className="trans-number mb-1">{orderInfo.payment_method}</h6>
        {orderInfo.payment_method === "CreditCard" &&
          <>
            <small className="text-muted me-2">Transaction Number:</small>
            <h6 className="trans-number mb-1">
              {(orderInfo.transaction_number && orderInfo.transaction_number !== "")
                ? orderInfo.transaction_number
                : <><span className="me-1">transaction failed</span> <i className="bi bi-exclamation-circle text-danger"></i></>}
            </h6>
          </>
        }
        {orderInfo.payment_method === "OnAccount" &&
          <>
            <small className="text-muted me-2">Account Number:</small>
            <h6 className="trans-number mb-1">{orderInfo.account_number}</h6>
          </>
        }
        {orderInfo.web_order_id &&
          <>
            <small className="text-muted me-2">Web Order Id:</small>
            <h6 className="trans-number mb-1">{orderInfo.web_order_id}</h6>
          </>
        }

      </div>
    </div>
  );
};

export const SellerInfo = ({
  companyName,
  sellerId,
  tenantUrlTag
}: {
  companyName?: string;
  sellerId?: string;
  tenantUrlTag: string;
}) => {
  return (
    <div className="d-flex mb-2">
      <div className="me-3 align-self-top">
        <i className="bi bi-shop text-muted text-large"></i>
      </div>
      <div className="flex-1">
        <small className="text-muted me-2">Seller:</small>
        {companyName && sellerId ? (
          <h6 className="seller mb-2">
            <Link to={`/${tenantUrlTag}/sellers/${sellerId}`}>{companyName}</Link>
          </h6>
        ) : null}
        <small className="text-muted me-2">Payment Terms:</small>
        <h6 className="payment-terms mb-1">{"Payment Terms"}</h6>
      </div>
    </div>
  );
};

export const CustomerInfo = (props: { customer: CustomerPartFragment; directShipping: boolean }) => {
  const { customer, directShipping } = props;
  return (
    <div className="d-flex mb-2">
      <div className="me-3 align-self-top">
        <i className="bi bi-person text-muted text-large"></i>
      </div>
      <div className="flex-1">
        <small className="text-muted me-2">Customer:</small>
        <h6 className="customer-name mb-0">
          {customer?.first_name} {customer?.last_name}
          <small className="text-muted ms-2">({customer?.customer_id})</small>
        </h6>
        <p className="customer-email small mb-0">
          <A data-testid="" href={`mailto:${customer?.email}`}>
            {customer?.email}
          </A>
        </p>
        <p className="customer-phone small  mb-0">{customer?.phone}</p>
        <p className="text-muted small text-uppercase">
          {customer?.shipping_address.country}
        </p>
        {customer?.tax_id && (<p className="small mb-0">Tax Id: {customer?.tax_id} </p>)}
        {directShipping && (
          <p className="font-weight-semi-bold">
            <i className="bi bi-exclamation-circle-fill me-1 text-warning"></i>
            Dropshipping to external customer. Do not include an invoice in the shipment.
          </p>)}
      </div>
    </div>
  );
};

export const OrderTotal = (props:
  {
    currencySubTotal: number,
    orderInfo: OrderInfoPartFragment,
    profitModel: string,
    maxDecimalsInUnitPrice: number
  }) => {
  const { currencySubTotal, orderInfo, profitModel, maxDecimalsInUnitPrice } = props;
  const {
    cp_sub_total_price: subtotal,
    cp_base_sub_total_price: baseSubTotal,
    shipping_price: shipping,
    cp_items_refund: subtotalRefund,
    cp_shipping_refund: shippingRefund,
    tax_info,
    cp_total_price: total,
    cp_base_total_price: baseTotal,
    trg_commission,
    cp_commission,
    currency_info
  } = orderInfo;
  const commission = profitModel === "Markup" ? trg_commission : cp_commission;
  const getCurrency = (name: string) => {
    if (!currency_info) return "";
    return `(${addCommaFunc(currency_info[name])} ${currency_info.origin_currency_code})`;
  }
  //const tax = subtotal ? tax_info?.tax_amount : 0;
  const tax = tax_info?.tax_amount;

  const getSubTotal = () => {
    if (currency_info) {
      return `${addCommaFunc(subtotal)} (${addCommaFunc(currencySubTotal)} ${currency_info.origin_currency_code})`;
    } else {
      return `${addCommaFunc(subtotal)}`;
    }
  };
  const getBaseSubTotal = () => {
    if (currency_info) {
      return `${addCommaFunc(baseSubTotal)} (${addCommaFunc(currencySubTotal)} ${currency_info.origin_currency_code})`;
    } else {
      return `${addCommaFunc(baseSubTotal)}`;
    }
  };
  return (
    <div className="d-flex mb-2">
      <div className="me-3 align-self-top">
        <i className="bi bi-cart-check text-muted text-large"></i>
      </div>
      <div className="flex-1 w-100 me-5">
        <small className="text-muted me-3">Order Total {currency_info &&
          `(Currency: ${currency_info.origin_currency_code}, Rate: ${currency_info.currency_conversion_rate})`}
        </small>
        <ul className="no-list">
          <li className="order-item d-flex justify-content-between">
            <span className="font-weight-semi-bold">Subtotal:&nbsp;</span>
            <span className={subtotal ? '' : 'text-muted line-through'}>${subtotal ? `${getSubTotal()}` : getBaseSubTotal()}</span>
          </li>
          {subtotalRefund !== 0 && (
            <li className="order-tax d-flex justify-content-between">
              <span className="text-muted">Adjustment:&nbsp;</span>
              <span>$ -{addCommaFunc(subtotalRefund)}</span>
            </li>
          )}
          <li className="order-shipping d-flex justify-content-between">
            <span className="font-weight-semi-bold">Shipping:&nbsp;</span>
            <span className={subtotal ? '' : 'text-muted line-through'}> ${addCommaFunc(shipping)} {getCurrency("origin_shipping_price")}</span>
          </li>
          {shippingRefund !== 0 && (
            <li className="order-tax d-flex justify-content-between">
              <span className="text-muted">Adjustment:&nbsp;</span>
              <span>$ -{addCommaFunc(shippingRefund)}</span>
            </li>
          )}
          <li className="order-tax d-flex justify-content-between">
            <span className="font-weight-semi-bold">Tax:&nbsp;</span>
            <span className={subtotal ? '' : 'text-muted line-through'}> ${addCommaFunc(tax)} {getCurrency("origin_tax_amount")}</span>
          </li>
          <li className="dropdown-divider"></li>
          <li className="order-total d-flex justify-content-between">
            <span className="font-weight-semi-bold">Total:&nbsp;</span>
            <span className={subtotal ? '' : 'text-muted line-through'}> ${subtotal ? addCommaFunc(total) : addCommaFunc(baseTotal)} {getCurrency("origin_total_price")}</span>
          </li>
          <li className="order-commission d-flex justify-content-between">
            <span className="font-weight-book text-muted">Commission:&nbsp;</span>
            <span>$ {addCommaFunc(commission)}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const ShippingInfo = (props: { customer: CustomerPartFragment, preferredCarrier: IPreferredCarrier, shippingMethod: string }) => {
  const { customer, preferredCarrier, shippingMethod } = props;
  return (
    <div className="col-lg-12 col-xl-3">
      <small className="text-uppercase font-weight-semi-bold">Shipping To:</small>
      <p className="mb-0 mt-2">
        {customer.first_name} {customer.last_name}
      </p>
      <small className="mb-0 font-weight-semi-bold">{customer.shipping_address.company}</small>
      <p className="mb-0">{customer.shipping_address.line1}</p>
      <p className="mb-0">{customer.shipping_address.line2}</p>
      <p className="mb-0">
        <span className="me-1">{customer.shipping_address.city},</span>
        <span className="me-1">{customer.shipping_address.state}</span>
        <span className="ship-zipcode">{customer.shipping_address.zipcode}</span>
      </p>
      <p className="mb-3">
        <small className="text-muted text-uppercase">
          {customer.shipping_address.country}
        </small>
      </p>
      <p className="small mb-0 font-weight-semi-bold">Shipping Method</p>
      <p className="mb-2 text-muted text-uppercase">{shippingMethod}</p>

      <p className="small mb-0 font-weight-semi-bold">Preferred Shipping Method</p>
      <p className="mb-0 text-muted">{preferredCarrier?.name || "N/A"}</p>
      {preferredCarrier?.number && (
        <p className="mb-2 text-muted">Account No. {preferredCarrier?.number}</p>
      )}
      {customer.shipping_address?.recipient_name ? <><p className="small mb-0 font-weight-semi-bold">Recipient Name</p>
        <p className="mb-0 text-muted">{customer.shipping_address?.recipient_name}</p></> : ""}
    </div>
  );
};

export const OrderItemBriefDescription: FC<{ orderItem: OrderItemPartFragment }> = ({ orderItem }) => {
  const { title, condition, offer_sku, mpn, mfr } = orderItem?.product_info;
  const images = orderItem?.cp_product_image?.split("||") || [];
  const image = images.find((img: string) => img)?.replace(/\"/g, "");
  return (
    <div className="d-flex align-items-center">
      {image && (
        <div className="me-3">
          <img
            src={image}
            alt="no_image"
            height="60"
            width="60"
            className="img-responsive"
          />
        </div>
      )}
      <div className="flex-1">
        <p className="font-weight-semi-bold mb-0">{title}</p>
        <p className="mb-0 line-height-sm">
          <small className="text-muted me-2">Mfr Part#:</small>
          <small>{mpn}</small>
        </p>
        <p className="mb-0 line-height-sm">
          <small className="text-muted me-2">Manufacturer:</small>
          <small>{mfr}</small>
        </p>
        <p className="mb-0 line-height-sm">
          <small className="text-muted me-2">Seller Item Sku:</small>
          <small>{offer_sku}</small>
        </p>
        {condition && (
          <p className="mb-0 line-height-sm">
            <small className="text-muted me-2">Condition:</small>
            <small className="text-uppercase">{condition}</small>
          </p>
        )}
      </div>
    </div>
  );
};
export const footerText = (
  <p>
    Learn more about <a href="https://marketpush.freshdesk.com/support/solutions/folders/150000539487" target="_blank">managing orders</a>.
  </p>
);
