import { FC } from "react";
import {
  useSellerUsersIntQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { UserActivityData } from "../../users-route/components/UserActivityData";
interface IProp {
  sellerInfo: {
    sellerId: string;
    addressId: string;
    contactInfoId: string;
    billingPeriodId: string;
  }
};

export const SellerActivity: FC<IProp> = ({ sellerInfo }) => {
  const userInputs = { variables: { seller_id: sellerInfo.sellerId } };
  const userInfo = useUserInfo();
  let operatorId = userInfo.operator_id!;
  const [usrsQueried] = useSellerUsersIntQuery(userInputs);

  const error = usrsQueried.error;
  if (error) {
    return <PageError error={{ source: "SellerActivity", errMsg: error.message }} />;
  }

  if (!usrsQueried.data) {
    return <Loader />;
  }

  //console.log(`sellerInfo: ${JSON.stringify(sellerInfo)}`)
  //const staticFilterString = `,"user": { "_in": ${filter}},"operator_id":{"_eq": "${operatorId}"}`;
  const staticFilterString = `,
        "table": { "_in": ["seller","contact_info","address","usership","seller_member_blk"]},
        "_or": [{
                "table_id":  
                    { "_in": 
                        ["${sellerInfo.sellerId}","${sellerInfo.addressId}","${sellerInfo.contactInfoId}","${sellerInfo.billingPeriodId}"]
                    }
                },
                {"seller_id": {"_eq": "${sellerInfo.sellerId}"}}
               ],
        "operator_id":{"_eq": "${operatorId}"}
        `;
  return (
    <Card className="p-4">
      <UserActivityData staticFilter={staticFilterString} />
    </Card>
  );
};
