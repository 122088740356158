import { FC, useEffect, useState } from "react";

import {
  useSalesByOrderQuery,
  Vw_Sales_By_Order,
  Vw_Sales_By_Order_Order_By
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { SalesByStoreDateTable } from "./SalesByStoreDateTable";
export const SalesOverTime: FC = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/reports`;
  const [loading, setLoading] = useState<boolean>(false);
  let whereStr: string = `{"created_date": { "_lt": "1900-01-01" }}`;
  let orderByStr: Vw_Sales_By_Order_Order_By | Vw_Sales_By_Order_Order_By[] = [];
  let tableData: {}[][] | undefined = [];
  const [exportClicked, setExportClicked] = useState<boolean>(false);
  const [input, setInput] = useState<{}>({
    variables: {
      limit: 0,
      offset: 0,
      order_by: orderByStr,
      where: JSON.parse(whereStr),
    },
  });
  let input2: {} = {};

  const downloadFile = (value: { [key: string]: string }) => {
    const blob = new Blob([value.data], { type: value.fileType });

    const aLink = document.createElement("a");
    aLink.download = value.fileName;
    aLink.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    aLink.dispatchEvent(clickEvt);
    aLink.remove();
  };

  const [queried] = useSalesByOrderQuery(input);

  //console.log(`input: ${JSON.stringify(input)}`);

  useEffect(() => {
    let headers = ["Date,Order,Status,Status History Date,Product Sale Amount,Commission,Taxes,Shipping,Gross Sales,Net Sales"];

    if (queried.data && exportClicked) {
      downloadFile({
        data: [...headers, tableData?.join("\n")].join("\n"),
        fileName: "ComprehensiveSales.csv",
        fileType: "text/csv",
      });
      setExportClicked(false);
      setLoading(false);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SalesOverTime", errMsg: error.message }} />;
  }

  const exportToCsv = async () => {
    setExportClicked(true);
    if (JSON.stringify(input) !== JSON.stringify(input2)) {
      setInput(input2);
      setLoading(true);
    }
  };

  const HandleCallBack = (
    where: string,
    orderBy: Vw_Sales_By_Order_Order_By | Vw_Sales_By_Order_Order_By[]
  ) => {

    input2 = {
      variables: {
        limit: 999999,
        offset: 0,
        order_by: orderBy,
        where: JSON.parse(where),
      }
    };
    //console.log(`input2: ${JSON.stringify(input2)}`);
  };

  tableData = queried.data?.vw_sales_by_order.map((row: Vw_Sales_By_Order) => {
    return [
      `"${row.created_date}","${row.order_number}","${row.trg_status}","${row.status_history_date}","${row.trg_sub_total}","${row.trg_commission}","${row.tax_amount}","${row.shipping_price}","${row.gross_sale}","${row.net_sale}"`,
    ];
  });
  return (
    <>
      <div className="d-flex justify-content-between return-header">
        <div className="d-flex align-items-start">
          <ReturnHeader
            title="Comprehensive Sales"
            url={baseRoute}
            description={`This report illustrates the trend of sales revenue over a specific period of time.  This report omits orders that have been rejected, refunded, and canceled.`}
          />
        </div>
        <div className="d-flex align-items-center">
          {loading ? (
            <div data-testid=""
              className="spinner-border spinner-border-sm" role="status">
            </div>
          )
            :
            <Button
              data-testid=""
              className="btn"
              id="exportButton"
              onClick={() => {
                exportToCsv();
              }}
            >
              Export
            </Button>
          }
        </div>
      </div>
      <SalesByStoreDateTable handleCallBack={HandleCallBack} />
    </>
  );
};