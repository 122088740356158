import { FC } from "react";
import {
  useFetchValuesForProductMetricsQuery,
  useGetValuesForProductMetricsSubscription,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { PlaceholderSingleItem } from "../../common/components/PlaceholderLoaders";
import { Label } from "../../forms/components/Label";
import { addCommaFunc } from "../../orders-route/handler/handler";
type Props = {
  metricData: { icon: string; label: string; name: "order_count" | "total_sale" | "offer" }[];
  mpin: string;
  offer: number;
};

export const ProductMetric: FC<Props> = (props): ReturnType<FC> => {
  const { metricData, mpin, offer } = props;
  const [queried] = useFetchValuesForProductMetricsQuery({ variables: { mpin } });
  const [subscribed] = useGetValuesForProductMetricsSubscription({ variables: { mpin } });

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "ProductMetrics", errMsg: error.message }} />;
  }

  if (!subscribed.data || !queried.data) {
    return (
      <div className="d-flex flex-row gap-3">
        {metricData.map((item, index) => {
          return (
            <div key={index} className="border col p-3 d-flex">
              <PlaceholderSingleItem />
            </div>
          );
        })}
      </div>
    );
  }
  const data = subscribed.data.vw_sales_by_product ?? queried.data.vw_sales_by_product;

  return (
    <div className="d-flex flex-column flex-lg-row  gap-3">
      {metricData.map((item, index) => {
        let value = item.name === "offer" ? offer : 0;
        if (data.length) {
          value = item.name === "offer" ? value : data[0][item.name];
        }
        return (
          <div key={index} className="border col p-3 d-flex">
            <h1 className="text-muted col-2 mb-0 d-flex justify-content-center align-items-center">
              <i className={`bi ${item.icon}`}></i>
            </h1>
            <div className="d-flex flex-column ms-3">
              <Label data-testid="">{item.label}</Label>
              <Label data-testid="" className="font-weight-semi-bold">
                {item.name === "total_sale" ? `$ ${addCommaFunc(value)}` : value}
              </Label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
