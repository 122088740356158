import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import {
  useAddToBlklistMutation,
  useMemberCompaniesQuery,
  useRemoveFromBlklistMutation,
  useResellerBlacklistInitQuery,
  useResellerBlacklistSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface Props {
  sellerId: string;
}

export const ResellerBlacklist: FC<Props> = ({ sellerId }: Props): ReturnType<FC> => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const manage = !userInfo.seller_id ? true : (
    context.operatorSettings.seller.member_based_marketpush
    && (userInfo.permissions & context.permissions.update_seller)
  );
  const defAction = { ids: null, action: "" };
  const [actionState, setActionState] = useState<IActionState>(defAction);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [memberComps, setMemberComps] = useState<SelectOption[]>([]);
  const selectRef = useRef<SelectRef | null>(null);

  const [{ fetching: adding }, addMutation] = useAddToBlklistMutation();
  const [{ fetching: removing }, removeMutation] = useRemoveFromBlklistMutation();
  const [compQueried] = useMemberCompaniesQuery({
    variables: _.omit(searchParam, "total"),
  });
  const input = {
    variables: {
      sellerId,
    },
  };

  const [blkQueried] = useResellerBlacklistInitQuery(input);
  const [blkSubscribed] = useResellerBlacklistSubscription(input);

  useEffect(() => {
    if (compQueried.data) {
      const comps = compQueried.data.members_member_company.map((comp) => ({
        label: `${comp.name} (${comp.company_id})`,
        value: comp.id,
      }));
      setMemberComps(comps);
      setSearchParam({ ...searchParam, total: compQueried.data.members.aggregate?.count || 0 });
    }
  }, [compQueried]);

  const error = blkQueried.error || blkSubscribed.error || compQueried.error;
  if (error) {
    return <PageError error={{ source: "ResellerBlackList", errMsg: error.message }} />;
  }

  if (!blkQueried.data || !blkSubscribed.data || !compQueried.data) {
    return (
      <>
        <Card className="p-4">
          <PlaceholderLists />
        </Card>
      </>
    );
  }

  const blkList =
    blkSubscribed.data?.members_member_company || blkQueried.data?.members_member_company;
  const columnNames = ["Company Id", "Restricted Resellers", "HQ Location"];
  if (manage) columnNames.push("Actions");
  const tableData = blkList?.map((blk) => {
    return {
      id: blk.id,
      Company_Id: blk.company_id,
      Restricted_Resellers: blk.name,
      HQ_Location: `${blk.line1}, ${blk.city}, ${blk.state}, ${blk.zipcode}`,
      Actions: (
        <div className="d-flex justify-content-center">
          <Button
            data-testid=""
            onClick={() => {
              setActionState({ ids: [blk.id], action: "delete" });
            }}
            className="btn btn-danger btn-sm text-white"
          >
            <i className="bi bi-trash"></i>
          </Button>
        </div>
      ),
    };
  });

  const continueRemove = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await removeMutation(
        { compIds: actionState.ids, sellerId },
        { additionalTypenames: ["members_seller_member_blk", "members_member_company"] }
      );
      if (!res.error) {
        setActionState(defAction);
      }
      alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, res));
    } else {
      setActionState(defAction);
    }
  };

  const addToBlacklist = async () => {
    const selected = selectRef.current?.value;
    if (selected && selected.length > 0) {
      const resellers = selected.map((comp: string) => ({
        seller_id: sellerId,
        member_company_id: comp,
      }));
      const res = await addMutation(
        { resellers },
        { additionalTypenames: ["members_seller_member_blk", "members_member_company"] }
      );
      if (!res.error) {
        selectRef.current?.clear();
      }
      alertsRef.current?.generate(mutationInfo("the seller", MutationAction.Update, res));
    }
  };

  return (
    <>
      <Card className="p-4">
        {manage && (
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <CustomSelect
                ref={selectRef}
                options={memberComps}
                placeholder="Please select a reseller..."
                search={{ searchParam, setSearchParam }}
                multiple={true}
              />
            </div>
            <div className="ms-2">
              <Button
                className="btn btn-primary"
                data-testid="Add"
                onClick={() => addToBlacklist()}
              >
                Add
              </Button>
            </div>
          </div>
        )}
        <Table columnNames={columnNames} data={tableData} css="bg-white mt-3 rounded" />
      </Card>
      <Dialog
        show={actionState.action === "delete"}
        title="Remove Company?"
        continueText="Remove from Blacklist"
        continue={continueRemove}
        contineBtnCss={"btn-danger"}
      >
        <p className="mb-0">Are you sure you want to remove this company from the blacklist?</p>
      </Dialog>
    </>
  );
};
