import { FC } from "react";
import {
  useUpdateSellerCurrentMutation,
  useUserStoresSubscription
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Spinner } from "../../common/components/Spinner";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "./MainProvider";

interface Props {
  showAllInPage?: boolean;
}

export const SellerStores: FC<Props> = ({ showAllInPage }) => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = "/" + tenantUrlTag;
  const [subscribed] = useUserStoresSubscription({
    variables: { user_id: userInfo.user_id! },
  });
  const [, updateMutation] = useUpdateSellerCurrentMutation();

  const error = subscribed.error;
  if (error) {
    return <PageError error={{ source: "SellerStores", errMsg: error.message }} />;
  }
  if (!subscribed.data) {
    return <Spinner size={20} />;
  }

  const loginToStore = (seller_id: string, currentLogged: boolean) => {
    if (currentLogged) return;
    updateMutation(
      { user_id: userInfo.user_id, seller_id: seller_id, operator_id: userInfo.operator_id },
      { additionalTypenames: ["usership"] }
    )
      .then((res) => {
        if (res.data && !res.error) {
          window.location.href = baseRoute;
        }
        alertsRef.current?.generate(mutationInfo("seller store", MutationAction.Update, res));
      })
      .catch((error) => alertsRef.current?.generate(mutationInfo("seller store", MutationAction.Update, error)));
  };

  const stores = subscribed?.data?.usership;

  const handleStoreName = (storeName: string = "") => {
    return storeName.length > 30 ? storeName.slice(0, 30) + " ..." : storeName;
  };

  const storeList = stores.map((store, index) => {
    return (
      <div key={`store_${index}_${store}`}>
        {!showAllInPage && index !== 0 && (
          <li>
            <hr className="dropdown-divider" />
          </li>
        )}
        <li className="list-group-item">
          <div
            className={`d-flex ${showAllInPage ? "list-group-item list-group-item-action" : "dropdown-item"
              } ${store.current && "bg-light-gray"}`}
            onClick={() => loginToStore(store.seller_id, store.current)}
            role="button"
          >
            <div className="me-2">
              <i className="bi bi-shop"></i>
            </div>
            <div title={store.seller?.company_name}>
              {handleStoreName(store.seller?.company_name)}{" "}
              <span className="small ms-1">({store.seller?.shop_id})</span>
            </div>
          </div>
        </li>
      </div>
    );
  });

  return stores.length > (showAllInPage ? 0 : 1) ? (
    <>
      {showAllInPage ? (
        <div className="text-start">
          <Label data-testid="label_choose_seller" className="font-weight-semi-bold mb-2">
            Please select the seller you wish to log into.
          </Label>
          <ul className="list-group">{storeList}</ul>
        </div>
      ) : (
        <div className="btn-group me-2 store-selector">
          <div
            className="dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title={stores.find((store) => store.current)?.seller?.company_name}
          >
            {handleStoreName(stores.find((store) => store.current)?.seller?.company_name)}
            <span className="small ms-1 me-2">
              ({handleStoreName(stores.find((store) => store.current)?.seller?.shop_id || "")})
            </span>
          </div>
          <ul
            className="dropdown-menu dropdown-menu-lg-end top-100 shadow"
            aria-labelledby="dropdownMenuLink"
          >
            {storeList}
          </ul>
        </div>
      )}
    </>
  ) : null;
};
