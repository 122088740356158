/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

type Props = {
  columnNames: string[];
  data: unknown[];
  css?: string;
};

export const Table: FC<Props> = (props): ReturnType<FC> => {
  //data property name should match name in columnNames except space replaced by underscore
  const { columnNames, data } = props;

  /*  const handleColumnName = (columnName: string): string => {
     const newColumnTitle =
      columnName.charAt(0).toUpperCase() +
      columnName.slice(1).replace("_", " "); 
    return columnName;
  };*/

  const handleObjectProperty = (columnName: string): string => {
    const newColumnTitle = columnName.replace(/[,.\/():]/g, "").replace(/\s/g, "_").replace(/\s/g, "_");
    return newColumnTitle;
  };
  const handleColumnName = (columnName: string): string => {
    return columnName;
  };

  return (
    <div className={props.css ?? "bg-white p-2 shadow-sm p-3 mb-5 bg-white rounded mt-0"}>
      <table className="table">
        <thead>
          <tr>
            {columnNames.map((column, colIndex) => (
              <th
                className={`text-capitalize ${column.toLowerCase().search(/action/) > -1 ? "text-center" : "text-left"
                  }`}
                key={colIndex}
              >
                {handleColumnName(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row: any, index) => (
            <tr key={index + row.id}>
              {columnNames.map((column, colIndex) => (
                <td key={index + colIndex + row.id}>{row[handleObjectProperty(column)]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="font-weight-semi-bold text-center">No data available</div>
      )}
    </div>
  );
};
