const Separator = (props: { display: boolean; date: string }) => {
    const display = props.display;

    let datetimeDate = new Date(props.date);
    datetimeDate.setHours(datetimeDate.getHours() - 8);
    let displayDate = datetimeDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });

    return (
        <>
            {display && (
                <div className="d-flex justify-content-center">
                    <div className="text-center msg-date-heading my-3 align-items-center">{displayDate}</div>
                </div>
            )}
        </>
    );
};
export default Separator