import React, { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  OpInfoPartFragment,
  useUpdateOperatorMutation
} from "../../../generated/urql-graphql";
import { Address } from "../../common/components/Address";
import { Card } from "../../common/components/Card";
import { Contact } from "../../common/components/Contact";
import { InputCheckColor } from "../../common/components/InputCheckbox";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadAppData } from "../../common/miscellaneous/storage";
import { getFileExt, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import _ from "lodash";

type ColorPickerType = {
  id: number;
  description: string;
  cssVariable: string;
  defaultValue: string;
  value: string;
  isChecked: boolean;
  type: string;
};

interface ISiteImg {
  file: File | null;
  src: string;
}

const Appearance: FC = () => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const { id: operatorId, site_info, custom_style, tenant_url_tag } = context.operatorInfo;
  const baseRoute = `/${tenant_url_tag}/settings`;
  const [iconImg, setIconImg] = useState<ISiteImg>({ file: null, src: site_info.icon });
  const [logoImg, setLogoImg] = useState<ISiteImg>({ file: null, src: site_info.logo });
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [styleData, setStyleData] = useState<ColorPickerType[] | null>(null);
  const [{ fetching }, updateMutation] = useUpdateOperatorMutation();

  const methods = useForm<OpInfoPartFragment>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: context.operatorInfo
  });

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset
  } = methods;

  const senderEmail = context.operatorInfo.contact?.sender_email
  const isSenderEmailVerified = context.operatorInfo.contact?.is_sender_email_verified as boolean
  const handleImportClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let selected = event.target.files[0];
      if (selected.size > 2097152) {
        alertsRef.current?.add("Maximum allowed file size is 2MB.", "error");
        return;
      }
      if (selected.type !== "image/svg+xml" && selected.type !== "image/png") {
        alertsRef.current?.add("Only .svg and .png are currently supported.", "error");
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(selected);
      reader.onload = function () {
        const img = { file: selected, src: reader.result as string };
        event.target.name === "icon-img" ? setIconImg(img) : setLogoImg(img);
      }
      setFormChanged(true)
      appRef.current?.updateStatus(true)
    }
  };

  useEffect(() => {
    let changed = Object.keys(dirtyFields).length !== 0;
    changed = changed || iconImg.src !== site_info.icon || logoImg.src !== site_info.logo;
    appRef.current?.updateStatus(changed);
    setFormChanged(changed);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (custom_style) {
      const tempData = custom_style.map((style: ColorPickerType) => {
        let isChecked = style.value === style.defaultValue;
        return {
          ...style,
          isChecked,
          type: "color",
        };
      });
      setStyleData(tempData);
    }
  }, [custom_style]);

  const getFileUrl = async (file: File, imgType: string) => {
    const uniqueFileName = `${imgType}_${userInfo.operator_id}${getFileExt(file.name)}`;
    return await uploadAppData(file, uniqueFileName);
  }

  async function reloadLogo(url: string) {
    await fetch(url, { cache: 'reload', mode: 'no-cors' })
    document.body.querySelectorAll(`img[src='${url}']`)
      .forEach((img: Element) => {
        (img as HTMLImageElement).src = url;
      })
  }

  function reloadUrl(siteInfo: any) {
    reloadLogo(siteInfo.logo);
    reloadLogo(siteInfo.icon);
  }

  const onSubmit = async (data: FieldValues) => {
    let siteInfo = { ...data.site_info, icon: site_info.icon, logo: site_info.logo };
    if (iconImg.src !== site_info.icon) {
      const src = iconImg.file ? await getFileUrl(iconImg.file, "icon") : iconImg.src;
      siteInfo = { ...siteInfo, icon: src };
    }
    if (logoImg.src !== site_info.logo) {
      const src = logoImg.file ? await getFileUrl(logoImg.file, "logo") : logoImg.src;
      siteInfo = { ...siteInfo, logo: src };
    }
    if (data.contact?.sender_email === senderEmail) {
      data.contact.is_sender_email_verified = isSenderEmailVerified
    } else {
      data.contact.is_sender_email_verified = false
    }
    const tmpData = _.omit(data, ["industry_id","cp_is_pim"]);
    const operatorObj = { ...tmpData, site_info: siteInfo, updated_date: new Date() };
    const res = await updateMutation({ operatorId, operatorObj });
    if (!res.error) {
      appRef.current?.updateStatus(false);
      reset(data);
    }
    reloadUrl(siteInfo);
    alertsRef.current?.generate(mutationInfo("the appearance", MutationAction.Update, res));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader url={baseRoute} title="Appearance" />
        </div>
      </div>

      <div className="row">
        <FormProvider {...methods}>
          <Form data-testid="appearance-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
            <div className="d-flex flex-column flex-lg-row">
              <div className="col-lg-12 col-xl-8 mx-auto">
                <h5 className="subtitle mb-2">Site Identity</h5>
                <p className="mb-3 text-muted">
                  Marketpush, your sellers, and your customers will use this information to recognize you.
                </p>
                <Card className="p-4">
                  <div className="d-flex flex-column">
                    <FormInput
                      name="legal_name"
                      label="Legal Name of Company"
                      reg_options={{ required: true }}
                      placeholder="Please enter the legal name of company"
                    />
                    <FormInput
                      name="site_info.title"
                      label="Site Title"
                      reg_options={{ required: true }}
                      placeholder="Please enter the site title"
                    />
                    <div data-testid="" className="form-check-label">
                      <h4 className="subheading mb-0">Logo</h4>
                      <p className="text-muted mb-0">Upload your sitewide logo.</p>
                    </div>
                    <Input
                      ref={null}
                      onChange={handleImportClick}
                      type="file"
                      data-testid="upload-logo"
                      id="logo-img"
                      name="logo-img"
                      accept=".png, .jpg, .jpeg,.svg"
                      style={{ display: "none" }}
                    />
                    {site_info?.logo ? (
                      <div className="my-3 d-flex flex-column">
                        <div className="site-logo-container p-4 d-flex justify-content-center align-items-center bg-light-gray border mb-3">
                          <img src={logoImg.src} alt="site logo" className="img-fluid" />
                        </div>
                        <div className="site-logo-button-wrapper">
                          <Button
                            data-testid=""
                            className="btn btn-outline-primary me-2"
                            type="button"
                            disabled={site_info.def_logo === logoImg.src}
                            onClick={() => {
                              setLogoImg({ file: null, src: site_info.def_logo });
                            }}
                          >
                            Reset
                          </Button>
                          <Label data-testid="" className="btn btn-primary" htmlFor="logo-img">
                            Replace Logo
                          </Label>
                        </div>
                      </div>
                    ) : null}
                    <div data-testid="" className="form-check-label">
                      <h4 className="subheading mb-0">Site Icon</h4>
                      <p className="helper-text mb-2 text-muted">
                        Site Icons are what you see in browser tabs and bookmark bars. Site Icons
                        should be square and at least 512 x 512 pixels.
                      </p>
                    </div>
                    <Input
                      ref={null}
                      onChange={handleImportClick}
                      type="file"
                      data-testid="upload-icon"
                      id="icon-img"
                      name="icon-img"
                      accept=".png, .jpg, .jpeg,.svg"
                      style={{ display: "none" }}
                    />
                    {site_info.icon ? (
                      <div className="mb-3 d-flex flex-column">
                        <div className="site-icon-wrapper mb-3">
                          <img
                            src={iconImg.src}
                            alt="site profile"
                            width="100px"
                            height="100px"
                            style={{
                              marginRight: "12px",
                              objectFit: "fill",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                        <div className="site-icon-button-wrapper">
                          <Button
                            data-testid=""
                            className="btn btn-outline-primary me-2"
                            type="button"
                            onClick={() => {
                              setIconImg({ file: null, src: site_info.def_icon });
                            }}
                          >
                            Reset
                          </Button>
                          <Label data-testid="" className="btn btn-primary" htmlFor="icon-img">
                            Replace Site Icon
                          </Label>
                        </div>
                      </div>
                    ) : null}
                    <FormInput
                      name="website"
                      label="Website"
                      type="url"
                      placeholder="http://yourwebsite.com"
                      reg_options={{ maxLength: 65 }}
                    />
                    <div className="row">
                      <FormInput
                        name="contact.sender_email"
                        label="Sender Email"
                        reg_options={{
                          maxLength: 100,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        }}
                        placeholder="Please enter the sender email"
                        css="mb-0"
                      />
                      <div className="mb-3 text-muted helper-text">
                        Used as the From address for all email notifications.
                        When changing the sender email address, please reach out to our tech support to complete the verification process.
                        <div className="d-flex align-items-center" style={{ lineHeight: "0.8rem" }}>
                          <span>Verified: </span>
                          {
                            isSenderEmailVerified ?
                              <span className="bi bi-check px-1 text-success text-large"></span>
                              : <span className="bi bi-x px-1 text-danger text-large"></span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <hr className="mt-5 mb-4 col-lg-12 col-xl-8 mx-auto" />
            <div className="d-flex flex-column flex-lg-row">
              <div className="col-lg-12 col-xl-8 mx-auto">
                <h5 className="subtitle mb-2">Contact Details</h5>
                <p className="text-muted">MarketPush may utilize this information to get in touch with the individual regarding their account.</p>
                <Card className="p-4">
                  <Contact parentPrefix="contact." />
                </Card>
              </div>
            </div>
            <hr className="mt-5 mb-4 col-lg-12 col-xl-8 mx-auto" />
            <div className="d-flex flex-column flex-lg-row">
              <div className="col-lg-12 col-xl-8 mx-auto">
                <h5 className="subtitle mb-2">Company Address</h5>
                <p className="text-muted">This address will appear on your invoices.</p>
                <Card className="p-4">
                  <Address parentPrefix="address." />
                </Card>
              </div>
            </div>
            <hr className="mt-5 mb-4 col-lg-12 col-xl-8 mx-auto" />
            <div className="row mb-4">
              <div className="col-lg-12 col-xl-8 mx-auto">
                <h5 className="subtitle mb-2">Advanced Settings</h5>
                <p className="text-muted">
                  Maintain your brand identity across the MarketPush platform.
                </p>
                <Card className="p-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}>Description</th>
                        <th>
                          <div className="d-flex align-items-center w-100 justify-content-between">
                            <span>Value</span>
                            <span>Use Default</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {styleData?.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className="align-middel">{row.description}</td>
                            <td className="form-switch">
                              <InputCheckColor
                                index={index}
                                isChecked={row.isChecked}
                                defaultValue={row.defaultValue}
                                value={row.value}
                                cssVariable={row.cssVariable}
                                type={row.type}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
            <div className="col-lg-12 col-xl-8 mx-auto d-flex justify-content-end">
              {fetching ? (
                <Button data-testid="" className="btn btn-primary" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              ) : (
                <Button data-testid="" className="btn btn-primary" type="submit" disabled={!formChanged}>
                  Save
                </Button>
              )}
            </div>
          </Form>
        </FormProvider>
      </div>
    </>
  );
};

export default Appearance;
