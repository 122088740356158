import { useFormContext } from "react-hook-form";
import { getFieldError, regProps } from "../../common/miscellaneous/utility";
import { IFormInputProps, SelectOption } from "../../common/types/types";

type InputProps = Omit<
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  "name"
> &
  IFormInputProps & {
    options?: SelectOption[];
    itemType?: string;
  };

export const FormSelect = (props: InputProps): JSX.Element => {
  const { name, label_extra, label, css, options, reg_options, tool_tip, form_methods } = props;
  const required = reg_options?.required;
  const methods = useFormContext() || form_methods;
  const {
    register,
    formState: { errors },
  } = methods;
  const fieldError = getFieldError(errors, name);
  return (
    <div className={`${css ? css : "mb-3"}`}>
      {" "}
      {/* custom css has higher priority */}
      {label && (
        <label
          data-testid={`label_${name}`}
          className={`font-weight-semi-bold form-label ${required ? "required" : ""}`}
        >
          {label === "LABEL_FOR_POS_HIDDEN" ? "" : label}
          {label_extra}
          {tool_tip && (
            <>
              <span className="ms-1 tooltip-custom right">
                <span className="tooltip-text">{tool_tip}
                </span>
                <i className="bi bi-question-circle"></i>
              </span>
            </>
          )}
        </label>
      )}
      <select className="form-control form-select" {...regProps(name, register, props)}>
        {options &&
          options.map((pair) => (
            <option key={pair.label} value={pair.value}>
              {pair.label}
            </option>
          ))}
      </select>
      {fieldError && (
        <>
          <small className="has-error text-danger mb-0">
            <i className="bi bi-exclamation-circle-fill me-1"></i>
            {fieldError.ref.validationMessage || fieldError.message}
          </small>
        </>
      )}
    </div>
  );
};
