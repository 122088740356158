import { FC } from "react";
import { NavLink } from "react-router-dom";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useMainContext } from "../../layout/components/MainProvider";

export const GeneralAdministration: FC = (): ReturnType<FC> => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/administration`;

  const data = [
    {
      id: 1,
      icon: "bi bi-window",
      title: "Application Settings",
      description: "Set and define MarketPush settings",
      url: `${baseRoute}/app-settings`,
    },
/*     {
      id: 2,
      icon: "bi bi-clipboard-check",
      title: "License Management",
      description: "Manage MarketPush license and plan",
      url: `${baseRoute}`,
    }, */
    {
      id: 3,
      icon: "bi bi-people-fill",
      title: "Manage Roles and Permissions",
      description: "Manage user roles and permissions on MarketPush",
      url: `${baseRoute}/role-permissions`,
    },
/*     {
      id: 4,
      icon: "bi bi-layout-text-window-reverse",
      title: "MarketPush Marketplace Apps",
      description: "Select which MarketPush Marketplace apps are available",
      url: `${baseRoute}`,
    },
    {
      id: 5,
      icon: "bi bi-arrow-counterclockwise",
      title: "Restore Deleted Items",
      description: "Restore accidentally deleted items",
      url: `${baseRoute}`,
    }, */
  ];

  return (
    <article className="mx-3">
      <div className="col-12">
        <ReturnHeader
          title="Administration"
          description="Administrators have access to all features and can manage every aspect of the
          organization’s MarketPush account."
        />
        <div className="d-flex flex-wrap gap-3 align-items-start">
          {data.map((card) => (
            <NavLink
              data-testid=""
              className="administration-card-link"
              to={card.url}
              key={card.id}
            >
              <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded-3 administration-card">
                <div className="mx-3">
                  <i className={`display-2 ${card.icon}`}></i>
                </div>
                <div className="text-left ms-2">
                  <p className="mb-1 display-5 card-title">{card.title}</p>
                  <p className="m-0 display-6 text-muted">{card.description}</p>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </article>
  );
};
