/* eslint-disable no-console */
import parse from 'html-react-parser';
import { FC, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useUserNotificationSubscription } from "../../../generated/urql-graphql";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import "../scss/user-notification.scss";
interface IProps {
  userId: string;
  numNotification?: string;
}

export const UserNotification: FC<IProps> = (props) => {
  const { userId } = props;
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const notificationUrl = `/${tenantUrlTag}/users/notification`;
  const [notificationsSuscription] = useUserNotificationSubscription({
    variables: {
      id: userId,
      operatorId: userInfo.operator_id!
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent("Refresh_Notification",
      { detail: "new notification" }));

  }, [notificationsSuscription.data?.vw_notification_user.length]);

  useEffect(() => {
    const validateClickOutsideTheModal = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        ref.current?.classList.remove("show");
      }
    };

    document.addEventListener("mousedown", validateClickOutsideTheModal);

    return () => {
      document.removeEventListener("mousedown", validateClickOutsideTheModal);
    };
  }, []);

  const showNotificationMenu = () => {
    ref.current?.classList.add("show");
  };

  if (notificationsSuscription.fetching || !notificationsSuscription.data) {
    return (
      <div className="me-2">
        <Button data-testid="" className="btn-clean position-relative">
          <i className="bi bi-bell display-3"></i>
        </Button>
      </div>
    );
  }
  if (notificationsSuscription.error) {
    return <p>{notificationsSuscription.error.message}</p>;
  }

  const notifications = notificationsSuscription.data.vw_notification_user;

  const closeModal = () => {
    ref.current?.classList.remove("show");
  };

  return (
    <div className="me-2 position-relative">
      <Button data-testid="" className="btn-clean position-relative" onClick={showNotificationMenu}>
        <i className="bi bi-bell display-3"></i>
        {notifications.length > 0 && (
          <span className="position-absolute translate-middle badge rounded-pill bg-danger notification-badge">
            {notifications.length > 99 ? "99+" : notifications.length}
          </span>
        )}
      </Button>
      <div
        className="dropdown-menu dropdown-menu-right dropdown-menu-lg shadow notification-dropdown"
        aria-labelledby="dropdownMenuButton"
        ref={ref}
      >
        {notifications.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between align-items-center card-header bg-secondary">
              <h5 className="card-title mb-0 py-2 text-white">Notifications</h5>
              <span className="badge bg-danger text-white">
                {notifications.length > 99 ? "99+" : notifications.length} New
              </span>
            </div>
            <div className="notification-menu-body">
              {notifications.map(
                (notification, index) =>
                  index <= 4 && (
                    <div key={notification.id} className="list-group">
                      <p className="list-group-item notification-item text-truncate mb-0">
                        {parse(notification.notification ?? "")}
                      </p>
                    </div>
                  )
              )}
            </div>
          </div>
        ) : (
          <div className="card-header notification-header bg-secondary">
            <div className="d-flex justify-content-between align-items-center">
              <p className="card-title text-white mb-0">
                <i className="bi bi-bell me-2"></i>Notifications
              </p>
            </div>
          </div>
        )}
        <div className="notification-menu-body p-2">
          <NavLink data-testid="" to={`${notificationUrl}`}>
            <Button data-testid="" onClick={closeModal} className="btn btn-primary my-2 btn-block">
              View all notifications
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
