import { FC } from "react";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { AdvancedSettings } from "./AdvancedSettings";
import { ProductGroup } from "./ProductGroup";
import { Relationships } from "./Relationships";

export const ConfigurationsDefaultRoute: FC = () => {
  const baseRoute = "/configurators";
  const tabs = [
    {
      tabName: "Product Groups",
      route: `${baseRoute}`,

      component: <ProductGroup />,
    },
    {
      tabName: "Relationships",
      route: `${baseRoute}/relationships`,
      component: <Relationships />,
    },
    {
      tabName: "Advanced Settings",
      route: `${baseRoute}/advanced-settings`,
      component: <AdvancedSettings />,
    },
  ];
  return (
    <div className="col-12">
      <ReturnHeader
        title="Product Configurators"
        description="Allow customers to configure and customize products or services."
      />
      <LinkTabsComponent tabs={tabs} />
    </div>
  );
};
