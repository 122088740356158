import { A } from "../../forms/components/A";

const FileLink = (props: {
    fileName: string | null | undefined;
    filePath: string | null | undefined;
}) => {
    const { fileName, filePath } = { ...props };
    const displayLink: boolean =
        fileName != null && fileName != "" && filePath != null && filePath != "";
    const random = (Math.random() + 1).toString(36).substring(7); // to generate a random test-id
    return (
        <>
            {displayLink && (
                <A data-testid={`download-action-${random}`} download={fileName} href={filePath ?? ""}>
                    {fileName}
                </A>
            )}
        </>
    );
};
export default FileLink