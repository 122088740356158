import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import { AddSeller } from "./AddSeller";
import PriceComparisonReport from "./PriceComparisonReport";
import { SellerList } from "./SellerList";
import { SellersData } from "./SellersData";

export const SellersRoute: FC = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const manageSeller = userInfo.permissions! & context.permissions.manage_seller;

  return (
    <Routes>
      <Route index element={<SellerList />} />
      <Route path="archived" element={<SellerList />} />
      {manageSeller && (
        <Route path="add-seller" element={<AddSeller />} />
      )}
      <Route path=":sellerId">
        <Route index element={<SellersData />} />
        <Route path="price-comparison-report" element={<PriceComparisonReport />} />
        <Route path="*" element={<SellersData />} />
      </Route>
    </Routes>
  );
};
