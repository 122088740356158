import _ from "lodash";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { App_Data_Type_Enum, Vw_Auth_User } from "../../../generated/urql-graphql";
import { IAlertInfo, IHash, IImportStatus, MutationAction, SelectOption } from "../types/types";
/* eslint-disable @typescript-eslint/no-explicit-any */
export const isDebug = (): boolean => {
  return process.env.NODE_ENV === "development";
};

const convertToIsoFormat = (date: Date): string => {
  return new Date(date.toLocaleDateString("en-US")).toISOString().slice(0, 10);
};

const convertToDate = (isoString: string): Date => {
  const isoDate = new Date(isoString);
  return new Date(isoDate.getTime() + isoDate.getTimezoneOffset() * 60000);
};

export const isoFormatDate = (isoDate: string | null, numberOfDays: number = 1) => {
  const date = isoDate ? convertToDate(isoDate) : new Date();
  const minDay = adjustedDate(date, numberOfDays);
  return convertToIsoFormat(minDay);
};

export const adjustedDate = (date: Date, numOfDays: number): Date => {
  date.setDate(date.getDate() - numOfDays);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return new Date(`${year}-${month}-${day}`);
};

export const currentDate = () => {
  const isoDate = convertToIsoFormat(new Date());
  const currentDay = convertToDate(isoDate);
  return convertToIsoFormat(currentDay);
};

export const badgeCss = (status: string | undefined | null, compareTo?: string): string => {
  let css = "badge rounded-pill ";
  if (compareTo && status !== compareTo) return css + "shadow-sm bg-light text-danger";
  switch (status) {
    case "Pending":
    case "Hold":
    case "Inactive":
      css += "bg-warning text-dark";
      break;
    case "IncidentOpen":
      css += "bg-orange";
      break;
    case "AwaitingFulfillment":
      css += "bg-secondary";
      break;
    case "Active":
    case "Accepted":
    case "Approved":
    case "Shipped":
    case "Open":
      css += "bg-success";
      break;
    case "Cancelled":
    case "Rejected":
    case "Suspended":
    case "Disputed":
    case "OutOfStock":
      css += "bg-danger";
      break;
    case "Received":
      css += "bg-primary";
      break;
    case "Closed":
      css += "bg-dark";
      break;
    case "PartialRefund":
    case "Refunded":
    case "Archived":
      css += "bg-gray";
      break;
    default:
      css += "bg-info";
      break;
  }
  return css;
};

export const getFieldError = (error: any, name: string): any => {
  if (!error || Object.entries(error).length === 0) return null;
  if (name.indexOf(".") !== -1) {
    const dotIndex = name.indexOf(".");
    const subName = name.slice(0, dotIndex);
    return getFieldError(error[subName], name.substring(dotIndex + 1));
  }
  return error[name];
};

export const regProps = (name: string, register: UseFormRegister<FieldValues>, props: any) => {
  const reg_options = props.reg_options;
  const constraint: { [key: string]: object } = {};
  if (reg_options) {
    Object.keys(reg_options).forEach((key) => {
      if (key !== "valueAsNumber") {
        const obj = reg_options[key];
        let value = typeof obj === "object" ? obj.value : obj;
        constraint[key] = value?.source ? value.source : value;
      }
    });
  }
  const inputProps = _.omit(props, [
    "input_extra",
    "label_extra",
    "reg_options",
    "radio_options",
    "options",
    "isswitch",
    "toggleSearch",
  ]);
  return {
    ...constraint,
    ...inputProps,
    ...register(name, reg_options),
  };
};

export const emptyUuid: string = "00000000-0000-0000-0000-000000000000";

export const isNullOrEmpty = (value: string | null | undefined): boolean => {
  return value === null || value === undefined || value === "" || value === emptyUuid;
};

export const reorderList = (list: string[], startIndex: number, endIndex: number): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getAttributeObj = (commKeys: string[], type: string, obj: any): any => {
  const keyHash: any = {
    text: commKeys.concat(["max_length"]),
    options: commKeys.concat(["options"]),
    datetime: commKeys.concat(["input_type", "show_date_range"]),
    boolean: commKeys.concat(["ui_component", "on_text", "off_text"]),
    number: commKeys.concat(["min_value", "max_value"]),
  };
  const keys = keyHash[type];
  const result = _.pick(obj, keys);
  if (type === "text" && obj.ui_component === "textarea") result["ui_component"] = "textarea";
  return result;
};

export const appDataTypeOptions = Object.values(App_Data_Type_Enum).map((type) => {
  return { value: type, label: type === "boolean" ? "true/false" : type };
});

export const boolUiComponents: SelectOption[] = [
  { label: "Radio", value: "input_radio" },
  { label: "Dropdown", value: "select" },
  { label: "Toggle Switch", value: "input_checkbox" },
];

export const textUiComponents: SelectOption[] = [
  { label: "multi-line text", value: "textarea" },
  { label: "single-line text", value: "input" },
];
export const dateInputTypes: SelectOption[] = [
  { label: "Date", value: "date" },
  { label: "Date and Time", value: "datetime-local" },
];

export const checkDuplicate = (fieldValues: IHash) => {
  const dupObj: IHash = {};
  Object.keys(fieldValues).forEach((name) => {
    const value = fieldValues[name];
    value && value in dupObj ? dupObj[value].push(name) : (dupObj[value] = [name]);
  });
  let dups: string[] = [];
  for (const key in dupObj) if (dupObj[key].length > 1) dups = [...dups, ...dupObj[key]];
  return dups;
};

export const getImportStatus = (status: string): IImportStatus => {
  let obj = {
    color: "text-primary",
    text: separateWithSpace(status),
    icon: "bi-hourglass",
  };
  switch (status) {
    case "Complete":
      obj = { ...obj, color: "text-success", icon: "bi-check-circle" };
      break;
    case "Error":
    case "CompleteWithErrors":
      obj = { ...obj, color: "text-danger", icon: "bi-exclamation-triangle" };
      break;
  }
  return obj;
};

export const separateWithSpace = (text: string | null | undefined): string =>
  text?.replace(/([A-Z])/g, " $1").trim() || "";

export const mutationInfo = (keyText: string, action: MutationAction, res: any): IAlertInfo => {
  const mutationOp = res && res.operation;
  let type: "success" | "error";
  let message = "";
  let errMsg = undefined;
  let strAction = MutationAction[action].toLowerCase();
  switch (strAction) {
    case "login":
      strAction = "logged into";
      break;
    case "send":
      strAction = "sent";
      break;
    default:
      strAction += strAction.slice(-1) === "e" ? "d" : "ed";
      break;
  }
  let strKeyTxt = keyText.substring(0, 1).toUpperCase() + keyText.substring(1);

  if (mutationOp && !res.error) {
    message = strKeyTxt + ` has been ` + strAction + ` successfully!`;
    if (strKeyTxt === "The notifications") {
      message = strKeyTxt + ` have been ` + strAction + ` successfully!`;
    }
    type = "success";
  } else {
    message = `An error occurred while trying to ${MutationAction[
      action
    ].toLowerCase()} ${keyText}!`;
    type = "error";
    if (mutationOp) {
      const errTitle =
        res.error.message.indexOf("Uniqueness") !== -1 ? `[${keyText}] already exists` : "";
      message += errTitle && ` - ${errTitle}`;
      const match = mutationOp.query?.loc?.source.body.match(/mutation \w+/);
      const mutationName = match.length && match[0].replace("mutation ", "");
      errMsg = res.error.message + " - " + mutationName;
    } else {
      errMsg = res;
    }
    isDebug() && console.error(errMsg);
  }
  return { message, type, errMsg };
};

export const isEqual = (array1: string[], array2: string[]) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]);

export const isSimilar = (array1: string[], array2: string[]) =>
  array1.length === array2.length && array1.every((value) => array2.includes(value));

export const LandingPageUrl = (
  landingPageName: string | null | undefined,
  tenantUrlTag: string,
  userInfo: Vw_Auth_User
): string => {
  if (window.localStorage.getItem("user-login") === "true") {
    window.localStorage.setItem("user-login", "false");
  }

  let landing = "";
  switch (landingPageName) {
    case "My Seller":
      landing = `/sellers/${userInfo.seller_id}`;
      break;
    case "Products":
    case "Offers":
    case "Orders":
    case "Sellers":
    case "Reports":
      landing = `/${landingPageName.toLocaleLowerCase()}`;
      break;
  }
  return landing ? `/${tenantUrlTag}` + landing : "";
};

export const getFileExt = (filename: string) => {
  const { path, last: ext } = popText(filename);
  return ext ? "." + ext.toLowerCase() : "";
};

export const popText = (text: string | null | undefined, separator: string = ".") => {
  const parts = text?.split(separator);
  const last = parts?.pop() || "";
  const path = parts?.join(separator) || "";
  return { path, last };
};

export function dateRangeToStr(startDt: Date, endDt: Date) {
  let startYY = startDt.getFullYear();
  let startMM = startDt.getMonth() + 1;
  let startDD = startDt.getDate();
  let endYY = endDt.getFullYear();
  let endMM = endDt.getMonth() + 1;
  let endDD = endDt.getDate();

  return `'${startYY}-${startMM}-${startDD}' and '${endYY}-${endMM}-${endDD}'`;
}

export const keydownfunc = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  evt.key === "." && evt.preventDefault();
  evt.key === "e" && evt.preventDefault();
  evt.key === "-" && evt.preventDefault();
  evt.key === "+" && evt.preventDefault();
};

export const delay = (millisec: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, millisec);
  });
};
