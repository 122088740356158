import { FC, useEffect, useRef } from "react";
import { useGetMessagesSubscription } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import FileLink from "../../common/components/FileLink";
import { Loader } from "../../common/components/Loader";
import Separator from "../../common/components/Separator";
import { useMainContext } from "../../layout/components/MainProvider";

interface IProps {
  customerDetails: {
    email: string;
    fullName?: string;
  }
  messageSellerId: string;
}

interface IMessage {
  id: string;
  message_text: string | null | undefined;
  created_date: string;
  user_info?: any;
  customer_info?: any;
  file_name?: string | null | undefined;
  file_path?: string | null | undefined;
  offer?: {
    id: string
    seller_product_sku: string
  }
  seller_id: string;
  customer_email: string;
  message_type: string;
  message_from: string;
  user: any;
}

interface IFileInfo {
  file_name: string;
  file_path: string;
  file_size: number;
}
const MessageThread: FC<IProps> = (props): ReturnType<FC> => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const offerRoute = `/${tenantUrlTag}/offers`;
  const { messageSellerId, customerDetails } = props
  const messagesDiv = useRef<HTMLDivElement>(null);
  const whereExpr = {
    seller_id: { _eq: messageSellerId }, _and: [{ customer_email: { _eq: customerDetails.email } }, { message_type: { _neq: "Order" } }]
  }
  const inputJSON = {
    variables: {
      where: whereExpr
    }
  }

  const [queried] = useGetMessagesSubscription(inputJSON)

  const scrollDown = () => {
    messagesDiv.current?.scrollTo(0, messagesDiv.current?.scrollHeight);
  };
  useEffect(() => {
    scrollDown();
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "MessageThread", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />
  }
  let lastDate: string = "";
  const messages = queried.data?.message as IMessage[]
  const isDateSame = (date1: string, date2: string) => {
    if (date1 == "" || date1 == null) return false;

    const dateOne = new Date(date1);
    const dateTwo = new Date(date2);
    dateOne.setHours(dateOne.getHours() - 8);
    dateTwo.setHours(dateTwo.getHours() - 8);

    if (
      dateOne.getDate() == dateTwo.getDate() &&
      dateOne.getMonth() == dateTwo.getMonth() &&
      dateOne.getFullYear() == dateTwo.getFullYear()
    )
      return true;
    return false;
  };
  const formatTime = (date: string) => {
    const datetimeDate = new Date(date);
    // datetimeDate.setHours(datetimeDate.getHours() - 8);
    let displayTime = datetimeDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return displayTime;
  };

  if (messages.length === 0) {
    return <div className="text-center my-3">There is no messages in this thread.</div>
  }
  return (
    <div style={{ overflowY: "scroll", maxHeight: "350px" }} className="border mb-3" ref={messagesDiv}>
      {
        messages.map((message, index) => {
          if (message.message_from == "Seller")
            return (
              <div key={index}>
                <Separator
                  display={!isDateSame(lastDate, message.created_date)}
                  date={message.created_date}
                />
                <div className="d-none">{(lastDate = message.created_date)}</div>
                <div className="message-wrapper d-flex justify-content-start">
                  <div className="message-avatar">
                    {message.user && (
                      <img
                        className="rounded-circle"
                        src={message.user?.profile_image}
                        alt={message.user?.profile_image}
                      />
                    )}
                  </div>
                  <div className="message-content col-md-5 mt-2">
                    <span className="font-weight-medium me-2">
                      {message.user_info?.full_name ?? message.user_info?.email ?? ""}
                    </span>
                    <span className="text-muted small">{formatTime(message.created_date)}</span>
                    <div className="rounded bg-light mt-2 p-3">
                      <div className="mb-1">{message.message_text ?? ""}</div>
                      <FileLink fileName={message.file_name} filePath={message.file_path} />
                    </div>
                  </div>
                </div>
              </div>
            )
          if (message.message_from == "Customer")
            return (
              <div key={index}>
                <Separator
                  display={!isDateSame(lastDate, message.created_date)}
                  date={message.created_date}
                />
                <div className="d-none">{(lastDate = message.created_date)}</div>
                <div className="m-3 pb-3 d-flex justify-content-end" key={index}>
                  <div className="mt-2 col-md-5 text-right">
                    <b>{message.customer_info?.full_name ?? ""}</b> - {formatTime(message.created_date)}
                    <div className="rounded bg-light mt-2 p-3">
                      {message.message_type === "Offer" && <div>(inquiry about offer <a data-testid="" href={`${offerRoute}/${message.offer?.id}`}>{message.offer?.seller_product_sku}</a>)</div>}
                      <div className="mb-1">{message.message_text ?? ""}</div>
                      <FileLink fileName={message.file_name} filePath={message.file_path} />
                    </div>
                  </div>
                  <div className="message-avatar">
                    {message.user && (
                      <img
                        className="rounded-circle"
                        src={message.user?.profile_image}
                        alt={message.user?.profile_image}
                      />
                    )}
                  </div>
                </div>
              </div>)
        })
      }
    </div>
  )
}
export default MessageThread