import { FC } from "react";

type Props = {
  children: React.ReactNode;
  /**
   * className to override card styles
   */
  className?: string;
};

export const Card: FC<Props> = (props): ReturnType<FC> => {
  const { children, className } = props;
  return <div className={`card shadow-sm ${className ? className : "p-3 mb-3"}`}>{children}</div>;
};
