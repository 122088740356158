import { useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateRoleMutation,
  useRoleDetailsQuery,
  useUpdateRolePermissionsMutation,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { appRef } from "../../common/components/appStatus";
import { useGroupList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { IRoleDetails } from "../miscellaneous/types";
import { PermissionsByRole, RolePerms } from "./PermissionsByRole";

export const RolePermissionDetails = () => {
  let { roleId } = useParams<{ roleId: string }>();
  roleId = roleId || emptyUuid;
  const [updatedPerms, setUpdatedPerms] = useState<string[]>([]);
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/administration/role-permissions`;

  const navigate = useNavigate();
  const permRef = useRef<RolePerms>(null);
  const [, createRoleMutation] = useCreateRoleMutation();
  const [, updateRoleMutation] = useUpdateRolePermissionsMutation();
  const userInfo = useUserInfo()!;
  const inputs = {
    variables: {
      roleId,
    },
  };

  const [queried] = useRoleDetailsQuery(inputs);
  const groups = useGroupList();
  const groupOptions = groups.map((group) => {
    return { label: group.name, value: group.base_level.toString() };
  });

  const [formChanged, setFormChanged] = useState<boolean>(false)
  const [permChanged, setPermChanged] = useState<boolean>(false)

  const methods = useForm<IRoleDetails>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset, watch, formState: { errors, dirtyFields } } = methods;

  useEffect(() => {
    const role = queried.data?.role_by_pk;
    if (role) {
      const level = role.user_level;
      const temp = { ...role, user_level: !level || level === 35 ? 30 : level };
      reset(temp);
    }
  }, [queried.data?.role_by_pk]);

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed || permChanged);
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    appRef.current?.updateStatus(formChanged || permChanged);
  }, [permChanged]);

  const groupLevel = watch("user_level");
  const apiOnly = watch("properties.api_only") || queried.data?.role_by_pk?.properties.api_only;
  if (queried.error) {
    return <p>{queried.error.message}</p>;
  }
  if (!queried.data) return <Loader />;

  const role = queried.data?.role_by_pk;
  const perms = queried.data?.permission;
  const manageRolePermissions = userInfo.permissions! & context.permissions.manage_role_permission && role?.id !== userInfo.role_id;

  const onSubmit = (formData: FieldValues) => {
    const updatedRole = formData as IRoleDetails;
    if (!updatedRole) return;
    const updatedRolePerms = permRef?.current?.get();
    if (updatedRolePerms?.length === 0) {
      alertsRef.current?.add("At lease one permission must be assigned", "error");
      return;
    }

    let roleProps = { ...updatedRole.properties };
    if (roleProps.group_admin === false) roleProps = { ...roleProps, group_admin: void 0 };
    if (roleProps.api_only === false) roleProps = { ...roleProps, api_only: void 0 };
    if (!updatedRole.id) {
      //addRole
      const groupId = groups
        ?.filter((group) => group.base_level === groupLevel)
        .find((group) => group)?.id;
      createRoleMutation({
        description: updatedRole.description,
        name: updatedRole.name,
        userLevel: updatedRole.user_level,
        groupId: groupId,
        properties: roleProps,
        permissions: updatedRolePerms!,
      })
        .then((res) => {
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Create, res));
          if (res.data && !res.error) {
            appRef.current?.updateStatus(false);
            setTimeout(() => navigate(baseRoute), 300);
          }
          return;
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Create, error));
          return;
        });
    } else {
      updateRoleMutation(
        {
          description: updatedRole.description,
          name: updatedRole.name,
          userLevel: updatedRole.user_level,
          roleId: updatedRole.id,
          properties: roleProps,
          rolePermissions: updatedRolePerms!,
        },
        { additionalTypenames: ["permission", "role_permission_jnc_aggregate", "role_permission_jnc"] }
      )
        .then((res) => {
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Update, res));
          if (res.data && !res.error) {
            appRef.current?.updateStatus(false);
            setUpdatedPerms(updatedRolePerms?.map(perm => perm.permission_id) || []);
            setFormChanged(false);
            setPermChanged(false);
          } else {
            return;
          }
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Create, error));
          return;
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <ReturnHeader
            title={(roleId === emptyUuid ? "Add" : "Edit") + " Role"}
            url={baseRoute}
          ></ReturnHeader>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <h5 className="sub-title">Roles and Permissions</h5>
          <p className="text-muted">
            Role and permission determine what MarketPush users can see and do.
          </p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12 col-xl-8 mx-auto">
          <Card className="p-4">
            <FormProvider {...methods}>
              <Form
                data-testid="role-details-form"
                id="role-details-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormSelect
                  name="user_level"
                  label="Group"
                  options={groupOptions}
                  css="mb-3"
                  reg_options={{ required: true }}
                  disabled={!isNullOrEmpty(role?.id) || !manageRolePermissions}
                />
                <FormInput
                  name="name"
                  label="Role Name"
                  css="mb-3"
                  reg_options={{
                    required: true,
                  }}
                  disabled={!manageRolePermissions}
                />
                <FormTextarea
                  name="description"
                  label="Description"
                  css="mb-3"
                  reg_options={{
                    required: true,
                  }}
                  disabled={!manageRolePermissions}
                />
                <div className="d-flex justify-content-between">
                  <FormInput
                    name="properties.api_only"
                    label="API Only"
                    type="checkbox"
                    css="d-flex align-items-center w-100"
                    disabled={!manageRolePermissions}
                  />
                  {groupLevel && groupLevel < 20 && (
                    <FormInput
                      name="properties.group_admin"
                      label="Group Admin"
                      type="checkbox"
                      css="d-flex align-items-center w-100"
                      disabled={!manageRolePermissions}
                    />
                  )}
                </div>
              </Form>
            </FormProvider>
            <PermissionsByRole
              permissions={perms!}
              groupLevel={groupLevel}
              roleId={roleId!}
              ref={permRef}
              apiOnly={apiOnly}
              updatedPerms={updatedPerms}
              permChanged={(changed: boolean) => setPermChanged(changed)}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mx-auto">
          {manageRolePermissions ? (
            <div className="mt-2 d-flex flex-row-reverse">
              <Button
                data-testid="btn-submit"
                form="role-details-form"
                value="Submit"
                className="btn btn-primary ms-1"
                disabled={!(formChanged || permChanged)}
              >
                Save
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
