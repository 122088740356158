import { SyntheticEvent } from "react";
import { SelectOption } from "../../types/types";

interface IProps {
  option: SelectOption;
  handleSuppress: (event: SyntheticEvent) => void;
  handleSelect: (option: SelectOption) => void;
  multiple?: boolean;
  disabled?: boolean;
}

export const SelectedItem = ({ option, multiple, handleSuppress, handleSelect, disabled }: IProps) => {
  const handleRemove = (event: SyntheticEvent) => {
    handleSuppress(event);
    handleSelect(option);
  };

  return multiple ? (
    <span
      key={option.value}
      className="badge rounded bg-light-gray text-dark me-2 text-break mb-1"
    >
      {option.label}
      <i className={`bi bi-x-lg ms-1`} onMouseDown={(event) => !disabled && handleRemove(event)}/>
    </span>
  ) : (
    <div className="mx-2">{option.label}</div>
  );
};
