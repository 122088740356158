import { FC, useEffect, useRef, useState } from "react";
import {
  UserNotificationTypeSubSubscription,
  UserProfilePartFragment,
  useCreateUserNotificationMutation,
  useCreateUserNotificationsMutation,
  useDeleteUserNotificationMutation,
  useDeleteUserNotificationsMutation,
  useGetAllNotificationTypeSubscription,
  useNotificationTypeCountQuery,
  useNotificationTypeSubscription,
  useOperatorUserProfileSubscription,
  useUpdateUserEmailNotificationMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, MutationAction } from "../../common/types/types";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

type NotificationItem = {
  name: string;
  category: string;
  description: string;
  id: string;
  action: JSX.Element;
};
interface PropDT {
  data: UserProfilePartFragment | undefined;
  dataUserNotification: UserNotificationTypeSubSubscription;
}
const UserProfileEmailNotifications: FC<PropDT> = (props) => {
  const [context] = useMainContext();
  const { data, dataUserNotification } = props;
  const [loading, setLoading] = useState<number>(-1);

  let notificationList: NotificationItem[];

  const userInfo = useUserInfo();
  let sellerId = userInfo.seller_id;
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [dateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "",
  });
  const multiSelectRef = useRef<IRef | null>(null);
  const formFields = {
    getEmailNotification: useRef<HTMLInputElement>(null),
  };
  const { number_items_per_page } = context.operatorSettings.preset;
  const tableColumns = ["category", "description", "action"];
  const tableColumnsToSearch = ["description", "group_name"];
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule: `,"notification_delivery_type": {"name": {"_eq": "email"}}`
        })
      ),
    },
  };
  const inputCount = {
    variables: {
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
          dateRange,
          selectedFilters,
          customRule: `,"notification_delivery_type": {"name": {"_eq": "email"}}`
        })
      ),
    },
  };
  const [OperatorUserProfile] = useOperatorUserProfileSubscription({
    variables: {
      userId: data?.id
    }
  });
  const [notificationTypeData] = useNotificationTypeSubscription(inputs);
  const [notificationTypeCount] = useNotificationTypeCountQuery(inputCount);
  const [, updateUserEmailNotificationMutation] = useUpdateUserEmailNotificationMutation();
  const [, createUserNotificationMutation] = useCreateUserNotificationMutation();
  const [, createUserNotificationsMutation] = useCreateUserNotificationsMutation();
  const [, deleteUserNotificationMutation] = useDeleteUserNotificationMutation();
  const [, deleteUserNotificationsMutation] = useDeleteUserNotificationsMutation();
  const [notificationTypeList] = useGetAllNotificationTypeSubscription({});
  const columnList = ["Category", "Description", "Action"];

  useEffect(() => {
    if (notificationTypeData.data) {
      setLoading(ELoadingType.None);
    }
  }, [notificationTypeData.data]);

  const error = OperatorUserProfile.error || notificationTypeData.error
    || notificationTypeCount.error || notificationTypeList.error;
  if (error) {
    return <PageError error={{ source: "UserProfile-EmailNotifications", errMsg: error.message }} />;
  }

  if (isNullOrEmpty(userInfo.user_id)
    || !OperatorUserProfile.data
    || !notificationTypeData?.data
    || !notificationTypeCount.data
    || !notificationTypeList.data) {
    return (
      <Card>
        <div className="mt-5"></div>
        <TablePlaceHolder columnNames={columnList} numberOfRows={20} />
      </Card>
    );
  }

  const UpdateUserProfile = async () => {
    const res = await updateUserEmailNotificationMutation({
      userId: data?.id, // userInfo.user_id,
      getEmailNotification: formFields.getEmailNotification.current?.checked ? true : false,
    });
    alertsRef.current?.generate(mutationInfo("email notification setting", MutationAction.Update, res));
  };

  const CreateUserNotification = async (notificationId: string, notificationName: string) => {
    const res = await createUserNotificationMutation({
      userId: userInfo.user_id,
      operatorId: userInfo.operator_id,
      notificationId: notificationId,
    });
    alertsRef.current?.generate(mutationInfo("email notification", MutationAction.Enable, res));
  };

  const DeleteUserNotification = async (notificationId: string, notificationName: string) => {
    const res = await deleteUserNotificationMutation({
      userId: userInfo.user_id,
      operatorId: userInfo.operator_id,
      notificationId: notificationId,
    });
    alertsRef.current?.generate(mutationInfo("email notification", MutationAction.Disable, res));
  };

  const HandleOnChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    name: string
  ) => {
    if (name === "turn_on_off_email_notification") {
      UpdateUserProfile();
    } else {
      if (event.target.checked) {
        CreateUserNotification(key, name);
      } else {
        DeleteUserNotification(key, name);
      }
    }
  };

  const HasRecord = (notificationName: string) => {
    let filterItem1 = notificationTypeData.data?.notification_type.filter(
      (item) => item.name === notificationName
    )[0];
    let filterItem2 = dataUserNotification?.user_notification_type.filter(
      (item) => item.notification_id === filterItem1?.id
    );
    return filterItem2?.length ? filterItem2?.length > 0 : false;
  };

  const applyChanges = async () => {
    let notificationIdList = multiSelectRef.current?.selectedData;
    if (multiSelectRef.current?.selectedOption === "Enable selected") {
      if (notificationTypeList && notificationTypeList.data) {
        interface IData {
          user_id: string;
          operator_id: string;
          notification_id: string;
        }
        let objs: IData[] = notificationTypeList.data.notification_type
          .filter((item) => notificationIdList?.find((selectedId) => selectedId === item.id))
          .filter(
            (item) =>
              !dataUserNotification.user_notification_type.find(
                (element) => element.notification_id === item.id
              )
          )
          .map((item) => {
            return {
              user_id: userInfo.user_id,
              operator_id: userInfo.operator_id,
              notification_id: item.id,
            };
          });

        const res = await createUserNotificationsMutation({ objects: objs });
        alertsRef.current?.generate(mutationInfo("selected email notifications", MutationAction.Enable, res));
      }
    }

    if (multiSelectRef.current?.selectedOption === "Disable selected") {
      const res = await deleteUserNotificationsMutation({
        userId: userInfo.user_id,
        operatorId: userInfo.operator_id,
        notificationIdList: notificationIdList,
      });
      alertsRef.current?.generate(mutationInfo("selected email notifications", MutationAction.Disable, res));
    }
  };

  const options = ["Enable selected", "Disable selected"];
  const notification_checked = OperatorUserProfile.data?.operator_user[0].get_email_notification === true ? true : false;//  data?.get_notification === true ? true : false;

  const notificationTypeFilter = sellerId ? 'seller' : 'operator';

  notificationList = notificationTypeData.data.notification_type
    .filter(item => item.user_type?.includes(notificationTypeFilter))
    .map((item) => {
      return {
        id: item.id,
        name: item.name || "",
        category: item.group_name || "",
        description: item.description || "",
        action: (
          <div className="form-check form-switch mt-3 mb-1 ml-5 d-flex justify-content-center">
            <Input
              key={item.id}
              data-testid=""
              ref={null}
              name={item.name || ""}
              className="form-check-input"
              type="checkbox"
              disabled={!notification_checked}
              checked={HasRecord(item.name || "")}
              onChange={(event) => {
                HandleOnChanged(event, item.id, item.name || "");
              }}
            />
          </div>
        ),
      };
    });

  const addFilterOptions: { label: string; value: string }[] = [];
  return (
    <>
      <Card className="p-4 no-top-border">
        <div className="d-flex flex-column position-absolute">
          <div className="form-check form-switch mt-2 mb-1">
            <Label className="mb-1" data-testid="">
              <Input
                data-testid=""
                ref={formFields.getEmailNotification}
                name="turn_on_off_notification"
                className="form-check-input"
                type="checkbox"
                checked={notification_checked}
                onChange={(event) => {
                  HandleOnChanged(event, "", "turn_on_off_email_notification");
                }}
              />
              <span>
                Enable email notifications
              </span>
            </Label>
          </div>
        </div>

        <Table
          columnNames={tableColumns}
          data={notificationList}
          offset={offset}
          setOffset={setOffset}
          //totalRecords={notificationTypeCount.data.notification_type_aggregate.aggregate?.count}
          multiSelectTable={true}
          filters={true}
          addFilterOptions={addFilterOptions}
          setSearchValue={setSearchValue}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          options={options}
          applyChanges={applyChanges}
          ref={multiSelectRef}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Descripti..."
          searchHint="Search by Description,Category"
        />
      </Card>
    </>
  );
}

export default UserProfileEmailNotifications