import {
    Transactions_Billing_Statement,
    Seller
} from "../../../generated/urql-graphql";

export interface IImageInfo {
    imageURL: string;
    imageWidth: number;
    imageHeight: number;
}


export interface IStatementSeller {
    id: string,
    company_name: string,
    shop_id:string,    
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    line1: string,
    line2: string,
    city: string,
    state: string,
    zipcode: string
}

export type IBillingStatement = Omit<Transactions_Billing_Statement, "seller" | "billing_statement_seller"> & { stmtSeller: IStatementSeller };

export interface PDFProps {
    billing_statement: IBillingStatement,
    timeZone: string,
    logoImage: IImageInfo
}

export const ToIStatementSeller = (sellerFrom: any| null | undefined) => {
    let stmt_seller = sellerFrom;
        const result = {
            company_name: stmt_seller?.company_name ?? "",
            id: stmt_seller?.id ?? "",
            phone: stmt_seller?.contact?.phone ?? "",
            first_name: stmt_seller?.contact?.first_name ?? "",
            last_name: stmt_seller?.contact?.last_name ?? "",
            email: stmt_seller?.contact?.email ?? "",
            shop_id: stmt_seller?.shop_id ?? "",
            line1: stmt_seller?.addresses?.[0].line1 ?? "",
            line2: stmt_seller?.addresses?.[0].line2 ?? "",
            city: stmt_seller?.addresses?.[0].city ?? "",
            state: stmt_seller?.addresses?.[0].state ?? "",
            zipcode: stmt_seller?.addresses?.[0].zipcode ?? "",
        }
        return result;
    
}