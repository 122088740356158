import { FC, forwardRef, Ref } from "react";

type Props = Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >,
  "ref"
> & {
  /**
   * force inclusion for testing
   */
  "data-testid": string;
  /**
   * force inclusion for browser constraint api, pass null to bypass
   */
  ref: Ref<HTMLSelectElement>;
};

export const Select: FC<Props> = forwardRef<HTMLSelectElement>((props, ref) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <select ref={ref} {...props}>
      {(props as any)?.children}
    </select>
  );
});
