import { FC, useState } from "react";
import {
  useBillingDetailsQuery,
  useDeleteBillingPeriodMutation,
  useSetDefaultBillingMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { BillingPeriodForm } from "./BillingPeriodForm";

const BillingPeriodsView: FC = () => {
  const [context] = useMainContext();
  const userInfo = useUserInfo()!;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings`;
  const [, deleteMutation] = useDeleteBillingPeriodMutation();
  const [, setDefault] = useSetDefaultBillingMutation();
  const manageBilling = userInfo.permissions! & context.permissions.manage_billing_period;

  const defActionState = { ids: null, action: "", item: null };
  const [actionState, setActionState] = useState<IActionState>(defActionState);
  const tableColumns = ["Billing Period", "Default", "Assigned Sellers", "Actions"];

  const [queried, reexecuteQuery] = useBillingDetailsQuery({});
  const billingList = queried.data?.billing_period || [];
  const tableData = billingList?.map((billing, index) => {
    const canEdit = manageBilling && !billing.cp_billing_sellers;
    const actions = [
      {
        enabled: true,
        actionType: canEdit ? "edit" : "view",
        id: billing.id,
        icon: canEdit ? "bi bi-pencil" : "bi bi-eye",
        actionFunc: () => setActionState({ ...actionState, action: canEdit ? "Edit" : "View", item: billing })
      },
      {
        enabled: manageBilling && !billing.default,
        actionType: "Set Default",
        id: billing.id,
        icon: "bi bi-check",
        actionFunc: () => setActionState({ ...actionState, action: "Set Default", item: billing })
      },
      {
        enabled: canEdit && !billing.default,
        actionType: "delete",
        id: billing.id,
        actionFunc: () => setActionState({ ...actionState, action: "Delete", item: billing })
      },
    ];
    return {
      id: billing.id,
      Billing_Period: billing.billing_period_name,
      Default: billing.default && <span className="bi bi-check text-success"></span>,
      Assigned_Sellers: billing.cp_billing_sellers,
      Actions: <DropdownItems items={actions.filter(item => item.enabled)} />
    };
  })
  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      const id = actionState.item.id;
      let res;
      if (actionState.action === "Delete") {
        res = await deleteMutation({ id });
      } else {
        res = await setDefault({ id });
      }
      alertsRef.current?.generate(mutationInfo("billing period", MutationAction.Update, res));
    };
    setActionState(defActionState);
  }

  return (
    <>
      <div className="inner-wrapper">
        <div className="row">
          <div className="col-lg-12 col-xl-8 mx-auto">
            <ReturnHeader
              url={baseRoute}
              title="Billing Periods"
              description="You can have up to four different billing periods. A billing period defines the length
              of time between the last statement closing date and the next for your sellers."
            >
              <Button
                disabled={billingList && billingList?.length >= 10}
                className="btn btn-primary"
                data-testid="btn-add-billing-period"
                onClick={() => setActionState({ ...actionState, action: "Add" })}
              >
                Add Billing Period
              </Button>
            </ReturnHeader>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-8 mx-auto">
            <Card className="p-4">
              <Table
                columnNames={tableColumns}
                data={tableData}
              />
            </Card>
          </div>
        </div>
      </div>
      {["Delete", "Set Default"].includes(actionState.action) &&
        <Dialog
          title={`${actionState.action} Billing Period`}
          continueText={actionState.action}
          continue={continueAction}
          contineBtnCss={"btn-danger"}
        >
          Are you sure you want to {actionState.action.toLowerCase()} this billing peroid?
        </Dialog>}
      {["Add", "Edit", "View"].includes(actionState.action) &&
        <BillingPeriodForm
          onClose={() => setActionState(defActionState)}
          billing={actionState.item}
          viewOnly={actionState.action === "View"}
          reexecuteQuery={reexecuteQuery}
          initBilling={!billingList.length}
        />}
    </>
  );
};

export default BillingPeriodsView;


