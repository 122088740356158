import { FC, useEffect, useState } from "react";
import {
  DeliveryMethodPartFragment,
  useCreateDeliveryMethodMutation,
  useEditDeliveryMethodMutation,
} from "../../../generated/urql-graphql";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Dialog } from "../../common/components/Dialog";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { FormInput } from "../../forms/components/FormInput";

type IProps = {
  onClose: () => void;
  deliveryMethod: DeliveryMethodPartFragment | null
};

export const DeliveryMethodForm: FC<IProps> = (props): ReturnType<FC> => {
  const {
    onClose,
    deliveryMethod,
  } = props;

  const userInfo = useUserInfo();
  const action = deliveryMethod ? "Edit" : "Add";
  const [{ fetching : creating}, createMutation] = useCreateDeliveryMethodMutation();
  const [{ fetching: editing }, editMutation] = useEditDeliveryMethodMutation();
  const [errorMsg, setErrorMsg] = useState<string>("");

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = methods;

  const status = watch("status") || false;

  useEffect(()=>{
    if (deliveryMethod)
      reset(deliveryMethod);
  }, [deliveryMethod]);

  const onSubmit = async (formData: FieldValues) => {
    let res;
    if(deliveryMethod) {//update
      res = await editMutation({
        id: deliveryMethod.id, 
        dlyMethod: {...formData, updated_date: 'now()'}
      });
    }
    else { //create
      const dlyMethod = {
        ...formData,
        operator_id: userInfo.operator_id,
      } as any;
      res = await createMutation({dlyMethod});
    }
    if (!res.error) {
      onClose();
      setErrorMsg("");
      mutationInfo("the delivery method", 
        deliveryMethod ? MutationAction.Update : MutationAction.Create, res);
    }
    else {
      setErrorMsg(res.error.message);
    }
  }

  const continueAction = (isContinue: boolean) => {
    if(!isContinue)
      onClose();
  }

  return (
    <Dialog
      title={`${action} Delivery Method`}
      continue={continueAction}
      continueText="Save"
      errorMsg={errorMsg}
      fetching={creating || editing}
      subFormId="manage_delivery_method_form"
      footerText={
        <FormInput
          form_methods={methods}
          label={`Status: ${status ? "Enabled" : "Disabled"}`}
          name="status"
          type="checkbox"
          isswitch={true}
        />
      }
    >
      <FormProvider {...methods}>
        <Form
          data-testid=""
          noValidate
          id="manage_delivery_method_form"
          onSubmit={handleSubmit(onSubmit)}
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="mb-2">
            <FormInput
              name="delivery_method_name"
              label="Delivery Method Name"
              reg_options={{required: true, maxLength: 80}}
              placeholder="Enter name of delivery method"
              css="mb-0"
            />
            <span className="small text-muted">Customers will see this</span>
          </div>
          <FormInput
            name="delivery_time"
            label="Delivery time"
            reg_options={{maxLength: 65}}
            placeholder="Enter a delivery time (i.e., 5 to 10 business days)"
            label_extra={<span className="ms-1 tooltip-custom right">
                            <span className="tooltip-text">Your realtime shipping service may provide its own
                              delivery time.
                            </span>
                            <i className="bi bi-question-circle"></i>
                          </span>}
          />
          <FormTextarea
            name="description"
            label="Description"
            reg_options={{ maxLength:100 }}                             
          />
        </Form>
      </FormProvider>
    </Dialog>
  );
};
