import { FC } from "react";
import { App_Setting_Category_Enum } from "../../../generated/urql-graphql";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isNullOrEmpty } from "../../common/miscellaneous/utility";
import { useMainContext } from "../../layout/components/MainProvider";
import { SettingsByCategory } from "./SettingsByCategory";

export const AppSettingsRoute: FC = (): ReturnType<FC> => {
  const [context] = useMainContext();
  const userInfo = useUserInfo();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const settingPath = userInfo.user_level! >= 30 ? "administration" : "settings";
  const baseRoute = `/${tenantUrlTag}/${settingPath}`;
  const settingRoute = `${baseRoute}/app-settings`;
  const emulating = !isNullOrEmpty(userInfo.emulater) && userInfo.user_level! < 30;

  let tabNames = userInfo.user_level! >= 30 ?
    ["system"] :
    ["preset", "seller", "product", "offer", "order"];
  const tabs = tabNames.map((name, index) => ({
    enabled: ["preset", "seller"].includes(name) || emulating || userInfo.user_level! >= 30,
    tabName: name,
    route: settingRoute + (index === 0 ? "" : `/${name}`),
    component: <SettingsByCategory key={name} category={name as App_Setting_Category_Enum} />,
  }));

  return (
    <>
      <ReturnHeader
        url={baseRoute}
        title="Application Settings"
        description="Customize and configure various aspects of MarketPush to align with your preferences and business needs."
      />
      {tabs.length === 1 ? tabs[0].component :
        <LinkTabsComponent tabs={tabs.filter(tab => tab.enabled)} baseRoute={settingRoute} />}
    </>
  );
};
