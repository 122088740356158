import { FC, useEffect, useState } from "react";
import {
  Vw_Report_Operator_Category_Count,
  useDashboardTop10CategoriesQuery
} from "../../../generated/urql-graphql";
import { ExportData } from "../../azure/components/AzureBlob";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../../orders-route/handler/handler";
export const PieChartComponent: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const notificationUrl = `/${tenantUrlTag}/users/notification`;

  const [where, setWhere] = useState<string>(`{
              "operator_id": { "_eq": "${userInfo.operator_id}" }
           }`);

  let inputs = {
    variables: {
      where1: JSON.parse(where),
      where2: JSON.parse(where),
    },
  };

  const [reportCategoryCountData] = useDashboardTop10CategoriesQuery(inputs);

  useEffect(() => {
    if (reportCategoryCountData.data) {
      setLoading(false);
    }
  }, [reportCategoryCountData.data]);

  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setWhere(`{
              "operator_id": { "_eq": "${userInfo.operator_id}" }
           }`)
    }
  }, [userInfo]);

  const error = reportCategoryCountData.error;
  if (error) {
    return <PageError error={{ source: "PieChart", errMsg: error.message }} />;
  }
  if (!reportCategoryCountData.data) {
    // We may need to change this into a resuable Component?
    return (
      <>
        <h4 className="card-title mb-3">
          <i className="bi bi-bag me-2"></i>
          Top Categories
        </h4>
        <p className="card-text placeholder-glow gap-3">
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
          <span className="placeholder col-12 mb-2"></span>
        </p>
      </>
    );
  }

  const setAlert = (message: string, messageType: "success" | "error") => {
    alertsRef.current?.add(message, messageType);
  };

  const catData = reportCategoryCountData.data;
  return (
    <>
      <span
        className="d-inline-block w-100"
        tabIndex={0}
        data-bs-toggle="popover"
        data-bs-trigger="hover focus"
        data-bs-content="Disabled popover"
        aria-describedby="popover23870"
      >
        <div className="d-flex justify-content-between">
          <h4 className="card-title me-2">
            <i className="bi bi-bag me-2"></i>
            Top {catData?.vw_report_operator_category_count.length} Categories
          </h4>

          {(catData?.category_aggregate.aggregate?.count ?? 0) > 0 &&
            <ExportData queryString={`QUERY_EXPORT_CATEGORY`}
              inputJson={`{"variables":{"where":{"operator_id":{"_eq":"${userInfo.operator_id}"}}}}`}
              caption={`Export categories`}
              setAlert={setAlert}
              btnType="link"
              redirectLink={notificationUrl}
            />
          }

        </div>
      </span>

      <div className="row">
        <div className="container">
          <ol className="ps-3 text-muted">
            {catData.vw_report_operator_category_count.map(
              (item: Vw_Report_Operator_Category_Count) => {
                return (
                  <li className="py-1" key={item.name}>
                    <div className="d-flex justify-content-between">
                      <div className="text-truncate" data-bs-toggle="tooltip" title={item.name || ""}>
                        {item.name}
                      </div>
                      <div className="text-md-end">({addCommaFunc(item.count, 0)})</div>
                    </div>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    </>
  );
};
