import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateSellerSftpSettingsMutation, useDecryptTextMutation, useGetSecretKeyByNameQuery, useSellerSftpSettingsSubscription, useUpdateSellerSftpSettingsMutation } from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";

interface ISFTPSettingsForm {
    username: string,
    password: string
}
interface IProps {
    sellerId: string;
}
const SFTPSettingsForm: FC<IProps> = (props) => {
    const { sellerId } = props
    const userInfo = useUserInfo()!;
    const [formValid, setFormValid] = useState<boolean>(true);
    const [sftpSettingsSecretKeyQueried] = useGetSecretKeyByNameQuery({
        variables: {
            secretKeyName: "SFTP_SETTING_ENCRYPTION_KEY"
        }
    })
    const [queried] = useSellerSftpSettingsSubscription({
        variables: {
            sellerId: sellerId
        }
    });

    const [{ fetching: fetchingCreateSellerSftpSettings }, createSellerSftpSettingsMutation] = useCreateSellerSftpSettingsMutation();
    const [{ fetching: fetchingUpdateSellerSftpSettings }, updateSellerSftpSettingsMutation] = useUpdateSellerSftpSettingsMutation();
    const [, decryptTextMutation] = useDecryptTextMutation()
    const [showGenerateDialog, setShowGenerateDialog] = useState<boolean>(false)

    const methods = useForm<ISFTPSettingsForm>({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: { username: "", password: "" }
    });

    const {
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        setValue
    } = methods;

    const usernameValue = watch("username")
    const passwordValue = watch("password")
    useEffect(() => {
        const sellerSFTPSettings = queried.data?.imports_seller_sftp_settings[0]
        if (sellerSFTPSettings?.username) {
            setValue("username", sellerSFTPSettings.username)
        } else {
            setFormValid(true)
        }
        if (sellerSFTPSettings?.password && sftpSettingsSecretKeyQueried.data?.configs_secret_keys_by_pk?.key) {
            decryptPassword(sellerSFTPSettings)
        }
    }, [queried.data])

    useEffect(() => {
        if (regexUsername.test(usernameValue) && usernameValue.length >= 3 && usernameValue.length <= 64) {
            setFormValid(true)
        } else if (formValid) {
            setFormValid(false)
        }
        const sameWithTheFirstValue = usernameValue == (sellerSFTPSettings?.username || "")
        appRef.current?.updateStatus(!sameWithTheFirstValue);
    }, [usernameValue])

    const decryptPassword = async (sellerSFTPSettings: any) => {
        const decryptedPassword = await decryptTextMutation({ encryptedText: sellerSFTPSettings?.password || "", secretKey: sftpSettingsSecretKeyQueried.data?.configs_secret_keys_by_pk?.key || "" })
        setValue("password", decryptedPassword.data?.decrypt?.plainText || "")
    }

    if (queried.error) {
        return <p>{queried.error.message}</p>;
    }
    const sellerSFTPSettings = queried.data?.imports_seller_sftp_settings[0]
    let sftpStatusTextColor = "";
    switch (sellerSFTPSettings?.status) {
        case "Error":
            sftpStatusTextColor = "text-danger";
            break;
        case "Pending":
            sftpStatusTextColor = "text-warning";
            break;
        case "Activated":
            sftpStatusTextColor = "text-success";
            break;
        default:
            break;
    }

    const regexUsername = /^[a-z0-9]+$/;
    const onSubmit = async () => {
        if (usernameValue && regexUsername.test(usernameValue) && usernameValue.length >= 3 && usernameValue.length <= 64) {
            setFormValid(true)
            setShowGenerateDialog(true)
        } else if (formValid) {
            setFormValid(false)
            return
        }
    }

    const continueGenerate = async (process: boolean) => {
        if (!process) {
            setShowGenerateDialog(false)
            return
        }
        const sftpUserName = usernameValue || sellerSFTPSettings?.username || sellerSFTPSettings?.blob_container_name || ""
        if (sellerSFTPSettings?.id) {
            const sftpSettingsId = sellerSFTPSettings.id as string
            const status = "Pending"
            const res = await updateSellerSftpSettingsMutation({ id: sftpSettingsId, userName: sftpUserName, status })
            alertsRef.current?.generate(mutationInfo("SFTP Settings", MutationAction.Update, res));
            setShowGenerateDialog(false)
            appRef.current?.updateStatus(false)
            return;
        }
        const res = await createSellerSftpSettingsMutation({ sellerId: userInfo.seller_id, username: sftpUserName, operatorId: userInfo.operator_id, userId: userInfo.user_id })
        alertsRef.current?.generate(mutationInfo("SFTP Settings", MutationAction.Create, res));
        appRef.current?.updateStatus(false)
        setShowGenerateDialog(false)
    }

    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(passwordValue || "")
        alertsRef.current?.generate({ message: "Password copied to clipboard!", type: "success" });
    }

    const sftpSettingsErrorMessage = sellerSFTPSettings?.error_message?.split('\.').shift()
    return (
        <div>
            <h5>SFTP Settings for Offer Import</h5>
            <FormProvider {...methods}>
                <Form data-testid=""
                    id="generate-api-key"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}>
                    <div>
                        <p className="text-muted">Use SFTP to transfer offer files directly to MarketPush's servers.</p>
                        <div className="my-3">
                            <div className="d-flex flex-wrap"><span style={{ width: "5rem" }} className="text-muted font-weight-semi-bold">Server: </span> <span>{sellerSFTPSettings?.account_name && `sftp://${sellerSFTPSettings?.account_name}.blob.core.windows.net`}</span></div>
                            <div className="d-flex flex-wrap"><span style={{ width: "5rem" }} className="text-muted font-weight-semi-bold">Port: </span> <span>{sellerSFTPSettings && "22"}</span></div>
                            <div className="d-flex flex-wrap"><span style={{ width: "5rem" }} className="text-muted font-weight-semi-bold">Status: </span> <span className={`${sftpStatusTextColor} font-weight-bold`}>{sellerSFTPSettings?.status}</span></div>
                            {
                                sftpSettingsErrorMessage &&
                                <span className="text-muted">
                                    Error message:
                                    <small className="text-danger">
                                        {" "}{sftpSettingsErrorMessage + "."}
                                    </small>
                                </span>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Label className="w-100 mb-1" data-testid="">Username</Label>
                                <div className="d-flex justify-content-between w-100 flex-wrap">
                                    <div className="sftp-input w-100">
                                        <FormInput name="username"
                                            css={`form-input ${!formValid && "is-invalid"}`}
                                            inputgrouplabel={sellerSFTPSettings?.account_name ? sellerSFTPSettings?.account_name + "." : "accountname."}
                                            data-testid="sftp-username" type="text" required />
                                    </div>
                                    {!formValid ?
                                        (
                                            <small className="has-error text-danger w-100 col-12">
                                                <i className="bi bi-exclamation-circle-fill me-1" />
                                                Username must be between 3 and 64 characters in length, use numbers and lower-case letters only, and must be unique only within the storage account.
                                            </small>
                                        ) : <></>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Label className="w-100 mb-1" data-testid="">Password
                                    <span className="ms-1 tooltip-custom top">
                                        <span className="tooltip-text">You'll need to generate a password to enable this authentication method.
                                        </span>
                                        <i className="bi bi-question-circle"></i>
                                    </span>
                                </Label>
                                <div className="sftp-input w-100">
                                    <FormInput name="password" readOnly disabled type="password" css="form-input w-100"
                                        copyClipboardFunc={passwordValue ? copyPasswordToClipboard : undefined} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            {
                                fetchingCreateSellerSftpSettings || fetchingUpdateSellerSftpSettings &&
                                <Button data-testid="" type="button" disabled className="btn btn-primary">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true">
                                    </span>
                                </Button>
                                ||
                                <Button data-testid="" value="submit" className="btn btn-outline-primary" disabled={sellerSFTPSettings?.status == "Pending"}>
                                    Generate
                                </Button>
                            }
                        </div>
                    </div>
                </Form>
            </FormProvider>
            <Dialog
                show={showGenerateDialog}
                title={`Generate Password`}
                continueText="Confirm"
                continue={continueGenerate}
                contineBtnCss="btn-success"
            >
                Are you sure you want to generate a new password?
            </Dialog>
        </div>
    )
}

export default SFTPSettingsForm