export function getCssVar(name:string) {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(name)
      .replace(/^\s+|\s+$/, "");
  }

export function setCssVar(name:string, value:string) {
    document.documentElement.style.setProperty(name, value);
  }

export function removeCssVar(name:string) {
  document.documentElement.style.removeProperty(name);
}
