import { FC } from "react";
import { CategoryPartFragment } from "../../../generated/urql-graphql";
import { ISetProdCtg } from "../types/types";
import { Category } from "./Category";
import { CategoryItem } from "./CategoryItem";

interface IProps {
  categories: CategoryPartFragment[];
  level: number;
  setProdCtg?: ISetProdCtg
}

export const HierarchyCategories: FC<IProps> = ({ categories, level, setProdCtg }: IProps): ReturnType<FC> => {
  const category = categories[level];
  const isOpen = categories.length > level + 1;
  return (
    <>
      {category && (
        <div className={`${level !== 0 ? "is-child" : ""}`}>
          <div className={`level-${level}`}>
            {isOpen ? (
              <CategoryItem category={category} isOpen={isOpen} setProdCtg={setProdCtg} />
            ) : (
              <Category level={level} category={category} setProdCtg={setProdCtg} />
            )}
            {categories.length > level && (
              <HierarchyCategories categories={categories} level={level + 1} setProdCtg={setProdCtg} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
