import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { v4 } from "uuid";
import {
  LocationPartFragment,
  useCreateAddressMutation,
  useCreatePrimaryAddressMutation,
  useUpdateAddressMutation,
  useUpdatePrimaryAddressMutation,
} from "../../../generated/urql-graphql";
import { Address } from "../../common/components/Address";
import { Dialog } from "../../common/components/Dialog";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";

interface IProps {
  action: string;
  location_item: LocationPartFragment | null;
  setClose: () => void;
}

export function LocationDetails({ action, location_item, setClose }: IProps): JSX.Element {
  const userInfo = useUserInfo();
  const storeId = userInfo.seller_id;
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [{ fetching: creating }, createMutation] = useCreateAddressMutation();
  const [{ fetching: creatingPrimary }, createPrimaryMutation] = useCreatePrimaryAddressMutation();
  const [{ fetching: updating }, updateMutation] = useUpdateAddressMutation();
  const [{ fetching: updatingPrimary }, updatePrimaryMutation] = useUpdatePrimaryAddressMutation();
  const [closeDlg, setCloseDlg] = useState<boolean>(false);
  const methods = useForm<LocationPartFragment>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    if (location_item) reset(location_item);
  }, [location_item]);

  useEffect(() => {
    if (closeDlg) {
      setClose();
      setCloseDlg(false)
    }
  }, [closeDlg]);

  useEffect(() => {
    if (location_item) {
      const changed = Object.keys(dirtyFields).length !== 0;
      setFormChanged(changed);
      appRef.current?.updateStatus(changed);
    }
  }, [location_item, Object.keys(dirtyFields).length]);

  const onSubmit = async (formData: FieldValues) => {
    const { is_primary } = formData;
    let address = _.omit(formData, ["cp_offers", "cp_orders", "shipping_rates"]);
    let res;
    if (!location_item) {
      //create location
      address = { ...address, seller_id: storeId };
      if (!is_primary) {
        res = await createMutation({ address }, { additionalTypenames: ["address"] });
      } else {
        const locId = v4();
        address = { ...address, id: locId };
        res = await createPrimaryMutation(
          { address, storeId, locationId: locId },
          { additionalTypenames: ["address"] }
        );
      }
    } else {
      //edit location
      const locationId = location_item.id;
      if (!is_primary) {
        res = await updateMutation({ locationId, address }, { additionalTypenames: ["address"] });
      } else {
        res = await updatePrimaryMutation(
          { address, storeId, locationId },
          { additionalTypenames: ["address"] }
        );
      }
    }

    const info = mutationInfo("location", MutationAction.Update, res);
    if (res.data && !res.error) {
      alertsRef.current?.generate(info);
      resetForm();
    } else {
      setErrorMsg(info.errMsg!);
    }
  };

  const continueAction = async (isContinue: boolean) => {
    if (!isContinue) {
      resetForm();
    }
  };

  const resetForm = () => {
    reset();
    setCloseDlg(true);
  };

  return (
    <Dialog
      show={!!action}
      title={`${action.replace("_", " ")}`}
      subFormId="shipping-location-form"
      continueText="Save"
      continue={continueAction}
      size="lg"
      staticModal={true}
      fetching={creating || creatingPrimary || updating || updatingPrimary}
      disableAction={!!location_item && !formChanged}
      footerText=
      {
        <FormInput
          name="is_primary"
          type="checkbox"
          label="Primary Location"
          css="d-flex align-items-center mb-3"
          isswitch="true"
          form_methods={methods}
          disabled={action.includes('edit') ? (location_item?.is_primary) : false}
        />
      }>
      <FormProvider {...methods}>
        <Form
          data-testid="shipping-location-form"
          id="shipping-location-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              <i className="bi bi-exclamation-triangle"></i>
              <span className="ms-2">{errorMsg}</span>
            </div>
          )}
          <FormInput
            name="name"
            label="Name"
            placeholder="Enter in the name of the location"
            reg_options={{ required: true }}
          />
          <Address />
        </Form>
      </FormProvider>
    </Dialog>
  );
}
