import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "../../../forms/components/Button";
import { IHash } from "../../types/types";

export interface IStep {
  label: string;
  formId?: string;
  content?: React.ReactElement;
  save?: (saveOnly: boolean) => Promise<boolean>;
  autoSave?: boolean;
  hidePrevBtn?: boolean;
}

interface IStepsProps {
  steps: IStep[];
  children: React.ReactElement[];
  prevBtn?: IStepButton;
  nextBtn?: IStepButton;
  showFinish?: boolean;
  goNext?: boolean;
}

interface IStepButton {
  label?: string;
  css?: string;
}

export interface IStepNav {
  navNext: () => void;
}

export type StepsRef = Readonly<IStepNav>;

export const MultipleSteps = forwardRef<StepsRef, IStepsProps>(
  ({ steps, children, prevBtn, nextBtn, showFinish, goNext }: IStepsProps, ref: Ref<StepsRef>):
    JSX.Element => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    children.forEach((chd, index) => (steps[index].content = chd));

    useImperativeHandle(
      ref,
      () => ({
        navNext: () => navStep(false)
      }),
      [currentStep]
    );

    const intervals : IHash = {};
    useEffect(() => {
      const clear = () => Object.values(intervals).forEach(intv=> clearInterval(intv));
      
      clear();
      const step = steps[currentStep];
      if (!step.autoSave || !step.save) return;

      intervals[currentStep] =  setInterval(async () => {
        step.save && await step.save(true);
      }, 60 * 1000);
  
      return () => clear();
    }, [currentStep]);

    if (children.length !== steps.length)
      return <p>Unmatched multiple step components</p>;

    const navStep = (isPrev: boolean = true) => {
      const step = isPrev ? -1 : 1;
      if (currentStep + step > steps.length || currentStep + step < 0)
        return;
      setCurrentStep(currentStep + step);
    }

    const next = async () => {
      const step = steps[currentStep];
      if (step.save) {
        const saved = await step.save(false);
        if (saved)
          navStep(false);
      }
      else if (!step.formId)
        navStep(false);
    }

    return (
      <>
        <div className="steps-wrapper">
          <div className="d-flex justify-content-center align-items-center my-4">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`arrow-step ${currentStep >= index ? "done" : ""}`}
              >
                <span>{step.label}</span>
              </div>
            ))}
          </div>
          <div>{steps[currentStep].content}</div>
          <div className="d-flex justify-content-between mt-4">
            <div>
              {steps[currentStep].hidePrevBtn ? null : (currentStep !== 0 &&
                <Button onClick={() => navStep()}
                  data-testid=""
                  className={`btn btn-primary ${prevBtn?.css}`}
                  disabled={false}>
                  {prevBtn?.label || "Prev"}
                </Button>)}
            </div>
            <div>
              {currentStep < steps.length - 1 ?
                (<Button onClick={next}
                    data-testid=""
                    className={`btn btn-primary ${nextBtn?.css || ""}`}
                    disabled={!goNext || false}
                    form={steps[currentStep].formId}
                    type={steps[currentStep].formId ? "submit" : "button"}
                  >
                    {nextBtn?.label || "Next"}
                </Button>)
                : (showFinish && showFinish === true ?
                  <Button data-testid=""
                    className={`btn btn-primary ${nextBtn?.css || ""}`}
                    disabled={false}
                    form={steps[currentStep].formId}
                    type={steps[currentStep].formId ? "submit" : "button"}
                  >
                    {"Finish"}
                  </Button> : <></>)}
            </div>
          </div>
        </div >
      </>
    );
  });