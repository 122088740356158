import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useGetMerchantBySellerIdQuery, useUpdateSellerPaymentMethodMutation } from "../../../generated/urql-graphql";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface IProps {
  sellerId: string;
  paymentMethod: string | null | undefined;
}

interface IFormData {
  credit_card: boolean;
  on_account: boolean;
}
export function PaymentMethods(props: IProps): JSX.Element {
  const { sellerId, paymentMethod } = props;
  const [context] = useMainContext();
  const { seller: sellerSetting } = context.operatorSettings;
  const [formChanged, setFormChanged] = useState<boolean>(false);
  //const [isOpen, setIsOpen] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const defData = {
    credit_card: paymentMethod?.includes('CreditCard') || false,
    on_account: paymentMethod?.includes('OnAccount') || false,
  };
  const [formData, setFormData] = useState<IFormData>(defData);
  const [merchantQueried] = useGetMerchantBySellerIdQuery({
    variables: {
      sellerId,
    },
  })
  const [{ fetching }, updateMutation] = useUpdateSellerPaymentMethodMutation();

  const editSeller = userInfo.user_level! >= 10;

  const disableForm = !editSeller;

  const methods = useForm<IFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: formData,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);

    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  function checkValidForm(): boolean {
    let inputCreditCard = document.querySelector<HTMLInputElement>('input[name="credit_card"]');
    let inputOnAcct = document.querySelector<HTMLInputElement>('input[name="on_account"]');
    if (inputCreditCard && inputOnAcct) {
      return inputCreditCard.checked || inputOnAcct.checked;
    }
    return false;
  }

  function checkCreditCard(): boolean {
    if (sellerSetting.enable_merchant_account_integration
      && (!merchantQueried.data?.configs_seller_merchant_account
        || merchantQueried.data?.configs_seller_merchant_account.length === 0)) {
      return false;
    }
    return true;
  }
  const doUpdate = async (data: FieldValues) => {
    let paymentMethods: Array<string> = [];
    if (data.credit_card) {
      paymentMethods.push('CreditCard');
    }
    if (data.on_account) {
      paymentMethods.push('OnAccount');
    }

    const res = await updateMutation({
      seller_id: sellerId,
      payment_method: paymentMethods.join('|')
    });

    if (!res?.error) {
      reset(data as IFormData);
    } else {
      //setIsOpen(false);
    }
    alertsRef.current?.generate(mutationInfo("payment-method", MutationAction.Update, res));
  }
  const onSubmit = async (data: FieldValues) => {
    setFormData(data as IFormData);
    doUpdate(data);
  };

  return (
    <div>
      <h5>Payment Methods</h5>
      <FormProvider {...methods}>
        <Form
          data-testid="seller-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div>
            <p className="text-muted">Specify the payment methods available to your customers during checkout</p>
            <div className="row col-12 ">
              <div className="col-6">
                <FormInput
                  name="credit_card"
                  label="Credit Card"
                  type="checkbox"
                  disabled={disableForm || !checkCreditCard()}
                  css={disableForm || !checkCreditCard() ? "text-muted" : ""}
                />
              </div>
              <div className="col-6">
                <FormInput
                  name="on_account"
                  label="On Account"
                  type="checkbox"
                  disabled={disableForm}
                />
              </div>
            </div>
          </div>
          {editSeller && (
            <div className="d-flex justify-content-end">
              <Button
                data-testid=""
                disabled={
                  !formChanged
                  || disableForm
                  || !checkValidForm()
                }
                type="submit"
                className="btn btn-primary "
                id="seller-save-btn"
              >
                Save
              </Button>
            </div>
          )}
        </Form>
      </FormProvider>
    </div>
  );
}
