import { FC, useEffect } from "react";
import { ELoadingType } from "../../common/types/types";
type Props = {
  totalOfElements: number;
  paginate: (value: number) => void;
  currentPage: number;
  setOffset: (value: number) => void;
  elementsPerPage: number;
  loading?: number;
  setLoading?: (val: number) => void;
};

const MAX_ROW_COUNT_SHOWING_LAST_PAGE = 10000;

export const PaginationOffset: FC<Props> = (props): ReturnType<FC> => {
  const { totalOfElements,
    paginate,
    currentPage,
    setOffset,
    elementsPerPage,
    loading,
    setLoading
  } = props;

  const pageNumbers: number[] = [];
  const pageBreak = 5;
  let totalPages = Math.ceil(totalOfElements / elementsPerPage);
  for (
    let index = Math.max(1, currentPage - pageBreak);
    index <= Math.min(totalPages, currentPage + pageBreak);
    index += 1
  ) {
    pageNumbers.push(index);
  }
  useEffect(() => {
    if (currentPage > totalPages) {
      paginate(1);
    }
  }, []);

  return (
    <>
      <div className="d-flex w-100 justify-content-end">
        <ul className="pagination">
          <li className="page-item" key="First">
            <span
              className="page-link"
              onClick={() => {
                if (currentPage !== 1) {
                  paginate(1);
                  setOffset(0);
                  setLoading && setLoading(ELoadingType.Pagination);
                }
              }}
            >
              {loading && (loading === ELoadingType.Pagination) ?
                <div className="spinner-border spinner-border-sm" role="status">
                </div>
                :
                <i className="bi bi-chevron-bar-left"></i>
              }
            </span>
          </li>
          <li className="page-item" key="Previous">
            <span
              className="page-link"
              onClick={() => {
                if (currentPage > 1) {
                  paginate(currentPage - 1);
                  setOffset((currentPage - 2) * elementsPerPage);
                  setLoading && setLoading(ELoadingType.Pagination + 1);
                }
              }}
            >
              {loading && (loading === (ELoadingType.Pagination + 1)) ?
                <div className="spinner-border spinner-border-sm" role="status">
                </div>
                :
                <i className="bi bi-chevron-left"></i>
              }
            </span>

          </li>
          {pageNumbers.map((pageNumber, index) => (
            <li
              className={`page-item ${pageNumber === currentPage && "active"}`}
              key={"page-item" + index}
            >
              <span
                className="page-link"
                onClick={() => {
                  paginate(pageNumber);
                  setOffset((pageNumber - 1) * elementsPerPage);
                  setLoading && setLoading(ELoadingType.Pagination + 4 + index);
                }}
              >
                {loading && (loading === (ELoadingType.Pagination + 4 + index)) ?
                  <div className="spinner-border spinner-border-sm" role="status">
                  </div>
                  :
                  <>{pageNumber}</>
                }
              </span>

            </li>
          ))}
          <li className="page-item" key="Next-chevron">

            <span
              className="page-link"
              onClick={() => {
                if (currentPage < totalPages) {
                  paginate(currentPage + 1);
                  setOffset(currentPage * elementsPerPage);
                  setLoading && setLoading(ELoadingType.Pagination + 2);
                }
              }}
            >
              {loading && (loading === (ELoadingType.Pagination + 2)) ?
                <div className="spinner-border spinner-border-sm" role="status">
                </div>
                :
                <i className="bi bi-chevron-right"></i>
              }
            </span>

          </li>
          {(totalOfElements < MAX_ROW_COUNT_SHOWING_LAST_PAGE) &&
            <li className="page-item" key="Next-chevron-bar-right">
              <span
                className="page-link"
                onClick={() => {
                  if (currentPage !== totalPages) {
                    paginate(totalPages);
                    setOffset((totalPages - 1) * elementsPerPage);
                    setLoading && setLoading(ELoadingType.Pagination + 3);
                  }
                }}
              >
                {loading && (loading === (ELoadingType.Pagination + 3)) ?
                  <div className="spinner-border spinner-border-sm" role="status">
                  </div>
                  :
                  <i className="bi bi-chevron-bar-right"></i>
                }
              </span>
            </li>
          }
        </ul>
        {/*

      */}
      </div>
    </>
  );
};
