import React, { FC } from "react";

type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "small" | "span" | "strong" | "label";

type Size =
  | "display-1"
  | "display-2"
  | "display-3"
  | "display-4"
  | "display-5"
  | "display-6"
  | "display-7"
  | "";

type Weight = "light" | "normal" | "medium" | "semi-bold" | "bold";

type Align = "start" | "center" | "end";

type ScreenSize = "sm" | "md" | "lg" | "xl" | "";

type Color =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "body"
  | "muted";

type Transform = "lowercase" | "uppercase" | "capitalize" | "";

type IProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  /**
   * Text contents
   */
  children: React.ReactNode;
  /**
   * Text variant to use
   */
  variant?: Variant;
  /**
   * Text size to use
   */
  size?: Size;
  /**
   * Text weight to use
   */
  weight?: Weight;
  /**
   * Text align to use
   */
  align?: Align;
  /**
   * Screen size to use for alignment
   */
  screenSize?: ScreenSize;
  /**
   * Text color to use
   */
  color?: Color;
  /**
   * Boolean that determines if the text will the truncated (use ellipsis)
   */
  truncate?: boolean;
  /**
   * Boolean that determines if the label will mark the input as required
   */
  required?: boolean;
  /**
   * Text transform to use
   */
  transform?: Transform;
  /**
   * className used to override input styles
   */
  className?: string;
};

/**
 * Text component
 */
export const Text: FC<IProps> = (props): ReturnType<FC> => {
  const {
    children,
    variant,
    size,
    weight,
    align,
    screenSize,
    color,
    truncate,
    required,
    transform,
    className,
  } = props;
  const Wrapper = variant as Variant;

  const alignmentString = `text-${screenSize && `${screenSize}-`}${align}`;
  const classString = `${size && size} font-weight-${weight} ${alignmentString} text-${color} ${
    truncate && "text-truncate"
  } ${required && "required"} ${transform && `text-${transform}`} ${className && className}`;

  return <Wrapper className={classString}>{children}</Wrapper>;
};

Text.defaultProps = {
  variant: "p",
  size: "",
  weight: "normal",
  align: "start",
  screenSize: "",
  color: "body",
  truncate: false,
  required: false,
  transform: "",
};
