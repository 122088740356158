import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { OrderItemPartFragment, useUpdateOrderItemMutation } from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";
import { OrderItemBriefDescription, footerText } from "./OrderItems";

interface IProps {
  setClose: () => void;
  locationOptions: SelectOption[];
  itemData: OrderItemPartFragment;
}

export const EditLineDialog: FC<IProps> = ({ setClose, locationOptions, itemData }) => {
  const updateItemMutation = useUpdateOrderItemMutation()[1];
  const [errMsg, setErrMsg] = useState<string>("");
  const [context] = useMainContext();
  const { max_decimals_in_unit_price } = context.operatorSettings.offer;

  const quantityUpdateEnabled = context.operatorSettings.order.order_item_quantity_can_be_updated_for_pending_orders && itemData.trg_status === "Pending"
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (itemData) reset(itemData);
  }, [itemData]);

  const onSubmit = async (formData: FieldValues) => {
    const incQty = itemData.quantity - formData.quantity;
    const res = await updateItemMutation({
      id: itemData?.id,
      quantity: formData.quantity,
      shippingLocId: formData.shipping_location_id,
      incQty,
      checkQty: -incQty
    });
    if (res.data?.update_order_item?.affected_rows === 0) {
      setErrMsg("Insufficient Stock");
    }
    else {
      if (res.data) {
        setClose();
        reset();
        setErrMsg("");
      }
      alertsRef.current?.generate(mutationInfo("order line item", MutationAction.Update, res));
    }
  };

  const continueConfirm = async (isContinue: boolean) => {
    if (!isContinue) {
      setClose();
    }
  };

  return (
    <>
      <Dialog
        subFormId="order-action-form-edit_item"
        title="Edit Item"
        continueText="Confirm"
        staticModal={true}
        continue={continueConfirm}
        size="lg"
        footerText={footerText}
        errorMsg={errMsg}
      >
        <FormProvider {...methods}>
          <Form
            data-testid=""
            noValidate
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
            id="order-action-form-edit_item"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormSelect
              name="shipping_location_id"
              label="Ship From Location"
              options={locationOptions}
            />
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <OrderItemBriefDescription orderItem={itemData!} />
                    </td>
                    <td>
                      <FormInput
                        name="quantity"
                        label=""
                        type="number"
                        disabled={!quantityUpdateEnabled}
                        style={{ width: "120px" }}
                        onKeyDown={(evt) => {
                          evt.key === "." && evt.preventDefault();
                          evt.key === "e" && evt.preventDefault();
                          evt.key === "-" && evt.preventDefault();
                        }}
                        className="form-control my-2"
                        reg_options={{
                          required: true,
                          min: 1,
                        }}
                      />
                    </td>
                    <td className="align-middle">
                      <p className="my-2">$ {addCommaFunc(itemData?.unit_price, max_decimals_in_unit_price)}</p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};
