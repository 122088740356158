import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ProductImportPartFragment, useProductImportsCountSubscription, useSubProductImportsSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Spinner } from "../../common/components/Spinner";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { getImportStatus } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IHash, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import dayjs from "dayjs";

export const productImportsCols = (sellerId: string | null | undefined) => {
  let tableColumns = ["Created Date", "Process By", "Type", "File Name", "Company", "Status", "Error Report"];
  tableColumns = sellerId ? tableColumns.filter(col => col !== "Company") : tableColumns;
  return tableColumns;
}

export const productImportsData = (data: ProductImportPartFragment[], formatDate: any, sellerRoute: string) => {
  const timeDiff = (imp: ProductImportPartFragment, completeTime: boolean = false) => {
    const d1 = dayjs(imp.status_obj.start);
    const d2 = !completeTime ? dayjs() : (imp.status_obj.completing && dayjs(imp.status_obj.completing));
    const tm = d2?.diff(d1, 'minute');
    const ts = d2 && (d2.diff(d1, 'minute', true) - tm)*60;
    return {tm, ts: Math.ceil(ts)};
  }
  
  return data.map(imp => {
    const status = getImportStatus(imp?.status || "");
    const {tm, ts} = timeDiff(imp, true);
    return {
      id: imp.id,
      Process_By: imp.email,
      Type: imp.action,
      File_Name: (
        <div>
          <p className="mb-0">
            <a href={imp.filepath_storage!}>{imp.filename}</a>
          </p>
        </div>
      ),
      Company:
        imp.seller_id ? <NavLink
          data-testid=""
          to={`${sellerRoute}/${imp.seller_id}`}
          className="text-decoration-underline"
        >
          {imp.company}
        </NavLink> : <div>{imp.company}</div>
      ,
      Created_Date: (
        <div>
          <p className="mb-0">{formatDate(imp.created_date)}</p>
          <span className="small text-muted">
            {formatDate(imp.created_date, "time")}
          </span>
        </div>
      ),
      Status: (
        <div className="text-truncate">
          <div className={`text-bold my-1 ${status.color}`}>
            <i className={`small me-1 bi ${status.icon}`}></i>
            {status.text}
          </div>
          {imp.status === "Processing" && (
            <div className="d-flex align-items-center">
              {(timeDiff(imp)?.tm || 0) > 15 ?
                (<div className="small text-danger">
                  Processing data timeout!
                </div>) :
                (<>
                  <Spinner size={20} />
                  <span className="small text-muted ms-2">
                    {imp.status_obj.completing
                      ? "Finishing product import..."
                      : imp.status_obj.upserting
                        ? "Updating products..."
                        : imp.status_obj.filtering
                        ? "Filtering product data..."
                        : imp.status_obj.copying
                          ? "Copying data to database..."
                          : imp.status_obj.start
                            ? "Start product data import..."
                            : ""}
                  </span>
                </>)}
            </div>
          )}
          {imp.status?.indexOf("Complete") !== -1 && (
            <>
              {ts && (
                <div className="small text-muted">
                  {" "}
                  Processing time:{" "}
                  {`00:${tm ? ("0" + tm).slice(-2) : "00"}:${("0" + ts).slice(-2)}`}
                </div>
              )}
              <div>
                <span className="small text-muted">
                  Lines total: {imp.status_obj.total || 0}
                </span>
                <br />
                <span className="small text-muted">
                  Lines processed: {imp.status_obj.processed || 0}
                </span>
                <br />
                <span className="small text-muted">
                  {imp.action === "update" ? "Non-exist products: " : "Lines added: "} {imp.status_obj.new_lines  || 0}                </span>
                <br />
                <span className="small text-muted">
                  Lines updated: {imp.status_obj.updated || 0}
                </span>
                <br />
                <span className="small text-muted">
                  Products created by others: {(imp.status_obj.no_auth || 0)}
                </span>
                <br />
                <span className="small text-muted">
                  Lines with errors: {imp.status_obj.error_lines || 0}
                </span>
                <br />
              </div>
            </>
          )}
          {imp.status === "Error" && (
            <div className="small text-muted">
              {imp.status_obj.error || imp.status_obj.Error}
            </div>
          )}
        </div>
      ),
      Error_Report: (
        <div className="text-left my-2">
          <p className="mb-0">
            {imp.status !== "Processing" && imp.errorpath_storage && (
              <a href={imp.errorpath_storage}>Download</a>
            )}
          </p>
        </div>
      ),
    };
  });
}

export const ImportHistory: FC = () => {
  const [context] = useMainContext();
  const formatDate = useDateFormat();
  const { number_items_per_page } = context.operatorSettings.preset;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const userInfo = useUserInfo();
  const sellerId = userInfo.seller_id;
  const [loading, setLoading] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "created date",
    column_name: "created_date",
    orderBy: "desc",
  });

  const orderByOptions: IHash = {
    Created_Date: "created_date",
    Seller: "company_lower",
    Status: "status_lower"
  };

  const filterOptions = [
    { label: "Status", value: "status_lower", type: "string" }
  ];
  if (!sellerId)
    filterOptions.push({ label: "Company", value: "company_lower", type: "string" });

  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }]
    : [{ [orderByOptions.Created_Date]: "desc" }];

  const tableColumnToSearch = "email";
  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, `%${searchValue.toLowerCase()}%`),
    dateRange,
    selectedFilters,
  });
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };
  const countInputs = {
    variables: {
      where: JSON.parse(handleWhereStr),
    },
  };
  const [subscribed] = useSubProductImportsSubscription(inputs);
  const [countSubscribed] = useProductImportsCountSubscription(countInputs);

  useEffect(() => {
    if (subscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [subscribed.data]);

  const tableColumns = productImportsCols(sellerId);

  const error = subscribed?.error || countSubscribed?.error;
  if (error) {
    return <PageError error={{ source: "Importhistory", errMsg: error.message }} />;
  }

  if (!subscribed.data || !countSubscribed.data)
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </Card>
      </>);

  const prodImports = subscribed.data?.imports_vw_product_import;
  const tableData = productImportsData(prodImports, formatDate, `/${tenantUrlTag}/sellers`);
  return (
    <Card>
      <Table
        columnNames={tableColumns}
        data={tableData}
        offset={offset}
        setOffset={setOffset}
        totalRecords={countSubscribed.data?.items?.aggregate?.count || 0}
        filters
        setSelectedFilters={setSelectedFilters}
        addFilterOptions={filterOptions}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setSearchValue={setSearchValue}
        searchPlaceHolder="Search Imported By"
        loading={loading}
        setLoading={setLoading}
      />
    </Card>
  );
}


