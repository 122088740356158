import { FC } from "react";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
import { SellerDetails } from "./SellerDetails";

export const AddSeller: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = userInfo.seller_id ? `/${tenantUrlTag}` : `/${tenantUrlTag}/sellers`;
  return (
    <div className="d-flex justify-content-center">
      <div className="col-sm-12 col-xl-8">
        <ReturnHeader title="Add Seller" url={baseRoute} />
        <SellerDetails />
      </div>
    </div>
  );
};
