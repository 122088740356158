import { FC } from "react";
interface ReportDataType {
  key: string;
  name: string;
  Orders: number;
  Refunds: number;
  Earnings: number;
}

interface PropsType {
  reportData: ReportDataType[];
}

export const RevenueBand: FC<PropsType> = ({ reportData }): ReturnType<FC> => {
  let totalOrderCount: number = 0;
  let totalRefundCount: number = 0;
  let totalEarning: number = 0;
  reportData.forEach((item) => {
    totalOrderCount = totalOrderCount + item.Orders;
    totalRefundCount = totalRefundCount + item.Refunds;
    totalEarning = totalEarning + item.Earnings;
  });

  // using Intl.NumberFormat() constructor to enable lang number formatting.
  let earnings = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "short",
  }).format(totalEarning);

  return (
    <>
      <div className="col-sm-4">
        <div className="p-2 d-flex flex-column align-items-center justify-content-center">
          <h5 className="mb-1">{totalOrderCount}</h5>
          <p className="text-muted mb-0">Orders</p>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="p-2 d-flex flex-column align-items-center justify-content-center">
          <h5 className="mb-1">{earnings}</h5>
          <p className="text-muted mb-0">Earnings</p>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="p-2 d-flex flex-column align-items-center justify-content-center">
          <h5 className="mb-1">{totalRefundCount}</h5>
          <p className="text-muted mb-0">{totalRefundCount > 1 ? `Refunds` : `Refund`}</p>
        </div>
      </div>
    </>
  );
};
