/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Invite_Insert_Input,
  useCreateInviteMutation,
  useSellerNamesQuery,
} from "../../../generated/urql-graphql";
import { Chips, ChipsRef } from "../../common/components/Chips";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { useGroupList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormSelect } from "../../forms/components/FormSelect";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import RoleSelector from "./RoleSelector";
import { useMainContext } from "../../layout/components/MainProvider";

interface PropDT {
  ToCCount: number
};
export const InviteUser: FC<PropDT> = ({ ToCCount }) => {
  const [context] = useMainContext();
  const {num_of_days_invite_expire : daysExpire} = context.operatorSettings.preset;
  const [showInviteForm, setShowInviteForm] = useState<boolean>(false);
  const [sellerErrorMsg, setSellerErrorMsg] = useState<string>("");
  const userInfo = useUserInfo()!;
  const groupList = useGroupList();
  const [, createInviteMutation] = useCreateInviteMutation();
  const emailsRef = useRef<ChipsRef>(null);

  const [inviteRoleId, setInviteRoleId] = useState<string>(emptyUuid);

  const [sellerOptions, setSellerOptions] = useState<SelectOption[]>([]);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0 };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const selectRef = useRef<SelectRef | null>(null);
  const [sellerQueried] = useSellerNamesQuery({
    variables: _.omit(searchParam, "total"),
  });
  const userType = userInfo.user_level! < 30 ? "User" : "Admin";

  useEffect(() => {
    if (sellerQueried.data) {
      const sellers =
        sellerQueried.data.seller.map((seller) => {
          return { label: seller.label, value: seller.value };
        }) || [];
      setSellerOptions(sellers);
      setSearchParam({ ...searchParam, total: sellerQueried.data.sellers.aggregate?.count || 0 });
    }
  }, [sellerQueried]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { handleSubmit, register, reset, watch } = methods;

  const groupLevel = watch("groupLevel") || Math.floor(userInfo?.user_level! / 10) * 10;
  const onSubmit = () => {
    const emails = emailsRef?.current?.get();
    if (!emails || emails?.length === 0) {
      alertsRef.current?.add("There is no valid email.", "error");
      return;
    }

    const sellerId = userInfo.seller_id || selectRef.current?.value;
    // validate seller
    if (groupLevel < 20) {
      if (!!!sellerId) {
        setSellerErrorMsg("Valid seller is required");
        return;
      } else {
        setSellerErrorMsg("");
      }
    }

    const inviteInput = {
      role_id: inviteRoleId,
      inviter_id: userInfo.user_id,
      expire_date: new Date(Date.now() + daysExpire * 24 * 60 * 60 * 1000),
      operator_id: userInfo.operator_id,
    } as Invite_Insert_Input;

    if (sellerId) {
      inviteInput.seller_id = sellerId;
    }

    const inviteInputs: Invite_Insert_Input[] = [];

    if (emails) {
      emails.forEach((email) => {
        inviteInputs.push({ ...inviteInput, email: email.toLowerCase() });
      });
    }

    createInviteMutation({ invites: inviteInputs })
      .then((res) => {
        if (res.data && !res.error) {
          resetForm();
        }
        alertsRef.current?.generate(mutationInfo("user invitation", MutationAction.Send, res));
        return;
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("user invitation", MutationAction.Send, error));
        return;
      });
  };

  const invite = (continueInvite: boolean) => {
    if (!continueInvite) {
      resetForm();
    }
  };

  const resetForm = () => {
    reset();
    setInviteRoleId(emptyUuid);
    setShowInviteForm(false);
    setSellerErrorMsg("");
    selectRef.current?.clear();
  };

  /*const groupOptions = groupList.map(group=>({
    label: group.name,
    value: group.base_level,
  }));*/
  
  let groupOptions: SelectOption[] = [];
  groupList.forEach((group) => {
    if (ToCCount === 0 && !userInfo.seller_id) {
      if (group.base_level >= 20) {
        groupOptions.push({
          label: group.name,
          value: group.base_level,
        });
      }
    }
    else {
      groupOptions.push({
        label: group.name,
        value: group.base_level,
      });
    }

  });

  return (
    <>
      <div className="">
        <Button
          data-testid="btnInviteView"
          ref={null}
          onClick={() => setShowInviteForm(true)}
          type="button"
          className="btn btn-primary"
        >
          Invite {userType}
        </Button>
      </div>
      <Dialog
        show={showInviteForm}
        title={`Invite ${userType}`}
        continueText="Invite"
        subFormId="invite-form"
        continue={invite}
        staticModal={true}
      >
        <FormProvider {...methods}>
          <Form data-testid="invite-form" id="invite-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <Label data-testid="" className="required font-weight-semi-bold mb-2">
                Email
              </Label>
              <Chips
                {...register("emails", {
                  validate: {
                    checkInput: () => {
                      return emailsRef.current?.validate();
                    },
                  },
                })}
                ref={emailsRef}
                contentFormat="\w+([\.-_+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+"
              />
            </div>
            {
              // only render the group dropdown menu for operator
              userInfo.user_level! >= 20 && userInfo.user_level! < 30 && (
                <div className="mb-3">
                  <FormSelect
                    name="groupLevel"
                    label="Group"
                    options={groupOptions}
                    reg_options={{ required: true }}
                  />
                </div>
              )
            }
            <div className="mb-3">
              <Label data-testid="" className="required font-weight-semi-bold mb-1">
                Select Role Assignment
              </Label>
              <div>
                <RoleSelector
                  curRoleId={userInfo.role_id}
                  groupBase={groupLevel}
                  onChange={(roleId: string) => setInviteRoleId(roleId)}
                  removeMpAdmin={true}
                />
              </div>
            </div>
            {!userInfo.seller_id && groupLevel < 20 && (
              <div className="mb-3">
                <CustomSelect
                  ref={selectRef}
                  required={true}
                  label="Select Seller"
                  options={sellerOptions}
                  placeholder="Please select or search for a seller"
                  search={{ searchParam, setSearchParam, fetching: sellerQueried.fetching }}
                  errorMsg={sellerErrorMsg}
                />
              </div>
            )}
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};
