import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Error403 } from "../../common/components/Errors/Error403";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { AdjustmentForm } from "./AdjustmentForm";
import { StatementList } from "./StatementList";
import { TransactionList } from "./TransactionList";
export const FinanceRoute: FC = () => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const enabledFinancesSection = context.operatorSettings.preset.enable_finances_section
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/finance`;
  const manageFinances = BigInt(userInfo.permissions!) & BigInt(context.permissions.manage_finance);
  const viewFinances = userInfo.permissions! & context.permissions.access_statement;
  const location = useLocation();

  const tabs = [
    {
      tabName: "Billing Statements",
      route: `${baseRoute}`,
      component: <StatementList sellerId={userInfo.seller_id} />,
    },
    {
      tabName: "Transaction History",
      route: `${baseRoute}/transactions`,
      component: <TransactionList sellerId={userInfo.seller_id} />,
    },
  ];

  const [showPopup, setShowPopup] = useState<boolean>(false);
  if (!viewFinances || !enabledFinancesSection) return (<Error403></Error403>);
  else
    return (
      <>
        <div>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="page-title">Billing Statements</h1>
            {(manageFinances && location.pathname.indexOf("/transactions") > -1) ? (
              <div className="d-flex flex-row align-items-center">
                <Button
                  className="btn btn-primary"
                  data-testid="btn-add-billing-period"
                  onClick={() => setShowPopup(true)}
                >
                  Add Adjustment
                </Button>
              </div>
            ) : null}
          </div>
          <LinkTabsComponent tabs={tabs} />
        </div>
        {showPopup && <AdjustmentForm close={() => setShowPopup(false)} />}
      </>
    );
};

