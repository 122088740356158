import { FC } from "react";
import { Card } from "../../common/components/Card";
import { Button } from "../../forms/components/Button";
type Props = {
  images: string[];
};

export const ProductImageCarousel: FC<Props> = (props): ReturnType<FC> => {
  const { images } = props;

  return (
    <>
      {images.length > 0 ? (
        <div className="product-carousel-wrapper d-none d-md-block me-4">
          <Card className="p-1 shadow-none">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {images.map((url: string, index: number) => {
                  const filename = url.substring(url.lastIndexOf("/") + 1);
                  return (
                    <div
                      key={`div-${index}`}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <img
                        key={`img-${index}`}
                        className="d-block mx-auto"
                        src={url}
                        alt={filename}
                      />
                    </div>
                  );
                })}
              </div>
              {images.length > 1 ? (
                <div className="carousel-indicators carousel-indicator-detail d-flex flex-row">
                  {images.map((url: string, index: number) => {
                    const filename = url.substring(url.lastIndexOf("/") + 1);
                    return (
                      <Button
                        key={index}
                        data-testid=""
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={`${index === 0 ? "active" : ""}`}
                        aria-current="true"
                        aria-label={`Slide ${index}`}
                        style={{ width: "50", height: "50" }}
                      >
                        <img alt={filename} className="d-block w-100" src={url} />
                      </Button>
                    );
                  })}
                </div>
              ) : <></>}
            </div>
          </Card>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
