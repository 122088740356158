import { FC, useEffect, useRef, useState } from "react";
import {
  useDeleteSellerCategorySettingsMutation,
  useOfferSettingsBySellerIdSubscription,
  useUpdateSellerCategorySettingsMutation,
  useUpdateSellerOfferSettingsMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { useFieldValidationNew } from "../../common/hooks/useFieldValidationNew";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { ICatUILine, OfferSettingsCategories } from "./OfferSettingsCategories";


interface IAutoReduceQty {
  enabled?: boolean,
  reduce_quantity_by_percentage?: number,
  apply_to_all_categories?: boolean
};
interface IEnableScheduling { // to finish this setting
  enabled: boolean,
  schedule_frequency: string
};

interface IProps {
  sellerId: string
}

interface ICatDB {
  seller_id: string,
  category_id: string,
  reduce_quantity_by_percentage: number
}


export const AdvancedSettings: FC<IProps> = (props: IProps): ReturnType<FC> => {

  const seller_id = props.sellerId;
  const defPercent = 50;
  const inputs = { variables: { sellerId: seller_id } };
  const [sellersOfferSettingQueried] = useOfferSettingsBySellerIdSubscription(inputs);

  const auto_reduce_stock_on_import: IAutoReduceQty = sellersOfferSettingQueried.data?.seller_setting?.filter(x => x.name === "auto_reduce_stock_on_import")[0]?.value;

  const [{ fetching }, updateSellerOfferSettingsMutation] = useUpdateSellerOfferSettingsMutation();
  const [{ }, updateSellerCategorySettingsMutation] = useUpdateSellerCategorySettingsMutation();
  const [{ }, deleteSellerCategorySettingsMutation] = useDeleteSellerCategorySettingsMutation();

  const [reduceQty, setReduceQty] = useState<boolean>(false);
  const [applyAllCategories, setApplyAllCategories] = useState<boolean>(false);
  const [defaultPercent, setDefaultPercent] = useState<number>(defPercent);

  const autoReducePercentageRef = useFieldValidationNew<HTMLInputElement>();
  const autoReduceRef = useRef<HTMLInputElement>(null);
  const applyAllRef = useFieldValidationNew<HTMLInputElement>();

  const updatedCats = useRef<ICatUILine[]>([]);

  useEffect(() => {
    setReduceQty(auto_reduce_stock_on_import?.enabled ?? false);
    setApplyAllCategories(auto_reduce_stock_on_import?.apply_to_all_categories ?? false);
    setDefaultPercent(auto_reduce_stock_on_import?.reduce_quantity_by_percentage ?? defPercent);
    autoReduceRef.current!.checked = auto_reduce_stock_on_import?.enabled ?? false;

    if (applyAllRef.current) applyAllRef.current!.checked = auto_reduce_stock_on_import?.apply_to_all_categories ?? false;
  }, [sellersOfferSettingQueried.data]);


  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);



  const updateSettings = async () => {
    if (autoReducePercentageRef.current?.validationMessage)
      return;

    if (updatedCats.current && updatedCats.current.filter(x => x.error).length > 0)
      return;
    let autoReduceQty = {};
    if (reduceQty) {
      autoReduceQty = {
        enabled: true,
        reduce_quantity_by_percentage: Number(autoReducePercentageRef.current?.value),
        apply_to_all_categories: applyAllCategories
      }
    }
    else autoReduceQty = {
      enabled: false
    }

    const data = {
      auto_reduce_stock_on_import: autoReduceQty
    }; // more settings coming

    const entries = Object.entries(data).map((pair) => {
      return {
        type: "offer",
        name: pair[0],
        value: pair[1],
        seller_id: seller_id
      };
    });

    const res = await updateSellerOfferSettingsMutation({ entries });
    const errors: string[] = [];

    if (reduceQty && !applyAllCategories) {
      if (updatedCats.current) {
        const catsToUpsert = updatedCats.current.filter(x => x.isSelected);
        const catsToDelete = updatedCats.current.filter(x => !x.isSelected);
        if (catsToUpsert.length > 0) {
          const catsRes = await updateCategories(catsToUpsert);
          mutationInfo("offer category setting", MutationAction.Update, catsRes);
        }
        if (catsToDelete.length > 0) {
          const catsRes = await deleteCategories(catsToDelete);
          mutationInfo("offer category setting", MutationAction.Remove, catsRes);
        }
      }
    }
    if (!res.error && errors.length === 0) {
      setBtnDisabled(true);
    }
    alertsRef.current?.generate(mutationInfo("offer setting", MutationAction.Update, res));
  };

  const updateCategories = async (items: ICatUILine[]) => {
    const entries: ICatDB[] = items.map(i => ({ seller_id: seller_id, category_id: i.id, reduce_quantity_by_percentage: i.percentage }));

    const res = await updateSellerCategorySettingsMutation({ entries });
    return res;
  }

  const deleteCategories = async (items: ICatUILine[]) => {
    const ids: string[] = items.map(i => i.id);
    const sellerId = seller_id;

    const res = await deleteSellerCategorySettingsMutation({ ids, sellerId });
    return res;
  }


  const updateApplyAllCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyAllCategories(event.target.checked)
    setBtnDisabled(false);
  }

  const updateReduceQty = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReduceQty(event.target.checked)
    setBtnDisabled(false);
  }

  const updateDefaultPercent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultPercent(Number(event.target.value));
    setBtnDisabled(false);
  }


  const updateCats = (items: ICatUILine[]) => {
    updatedCats.current = items;
    setBtnDisabled(false);
  }


  return (
    <div>
      <Card>
        <h4 className="font-weight-semi-bold mb-2">Specify how MarketPush updates your product inventory</h4>
        <table className="table align-middle">
          <tbody>
            <tr>
              <td className="d-flex flex-row justify-content-between align-items-center form-switch py-3">
                <span>
                  Automatically reduce stock quantity when importing offers
                  <span className="ms-2 tooltip-custom right">
                    <span className="tooltip-text">When offers are imported, the offers' stock quantity will be automatically reduced by a specified percentage.
                    </span>
                    <i className="bi bi-info-circle-fill text-muted"></i>
                  </span>
                </span>
                <Input
                  ref={autoReduceRef}
                  data-testid="ch-auto-reduce"
                  className="form-check-input col-1"
                  type="checkbox"
                  defaultChecked={reduceQty}
                  onChange={(e) => updateReduceQty(e)}
                />
              </td>
            </tr>

            {reduceQty &&
              <tr>
                <td className="d-flex flex-row justify-content-between align-items-center form-switch py-3">
                  <Label data-testid="auto-reduce-qty" className="col-8">
                    For offer imports, reduce the stock quantity by:
                  </Label>
                  <div className="col-2">
                    <Input
                      ref={applyAllRef}
                      data-testid="ch-apply-all"
                      className="form-check-input col-1 me-2"
                      type="checkbox"
                      defaultChecked={applyAllCategories}
                      onChange={(e) => updateApplyAllCategories(e)}
                    />
                    <label>
                      Apply across all categories
                    </label>
                  </div>
                  <div className="input-group ps-3" style={{ width: "120px" }}>
                    <Input
                      data-testid="reduce-percentage"
                      type="number"
                      step="0.01"
                      min="0"
                      max="100"
                      name="reduce_percentage"
                      required
                      ref={autoReducePercentageRef}
                      className={`form-control input-sm to-validate ${autoReducePercentageRef.current?.validationMessage && "is-invalid"
                        }`}
                      defaultValue={auto_reduce_stock_on_import?.reduce_quantity_by_percentage ?? ""}
                      onChange={(e) => updateDefaultPercent(e)}
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </td>
              </tr>
            }
            {(reduceQty && !applyAllCategories) &&
              <OfferSettingsCategories sellerId={seller_id}
                defaultPercent={defaultPercent}
                updateCats={updateCats} />}

          </tbody>
        </table>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        {fetching ? (
          <Button data-testid="" className="btn btn-primary" disabled>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </Button>
        ) : (

          <Button
            data-testid=""
            className="btn btn-primary"
            onClick={updateSettings}
            disabled={btnDisabled}
          >
            Save
          </Button>
        )
        }
      </div>
    </div>
  );
}