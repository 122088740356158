import { FC, useCallback, useEffect } from "react";
import { Button } from "../../forms/components/Button";
import { IAlert } from "../hooks/globals";

interface IProps {
  data: IAlert;
  deleteAlert: (value: number) => void;
}

export const ToastAlert: FC<IProps> = (props) => {
  const { data, deleteAlert } = props;
  const { autoClose, id, message, type } = data;

  const onClick = useCallback(() => deleteAlert(id), [deleteAlert, id]);

  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(onClick, 10000);
      return () => clearTimeout(timer);
    }
  }, [autoClose, onClick]);

  const alertColor = type === "success" ? "alert-success" : "alert-danger";
  const textColor = type === "success" ? "text-success" : "text-danger";

  return (
    <div
      className={`alert d-flex align-items-center justify-content-between ${alertColor}`}
      role="alert"
    >
      {message}
      <Button
        data-testid=""
        type="button"
        className={`btn btn-sm p-0 ${textColor}`}
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClick}
      >
        <span aria-hidden="true" className="fs-4">
          &times;
        </span>
      </Button>
    </div>
  );
};
