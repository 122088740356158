import { escapeControlCharacters } from "./handleEscapeControlChar";

export function handleColumnFilter(
  data: string[],
  searchValue: string,
  isOptimized?: boolean
): string {
  let value = "";
  const isStrictSearch = isOptimized ?? false;

  const escapedControlChars = escapeControlCharacters(searchValue);
  let escapedSearchValue = escapedControlChars;
  data.forEach((filter, index) => {
    if (isStrictSearch) {
      // starting with search that relies on indexes
      value += `{"${filter}":{"_like": ${JSON.stringify(
        escapedSearchValue.replaceAll("\\", "\\\\") + "%"
      )} }}${data.length === index + 1 ? "" : ","}`;
    } else {
      value += `{"${filter}":{"_ilike": ${JSON.stringify(
        "%" + escapedSearchValue.replaceAll("\\", "\\\\") + "%"
      )}}}${data.length === index + 1 ? "" : ","}`;
    }
  });

  return value;
}
