/* eslint-disable id-length */

interface IProps {
  numberOfBars?: number;
  title?: string;
  loadingText?: string;
  icon?: string;
  showMetrics?: boolean;
}

// interface MetricPlaceHoldersProps {
//   sizeHeader: number;
// }

// const MetricPlaceHolders = ({ sizeHeader }: MetricPlaceHoldersProps) => {
//   return (
//     <div className="d-flex justify-content-center">
//       <div className="ripple" role="status"></div>
//     </div>
//   );
// };

export function ChartPlaceHolder(props: IProps): JSX.Element {
  const { numberOfBars, title, loadingText, showMetrics, icon } = props;

  const generateRandomCol = (): string => {
    const randomNumber = Math.floor(Math.random() * (12 - 3 + 1)) + 3;
    return `height-${randomNumber}`;
  };

  return (
    <>
      {title && (
        <div className="card-title placeholder-glow d-flex justify-content-between">
          <h4 className="card-title">
            <i className={`bi bi-${icon} me-2`}></i>
            {title}
          </h4>
        </div>
      )}
      {/* {showMetrics && (
        <div className="d-flex my-4 d-flex justify-content-center">
          <MetricPlaceHolders sizeHeader={1} />
        </div>
      )} */}
      <div className="chart d-flex placeholder-glow align-items-end p-2">
        <h3 className="chart-title">{loadingText}</h3>
        {Array.from(Array(numberOfBars).keys()).map((fakeRow, index) => (
          <span key={fakeRow + index} className={`placeholder col-0 ${generateRandomCol()}`} />
        ))}
      </div>
    </>
  );
}

ChartPlaceHolder.defaultProps = {
  numberOfBars: 15,
  showMetrics: false,
  icon: "bar-chart",
};
