import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from "urql";
import {
  useOfferListQuery,
  useUpdateOfferStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { handleTitle } from "../../sellers-route/handlers/tableHandlers";

interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

export const ArchivedOffers = ({
  numOfOffers,
  sellerId,
}: {
  numOfOffers: number;
  sellerId: string;
}) => {
  const [loading, setLoading] = useState<number>(-1);
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/offers`;
  const sellerRoute = `/${tenantUrlTag}/sellers`
  const formatDate = useDateFormat();
  const [filterCount, setFilterCount] = useState<number>(numOfOffers ?? 0);
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  // const customRule = `,"archived_at":{"_is_null":false}`;
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "seller item sku",
    column_name: "seller_product_sku_lower",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    created_date: "created_date",
    seller_item_sku: "seller_product_sku_lower",
    seller: "company_name",
    location: "address_name",
    date_archived: "archived_at",
    archived_by: "archived_by",
    total_qty_ordered: "order_count",
    id: "id",
  };

  const userInfo = useUserInfo();
  const tableColumnToSearch = "seller_product_sku_lower";

  const listBySeller = sellerId ? `"seller_id":{"_eq": ${JSON.stringify(sellerId)}}` : "";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    sellerFilter: listBySeller,
    statusFilter: `"offer_avail_for_sale_status":{"_eq": "Archived"}`,
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Created_Date]: "desc" },
      where: JSON.parse(handleWhereStr),
    },
  };

  const inputCounts = {
    variables: {
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useOfferListQuery(inputs);
  //const [queriedCount] = useOfferListAggQuery(inputCounts);
  const GET_OFFER_COUNTT = `
        query OfferListCount($where: vw_offer_bool_exp!, $count_limit: Int) {
            vw_offer_aggregate(where: $where, limit: $count_limit) {
              aggregate {
                count
              }
            }
        }
  `;
  const [queriedCount, executeQuery] = useQuery({
    query: GET_OFFER_COUNTT,
    requestPolicy: "cache-and-network",
    variables: inputCounts.variables,
    pause: true
  })
  const [, restoreMutation] = useUpdateOfferStatusMutation();

  const manageOffer = userInfo.permissions! & context.permissions.manage_offer;
  const isSeller = !!userInfo.seller_id;
  const navigate = useNavigate();
  const multiSelectRef = useRef<IRef | null>(null);
  const [restoreIds, setRestoreIds] = useState<string[] | null>(null);

  const columnNames = isSeller
    ? ["created date", "seller item sku", "location", "date archived", "archived by", "total qty ordered", "actions"]
    : ["created date", "seller item sku", "seller", "location", "date archived", "archived by", "total qty ordered"];

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  useEffect(() => {
    if (searchValue !== '' || selectedFilters !== '' || dateRange.startDate || dateRange.endDay) {
      executeQuery({ requestPolicy: 'cache-and-network' });
    }
    else {
      setFilterCount(numOfOffers || 0);
    }
  }, [searchValue, selectedFilters, dateRange]);

  useEffect(() => {
    if (queriedCount.data) {
      setFilterCount(queriedCount.data.vw_offer_aggregate.aggregate?.count);
    }
    else {
      setFilterCount(numOfOffers || 0);
    }
  }, [queriedCount]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ArchivedOffers", errMsg: error.message }} />;
  }
  const errorCount = queriedCount.error;
  if (errorCount) {
    return <PageError error={{ source: "ActiveOffers", errMsg: errorCount.message }} />;
  }
  if (!queried.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }

  const offers = queried.data.vw_offer;
  const tableData = offers.map((offer) => {
    const actions = [
      {
        enabled: true,
        actionType: "view",
        id: offer.id,
        display: "View",
        icon: "bi bi-eye",
        actionFunc: (id: string) => navigate(`${baseRoute}/${id}`),
      },
      {
        enabled: isSeller && manageOffer,
        actionType: "restore",
        id: offer.id,
        display: "Restore",
        icon: "bi bi-arrow-counterclockwise",
        actionFunc: (id: string) => setRestoreIds([id]),
      },
    ];
    return {
      id: offer.id,
      created_date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(offer.created_date)}</p>
          <span className="small text-muted">{formatDate(offer.created_date, "time")}</span>
        </div>
      ),
      seller: (
        <NavLink
          data-testid=""
          to={`${sellerRoute}/${offer.seller_id}`}
          className="text-decoration-underline"
        >
          {offer.company_name}
        </NavLink>
      ),
      location: (<div className="location">
        <div className="text-truncated text-capitalize mb-0">
          <div>{handleTitle(offer.address_name || "", 15)}</div>
          <div className="text-muted small"> {handleTitle(offer.city || "", 15)}, {handleTitle(offer.state || "", 15)}</div>
        </div>
      </div >),
      date_archived: (
        <div className="date-created">
          <p className="mb-0">{formatDate(offer.archived_at)}</p>
          <span className="small text-muted">{formatDate(offer.archived_at, "time")}</span>
        </div>
      ),
      archived_by: offer.archived_by,
      // using Store Item SKU instead of seller_product_sku to fix Stores table columns.
      seller_item_sku: (
        <NavLink data-testid="" to={`${baseRoute}/${offer.id}`} className="text-decoration-underline">
          {offer.seller_product_sku}
        </NavLink>
      ),
      total_qty_ordered: offer.order_count,
      actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  const options = ["Restore"];

  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    if (ids && ids.length !== 0) setRestoreIds(ids);
    return true;
  };
  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await restoreMutation(
        { ids: restoreIds, archived: null, offerStatus: "Active" },
        {
          additionalTypenames: [
            "offer_aggregate",
            "offer",
            "search_counts",
            "vw_offer",
            "search_offers",
          ],
        }
      );
      alertsRef.current?.generate(mutationInfo("offer", MutationAction.Update, res));
      if (res.error) {
        return;
      }
    }
    multiSelectRef.current?.clearSelectedData();
    setRestoreIds(null);
  };

  const addFilterOptions = [
    { label: "Location", value: "address_name_lower" },
    { label: "Seller item sku", value: "seller_product_sku_lower" },
  ];
  if (!sellerId)
    addFilterOptions.push({ label: "Seller", value: "company_name_lower" });
  //const addFilterOptions = [
  //  { label: "Seller", value: "company_name" },
  //  { label: "Seller Item Sku", value: "seller_product_sku" },
  //];
  //const customFilters = [
  //  {
  //    label: "company_name",
  //    value: `{"seller": {"company_name": {"FILTER": "%VALUE%"}}}`,
  //  },
  //  {
  //    label: "seller_product_sku",
  //    value: `{"seller_product_sku": {"FILTER": "%VALUE%"}}`,
  //  },
  //];

  return (
    <div className="col-12">
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={columnNames}
          data={tableData}
          options={options}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={filterCount || 0}
          ref={multiSelectRef}
          multiSelectTable={!!sellerId}
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Item Sku"
          searchHint={`Search by Item Sku`}
        />
      </Card>
      <Dialog
        show={!!restoreIds}
        title={`Restore Offer`}
        continueText="Restore"
        continue={continueAction}
      >
        Are you sure to restore the offer(s)?
      </Dialog>
    </div>
  );
};
