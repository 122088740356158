import { FC, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  useMessageDetailsInitQuery,
  useUpdateMessageContentMutation
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { TabsComponent } from "../../common/components/TabsComponent";
import { appRef } from "../../common/components/appStatus";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormTextarea } from "../../forms/components/FormTextarea";
import { alertsRef } from "../../layout/components/Main";

export const EmailSettingDetails: FC = (): ReturnType<FC> => {
  const baseRoute = "/settings/email-settings";
  const { messageId } = useParams<{ messageId: string }>();
  const msgInputs = {
    variables: {
      msid: messageId,
    },
  };
  const [queried] = useMessageDetailsInitQuery(msgInputs);
  const [, updateMutation] = useUpdateMessageContentMutation();
  useEffect(() => {
    if (queried.data) {
      reset(queried.data.message_setting.find((setting) => setting));
    }
  }, [queried?.data]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = methods;
  useEffect(() => {
    appRef.current?.updateStatus(Object.keys(dirtyFields).length !== 0);
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = (data: FieldValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const msgContent: any = {
      ...data,
      name: void 0,
      __typename: void 0,
    };
    updateMutation(msgContent)
      .then((res) => {
        alertsRef.current?.generate(mutationInfo("email template", MutationAction.Update, res));
      })
      .catch((error) => {
        alertsRef.current?.generate(mutationInfo("email template", MutationAction.Update, error));
      });
  };

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "EmailSettingDetails", errMsg: error.message }} />;
  }
  if (!queried.data) {
    return <Loader />;
  }

  const msgDetails = queried.data.message_setting.find((setting) => setting);
  const variables = msgDetails?.template!.match(/{\w+}/g);

  const tabs = [
    {
      tabName: "Email Template",
      component: (
        <>
          <FormProvider {...methods}>
            <Form data-testid="invite-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-body bg-white p-3 shadow-sm">
                <FormInput
                  className="mb-3 form-control"
                  name="subject"
                  label="Subject"
                  reg_options={{ required: true }}
                />
                <FormTextarea
                  className="form-control email-template-body"
                  name="template"
                  label="Body"
                  reg_options={{ required: true }}
                />
              </div>
              <div className="mt-3 d-flex flex-row-reverse">
                <Button data-testid="btn-submit" value="Submit" className="btn btn-primary ms-1">
                  Save
                </Button>
              </div>
            </Form>
          </FormProvider>
        </>
      ),
    },
    {
      tabName: "Variables",
      component: (
        <div className="bg-white p-3 shadow-sm">
          <p className="template-description">
            Some of the available variables for this template are:
          </p>
          <ul>
            {variables
              ?.filter((item, pos) => variables?.indexOf(item) === pos)
              .map((item) => (
                <li key={item}>{item}</li>
              ))}
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="col-lg-12 col-xl-8 mx-auto">
      <ReturnHeader
        title={msgDetails?.name ?? ""}
        url={baseRoute}
        description="You can use variables to customize your templates."
      />
      <TabsComponent tabs={tabs} />
    </div>
  );
};
