import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Vw_Seller_Bool_Exp,
  useTotalNumberOfSellersArchivedQuery,
  useTotalNumberOfSellersQuery
} from "../../../generated/urql-graphql";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { AllSellers } from "./AllSellers";
import { ArchivedSellers } from "./ArchivedSellers";

export const SellerList: FC = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/sellers`;
  const userInfo = useUserInfo()!;
  const manageSeller = userInfo.permissions! & context.permissions.manage_seller;
  const location = useLocation();
  let staticFilterString = "";

  let initialQryString = `{ "_and": []}`;
  if (location.pathname === baseRoute) {
    staticFilterString = `"_not":{"status_obj":{"_has_key":"archived"}}`;
    initialQryString = `{ "_and": [], ${staticFilterString}}`;
  } else if (location.pathname === baseRoute + "/archived") {
    staticFilterString = `"status_obj":{"_has_key":"archived"}`;
    initialQryString = `{ "_and": [], ${staticFilterString}}`;
  }

  const [whereString, setWhereString] = useState<Vw_Seller_Bool_Exp>(JSON.parse(initialQryString));
  const [numberOfSellers, executeActiveSellerCountQuery] = useTotalNumberOfSellersQuery({
    requestPolicy: 'cache-and-network',
  });
  const [numberOfSellersArchived, executeArchivedSellerCountQuery] = useTotalNumberOfSellersArchivedQuery({
    requestPolicy: 'cache-and-network',
  });

  const refreshSellerCount = () => {
    executeActiveSellerCountQuery();
    executeArchivedSellerCountQuery();
  };

  useEffect(() => {
    let wString1 = JSON.stringify(whereString);
    let wString2: string = "";
    if (location.pathname === baseRoute) {
      wString2 = wString1.replace(
        '"status_obj":{"_has_key":"archived"}',
        '"_not":{"status_obj":{"_has_key":"archived"}}'
      );
    } else if (location.pathname === baseRoute + "/archived") {
      wString2 = wString1.replace(
        '"_not":{"status_obj":{"_has_key":"archived"}}',
        '"status_obj":{"_has_key":"archived"}'
      );
    }
    if (wString2.length > 0 && wString2 !== wString1) {
      setWhereString(JSON.parse(wString2));
    }
  }, [location.pathname]);

  if (
    !numberOfSellers.data ||
    numberOfSellers.fetching ||
    !numberOfSellersArchived.data ||
    numberOfSellersArchived.fetching
  ) {
    return <Loader />;
  }

  const totalSellers = numberOfSellers.data.vw_seller_aggregate.aggregate?.count;
  const totalSellersArchived = numberOfSellersArchived.data.vw_seller_aggregate.aggregate?.count;

  const tabs = [
    {
      tabName: "All Sellers",
      route: `${baseRoute}`,

      totalNumberOfElements: totalSellers,
      component: <AllSellers refreshCount={refreshSellerCount} />,
    },
    {
      tabName: "Archived Sellers",
      route: `${baseRoute}/archived`,
      totalNumberOfElements: totalSellersArchived,
      component: (
        <ArchivedSellers refreshCount={refreshSellerCount} />
      ),
    },
  ];

  return (
    <div className="seller-container-inner">
      <div className="d-flex justify-content-between mb-3">
        <h1 className="page-title">Sellers</h1>
        <div className="d-flex flex-row align-items-end">
          {manageSeller ? (
            <>
              <Link to={{ pathname: `${baseRoute}/add-seller` }} id="sellers-add-seller-link">
                <Button
                  className="btn btn-primary ms-1"
                  data-testid="btn-add-seller"
                  id="sellers-add-seller-btn"
                >
                  Add Seller
                </Button>
              </Link>
            </>
          ) : null}
        </div>
      </div>
      <div className="seller-wrapper">
        <LinkTabsComponent tabs={tabs} />
      </div>
    </div>
  );
};
