import { FC } from "react";
import { Error403 } from "./Error403";
import { Error404 } from "./Error404";
import { Error500 } from "./Error500";
import { ErrorNetwork } from "./ErrorNetwork";

interface IPageError {
  errMsg: string;
  source: string
}

interface IProps {
  statusCode?: number;
  error?: IPageError;
};

export const PageError: FC<IProps> = (props): ReturnType<FC> => {
  const { statusCode, error } = props;
  error && console.log(`${error.source} - page error: ${error.errMsg}`);

  const handleError = () => {
    if (error?.errMsg === "[Network] undefined")
      return <ErrorNetwork />;
    else {
      switch(statusCode) {
        case 403:
          return <Error403 />;
        case 404: 
          return <Error404 />;
        default:
          return <Error500 />;
      }
    }
  }
  return (
    <div>
      {handleError()}
    </div>
  );
};
