import { useRef, useEffect, useState } from "react";

type Field =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement
  | HTMLSelectElement;

export function useFieldValidationNew<
  T extends Field
>(): React.MutableRefObject<T | null> {
  const ref = useRef<T | null>(null);

  const [, setCount] = useState<number>(0);

  let newRef = ref.current;

  useEffect(() => {
    newRef?.classList.add("to-validate");

    function onChange() {
      setCount((count) => count + 1);
    }

    newRef?.addEventListener("keyup", onChange);

    return () => {
      newRef?.removeEventListener("keyup", onChange);
    };
  }, [newRef]);

  return ref;
}
