import { FC, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  Vw_Audit_Log_User_Bool_Exp,
  useAuditLogCountQuery,
  useAuditLogHistoryQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
// eslint-disable-next-line no-shadow
enum EventType {
  Update = "update",
  Create = "create",
  Delete = "delete",
  Login = "login",
  Emulation = "emulation",
};
interface IProp {
  HandleCallBack: (where: Vw_Audit_Log_User_Bool_Exp) => void;
};

export const AuditLogOffset: FC<IProp> = (props) => {
  const { HandleCallBack } = props
  const [context] = useMainContext();
  const [loading, setLoading] = useState<number>(-1);
  const userInfo = useUserInfo();
  let sellerId = userInfo.seller_id;
  let operatorId = userInfo.operator_id!;
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const formatDate = useDateFormat();
  const [selectedFilters, setSelectedFilters] = useState<string>("");

  const { number_items_per_page } = context.operatorSettings.preset;
  let currentDate = new Date();

  let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let initNewRangeDate: IDateRange = {
    startDate: new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate()),
    endDay: null,
    column_name: "id",
  };
  const [dateRange, setdateRange] = useState<IDateRange>(initNewRangeDate);
  const tableColumns = [
    "Log"
  ];
  const tableColumnsToSearch: string[] = [
    "user",
    "event_type",
    "table",
  ];
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue, true),
    dateRange,
    selectedFilters,
    customRule: `,"user":{"_neq": "system"},"operator_id":{"_eq": "${operatorId}"}`,
  });

  HandleCallBack(JSON.parse(handleWhereStr));

  const [auditLogSubscribed] = useAuditLogHistoryQuery({
    variables: {
      whereValue: JSON.parse(handleWhereStr),
      itemsPerPage: number_items_per_page,
      offset: offset,
    },
  });
  const [auditLogCountData] = useAuditLogCountQuery({
    variables: {
      whereValue: JSON.parse(handleWhereStr),
    },
  });

  const addFilterOptions = [
    { label: "User", value: "user", type: "string" },
    {
      label: "Event_type",
      value: "event_type",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Login', value: 'login' },
          { label: 'Emulation', value: 'emulation' },
          { label: 'Delete', value: 'delete' },
          { label: 'Update', value: 'update' },
          { label: 'Create', value: 'create' }
        ]
    },
    { label: "Table", value: "table", type: "string" },
  ];
  useEffect(() => {
    if (auditLogSubscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [auditLogSubscribed.data]);

  const error = auditLogSubscribed.error || auditLogCountData.error
  if (error) {
    return <PageError error={{ source: "AuditLogOffset", errMsg: error.message }} />;
  }

  const audit_data = auditLogSubscribed.data;
  const audit_log_count = auditLogCountData.data?.vw_audit_log_user_count_aggregate.aggregate?.count;
  const textCleaner = (text: string): string => {
    const commaPosition = text.indexOf(",");
    return text
      .substring(0, commaPosition)
      .replace("(", "")
      .replace(`"`, "")
      .replace(`"`, "")
      .replace(`{`, "")
      .slice(0, 50);
  };

  const createTableInfo = (tableName: string, newData: string): string => {
    try {
      let newDataJson = JSON.parse(newData);
      if (tableName === "order") {
        return `${tableName} #${newDataJson.order_number}`;
      } else if (tableName === "tracking_item") {
        return `${tableName} #${newDataJson.tracking_number} for carrier "${newDataJson.carrier_key}"`;
      } else {
        return `${tableName}`;
      }
    } catch (eException) {
      let dataList = newData.replace("(", "").replace(",)", "").replace(")", "").split(",");
      if (tableName === "order") {
        return `${tableName} #${dataList[dataList.length - 2]}`;
      } else if (tableName === "tracking_item") {
        return `${tableName} #${dataList[1]} for carrier "${dataList[2]}"`;
      } else {
        return `${tableName}`;
      }
    }
  };

  const getJsonText = (jsonText: string, key: string): string => {
    try {
      let obj = JSON.parse(jsonText);
      return obj[key];
    } catch (eException) {
      return jsonText;
    }
  };

  const tableName = (table: string | undefined | null, newData: string): string => {
    const data = JSON.parse(newData);
    switch (table) {
      case 'seller':
        return `company "${data.company_name}"`;
      default:
        return (table || "");
    }
  };

  const listUpdateChange = (jsonText: string): JSX.Element => {
    try {
      let obj = JSON.parse(jsonText);

      return (
        <>
          {Object.keys(obj).map((itemKey, itemIndex) => {
            return (
              <li key={itemIndex}>
                {`${itemKey} changed from ${obj[itemKey][0] === "" ? " < empty > " : obj[itemKey][0]
                  } to ${obj[itemKey][1] === "" ? " < empty > " : obj[itemKey][1]}`}
              </li>
            );
          })}
        </>
      );
    } catch (eException) {
      return <li>{jsonText}</li>;
    }
  };

  const tableData = audit_data?.vw_audit_log_user.map((row) => {
    return {
      id: uuid().toString(),
      Log: (
        <span className="me-1">
          <span className="text-muted">{formatDate(row.id)}{` `}{formatDate(row.id, "time")}</span>
          &nbsp;
          {row.event_type === EventType.Emulation && (
            <>
              <span className="me-1">{row.table}</span>
              <span className="me-1 text-lowercase">{row.event_type}</span>
              <span className="me-1">on {row.user}</span>
              <span className="me-1">
                performed by {getJsonText(row.new_data || "", "email")}
              </span>
            </>
          )}
          {/* Event type Login */}
          {row.event_type === EventType.Login && (
            <>
              <span className="mb-0">successful login by {row.user}</span>
            </>
          )}
          {row.event_type === EventType.Update && (
            <>
              <span className="me-1">{tableName(row.table, row.new_data || "")}</span>
              <span className="me-1 text-lowercase">{row.event_type}d</span>
              <span className="me-1">by {row.user}, </span>
              {row.change_data && <ul className="mb-0">{listUpdateChange(row.change_data || "")}</ul>}
            </>
          )}
          {/* Event type Create */}
          {row.event_type === EventType.Create && (
            <>
              <span className="me-1">{row.user}</span>
              <span className="me-1 text-lowercase">
                {`created new record in table ${createTableInfo(row.table || "", row.new_data || "")}`}
              </span>
            </>
          )}
          {/* Event type Delete */}
          {row.event_type === EventType.Delete && (
            <>
              <span className="me-1">{row.user}</span>
              <span className="me-1 text-lowercase">{`deleted record in table "${row.table}"`}</span>
            </>
          )}
        </span >
      )
    };
  });

  return (
    <section>
      <Card className="p-4">
        {(tableData === undefined) ? (
          <>
            <PlaceholderTableSearchItem />
            <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
          </>
        ) : (
          <Table
            setSearchValue={setSearchValue}
            columnNames={tableColumns}
            data={!sellerId ? tableData : []}
            offset={offset}
            setOffset={setOffset}
            totalRecords={audit_log_count}
            multiSelectTable={false}
            dateRange={dateRange}
            predefinedDateRange={true}
            setDateRange={setdateRange}
            filters
            addFilterOptions={addFilterOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Card>
    </section>
  );
};
