import { forwardRef, Ref, useEffect, useState } from "react";
import { useSearch } from "../hooks/useSearch";
import { emptyUuid } from "../miscellaneous/utility";
import { ISearchParam, SelectOption } from "../types/types";
import { CustomSelect, SelectRef } from "./CustomSelect";

interface IProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  itemChanged?: (item: SelectOption | null) => void;
  initOptions?: SelectOption[];
  defVal?: string;
  totalItems?: number;
  noClear?: boolean;
  itemType: string;
  multiple?: boolean;
  required?: boolean;
  errorMsg?: string;
  
  addValue?: (value: string, callback: (added: SelectOption | null)=>void) => void
}

export const SearchSelect = forwardRef<SelectRef, IProps>(
  (props: IProps, ref: Ref<SelectRef>): JSX.Element => {
  const {
    label,
    placeholder,
    disabled,
    itemChanged,
    initOptions,
    defVal,
    totalItems,
    noClear,
    itemType,
    multiple,
    addValue,
    required,
    errorMsg,
  }= props;
  const [optionsData, setOptionsData] = useState<SelectOption[]>(initOptions || []);
  const [initPause, setInitPause] = useState<boolean>(!!initOptions?.length || false);

  const defParam = {
    searchTerm: "%",
    limit: 15,
    offset: 0,
    total: totalItems || 0,
    defaultValue: defVal || emptyUuid
  };

  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const itemOptions = useSearch(itemType, initPause, searchParam);

  useEffect(() => {
    if (initPause && (!["%", "%%"].includes(searchParam.searchTerm!) || searchParam.offset > 0)) {
      setInitPause(false);
    }
  }, [searchParam]);
  useEffect(() => {
    if (searchParam.searchTerm !== "%") {
      setOptionsData(itemOptions.options);
      setSearchParam({ ...searchParam, total: itemOptions.total });
    }
  }, [itemOptions]);

  return (
    <CustomSelect
      label={label}
      options={optionsData || initOptions}
      placeholder={placeholder || ""}
      search={{ searchParam, setSearchParam }}
      onSelectionChanged={(options: SelectOption[]) => {
        let selected = null;
        if (options.length) {
          selected = options[0];
        }
        itemChanged && itemChanged(selected);
      }}
      disabled={disabled}
      noClear={noClear}
      multiple={multiple}
      ref={ref}
      addValue={addValue}
      required={required}
      errorMsg={errorMsg}
    />
  );
});
