import { FC, useEffect, useState } from "react";
import { Money } from "../../../features/reports-route/components/Money";
import {
  Vw_Sales_By_Seller,
  useDashboardTop5SellerQuery
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";

export const TopSeller: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo()!;
  const [loading, setLoading] = useState<boolean>(false);
  let maxTotalSale = 0;
  const [where, setWhere] = useState<string>(`{
              "operator_id": { "_eq": "${userInfo.operator_id}" },
              "status_cd": { "_nin": "Archived"}
           }`);
  let inputs = {
    variables: {
      where: JSON.parse(where),
    },
  };
  const [reportSalesBySellerData] = useDashboardTop5SellerQuery(inputs);
  useEffect(() => {
    if (reportSalesBySellerData.data) {
      setLoading(false);
    }
  }, [reportSalesBySellerData.data]);

  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setWhere(`{
                "operator_id": { "_eq": "${userInfo.operator_id}" },
                 "status_cd": { "_nin": "Archived"}
           }`);
    }
  }, [userInfo]);

  const error = reportSalesBySellerData.error;
  if (error) {
    return <PageError error={{ source: "TopSeller", errMsg: error.message }} />;
  }
  return (
    <div className="top-5-wrapper">
      <span
        className="d-inline-block"
        tabIndex={0}
        data-bs-toggle="popover"
        data-bs-trigger="hover focus"
        data-bs-content="Disabled popover"
        aria-describedby="popover23870"
      >
        <div className="btn-group">
          <h4 className="card-title me-2">
            <i className="bi bi-shop me-2"></i>
            Top {reportSalesBySellerData.data?.vw_sales_by_seller.length} Sellers
          </h4>
        </div>
      </span >

      <div className="row">
        <div className="col">
          <ol className="mt-3 ps-0">
            {reportSalesBySellerData.data?.vw_sales_by_seller.map(
              (item: Vw_Sales_By_Seller, index: number) => {
                if (index === 0) {
                  maxTotalSale = item.total_sales;
                }
                return (
                  <div key={index} className="">
                    <div className="d-flex justify-content-between">
                      <p className="mb-0 font-weight-medium">{item.company_name}</p>
                      <p className="mb-0 text-right text-muted">
                        <Money amount={item.total_sales ?? "N/A"} />
                      </p>
                    </div>
                    <div
                      className="progress mb-2"
                      role="progressbar"
                      arial-aria-valuenow={item.company_name}
                      arial-aria-valuemin={0}
                      ariel-aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-primary"
                        style={{ width: `${(item.total_sales / maxTotalSale) * 100}%` }}
                      ></div>
                    </div>
                  </div>
                );
              })}
          </ol>
        </div>
      </div>
    </div >
  );
};
