import { FC, forwardRef, Ref } from "react";

type Props = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref"
> & {
  /**
   * force inclusion for testing
   */
  "data-testid": string;
  /**
   * force inclusion for browser constraint api, pass null to bypass
   */
  ref: Ref<HTMLInputElement>;
};

export const Input: FC<Props> = forwardRef<HTMLInputElement>((props, ref) => {
  // eslint-disable-next-line react/forbid-elements
  return <input ref={ref} {...props} />;
});
