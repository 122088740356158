import { ILocalSingleFilter, SelectOption } from "../types/types";

export function handleSingleCustomFilter(
  data: ILocalSingleFilter[],
  customFilters: SelectOption[]
): string {
  let value = "";
  data.forEach((filter, index) => {
    const currentValue = filter.filter_value;
    const filterRule = filter.filter_op;
    const element = customFilters.find((ele) => ele.label === filter.filter_name);
    const coma = data.length === index + 1 ? "" : ",";
    value +=
      element?.value
        .replace("FILTER", `${filterRule === "contains" ? "_ilike" : "_nilike"}`)
        .replace("VALUE", currentValue) + coma;
  });

  return value;
}
