import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, } from "react-router-dom";
import { v4 } from "uuid";
import {
  CtgAttrPropsPartFragment,
  IndustryProductPartFragment,
  ProductDetailPartFragment,
  SellerProductPartFragment,
  useCreateProductMutation,
  useOpUrlDomainQuery,
  useProductAttrPropsQuery,
  useUpdateProductMutation,
  useUpdateProductWoDataMutation,
  useUpsertSellerProductMutation,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadProductImage } from "../../common/miscellaneous/storage";
import { isNullOrEmpty, mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { IProdImage, getProdImgUrls } from "../types/product";
import { CategoryAttributes } from "./CategoryAttributes";
import { DetailsOpSection } from "./DetailsOpSection";
import { ProductImage } from "./ProductImage";

interface IProps {
  categoryId: string;
  productData?: ProductDetailPartFragment | SellerProductPartFragment | IndustryProductPartFragment;
  languageCode?: string;
}

export const ProductDetails: FC<IProps> = (props): ReturnType<FC> => {
  const { categoryId, productData } = props;
  //const lang = languageCode || "en-us";
  const [context] = useMainContext();
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const [updatedCtgId, setUpdatedCtgId] = useState<string>(categoryId);
  const [queried] = useProductAttrPropsQuery({
    variables: { ctgId: updatedCtgId },
  });
  const [{ fetching: creating }, createMutation] = useCreateProductMutation();
  const [{ fetching: updating }, updateMutation] = useUpdateProductMutation();
  const [{ fetching: upsertingSP }, upsertSellerProd] = useUpsertSellerProductMutation();
  const [, updateProdWoData] = useUpdateProductWoDataMutation();
  const [domainQueried] = useOpUrlDomainQuery({
    variables: { operatorId: userInfo.operator_id },
  });

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/products`;
  const {
    products_require_operator_approval: prodAppr, allow_customer_mpin, check_mfr }
    = context.operatorSettings.product;

  const productship = productData?.__typename?.includes("data_vw_product") ?
    productData as ProductDetailPartFragment : null;
  const manageProduct = !productData?.__typename?.includes("data_mvw_industry_product")
    && !!(userInfo.permissions! & context.permissions.manage_product);

  const dataSource = !!productship?.info?.ds_prod_id;
  const isPimProduct = !productship?.pim_prod_id
  const dsOpEdit = manageProduct && !userInfo.seller_id && dataSource;
  const sourceEdit = manageProduct &&
    (!productData || //create product
      (!dataSource && //edit non-datasource product (productship or seller product)
        isPimProduct && // edit non-pim product
        (!userInfo.seller_id || //operator
          (userInfo.seller_id === productData.seller_id && //seller own product 
            (!prodAppr || !productship) //productship with non-approval or seller product
          ))
      ));

  const domainNames = domainQueried.data?.pim_operator_url_domain?.map(oud => oud.name);
  const imgUrls = getProdImgUrls(productData?.data.product_image_urls).map(url => ({ url }));
  const defProdImgs: IHash = { origins: imgUrls, added: [] }
  const [prodImages, setProdImages] = useState<IHash>(defProdImgs);
  const [pageChanged, setPageChanged] = useState<boolean>(false);

  /*   const [FetchLanguageQueried] = useFetchLanguagesQuery({
    variables: {
      whereValue: {
        _and: [
          { status_obj: { _contains: { active: true } } },
          { status_obj: { _contains: { available: true } } },
        ],
      },
    }); 
    
    useEffect(() => {
      if (tempProdData && !tempProdData["language_code"]) {
        tempProdData["language_code"] = lang;
      }
       if (FetchLanguageQueried.data && tempProdData && tempProdData["language_code"]) {
        if (
          !FetchLanguageQueried.data?.languages?.find(
            (language) => language.language_code === tempProdData["language_code"]
          )
        ) {
          tempProdData["language_code"] = lang;
        }
      } 
      reset({ ...tempProdData });
      setDisableButton(true);
      trackFormChanged.current = false;
      trackImageChanged.current = false;
      appRef.current?.updateStatus(false);
    }, [productData]);*/

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues:
      productship ?
        {
          ...productship.data,
          ...productship.extra_data,
          featured: productship.featured,
          mpin: productship.mpin
        }
        : productData?.data
  });

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = methods;

  useEffect(() => {
    let changed = Object.keys(dirtyFields).length !== 0;
    const isCtgIdUpdated = (productData && updatedCtgId !== productData.category_id) as boolean;
    changed = changed || prodImages.origins.length !== imgUrls.length
      || !!prodImages.added.length || isCtgIdUpdated;
    if (!prodAppr) {
      appRef.current?.updateStatus(changed);
    }
    setPageChanged(changed);
  }, [Object.keys(dirtyFields).length, prodImages.origins.length, prodImages.added.length, updatedCtgId]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ProductDetails", errMsg: error.message }} />;
  }
  if (!queried.data) {
    return <Loader />;
  }

  const orderedAttrs: CtgAttrPropsPartFragment[] =
    queried.data?.vw_category_attribute_properties || [];

  const onSubmit = async (formData: FieldValues) => {
    //images
    const imageUrls: string[] = prodImages["origins"].map((img: IProdImage) => (img.url));
    for (let img of prodImages.added) {
      if (img.url) {
        if (domainNames?.length && !domainNames.some(dn => img.url.includes(dn))) {
          alertsRef.current?.add("Invalid product image source or you can upload the image", "error");
          return;
        }
        imageUrls.push(img.url);
      }
      else if (img.uploadImg) {
        // save image to Azure Blob
        const filename = `product_image_${userInfo.operator_id}_${img.uploadImg.file?.name}`;
        const url = await uploadProductImage(img.uploadImg.file!, filename)
        imageUrls.push(url);
      }
    }
    const imgs = imageUrls.join("||");
    let res;
    const info = {
      key: "product",
      action: !productData ? MutationAction.Create : MutationAction.Update,
      page: ""
    };

    //upsert product to producship or seller product
    let attrs: IHash = { ...formData };
    const data: IHash = {};
    const keysRemove: string[] = ["mpin"];
    orderedAttrs.forEach(attr => {
      const name = attr.attr_props.name;
      keysRemove.push(name);
      const value = name === "product_image_urls" ? imgs : attrs[name];
      if (!isNullOrEmpty(value))
        data[name] = value;
    });

    let product: IHash = {
      lmfr: attrs.manufacturer.toLowerCase(),
      lmpn: attrs.mfr_part_number.toLowerCase(),
      category_id: updatedCtgId,
      data,
      seller_id: userInfo.seller_id,
    };

    if ((!productData && prodAppr && userInfo.seller_id) || (productData && !productship)) { //seller product
      const additionalTypenames = ["imports_seller_product", "imports_vw_seller_product",
        "imports_vw_seller_product_aggregate"];
      product = { ...product, status: 'Pending', updated_date: 'now()' };
      res = await upsertSellerProd({ product }, { additionalTypenames });
      info.page = "/pending";
      info.key = "seller product";
    }
    else {//productship
      const additionalTypenames = ["data_productship",
        "data_vw_product", "data_vw_product_aggregate"];

      const extra_data: IHash = {};
      Object.keys(attrs).filter(key => !keysRemove.includes(key))
        .forEach(key => extra_data[key] = attrs[key]);

      if (!productship) {//creating
        if (check_mfr)
          extra_data.mp_check_unverified = true;
        product = {
          ...product,
          extra_data,
          mpin: attrs.mpin || v4(),
          operator_id: userInfo.operator_id,
          featured: attrs.featured || false
        };
        res = await createMutation({ product }, { additionalTypenames });
      }
      else {//updating
        let updates: IHash = {
          updated_date: "now()"
        };
        if (!userInfo.seller_id) {
          if (attrs.featured !== productship.featured)
            updates = {
              ...updates,
              featured: attrs.featured
            };
          if (allow_customer_mpin && attrs.mpin)
            updates.mpin = attrs.mpin;
        }
        if (dsOpEdit && updatedCtgId === productData?.category_id) {
          const images = { product_image_urls: imgs };
          res = await updateProdWoData(
            { productId: productship.id, images, updates },
            { additionalTypenames }
          );
        }
        else {
          updates = {
            ...updates,
            data,
            extra_data,
            category_id: updatedCtgId,
          }
          res = await updateMutation(
            { productId: productship.id, updates },
            { additionalTypenames }
          );
        }
      }
    }

    if (!res?.error) {
      appRef.current?.updateStatus(false);
      setPageChanged(false);
      if (!productData) {
        setTimeout(() => navigate(`${baseRoute}${info.page}`), 300);
      }
    }
    alertsRef.current?.generate(mutationInfo(info.key, info.action, res));
  }

  /*   if (FetchLanguageQueried.error) {
      const error = FetchLanguageQueried.error;
      return <div>{error?.message}</div>;
    }
    if (!FetchLanguageQueried.data || FetchLanguageQueried.fetching) {
      return <Loader />;
    }
    const languageData = FetchLanguageQueried.data;
   
    const languageOptions = languageData.languages.map((item) => {
      return { label: item.name ?? "", value: item.language_code ?? "" };
    }); */

  return (
    <div className="col-auto">
      {(sourceEdit || dsOpEdit) && (
        <div className="mt-4 d-flex justify-content-end form-group">
          {(creating || updating || upsertingSP) ? (
            <Button
              data-testid="update-product-detail-spinner"
              className="btn btn-primary"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </Button>
          ) : (
            <Button
              data-testid=""
              type="submit"
              className="btn btn-primary "
              form="product-detail-form"
              disabled={!pageChanged}
            >
              Save
            </Button>
          )}
        </div>)}
      <FormProvider {...methods}>
        <Form

          data-testid="product-detail-form"
          id="product-detail-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          {(!userInfo.seller_id || productData) &&
            <DetailsOpSection
              editable={sourceEdit}
              productData={productData}
              dsOpEdit={dsOpEdit}
              setCategory={(ctgId: string) => setUpdatedCtgId(ctgId)}
            />}
          <CategoryAttributes
            orderedAttrs={orderedAttrs}
            editable={sourceEdit}
            isCreating={!productData}
            globalSec={true}
          />
          {(sourceEdit || dsOpEdit) && (
            <Card className="p-4">
              <Label data-testid="" className="card-title">
                Media
              </Label>
              <ProductImage
                prodImages={prodImages}
                setProdImages={setProdImages}
              ></ProductImage>
            </Card>
          )}
          <CategoryAttributes
            orderedAttrs={orderedAttrs}
            editable={sourceEdit}
            isCreating={!productData}
            globalSec={false}
          />
        </Form>
      </FormProvider>
      {(sourceEdit || dsOpEdit) && (
        <div className="mt-4 d-flex justify-content-end form-group">
          {(creating || updating || upsertingSP) ? (
            <Button
              data-testid="update-product-detail-spinner"
              className="btn btn-primary"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </Button>
          ) : (
            <Button
              data-testid=""
              type="submit"
              className="btn btn-primary "
              form="product-detail-form"
              disabled={!pageChanged}
            >
              Save
            </Button>
          )}
        </div>)}
    </div>
  );
};
