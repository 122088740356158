import { FC, useEffect, useRef, useState } from "react";
import {
  useArchivedUsersInitQuery,
  useArchivedUsersSubscription,
  useRestoreOpUserMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

interface UserState {
  showDialog: boolean;
  archivedIds: string[];
}

export const ArchivedUsers: FC = () => {
  const [context] = useMainContext();

  const [loading, setLoading] = useState<number>(-1);
  const { number_items_per_page } = context.operatorSettings.preset;
  const formatDate = useDateFormat();
  const userInfo = useUserInfo()!;
  const archiveUser = userInfo.permissions! & context.permissions.archive_user;
  const defState = {
    showDialog: false,
    archivedIds: [],
  };
  const [userState, setUserState] = useState<UserState>(defState);
  const multiSelectRef = useRef<IRef | null>(null);
  const columnNames = ["company", "email", "full name", "archived date", "archived by"];
  const tableColumns = ["company_name", "email", "full_name", "archived_by"];

  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "archived_at",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    company: "company_name",
    email: "email",
    full_name: "full_name",
    archived_date: "archived_at",
    archived_by: "archived_by",
  };
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      where: JSON.parse(
        handleWhere({
          columnSearch: handleColumnFilter(tableColumns, searchValue),
          dateRange,
          selectedFilters,
          customRule: `,"operator_id": {"_eq": "${userInfo.operator_id}"}`
        })
      ),
      order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined,
    },
  };
  const [queried] = useArchivedUsersInitQuery(inputs);
  const [subscribed] = useArchivedUsersSubscription(inputs);
  const [, restoreMutation] = useRestoreOpUserMutation();
  useEffect(() => {
    if (subscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [subscribed.data]);

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "ArchivedUsers", errMsg: error.message }} />;
  }

  if (!queried.data || !subscribed.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={columnNames} />
      </Card>
    );
  }

  const continueRestore = (isContinue: boolean) => {
    if (isContinue) {
      restoreMutation({ userIds: userState.archivedIds, operatorId: userInfo.operator_id })
        .then((res) => {
          if (res.data && !res.error) {
            setUserState(defState);
          }
          alertsRef.current?.generate(mutationInfo("user", MutationAction.Restore, res));
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("user", MutationAction.Restore, error));
        });
    } else setUserState(defState);
  };

  const users = subscribed.data.vw_archived_user ?? queried.data.vw_archived_user;
  const totalNumber = queried.data.total_archived.aggregate?.count;

  if (archiveUser) columnNames.push("Actions");
  const tableBulkActions = ["Restore"];

  const tableData = users.map((user) => {
    const actions = [
      {
        actionType: "restore",
        id: user.user_id,
        display: "Restore",
        icon: "bi bi-arrow-counterclockwise",
        actionFunc: (id: string) =>
          setUserState({
            archivedIds: [id],
            showDialog: true,
          }),
      },
    ];
    return {
      id: user.user_id,
      company: user.company_name,
      email: user.email,
      full_name: user.full_name,
      archived_date: formatDate(user.archived_at, "datetime", false),
      archived_by: user.archived_by,
      Actions: <DropdownItems items={actions} />,
    };
  });

  const applyChanges = () => {
    setUserState({
      archivedIds: multiSelectRef.current?.selectedData ?? [],
      showDialog: true,
    });
    return true;
  };

  const addFilterOptions = [
    { label: "Company", value: "company_name" },
    { label: "Email", value: "email" },
    { label: "Full name", value: "full_name" },
    { label: "Archived by", value: "archived_by" },
  ];

  return (
    <Card>
      <Table
        columnNames={columnNames}
        data={tableData}
        offset={offset}
        setOffset={setOffset}
        totalRecords={totalNumber || 0}
        setSearchValue={setSearchValue}
        filters
        dateRange={dateRange}
        setDateRange={setdateRange}
        addFilterOptions={addFilterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        multiSelectTable
        applyChanges={applyChanges}
        ref={multiSelectRef}
        options={tableBulkActions}
        setOrderBy={setOrderBy}
        orderByOptions={orderByOptions}
        orderBy={orderBy}
        loading={loading}
        setLoading={setLoading}
        searchPlaceHolder="Search by Company,..."
        searchHint="Search by Company, Email, Full Name, Archived By"
      />
      <Dialog
        show={userState.showDialog}
        title="Restore user account?"
        continueText="Restore"
        continue={continueRestore}
        contineBtnCss="btn-success"
      >
        <div className="pt-2">
          This will restore the selected user account and its assignments. Restore the selected user
          account?
        </div>
      </Dialog>
    </Card>
  );
};

export default ArchivedUsers;
