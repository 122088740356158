import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Message_Insert_Input, useCreateMessageMutation, useGetSellerMessageByIdQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadOrderFile } from "../../common/miscellaneous/storage";
import { getFileExt, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { A } from "../../forms/components/A";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { TextArea } from "../../forms/components/Textarea";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import MessageThread from "./MessageThread";

interface IFileInfo {
  file_name: string;
  file_path: string;
  file_size: number;
}

const MessagesByCustomer = () => {
  const [context] = useMainContext();
  const userInfo = useUserInfo()!;
  const { preset } = context.operatorSettings;
  const manageMessages = userInfo.permissions! & context.permissions.manage_order_messages;

  const tenantUrlTag = context.operatorInfo.tenant_url_tag
  const baseRoute = `/${tenantUrlTag}/seller-messages`;
  const { messageId } = useParams<{ messageId: string }>();
  const [queried] = useGetSellerMessageByIdQuery({ variables: { messageId: messageId } })
  const customerDetails = {
    email: queried.data?.vw_seller_message[0].customer_email || "",
    fullName: queried.data?.vw_seller_message[0].customer_info?.full_name
  }
  const messageSellerId = queried.data?.vw_seller_message[0].seller_id

  const [loader, setLoader] = useState<boolean>(false)
  const [fetchingCreate, createMessage] = useCreateMessageMutation()
  const [fileSelected, setFileSelected] = useState<IFileInfo | null>(null);
  const currentMessage = useRef<HTMLTextAreaElement>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [messageTextLength, setMessageTextLength] = useState<number>(0)
  const messageInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBtnEnabled(e.target.value.length > 0 || fileSelected != null);
    setMessageTextLength(e.target.value.length)
  };

  const handleUploadClick = () => {
    uploadRef.current?.click();
  };

  const allowedFileTypesSetting = preset.document_formats ?? "";
  const maxFileSizeKB = preset.document_size ?? 2048;
  const maxFileSizeMB = Math.round(maxFileSizeKB / 1024);
  const maxFileSizeBytes = maxFileSizeKB * 1024;
  const allowedFileFormats = allowedFileTypesSetting
    .split(",")
    .map((item: string) => {
      let fixedItem = item.trim().toLowerCase();
      if (!fixedItem.startsWith(".")) fixedItem = `.${fixedItem}`;
      return fixedItem;
    })
    .join(",");

  const onFileBlobUpload = async (file: File) => { return await uploadOrderFile(file); }

  const setupFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selected = event.target.files[0];
      // checking for just the extension or MIME type may not be secure enough?
      const extension = getFileExt(selected.name);
      const allowedTypes = allowedFileFormats.split(",");
      const typeAllowed = allowedTypes.indexOf(extension) > -1;
      if (!typeAllowed) {
        alertsRef.current?.add("This file type is not allowed", "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else if (selected.size > maxFileSizeBytes) {
        alertsRef.current?.add(`Maximum allowed file size is ${maxFileSizeMB}MB`, "error");
        if (uploadRef.current !== null) uploadRef.current.value = "";
        return;
      } else {
        const uploadedFile: IFileInfo = {
          file_name: selected.name,
          file_path: "",
          file_size: selected.size,
        };
        setFileSelected(uploadedFile);
        setBtnEnabled(true);
      }
    }
  };

  const removeSelectedFile = () => {
    setFileSelected(null);
    if (uploadRef.current !== null) uploadRef.current.value = "";
    const curMessage = currentMessage?.current?.value;
    if (curMessage != null && curMessage.length == 0) setBtnEnabled(false);
  };

  const postMessage = async (message: Message_Insert_Input) => {
    const res = await createMessage({ message });

    if (res && res.error) {
      alertsRef.current?.generate(mutationInfo("message", MutationAction.Add, res));
    }
    setLoader(false);
    if (currentMessage.current != null) currentMessage.current.value = "";
    if (uploadRef.current !== null) uploadRef.current.value = "";
    setBtnEnabled(false);
    setFileSelected(null);
  };

  const addMessage = async (e: any) => {
    e.preventDefault();
    const user = {
      full_name: userInfo.full_name,
      email: userInfo.email,
    };

    let messageObject: Message_Insert_Input = {
      user_id: userInfo.user_id,
      message_text: currentMessage.current?.value,
      user_info: user,
      file_name: null,
      file_path: null,
      seller_id: userInfo.seller_id || messageSellerId,
      message_type: "Seller",
      operator_id: userInfo.operator_id,
      customer_email: customerDetails.email,
      message_from: "Seller"
    };

    if (uploadRef.current?.files && uploadRef.current.files.length > 0) {
      setLoader(true);
      const selected = uploadRef.current.files[0];
      const uploadResult = onFileBlobUpload(selected);
      uploadResult.then((url) => {
        messageObject.file_name = selected.name;
        messageObject.file_path = url;
        postMessage(messageObject);
      });

      uploadResult.catch((err) => {
        alertsRef.current?.add(`File upload failed: ${err}`, "error");
        removeSelectedFile();
        setLoader(false);
      });
    } else if (messageObject.message_text != null && messageObject.message_text != "") {
      setLoader(true);
      postMessage(messageObject);
    }
  };
  return (
    <>
      <ReturnHeader title="Messages" url={baseRoute} />
      <div className="d-flex mb-4">
        <div className="me-3 align-self-top">
          <i className="bi bi-person text-muted text-large"></i>
        </div>
        <div className="flex-1">
          <small className="text-muted me-2">Customer:</small>
          <h6 className="customer-name mb-0">
            {customerDetails.fullName}
          </h6>
          <p className="customer-email small mb-0">
            <A data-testid="" href={`mailto:${customerDetails.email}`}>
              {customerDetails.email}
            </A>
          </p>
        </div>
      </div>
      <Card className="mb-3">
        <div className="row">
          <div className="col-md-12">
            <div className="border-bottom card-header">
              <h5 className="d-flex justify-content-between card-title mb-0 align-items-center">
                <span>
                  <i className="bi bi-chat-left-text me-2"></i>
                  <span>Messages between you and {customerDetails.email}</span>
                </span>
              </h5>
            </div>
            <MessageThread customerDetails={customerDetails} messageSellerId={messageSellerId} />
            {
              manageMessages && <>
                <form onSubmit={(e) => addMessage(e)}>
                  <div className="d-flex input-group">
                    <TextArea
                      data-testid=""
                      ref={currentMessage}
                      className="form-control p-2"
                      placeholder="Write a message"
                      rows={1}
                      onChange={(e) => messageInputChange(e)}
                      maxLength={3000}
                    />
                    {!loader && (
                      <>
                        <Button
                          data-testid=""
                          role="button"
                          className="btn btn-light border"
                          type="button"
                          title="Attach file"
                          onClick={handleUploadClick}
                        >
                          <i
                            role="button"
                            className="bi bi-paperclip fa-lg"
                            title="paperclip icon"
                          ></i>
                        </Button>
                        <Input
                          id="file"
                          type="file"
                          ref={uploadRef}
                          style={{ display: "none" }}
                          data-testid="message-file-upload"
                          accept={allowedFileFormats}
                          onChange={setupFileUpload}
                        />
                      </>
                    )}
                    {loader ? (
                      <Button data-testid="" className="btn btn-primary" disabled>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </Button>
                    ) : (

                      <Button
                        data-testid=""
                        className="btn btn-primary"
                        disabled={!btnEnabled}
                        type="submit"
                      >Send
                      </Button>
                    )}
                  </div>
                  <div className="d-flex justify-content-end me-2">
                    <small className={`${messageTextLength >= 3000 ? "text-danger " : "text-muted"}`}>{messageTextLength}/3000</small>
                  </div>
                </form>
                {fileSelected && (
                  <div className="d-flex font-weight-light align-items-center justify-content-between px-3 py-1 mt-2 border">
                    <div className="file-name-wrapper me-3">
                      <p className="small file-name font-weight-semi-bold mb-0">
                        {fileSelected?.file_name}
                        <span className="small text-muted ms-2 font-weight-light">
                          {Math.round(fileSelected?.file_size / 1000)} KB
                        </span>
                      </p>
                      <small className="text-muted">Attached</small>
                    </div>
                    <Button
                      data-testid=""
                      className="btn btn-sm btn-danger"
                      onClick={removeSelectedFile}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </Card>
    </>
  )
}

export default MessagesByCustomer