import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getFieldError, regProps } from "../../common/miscellaneous/utility";
import { IFormInputProps, SelectOptionExt } from "../../common/types/types";

type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "name"
> &
  IFormInputProps & {
    //radio type value/label array
    radio_options?: SelectOptionExt[];
    inputgrouplabel?: string;
    measurelabel?: string | null;
    isswitch?: boolean | string | null;
    toggleSearch?: () => void;
    copyClipboardFunc?: () => void;
  };

export const FormInput = (props: InputProps): JSX.Element => {
  const {
    name,
    label,
    label_extra,
    type,
    input_extra,
    tool_tip,
    tooltip_position,
    css,
    reg_options,
    inputgrouplabel,
    measurelabel,
    radio_options,
    isswitch,
    form_methods,
    toggleSearch,
    copyClipboardFunc
  } = props;
  const methods = useFormContext() || form_methods;
  const [inputMasked, setIsInputMasked] = useState<boolean>(type === "password")
  const [inputType, setInputType] = useState<string>(type || "text")
  const {
    register,
    formState: { errors },
    setError,
    getValues,
  } = methods;

  useEffect(() => {
    if (inputMasked) {
      setInputType("password")
    } else if (type === "password") {
      setInputType("text")
    }
  }, [inputMasked])

  const fieldError = getFieldError(errors, name);
  const required = reg_options?.required;
  const maxLengthStr = reg_options?.maxLength
    ? `Please shorten this text to ${reg_options.maxLength ?? 0} characters or less.`
    : undefined;
  useEffect(() => {
    if (
      fieldError &&
      fieldError?.type === "maxLength" &&
      reg_options?.maxLength &&
      getValues(name).length > reg_options?.maxLength
    ) {
      setError(name, { type: "maxLength", message: maxLengthStr });
    }
  }, [errors]);
  const tooltipElement = tool_tip && (
    <>
      <span className={`ms-1 tooltip-custom ${tooltip_position || "right"}`}>
        <span className="tooltip-text">{tool_tip}
        </span>
        <i className="bi bi-question-circle"></i>
      </span>
    </>
  );
  return (
    <div className={`${css ? css : type === "checkbox" ? "mb-1" : "mb-3"}`}>
      {/* custom css has higher priority */}
      {type === "checkbox" ? (
        <>
          <div
            className={`form-check ${isswitch ? "form-switch" : ""} ${input_extra ? "me-2" : ""}`}
          >
            <label className={`${required ? "required mb-0" : "form-check-label mb-0"}`}>
              <input className="form-check-input" {...regProps(name, register, props)} />
              {label && (
                <label
                  className={`font-weight-semi-bold form-label ${required ? "required" : ""}`}
                >
                  {label} {label_extra} {tooltipElement}
                </label>
              )}
            </label>
          </div>
          {input_extra}
        </>
      ) 
      :
      (type === "radio" ? 
        (radio_options && radio_options?.length > 0 && 
          (<div className="d-flex flex-column">
            {label && (
              <label className={`font-weight-semi-bold form-label ${required ? "required" : ""}`}>
                {label} {label_extra}
                {tooltipElement}
              </label>
            )}
            {radio_options.filter(option=>!option.disabled).map((option, index: number) => (
              <div className="form-radio" key={`${name}_${index}`}>
                <label className="form-check-label">
                  <input
                    id={`${name}_${index}`}
                    className="form-check-input me-1"
                    value={option.value}
                    {...regProps(name, register, props)}
                  />
                  {option.label}
                </label>
                {option.description}
              </div>
            ))}
          </div>)
        )
        :
        ( //type === "input"
          <>
            {label && (
              <label className={`font-weight-semi-bold form-label ${required ? "required" : ""}`}>
                {label === "LABEL_FOR_POS_HIDDEN" ? "" : label} {label_extra} {tooltipElement}
              </label>
            )}
            <div className="input-wrapper">
              <div
                className={`${inputgrouplabel
                  ? "input-group"
                  : measurelabel
                    ? "input-group"
                    : input_extra || toggleSearch
                      ? "d-flex flex-row align-items-center"
                      : ""
                  }`}
              >
                {inputgrouplabel && <span className="input-group-text">{inputgrouplabel}</span>}
                {copyClipboardFunc && <i onClick={copyClipboardFunc} className={`bi bi-clipboard me-1 ${type == "password" ? "input-icon-end2" : "input-icon-end"}`} />}
                {type === "password" && <i onClick={() => { setIsInputMasked(!inputMasked); }} className={`bi ${inputMasked ? 'bi-eye-slash-fill' : 'bi-eye-fill'} me-1 input-icon-end`} />}
                <input
                  className={`form-control ${fieldError ? "is-invalid" : ""}`}
                  {...regProps(name, register, { ...props, type: inputType, onClick: toggleSearch })}
                />
                {measurelabel ? <span className="input-group-text">{measurelabel}</span> : ""}
                {input_extra}
              </div>
              {fieldError && (
                <>
                  <small className="has-error text-danger">
                    <i className="bi bi-exclamation-circle-fill me-1"></i>
                    {fieldError?.type === "maxLength" ? maxLengthStr : 
                      (fieldError.message || fieldError.ref.validationMessage)}
                  </small>
                </>
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

FormInput.defaultProps = {
  type: "text",
};
