import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  App_Setting_Category_Enum,
  useUpdateAppSettingsMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { appRef } from "../../common/components/appStatus";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

export const SMTPSettings: FC = (): ReturnType<FC> => {
  const baseRoute = "/settings/email-settings";
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const { smtp } = context.operatorSettings;
  const [, updateMutation] = useUpdateAppSettingsMutation();

  const [formChanged, setFormChanged] = useState<boolean>(false);
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: smtp
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    const changed = Object.keys(dirtyFields).length !== 0;
    setFormChanged(changed);
    appRef.current?.updateStatus(changed);
  }, [Object.keys(dirtyFields).length]);

  const enableSmtp = watch("custom_smtp");
  const onSubmit = async (data: FieldValues) => {
    const settings = Object.entries(data).map((pair) => {
      return {
        _set: { value: pair[1]?.toString(), updated_date: "now()" },
        where: {
          operator_id: { _eq: userInfo.operator_id },
          app_setting: { name: { _eq: pair[0] }, category: { _eq: App_Setting_Category_Enum.Smtp } }
        }
      };
    }) as any;
    const res = await updateMutation({ settings });

    if (!res?.error) {
      setFormChanged(false);
      appRef.current?.updateStatus(false);
    }
    alertsRef.current?.generate(mutationInfo("smtp setting", MutationAction.Update, res));
  };

  return (
    <>
      <ReturnHeader
        title="SMTP Settings"
        url={baseRoute}
        description="Configure SMTP to allow MarketPush to send email-based notifications, such as new user
        invitations and system errors."
      />
      <FormProvider {...methods}>
        <Form
          data-testid="seller-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <Card>
            <div className="d-flex justify-content-between">
              <FormInput
                name="custom_smtp"
                label="Enable email sending with SMTP"
                type="checkbox"
                css="d-flex align-items-center"
              />
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <FormInput
                  name="sender_name"
                  label="Sender Name"
                  css="mb-3"
                  reg_options={{ required: enableSmtp, maxLength: 100 }}
                  disabled={!enableSmtp}
                />
                <FormInput
                  name="host"
                  label="Host"
                  css="mb-3"
                  reg_options={{ required: enableSmtp, maxLength: 100 }}
                  disabled={!enableSmtp}
                />
                <FormSelect
                  name="authentication"
                  label="Authentication"
                  css="mb-3"
                  options={[
                    { label: "Plain/Login", value: "Plain/Login" },
                    { label: "Auth0", value: "Auth0" },
                    { label: "SSO", value: "SSO" },
                  ]}
                  reg_options={{ required: enableSmtp }}
                  disabled={!enableSmtp}
                />
                <FormInput
                  name="username"
                  label="Username"
                  css="mb-3"
                  reg_options={{ required: enableSmtp, maxLength: 100 }}
                  disabled={!enableSmtp}
                />
                <FormInput
                  name="password"
                  label="Password"
                  type="password"
                  css="mb-3"
                  reg_options={{ required: enableSmtp, maxLength: 100 }}
                  disabled={!enableSmtp}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <FormInput
                  name="sender_email"
                  label="Sender Email"
                  css="mb-3"
                  reg_options={{ required: enableSmtp, maxLength: 100 }}
                  disabled={!enableSmtp}
                />
                <FormInput
                  name="port"
                  label="Port"
                  css="mb-3"
                  type="number"
                  reg_options={{ required: enableSmtp, min: 1 }}
                  step={1}
                  disabled={!enableSmtp}
                />
              </div>
            </div>
          </Card>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              data-testid=""
              disabled={!formChanged}
              type="submit"
              className="btn btn-primary "
            >
              Save
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
