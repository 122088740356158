import { FC, useEffect, useRef, useState } from "react";
import {
  ProductDetailPartFragment,
  useCategoryFamiliesQuery,
  useUpdateProductMutation
} from "../../../generated/urql-graphql";
import { CustomSelect, SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { isNullOrEmpty, mutationInfo, popText } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";

interface IProps {
  productData: ProductDetailPartFragment;
  setCtg: (ctg: SelectOption | null) => void;
}

export const CategoryFamilies: FC<IProps> = ({
  productData,
  setCtg
}: IProps): ReturnType<FC> => {
  const { category_id, product_category } = productData;
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const [errMsg, setErrMsg] = useState<string>("");
  const selectRef = useRef<SelectRef | null>(null);

  const [queried] = useCategoryFamiliesQuery({
    variables: { category_id },
    pause: isNullOrEmpty(category_id),
  });
  const [, updateMutation] = useUpdateProductMutation();

  const {path: ctgPath, last:ctgName} = popText(product_category, "->");
  useEffect(() => {
    if (queried.data) {
      const categories =
        queried.data.category_families.map(
          (ctg) =>
          ({
            label: ctg.fullname?.replace(ctgPath + "->", ""),
            value: ctg.id,
          } as SelectOption)
        ) || [];
      setCategoryOptions(categories);
    }
  }, [queried.data]);


  const continueChange = async (isContinue: boolean) => {
    if (isContinue) {
      const ctg = selectRef.current?.selected.find(opt=>opt);
      if (!ctg) {
        setErrMsg("new category is required");
        return;
      }
      const updates = {category_id: ctg.value, updated_date: new Date().toISOString()};
      const res = await updateMutation(
        { productId: productData.id, updates },
        { additionalTypenames: ["data_productship"] }
      );
      const info = mutationInfo("product", MutationAction.Update, res)
      if (!res.error) {
        alertsRef.current?.generate(info);
        setCtg(ctg);
        setErrMsg("");
      } else {
        setErrMsg(info.message);
      }
    }
    else setCtg(null);
  }

  return (
    <>
      <Dialog
        title="Change Unassigned Category"
        continueText="Change"
        continue={continueChange}
        size="lg"
        footerText={
        <small className="text-muted">
          <i className="bi bi-exclamation-circle-fill me-1 text-warning"></i>
          Changing unassigned category is irreversible.
        </small>}
      >
        <div className="mb-3">
          <span className="font-weight-semi-bold">Category path:</span>
          <code className="ms-2">{ctgPath}</code>
        </div>
        <div className="mb-3">
          <span className="font-weight-semi-bold">Changing subcategory</span>
          <code className="ms-2">{ctgName}</code>         
        </div>
        <div className="mb-3">
          <CustomSelect
            ref={selectRef}
            label="To new subcategory"
            required={true}
            options={categoryOptions}
            noClear={true}
          />
          {errMsg && (
            <small className="has-error text-danger">
              <i className="bi bi-exclamation-circle-fill me-1"></i>
              {errMsg}
            </small>
          )}
        </div>
      </Dialog>
    </>
  );
};
