import { FC, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  useProductByCategoriesQuery,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";

interface StorePgroup {
  id: string | null;
  store: string;
  pgroup: string;
  categories: SelectOption[];
}

interface Props {
  closeDialog: () => void;
  storePgroup: StorePgroup;
}

export const AddCost: FC<Props> = ({ closeDialog, storePgroup }) => {
  const [variants, setVariants] = useState<number>(1);
  const ctgIds = storePgroup.categories.map((ctg) => ctg.value);
  const inputs = {
    variables: {
      ids: ctgIds,
    },
  };
  const [queried] = useProductByCategoriesQuery(inputs);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (formData: FieldValues) => {
    closeDialog();
  };

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "AddCost", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return <Loader />;
  }

  const products = queried.data.data_vw_product;
  return (
    <div>
      <span>Create or edit existing costs between a seller and a specific product group.</span>
      <div className="d-flex">
        <div className="col-xs-12 col-sm-6">
          <small className="text-muted me-2">Product Groups:</small>
          <h6 className="mb-2">{storePgroup.pgroup}</h6>
        </div>
        <div className="col-xs-12 col-sm-6">
          <small className="text-muted me-2">Sellers:</small>
          <h6 className="payment-terms mb-1">{storePgroup.store}</h6>
        </div>
      </div>
      <FormProvider {...methods}>
        <Form
          data-testid="store-pgroup-cost-form"
          id="store-pgroup-cost-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <table className="table table-hover align-middle my-2">
            <thead>
              <tr>
                <th scope="col">Variant</th>
                {storePgroup.categories.map((category, index) => (
                  <th key={"title_" + index}>{category.label}</th>
                ))}
                <th scope="col" className="required">
                  Cost per unit
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: variants }, (item, vIndex) => {
                return (
                  <tr key={"row_" + vIndex}>
                    <td>{vIndex + 1}</td>
                    {storePgroup.categories.map((category, ctgIndex) => {
                      const ctgProducts = products
                        .filter((product) => product.category_id === category.value)
                        .map((product) => {
                          return { label: product.product_title!, value: product.id! };
                        });
                      return (
                        <td key={"col_" + vIndex + "_" + ctgIndex}>
                          <FormSelect
                            name={`variant.${vIndex}.category.${ctgIndex}`}
                            label=""
                            options={ctgProducts}
                          />
                        </td>
                      );
                    })}
                    <td>
                      <FormInput
                        name={`variant.${vIndex}.unit_cost`}
                        label=""
                        reg_options={{ min: 0, required: true }}
                        type="number"
                        step="0.01"
                        onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                          evt.key === "e" && evt.preventDefault();
                          evt.key === "-" && evt.preventDefault();
                          evt.key === "+" && evt.preventDefault();
                        }}
                      />
                    </td>
                    <td>
                      {vIndex > 0 && <Button
                        data-testid=""
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setVariants(variants - 1)}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex flex-row">
            <Button
              data-testid=""
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setVariants(variants + 1);
              }}
            >
              Add Variant
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
