import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useCreateUserNotificationTypeMutation,
  useGetAllNotificationTypeSubscription,
  useUpdateUserSettingMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadAppData } from "../../common/miscellaneous/storage";
import { getFileExt, mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { Input } from "../../forms/components/Input";
import { Label } from "../../forms/components/Label";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
interface PropDT {
  data: {
    [key: string]: any
  };
};

export const ProfileReviewSubmit: FC<PropDT> = (props) => {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag ?? "";
  const { data, } = props;
  const sellerEmailNotificationNames = ['New order - email', 'New Message']
  const [notificationTypeList] = useGetAllNotificationTypeSubscription({});
  const [{ fetching }, updateMutation] = useUpdateUserSettingMutation();
  const [{ fetching: loading }, createNewUserNotificationType] = useCreateUserNotificationTypeMutation();
  const navigate = useNavigate();
  const [busy, setBusy] = useState<boolean>(false);
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      contact: data.contact,
      presetNotification: data.preset_notification ? 'Yes' : 'No',
      enableNotification: data.enable_notification ? 'Yes' : 'No',
      enableEmailNotification: data.enable_email_notification ? 'Yes' : 'No'
    },
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    reset
  } = methods;

  const UpdateUserProfile = async () => {
    //console.log(`UpdateUserProfile`);
    let get_notification = false;
    if (data.preset_notification) {
      get_notification = true;
    }
    else {
      get_notification = data.enable_notification;
    }
    if (data.file && data.profile_image) {
      const uniqueFileName = `profile_image_${data.user_id}${getFileExt(data.file.name)}`;
      data.url = await uploadAppData(data.file, uniqueFileName);
    }
    const res = await updateMutation({
      userId: data.user_id,
      profile: {
        first_name: data.first_name ?? "",
        last_name: data.last_name ?? "",
        phone: data.contact,
        profile_image: data.url ?? data.profile_image,
        updated_date: (new Date()).toISOString()
      },
      operatorId: userInfo.operator_id,
      opSetting: {
        is_preset_notification: data.preset_notification ? true : false,
        get_notification: get_notification,
        get_email_notification: data.get_email_notification ?? true,
        updated_date: (new Date()).toISOString()
      }
    });

    if (!res.error) {
      window.location.href = `/${tenantUrlTag}`;
    }
    else {
      //console.log(`Exception: ${res.error}`);
      // This does not work since main is not loaded yet
      alertsRef.current?.generate(mutationInfo("user profile", MutationAction.Update, res));
    }
  };
  const UpdateNotificationType = async () => {
    data.notification_type_list.forEach((item: any) => {
      if (item.id) {
        createNewUserNotificationType({
          user_id: data.user_id,
          operator_id: data.operator_id,
          nt_type_id: item.id,
        })
          .then((res) => {
            alertsRef.current?.generate(mutationInfo("user notification type",
              MutationAction.Update, res));
          })
          .catch((error) => {
            // ignore 
            console.log(`Exception: ${error}`);
          })
      }
    }
    )
  };

  const UpdateEmailNotificationType = async () => {
    const notificationList = notificationTypeList.data?.notification_type
    if (userInfo.seller_id) {
      const emailNotificationList = notificationList?.filter(notificationTypeItem => sellerEmailNotificationNames.includes(notificationTypeItem.name || ""))
      emailNotificationList?.forEach(emailNotificationType => {
        createNewUserNotificationType({
          user_id: data.user_id,
          operator_id: data.operator_id,
          nt_type_id: emailNotificationType.id,
        }).then((res) => {
          alertsRef.current?.generate(mutationInfo("user notification type",
            MutationAction.Update, res));
        })
          .catch((error) => {
            // ignore 
            console.log(`Exception: ${error}`);
          })
      })
    }
  }
  const onSubmit = async () => {
    //console.log(`Review onSubmit`);
    setBusy(true);
    if (data.preset_notification === false) {
      await UpdateNotificationType();
    }
    await UpdateEmailNotificationType()
    await UpdateUserProfile();
    setBusy(false);
  };

  return (
    <div className="mb-2 mx-auto">
      <FormProvider {...methods}>
        <Form
          id="profile-review-submit-step"
          data-testid="user-profile-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
        >
          <div className="d-flex flex-column">
            <Card className="p-4 no-top-border">

              <div className="container p-0">
                <div className="row">
                  <div className="col-sm">
                    <FormInput
                      name="firstname"
                      label="First Name"
                      type="text"
                      placeholder="Please enter your first name"
                      reg_options={{
                        required: false,
                        maxLength: 100,
                        pattern: /^[a-z ,.'-]+$/i
                      }}
                      disabled={true}
                    />
                  </div>
                  <div className="col-sm">
                    <FormInput
                      name="lastname"
                      label="Last Name"
                      type="text"
                      placeholder="Please enter your last name"
                      reg_options={{
                        required: false,
                        maxLength: 100,
                        pattern: /^[a-z ,.'-]+$/i
                      }}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <Label className="font-weight-medium d-flex align-items-center mt-3" data-testid="">
                Profile Image
              </Label>
              <p className="py-1">
                A profile image helps personalize your account and lets you know if you're signed
                into your account.
              </p>

              <div className="container-overlay">
                <img
                  src={data.profile_image ?? ""}
                  className="border rounded-circle"
                  alt=""
                  width="100px"
                  height="100px"
                  onError={(event) => {
                    event.currentTarget.src = "/default-user-avatar.jpeg";
                  }}
                />
              </div>
              <Input
                ref={null}
                type="file"
                data-testid="user-profile-upload"
                style={{ display: "none" }}
                onChange={undefined}
                accept=".jpg, .png, .jpeg"
              />
              <FormInput
                name="profile_image"
                style={{ display: "none" }}
              />
              <FormInput
                name="email"
                label="MarketPush Account Email"
                data-testid=""
                type="text"
                placeholder="Please enter email"
                disabled={true}
                css="mt-3"
              />
              <FormInput
                name="contact"
                label="Contact Phone Number"
                data-testid=""
                type="tel"
                placeholder=""
                reg_options={{
                  required: false,
                  maxLength: 15,
                  pattern: /^[+\s\-]?(\(?\d{3}\)?[\s.\-]?)?[\d\s\-]{0,12}$/i
                }}
                css="mt-3"
                disabled={true}
              />
              {data.preset_notification && <FormInput
                name="presetNotification"
                label="Preset Notification"
                type="text"
                placeholder=""
                reg_options={{
                  required: false,
                  maxLength: 10
                }}
                css="mt-3"
                disabled={true}
              />
              }
              {!data.preset_notification &&
                <>
                  <FormInput
                    name="enableNotification"
                    label="Enable in-app Notifications"
                    type="text"
                    placeholder=""
                    reg_options={{
                      required: false,
                      maxLength: 10,
                    }}
                    css="mt-3"
                    disabled={true}
                  />
                  <FormInput
                    name="enableEmailNotification"
                    label="Enable Email Notifications"
                    type="text"
                    placeholder=""
                    reg_options={{
                      required: false,
                      maxLength: 10,
                    }}
                    css="mt-3"
                    disabled={true}
                  />
                </>
              }
              {!data.preset_notification && data.enable_notification &&
                (<>
                  <div className="row">
                    <div className="font-weight-semi-bold form-label mt-3">
                      {data.notification_type_list.length === 0 ?
                        `You have selected 0 notification.`
                        :
                        data.notification_type_list.length === 1 ?
                          `You have selected the following notification:`
                          :
                          `You have selected the following notifications:`
                      }
                    </div>
                  </div>
                  <ul className="pl-2">
                    {data.notification_type_list.map((item: any) => {
                      return (
                        <li key={item.name}>
                          {item.name}
                        </li>
                      )
                    })}
                  </ul>
                </>)}
            </Card>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
}