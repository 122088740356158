import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { OrderDetails } from "./OrderDetails";
import OrderIssueRefunds from "./OrderIssueRefunds";
import { OrderList } from "./OrderList";

export const OrdersRoute: FC = () => {
  return (
    <Routes>
      <Route index element={<OrderList />} />
      <Route path=":orderId" element={<OrderDetails />} />
      <Route path=":orderId/refunds" element={<OrderIssueRefunds />} />
    </Routes>
  );
};
