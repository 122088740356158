import { FC } from "react";
import { Money } from "./Money";
import { StrikedOutMoney } from "./StrikedOutMoney";

type ZeroedOutMoneyProps = { amount: number };

export const ZeroedOutMoney: FC<ZeroedOutMoneyProps> = (props) => {
  return (
    <span>
      {props.amount === 0 ? (
        <StrikedOutMoney amount={props.amount} />
      ) : (
        <Money amount={props.amount} />
      )}
    </span>
  );
};
