import { FC, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  useAddProductGroupMutation
} from "../../../generated/urql-graphql";
import { appRef } from "../../common/components/appStatus";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";
import { alertsRef } from "../../layout/components/Main";

interface Props {
  closeDialog: () => void;
  subctgOptions: SelectOption[];
}
export const AddProductGroup: FC<Props> = ({ closeDialog, subctgOptions }) => {
  const [optionLength, setOptionLength] = useState<number>(1);
  const [, addMutation] = useAddProductGroupMutation();
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = methods;

  const onSubmit = async (formData: FieldValues) => {
    let res;
    const productGroup = { ...formData };
    res = await addMutation({ productGroup });
    if (!res.error) {
      closeDialog();
      appRef.current?.updateStatus(false);
    }
    alertsRef.current?.generate(mutationInfo("product group", MutationAction.Update, res));
  };

  const removeOption = (index: number) => {
    const temp = getValues("categories");
    temp?.splice(index, 1);
    setValue("categories", temp);
    setOptionLength(temp?.length ?? 1);
  };

  const optionsRequirement = () => {
    return (
      <>
        {Array.from({ length: optionLength }, (elm, index) => {
          return (
            <div className="row mb-3" key={`categories_${index}`}>
              <div className="col">
                <FormSelect
                  name={`categories.${index}`}
                  label={`Category ${index + 1}`}
                  options={subctgOptions}
                  reg_options={{ required: true }}
                />
              </div>
              {index > 0 && (
                <div className="col-auto p-0 d-flex flex-column justify-content-end">
                  <Button
                    data-testid=""
                    type="button"
                    className="btn btn-flat me-2 mt-2 p-2"
                    onClick={() => removeOption(index)}
                  >
                    <i className="bi bi-trash text-danger" />
                  </Button>
                </div>
              )}
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-between">
          <Button
            data-testid=""
            type="button"
            className="btn btn-link p-0 mt-2 mb-2"
            onClick={() => setOptionLength(optionLength + 1)}
          >
            + Add
          </Button>
        </div>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="product-group-form"
        id="product-group-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
      >
        <div className="px-2">
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-6">
              <FormInput
                name="name"
                label="Product Group Name"
                reg_options={{
                  required: true,
                  maxLength: 50,
                  pattern: {
                    value: /^[a-zA-Z][a-zA-Z0-9_]+/,
                    message:
                      "Part number starts with letter and only includes letter, number and underscore",
                  },
                }}
                css="mb-3"
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              {optionsRequirement()}
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
