import { createContext, MutableRefObject } from "react";
import {
  AttributeMappingPartFragment,
  CategoryMappingPartFragment,
} from "../../../generated/urql-graphql";
import { IHash } from "../../common/types/types";

export type RowsTypes = {
  header: string[];
  bodyRows: IHash[];
};

export interface IMapping {
  attrMapped: AttributeMappingPartFragment[];
  ctgMapped: CategoryMappingPartFragment[];
  resultsRef: MutableRefObject<IMappingResults> | null;
}

export const MappingContext = createContext<IMapping>({
  attrMapped: [],
  ctgMapped: [],
  resultsRef: null,
});

export interface ISheetData {
  file: File | null;
  sheetName: string;
  rowData?: RowsTypes | null;
}

export interface IProductImport {
  id: string;
  type: string;
  changeType: boolean;
}

export interface ILineError {
  lineNumber: number;
  fields: string;
  message: string;
}
export interface IMappingResults {
  sheet: ISheetData;
  attrMapping: IHash;
  ctgMapping: IHash;
  importing: IProductImport;
}

export interface IAttrImp {
  id: string;
  display: string;
  required: boolean;
  global: boolean;
  attribute_name: string;
  sheet_column: string;
  attribute_id: string;
}

export const getDefMapResults = () => ({
  sheet: { file: null, sheetName: "", rowData: null },
  attrMapping: new Object(),
  ctgMapping: new Object(),
  importing: {id: "", type: "import", changeType: true}
})
