import { Card } from "./Card";
import { TablePlaceHolder } from "./TablePlaceHolder";
// for TablePlaceholder
const userActivityColumnNames = ["Date", "Table", "Event Type", "Description"];
const userAccountColumnNames = ["Seller", "Role", "Current Logged In", "Action"];
interface PlaceHolderItemProps {
  labelWidth?: number;
  rows?: number;
  columns?: number;
}

export const PlaceholderItem: React.FC<PlaceHolderItemProps> = ({
  labelWidth = 1,
  rows = 1,
  columns = 1,
}) => {
  return (
    <div className="placeholder-inner">
      {Array.from(Array(rows).keys()).map((row, index) => (
        <div key={"row" + index} data-row={row + index} className="row mb-3">
          {Array.from(Array(columns).keys()).map((index) => (
            <div key={"column" + index} data-col={index} className="col">
              <div className="h5 card-title placeholder-glow">
                <span className={`placeholder col-${labelWidth}`}></span>
              </div>
              <p className="card-text placeholder-glow mt-2">
                <span className="placeholder col-12"></span>
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const PlaceholderDescriptionItem = () => {
  return (
    <div className="mb-3">
      <div className="h5 card-title placeholder-glow">
        <span className="placeholder col-2"></span>
      </div>
      <p className="card-text placeholder-glow mt-2">
        <span className="placeholder col-12 placeholder-description"></span>
      </p>
    </div>
  );
};

export const PlaceholderHeadingItem = () => {
  return (
    <div className="mb-3">
      <div className="h3 card-title placeholder-glow mb-0">
        <span className="placeholder col-2"></span>
      </div>
      <p className="card-text placeholder-glow mt-1">
        <span className="placeholder col-3 placeholder-small"></span>
      </p>
    </div>
  );
};

export const PlaceholderSingleItem = () => {
  return (
    <div className="placeholder-glow w-100">
      <span className="placeholder placeholder-image w-100"></span>
    </div>
  );
};

export const PlaceholderImageItem = () => {
  return (
    <div className="d-flex mb-3">
      <div className="me-2">
        <div className="placeholder-glow">
          <span className="placeholder placeholder-image"></span>
        </div>
      </div>
      <div className="flex-1">
        <p className="d-flex placeholder-glow mb-1">
          <span className="placeholder vw10 me-2"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-sm-7"></span>
        </p>
      </div>
    </div>
  );
};

export const PlaceholderTableSearchItem = () => {
  return (
    <div className="mb-3 placeholder-search">
      <div className="row">
        <div className="col-6">
          <div className="placeholder-glow">
            <span className="placeholder col-2 placeholder-date-filter me-3"></span>
            <span className="placeholder col-3 placeholder-search-filter"></span>
          </div>
        </div>
        <div className="col-6">
          <div className="placeholder-glow mb-2 d-flex justify-content-end">
            <span className="placeholder placeholder-search-input"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SellerDetailsLoader = () => {
  return (
    <Card className="p-4">
      <PlaceholderItem rows={2} />
      <PlaceholderDescriptionItem />
      <PlaceholderItem columns={2} />
      <div className="border-top my-3"></div>
      <PlaceholderHeadingItem />
      <PlaceholderItem rows={6} />
      <PlaceholderItem labelWidth={2} />
      <PlaceholderItem columns={3} />
      <PlaceholderItem />
    </Card>
  );
};

export const PlaceholderLists = () => {
  return (
    <div className="placeholder-list">
      <ul className="d-flex flex-column gap-2 placeholder-glow no-list">
        <li className="placeholder col-9"></li>
        <li className="placeholder col-9"></li>
        <li className="placeholder col-5"></li>
      </ul>
    </div>
  );
};

export const UserActivityLoader = () => {
  return (
    <Card className="p-4">
      <PlaceholderImageItem />
      <PlaceholderTableSearchItem />
      <TablePlaceHolder columnNames={userActivityColumnNames} />
    </Card>
  );
};

export const UserSellerAccountsLoader = () => {
  return (
    <Card className="p-4">
      <PlaceholderImageItem />
      <TablePlaceHolder columnNames={userAccountColumnNames} numberOfRows={2} />
    </Card>
  );
};

export const PlaceholderBarItem = () => {
  return (
    <div className="mb-4 text-center">
      <div className="card-title placeholder-glow mb-0">
        <span className="placeholder col-6" style={{minHeight: 40}}></span>
      </div>
    </div>
  );
};

export const PlaceholderBoxItem = () => {
  return (
    <div className="mb-3 text-center">
      <div className= "card-title placeholder-glow h-100 mb-0">
        <span className="placeholder col-12" style={{minHeight: 420}}></span>
      </div>
    </div>
  );
};

export const PlaceholderSingleButton = () => {
  return (
    <div className="mb-3 text-right">
      <div className= "card-title placeholder-glow h-100 mb-0">
        <span className="placeholder" style={{minHeight: 40, width: 60}}></span>
      </div>
    </div>
  );
};