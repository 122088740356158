import { useContext, useEffect, useState } from "react";
import {
  CategoryMappingPartFragment, useCustomerCtgsQuery,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { SearchSelect } from "../../common/components/SearchSelect";
import { useSearch } from "../../common/hooks/useSearch";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { IHash, IOptions, SelectOption } from "../../common/types/types";
import { MappingContext } from "../utils/types";

export const MapCategory = () => {
  const initCtgData = useSearch("category", false);
  const mapContext = useContext(MappingContext);
  const results = mapContext.resultsRef!.current;
  const ctgids = Object.values(results.ctgMapping)
    .filter(ctg => ctg.category_id)
    .map(ctg => ctg.category_id);
  const [queried] = useCustomerCtgsQuery({ variables: { ctgids } });
  const [init, setInit] = useState<boolean>(true);

  useEffect(() => {
    if (init) {
      setInit(false);
      return;
    }

    const mapped : IHash = {};
    Object.values(results.ctgMapping).forEach(ctg => {
      let temp = { ...ctg };
      const found = mapContext.ctgMapped.find((mctg) => mctg.sheet_category_value === ctg.sheet_category_value);
      if (found) {
        temp = {...found, orig_ctg_id: found.category_id};
      }
      mapped[ctg.sheet_category_value] = temp;
    });
    results.ctgMapping = mapped;
  }, [mapContext.ctgMapped]);

  useEffect(() => {
    const customerCtgs = queried?.data?.vw_category_hierarchy;
    if (!customerCtgs?.length) return;
    
    Object.values(results.ctgMapping).forEach(ctg => {
      if (ctg.category_id && (!ctg.ctg_fullname || ctg.category_id !== ctg.orig_ctg_id)) {
          const found = customerCtgs?.find(cctg => cctg.id === ctg.category_id);
          if (found)
            ctg.ctg_fullname = found.fullname;
      }
    });
  }, [queried.data]);

  if (queried.error)
    return <p>{queried.error.message}</p>;
  if (!initCtgData.total || !queried.data)
    return <PlaceholderLists />;

  const mappingKeys = Object.keys(results.ctgMapping);
  return (
    <>
      <div className="product-import-wizard map-categories">
        <Card className="my-4 p-4">
          <h4 className="card-title">Map Categories</h4>
          <p className="alert alert-warning">
            <i className="bi bi-exclamation-triangle me-2"></i>Products with unmapped categories{" "}
            <strong>will not</strong> be imported during the import process.
          </p>
          <table className="table my-2">
            <thead>
              <tr>
                <th className="d-flex col-12">
                  <span className="col-4">Your Categories</span>
                  <span className="">Map to Marketpush Categories</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!mappingKeys.length ? 
                <tr>
                  <td className="text-center">No mapped category</td>
                </tr> : 
                <>
                  {mappingKeys.map((ctgKey, index) => {
                  return (
                    <tr key={`${ctgKey}_${index}`}>
                      <td className="d-flex col-12 align-items-center">
                        <div className="col-4 d-flex justify-content-between align-items-center">
                          <div>{ctgKey}</div>
                        </div>
                        <div className="col-8">
                          <CategoryMapping category={results.ctgMapping[ctgKey]} initData={initCtgData} />
                        </div>
                      </td>
                    </tr>
                  );})}
                </>}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

const CategoryMapping = ({ category, initData }: { category: CategoryMappingPartFragment, initData: IOptions }) => {
  const mapContext = useContext(MappingContext);
  const results = mapContext.resultsRef!.current;
  const defCtgId = category.category_id || emptyUuid;
  const ctgVal = category.sheet_category_value || "";
  let initOptions = [...initData.options];
  if (!isNullOrEmpty(defCtgId) && initOptions.some(opt => opt.value !== defCtgId)) {
    initOptions = [...initOptions, { label: category?.ctg_fullname || "", value: defCtgId }];
  }
  const ctgChanged = (ctg: SelectOption | null) => {
    results.ctgMapping[ctgVal] = {
      ...results.ctgMapping[ctgVal],
      category_id: ctg?.value,
      ctg_fullname: ctg?.label,
    };
  };
  return (
    <SearchSelect
      initOptions={initOptions}
      itemChanged={(item: SelectOption | null) => ctgChanged(item)}
      defVal={defCtgId}
      totalItems={initData.total}
      placeholder="Please select or search for a product category..."
      itemType="category"
    />);
};