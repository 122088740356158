import { BlobServiceClient } from "@azure/storage-blob";
import { env } from "../../../env";

const importContainer = "imports";
const appContainer = "appsites";
const productContainer = "products";
const orderContainer = "orders";
const storageBaseUrl = `https://${env.REACT_APP_STORAGERESOURCENAME}.blob.core.windows.net`;
export const TENANT_SETTING_URL = `${storageBaseUrl}/${appContainer}/tenants_settings.json`;
export const OFFER_TEMPLATE_URL = `${storageBaseUrl}/${appContainer}/offer-template-blank.xlsx`;

const getBlobServiceClient = () => {
  const sasToken = env.REACT_APP_STORAGESASTOKEN;
  return new BlobServiceClient(storageBaseUrl + "/?" + sasToken);
};
const blobServiceClient = getBlobServiceClient();

const createBlobInContainer = async (file: File, filename: string, container: string) => {
  const containerClient = blobServiceClient.getContainerClient(container);
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(filename);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);
};

const uploadFileToBlob = async (
  file: File | null,
  container: string,
  filename?: string
): Promise<string> => {
  if (!file) return "";

  if (!filename) {
    const uniqueNum = new Date().getTime();
    filename = `${uniqueNum.toString()}-${file.name}`;
  }

  // upload file
  await createBlobInContainer(file, filename, container);
  return `${storageBaseUrl}/${container}/${filename}`;
};

export const uploadProductImage = async (
  file: File | null,
  filename?: string
): Promise<string> => {return await uploadFileToBlob(file, productContainer, filename);}

export const uploadImportFile = async (
  file: File | null,
  filename?: string
): Promise<string> => {return await uploadFileToBlob(file, importContainer, filename);}

export const uploadAppData = async (
  file: File | null,
  filename?: string
): Promise<string> => {return await uploadFileToBlob(file, appContainer, filename);}

export const uploadOrderFile = async (
  file: File | null,
  filename?: string
): Promise<string> => {return await uploadFileToBlob(file, orderContainer, filename);}



