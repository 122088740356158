import { useFormContext } from "react-hook-form";
import { getFieldError, regProps } from "../../common/miscellaneous/utility";
import { IFormInputProps } from "../../common/types/types";

type InputProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  "name"
> &
  IFormInputProps;

export const FormTextarea = (props: InputProps): JSX.Element => {
  const { name, label, label_extra, input_extra, css, reg_options, tool_tip, form_methods, maxLength } = props;
  const required = reg_options?.required;
  const methods = useFormContext() || form_methods;
  const {
    register,
    formState: { errors },
    watch
  } = methods;
  const fieldError = getFieldError(errors, name);
  const maxLen = maxLength || reg_options?.maxLength;
  const inputValue = watch(name);

  return (
    <div className={`mb-3 ${css ? css : ""}`}>
      <label className={` font-weight-medium form-label ${required ? "required" : ""}`}>
        {label === "LABEL_FOR_POS_HIDDEN" ? "" : label}
        {label_extra}
        {tool_tip && (
          <>
            <span className="ms-1 tooltip-custom right">
              <span className="tooltip-text">{tool_tip}
              </span>
              <i className="bi bi-question-circle"></i>
            </span>
          </>
        )}
      </label>
      <textarea
        data-testid={`textarea_${name}`}
        className="form-control"
        {...regProps(name, register, props)}
      ></textarea>

      {maxLen &&
        <div className="d-flex justify-content-end">
          <small className={`${inputValue?.length > maxLen}
              ? "text-danger "
              : "text-muted" 
          `}>
            {`${inputValue?.length || 0}/${maxLen}`}
          </small>
        </div>
      }

      {input_extra}
      {fieldError && (
        <>
          <small className="has-error text-danger mb-0">
            <i className="bi bi-exclamation-circle-fill me-1"></i>
            {fieldError.ref.validationMessage || fieldError.message}
          </small>
        </>
      )}
    </div>
  );
};
