import { FC } from "react";

type Props = {
  size: number;
};

export const Spinner: FC<Props> = (props): ReturnType<FC> => {
  const { size } = props;

  const remSize = size / 16;

  return (
    <div
      className="animation-container"
      style={{ width: `${remSize}rem`, height: `${remSize}rem` }}
    >
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
      <div className="dot dot-4"></div>
      <div className="dot dot-5"></div>
      <div className="dot dot-6"></div>
      <div className="dot dot-7"></div>
      <div className="dot dot-8"></div>
    </div>
  );
};
