import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  CustomerPartFragment,
  OrderInfoPartFragment,
  OrderItemPartFragment,
  Scalars,
  TrackingItemPartFragment,
  useFetchCarriersQuery,
  useMarkAsShippedMutation,
  useNoRequireTrackingMutation,
  useRequireTrackingMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { alertsRef } from "../../layout/components/Main";
import { EditQuantityContext } from "./OrderDetails";
import { ShippingInfo } from "./OrderItems";
import TrackingDetails from "./TrackingDetails";
import TrackingInfoTable from "./TrackingInfoTabe";
interface IProp {
  customer: CustomerPartFragment;
  orderInfo: OrderInfoPartFragment;
  orderItems: OrderItemPartFragment[];
  totalAcceptedQuantity: { [key: string]: number };
  trackingItems: TrackingItemPartFragment[] | undefined
}

export interface TrackingRowType {
  quantity: number;
  order_item_id: string;
  id: string;
  carrier_key: string;
  tracking_number: string;
  shipped_date: Scalars["timestamp"];
}
const ShippingInfoComponent: FC<IProp> = ({
  customer,
  orderInfo,
  orderItems,
  totalAcceptedQuantity,
  trackingItems
}) => {
  const [update] = useContext(EditQuantityContext);
  const [queried] = useFetchCarriersQuery();
  const markAsShippedMutation = useMarkAsShippedMutation()[1];
  const noRequireTrackingMutation = useNoRequireTrackingMutation()[1];
  const requireTrackingMutation = useRequireTrackingMutation()[1];
  const initial: { [key: string]: number } = {};
  orderItems.filter(item => item.trg_status === "Accepted").forEach((item) => {
    initial[item.id as string] = item.quantity;
  });
  const [adjust, setAdjust] = useState<boolean>(false); //for adjust prompt window
  const shippedItems = useRef<{ [key: string]: number }>({});
  const exceedItems = useRef<string[]>([]);
  const noRequiredTracking = useRef<HTMLInputElement | null>(null);
  const [availableQuantity, setAvailableQuantity] = useState<{ [key: string]: number }>({
    ...initial,
  });
  const [ifDisableTrackingButton, setIfDisableTrackingButton] = useState<boolean>(false);
  const [displayContent, setDisplayContent] = useState<boolean>(orderInfo.use_tracking);
  const [addTracking, setAddTracking] = useState<boolean>(false);
  const totalQuantity = Object.values(totalAcceptedQuantity).reduce(
    (total, item) => (total += item),
    0
  );
  const shippingQuantity = Object.values(shippedItems.current).reduce(
    (total, item) => (total += item),
    0
  );
  const markAsShipped = async () => {
    const res = await markAsShippedMutation({ id: orderInfo.id });
    alertsRef.current?.generate(mutationInfo("shipping info", MutationAction.Update, res));
  };
  const checkAvailableFunc = () => {
    const temp = { ...initial };
    if (!noRequiredTracking.current?.checked) {
      for (const [key, value] of Object.entries(shippedItems.current)) {
        if (temp[key]) temp[key] = temp[key] - value;
      }
      setAvailableQuantity(temp);
    }
  };

  const noRequireTrackingFunc = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setDisplayContent(false);
      exceedItems.current = [];
      await noRequireTrackingMutation({ id: orderInfo.id });
    } else {
      setAvailableQuantity({ ...initial });
      setDisplayContent(true);
      await requireTrackingMutation({ id: orderInfo.id });
    }
  };

  useEffect(() => {
    if (update && !noRequiredTracking.current?.checked) {
      //useTracking
      exceedItems.current = [];
      let shippedExceedTimes = 0;
      for (const [key, value] of Object.entries(totalAcceptedQuantity)) {
        if (shippedItems.current[key] && shippedItems.current[key] > value) {
          ++shippedExceedTimes;
          if (exceedItems.current.indexOf(key) === -1) exceedItems.current.push(key);
        }
      }
      if (shippedExceedTimes > 0) {
        setAdjust(true);
        setIfDisableTrackingButton(true);
        return;
      }
      setAdjust(false);
      const num = totalQuantity - shippingQuantity;
      if (num > 0) setIfDisableTrackingButton(false);
      else setIfDisableTrackingButton(true);
    }
  }, [update]);

  const checkEachItemQuantity = () => {
    const num = totalQuantity - shippingQuantity;
    if (num !== 0) return false;
    else {
      for (const [key, value] of Object.entries(totalAcceptedQuantity)) {
        if (shippedItems.current[key] !== value) {
          return false;
        }
      }
    }
    return true;
  };
  useEffect(() => {
    exceedItems.current = [];
    //trigger the check if mutation happens in tracking item
    if (!noRequiredTracking.current?.checked) {
      let checkExceedTimes = 0;
      for (const [key, value] of Object.entries(availableQuantity)) {
        if (value < 0) {
          ++checkExceedTimes;
          if (exceedItems.current.indexOf(key) === -1) exceedItems.current.push(key);
        }
      }
      if (checkExceedTimes > 0) {
        setAdjust(true);
        setIfDisableTrackingButton(true);
        return;
      }
      const totalAvailableQuantity = Object.values(availableQuantity).reduce(
        (total, val) => (total += val),
        0
      );
      setAdjust(false);
      if (totalAvailableQuantity) setIfDisableTrackingButton(false);
      else setIfDisableTrackingButton(true);
    }
  }, [availableQuantity]);

  const error = queried.error;
  if (error) {

    return <PageError error={{ source: "ShippingInfoComponent", errMsg: error.message }} />;
  }

  let carriers = [{ label: "", value: "" }];
  carriers = [...carriers, ...queried.data?.shipping_carrier?.map((item) => ({ label: item.shipping_carrier, value: item.key })) || []];

  const displayMarkasShipped = () => {
    if (orderInfo.trg_status === "AwaitingFulfillment") {
      if (
        ifDisableTrackingButton &&
        checkEachItemQuantity() &&
        !noRequiredTracking.current?.checked
      )
        return true;
      else if (!orderInfo.use_tracking || noRequiredTracking.current?.checked) return true;
      else return false;
    } else return false;
  };
  const trackingInfoElements = (
    <TrackingInfoTable
      status={orderInfo.trg_status || ""}
      orderItems={orderItems}
      shippedItems={shippedItems}
      availableFunc={checkAvailableFunc}
      availableQuantity={availableQuantity}
      adjust={adjust}
      exceedItems={exceedItems.current}
      trackingItems={trackingItems}
      carriers={carriers}
    />
  );

  return (
    <div className="col-lg-12 col-xl-9">
      <Card className="mb-3">
        <div className="row">
          <div className="col-md-12">
            <div className="border-bottom card-header">
              <h5 className="d-flex justify-content-between card-title mb-0 align-items-center">
                <span>
                  <i className="bi bi-truck me-2"></i>
                  <span>Shipping Information</span>
                </span>
                {displayMarkasShipped() && (
                  <span>
                    <Button
                      data-testid=""
                      type="button"
                      className="btn btn-success"
                      onClick={markAsShipped}
                    >
                      Mark as Shipped
                    </Button>
                  </span>
                )}
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <ShippingInfo customer={customer} preferredCarrier={orderInfo.shipping_carrier} shippingMethod={orderInfo.shipping_method_code} />
                <div className="col-lg-12 col-xl-9">
                  {displayContent && trackingInfoElements}
                  {orderInfo.trg_status === "AwaitingFulfillment" && (
                    <div className="d-flex align-items-start flex-column">
                      {displayContent && (
                        <div className="mb-2">
                          <Button
                            data-testid=""
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              checkAvailableFunc();
                              setAddTracking(true); //add
                            }}
                            disabled={ifDisableTrackingButton}
                          >
                            Add Tracking
                          </Button>
                        </div>
                      )}
                      <div className="form-check form-switch mb-3">
                        <label data-testid="" className="form-check-label text-muted">
                          <Input
                            data-testid=""
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={!orderInfo.use_tracking}
                            ref={noRequiredTracking}
                            onChange={noRequireTrackingFunc}
                          //disabled={displayMarkasShipped() ? true : false}
                          />
                          This order does not require tracking.
                        </label>
                      </div>
                    </div>
                  )}

                  {!orderInfo.use_tracking && orderInfo.trg_status !== "AwaitingFulfillment" && (
                    <p>This order does not require tracking.</p>
                  )}
                  {addTracking &&
                    <TrackingDetails
                      orderItems={orderItems}
                      availableQuantity={availableQuantity}
                      close={() => setAddTracking(false)}
                      orderId={orderInfo.id}
                      carriers={carriers}
                    />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ShippingInfoComponent;
