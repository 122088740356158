import { FC } from "react"
import { Route, Routes } from "react-router-dom"
import MemberDetails from "./MemberDetails"
import MemberList from "./MemberList"

const MembersRoute: FC = () => {
    return (
        <Routes>
            <Route index element={<MemberList />} />
            <Route path="add-member" element={<MemberDetails isAddForm={true} />} />
            <Route path=":memberIdParam" element={<MemberDetails isAddForm={false} />} />
        </Routes>
    )
}

export default MembersRoute
