import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useProductDataQuery,
  useProductOptionsQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { CustomSelect } from "../../common/components/CustomSelect";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { ISearchParam, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { useMainContext } from "../../layout/components/MainProvider";
import { OfferDetails } from "./OfferDetails";
import { ProductInfo } from "./ProductInfo";

export const AddOffer = () => {
  const { prodId } = useParams<{
    prodId: string;
  }>();

  const [context] = useMainContext();
  const { check_mfr } = context.operatorSettings.product;

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseUrl = `/${tenantUrlTag}/offers`;
  const leadingTime = context.operatorSettings.offer.days_for_lead_time_to_shipped

  const userInfo = useUserInfo();
  const storeId = userInfo.seller_id;
  const [productId, setProductId] = useState<string>(prodId ?? emptyUuid);
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);

  let defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0, unverifiedStatuses: [true, false] };
  if (check_mfr) {
    defParam = {
      searchTerm: "%",
      limit: 15,
      offset: 0,
      total: 0,
      unverifiedStatuses: [false, true]
    };
  }

  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [productOptionQueried] = useProductOptionsQuery({
    variables: _.omit(searchParam, "total"),
  });

  useEffect(() => {
    if (productOptionQueried.data) {
      const products =
        productOptionQueried.data.data_vw_product.map(
          (prod) => {
            const productTitle = prod.product_title || ""
            const displayProductTitle = productTitle?.length > 30 ? (productTitle.slice(0, 35) + '...') : prod.product_title
            return {
              label: `${prod.mfr_part_number_lower} | ${prod.manufacturer} | ${displayProductTitle}`,
              value: prod.id,
            } as SelectOption
          }
        ) || [];
      setProductOptions(products);
      setSearchParam({
        ...searchParam,
        searchTerm: searchParam.searchTerm?.toLowerCase() || "%",
        total: productOptionQueried.data.vw_products.aggregate?.count || 0,
      });
    }
  }, [productOptionQueried.fetching]);

  const [productQueried] = useProductDataQuery({
    variables: {
      productId,
      storeId,
    },
  });

  const error = productQueried.error;
  if (error) {
    return <PageError error={{ source: "AddOffer", errMsg: error.message }} />;
  }
  if (!productQueried.data) {
    return <Loader />;
  }

  const productData = productQueried.data?.data_vw_product?.find(prod => prod);
  const locOptions = productQueried?.data?.address.map(addr => ({ value: addr.value, label: addr.label }));

  return (
    <div className="offers-wrapper">
      <div className="d-flex justify-content-center">
        <div className="col-sm-12 col-xl-8 mx-auto">
          <ReturnHeader title="Add Offer" url={baseUrl}>
            {!isNullOrEmpty(productId) ? (
              <Button
                data-testid="btn-save-offer"
                className="btn btn-primary"
                form="offer-details-form"
                type="submit"
              >
                Save
              </Button>
            ) : (
              <div></div>
            )}
          </ReturnHeader>
          <div className="mx-0">
            {!isNullOrEmpty(productId) && productData ? (
              <>
                <ProductInfo productData={productData} />
                <OfferDetails locOptions={locOptions} productId={productId} leadingTime={leadingTime} />
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    data-testid="btn-save-offer"
                    className="btn btn-primary"
                    form="offer-details-form"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <Card className="p-4">
                <CustomSelect
                  label="Search for a product"
                  options={productOptions}
                  placeholder="Please select or search for a product"
                  search={{ searchParam, setSearchParam }}
                  onSelectionChanged={(options: SelectOption[]) => options.length && setProductId(options.find(opt => opt)?.value)}
                />
                {productOptionQueried.fetching && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ position: "absolute", top: "68px", right: "60px" }}
                  ></span>
                )}
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
