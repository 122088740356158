import { FC } from "react";
import { useMainContext } from "./MainProvider";

interface IProps {
  setBorder?: (css: string) => void;
}

export const GlobalMessage: FC<IProps> = ({ setBorder }) => {
  const [context] = useMainContext();
  const { show_glb_msg, glb_message, glb_msg_type } = context.operatorSettings.system;
  const defCss = setBorder ? "mb-0 py-1 px-2" : "global-message d-flex w-100 position-fixed px-4 py-1";
  const glbMsgCss = (msgType: string) => {
    let cssObj = {
      textCss: "",
      icon: "",
      bg: "",
    };
    switch (msgType) {
      case "Warning":
        cssObj = {
          textCss: "text-dark",
          icon: "bi bi-exclamation-circle",
          bg: "bg-warning"
        };
        break;
      case "Critical":
        cssObj = {
          textCss: "text-white",
          icon: "bi bi-exclamation-triangle",
          bg: "bg-danger"
        };
        break;
      case "Info":
        cssObj = {
          textCss: "text-white",
          icon: "bi bi-info-circle",
          bg: "bg-info"
        };
        break;
      default: //Plain Text:
        break;
    }
    return cssObj;
  };

  const cssObj = glbMsgCss(glb_msg_type);
  if (setBorder && cssObj.bg !== "") {
    setBorder(cssObj.bg.replace("bg-", "border-"));
  }
  const showGlbMsg = show_glb_msg?.toString() === "true" && glb_message !== "";

  return showGlbMsg || setBorder ?
    <div
      className={`${cssObj.bg} ${cssObj.textCss} ${defCss}`}
    >
      {showGlbMsg ? <> <i className={`${cssObj.icon} me-1`}></i> {glb_message} </> : "All services normal"}
    </div>
    : null;
}

